import React from 'react'
import styles from './generalContainer.module.css'

export default function GeneralContainer({ children, backGround, className, ...props }) {
  return (
    <div
      className={`container-fluid ${styles['general-container']} ${className}`}
      style={{
        backgroundImage: `url(${backGround})`,
        ...props.style
      }}
      {...props}
    >
      {children}
    </div>
  )
}
