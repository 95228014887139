import React from 'react'
import logo from './img/fundacion-solidaria-verde.svg'
import styles from './initiative-image.module.css'
import { FormattedMessage } from 'react-intl'
import GeneralContainer from '../../Common/GeneralContainer/GeneralContainer'
import backGround from '../../../../assets/img/Home/donar-home.png'

const InitiativeImage = () => (
  <section className="showcase">
    <GeneralContainer backGround={backGround} className='flex-wrap'>
      <img src={logo} alt='logo' title='logo' className={styles['logo']} />
      <h3 className={`mx-auto mb-5 ${styles['txtC']}`}>
        <FormattedMessage id="callout.share2" />
      </h3>
    </GeneralContainer>
  </section>
)

export default InitiativeImage