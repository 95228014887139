import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import AdminInfo from './adminComponents/AdminInfo'
import LocationInfo from './adminComponents/LocationInfo'
import Admincard from './adminComponents/Admincard'
import PropTypes from 'prop-types'
// Redux
import { connect } from 'react-redux'
class Welcome extends Component {
  state = {
    email: '',
    name: '',
    firstname: '',
    lastnames: '',
    rol: '',
    ip: null,
    bb: {},
    usersonline: 0,
    usersoffline: 0
  }



  componentDidMount() {

    const { name, email, rol } = this.props.auth.user
    const names = name.split(' ')

    let firstname = `${names[0]}`
    let lastnames = ''

    if (names.length === 4)
      lastnames = `${names[2]} ${names[3]}`
    else
      lastnames = `${names[1]} ${names[2]}`
    this.setState({ name, lastnames, firstname, email, rol })
  }

  render() {
    return (
      <Fragment>

        <div className="row">
          <Admincard />
        </div>

        <div className="row">
          <div className="col-xl-3 col-md-6 mb-4 fixed">
            <h1 className="h3 mb-2 text-gray-800"><FormattedMessage id="dashboard.welcome" /> {this.state.firstname}</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 col-md-12 col-sm-12 mb-4">
            <div className="card-deck">
              <AdminInfo />
              <LocationInfo />
            </div>
          </div>
        </div>

      </Fragment >
    )
  }
}

Welcome.propTypes = {
  auth: PropTypes.object.isRequired,
}
const mapStateToProps = ({ auth, }) => ({ auth, })
export default connect(mapStateToProps, {})(Welcome)