import React from 'react'

export default function InstagramSVG(props) {
  return (
    <svg id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 612 612' {...props}>
      <path className='st0' d='M402,50H210c-88.4,0-160,71.6-160,160v192c0,88.4,71.6,160,160,160h192c88.4,0,160-71.6,160-160V210 C562,121.6,490.4,50,402,50z M514,402c0,61.8-50.2,112-112,112H210c-61.8,0-112-50.2-112-112V210c0-61.8,50.2-112,112-112h192 c61.8,0,112,50.2,112,112V402z'
      />
      <path className='st0' d='M306,178c-70.7,0-128,57.3-128,128s57.3,128,128,128s128-57.3,128-128S376.7,178,306,178z M306,386 c-44.1,0-80-35.9-80-80c0-44.1,35.9-80,80-80s80,35.9,80,80C386,350.1,350.1,386,306,386z'
      />
      <circle className='st0' cx='443.6' cy='168.4' r='17.1' />
    </svg>
  )
}
