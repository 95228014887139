import { today, tomorrow } from './../../../util/dateHelpers'

export default {
  title: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },
  description: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },
  startDate: {
    value: today(),
    valid: null,
    text: '',
    feed: []
  },
  endDate: {
    value: tomorrow(),
    valid: null,
    text: '',
    feed: []
  },
  sede: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },
  subsede: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },
  content: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },
  thumbnail: {
    value: '',
    valid: null,
    text: '',
    feed: []
  }
}
