import React, { Component } from 'react'
import { Title, Paragraph, Subtitle } from '../Common/Tipography/Tipography'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'


class PrivacyTerms extends Component {
  render() {
    const { className = 'my-5' } = this.props

    return (
      <section>
        <div className={`container ${className}`} >
          <Title>{<FormattedMessage id="privacyTerms.title" />}</Title>
          <Paragraph>{<FormattedMessage id="privacyTerms.text" />}</Paragraph>
          <Paragraph>{<FormattedMessage id="privacyTerms.text1" />}</Paragraph>
          <Paragraph>{<FormattedHTMLMessage id="privacyTerms.text2" />}</Paragraph>
          <Paragraph>{<FormattedHTMLMessage id="privacyTerms.text3" />}</Paragraph>
          <Subtitle style={{ textTransform: 'none' }}>{<FormattedMessage id="privacyTerms.strong" />}</Subtitle>
          <Paragraph>{<FormattedMessage id="privacyTerms.text4" />}</Paragraph>
        </div>
      </section>
    )
  }
}
export default PrivacyTerms