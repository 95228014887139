import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import logonav from '../../../../assets/img/logo-nav-w.png'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from './../../../../util/isEmpty'

const DropdownItem = ({ change, title, actions, children }) => (
  <li className={'nav-item'}>
    <span
      className={'nav-link collapsed'}
      style={{ cursor: 'pointer' }}
      data-toggle='collapse'
      data-target={`#collapse${title}`}
      aria-expanded='true'
      aria-controls={`#collapse${title}`}>
      {children} {change}
    </span>
    <div
      id={`collapse${title}`}
      className={'collapse'}
      aria-labelledby={`#collapse${title}`}
      data-parent='#accordionSidebar'>
      <div className={'bg-white py-2 collapse-inner rounded'}>
        {actions.map((action, i) => {
          if (
            isEmpty(action.private) ||
            (action.private === true && action.rol !== 'reporter')
          ) {
            return (
              <Link className={'collapse-item'} key={i} to={action.reference}>
                {action.change}
              </Link>
            )
          } else {
            return ''
          }
        })}
      </div>
    </div>
  </li>
)

class SideNavbar extends Component {
  render() {
    const { user } = this.props.auth

    const dropdownItems = [
      {
        change: <FormattedMessage id='dashboard.catalog' />,
        title: 'Catalogos',
        actions: [
          {
            change: <FormattedMessage id='label.activitie' />,
            reference: '/dashboard/catalog-activities'
          },
          {
            change: <FormattedMessage id='label.categorie' />,
            reference: '/dashboard/catalog-categories'
          },
          {
            change: <FormattedMessage id='label.cause' />,
            reference: '/dashboard/catalog-causes'
          },
          {
            change: <FormattedMessage id='label.skill' />,
            reference: '/dashboard/catalog-skills'
          },
          {
            change: <FormattedMessage id='label.institution' />,
            reference: '/dashboard/catalog-institution'
          }
        ]
      },

      {
        change: <FormattedMessage id='label.sede' />,
        title: 'Sedes',
        actions: [
          {
            change: <FormattedMessage id='dashboard.admon' />,
            reference: '/dashboard/catalog-sedes'
          }
        ]
      },
      {
        change: <FormattedMessage id='label.subsede' />,
        title: 'Subsedes',
        actions: [
          {
            change: <FormattedMessage id='dashboard.admon' />,
            reference: '/dashboard/catalog-subsedes'
          }
        ]
      },
      {
        change: <FormattedMessage id='dashboard.users' />,
        title: 'Administradores',
        actions: [
          {
            change: <FormattedMessage id='dashboard.admon' />,
            reference: '/dashboard/catalog-rol'
          }
        ]
      },
      {
        change: <FormattedMessage id='video.header' />,
        title: 'Video',
        actions: [
          {
            change: <FormattedMessage id='dashboard.admon' />,
            reference: '/dashboard/catalog-video'
          }
        ]
      }
    ]

    const campaignLinks = [
      {
        change: <FormattedMessage id='dashboard.projects' />,
        title: 'Campañas',
        actions: [
          {
            change: <FormattedMessage id='dashboard.create' />,
            private: true,
            rol: user.rol,
            name: 'Crear',
            reference: '/dashboard/campaign/add'
          },
          {
            change: <FormattedMessage id='dashboard.list' />,
            name: 'Lista',
            reference: '/dashboard/campaign/list'
          },
          {
            change: <FormattedMessage id='dashboard.gallerie' />,
            name: 'Galería',
            reference: '/campaign/gallerie'
          },
          {
            change: <FormattedMessage id='dashboard.reports' />,
            name: 'Reportes',
            reference: '/dashboard/campaign-report'
          }
        ]
      }
    ]

    const newsLinks = [
      {
        change: <FormattedMessage id='dashboard.news' />,
        title: 'Noticias',
        actions: [
          {
            change: <FormattedMessage id='dashboard.create' />,
            private: true,
            rol: user.rol,
            name: 'Crear',
            reference: '/dashboard/news/add'
          },
          {
            change: <FormattedMessage id='dashboard.list' />,
            name: 'Lista',
            reference: '/dashboard/news/list'
          },
          {
            change: <FormattedMessage id='dashboard.gallerie' />,
            name: 'Galería',
            reference: '/news/gallerie'
          }
        ]
      }
    ]

    const convocatoryLinks = [
      {
        change: <FormattedMessage id='dashboard.conv' />,
        title: 'Convocatorias',
        actions: [
          {
            change: <FormattedMessage id='dashboard.create' />,
            private: true,
            rol: user.rol,
            name: 'Crear',
            reference: '/dashboard/convocatory/add'
          },
          {
            change: <FormattedMessage id='dashboard.list' />,
            name: 'Lista',
            reference: '/dashboard/convocatory/list'
          },
          {
            change: <FormattedMessage id='dashboard.gallerie' />,
            name: 'Galería',
            reference: '/convocatory/gallerie'
          }
        ]
      }
    ]

    const interactionLinks = [
      {
        change: <FormattedMessage id='label.friend' />,
        title: 'Amigo',
        actions: [
          {
            change: <FormattedMessage id='dashboard.admon' />,
            name: 'Administrar',
            reference: '/dashboard/catalog-friends'
          }
        ]
      },
      {
        change: <FormattedMessage id='label.initiative' />,
        title: 'Iniciativas',
        actions: [
          {
            change: <FormattedMessage id='dashboard.admon' />,
            name: 'Administrar',
            reference: '/dashboard/catalog-initiative'
          }
        ]
      },
      {
        change: <FormattedMessage id='label.contact' />,
        title: 'Contactos',
        actions: [
          {
            change: <FormattedMessage id='dashboard.admon' />,
            reference: '/dashboard/catalog-contacts'
          }
        ]
      }
    ]

    const donationLinks = [
      {
        change: <FormattedMessage id='label.donation' />,
        title: 'Donaciones',
        actions: [
          {
            change: <FormattedMessage id='dashboard.admon' />,
            private: true,
            rol: user.rol,
            reference: '/dashboard/catalog-donation'
          },
          {
            change: <FormattedMessage id='dashboard.paypal' />,
            private: true,
            rol: user.rol,
            reference: '/dashboard/paypal'
          }
          // { change: <FormattedMessage id="dashboard.reports" />, reference: '/dashboard/donations-report' },
        ]
      }
    ]

    const carrouselLinks = [
      {
        change: <FormattedMessage id='carrousel.title' />,
        title: 'Carrusel',
        actions: [
          { change: <FormattedMessage id='btn.add' />, reference: '/dashboard/carrousel/add' },
          {
            change: <FormattedMessage id='dashboard.admon' />,
            reference: '/dashboard/carrousel/list'
          }
        ]
      }
    ]

    return (
      <ul
        className={'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'}
        id='accordionSidebar'>
        <Link
          className={'sidebar-brand d-flex align-items-center justify-content-center'}
          to={'/'}>
          <div className={'sidebar-brand-icon '}>
            <img
              id='logo-dashboard'
              className='img-responsive'
              src={logonav}
              alt='Homepage'
              style={{ maxWidth: '100%' }}
            />
          </div>
        </Link>

        <hr className={'sidebar-divider my-0'} />

        <li className={'nav-item active'}>
          <Link className={'nav-link'} to='/dashboard'>
            <i className={'fas fa-fw fa-tachometer-alt'}></i>
            <span>
              <FormattedMessage id='general.dashboard' />
            </span>
          </Link>
        </li>

        <hr className={'sidebar-divider'} />

        {campaignLinks.map((item, i) => (
          <DropdownItem key={`${i}-campaign`} {...item} />
        ))}
        {newsLinks.map((item, i) => (
          <DropdownItem key={`${i}-news`} {...item} />
        ))}
        {convocatoryLinks.map((item, i) => (
          <DropdownItem key={`${i}-convocatory`} {...item} />
        ))}
        {donationLinks.map((item, i) => (
          <DropdownItem key={`${i}-convocatory`} {...item} />
        ))}

        <li className='nav-item'>
          <Link className='nav-link' to='/dashboard/customer-project/list'>
            <span>Campañas</span>
          </Link>
        </li>

        <li className='nav-item'>
          <Link className='nav-link' to='/dashboard/holiday-project/list'>
            <span>Campañas México 2020</span>
          </Link>
        </li>

        <hr className={'sidebar-divider'} />

        <div className={'sidebar-heading'}>
          <FormattedMessage id='dashboard.interaction' />
        </div>
        {interactionLinks.map((item, i) => (
          <DropdownItem
            key={i}
            change={item.change}
            title={item.title}
            actions={item.actions}
          />
        ))}

        <hr className={'sidebar-divider'} />

        {user.rol === 'corporative' && (
          <div className={'sidebar-heading'}>
            <FormattedMessage id='dashboard.configuration' />
          </div>
        )}

        {user.rol === 'corporative' &&
          dropdownItems.map((item, i) => (
            <DropdownItem
              key={i}
              change={item.change}
              title={item.title}
              actions={item.actions}
            />
          ))}

        <li className='nav-item'>
          <Link className='nav-link' to='/dashboard/mailing/list'>
            <span>Mailing</span>
          </Link>
        </li>

        <hr className={'sidebar-divider'} />

        {(user.rol === 'corporative' || user.rol === 'maintainer') && (
          <div className={'sidebar-heading'}>
            <FormattedMessage id='dashboard.multimedia' />
          </div>
        )}
        {(user.rol === 'corporative' || user.rol === 'maintainer') &&
          carrouselLinks.map((item, i) => (
            <DropdownItem
              key={i}
              change={item.change}
              title={item.title}
              actions={item.actions}
            />
          ))}
      </ul>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
export default connect(mapStateToProps)(SideNavbar)
