import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { spinnerToggle } from '../../redux/actions/spinnerActions'
import { getCampaing, getCleanCampaing } from '../../redux/actions/campaingActions'
import { isEmpty } from './Helpers'
import Navbar from '../Main/Common/Navbar/Navbar'
import Footer from '../Main/Common/Footer/Footer'
import ModalSubscribe from './component/Modals/ModalSubscribe'
import ImgPreview from './../common/ImgPreview'
import DonationsPanel from './panels/DonationsPanel'
import VolunteeringPanel from './panels/VolunteeringPanel'
import InkindDonationsPanel from './panels/InkindDonationsPanel'
import { dmy } from './../../util/dateHelpers'
import { FormattedMessage, injectIntl } from 'react-intl'
import Breadcrumb from '../Main/Common/Breadcrumb/Breadcrumb'
import MiniGallerie from './component/MiniGallerie/MiniGallerie'
import TagDate from './component/CampaingTagDate/CampaingTagDate'
import TagText from './component/CampaingTagText/CampaingTagTex'
import ShareCard from '../SocialMedia/ShareCard'
import ShareTree from '../SocialMedia/ShareTree'

const getNames = (arr) => arr.reduce((pre, cur) => [ ...pre, cur.name ], []).join(', ')

export class Campaing extends Component {
	state = {
		modal: false
	}

	componentDidMount() {
		const { id } = this.props.match.params
		this.props.getCampaing(id, this.props.intl)
	}

	componentWillReceiveProps(np) {
		if (isEmpty(np.campaing.view)) {
			const { id } = this.props.match.params
			this.props.getCampaing(id, this.props.intl)
		}
	}

	componentWillUnmount() {
		this.props.getCleanCampaing()
	}

	toggle = () => this.setState({ modal: !this.state.modal })

	render() {
		const { view } = this.props.campaing
		const message = `Amigos, apoyemos el proyecto "${view.title}" desde esta página.`
		const shareUrl = window.location.href
		const gallerieItems = view.gallerie
			? view.gallerie.map((g) => ({
					thumbnail: g.path,
					original: g.path,
					size: '100px',
					description: g.title
				}))
			: []
		return (
			<Fragment>
				<Navbar />
				{isEmpty(view) ? (
					<h1>
						<FormattedMessage id="general.loading" />
					</h1>
				) : (
					<div className=" contenedorProject">
						<Breadcrumb {...view} link="/campaign/gallerie" id="nav.gallerie" />

						<div className="col-lg-12 row">
							<h1 className="mt-4 mb-3 subtitleProject">{view.title}</h1>
						</div>
						<br />
						<div className="row">
							<div className="col-lg-8">
								<ImgPreview {...view.thumbnail} />
								<ul className="list-inline mb-0">
									<TagText
										icon="fas fa-globe"
										children={
											<span>
												{view.sede.name}, {view.subsede.name}
											</span>
										}
									/>
									<TagText icon="fa fa-user" style={{ width: 280 }}>
										{!isEmpty(view.created_by) && view.created_by.name}
									</TagText>
									<TagText style={{ width: 120 }}>{view.status}</TagText>
								</ul>
								<ul className="list-inline mb-0">
									<TagDate
										icon="far fa-calendar-alt"
										children={
											<span>
												{dmy(view.startDate)} - {dmy(view.endDate)}
											</span>
										}
									/>
									<TagDate icon="fas fa-tag">
										<strong>
											<FormattedMessage id="modal.project" />:{' '}
										</strong>
										<em>{view.type}</em>
									</TagDate>
								</ul>
								<ul className="list-inline mb-0">
									<TagDate icon="fas fa-tag" style={{ width: '100%' }}>
										<strong>
											<FormattedMessage id="label.activitie" />:{' '}
										</strong>
										<em>{getNames(view.activities)}</em>
									</TagDate>
								</ul>
								<p className="lead titleProject">{view.description}</p>
								<div className="txtProject" dangerouslySetInnerHTML={{ __html: view.content }} />
								{!isEmpty(gallerieItems) && (
									<div>
										<h4 className="subtitleProject_gallerie my-4">
											<FormattedMessage id="label.gallerieImages" />
										</h4>
										<div className="gallerie_subirtexto">
											<MiniGallerie items={gallerieItems} />
										</div>
									</div>
								)}
								<div className="subirTexto" />
							</div>
							<div className="col-lg-4">
								{!isEmpty(view.donation) &&
								view.donation === true && (
									<DonationsPanel {...view.donation} project={view} location={this.props.location} />
								)}
								{!isEmpty(view.volunteering) &&
								view.volunteering === true && (
									<VolunteeringPanel
										{...this}
										{...this.props}
										{...view.volunteering}
										{...view}
										project={view}
									/>
								)}
								{!isEmpty(view.inkindDonations) &&
								view.inkindDonations === true && (
									<InkindDonationsPanel {...view} project={view} {...view.inkindDonations} />
								)}
								<ShareCard shareUrl={shareUrl} message={message} />
                {view._id === "647773074a2caaef612c87e3" ?
                  <ShareTree /> : ''
                }
							</div>
						</div>
					</div>
				)}
				<ModalSubscribe {...this} {...this.props} item={view} user={this.props.auth.user} />
				<Footer />
			</Fragment>
		)
	}
}

Campaing.propTypes = {
	auth: PropTypes.object.isRequired,
	campaing: PropTypes.object.isRequired,
	getCampaing: PropTypes.func.isRequired,
	getCleanCampaing: PropTypes.func.isRequired
}
const mapStateToProps = ({ auth, campaing, location }) => ({ auth, campaing, location })
const mapDispatchToProps = { spinnerToggle, getCampaing, getCleanCampaing }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Campaing))
