import React, { Fragment } from 'react'
import oxxopay from './../../assets/img/oxxopay_brand.png'
import './oxxo-pay-stub.css'

export default function OxxoPayStub({ amount, reference, barcode }) {
  return (
    <Fragment>
      <div className='opps' id='oxxo-pay-stub'>
        <div className='opps-header'>
          <div className='opps-reminder'>Ficha digital. No es necesario imprimir.</div>
          <div className='opps-info'>
            <div className='opps-brand'>
              <img src={oxxopay} alt='OXXOPay' />
            </div>
            <div className='opps-ammount'>
              <h3>Monto a pagar</h3>
              <h2>
                $ {Number(amount).toFixed(2)} <sup>MXN</sup>
              </h2>
              <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
            </div>
          </div>
          <div className='opps-reference'>
            <h3>Referencia</h3>
            <h1>{reference}</h1>
          </div>
        </div>
        <div className='opps-instructions'>
          <h3>Instrucciones</h3>
          <ol>
            <li>
              Acude a la tienda OXXO más cercana.{' '}
              <a
                href='https://www.google.com.mx/maps/search/oxxo/'
                target='_blank'
                rel='noopener noreferrer'>
                Encuéntrala aquí
              </a>
              .
            </li>
            <li>
              Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.
            </li>
            <li>
              Dicta al cajero el número de referencia en esta ficha para que tecleé directamete
              en la pantalla de venta.
            </li>
            <li>Realiza el pago correspondiente con dinero en efectivo.</li>
            <li>
              Al confirmar tu pago, el cajero te entregará un comprobante impreso.{' '}
              <strong>En el podrás verificar que se haya realizado correctamente.</strong>{' '}
              Conserva este comprobante de pago.
            </li>
          </ol>
          <div className='opps-footnote'>
            Al completar estos pasos recibirás un correo de{' '}
            <strong>Fundación Solidaria</strong> confirmando tu pago.
          </div>
          <img
            src={barcode}
            alt='barcode'
            className='d-block mb-0'
            style={{ margin: '20px auto', width: 130 }}
          />
        </div>
      </div>
    </Fragment>
  )
}

OxxoPayStub.defaultProps = {
  amount: 100,
  reference: '0000-0000-0000-00'
}
