import React from 'react'
import logo from '../../../assets/img/logos/3iconos-solidaria-verde.svg'
import styles from './loading.module.css'

export default function LoadingPageOverlay() {
  return (
    <div className={styles['overlay-init']}>
      <div className={styles['wrap-overlay']}>
        <img
          src={logo}
          className={styles['blink']}
          style={{ height: 'auto', width: '50%' }}
          alt=''
        />
      </div>
      <ul className={styles['straps-pos']}>
        <li className={styles['li']}>
          <div className={styles['animate-me']}></div>
        </li>
        <li className={styles['li']}>
          <div className={styles['animate-me']}></div>
        </li>
        <li className={styles['li']}>
          <div className={styles['animate-me']}></div>
        </li>
      </ul>
    </div>
  )
}
