import axios from 'axios'
import swal from '@sweetalert/with-react'
import { isEmpty } from '../../util/isEmpty'
import {
  GET_ERRORS,
  GET_CONVOCATORY,
  POST_CONVOCATORY,
  UPDATE_CONVOCATORY,
  GET_All_CONVOCATORY,
  EDIT_CONVOCATORY,
  GET_CLEAN_CONVOCATORY,
  EDIT_CLEAN_CONVOCATORY,
  GET_All_CLEAN_CONVOCATORY,
  GET_SUBSCRIBERS_CONVOCATORY,
  GET_CLEAN_SUBSCRIBERS_CONVOCATORY
} from './types'
import { loadingPage } from './loadingpageAction'
import { currentStatus, isClosed, removeCharFromDate, getStatus } from './../../components/Campaign/Helpers/projectStatus'

// GET ALL CONVOCATORY ADMIN
export const getAllConvocatoryAdmin = intl => dispatch => {
  dispatch(loadingPage())
  axios
    .get('/api/convocatoryadmin')
    .then(res => {
      const payload = res.data.map(r => ({
        ...r,
        status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(r.startDate, r.endDate, false, r.active)
      }))
      dispatch({ type: GET_All_CONVOCATORY, payload })
    })
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET ALL CONVOCATORY (USER)
export const getAllConvocatoryUser = intl => dispatch => {
  dispatch(loadingPage())
  axios
    .get('/api/convocatory')
    .then(res => {
      const payload = res.data.map(r => ({
        ...r,
        status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(r.startDate, r.endDate, false, r.active)
      }))
      dispatch({ type: GET_All_CONVOCATORY, payload })
    })
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET A CONVOCATORY
export const getConvocatory = (id, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .get(`/api/convocatory/${id}`)
    .then(r => {
      const payload = {
        ...r.data,
        status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(r.data.startDate, r.data.endDate, false, r.data.active)
      }
      dispatch({ type: GET_CONVOCATORY, payload })
    })
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// EDIT A CONVOCATORY
export const editConvocatory = (id, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .get(`/api/convocatory/${id}`)
    .then(r => dispatch({ type: EDIT_CONVOCATORY, payload: r.data }))
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// POST CONVOCATORY
export const postConvocatory = (d, history, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .post(`/api/convocatory`, d)
    .then(r => dispatch({ type: POST_CONVOCATORY, payload: r.data }))
    .then(() => swal({ title: intl.formatMessage({ id: 'alert.convocaCreate' }), text: '', icon: 'success', closeOnClickOutside: false, closeOnEsc: false }))
    .then(() => dispatch(loadingPage()))
    .then(go => go ? history.push('/convocatory/gallerie') : '')
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))

}

// UPDATE CONVOCATORY
export const updateConvocatory = (id, payload, history, intl) => dispatch => {
  swal({
    title: intl.formatMessage({ id: 'alert.desTitle' }),
    icon: 'warning',
    buttons: {
      error: {
        text: intl.formatMessage({ id: 'alert.causeErrorText' }),
        value: intl.formatMessage({ id: 'alert.causeErrorText' })
      },
      actualizacion: {
        text: intl.formatMessage({ id: 'alert.upText' }),
        value: intl.formatMessage({ id: 'alert.upText' })
      }
    }
  }).then(cause => {
    if(!isEmpty(cause)){
      swal({ title: intl.formatMessage({ id: 'alert.desTitle' }), content: "input", buttons: true })
        .then(justification => {
          if (!isEmpty(justification)) {
            const detail = {
              cause,
              description: justification
            }
            const d = { detail, payload }

            dispatch(loadingPage())
            axios
              .put(`/api/convocatory/${id}`, d)
              .then(r => dispatch({ type: UPDATE_CONVOCATORY, payload: r.data }))
              .then(dispatch(loadingPage()))
              .then(() => swal({ title: intl.formatMessage({ id: 'alert.changes' }), text:'', icon: 'success', closeOnClickOutside: false, closeOnEsc: false }))
              .then(go => go ? history.push('/dashboard/convocatory/list') : '')
              .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
          } else {
            swal(intl.formatMessage({ id: 'alert.changesOff' }), intl.formatMessage({ id: 'alert.writeCause' }), 'info')
          }
        }).catch(() => console.log(`no agrego justificacion`))
      }
  })
}

// ACITVATE/DEACTIVATE CONVOCATORY
export const activateConvocatory = (id, payload, history, intl) => dispatch => {
  swal({
    title: intl.formatMessage({ id: 'alert.confiTitle' }),
    text: `${intl.formatMessage({ id: 'alert.confiText' })} ${payload.active ? intl.formatMessage({ id: 'alert.on' }) : intl.formatMessage({ id: 'alert.off' })}`,
    icon: 'warning',
    buttons: true
  })
    .then(confirmation => {
      if (confirmation === true) {
        swal({ title: intl.formatMessage({ id: 'alert.desTitle' }), content: "input" })
          .then(justification => {
            if (!isEmpty(justification)) {
              const detail = {
                cause: `${payload.active ? intl.formatMessage({ id: 'alert.onCap' }) : intl.formatMessage({ id: 'alert.offCap' })}`,
                description: justification
              }
              const d = { payload, detail }
              dispatch(loadingPage())
              axios
                .put(`/api/convocatory/${id}`, d)
                .then(r => {
                  const payload = {
                    ...r.data,
                    status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(r.data.startDate, r.data.endDate, false, r.data.active)
                  }
                  dispatch({ type: UPDATE_CONVOCATORY, payload })
                })
                .then(dispatch(loadingPage()))
                .then(() => swal({ title: '', text: d.payload.active ? intl.formatMessage({ id: 'alert.registerActivate' }) : intl.formatMessage({ id: 'alert.registerDeactivate' }), icon: 'success' }))
                .then(go => go ? history.push('/dashboard/convocatory/list') : '')
                .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
            } else {
              swal(intl.formatMessage({ id: 'alert.changesOff' }), intl.formatMessage({ id: 'alert.writeCause' }), 'info')
            }
          })
      }
    })
    .catch(error => console.log(error))
}


// UPDATE CONVOCATORY
export const getSubscribersConvocatory = id => dispatch => {
  dispatch(loadingPage())
  axios
    .get(`/api/convocatory/${id}`)
    .then(r => dispatch({ type: GET_SUBSCRIBERS_CONVOCATORY, payload: r.data }))
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

export const unsubscribeConvocatory = (id, d, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .put(`/api/convocatory/unsubscribe/${id}`, d)
    .then(r => dispatch({ type: GET_SUBSCRIBERS_CONVOCATORY, payload: r.data }))
    .then(() => swal(intl.formatMessage({ id: 'alert.delete' }), intl.formatMessage({ id: 'alert.suscribeDelete' }), 'success'))
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

export const contactConvocatory = (id, d, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .put(`/api/convocatory/contact/${id}`, d)
    .then(r => dispatch({ type: GET_SUBSCRIBERS_CONVOCATORY, payload: r.data }))
    .then(() => swal(intl.formatMessage({ id: 'table.update' }), '', 'success'))
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// CLEAN A CONVOCATORY VIEW FROM THE STATE
export const getCleanConvocatory = () => ({
  type: GET_CLEAN_CONVOCATORY,
  payload: {}
})

// CLEAN A CONVOCATORY VIEW FROM THE STATE
export const editCleanConvocatory = () => ({
  type: EDIT_CLEAN_CONVOCATORY,
  payload: {}
})

// CLEAN THE CONVOCATORY LIST FROM THE STATE
export const getAllCleanConvocatory = () => ({
  type: GET_All_CLEAN_CONVOCATORY,
  payload: []
})

// CLEAN THE CONVOCATORY SUBSCRIBERS NODE FROM THE STATE
export const getCleanSubscribersConvocatory = () => ({
  type: GET_CLEAN_SUBSCRIBERS_CONVOCATORY,
  payload: {}
})

