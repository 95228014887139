import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  getAllCampaingUser,
  getCampaing,
  getCleanCampaing,
  getAllCleanCampaing,
  getAllCampaingHistorical
} from '../../redux/actions/campaingActions'
import { filterText, evaluateBetween, applyFilters } from './../../util/filterHelpers'
import { Container } from 'reactstrap'
import axios from 'axios'
import Gallerie from '../Main/Layout/Gallerie'
import { isEmpty } from './lib/helpers'
import Pagination from './../common/Pagination/Pagination'
import Filters from './component/Filters/Filters'
import styles from '../Main/Common/Cards/cardNew.module.css'
import { FormattedMessage } from 'react-intl'
import Card from '../Main/Common/Cards/CardNew'

export class CampaingGallerie extends Component {
  // La propiedad filtered guarda los elementos filtrados
  state = {
    filters: {
      title: { type: 'text', value: '' },
      type: { type: 'text', value: '' },
      subsede: { type: 'nestedId', value: '' },
      startDate: { type: 'date', value: '' },
      endDate: { type: 'date', value: '' }
    },
    filtered: [],
    subsedes: []
  }

  componentDidMount() {
    const { path } = this.props.match
    const route = path.split('/')
    const view = route[route.length - 1]
    if (view === 'gallerie') {
      this.props.getAllCampaingUser(this.props.intl)
    } else if (view === 'historical') {
      this.props.getAllCampaingHistorical(this.props.intl)
    }

    axios
      .get('/api/subsede')
      .then(res => this.setState({ subsedes: res.data }))
      .catch(e => this.setState({ subsedes: [] }))
  }

  componentWillReceiveProps(np) {
    if (!isEmpty(np.campaing.view)) {
      const id = np.campaing.view._id
      this.props.history.push(`/campaign/gallerie/${id}`)
    }
    if (!isEmpty(np.campaing.list)) {
      this.setState({ filtered: np.campaing.list })
    }
  }

  componentWillUnmount() {
    // Limpia el nodo campaing -> list del estado global de la aplicación
    this.props.getAllCleanCampaing()
  }

  // Handle para guardar los filtros:
  // título, tipo, rango de fechas(fecha inicio, fecha fin) en el estado
  onChange = event => {
    const { name, value } = event.target
    this.setState(s => ({
      ...s,
      filters: { ...s.filters, [name]: { ...s.filters[name], value } }
    }))
  }

  // Handle para aplicar los filtros configurados
  handleSubmit = event => {
    event.preventDefault()
    const { list } = this.props.campaing
    const { filters } = this.state
    const filtered = applyFilters(filterText, evaluateBetween)(list, filters, [
      'startDate',
      'endDate'
    ])
    this.setState({ filtered })
  }

  render() {
    const { filtered, filters, subsedes } = this.state
    const { formatMessage } = this.props.intl

    return (
      <Gallerie>
        <Container>
          {/* Gallery's title */}
          <h1 className={`${styles['card__gallerie-title']} mx-3`}>
            <FormattedMessage id='dashboard.gallProjects' />
          </h1>

          {/* Gallery's filters */}
          <Filters
            {...this}
            filters={filters}
            formatMessage={formatMessage}
            subsedes={subsedes}
          />

          {/* Gallery content, including paginator */}
          <Pagination
            data={filtered}
            route='campaign/gallerie'
            sizePerPage={6}
            component={Card}
          />
        </Container>
      </Gallerie>
    )
  }
}

CampaingGallerie.propTypes = {
  auth: PropTypes.object.isRequired,
  campaing: PropTypes.object.isRequired,
  subsedes: PropTypes.object.isRequired,
  getAllCampaingUser: PropTypes.func.isRequired,
  getCampaing: PropTypes.func.isRequired,
  getCleanCampaing: PropTypes.func.isRequired,
  getAllCleanCampaing: PropTypes.func.isRequired
}
const mapStateToProps = ({ auth, campaing, subsedes }) => ({ auth, campaing, subsedes })
const mapDispatchToProps = {
  getAllCampaingUser,
  getCampaing,
  getCleanCampaing,
  getAllCleanCampaing,
  getAllCampaingHistorical
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaingGallerie))
