import { isEmpty } from '../../util/isEmpty'
import { SOCKET_AUTH, SOCKET_CONNECTION, SOCKET_DISCONNECT, SOCKET_DASHBOARD } from '../actions/types'

const initialState = {
  id: null,
  sockets: {
    connections: {
      all: [],
      others: [],
      admins: [],
      softtekians: [],
    },
    count: 0
  },
  charts: {
    line: {},
    pie: {}
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SOCKET_CONNECTION:
      return {
        ...state,
        id: action.payload.id,
        sockets: {
          ...state.sockets,
          connections: {
            ...state.sockets.connections,
            all: [...action.payload.sockets.connections],
            others: [...action.payload.sockets.connections].filter(c => isEmpty(c.user.rol)),
            admins: [...action.payload.sockets.connections].filter(c => (!isEmpty(c.user.rol) && c.user.rol !== 'softtekian')),
            softtekians: [...action.payload.sockets.connections].filter(c => (!isEmpty(c.user.rol) && c.user.rol === 'softtekian')),
          },
          count: action.payload.sockets.count
        }
      }
    case SOCKET_AUTH:
      return {
        ...state,
        sockets: {
          ...state.sockets,
          connections: {
            ...state.sockets.connections,
            all: [...action.payload.sockets.connections],
            others: [...action.payload.sockets.connections].filter(c => isEmpty(c.user.rol)),
            admins: [...action.payload.sockets.connections].filter(c => (!isEmpty(c.user.rol) && c.user.rol !== 'softtekian')),
            softtekians: [...action.payload.sockets.connections].filter(c => (!isEmpty(c.user.rol) && c.user.rol === 'softtekian')),

          },
          count: action.payload.sockets.count
        }
      }
    case SOCKET_DISCONNECT:
      return {
        ...state,
        sockets: {
          ...state.sockets,
          connections: {
            ...state.sockets.connections,
            all: [...action.payload.sockets.connections],
            others: [...action.payload.sockets.connections].filter(c => isEmpty(c.user.rol)),
            admins: [...action.payload.sockets.connections].filter(c => (!isEmpty(c.user.rol) && c.user.rol !== 'softtekian')),
            softtekians: [...action.payload.sockets.connections].filter(c => (!isEmpty(c.user.rol) && c.user.rol === 'softtekian')),
          },
          count: action.payload.sockets.count
        }
      }
    case SOCKET_DASHBOARD:
      return {
        ...state,
        charts: {
          ...state.charts,
          ...action.payload
        }
      }
    default:
      return state
  }
}