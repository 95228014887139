import axios from 'axios'
import swal from '@sweetalert/with-react'
import {
  GET_All_DONATION,
  GET_ERRORS,
  EDIT_DONATION,
  POST_DONATION,
  UPDATE_DONATION,
  GET_All_DONATION_PROJECTS,
  // APPROVE_DONATION,
  DELETE_DONATION
} from './types'
import { loadingPage } from './loadingpageAction'
import { isEmpty } from '../../util/isEmpty'
import socket from './../../socket'

// POST GENERAL DONATION
export const postDonation = (payload, intl, cb = null) => dispatch => {
  dispatch(loadingPage())
  axios
    .post(`/api/generaldonation`, payload)
    .then(dispatch(loadingPage()))
    .then(() => (cb ? cb() : ''))
    .then(() => swal(intl.formatMessage({ id: 'alert.donationTh' }), '', 'success'))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// POST PROJECT DONATION
export const projectDonationPayPal = (payload, intl, fn = null) => dispatch => {
  dispatch(loadingPage())
  axios
    .post(`/api/donations/payPal`, payload)
    .then(dispatch(loadingPage()))
    .then(() => (fn ? fn() : ''))
    .then(() => swal(intl.formatMessage({ id: 'alert.donationTh' }), '', 'success'))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// POST PROJECT DONATION
export const projectDonationCreditCard = (payload, intl, fn = null) => dispatch => {
  dispatch(loadingPage())
  axios
    .post(`/api/projectdonation/credit-card`, payload)
    .then(dispatch(loadingPage()))
    .then(() => (fn ? fn() : ''))
    .then(() => swal(intl.formatMessage({ id: 'alert.donationTh' }), '', 'success'))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// ADMINISTRATOR ACTIONS

// Get all the donations' records
export const getAllDonation = () => dispatch => {
  dispatch(loadingPage())
  axios
    .get('/api/donation')
    .then(r => dispatch({ type: GET_All_DONATION, payload: r.data }))
    .then(dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// Get the donation's data to edit
export const editDonation = payload => dispatch => {
  dispatch({ type: EDIT_DONATION, payload })
}

// Add a manual donation
export const addDonation = (p, intl, cb) => dispatch => {
  dispatch(loadingPage())
  axios
    .post('/api/donation', p)
    .then(r => dispatch({ type: POST_DONATION, payload: r.data }))
    .then(dispatch(loadingPage()))
    .then(() => cb())
    .then(() => swal(intl.formatMessage({ id: 'alert.donationAdd' }), '', 'success'))
    .catch(error => {
      const { response } = error
      if (response.status === 400 && response.data && response.data.label) {
        swal({
          text:
            intl.formatMessage({ id: response.data.label }) +
            (p.currency !== 'USD' ? ` / ${response.data.limit} ${p.currency}` : ''),
          icon: 'warning'
        })
      }
      dispatch({ type: GET_ERRORS, payload: error })
    })
}

// Update a donation record
export const updateDonation = (id, d, intl, cb) => dispatch => {
  swal({
    title: intl.formatMessage({ id: 'alert.desTitle' }),
    icon: 'warning',
    buttons: {
      error: {
        text: intl.formatMessage({ id: 'alert.causeErrorText' }),
        value: intl.formatMessage({ id: 'alert.causeErrorText' })
      },
      actualizacion: {
        text: intl.formatMessage({ id: 'alert.upText' }),
        value: intl.formatMessage({ id: 'alert.upText' })
      }
    }
  }).then(cause => {
    if (!isEmpty(cause)) {
      function updateDonationPromise() {
        swal({
          title: intl.formatMessage({ id: 'alert.desTitle' }),
          content: 'input',
          buttons: true
        })
          .then(justification => {
            if (!isEmpty(justification)) {
              const detail = { cause, description: justification }
              const payload = { payload: { ...d }, detail }
              dispatch(loadingPage())
              axios
                .put(`/api/donation/${id}`, payload)
                .then(r => dispatch({ type: UPDATE_DONATION, payload: r.data }))
                .then(dispatch(loadingPage()))
                .then(() => cb())
                .then(() =>
                  swal({
                    title: intl.formatMessage({ id: 'alert.changes' }),
                    text: '',
                    icon: 'success'
                  })
                )
                .catch(error => {
                  const { response } = error
                  if (response.status === 400 && response.data && response.data.label) {
                    swal({
                      text:
                        intl.formatMessage({ id: response.data.label }) +
                        (d.currency !== 'USD'
                          ? ` / ${response.data.limit} ${d.currency}`
                          : ''),
                      icon: 'warning'
                    })
                  }
                  dispatch({ type: GET_ERRORS, payload: error })
                })
            } else {
              swal(
                intl.formatMessage({ id: 'alert.changesOff' }),
                intl.formatMessage({ id: 'alert.writeCause' }),
                'info'
              )
            }
          })
          .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
      }

      if (d.currencyChanged)
        swal({
          title: intl.formatMessage({ id: 'alert.confiTitle' }),
          text: intl.formatMessage({ id: 'label.currencyChange' }),
          icon: 'warning',
          buttons: true
        }).then(confirmation => {
          if (confirmation) updateDonationPromise()
        })
      else updateDonationPromise()
    }
  })
}

// Delete a donation (it has to be not approved)
export const deleteDonation = (id, intl) => dispatch => {
  swal({
    title: intl.formatMessage({ id: 'alert.confiTitle' }),
    text: intl.formatMessage({ id: 'alert.registryDelete' }),
    icon: 'warning',
    buttons: true,
    dangerMode: true
  }).then(willUpdate => {
    if (willUpdate) {
      swal({
        title: intl.formatMessage({ id: 'alert.desTitle' }),
        content: 'input',
        buttons: true
      }).then(desc => {
        if (!isEmpty(desc)) {
          dispatch(loadingPage())
          axios
            .delete(`/api/donation/${id}`, { params: { id } })
            .then(r => dispatch({ type: DELETE_DONATION, payload: r.data }))
            .then(dispatch(loadingPage()))
            .then(() => swal(intl.formatMessage({ id: 'alert.delete' }), { icon: 'success' }))
            .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
        }
      })
    }
  })
}

// Approve a manual donation
export const approveDonation = (id, intl) => dispatch => {
  swal({
    title: intl.formatMessage({ id: 'alert.donationApprove' }),
    content: 'input',
    buttons: true
  }).then(desc => {
    // If the user clicks on OK and type on the input for justification
    if (!isEmpty(desc)) {
      swal({
        title: intl.formatMessage({ id: 'alert.desTitle' }),
        text: intl.formatMessage({ id: 'alert.donationReverse' }),
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then(willUpdate => {
        if (willUpdate) {
          const payload = {
            payload: { id },
            detail: {
              cause: intl.formatMessage({ id: 'table.approved' }),
              description: desc
            }
          }

          axios
            .put('/api/donation/approve', payload)
            .then(r => {
              socket.emit('dashboard', {})
              dispatch({ type: UPDATE_DONATION, payload: r.data })
              swal(intl.formatMessage({ id: 'alert.approveRegistry' }), {
                icon: 'success'
              })
            })
            .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
        }

        // If the user clicks on OK but doesn't type anything on the input for justification
        else if (desc === '') {
          swal(
            intl.formatMessage({ id: 'alert.changesOff' }),
            intl.formatMessage({ id: 'alert.writeCause' }),
            'info'
          )
        }
      })
    }
  })
}

// Get the projects than can be donated
export const getAllDonationProjects = () => dispatch => {
  axios
    .get('/api/project')
    .then(r => {
      const payload = r.data.filter(d => d.donation)
      dispatch({ type: GET_All_DONATION_PROJECTS, payload })
    })
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// Clean nodes of the donation property
export const getAllCleanDonation = () => dispatch => {
  dispatch({ type: GET_All_DONATION, payload: [] })
}

export const getCleanEditDonation = () => dispatch => {
  dispatch({ type: EDIT_DONATION, payload: {} })
}
