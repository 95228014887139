import React from 'react'
import { FormattedMessage } from 'react-intl'

export default  [
  {
    title: <FormattedMessage id="nav.about" />,
    links: [
      {
        label: <FormattedMessage id="nav.value" />,
        link: '/ourvalues'
      },
      {
        label: <FormattedMessage id="nav.programs" />,
        link: '/ourprograms'
      },
      {
        label: <FormattedMessage id="nav.projects" />,
        link: '/ourprojects'
      }
    ] 
  },
  {
    title: <FormattedMessage id="nav.publications" />,
    links: [
      {
        label: <FormattedMessage id="nav.news" />,
        link: '/news/gallerie'
      },
      {
        label: <FormattedMessage id="nav.convocatory" />,
        link: '/convocatory/gallerie'
      },
      {
        label: <FormattedMessage id="nav.gallerie" />,
        link: '/campaign/gallerie'
      },
      {
        label: <FormattedMessage id="nav.historical" />,
        link: '/campaign/historical'
      }
    ] 
  },
  {
    title: <FormattedMessage id="nav.help" />,
    links: [
      {
        label: <FormattedMessage id="nav.dare" />,
        link: '/daretoshare'
      },
      {
        label: <FormattedMessage id="nav.share" />,
        link: '/initiative'
      }
    ]
  }
]