import { addItem, deleteItem, updatedItem } from '../../util/helpers'
import {
  POST_CARROUSEL,
  GET_ALL_CARROUSEL_USER,
  GET_ALL_CARROUSEL_ADMIN,
  GET_OPTIONS_CARROUSEL,
  ACTIVATE_CARROUSEL,
  DEACTIVATE_CARROUSEL
} from './../actions/types'

const initialState = {
  view: {},
  list: [],
  listAdmin: [],
  select: []
}

// EXPLICACIÓN
// Esta función es de ayuda cuando un administrador desea activar un proyecto
// en el carrusel, sí ya hay uno en su subsede este se actualizará con el nuevo,
// de lo contrario, se agregará a la lista/slides (PÚBLICOS) como uno nuevo
const updateCarrousel = (list, item, param) => {
  const index = list.findIndex(i => i['project'][param] === item['project'][param])
  if (!isNaN(index) && index !== -1) {
    return [...list.slice(0, index), item, ...list.slice(index + 1)]
  } else {
    return [...list, item]
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CARROUSEL_USER:
      return {
        ...state,
        list: [...action.payload]
      }
    case GET_ALL_CARROUSEL_ADMIN:
      return {
        ...state,
        listAdmin: [...action.payload]
      }
    case POST_CARROUSEL:
      return {
        ...state,
        list: addItem(state.list, action.payload)
      }
    case GET_OPTIONS_CARROUSEL:
      return {
        ...state,
        select: [...action.payload]
      }
    case ACTIVATE_CARROUSEL:
      return {
        ...state,
        list: updateCarrousel(state.list, action.payload, 'subsede'),
        listAdmin: updatedItem(state.listAdmin, action.payload)
      }
    case DEACTIVATE_CARROUSEL:
      return {
        ...state,
        list: deleteItem(state.list, action.payload._id),
        listAdmin: updatedItem(state.listAdmin, action.payload)
      }
    default:
      return state
  }
}
