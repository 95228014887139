import React from 'react'

// Componente para 'wrappear' una tabla, homologada con los demás catalogos del
// panel de administradores
// PROPS: title, label, children
export default props => {
  return (
    <div className='container-fluid'>
      <h1 className='h3 mb-2 text-gray-800'>{props.title}</h1>
      <div className='row justify-content-center'>
        <div className='card shadow mt-3 mb-4' style={{ width: '100%' }}>
          <div className='card-header py-3'>
            <h6 className='m-0 mb-1 font-weight-bold text-primary'>{props.label}</h6>
          </div>
          <div className='card-body table-responsive'>{props.children}</div>
        </div>
      </div>
    </div>
  )
}
