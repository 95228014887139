import React from 'react'
import educacion from './img/programas-02.svg'
import { FormattedMessage } from 'react-intl'

const ProgramsEducation = () => (
  <div className='row'>
    <div className=' mx-auto col-md-2 mb-lg-0'>
      <img className='img-fluid rounded-circle mb-3 iconsPrograms' src={educacion} alt='' />
    </div>
    <div className=' mx-auto col-md-10 mb-lg-0'>
      <h4 className='txtPrograms'>
        <FormattedMessage id='programs.edu' />
      </h4>
      <p className='contentPrograms'>
        <FormattedMessage id='programs.eduTxt' />
      </p>
    </div>
  </div>
)

export default ProgramsEducation
