import validator from 'validator'
import { isEmpty } from '../../../util/isEmpty'
import msg from './../../../lang/message'
const RolValidations = [
  {
    field: 'email',
    function(value, state = null) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, msg.requerido] }
      } else if (!validator.isEmail(value)) {
        check = { ...check, feed: [...check.feed, msg.email] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  },
  {
    field: 'is',
    function(value, state = null) {

      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, msg.requerido] }
      } else if (value.length < 4) {
        check = { ...check, feed: [...check.feed, msg.min4] }
      } else if (value.length > 7) {
        check = { ...check, feed: [...check.feed, msg.max7] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  },
  {
    field: 'phone',
    function(value) {

      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, msg.requerido] }
      } else if (!Number(value)) {
        check = { ...check, feed: [...check.feed, msg.numero] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  },
  {
    field: 'rol',
    function(value, state = null) {

      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, msg.requerido] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  },
  {
    field: 'subsede',
    function(value, state = null) {

      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, msg.requerido] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  }
]
export default RolValidations