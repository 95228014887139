import React from 'react'
import { FormattedMessage } from 'react-intl'
import { sorting, dmy } from './dateHelpers'
import { isEmpty } from './helpers'

export const detailRow = {
  // Table que muestra el registro historico de modificaciones
  renderer: row =>
    !isEmpty(row.detail) && (
      <table style={{ width: '100%', backgroundColor: 'whitesmoke' }}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id='modal.cause' />
            </th>
            <th>
              <FormattedMessage id='label.description' />
            </th>
            <th>
              <FormattedMessage id='general.date' />
            </th>
            <th>
              <FormattedMessage id='general.user' />
            </th>
          </tr>
        </thead>
        <tbody>
          {sorting(row.detail).map(d => (
            <tr>
              <td>{d.cause}</td>
              <td>{d.description}</td>
              <td>{dmy(d.createdAt)}</td>
              <td>{!isEmpty(d.created_by) ? d.created_by.name : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
}
