import moment from 'moment'

export const dmyShort = (date) =>
	moment(Boolean(date) ? new Date(date).toISOString().split('T')[0] : date).format('M/DD/YY')
export const dmy = (date) =>
	moment(Boolean(date) ? new Date(date).toISOString().split('T')[0] : date).format('MM/DD/YYYY')
export const ymd = (date) => moment(date).format('YYYY-MM-DD')
export const mdyhm = (date) => moment(date).format('DD/MM/YYYY, hh:mm A')
export const fromNow = (date) => moment(date).fromNow()
// Helper to sort in descending order an array of objects with the property createAt (Date)
export const sorting = (arr) => arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
// Helper to conver to ISO 8601 to YYYY-MM-DD
export const ISOtoStandard = (date) => moment(date).add(1, 'days').format('YYYY-MM-DD')

// Devuelve el día de ahora en el formato "YYYY-MM-DD"
export const today = () => moment().format('YYYY-MM-DD')

// Devuelve el día de mañana en el formato "YYYY-MM-DD"
export const tomorrow = () => moment().add(1, 'days').format('YYYY-MM-DD')

// Devuelve boolean si es la misma fecha o antes de la fecha de hoy.
export const isSameOrBeforeToday = (date) => !moment(today()).isSameOrBefore(date)

// Devuelve boolean si es la misma fecha o antes de la fecha @param.
export const isSameOrBefore = (startDate, date) => !moment(startDate).isSameOrBefore(date)

// Devuelve boolean si es la misma fecha o es la misma o despues de la fecha @param.
export const isSameOrAfter = (endDate, date) => !moment(endDate).isSameOrAfter(date)

// Devuelve boolean si el @param esta dentro del rango de las fechas.
export const isBetween = (startDate, endDate, date) => !moment(date).isBetween(startDate.value, endDate.value)
