import { isEmpty } from './../../../../util/isEmpty'

export default [
  {
    field: 'is',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: ['Este campo es requerido'] }
      } else if (value.length< 4) {
        check = { ...check, feed: ['El IS debe ser mayor o igual a cuatro caracteres'] }
      } else {
        check = { valid: true, text: '', feed: ['Correcto'] }
      }
      const result = { [this.field]: { ...check, value } }
      return result
    }
  },
  {
    field: 'phone',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: ['Este campo es requerido'] }
      } else if (value.length < 8) {
        check = { ...check, feed: ['Ingrese un número telefonico válido'] }
      } else {
        check = { valid: true, text: '', feed: ['Correcto'] }
      }
      const result = { [this.field]: { ...check, value } }
      return result
    }
  },
  {
    field: 'subsede',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: ['Este campo es requerido'] }
      } else {
        check = { valid: true, text: '', feed: ['Correcto'] }
      }
      const result = { [this.field]: { ...check, value } }
      return result
    }
  }
]