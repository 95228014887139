import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'

export class ProjectStatus extends Component {
  // que falta ?
  // 1.- traernos status de multi lenguaje
  // 2.- funcion que reciba las fechas y nos marque en que estatus estamos.
  // 2.1 funcion que quite los seros 20-12-19:T00:00:00Z
  // 2.2 funcion que retorne el string del status.
  // 3.- Renderiar el status.

  render() {
    return (
      <span className='badge statusProject'>
        <FormattedMessage id={this.props.status} />
      </span>
    )
  }
}

ProjectStatus.propTypes = {
  campaing: PropTypes.object.isRequired
}
const mapStateToProps = ({ campaing }) => ({ campaing })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProjectStatus))