import axios from 'axios'
import { SET_LOCATION, SET_LANGUAGE_MANUAL, SET_SUBSEDES, GET_ERRORS } from './types'
import { isEmpty } from '../../util/isEmpty'
import { initConekta } from '../../util/conekta'
import { decryptionAES } from '../../util/encryption'
import config from '../../config'

// @params     payload (object)
// @desc       Redux action to activate the SET_LOCATION reducer
export const setLocation = payload => ({ type: SET_LOCATION, payload })

// @params     IP address v4.0 (string)
// @desc       Use the IP to make a HTTP POST request to /api/location in order
//             to obtain the geolocation object (created by the ipify API), then
//             dispatch the getSede() action
//@return      void
export const getLocation = ip => dispatch => {
  axios
    .post('/api/location', { ip })
    .then(response => {
      const location = response.data
      dispatch(getSede(location))
    })
    .catch(error => {
      dispatch({ type: GET_ERRORS, payload: error })
    })
}

// @params     geolocation (object)
// @desc       Use the property location.country of the geolocation object
//             to make a HTTP POST request to /api/inputs/regioncode in order
//             to obtain the Softtek sede which belongs to that country.
//             Then dispatch the setSubsedes() and setLocation() actions.
//@return      void
export const getSede = geolocation => dispatch => {
  const {
    location: { country }
  } = geolocation

  axios.post('/api/inputs/regioncode', { countryCode: country }).then(response => {
    if (response.data) {
      // Obtain the sede of the HTTP previous response
      const sede = response.data
      const { subsedes, languageCode } = sede

      if (!isEmpty(sede.conekta.clientId)) {
        initConekta(decryptionAES(sede.conekta.clientId, config.secret))
      }

      // Set the subsedes in the three state
      dispatch(setSubsedes(subsedes))

      // Delete the subsedes property, since they are no longer required
      delete sede.subsedes

      // Group the object to be stored in the redux three's "location" node
      const payload = { sede, geolocation, languageCode }

      // Set the location node
      dispatch(setLocation(payload))
    }
  })
}

// @params     subsedes (array)
// @desc       Action's creator to format the subsedes array and
//             return them in a redux's action format with the type
//             SET_SUBSEDES
//@return      object
export const setSubsedes = subsedes => {
  const options = subsedes.map(s => ({ label: s.name, value: s._id }))
  const payload = { list: subsedes, options }
  return { type: SET_SUBSEDES, payload }
}

// @params     payload (object)
// @desc       Action to set the language of the application according
//             to the payload object values
//@return      void
export const setLanguageManual = payload => dispatch => {
  dispatch({ type: SET_LANGUAGE_MANUAL, payload })
}
