import React from 'react'

export default function ArrowSVG(props) {
  return (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 336 336' {...props}>
    <path className='st0' d='M152.2,82.6c-5-5-13.3-5-18.3,0s-5,13.3,0,18.3l0,0l65.8,65.8l-65.9,65.9c-5,5-5,13.2,0,18.3 c2.4,2.4,5.7,3.8,9.1,3.8l0,0c3.5,0,6.7-1.3,9.1-3.8l74.9-74.9c1.6-1.6,2.8-3.7,3.4-6c0.3-1.1,0.4-2.2,0.4-3.3 c0-3.4-1.4-6.7-3.8-9.1L152.2,82.6z' />
    <path className='st0' d='M168,16C84.2,16,16,84.2,16,168s68.2,152,152,152s152-68.2,152-152S251.8,16,168,16z M168,299.1 c-72.3,0-131.1-58.8-131.1-131.1S95.7,36.9,168,36.9S299.1,95.7,299.1,168S240.3,299.1,168,299.1z' />
  </svg>
  )
}
