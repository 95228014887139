import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './holiday-project.helpers'

const { Types, Creators } = createActions(
  {
    publicLoadAllHolidayProjectRequest: ['payload'],
    publicLoadAllHolidayProjectSuccess: ['payload'],
    publicLoadAllHolidayProjectFailure: ['payload'],

    publicLoadHolidayProjectRequest: ['payload'],
    publicLoadHolidayProjectSuccess: ['payload'],
    publicLoadHolidayProjectFailure: ['payload'],

    publicDonateHolidayProjectRequest: ['payload'],
    publicDonateHolidayProjectSuccess: ['payload'],
    publicDonateHolidayProjectFailure: ['payload'],

    loadAllHolidayProjectRequest: ['payload'],
    loadAllHolidayProjectSuccess: ['payload'],
    loadAllHolidayProjectFailure: ['payload'],

    loadHolidayProjectRequest: ['payload'],
    loadHolidayProjectSuccess: ['payload'],
    loadHolidayProjectFailure: ['payload'],

    createHolidayProjectRequest: ['payload'],
    createHolidayProjectSuccess: ['payload'],
    createHolidayProjectFailure: ['payload'],

    editHolidayProjectRequest: ['payload'],
    editHolidayProjectSuccess: ['payload'],
    editHolidayProjectFailure: ['payload'],

    updateHolidayProjectRequest: ['payload'],
    updateHolidayProjectSuccess: ['payload'],
    updateHolidayProjectFailure: ['payload'],

    toggleHolidayProjectRequest: ['payload'],
    toggleHolidayProjectSuccess: ['payload'],
    toggleHolidayProjectFailure: ['payload'],

    cleanHolidayProject: null
  },
  {}
)

const initialState = {
  list: [],
  view: {},
  edit: {},
  gallery: [],
  individual: {}
}

export function publicLoadAllHolidayProject(state, action) {
  return { ...state, gallery: [...action.payload] }
}

export function publicLoadHolidayProject(state, action) {
  return { ...state, individual: { ...action.payload } }
}

export function loadAllHolidayProject(state, action) {
  return { ...state, list: [...action.payload] }
}

export const HANDLERS = {
  [Types.PUBLIC_LOAD_ALL_HOLIDAY_PROJECT_SUCCESS]: publicLoadAllHolidayProject,
  [Types.PUBLIC_LOAD_HOLIDAY_PROJECT_SUCCESS]: publicLoadHolidayProject,
  [Types.LOAD_ALL_HOLIDAY_PROJECT_SUCCESS]: loadAllHolidayProject,
  [Types.LOAD_HOLIDAY_PROJECT_SUCCESS]: handlers.loadSuccess,
  [Types.CREATE_HOLIDAY_PROJECT_SUCCESS]: handlers.createSuccess,
  [Types.EDIT_HOLIDAY_PROJECT_SUCCESS]: handlers.editSuccess,
  [Types.UPDATE_HOLIDAY_PROJECT_SUCCESS]: handlers.updateSuccess,
  [Types.TOGGLE_HOLIDAY_PROJECT_SUCCESS]: handlers.toggleSuccess,
  [Types.CLEAN_HOLIDAY_PROJECT]: state => ({ ...state, individual: {} })
}

export const types = Types
export const actions = Creators
export const holidayProject = createReducer(initialState, HANDLERS)
