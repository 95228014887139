import React from 'react'
import { Button } from 'reactstrap'
import { isEmpty } from './../../../util/isEmpty'
import { sorting, dmy } from './../../../util/dateHelpers'
import { FormattedMessage } from 'react-intl'
import { textFilter } from 'react-bootstrap-table2-filter'

export const tableOptions = (formatMessage, props) => [
  {
    dataField: 'project.title',
    text: formatMessage({ id: 'modal.project' }),
    filter: textFilter(),
    sort: true
  },
  {
    dataField: 'sede.name',
    text: formatMessage({ id: 'modal.sede' }),
    filter: textFilter(),
    sort: true
  },
  {
    dataField: 'subsede.name',
    text: formatMessage({ id: 'label.subsede' }),
    filter: textFilter(),
    sort: true
  },
  {
    dataField: 'active',
    text: formatMessage({ id: 'label.published' }),
    formatter: cell =>
      cell
        ? formatMessage({ id: 'general.confirmation' })
        : formatMessage({ id: 'general.negation' }),
    sort: true
  },
  {
    dataField: '_id',
    text: formatMessage({ id: 'general.actions' }),
    csvExport: false,
    formatter: (cell, row) => (
      <Button
        color={row.active ? 'danger' : 'success'}
        onClick={() =>
          row.active ? props.handleDeactivate(row._id) : props.handleActivate(row._id)
        }>
        {/* <i className="fas fa-power-off"></i> {cell ? 'Desactivar' : 'Activar'} */}
        <i className='fas fa-power-off'></i>
      </Button>
    )
  }
]

export const expandRow = {
  renderer: row =>
    !isEmpty(row.detail) && (
      <table style={{ width: '100%', backgroundColor: 'whitesmoke' }}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id='table.cause' />
            </th>
            <th>
              <FormattedMessage id='alert.desTitle' />
            </th>
            <th>
              <FormattedMessage id='general.date' />
            </th>
            <th>
              <FormattedMessage id='general.user' />
            </th>
          </tr>
        </thead>
        <tbody>
          {sorting(row.detail).map(d => (
            <tr>
              <td>{d.cause}</td>
              <td>{d.description}</td>
              <td>{dmy(d.createdAt)}</td>
              <td>{!isEmpty(d.created_by) ? d.created_by : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
}
