import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from './holiday-project.redux'
import { isEmpty } from '../../util/helpers'
import HolidayProjectForm from './HolidayProjectForm'
import Admin from '../Layout/Admin/Admin'
import { initialize } from 'redux-form'

class HolidayProjectEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.editHolidayProjectRequest({ id })
  }

  componentWillUnmount() {
    this.props.editHolidayProjectSuccess({})
  }

  componentDidUpdate() {
    this.props.initialize('holiday-project', this.props.holidayProject.edit)
  }

  onCancel = () => {
    this.props.history.push('/dashboard/holiday-project/list')
  }

  submit = values => {
    const { id } = this.props.match.params
    this.props.updateHolidayProject({ id, values })
  }

  render() {
    const values = this.props.holidayProject.edit

    return (
      <Admin>
        <div className='header-h2 col'>
          <h1 className='h3 mb-2 text-gray-800'>Editar campaña</h1>
        </div>
        {!isEmpty(values) && (
          <HolidayProjectForm
            {...this.props}
            label='Actualizar'
            onCancel={this.onCancel}
            onSubmit={this.submit}
            initialValues={values}
          />
        )}
      </Admin>
    )
  }
}

const mapStateToProps = ({ holidayProject }) => ({ holidayProject })
const mapDispatchToProps = {
  updateHolidayProject: actions.updateHolidayProjectRequest,
  editHolidayProjectRequest: actions.editHolidayProjectRequest,
  editHolidayProjectSuccess: actions.editHolidayProjectSuccess,
  initialize
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HolidayProjectEdit)
