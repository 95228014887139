import React from 'react'
import Im1 from './img/proyectos-01.svg'
import Im2 from './img/proyectos-02.svg'
import Im3 from './img/proyectos-03.svg'
import Im4 from './img/proyectos-04.svg'
import styles from './projects-info-section.module.css'
import ProjectIcons from './ProjectIcons/ProjectIcons'
import { FormattedMessage } from 'react-intl'
import ContentWrapper from '../../../common/ContentWrapper/ContentWrapper'

const info = [
  { icon: Im1, text: <FormattedMessage id='weshare.causes1' />, isActive: true },
  { icon: Im2, text: <FormattedMessage id='weshare.causes2' />, isActive: true },
  { icon: Im3, text: <FormattedMessage id='weshare.causes3' />, isActive: true },
  { icon: Im4, text: <FormattedMessage id='weshare.causes4' />, isActive: true }
]

const ProjectsInfoSection = () => (
  <section className={`${styles['fondoProjects']}`}>
    <div className={`text-center container`}>
      <ContentWrapper
        pretitle={<FormattedMessage id='programs.know' />}
        title={<FormattedMessage id='weshare.title' />}
        content={<FormattedMessage id='weshare.txt' />}
      />

      <div className={`container`}>
        <div className='row my-5'>
          {info.map((c, i) => (
            <div className={`col-md-6`}>
              <div className='container-fluid'>
                <ProjectIcons key={c.label + '-' + i} {...c} />
              </div>
            </div>
          ))}
        </div>

        <ContentWrapper
          content={<FormattedMessage id='weshare.exc' />}
          contentClass={`${styles['txtIcons']}`}
        />
      </div>
    </div>
  </section>
)

export default ProjectsInfoSection
