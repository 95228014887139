import { addItem, updatedItem, deleteItem } from '../../util/helpers'
import {
  GET_CAMPAING,
  POST_CAMPAING,
  EDIT_CAMPAING,
  UPDATE_CAMPAING,
  GET_All_CAMPAING,
  GET_CLEAN_CAMPAING,
  GET_CLOSE_CAMPAING,
  GET_REPORT_CAMPAING,
  EDIT_CLEAN_CAMPAING,
  GET_GALLERIE_CAMPAING,
  UPDATE_CLOSE_CAMPAING,
  ADD_GALLERIE_CAMPAING,
  GET_All_CLEAN_CAMPAING,
  GET_VOLUNTEERS_CAMPAING,
  REMOVE_GALLERIE_CAMPAING
} from '../actions/types'

const initialState = {
  list: [],
  view: {},
  edit: {},
  modal: {},
  report: {},
  gallerie: {},
  volunteers: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CAMPAING:
      return {
        ...state,
        view: { ...action.payload }
      }
    case EDIT_CAMPAING:
      return {
        ...state,
        edit: { ...action.payload }
      }
    case POST_CAMPAING:
      return {
        ...state,
        list: addItem(state.list, action.payload)
      }
    case UPDATE_CAMPAING:
      return {
        ...state,
        list: updatedItem(state.list, action.payload)
      }
    case GET_REPORT_CAMPAING:
      return {
        ...state,
        report: {...action.payload}
      }
    case GET_All_CAMPAING:
      return {
        ...state,
        list: [...action.payload]
      }
    case GET_All_CLEAN_CAMPAING:
      return {
        ...state,
        list: [...action.payload]
      }
    case GET_CLEAN_CAMPAING:
      return {
        ...state,
        view: {...action.payload}
      }
    case EDIT_CLEAN_CAMPAING:
      return {
        ...state,
        edit: {...action.payload}
      }
    // Agregar la información de cierre de campaña
    case UPDATE_CLOSE_CAMPAING:
      return {
        ...state,
        list: updatedItem(state.list, action.payload)
      }
    // Cargar en el nodo modal la información de cierre de campaña
    case GET_CLOSE_CAMPAING:
      return {
        ...state,
        modal: {...action.payload}
      }

    // Cargar en el nodo volunteers el proyecto cuyos voluntarios se desean ver
    case GET_VOLUNTEERS_CAMPAING:
      return {
        ...state,
        volunteers: {...action.payload}
      }

    // Cargar en el nodo gallerie el proyecto cuyas imagenes se desean ver
    case GET_GALLERIE_CAMPAING:
      return {
        ...state,
        gallerie: {...action.payload}
      }

    case ADD_GALLERIE_CAMPAING:
      return {
        ...state,
        gallerie: {
          ...state.gallerie,
          gallerie: addItem(state.gallerie.gallerie, action.payload)
        }
      }
    case REMOVE_GALLERIE_CAMPAING:
      return {
        ...state,
        gallerie: {
          ...state.gallerie,
          gallerie: deleteItem(state.gallerie.gallerie, action.payload._id)
        }
      }
    default:
      return state
      
  }
}