import React, { Component } from 'react'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import {
  subscribeCampaing,
  unsubscribeCampaing
} from './../../../../redux/actions/campaingActions'
import { FormattedMessage, injectIntl } from 'react-intl'
import { isEmpty } from './../../Helpers'
import moment from 'moment'
import { Button } from 'reactstrap'

export class VoluntaringBtn extends Component {
  state = { user: null }

  componentDidMount() {
    if (!isEmpty(this.props.auth)) {
      const { user } = this.props.auth
      this.setState({ user: user._id })
    }
  }

  componentWillReceiveProps(np) {
    if (!isEmpty(np.auth)) {
      const { user } = np.auth
      this.setState({ user: user._id })
    }
  }

  // Method to redirect the user to the login page
  needLogin = history => history.push('/login')

  // Method to redirect the user to his profile, to complete all the needed field
  needInfo = history => {
    // const { intl } = this.props
    // swal(intl.formatMessage({ id: 'alert.profileValidation' }), ``, 'warning')
    swal(
      this.props.intl.formatMessage({ id: 'alert.profileEmptyTitle' }),
      this.props.intl.formatMessage({ id: 'alert.profileEmptyText' }),
      'warning'
    )
    history.push('/user')
  }

  // Method to subscribe a user to a project
  register = () => {
    const { project, intl } = this.props
    const { user } = this.state
    this.props.subscribeCampaing(project._id, { user }, intl)
  }

  // Method to unsubscribe a user from a project
  unregister = () => {
    const { project, intl } = this.props
    const { user } = this.state
    this.props.unsubscribeCampaing(project._id, { user }, intl)
  }

  // Check if the user is suscribed to a project
  isSubscribed = (subscriptions, project) => {
    return !isEmpty(subscriptions.filter(d => d.project._id === project._id))
  }

  handleClick = () => {
    const { history, auth } = this.props
    const { user, isAuthenticated } = auth

    // The user is not authenticated
    if (!isAuthenticated) {
      this.needLogin(history)
    }
    // The user is authenticated but doesn't have all
    // the required fields to join the project
    else if (
      isAuthenticated === true &&
      (isEmpty(user) ||
        isEmpty(user.is) ||
        isEmpty(user.sede) ||
        isEmpty(user.name) ||
        isEmpty(user.email) ||
        isEmpty(user.phone))
    ) {
      this.needInfo(history)
    }
    // The user has his profile complete
    // and is authenticated
    else {
      this.register()
    }
  }

  render() {
    let buttonOptions = {}

    // Destructuring the needed props
    const {
      project,
      auth: { user }
    } = this.props

    // Destructuring the information we want of the project
    const { volunteering_startDate, volunteering_endDate } = project

    // Destructuring the subscriptions list of the user
    const { subscriptions } = user

    // Variables to handle the logic of the Voluntaring Button
    let can = false
    let canRegister = false
    let isSubscribed = false
    const isSofttekian = user.rol === 'softtekian'

    if (!isEmpty(project)) {
      const today = moment()
      // The value of 'can' is true only if today is on the dates of volunteerings
      can = moment(today).isBetween(volunteering_startDate, volunteering_endDate, 'days', '[]')
    }

    if (!isEmpty(subscriptions)) {
      // Check if the user is already subscribed to the project
      isSubscribed = this.isSubscribed(subscriptions, project)
    }

    // Check all the validations between the user and project to determine if
    // he/she can subscribe to it.
    if (
      can &&
      !project.closed &&
      (isEmpty(user.subsede) || user.subsede === project.subsede._id) &&
      project.volunteering
    ) {
      canRegister = true
    }

    // Decide between the subscribe and the unsubscribe button
    if (canRegister) {
      // SUBSCRIBE button
      if (!isSubscribed) {
        buttonOptions = {
          className: 'buttonProjectPage',
          onClick: this.handleClick,
          label: 'btn.join'
        }
      }
      // UNSUBSCRIBE button
      else {
        buttonOptions = {
          className: 'buttonProjectPageUnsubscribe',
          onClick: this.unregister,
          label: 'btn.unsubscribe'
        }
      }
    }

    return (
      <div className='buttonProjectPage__wrapper'>
        {canRegister && (
          <Button
            block
            color=''
            disabled={!isSofttekian}
            className={buttonOptions.className}
            size='md'
            onClick={buttonOptions.onClick}>
            <FormattedMessage id={buttonOptions.label} />
          </Button>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = { subscribeCampaing, unsubscribeCampaing }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VoluntaringBtn))
