import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link } from 'react-router-dom'
import { logoutUser } from '../../../../redux/actions/authActions'
import { clearCurrentProfile } from '../../../../redux/actions/profileActions'
import logo from '../../../../assets/img/softtek-good.png'
import { FormattedMessage } from 'react-intl'

class Topbar extends Component {

  logOut = event => {
    event.preventDefault()
    this.props.logoutUser()
    this.props.clearCurrentProfile()
  }

  collapseSidebar = () => {
    const sidebar = document.getElementsByClassName('sidebar')[0]
    sidebar.classList.toggle('collapse')
  }

  componentDidMount(){
    const buttonToggler = document.getElementById('sidebarToggleTop')
    buttonToggler.addEventListener('click', this.collapseSidebar)
  }

  componentWillUnmount() {
    const buttonToggler = document.getElementById('sidebarToggleTop')
    buttonToggler.removeEventListener('click', this.collapseSidebar)
  }

  render() {
    return (
      <nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
        <button
          id='sidebarToggleTop'
          className='btn btn-link d-md-none rounded-circle mr-3'
          style={{
            backgroundColor: 'whitesmoke'
          }}
        >
          <i className='fa fa-bars blue-color-fs' />
        </button>

        <ul className='navbar-nav ml-auto'>

          <div className='topbar-divider d-none d-sm-block' />

          <li
            className='nav-item dropdown no-arrow'
            style={{ cursor: 'pointer' }}
          >
            
            <p
              className='nav-link dropdown-toggle'
              id='userDropdown'
              role='button'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span className='mr-2 d-none d-lg-inline text-gray-600 small'></span>
              <img
                className='img-profile img-responsive'
                alt='Softtek'
                src={logo}
                style={{
                  width: 'auto',
                  height: '60px'
                }}
              />
            </p>

            <div
              className='dropdown-menu dropdown-menu-right shadow animated--grow-in'
              aria-labelledby='userDropdown'
            >
              <p
                className='dropdown-item'
                data-toggle='modal'
                data-target='#logoutModal'
                onClick={this.logOut}
                style={{
                  marginBottom: 0
                }}
              >
                <i className='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>
                <FormattedMessage id='btn.logout' />
              </p>

            </div>
          </li>
        </ul>
      </nav>
    )
  }
}


Topbar.prototypes = {
  logoutUser: PropTypes.func.isRequired,
  clearCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth }) => ({ auth })
export default connect(mapStateToProps, { logoutUser, clearCurrentProfile })(Topbar)