import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form } from 'reactstrap'
import { connect } from 'react-redux'
import { spinnerToggle } from '../../redux/actions/spinnerActions'
import { postCampaing } from '../../redux/actions/campaingActions'
// Layout
import Admin from './../Layout/Admin/Admin'
// Forms
import ThumbnailForm from './forms/ThumbnailForm'
import InformationForm from './forms/InformationForm'
import CategoriesForm from './forms/CategoriesForm'
import VolunteeringForm from './forms/VolunteeringForm'
import DonationsForm from './forms/DonationsForm'
import ItemsForm from './forms/ItemsForm'
import ContentForm from './forms/ContentForm'
import { isEmpty } from './Helpers'
import { validateOnSubmit, plainState } from './lib/helpers'
import { initialState } from './Helpers/initialState'

// Helpers
import validations from './validations'
import { injectIntl, FormattedMessage } from 'react-intl'
export class CampaingCreate extends Component {
	state = { ...initialState, published: false }

	componentWillMount() {
		this.setState({ ...initialState })
		if (!isEmpty(this.props.auth.user.subsede)) {
			const subsede = this.props.auth.user.subsede
			const sede = this.props.auth.user.sede
			this.setState((s) => ({ ...s, subsede, sede }))
		}
	}

	componentWillReceiveProps(np) {
		if (!isEmpty(np.auth.user.subsede)) {
			const subsede = np.auth.user.subsede
			const sede = np.auth.user.sede
			this.setState((s) => ({ ...s, subsede, sede }))
		}
	}

	// Handle agrega los valores a el
	// state de la propiedad de un objeto
	// recibe el nombre y un objecto de propiedades
	handleChangeSelectData = (props) => {
		this.setState((s) => ({ ...s, [props.name]: { ...s[props.name], value: props.value } }))
	}

	// Handle agrega los valores a el
	// state de la propiedad del editor
	onChangeEditor = ({ editor }) => {
		const value = editor.getData()
		this.setState((state, prop) => ({ content: { ...state.content, value } }))
	}

	// Handle agrega los valores del state
	// para inputs y checkbox
	handleInputChange = (event) => {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name
		this.setState((s) => ({ ...s, [name]: { ...s[name], value } }))
	}

	onChange = (event) => {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name
		this.setState({ [name]: value })
	}

	onChangeCan = (event) => {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name
		this.setState((s) => ({ ...s, can: { ...s.can, [name]: value } }))
	}
	// hello wolred
	onSubmit = (event) => {
		event.preventDefault()
		let { state } = this
		const validated = validateOnSubmit(state, validations)
		if (validated.pass) {
			const s = {
				...validated.state,
				sede: state.sede,
				subsede: state.subsede,
				donation: state.donation,
				donations_showCounter: state.donations_showCounter,
				donations_showCountDown: state.donations_showCountDown,
				volunteering: state.volunteering,
				volunteering_showCounter: state.volunteering_showCounter,
				inkindDonations: state.inkindDonations,
				inkindDonations_showCountDown: state.inkindDonations_showCountDown
			}
			const payload = plainState(s)
			const { intl } = this.props
			this.props.postCampaing(payload, this.props.history, intl)
			this.setState({ published: true })
		} else {
			const faildState = validated.state
			const failedFields = Object.keys(faildState).filter((f) => !faildState[f].valid)
			if (failedFields.length > 0) {
				document.getElementById(failedFields[0]).scrollIntoView()
			}
			this.setState({ ...faildState })
		}
	}

	render() {
		const { donation, volunteering, inkindDonations, published } = this.state
		return (
			<Admin>
				<Form onSubmit={this.onSubmit} ref={(f) => (this.form = f)}>
					{/* First row: Thumbnail and Information */}
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<h1 className="h3 mb-2 text-gray-800">
								<FormattedMessage id="label.publicProject" />
							</h1>
						</div>
						{/* Information (column 1) */}
						<div className="col-md-6 col-lg-6">
							<div className="card shadow mb-4">
								<div className="card-header py-3">
									<h6 className="m-0 font-weight-bold text-primary">
										<FormattedMessage id="label.info" />
									</h6>
								</div>
								<div className="card-body">
									<InformationForm {...this} {...this.state} />
								</div>
							</div>
						</div>
						{/* Thumbnail (column 2) */}
						<div className="col-md-6 col-lg-6">
							<div className="card shadow mb-4">
								<div className="card-header py-3">
									<h6 className="m-0 font-weight-bold text-primary">
										<FormattedMessage id="label.thumbnail" />
									</h6>
								</div>
								<div className="card-body">
									<ThumbnailForm {...this} {...this.state} />
								</div>
							</div>
						</div>
					</div>

					{/* Second row: Información and Voluntariado */}
					<div className="row">
						{/* Categorias (column 2) */}
						<div className="col-md-6 col-lg-6">
							<div className="card shadow mb-4">
								<div className="card-header py-3">
									<h6 className="m-0 font-weight-bold text-primary">
										<FormattedMessage id="label.categorie" />
									</h6>
								</div>
								<div className="card-body">
									<CategoriesForm {...this} {...this.state} />
								</div>
							</div>
						</div>

						{/* Donations (Column 2) */}
						<div className="col-md-6 col-lg-6">
							<div className="card shadow mb-4">
								<div className="card-header py-3">
									<h6 className="m-0 font-weight-bold text-primary">
										<FormattedMessage id="label.moneyDonated" />
									</h6>
								</div>
								<div className="card-body">
									<div className="form-row">
										<div className="form-group col">
											<div className="form-check">
												<input
													name="donation"
													checked={donation}
													type="checkbox"
													onChange={this.onChange}
													className="form-check-input"
												/>
												<label className="form-check-label">
													<FormattedMessage id="label.enable" />
												</label>
											</div>
										</div>
									</div>
									{donation && <DonationsForm {...this} {...this.state} />}
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						{/*Voluntariado and Donations (column 2) */}
						<div className="col-md-6 col-lg-6">
							{/* Volunteering (Column 1) */}
							<div className="card shadow mb-4">
								<div className="card-header py-3">
									<h6 className="m-0 font-weight-bold text-primary">
										<FormattedMessage id="label.volunteer" />
									</h6>
								</div>
								<div className="card-body">
									<div className="form-row">
										<div className="form-group col">
											<div className="form-check">
												<input
													name="volunteering"
													checked={volunteering}
													type="checkbox"
													onChange={this.onChange}
													className="form-check-input"
												/>
												<label className="form-check-label">
													<FormattedMessage id="label.subscription" />
												</label>
											</div>
										</div>
									</div>
									{volunteering && <VolunteeringForm {...this} {...this.state} />}
								</div>
							</div>
						</div>

						{/* Donations (Column 2) */}
						<div className="col-md-6 col-lg-6">
							<div className="card shadow mb-4">
								<div className="card-header py-3">
									<h6 className="m-0 font-weight-bold text-primary">
										<FormattedMessage id="label.itemsDonated" />
									</h6>
								</div>
								<div className="card-body">
									<div className="form-row">
										<div className="form-group col">
											<div className="form-check">
												<input
													name="inkindDonations"
													checked={inkindDonations}
													type="checkbox"
													onChange={this.onChange}
													className="form-check-input"
												/>
												<label className="form-check-label">
													<FormattedMessage id="label.itemsEnable" />
												</label>
											</div>
										</div>
									</div>
									{inkindDonations && <ItemsForm {...this} {...this.state} />}
								</div>
							</div>
						</div>
					</div>

					<ContentForm {...this} {...this.state} />

					<Button className="mb-4" disabled={published} type="submit">
						<FormattedMessage id="btn.save" />
					</Button>
				</Form>
			</Admin>
		)
	}
}

CampaingCreate.propTypes = {
	auth: PropTypes.object.isRequired,
	campaing: PropTypes.object.isRequired,
	spinnerToggle: PropTypes.func.isRequired,
	postCampaing: PropTypes.func.isRequired
}

const mapStateToProps = ({ auth, campaing, location }) => ({ auth, campaing, location })
const mapDispatchToProps = { spinnerToggle, postCampaing }

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaingCreate))
