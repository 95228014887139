import React from 'react'
import { Field } from 'redux-form'
import { Radio } from './RFRadio'
import { Input } from './RFInput'
import { Select, SelectAsync } from './RFSelect'
import { Checkbox } from './RFCheckbox'
import { Uploader } from './RFUploader'
import { HTMLEditor } from './RFHTMLEditor'
import { compose } from 'redux'
import { Label } from 'reactstrap'

// WRAPPERS
export const InputWrapper = Input => ({ meta, col = 12, ...props }) => {
  return (
    <div className={`col-md-${col}`}>
      <Label for={props.id}>{props.label}</Label>
      <Input
        invalid={meta.touched && meta.error}
        valid={meta.touched && !meta.error}
        {...props}
      />
      {meta.touched &&
        ((meta.error && <span className='text-danger'>{meta.error}</span>) ||
          (meta.warning && <span className='text-warning'>{meta.warning}</span>))}
      <div className='mb-2' />
    </div>
  )
}

export const CheckboxWrapper = Input => ({ meta, ...props }) => {
  return (
    <div>
      <Input
        invalid={meta.touched && meta.error}
        valid={meta.touched && !meta.error}
        {...props}
      />
      <Label for={props.id}>{props.label}</Label>
      {meta.touched &&
        ((meta.error && <span className='text-danger'>{meta.error}</span>) ||
          (meta.warning && <span className='text-warning'>{meta.warning}</span>))}
      <br />
    </div>
  )
}

// Wrapper to connect with Redux Form
export const useWrapperRF = Input => props => {
  return <Field {...props} component={Input} />
}

// CONNECTED INPUT COMPONENTS
// TODO: Tags, TagsInput
const withRF = compose(useWrapperRF, InputWrapper)

export const RFInput = withRF(Input)
export const RFRadio = withRF(Radio)
export const RFSelect = withRF(Select)
export const RFSelectAsync = withRF(SelectAsync)
export const RFCheckbox = useWrapperRF(CheckboxWrapper(Checkbox))
export const RFUploader = withRF(Uploader)
export const RFHTMLEditor = withRF(HTMLEditor)
