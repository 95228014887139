import { Component } from 'react'

// Component to be used as a solution for the scroll conservation behavior
// in the browser, and the no official solution implemented by react-router.

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return null
  }
}

// HOW TO USE:
// If we have a page with a only component named <LargeInformation> and we want 
// the behavior of that page to be scrolled up every time the page is accesed
// We just render the Layout like this:
// render(){
// ...
//  <ScrollToTopOnMount />
//  <LargeInformation /> 
// ...
// }

export default ScrollToTopOnMount