import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { SET_CURRENT_USER, UPDATE_USER, GET_ERRORS } from './types'
import setAuthToken from '../../util/setAuthToken'
import swal from '@sweetalert/with-react'
import { isEmpty } from '../../util/isEmpty'
import socket from '../../socket'
import { loadingPage } from './loadingpageAction'

// Login - Get User Token ~
export const loginUser = userData => dispatch => {
  dispatch(loadingPage())
  axios
    .post('/api/auth/azure/login', userData)
    .then(res => {
      // Save to localStorage
      const { token } = res.data
      // Set token on localStorage
      localStorage.setItem('token', token)
      // Set token in axios Header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      if (decoded.rol !== 'softtekian') {
        // Set current Admin
        dispatch(setCurrentUser(decoded))
      } else {
        // Set extra props softtekian
        dispatch(softekkianLoginProps(decoded))
      }
      return decoded
    })
    .then(decoded => socket.emit('auth', decoded)) // tell the other how i am !
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// AGREGA PROPIEDADES AL USUARIO SOFTTKIAN
// EN REDUX AL VOLVER ENTRAR A LA PAGINA
export const softekkianLoginProps = decode => dispatch => {
  // Se monta el usuario en redux, aun sin las propiedades de causas, habilidades y actividades
  dispatch(setCurrentUser(decode))
  // Se hace una petición GET para obtener la información no incluida en el token sobre el usuario
  axios
    .get(`/api/user/${decode._id}`)
    .then(res => res.data)
    // Se destructura la sede, subsede, is, causas, actividades y habilidades del usuario
    .then(({ is, sede, subsede, phone, causes, skills, activities, isFriend, subscriptions, avatar }) => {
      const data = { causes, skills, activities }
      // Si el usuario no tiene subsede asignada, solo se agregan las propiedades de causas, actividade y habilidades
      if (!isEmpty(subsede)) {
        return { is, phone, isFriend, subsede: subsede._id, subsede_name: subsede.name, sede_name: sede.name, sede: sede._id, subscriptions, avatar: !isEmpty(avatar) && avatar.path, ...data }
      }
      return data
    })
    .then(data => ({ ...decode, ...data }))
    // Se monta el usuario con las propiedades agregadas
    .then(user => dispatch(setCurrentUser(user)))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// agrega preopiedades al softekian cuanto acutlizan
// su perfil.
export const updateSofttekian = (id, d, intl) => dispatch => {
  axios
    .put(`/api/user/${id}`, d)
    .then(res => res.data)
    .then(({ is, subsede, phone, sede, causes, skills, activities, isFriend, avatar  }) =>
      ({ is, phone, subsede: subsede._id, subsede_name: subsede.name, sede_name: sede.name, sede: sede._id, causes, skills, activities, isFriend, avatar: !isEmpty(avatar) && avatar.path }))
    .then(data => {
      dispatch({ type: UPDATE_USER, payload: data })
    })
    .then(() => swal(intl.formatMessage({ id: 'alert.profileUpdate' }), '', "success"))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// agrega preopiedades al softekian cuanto  se suscribe a un proyecto
export const getSubscriptionsUser = id => dispatch => {
  axios
    .get(`/api/user/${id}`)
    .then(res => res.data)
    .then(({ is, subsede, phone, sede, causes, skills, activities, subscriptions, isFriend, avatar  }) =>
      ({ is, phone, subsede: subsede._id, subsede_name: subsede.name, sede_name: sede.name, sede: sede._id, causes, skills, activities, subscriptions, isFriend, avatar: !isEmpty(avatar) && avatar.path }))
    .then(data => dispatch({ type: UPDATE_USER, payload: data }))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

export const setCurrentUser = user => ({type: SET_CURRENT_USER,payload: user})

// Log user out
export const logoutUser = () => dispatch => {
  dispatch(loadingPage())
  const token = localStorage.getItem("token")
  if (token) {
    const decoded = jwt_decode(token)
    const id = decoded._id
    axios.put(`/api/auth/logout/user/${id}`, { login: false })
      .then(res => {
        dispatch(removeCredentials())
        socket.emit('logout', id)
      })
      .then(() => dispatch(loadingPage()))
      .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
  }
}

export const updateUser = (id, d, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .put(`/api/user/${id}`, d)
    .then(r => dispatch({ type: UPDATE_USER, payload: r.data }))
    .then(() => swal(intl.formatMessage({ id: 'table.update' }), '', "success"))
    .then(swal => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

    // // diferente de logOutuser no tiene intl
    // export const logOut = () => dispatch => {
    //   const decoded = jwt_decode(localStorage.token)
    //   const id = decoded._id
    //   axios.put(`/api/auth/logout/user/${id}`, { login: false })
    //     .then(res => {
    //       dispatch(removeCredentials())
    //       socket.emit('logout', id)
    //     })
    //     .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
    // }

// Remove all credentials
export const removeCredentials = () => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem('token')
  // Remove the auth header for future requests
  setAuthToken(false)
  // set the current user to {}
  // which will set isAuthenticated to false
  dispatch(setCurrentUser({}))
}
