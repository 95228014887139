import React from 'react'
import style from './listElement.module.css'
import { FormattedMessage, injectIntl } from 'react-intl'

import { isEmpty } from './../../../../util/isEmpty'

function ListElement({ message, children, intl }) {
  const { formatMessage } = intl

  const canRender = !isEmpty(formatMessage({id: message}))
  return (
    <li className={`${style['optionsPrograms']}`}>
      {canRender && children}
      <p>
        <FormattedMessage id={message} />
      </p>
    </li>
  )
}

export default injectIntl(ListElement)
