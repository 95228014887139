import { isEmpty } from './../../../util/isEmpty'

// El componente BootstrapTable solo acepta un objeto plano como data
export const formatToTable = data => data.map(d => {
  let result = {...d}
  // Subsede (Nested object to plain object)
  if (!isEmpty(d.subsede)){
    result = {...result, subsede: result.subsede.name }
  } else {
    result =  {...result, subsede: 'No asignada'}
  }
  // Sede (Nested object to plain object)
  if (!isEmpty(d.sede)){
    result = {...result, sede: result.sede.name }
  } else {
    result =  {...result, sede: 'No asignada'}
  }
  // IS (If empty, it assigns a default value)
  if (!isEmpty(d.is)){
    result = {...result, is: result.is }
  } else {
    result =  {...result, is: 'No asignado'}
  }
  return result
})
