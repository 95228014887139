export const initialState = {

  sede: null,
  // email: null,
  subsede: null,

  type: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  startDate: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  endDate: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  eventDate: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  objective: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },
  institution: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },
  // thumbnail
  title: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },
  description: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },
  thumbnail: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  // categories form

  categorie: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  cause: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  activities: {
    value: [],
    valid: null,
    text: '',
    feed: []
  },

  skills: {
    value: [],
    valid: null,
    text: '',
    feed: []
  },

  donation: true,
  donations_showCounter: false,
  donations_showCountDown: false,

  donations_startDate: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  donations_endDate: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  donations_text: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },


  volunteering: false,
  volunteering_showCounter: false,


  volunteering_startDate: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  volunteering_endDate: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  volunteering_volunteersRequired: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  content: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },


  
  inkindDonations: false,
  inkindDonations_showCountDown: false,

  inkindDonations_startDate: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },

  inkindDonations_endDate: {
    value: '',
    valid: null,
    text: '',
    feed: []
  },
  
  inkindDonations_text: {
    value: '',
    valid: null,
    text: '',
    feed: []
  }
}
