import React, { Fragment } from 'react'
import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import Im1 from './../../../../assets/img/Icons/verified-01.svg'

import styles from './initiative.module.css'

const InitiativeForm = ({ fields, handleChange }) => {
  return (
    <Fragment>
      <form>

        <FormGroup>
          <img className={`img-fluid rounded-circle mb-3 ${styles['logo']}`} src={Im1} alt="" />
          <Label
            className={styles['label']}
            for="description"
          >
            <FormattedMessage id="label.description" />:
          </Label>
          <Input
            type="textarea"
            rows="10"
            name="fields.description"
            className={styles['input']}
            valid={fields.description.valid}
            invalid={fields.description.invalid}
            value={fields.description.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.description.valid}>
            <ul className="list-unstyled">
              {fields.description.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.description.text}</FormText>
        </FormGroup>

      </form>

      {/* <div id="success"></div>
      <center>
        <button type="submit" onClick={onSubmit} className={styles['button-send']} id="sendMessageButton">
          <FormattedMessage id="btn.send" />
        </button>
      </center> */}

    </Fragment>
  )
}

export default InitiativeForm