import React, { Fragment } from 'react'
import Footer from '../Common/Footer/Footer'
import Navbar from '../Common/Navbar/Navbar'
import ProgramsInfoSection from './../OurProgramsSection/ProgramsInfoSection/ProgramsInfoSection'
import ProgramsSection from './../OurProgramsSection/ProgramsSection/ProgramsSection'

// Solution to scroll restoration
import ScrollToTopOnMount from './../Common/ScrollToTopOnMount'

function OurPrograms() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <Navbar />
      <ProgramsInfoSection />
      <ProgramsSection />
      <Footer />
    </Fragment>
  )
}

export default OurPrograms