import React, { Fragment } from 'react'
import { dmy, sorting } from '../../../../util/dateHelpers'
import { FormattedMessage } from 'react-intl'
import DetailTable from './ActivitiesDetailTable'

function ActivitiesTable({ columns, list, onClickDelete, onClickDetail, onClickEdit }) {
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th></th>
          {columns.map((column, i) => <th key={`${column}-${i}`}><FormattedMessage id={column} /></th>)}
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          (list && list.length > 0) &&
          sorting(list).map((r, i) => (
            <Fragment>
              <tr
                key={`${r.name}-${i}`}
                data-toggle="collapse"
                data-target={`#activitie-${r._id}`}
                className="accordion-toggle"
              >
                <td>{i + 1}</td>
                <td>
                  {r.detail.length > 0 && <button className="btn btn-default btn-xs">+</button>}
                </td>
                <td>{r.name}</td>
                <td>
                  {
                    r.active ?
                      <i style={{ color: '#41b38f' }} className="fas fa-check"></i> :
                      <i style={{ color: '#dc3545' }} className="fas fa-times"></i>
                  }
                </td>
                <td>{dmy(r.createdAt)}</td>
                <td>{dmy(r.updatedAt)}</td>
                <td>
                  <button
                    className="btn btn-info"
                    title="Editar"
                    onClick={() => onClickEdit(r._id)}
                  >
                    <i className="fas fa-edit"></i>
                  </button>
                </td>
                <td>
                  <button
                    className={`btn ${r.active ? 'btn-danger' : 'btn-success'}`}
                    title="Activar/Desactivar"
                    onClick={() => onClickDelete(r._id)}
                  >
                    <i className="fas fa-power-off"></i>
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-secondary"
                    title="Detalle"
                    onClick={() => onClickDetail(r._id)}
                  >
                    <i className="fas fa-eye"></i>
                  </button>
                </td>
              </tr>
              <DetailTable r={r} i={i} />
            </Fragment>
          ))
        }
      </tbody>
    </table>
  )
}
export default ActivitiesTable