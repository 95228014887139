import { addItem, updatedItem } from '../../util/helpers'
import {
  GET_CONVOCATORY, POST_CONVOCATORY, UPDATE_CONVOCATORY,
  GET_All_CONVOCATORY,
  EDIT_CONVOCATORY,
  GET_CLEAN_CONVOCATORY,
  EDIT_CLEAN_CONVOCATORY,
  GET_All_CLEAN_CONVOCATORY,
  GET_SUBSCRIBERS_CONVOCATORY,
  GET_CLEAN_SUBSCRIBERS_CONVOCATORY
} from '../actions/types'

const initialState = {
  list: [],
  view: {},
  edit: {},
  subscribers: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONVOCATORY:
      return {
        ...state,
        view: { ...action.payload }
      }
    case POST_CONVOCATORY:
      return {
        ...state,
        list: addItem(state.list, action.payload)
      }
    case EDIT_CONVOCATORY:
      return {
        ...state,
        edit: {...action.payload}
      }
    case UPDATE_CONVOCATORY:
      return {
        ...state,
        list: updatedItem(state.list, action.payload)
      }
    case GET_All_CONVOCATORY:
      return {
        ...state,
        list: [...action.payload]
      }
    case GET_CLEAN_CONVOCATORY:
      return {
        ...state,
        view: {...action.payload}
      }
    case EDIT_CLEAN_CONVOCATORY:
      return {
        ...state,
        edit: {...action.payload}
      }
    case GET_All_CLEAN_CONVOCATORY:
      return {
        ...state,
        list: [...action.payload]
      }
    case GET_SUBSCRIBERS_CONVOCATORY:
      return {
        ...state,
        subscribers: {...action.payload}
      }
    case GET_CLEAN_SUBSCRIBERS_CONVOCATORY:
      return {
        ...state,
        subscribers: {...action.payload}
      }
    default:
      return state
  }
}