import React, { Component } from 'react'
// Dependencies
import PropTypes from 'prop-types'
// Redux imports
import { connect } from 'react-redux'
import {
  getAllConvocatoryUser,
  getAllCleanConvocatory
} from './../../redux/actions/convocatoryActions'
// Useful components
import Gallerie from '../Main/Layout/Gallerie'
import Pagination from './../common/Pagination/Pagination'
import { isEmpty } from './../../util/isEmpty'
import Filters from './components/Filters'
import { Container } from 'reactstrap'
import { filterText, evaluateBetween, applyFilters } from './../../util/filterHelpers'
import axios from 'axios'
import { injectIntl, FormattedMessage } from 'react-intl'
import styles from '../Main/Common/Cards/cardNew.module.css'
import Card from '../Main/Common/Cards/CardNew'

export class ConvocatoryGallerie extends Component {
  state = {
    filters: {
      title: { type: 'text', value: '' },
      type: { type: 'text', value: '' },
      subsede: { type: 'nestedId', value: '' },
      startDate: { type: 'date', value: '' },
      endDate: { type: 'date', value: '' }
    },
    filtered: [],
    subsedes: []
  }

  componentDidMount() {
    // GET the convocatories
    this.props.getAllConvocatoryUser(this.props.intl)
    axios
      .get('/api/subsede')
      .then(res => this.setState({ subsedes: res.data }))
      .catch(e => this.setState({ subsedes: [] }))
  }

  componentWillReceiveProps(np) {
    if (!isEmpty(np.convocatory.list)) {
      this.setState({ filtered: np.convocatory.list })
    }
  }

  componentWillUnmount() {
    this.props.getAllCleanConvocatory()
  }

  // Handle para guardar los filtros:
  // título, tipo, rango de fechas(fecha inicio, fecha fin) en el estado
  onChange = event => {
    const { name, value } = event.target
    this.setState(s => ({
      ...s,
      filters: { ...s.filters, [name]: { ...s.filters[name], value } }
    }))
  }

  // Handle para aplicar los filtros configurados
  handleSubmit = event => {
    event.preventDefault()
    const { list } = this.props.convocatory
    const { filters } = this.state
    const filtered = applyFilters(filterText, evaluateBetween)(list, filters, [
      'startDate',
      'endDate'
    ])
    this.setState({ filtered })
  }

  render() {
    const { filtered, filters } = this.state
    const { formatMessage } = this.props.intl
    const { subsedes } = this.state

    return (
      <Gallerie>
        <Container>
          {/* Gallery's title */}
          <h1 className={`${styles['card__gallerie-title']} mx-3`}>
            <FormattedMessage id='convocatory.gall' />
          </h1>

          {/* Gallery's filters */}
          <Filters
            {...this}
            filters={filters}
            formatMessage={formatMessage}
            subsedes={subsedes}
          />

          {/* Gallery content, including paginator */}
          <Pagination
            data={filtered}
            route='convocatory/gallerie'
            sizePerPage={6}
            component={Card}
          />
        </Container>
      </Gallerie>
    )
  }
}

ConvocatoryGallerie.propTypes = {
  auth: PropTypes.object.isRequired,
  getAllConvocatoryUser: PropTypes.func.isRequired
}
const mapStateToProps = ({ auth, convocatory, subsedes }) => ({ auth, convocatory, subsedes })
const mapDispatchToProps = { getAllConvocatoryUser, getAllCleanConvocatory }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConvocatoryGallerie))
