import React, { Fragment } from 'react'
import styles from './project-icons.module.css'

const ProjectIcon = ({ icon, text, isActive }) => {
  return (
    <Fragment>
      {isActive && (
        <div className={`testimonial-item mb-5 mb-lg-0 row`}>
          <div className='col-lg-4'>
            <img className={`img-fluid mb-3 ${styles['iconsSize']}`} src={icon} alt={text} />
          </div>
          <div className='col-lg-8 d-flex align-items-center'>
            <p className={`font-weight-light lead mb-0 ${styles['txtIcons']}`}>{text}</p>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default ProjectIcon
