import React, { Fragment } from 'react'
import { Input, Col, Row } from 'reactstrap'
import FormGroupValid from '../lib/FormGroupValid'
import { FormattedMessage } from 'react-intl';

const InformationForm = ({ volunteering_startDate, volunteering_endDate, volunteering_volunteersRequired, volunteering_showCounter, handleInputChange, onChange }) => {
  return (
    <Fragment>

      <Row>
        <Col md={6}>
          <FormGroupValid forp="volunteering_startDate" label={<FormattedMessage id="label.volunteering_startDate" />} {...volunteering_startDate}>
            <Input
              type="date"
              id="volunteering_startDate"
              name="volunteering_startDate"
              value={volunteering_startDate.value}
              valid={volunteering_startDate.valid}
              invalid={volunteering_startDate.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>

        <Col md={6}>
          <FormGroupValid forp="volunteering_endDate" label={<FormattedMessage id="label.volunteering_endDate" />} {...volunteering_endDate}>
            <Input
              type="date"
              id="volunteering_endDate"
              name="volunteering_endDate"
              value={volunteering_endDate.value}
              valid={volunteering_endDate.valid}
              invalid={volunteering_endDate.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupValid forp="volunteering_volunteersRequired" label={<FormattedMessage id="label.volunteering_volunteersRequired" />} {...volunteering_volunteersRequired}>
            <Input
              type="number"
              id="volunteering_volunteersRequired"
              name="volunteering_volunteersRequired"
              value={volunteering_volunteersRequired.value}
              valid={volunteering_volunteersRequired.valid}
              invalid={volunteering_volunteersRequired.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="form-group col">
            <div className="form-check">
              <input
                type="checkbox"
                id="volunteering_showCounter"
                name="volunteering_showCounter"
                checked={volunteering_showCounter}
                onChange={onChange}
                className="form-check-input" />
              <label className="form-check-label">
                <FormattedMessage id="label.showCounter" />
              </label>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

export default InformationForm
