import React from 'react'
import solidaria from './img/programas-04.svg'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import ListElement from '../../../Common/ListElement/ListElement';
import Bullet from '../../../Common/Bullet/Bullet';


const ProgramsCoexistence = () => (
  <div className="row">
    <div className=" mx-auto col-md-2 mb-lg-0">
      <img className="img-fluid rounded-circle mb-3 iconsPrograms" src={solidaria} alt="" />
    </div>
    <div className=" mx-auto col-md-10 mb-lg-0">
      <h4 className="txtPrograms"><FormattedMessage id="programs.solidarity" /></h4>
      <p className="contentPrograms" ><FormattedHTMLMessage id="programs.solTxt" /><br /></p>
      <ul className="font-weight-light lead mb-0 list-unstyled" >
        <ListElement message={"programs.sT1"}>
          <Bullet />
        </ListElement>
      </ul><br />
      <p className="font-weight-light lead mb-0 txtPrograms" ><strong><FormattedMessage id="programs.tit2" /></strong></p>
      <p className="contentPrograms" ><FormattedMessage id="programs.tit3" /></p>
      <ul className="font-weight-light lead mb-0 list-unstyled" >
        <ListElement message={"programs.sT2"}>
          <Bullet />
        </ListElement>
        <ListElement message={"programs.sT3"}>
          <Bullet />
        </ListElement>
      </ul><br /><br />
    </div>
  </div>
)

export default ProgramsCoexistence