import moment from 'moment'

// Función para filtrar por texto un arreglo de objetos en base a un parametro
// Params:
//         param (Propiedad del objeto a considerar para el filtrado)
//         text (Texto de filtrado)
export const filterText = (param, text) => x => new RegExp(text, 'i').test(x[param])

// Función para filtrar por texto un arreglo de objetos en base a un parametro nesteado
// Params:
//         param (Propiedad del objeto a considerar para el filtrado)
//         text (Texto de filtrado)
export const filterNested = (param, nestedParam, text) => x => new RegExp(text, 'i').test(x[param][nestedParam])


// Función para evaluar entre fechas
// PRIMERA FUNCIÓN
// Params: 
//         startDate (fecha inicio)
//         endDate (fecha fin)
//         params (Arreglo donde el primer elemento es el nombre de la propiedad como fecha inicial,
//                 el segundo como fecha final)
// SEGUNDA FUNCIÓN
// Params: p (Objeto que debe contener las dos propiedades contenidas en el arreglo 'params' de la primera función)
export const evaluateBetween = (startDate, endDate, params) => p => {
  const sd = new Date(startDate)
  const ed = new Date(endDate)
  return (
    moment(p[params[0]]).isBetween(sd, ed, 'days', '[]') || 
    moment(p[params[1]]).isBetween(sd, ed, 'days', '[]') || 
    (moment(p[params[0]]).isBefore(sd) && moment(p[params[1]]).isAfter(ed))
  )
}

// Función para aplicar filtros de texto y fecha
// PRIMERA FUNCIÓN
// Params:
//         filterText y filterDate (funciones para filtrar por texto y fecha)
// SEGUNDA FUNCIÓN
// Params: 
//         arr (Arreglo de objetos a ser filtrado)
//         filters (Objeto con los filtros a aplicar en el formato especificado al final del archivo*).
//         dateParams (Arreglo con dos elementos especificando el nombre de las propiedades que se 
//                     tomaran como fecha inicio y fecha fin de los objetos a filtrar)
export const applyFilters = (filterText, filterDate) => {
  return (arr, filters, dateParams) => {
  const validFilters = Object.keys(filters).filter(p => filters[p].value !== '')
  const [sd, ed] = dateParams
    for(const filter of validFilters){
      switch(filters[filter].type){
        case 'text':
          arr = arr.filter(filterText(filter, filters[filter].value))
          break;
        case 'nestedId':
          arr = arr.filter(filterNested(filter, '_id', filters[filter].value))
          break;
        case 'date':
          arr = arr.filter(filterDate(filters[sd].value, filters[ed].value, dateParams));
          break
        default: break
      }
    }
    return arr
  }
}

/* 
  *) Formato de filtros a aplicar: 
   {<Nombre_de_filtro>: {
     type: <Tipo_de_filtro(enum['text', 'date'])>,
     value: ''
   }}
*/