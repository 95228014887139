import React from 'react'
// fields
import UploadFiles from './../../common/Upload/UploadFiles'
import { Input, Col, Row } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import FormGroupValid from './../../Campaign/lib/FormGroupValid'
import FormGroupCustom from './../../Campaign/lib/FormGroupCustom'

// const AvUploadFiles = props => (<AvField {...props} tag={UploadFiles} />)

const ThumbnailForm = ({ handleChangeSelectData, handleInputChange, state }) => {
  const { title, startDate, endDate, description, thumbnail } = state
  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          <FormattedMessage id='label.thumbnail' />
        </h6>
      </div>
      <div className="card-body table-responsive">

        <FormGroupValid forp="title" label={<FormattedMessage id="label.title" />} {...title}>
          <Input
            id="title"
            type="text"
            name="title"
            value={title.value}
            valid={title.valid}
            invalid={title.valid === false}
            onChange={handleInputChange} />
        </FormGroupValid>

        <Row>
          <Col md={6}>
            <FormGroupValid forp="startDate" label={<FormattedMessage id="label.startDate" />} {...startDate}>
              <Input
                id="startDate"
                type="date"
                name="startDate"
                value={startDate.value}
                valid={startDate.valid}
                invalid={startDate.valid === false}
                onChange={handleInputChange} />
            </FormGroupValid>
          </Col>

          <Col md={6}>
            <FormGroupValid for="endDate" label={<FormattedMessage id="label.endDate" />} {...endDate}>
              <Input
                type="date"
                id="endDate"
                name="endDate"
                value={endDate.value}
                valid={endDate.valid}
                invalid={endDate.valid === false}
                onChange={handleInputChange} />
            </FormGroupValid>
          </Col>
        </Row>

        <FormGroupValid forp="description" label={<FormattedMessage id="label.description" />} {...description}>
          <Input
            type="textarea"
            id="description"
            name="description"
            value={description.value}
            valid={description.valid}
            invalid={description.valid === false}
            onChange={handleInputChange} />
        </FormGroupValid>

        <FormGroupCustom forp='thumbnail' label={<FormattedMessage id="label.cover" />} {...thumbnail}>
          <UploadFiles
            url={'/api/file'}
            file={file => handleChangeSelectData({ name: 'thumbnail', value: file._id })} />
        </FormGroupCustom>
      </div>
    </div>
  )
}

export default ThumbnailForm