import { reducer as form } from 'redux-form'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import authReducer from './authReducer'
import errorsReducer from './errorsReducer'
import notifyReducer from './notifyReducer'
import profileReducer from './profileReducer'
import spinnerReducer from './spinnerReducer'
import subsedeReducer from './subsedeReducer'
import locationReducer from './locationReducer'
import campaingReducer from './campaingReducer'
import newsReducer from './newsReducer'
import convocatoryReducer from './convocatoryReducer'
import loadingReducer from './loadingReducer'
import loadingpageReducer from './loadingpageReducer'
import contactReducer from './contactReducer'
import solidaryFriendReducer from './solidaryFriendReducer'
import initiativeReducer from './initiativeReducer'
import socketReducer from './socketReducer'
import counterReducer from './counterReducer'
import carrouselReducer from './carrouselReducer'
import videoReducer from './videoReducer'
import donationsReducer from './donationsReducer'
import rolReducer from './rolReducer'

import { alert as alertReducer } from './../../components/Alert/alert.redux'
import { customerProject } from './../../components/CustomerProject/customer-project.redux'
import { paypal } from './../../components/PayPal/paypal.redux'
import { holidayProject } from './../../components/HolidayProject/holiday-project.redux'
import { mailing } from './../../components/Mailing/mailing.redux'
import { donation as conekta } from './../../components/Donations/donation.redux'

export default history =>
  combineReducers({
    form,
    router: connectRouter(history),
    alert: alertReducer,
    auth: authReducer,
    errors: errorsReducer,
    profile: profileReducer,
    notify: notifyReducer,
    spinner: spinnerReducer,
    location: locationReducer,
    campaing: campaingReducer,
    carrousel: carrouselReducer,
    news: newsReducer,
    convocatory: convocatoryReducer,
    subsedes: subsedeReducer,
    loading: loadingReducer,
    loadingpage: loadingpageReducer,
    contact: contactReducer,
    solidaryFriend: solidaryFriendReducer,
    initiative: initiativeReducer,
    socket: socketReducer,
    counter: counterReducer,
    video: videoReducer,
    donations: donationsReducer,
    rols: rolReducer,
    customerProject,
    paypal,
    holidayProject,
    mailing,
    conekta
  })
