import React, { Component } from 'react'
import { postVideo } from './../../redux/actions/videoActions'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Form } from 'reactstrap'
import swal from '@sweetalert/with-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Layout 
import Admin from './../Layout/Admin/Admin'
// Forms
import VideoForm from './forms/VideoForm'
// Helpers
import validations from './validations'
import { validateOnSubmit } from './../Campaign/lib/helpers'

export class Video extends Component {
  
  state = {
    video: {
      value: '',
      valid: null,
      text: '',
      feed: []
    }
  }

  // Handle para agregar los valores a el
  // state de la propiedad de un objeto
  // recibe el nombre y un objeto de propiedades
  handleChangeSelectData = props => {
    this.setState(s => ({ ...s, [props.name]: { ...s[props.name], value: props.value } }))
  }

  // Handle para validar el formulario
  onSubmit = event => {
    event.preventDefault()
    const { intl, intl: { formatMessage }, history} = this.props
    let { state: { video } } = this
    const stateToValidate = { video }

    swal({text: formatMessage({id: 'alert.videoConfirmation'}), buttons: true, icon: 'warning'})
      .then(confirm => {

        if (confirm){
          // Las validaciones pasaron, se envia una petición POST a /api/video
          const validated = validateOnSubmit(stateToValidate, validations)
          if (validated.pass) {
            const s = { ...validated.state }
            const payload = { video: s.video.value }
            this.props.postVideo(payload, history, intl)
          } else {
            // Las validaciones NO pasaron
            const faildState = validated.state
            this.setState({ ...faildState })
          }
        }
      })
  }

  render() {
    return (
      <Admin>
        <div className="row">
          <div className="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10">
            <h1 className="h3 mb-2 text-gray-800">
              <FormattedMessage id='video.header' />
            </h1>
          </div>
        </div>

        <Form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-xl-10 col-lg-10 offset-lg-1 my-0">
              <VideoForm {...this} />
            </div>
          </div>
        </Form>
      </Admin>
    )
  }
}

Video.propTypes = {
  video: PropTypes.object.isRequired,
  postNews: PropTypes.func.isRequired
}
const mapStateToProps = ({ video }) => ({ video })
const mapDispatchToProps = { postVideo }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Video))