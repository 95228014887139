// devuelve si un objeto , string, array 
// esta vacio devuelve TRUE.
const isEmpty = value => (
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0) ||
  (Array.isArray(value) && value.length === 0)
)
// devuelve si un objeto , string, array 
// esta vacio devuelve TRUE si no devuelve 
// el parametro.
const checkIsEmpty = value => isEmpty(value) ? isEmpty(value) : value
export { isEmpty, checkIsEmpty }