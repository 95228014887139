/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns } from './customer-project.helpers'
import { actions } from './customer-project.redux'
import DataTable from '../common/Table/Table'
import TableContainer from '../common/Container/TableContainer'
// import { useExportSelected } from 'hooks/useExportSelected'
import Admin from './../Layout/Admin/Admin'

function CustomerProjectList(props) {
  // const [selectRow, handleExport] = useExportSelected('Campañas', '/api/customerProjects/export')

  useEffect(() => {
    props.loadAllCustomerProject()
  }, [])

  const rowEvents = {
    onClick: (e, row) => {
      const redirectTo = `/dashboard/customer-project/view/${row._id}`
      props.history.push(redirectTo)
    }
  }

  return (
    <Admin>
      <TableContainer title='Campañas' subtitle='Lista de campañas'>
        <DataTable
          {...props}
          rowEvents={rowEvents}
          data={props.customerProjects}
          structure={columns}
          // onExport={handleExport}
          // selectRow={selectRow}
          // selected={selectRow.selected}
        />
      </TableContainer>
    </Admin>
  )
}

const mapStateToProps = ({ customerProject }) => ({ customerProjects: customerProject.list })
const mapDispatchToProps = {
  loadAllCustomerProject: actions.loadAllCustomerProjectRequest,
  toggleCustomerProject: actions.toggleCustomerProjectRequest
  // exportCustomerProjects: actions.exportCustomerProjectsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerProjectList)
