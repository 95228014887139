import axios from 'axios'
import swal from '@sweetalert/with-react'
import { POST_SF, UPDATE_SF, GET_All_SF, GET_All_CLEAN_SF, GET_ERRORS } from './types'
import { loadingPage } from './loadingpageAction'
import { store } from './../../index'

// POST SOLIDARY FRIEND (SOFTTEKIAN)
// Este action se utiliza cuando un softtekian quiere hacerse softtekian solidario por primera vez
// tiene que dirigirse a la página /friend y dar click a "Estoy interesado"
// posteriormente se le preguntará el monto que está dispuesto a donar mensualmente
export const postSF = (payload, intl) => dispatch => {
  const { isFriend } = store.getState().auth.user
  // Este caso es cuando el usuario NUNCA ha sido softtekian solidario
  if (isFriend === null) {
    swal({
      title: intl.formatMessage({ id: 'label.monthlyAmount' }),
      text: '',
      icon: 'info',
      content: 'input',
      buttons: true
    }).then(amount => {
      // La solicitud solo procede si el softtekian ingresa una cantidad mayor a cero
      if (Number(amount) > 0) {
        dispatch(loadingPage())
        axios
          .post(`/api/friend/petition`, { ...payload, amount })
          .then(dispatch(loadingPage()))
          .then(res => dispatch({ type: POST_SF, payload: res.data }))
          .then(() =>
            swal({
              title: intl.formatMessage({ id: 'alert.friendTh' }),
              text: intl.formatMessage({ id: 'alert.friendThanks' }),
              icon: 'success'
            })
          )
      }
    })
  }
  // El usuario fue softtekian solidario pero decidió retirarse del programa.
  else if (isFriend === false) {
    swal({ title: '', text: intl.formatMessage({ id: 'alert.wasFriend' }), icon: 'info' })
  }
  // El usuario es softtekian solidario activo
  else if (isFriend === true) {
    swal({ title: '', text: intl.formatMessage({ id: 'alert.alreadyFriend' }), icon: 'info' })
  }
}

// POST SOLIDARY FRIEND (ADMIN)
// Este action es utilizado para agregar administradores como amigos solidarios directamente desde el panel
// de administración de la aplicación
export const postAdminSF = (payload, intl) => dispatch => {
  swal({
    title: intl.formatMessage({ id: 'label.monthlyAmount' }),
    text: '',
    icon: 'info',
    content: 'input',
    buttons: true
  }).then(amount => {
    if (Number(amount) > 0) {
      dispatch(loadingPage())
      axios
        .post(`/api/friend`, { ...payload, amount })
        .then(dispatch(loadingPage()))
        .then(res => dispatch({ type: POST_SF, payload: res.data }))
        .then(() =>
          swal({
            title: intl.formatMessage({ id: 'alert.friendTh' }),
            text: intl.formatMessage({ id: 'alert.friendThanks' }),
            icon: 'success'
          })
        )
        .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
    }
  })
}

// ACTIVATE/DEACTIVATE SOLIDARY FRIEND
// Este action es utilizado cuando un administrador desea activar o desactivar un registro de softtekian solidario
// especifico
export const deactivateSF = (id, payload, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .put(`/api/friend/activate/${id}`, payload)
    .then(dispatch(loadingPage()))
    .then(res => dispatch({ type: UPDATE_SF, payload: res.data }))
    .then(() =>
      swal({ title: intl.formatMessage({ id: 'table.update' }), text: '', icon: 'success' })
    )
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// REQUEST TO ACTIVATE A SF
// Este action es utilizado por softtekians para pedir ser reactivados como amigos solidarios, para ello se vuelve a
// capturar la cantidad que desean donar mensualmente
// Esto se hace directamente desde su perfil de usuario
export const petitionActivateSF = (id, intl) => dispatch => {
  swal({
    title: intl.formatMessage({ id: 'label.monthlyAmount' }),
    text: '',
    icon: 'info',
    content: 'input',
    buttons: true
  }).then(amount => {
    if (Number(amount) > 0) {
      dispatch(loadingPage())
      axios
        .put(`/api/friend/petition/activate/${id}`, { amount })
        .then(dispatch(loadingPage()))
        .then(() =>
          swal({
            title: intl.formatMessage({ id: 'alert.friendTh' }),
            text: intl.formatMessage({ id: 'alert.friendThanks' }),
            icon: 'success'
          })
        )
        .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
    }
  })
}

// REQUEST TO DEACTIVATE A SF
// Este actions es utilizado por softtekians que son amigos solidarios pero desean darse de baja del programa
// Esto lo hacen directamente desde su perfil de usuario
export const petitionDeactivateSF = (id, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .put(`/api/friend/petition/deactivate/${id}`, {})
    .then(dispatch(loadingPage()))
    .then(() =>
      swal({ title: intl.formatMessage({ id: 'alert.request' }), text: '', icon: 'success' })
    )
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

export const getAllSF = () => dispatch => {
  axios
    .get('/api/friend')
    .then(res => dispatch({ type: GET_All_SF, payload: res.data }))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

export const getAllCleanSF = () => dispatch =>
  dispatch({
    type: GET_All_CLEAN_SF,
    payload: []
  })
