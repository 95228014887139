import React from 'react'
import Logo from './img/3iconos solidaria-verde.svg'
import styles from './values-image-section.module.css'
import GeneralContainer from './../../Common/GeneralContainer/GeneralContainer'
import background from './img/BLUE2.png'

const ValuesImageSection = () => (
  <GeneralContainer backGround={background}>
    <img className={`img-fluid rounded-circle core mb-3 ${styles['logoSolidaria']}`} src={Logo} alt="FS" />
  </GeneralContainer>
)

export default ValuesImageSection