import React, { Component } from 'react'
import { connect } from 'react-redux'
import Admin from './../Layout/Admin/Admin'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import swal from '@sweetalert/with-react'
import {
  getCleanSubscribersConvocatory,
  getSubscribersConvocatory,
  unsubscribeConvocatory,
  contactConvocatory
} from '../../redux/actions/convocatoryActions'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, {
  textFilter,
  dateFilter,
  selectFilter
} from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
// Hepers
import TableWrapper from './../common/TableWrapper'
import { dmy } from './../../util/dateHelpers'
import { isEmpty } from '../../util/helpers'
import { FormattedMessage, injectIntl } from 'react-intl'

const expandRow = {
  renderer: subscriber => (
    <div className='px-3'>
      {!isEmpty(subscriber.content) && (
        <p>
          <strong>
            <FormattedMessage id='table.cause' />:{' '}
          </strong>
          {subscriber.content}
        </p>
      )}
      {!isEmpty(subscriber.contactedAt) && (
        <p>
          <strong>
            <FormattedMessage id='alert.contactConvocatory' />:{' '}
          </strong>
          {dmy(subscriber.contactedAt)}
        </p>
      )}
    </div>
  )
}

class ConvocatorySubscribers extends Component {
  handleUnsubscribe = subscriber => {
    const { id } = this.props.match.params
    const { intl } = this.props
    swal(
      intl.formatMessage({ id: 'alert.confiTitle' }),
      intl.formatMessage({ id: 'alert.registryDelete' }),
      'warning',
      { buttons: true }
    ).then(
      confirm =>
        confirm && this.props.unsubscribeConvocatory(id, { subscriber: subscriber._id }, intl)
    )
  }

  handleContact = subscriber => {
    const { id } = this.props.match.params
    const { intl } = this.props
    swal({
      title: `${intl.formatMessage({ id: 'alert.suscriber' })} ${subscriber.name}?`,
      content: 'input',
      text: intl.formatMessage({ id: 'alert.confirmation' }),
      icon: 'info',
      buttons: true
    }).then(
      confirm =>
        confirm && this.props.contactConvocatory(id, { subscriber: subscriber._id }, intl)
    )
  }

  contactedOptions = {
    true: this.props.intl.formatMessage({ id: 'general.confirmation' }),
    false: this.props.intl.formatMessage({ id: 'general.negation' })
  }

  // Columns configurations
  columns = [
    {
      dataField: 'name',
      text: this.props.intl.formatMessage({ id: 'label.name' }),
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'phone',
      text: this.props.intl.formatMessage({ id: 'label.phone' }),
      sort: true
    },
    {
      dataField: 'email',
      text: this.props.intl.formatMessage({ id: 'label.email' }),
      sort: true
    },
    {
      dataField: 'contacted',
      text: this.props.intl.formatMessage({ id: 'alert.contactConvocatory' }),
      filter: selectFilter({
        options: this.contactedOptions
      }),
      formatter: cell => this.contactedOptions[cell]
    },
    {
      dataField: 'createdAt',
      text: this.props.intl.formatMessage({ id: 'label.dateRegistry' }),
      sort: true,
      formatter: dmy,
      filter: dateFilter(),
      csvFormatter: dmy
    },
    {
      dataField: 'contacted',
      text: this.props.intl.formatMessage({ id: 'label.continue' }),
      sort: true,
      hidden: this.props.auth.user.rol === 'reporter',
      formatter: (cellContent, row) => (
        <div>
          <Button
            color='success'
            disabled={cellContent}
            className='mr-3'
            onClick={() => this.handleContact(row)}>
            <i className='fa fa-phone'></i>
          </Button>
          {!cellContent && (
            <Button color='danger' onClick={() => this.handleUnsubscribe(row)}>
              <i class='fa fa-trash'></i>
            </Button>
          )}
        </div>
      )
    }
  ]

  componentDidMount() {
    if (isEmpty(this.props.convocatory.subscribers)) {
      const { id } = this.props.match.params
      this.props.getSubscribersConvocatory(id)
    }
  }

  componentWillUnmount() {
    this.props.getCleanSubscribersConvocatory()
  }

  render() {
    const { subscribers } = this.props.convocatory
    const data = subscribers.subscribers ? subscribers.subscribers.filter(s => s.active) : []
    return (
      <Admin>
        <ToolkitProvider
          keyField='_id'
          data={data}
          columns={this.columns}
          bootstrap4={true}
          search={{
            searchFormatted: true
          }}>
          {props => (
            <TableWrapper
              title={`${subscribers.title}`}
              label={<FormattedMessage id='label.listSuscribe' />}>
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                filter={filterFactory()}
                expandRow={expandRow}
                className='table-responsive'
                bordered
                hover
              />
            </TableWrapper>
          )}
        </ToolkitProvider>
      </Admin>
    )
  }
}

ConvocatorySubscribers.propTypes = {
  auth: PropTypes.object.isRequired,
  convocatory: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth, convocatory }) => ({ auth, convocatory })
const mapDispatchToProps = {
  getCleanSubscribersConvocatory,
  getSubscribersConvocatory,
  unsubscribeConvocatory,
  contactConvocatory
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConvocatorySubscribers))
