export default {
  fields: {
    amount: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    },
    project: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    },
    type: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    },
    currency: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    },
    description: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    },
    needReceipt: {
      value: false,
      valid: null,
      invalid: null,
      feed: []
    },
    paypal: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    },
    approved: {
      value: false,
      valid: null,
      invalid: null,
      feed: []
    }
  },

  btnSave: false,
  btnUpdate: false,
  btnAdd: true,
  btnCancel: false,
  form: false,

  modal: false,
  item: {}
}
