import React from 'react'
import logo from '../../../assets/img/logos/fundacion-solidaria-blanco.svg'
import styles from './donationSection.module.css'
import PayPalBtn from './PaypalBtn/PayPalBtn'
import { FormattedMessage } from 'react-intl'
import backGround from '../../../assets/img/Home/donar-home.png'
import ModalInitiative from '../../Dashboard/Catalogs/Initiative/ModalInitiative'
import './donation-button-section.css'

import GeneralContainer from './../Common/GeneralContainer/GeneralContainer'

const DonationSection = () => (
  <GeneralContainer backGround={backGround} className="flex-wrap">
    <img src={logo} alt="logo" className={styles['logoSolidaria']} />
    <h3 className={`mx-auto mb-5 ${styles['txt']}`}>
      <FormattedMessage id="callout.share2" />
    </h3>
    <div className={'donation-buttons'}>
      <PayPalBtn />
      <ModalInitiative />
    </div>
  </GeneralContainer>
)

export default DonationSection
