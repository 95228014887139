/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns } from './holiday-project.helpers'
import { actions } from './holiday-project.redux'
import DataTable from '../common/Table/Table'
import TableContainer from '../common/Container/TableContainer'
// import { useExportSelected } from 'hooks/useExportSelected'
import Admin from '../Layout/Admin/Admin'

function HolidayProjectList(props) {
  // const [selectRow, handleExport] = useExportSelected('Campañas', '/api/holidayProjects/export')

  useEffect(() => {
    props.loadAllHolidayProject()
  }, [])

  const rowEvents = {
    onClick: (e, row) => {
      const redirectTo = `/dashboard/holiday-project/view/${row._id}`
      props.history.push(redirectTo)
    }
  }

  return (
    <Admin>
      <TableContainer title='Campañas México 2020' subtitle='Lista de campañas'>
        <DataTable
          {...props}
          rowEvents={rowEvents}
          data={props.holidayProjects}
          structure={columns}
          // onExport={handleExport}
          // selectRow={selectRow}
          // selected={selectRow.selected}
        />
      </TableContainer>
    </Admin>
  )
}

const mapStateToProps = ({ holidayProject }) => ({ holidayProjects: holidayProject.list })
const mapDispatchToProps = {
  loadAllHolidayProject: actions.loadAllHolidayProjectRequest,
  toggleHolidayProject: actions.toggleHolidayProjectRequest
  // exportHolidayProjects: actions.exportHolidayProjectsRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HolidayProjectList)
