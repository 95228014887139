import React from 'react'
import salud from './img/programas-01.svg'
import { FormattedMessage } from 'react-intl'


const ProgramsHealth = () => (
  <div className="row">
    <div className="mx-auto col-md-2 mb-lg-0">
      <img className="img-fluid rounded-circle mb-3 iconsPrograms" src={salud} alt="" />
    </div>
    <div className="col-md-10 mb-lg-0">
      <h4 className="txtPrograms"><FormattedMessage id="programs.health" /></h4>
      <p className="contentPrograms"><FormattedMessage id="programs.healthTxt" /></p><br /><br />
    </div>
  </div>
)

export default ProgramsHealth