import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'reactstrap'
import { spinnerToggle } from '../../redux/actions/spinnerActions'
import { postNews } from './../../redux/actions/newsActions'

// Layout 
import Admin from '../Layout/Admin/Admin'
// Forms
import ThumbnailForm from './forms/ThumbnailForm'
// Helpers
import validations from './validations'
import { isEmpty } from '../../util/isEmpty'
import { validateOnSubmit, plainState } from './../Campaign/lib/helpers'
import { FormattedMessage, injectIntl } from 'react-intl'
import initialState from './Helpers/initialState'

export class NewsCreate extends Component {
  
  state = { ...initialState, published: false }

  componentDidMount() {
    // Set the user's subsede in the news
    if (!isEmpty(this.props.auth.user)) {
      this.setState(s => ({ ...s, subsede: { ...s.subsede, value: this.props.auth.user.subsede } }))
    }
  }

  // Handle para agregar los valores a el
  // state de la propiedad de un objeto
  // recibe el nombre y un objeto de propiedades
  handleChangeSelectData = props => {
    this.setState(s => ({ ...s, [props.name]: { ...s[props.name], value: props.value } }))
  }

  // Handle agrega los valores a el
  // state de la propiedad del editor 
  onChangeEditor = ({ editor }) => {
    const value = editor.getData()
    this.setState((state, prop) => ({ content: { ...state.content, value } }))
  }

  // Handle para agrega los valores del state
  // para inputs y checkbox
  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState(s => ({ ...s, [name]: { ...s[name], value } }))
  }

  // Hadle si las validaciones pasaron
  onSubmit = event => {
    event.preventDefault()
    let { state: { title, subsede, publishDate, endDate, description, thumbnail, content } } = this
    const stateToValidate = { title, subsede, publishDate, endDate, description, thumbnail, content }
    // debugger
    const validated = validateOnSubmit(stateToValidate, validations)
    if (validated.pass) {
      const s = { ...validated.state }
      const payload = plainState(s)
      const { intl } = this.props
      this.props.postNews(payload, this.props.history, intl)
      this.setState({ published: true })
    } else {
      window.scrollTo(0, 0)
      const faildState = validated.state
      this.setState({ ...faildState })
    }
  }

  render() {
    return (
      <Admin>
        <div className="row">
          <div className="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10">
            <h1 className="h3 mb-2 text-gray-800">
              <FormattedMessage id="news.public" />
            </h1>
          </div>
        </div>

        <Form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-xl-10 col-lg-10 offset-lg-1">
              <ThumbnailForm {...this} formatMessage={this.props.intl.formatMessage} />
            </div>
          </div>
        </Form>
      </Admin>
    )
  }
}

NewsCreate.propTypes = {
  auth: PropTypes.object.isRequired,
  news: PropTypes.object.isRequired,
  spinnerToggle: PropTypes.func.isRequired,
  postNews: PropTypes.func.isRequired
}
const mapStateToProps = ({ auth, news }) => ({ auth, news })
const mapDispatchToProps = { spinnerToggle, postNews }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewsCreate))