import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setLanguageManual } from './../../../redux/actions/locationActions'

import arrowGreen from '../../Main/Common/Navbar/img/arrow-verde-01.svg'
import arrowWhite from '../../Main/Common/Navbar/img/arrow-blanco-02.svg'

export class SelectLanguage extends Component {
  setLanguage = language => {
    this.props.setLanguageManual(language)
  }

  handleClick = event => {
    event.currentTarget.classList.toggle('dropdown-open')
  }

  render() {
    const { title, className = '', ...props } = this.props
    const languages = [
      { label: 'ES', value: 'es-MX' },
      { label: 'EN', value: 'en-US' },
      { label: 'PT', value: 'pt-BR' }
    ]

    return (
      <li className={`${className} dropdown-sub-menu`} {...props} onClick={this.handleClick}>
        <a href='/#'>
          <div className='dropdown-title'>
            {title}
            <img src={arrowWhite} alt='' className='arrow-toggle-dropdown' />
          </div>
        </a>
        <ul className='sub-menu'>
          {languages.map(({ label, value }, i) => {
            return (
              <li key={`dropdow-item-${label}-${i}`}>
                <a href='/#' onClick={() => this.setLanguage(value)}>
                  <img className='arrow-link' src={arrowGreen} alt='' />
                  {label}
                </a>
              </li>
            )
          })}
        </ul>
      </li>
    )
  }
}

const mapStateToProps = ({ auth, location }) => ({ auth, location })
export default connect(mapStateToProps, { setLanguageManual })(SelectLanguage)
