import React from 'react'
import styles from './dts-voluntario.module.css'
import Activo from './img/daretoshare-02.svg'
import { FormattedMessage } from 'react-intl'
import DTSInfoCard from './../../DTSInfoCard/DTSInfoCard'

const DTSVoluntarioRow = ({ title, content }) => (
  <div className='my-4'>
    <h4 className={`${styles['txtVoluntario']}`}>
      <strong>
        <FormattedMessage id={title} />
      </strong>
    </h4>
    <p className={`contentPrograms`}>
      <FormattedMessage id={content} />
    </p>
  </div>
)

const DTSVoluntario = () => (
  <div>
    <DTSInfoCard icon={Activo}>
      <h1 className='contentPrograms mb-4'>
        {/* <h1 className={`${styles['subtitleVoluntario']} mb-4`}>  estilo con el que estaba antes*/}
        <FormattedMessage id='dare.typesVolunt' />
      </h1>
      <DTSVoluntarioRow title='dare.actTit' content='dare.actTxt' />
      <DTSVoluntarioRow title='dare.remoteTit' content='dare.remoteTxt' />
      <DTSVoluntarioRow title='dare.comiteTit' content='dare.comiteTxt' />
    </DTSInfoCard>
  </div>
)

export default DTSVoluntario
