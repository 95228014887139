import React, { Fragment } from 'react'
import { Col, Row, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap'
import { isEmpty } from '../../util/inputsHelpers'
import { FormattedMessage } from 'react-intl'

const InputField = props => {
  return (
    <FormGroup>
      <Label for={props.name}>{props.label}</Label>
      <Input
        type={props.type}
        name={`fields.${props.name}`}
        valid={props.valid}
        invalid={props.invalid}
        value={props.value}
        onChange={props.handleChange}
      >
        {props.children}
      </Input>
      <FormFeedback valid={props.valid}>
        <ul className="list-unstyled">
          {!isEmpty(props.feed) && props.feed.map(f => (<li className="">
            {f}
          </li>))}
        </ul>
      </FormFeedback>
      <FormText>{!isEmpty(props.text) && props.text}</FormText>
    </FormGroup>
  )
}

const DonationsForm = ({ fields, handleChange, subsedes, user }) => {
  return (
    <Fragment>
      <Form>
        <Row>
          <Col md={6}>
            <InputField
              name="email"
              type="email"
              label={<FormattedMessage id="label.email" />}
              valid={fields.email.valid}
              invalid={fields.email.invalid}
              handleChange={handleChange}
              value={fields.email.value}
              // placeholder="(USD)"
              feed={fields.email.feed}
              text={fields.email.text}
            />
          </Col>

          <Col md={3}>
            <InputField
              name="is"
              type="is"
              label="IS"
              valid={fields.is.valid}
              invalid={fields.is.invalid}
              handleChange={handleChange}
              value={fields.is.value}
              feed={fields.is.feed}
              text={fields.is.text}
            />
          </Col>

          <Col md={3}>
            <InputField
              name="phone"
              type="phone"
              label={<FormattedMessage id="label.phone" />}
              valid={fields.phone.valid}
              invalid={fields.phone.invalid}
              handleChange={handleChange}
              value={fields.phone.value}
              feed={fields.phone.feed}
              text={fields.phone.text}
            />
          </Col>

          <Col md={6}>
            <InputField
              name="rol"
              type="select"
              label={<FormattedMessage id="modal.role" />}
              valid={fields.rol.valid}
              invalid={fields.rol.invalid}
              handleChange={handleChange}
              value={fields.rol.value}
              feed={fields.rol.feed}
              text={fields.rol.text}
            >
              <Fragment>
                <option value="maintainer">Maintainer</option>
                {user.rol === 'corporative' && <option value="corporative">Corporative</option>}
                {user.rol === 'corporative' && <option value="reporter">Reporter</option>}
                <option value="softtekian">Softtekian</option>
              </Fragment>
            </InputField>
          </Col>

          <Col md={6}>
            <InputField
              name="subsede"
              type="select"
              label={<FormattedMessage id="modal.subsede" />}
              valid={fields.subsede.valid}
              invalid={fields.subsede.invalid}
              handleChange={handleChange}
              value={!isEmpty(fields.subsede) && fields.subsede.value}
              disabled={isEmpty(subsedes)}
              feed={fields.subsede.feed}
              text={fields.subsede.text}
            >
              <Fragment>
                {isEmpty(fields.subsede.value) && <option disabled></option>}
                {subsedes.filter(r => r.active).map((option, i) => 
                  <option key={i} value={option._id}>{option.name}</option>
                )}
              </Fragment>
            </InputField>
          </Col>
      </Row>
    </Form>
  </Fragment>
  )
}

export default DonationsForm
