import React, { useState } from 'react'
import { Form } from 'reactstrap'
import axios from 'axios'
import { reduxForm } from 'redux-form'
import { ButtonsDeck } from '../common/Container/FormContainer'
import { withForm } from '../../util/withForm'
import { RFInput, RFSelect } from '../Inputs/RFFields'
import { required } from '../../util/form'

function MailingForm({ handleSubmit, label, title, ...props }) {
  const [template, setTemplate] = useState(null)
  const [preview, setPreview] = useState(null)
  const templates = [{ value: 'end-of-year-not-participated-us', label: 'Agradecimiento US' }]
  const labels = templates.reduce((pre, cur) => ({ ...pre, [cur.value]: cur.label }), {})

  const handleTemplateChange = (_event, value) => {
    setTemplate(value)
    axios
      .get(`/api/mailing/template/${value}`)
      .then(({ data }) => {
        setPreview(data)
      })
      .catch(error => {
        console.log('Error')
      })
  }

  return (
    <div className='col-12'>
      <div className='header-h2'>
        <h1 className='h3 mb-2 text-gray-800'>{title}</h1>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <div className='card shadow my-4'>
            <div className='card-header py-3 d-flex justify-content-between'>
              <h6 className='m-0 font-weight-bold text-primary'>{label}</h6>
            </div>
            <div className='card-body'>
              <Form>
                <RFSelect
                  name='template'
                  label='Plantilla de correo'
                  options={templates}
                  onChange={handleTemplateChange}
                  validate={[required]}
                />
                <RFInput
                  name='emails'
                  type='textarea'
                  label='Correos'
                  style={{ width: '100%' }}
                  // normalize={limit200}
                  validate={[required]}
                />
              </Form>
              <div className='mx-2 px-1'>
                <ButtonsDeck onClick={handleSubmit} {...props} />
              </div>
            </div>
          </div>
        </div>
        {template && (
          <div className='col-md-6 h-100'>
            <div className='card shadow my-4'>
              <div className='card-header py-3 d-flex justify-content-between'>
                <h6 className='m-0 font-weight-bold text-primary'>{labels[template]}</h6>
              </div>
              <div className='card-body'>
                <iframe title='preview' srcDoc={preview} width='100%' height='400px' />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default withForm(reduxForm({ form: 'mailing' })(MailingForm))
