export default {

  fields: {
    email: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    },
    is: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    },
    phone: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    },
    rol: {
      value: 'maintainer',
      valid: null,
      invalid: null,
      feed: []
    },
    // sede: {
    //   value: false,
    //   valid: null,
    //   invalid: null,
    //   feed: []
    // },
    subsede: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    }
  },

  btnSave: false,
  btnUpdate: false,
  btnAdd: true,
  btnCancel: false,
  form: false,

  modal: false,
  item: {}
}