import React, { Fragment } from 'react'
import Footer from '../Common/Footer/Footer'
import Navbar from '../Common/Navbar/Navbar'
import ValuesInfoSection from './../OurValuesSection/ValuesInfoSection/ValuesInfoSection'
import ValuesImageSection from './../OurValuesSection/ValuesImageSection/ValuesImageSection'
import ValuesMotivationSection from './../OurValuesSection/ValuesMotivationSection/ValuesMotivationSection'

// Solution to scroll restoration
import ScrollToTopOnMount from '../Common/ScrollToTopOnMount'

function OurValues() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <Navbar />
      <ValuesInfoSection />
      <ValuesImageSection />
      <ValuesMotivationSection />
      <Footer />
    </Fragment>
  )
}

export default OurValues