import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback
} from 'reactstrap'
import { connect } from 'react-redux'
import { actions } from '../customer-project.redux'
import lenguaje from './../text'
import CustomerProjectLogo from './CustomerProjectLogo'
import { isEmail } from '../../../util/form'

const ModalConfirmation = ({ label, children, modal, toggle, onSubmit, ...match }) => {
  debugger
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{label}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button color='info' onClick={onSubmit}>
            {match.params.country === 'MX'
              ? lenguaje.MX.buttonConfirm
              : lenguaje.EU.buttonConfirm}
          </Button>{' '}
          <Button color='danger' onClick={toggle}>
            {match.params.country === 'MX'
              ? lenguaje.MX.buttonCancel
              : lenguaje.EU.buttonCancel}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

function CustomerProjectDonate({ project, donate, match }) {
  const [email, setEmail] = useState('')
  const [modal, setModal] = useState(false)
  const [error, setError] = useState('')
  const [touched, setTouched] = useState(false)

  const message =
    match.params.country === 'MX' ? lenguaje.MX.validEmail : lenguaje.EU.validEmail

  const toggle = () => {
    if (!error) setModal(modal => !modal)
  }

  function handleChangeEmail(event) {
    const { value } = event.target
    const validation = isEmail(value, message)
    setError(validation)
    setEmail(value)
    if (touched === false) setTouched(true)
  }

  function handleDonate(event) {
    event.preventDefault()
    donate({ slug: project.slug, email, lang: match.params.country === 'MX' ? 'MX' : 'EU' })
    toggle()
  }

  return (
    <>
      <CardPanel
        title={
          match.params.country === 'MX' ? lenguaje.MX.panelTitle : lenguaje.EU.panelTitle
        }>
        <CustomerProjectLogo alt={project.title} src={project.logo.path} />
        <form onSubmit={toggle} className='card-body miniCardBody'>
          <Input
            type='email'
            className='w-100 mb-3'
            placeholder='email'
            onChange={handleChangeEmail}
            value={email}
            valid={touched && !error}
            invalid={touched && !!error}
          />
          <FormFeedback>{error}</FormFeedback>
          <p style={{ fontSize: '10px' }}>
            {match.params.country === 'MX' ? lenguaje.MX.donateText : lenguaje.EU.donateText}
            {match.params.country === 'MX' ? (
              undefined
            ) : (
              <a href='https://www.softtek.com/privacy-notice'> here.</a>
            )}
          </p>
          <Button
            block
            color='info'
            type='button'
            style={{ fontSize: 17 }}
            disabled={!email}
            onClick={toggle}>
            {match.params.country === 'MX'
              ? lenguaje.MX.donateButton
              : lenguaje.EU.donateButton}
          </Button>
          {match.params.country === 'MX' ? (
            <div></div>
          ) : (
            <div style={{ fontSize: '10px', paddingTop: '10px' }}>
              <u>Important:</u>
              <li>The donation of 30 meals will be made to one organization.</li>
              <li>
                The meals will be donated by Softtek anonymously;{' '}
                <u>your data will not be shared with anyone.</u>
              </li>
            </div>
          )}
        </form>
      </CardPanel>

      <ModalConfirmation
        {...match}
        modal={modal}
        toggle={toggle}
        label={
          match.params.country === 'MX'
            ? lenguaje.MX.modalConfirmlabel
            : lenguaje.EU.modalConfirmlabel
        }
        onSubmit={handleDonate}>
        {match.params.country === 'MX'
          ? `Tu donación será destinada a ${project.title} si tu correo es ${email} ¿Deseas confirmar tu elección?`
          : lenguaje.EU.modalConfirmtext}
      </ModalConfirmation>
    </>
  )
}

function CardPanel({ title, children }) {
  return (
    <div className='card mb-4 box-shadowCard miniCard customer-project-card'>
      <div className='card-header miniCardHeader customer-project-card-header'>
        <h4 className='my-0'>{title}</h4>
      </div>
      <>{children}</>
    </div>
  )
}

const mapDispatchToProps = { donate: actions.publicDonateCustomerProjectRequest }
const withConnect = connect(null, mapDispatchToProps)

export default withConnect(injectIntl(CustomerProjectDonate))
