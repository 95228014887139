import { updatedItem } from '../../util/helpers'
import {
  UPDATE_INITIATIVE,
  GET_All_INITIATIVE
} from '../actions/types'

const initialState = {
  list: [],
  view: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_INITIATIVE:
      return {
        ...state,
        list: updatedItem(state.list, action.payload)
      }
    case GET_All_INITIATIVE:
      return {
        ...state,
        list: [...action.payload]
      }
    default:
      return state
  }
}