import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery'

import './MiniGallerie.css'
import "react-image-gallery/styles/css/image-gallery.css"
 
class MiniGallerie extends Component {
  render() {
    return (
      <ImageGallery
        items={this.props.items}
        useBrowserFullscreen={false}
        showBullets
        showNav
        showIndex
      />
    )
  }
}

export default MiniGallerie