import { isEmpty } from './util/isEmpty'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './assets/css/custom.css'
import React, { Component, Fragment } from 'react'
import Loading from './components/common/Loading/Loading'
import LoadingPage from './components/common/LoadingPage/LoadingPage'
import Error from './components/Error/Error'
import Router from './routes/Router'
import { addLocaleData, IntlProvider } from 'react-intl'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import pt from 'react-intl/locale-data/pt'
import zh from 'react-intl/locale-data/zh'
import Alert from './components/Alert/Alert'
import { flattenMessages } from './utils'
import 'flag-icon-css/css/flag-icon.css'
import messages from './lang/language'
import './assets/css/stylish-portfolio.css'
import './assets/css/weshare.css'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css'

addLocaleData([...en, ...es, ...pt, ...zh])

class App extends Component {
  state = { local: 'en-US' }

  componentWillReceiveProps({ location }) {
    const { languageCode } = location
    if (!isEmpty(languageCode)) {
      const local = languageCode
      this.setState({ local })
    } else {
      let local = { local: 'en-US' }
      this.setState({ local })
    }
  }

  render() {
    const local = this.state.local
    return (
      <IntlProvider locale={local} messages={flattenMessages(messages[local])}>
        <Fragment>
          <Alert />
          <Error />
          <Loading />
          <LoadingPage />
          <Router />
        </Fragment>
      </IntlProvider>
    )
  }
}

App.propTypes = {
  language: PropTypes.object.isRequired
}

const mapStateToProps = ({ location }) => ({ location })
export default connect(mapStateToProps, null)(App)
