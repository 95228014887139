import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MiniCard from './../../../../Campaign/component/Report/MiniCard'
import { injectIntl } from 'react-intl'
export class ConnectionsRow extends Component {

  render() {
    const { intl: { formatMessage } } = this.props
    const { connections, count } = this.props.socket.sockets
    return (
      <div className="row">
        <MiniCard
          className='col-xl-3 col-md-6 mb-4'
          color='info'
          label={formatMessage({ id: 'dash.cardUsers' })}
          value={count}
          icon='fa fa-users' />
        <MiniCard
          className='col-xl-3 col-md-6 mb-4'
          color='success'
          label={formatMessage({ id: 'dash.cardAdmins' })}
          value={connections.admins.length}
          icon='fa fa-users' />
        <MiniCard
          className='col-xl-3 col-md-6 mb-4'
          color='primary'
          label={formatMessage({ id: 'dash.cardSofttekians' })}
          value={connections.softtekians.length}
          icon='fa fa-users' />
        <MiniCard
          className='col-xl-3 col-md-6 mb-4'
          color='warning'
          label={formatMessage({ id: 'dash.cardVisits' })}
          value={connections.others.length}
          icon='fa fa-users' />
      </div>
    )
  }
}

ConnectionsRow.propTypes = {
  socket: PropTypes.object.isRequired,
}

const mapStateToProps = ({ socket }) => ({ socket })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConnectionsRow))