import React, { Component } from 'react'
import jsPDF from 'jspdf'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import html2canvas from 'html2canvas'
import { Row } from 'reactstrap'

import Admin from './../Layout/Admin/Admin'
import RowCards from './component/Report/Rows/RowCards'
import RowCharts from './component/Report/Rows/RowCharts'
import CollapsibleCard from './component/Report/CollapsibleCard'
import RowDonations from './component/Report/Rows/RowDonations'
import ClosingInformation from './component/Report/ClosingInformation'
import SummaryInformation from './component/Report/SummaryInformation'

import {
  getReportCampaing,
  getCleanReportCampaing
} from './../../redux/actions/campaingActions'

import { donationsColumns } from './Helpers/report'
import { isEmpty } from './../../util/isEmpty'
import { dmy, sorting } from './../../util/dateHelpers'

class CampaingIndividualReport extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getReportCampaing(id, this.props.intl)
  }

  componentWillUnmount() {
    this.props.getCleanReportCampaing()
  }

  exportPdf = title => {
    html2canvas(document.querySelector('#individual_report')).then(canvas => {
      const imgData = canvas.toDataURL('image/png')
      let pdf = null
      if (canvas.width > canvas.height) {
        pdf = new jsPDF('l', 'px', [canvas.width, canvas.height])
      } else {
        pdf = new jsPDF('p', 'px', [canvas.height, canvas.width])
      }
      pdf.addImage(imgData, 'PNG', 0, 0)
      pdf.save(`${title}.pdf`)
    })
  }

  render() {
    const { campaing, intl } = this.props
    const { report } = campaing
    const { cards, charts, donations } = report
    const tableData = !isEmpty(donations) ? sorting(donations) : donations
    const columns = donationsColumns(intl.formatMessage, dmy)

    return (
      <Admin>
        {!isEmpty(report) && (
          <div id='individual_report'>
            <div>
              <button
                className='btn'
                onClick={() => this.exportPdf(report.title)}
                style={{ background: 'firebrick', color: 'whitesmoke', float: 'right' }}>
                PDF
              </button>
            </div>
            <h3 className='h3 mb-2 text-gray-800'>{report.title}</h3>
            <RowCards status={cards.status} currency={report.currency} data={cards} />
            <RowCharts currency={report.currency} charts={charts} />
            <Row>
              <CollapsibleCard
                id='summary'
                label={intl.formatMessage({ id: 'report.summaryLabel' })}
                className='col-xl-12 col-lg-12'>
                <Row>
                  <SummaryInformation {...report} formatter={dmy} />
                </Row>
              </CollapsibleCard>
            </Row>
            <RowDonations label={report.title} data={tableData} columns={columns} />
            <Row>
              {report.closed && (
                <CollapsibleCard
                  id='closure'
                  label={intl.formatMessage({ id: 'report.closureLabel' })}
                  className='col-xl-12 col-lg-12'>
                  <ClosingInformation {...report} />
                </CollapsibleCard>
              )}
            </Row>
            <Row>
              {report.closed && report.closure_evidence && (
                <CollapsibleCard
                  id='closure-evidence'
                  label={intl.formatMessage({ id: 'label.evidence' })}
                  className='col-xl-12 col-lg-12'>
                  <img
                    alt=''
                    src={report.closure_evidence.path}
                    style={{ width: '65%', display: 'block', margin: 'auto' }}
                  />
                </CollapsibleCard>
              )}
            </Row>
          </div>
        )}
      </Admin>
    )
  }
}

const mapStateToProps = ({ campaing }) => ({ campaing })
const mapDispatchToProps = { getReportCampaing, getCleanReportCampaing }
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CampaingIndividualReport))
