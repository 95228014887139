import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Admin from './../Layout/Admin/Admin'
import PropTypes from 'prop-types'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import swal from '@sweetalert/with-react'
import { getAllInitiative, updateInitiative, getAllCleanInitiative } from './../../redux/actions/initiativeActions'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
// Hepers
import TableWrapper from './../common/TableWrapper'
import { dmy } from './../../util/dateHelpers'
import { isEmpty } from './../../util/helpers'
import { detailRow } from './../../util/report'
import { FormattedMessage, injectIntl } from 'react-intl'
import CSVButton from './../common/CSVButton'
import { today } from './../../util/dateHelpers'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import ExportExcel from '../common/ExportExcel/ExportExcel'

const ModalDetail = ({ contact, toggle, modal }) => (
	<Modal size="lg" isOpen={modal} toggle={toggle}>
		<ModalHeader toggle={toggle}>
			<FormattedMessage id="label.initiative" />
		</ModalHeader>
		<ModalBody>
			{!isEmpty(contact) && (
				<p>
					<strong>
						<FormattedMessage id="label.name" />:
					</strong>{' '}
					{contact.name} <br />
					<strong>
						<FormattedMessage id="label.email" />:
					</strong>{' '}
					{contact.email} <br />
					<strong>
						<FormattedMessage id="modal.subsede" />:
					</strong>{' '}
					{contact.subsede.name} <br />
					<strong>
						<FormattedMessage id="label.content" />:
					</strong>{' '}
					{contact.description} <br />
					<strong>
						<FormattedMessage id="general.date" />:
					</strong>{' '}
					{dmy(contact.createdAt)} <br />
				</p>
			)}
		</ModalBody>
		<ModalFooter>
			<Button color="secondary" onClick={toggle}>
				<FormattedMessage id="btn.cancel" />
			</Button>
		</ModalFooter>
	</Modal>
)

class ContactListPagination extends Component {
	handleContact = (contact) => {
		const { intl } = this.props
		swal({
			title: `${intl.formatMessage({ id: 'alert.suscriber' })} ${contact.name}?`,
			content: 'input',
			text: intl.formatMessage({ id: 'alert.confirmation' }),
			icon: 'info',
			buttons: true
		})
			.then(
				(description) =>
					!isEmpty(description) &&
					this.props.updateInitiative(
						contact._id,
						{
							contacted: true,
							detail: { cause: intl.formatMessage({ id: 'general.contacted' }), description }
						},
						intl
					)
			)
			.catch((e) => alert('Error'))
	}

	handleModal = (contact) => {
		this.setState({ contact })
		this.toggle()
	}

	// Toggle the modal
	toggle = () => this.setState((p) => ({ modal: !p.modal }))

	// True or false traductions
	contactedOptions = {
		false: this.props.intl.formatMessage({ id: 'general.negation' }),
		true: this.props.intl.formatMessage({ id: 'general.confirmation' })
	}

	// Columns configurations
	columns = [
		{
			dataField: 'name',
			text: this.props.intl.formatMessage({ id: 'label.name' }),
			sort: true
		},
		{
			dataField: 'email',
			text: this.props.intl.formatMessage({ id: 'label.email' }),
			sort: true
		},
		{
			dataField: 'contacted',
			text: this.props.intl.formatMessage({ id: 'general.contacted' }),

			formatter: (cell) => this.contactedOptions[cell]
		},
		{
			dataField: 'createdAt',
			text: this.props.intl.formatMessage({ id: 'label.dateRegistry' }),
			sort: true,
			formatter: dmy,

			csvFormatter: dmy
		},
		{
			dataField: '_id',
			text: this.props.intl.formatMessage({ id: 'label.continue' }),
			sort: true,
			csvExport: false,
			hidden: this.props.auth.user.rol === 'reporter',
			formatter: (cellContent, row) => (
				<UncontrolledDropdown>
					<DropdownToggle caret>
						<i class="fas fa-list-ul" />
					</DropdownToggle>
					<DropdownMenu>
						{!row.contacted && (
							<DropdownItem>
								<Fragment>
									<Button
										color="success"
										className="btn btn-block mr-1"
										onClick={() => this.handleContact(row)}
									>
										<i className="fa fa-phone" /> <FormattedMessage id="btn.contact" />
									</Button>
								</Fragment>
							</DropdownItem>
						)}
						<DropdownItem>
							<Button
								color="primary"
								className="btn btn-block mr-1"
								onClick={() => this.handleModal(row)}
							>
								<i className="fas fa-eye" /> <FormattedMessage id="btn.see" />
							</Button>
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			)
		}
	]

	state = {
		contact: {},
		modal: false
	}

	componentDidMount() {
		if (isEmpty(this.props.initiative.list)) {
			this.props.getAllInitiative()
		}
	}

	componentWillUnmount() {
		this.props.getAllCleanInitiative()
	}

	render() {
		const { list } = this.props.initiative

		const columnsToExport = [
			{ title: 'Nombre', value: 'name' },
			{ title: 'Email', value: 'email' },
			{ title: 'Contactado', value: (row) => (row.contacted ? 'Sí' : 'No') },
			{ title: 'Fecha de Registro', value: (row) => dmy(row.createdAt) }
		]

		return (
			<Admin>
				<ToolkitProvider
					keyField="_id"
					data={list}
					columns={this.columns}
					bootstrap4={true}
					search={{
						searchFormatted: true
					}}
					exportCSV={{
						fileName: `InitiativesFS-${today()}.csv`,
						separator: ',',
						noAutoBOM: false,
						onlyExportFiltered: true,
						exportAll: false
					}}
				>
					{(props) => (
						<TableWrapper
							title={<FormattedMessage id="label.initiative" />}
							label={<FormattedMessage id="table.registry" />}
						>
							<BootstrapTable
								{...props.baseProps}
								pagination={paginationFactory()}
								filter={filterFactory()}
								expandRow={detailRow}
								className="table-responsive"
								bordered
								hover
							/>
							{/* <CSVButton {...props.csvProps} /> */}
							{!isEmpty(list) && (
								<ExportExcel data={list} columnsToExport={columnsToExport} tableName="Iniciativas" />
							)}
						</TableWrapper>
					)}
				</ToolkitProvider>
				<ModalDetail {...this} {...this.state} />
			</Admin>
		)
	}
}

ContactListPagination.propTypes = {
	auth: PropTypes.object.isRequired,
	initiative: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth, initiative }) => ({ auth, initiative })
const mapDispatchToProps = { getAllInitiative, updateInitiative, getAllCleanInitiative }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContactListPagination))
