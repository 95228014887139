import React from 'react'
import styles from './programs-section.module.css'
import ProgramsHealth from './ProgramsHealth/ProgramsHealth'
import ProgramsHusing from './ProgramsHusing/ProgramsHusing'
import ProgramsNature from './ProgramsNature/ProgramsNature'
import ProgramsCoexistence from './ProgramsCoexistence/ProgramsCoexistence'
import ProgramsEducation from './ProgramsEducation/ProgramsEducation'
import ProgramsImage from './ProgramsImage/ProgramsImage'

const ProgramsSection = () => (
  <section className="text-center">
    <div className={`${styles['fondoFlechaCirculo']}`}>
      <div className={`container`}>
        <ProgramsHealth />
        <ProgramsHusing />
        <ProgramsNature />
      </div>
    </div>

    <ProgramsImage />

    <br />
    <br />
    
    <div className={`${styles['fondoCorazon']}`}>
      <div className={`container`}>
        <ProgramsCoexistence />
        <ProgramsEducation />
      </div>
    </div>
  </section>
)

export default ProgramsSection