import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setNotify } from '../../redux/actions/notifyAction'
import Notifications, { notify } from 'react-notify-toast'

class Notify extends Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.notify.type) {
      const { type, message } = nextProps.notify
      notify.show(type, message)
    }
  }

  render() {
    return (<Notifications />)
  }
}

Notify.propTypes = { notify: PropTypes.object.isRequired }
const mapStateToProps = ({ notify }) => ({ notify })
export default connect(mapStateToProps, { setNotify })(Notify)

