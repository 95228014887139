import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { getVideo } from './../../../redux/actions/videoActions'
import ContentWrapper from './../../common/ContentWrapper/ContentWrapper'

// Helpers, static y resources
import { isEmpty } from './../../../util/isEmpty'
import Logo from './img/fundacion_solidaria_horizontal_blanco.svg'
import styles from './videoSection.module.css'
// Video a utilizar si nadie ha subido alguno
import videoBackup from './video/softtek_default_video.mp4'

class VideoSection extends Component {

  render() {
    const { video } = this.props.video.view
    const path = !isEmpty(video) ? video.path : videoBackup
    
    return(
      <section className={styles['backgroundColorVideo']}>
        <div className="container-fluid">
          <div className={`text-center ${styles['margin']}`}>
            <ContentWrapper
              pretitle={<FormattedMessage id="video.thanks" />}
              title={<FormattedMessage id="video.txt" />}
              pretitleClass={styles['pretitle']}
              titleClass={styles['title']}
            />
            <div className={`col-lg-10 col-md-12 mb-4 mov-video ${styles['video']}`}>
                <video key={path} className={`z-depth-1 ${styles['roundVideo']}`} autoPlay loop controls muted>
                  <source src={path} type="video/mp4" />
                </video>
              <img src={Logo} alt="Sample" className={styles['logoSolidaria']} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = ({ video }) => ({ video })
const mapDispatchToProps = { getVideo }
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(VideoSection))