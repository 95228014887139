import React from 'react'
import { Link } from 'react-router-dom'
import styles from './../carouselSection.module.css'
import ArrowSVG from './../../../common/SVG/ArrowSVG'

export default props => {
  // Destructuring the props
  const { project: { _id, title, description, thumbnail } } = props

  // If it is a project, the user can direct to the individual view clicking it
  const route = _id ? `/campaign/gallerie/${_id}`: '/'

  return (
    <div
      className={`${styles['h100']}`}
      style={{
        backgroundImage: `url(${thumbnail.path})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <Link to={route}>
        <div className={`d-md-block ${styles['carousel__caption']}`}>
          <div className={`${styles['carousel__caption-content']}`}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <ArrowSVG className={styles['carousel_arrow']} />
        </div>
      </Link>
    </div>
  )
}