import React, { Component, Fragment } from 'react'
import { Subtitle, Paragraph } from '../../Common/Tipography/Tipography'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export default class NoticePrivacyDefinitions extends Component {
  render() {
    return (
      <div>
        <Subtitle>{<FormattedMessage id='noticePrivacy.termsOfUse.title' />}</Subtitle>
        <Paragraph>
          {/* Lista ordenada de Términos de uso */}
          <ol>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l1' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c1' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l2' />}
                </strong>
                <br />
                <span>
                  {<FormattedHTMLMessage id='noticePrivacy.termsOfUse.content.c2' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l3' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c3' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l4' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c4' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l5' />}
                </strong>
                <br />
                <span>
                  {<FormattedHTMLMessage id='noticePrivacy.termsOfUse.content.c5' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l6' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c6' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l7' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c7' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l8' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c8' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l9' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c9' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l10' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c10' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l11' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c11' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l12' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c12' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l13' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c13' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l14' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c14' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l15' />}
                </strong>
                <br />
                <span>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.c15' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
            <Fragment>
              <li>
                <strong>
                  {<FormattedMessage id='noticePrivacy.termsOfUse.content.l16' />}
                </strong>
                <br />
                <span>
                  {<FormattedHTMLMessage id='noticePrivacy.termsOfUse.content.c16' />}
                  <br />
                  <br />
                </span>
              </li>
              <br />
            </Fragment>
          </ol>
        </Paragraph>
      </div>
    )
  }
}
