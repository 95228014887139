import { updatedItem } from '../../util/helpers'
import {
  GET_All_ROL,
  ADD_ROL,
  UPDATE_ROL,
  GET_All_ROL_SUBSEDES,
  EDIT_ROL
} from '../actions/types'

const initialState = {
  list: [],
  edit: {},
  subsedes: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_All_ROL:
      return {
        ...state,
        list: [...action.payload]
      }

    case ADD_ROL:
      return {
        ...state,
        list: [action.payload, ...state.list]
      }

    case UPDATE_ROL:
      return {
        ...state,
        list: updatedItem(state.list, action.payload)
      }

    case EDIT_ROL:
      return {
        ...state,
        edit: { ...action.payload }
      }

    case GET_All_ROL_SUBSEDES:
      return {
        ...state,
        subsedes: [...action.payload]
      }

    default:
      return state
  }
}
