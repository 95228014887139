import React from 'react'
import { FormattedMessage } from 'react-intl'

import help1 from './../img/1.jpg'
import help2 from './../img/2.jpg'
import help3 from './../img/6.jpg'


export default [
  {
    project: {
      title: <FormattedMessage id="header.firstTit" />,
      objective: <FormattedMessage id="header.firstTxt" />,
      description: <FormattedMessage id="header.firstTxt" />,
      thumbnail: {
        path: help1
      }
    }
  },
  {
    project: {
      title: <FormattedMessage id="header.seconTit" />,
      objective: <FormattedMessage id="header.seconTxt" />,
      description: <FormattedMessage id="header.seconTxt" />,
      thumbnail: {
        path: help2
      }
    }
  },
  {
    project: {
      title: <FormattedMessage id="header.thirTit" />,
      objective: <FormattedMessage id="header.thirTxt" />,
      description: <FormattedMessage id="header.thirTxt" />,
      thumbnail: {
        path: help3
      }
    }
  }
]