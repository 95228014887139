import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
export class LocationInfo extends Component {
  componentWillReceiveProps(np) { }
  render() {
    const {
      geoplugin_continentName,
      geoplugin_countryName,
      geoplugin_countryCode,
      geoplugin_regionName,
      geoplugin_city,
      geoplugin_currencyCode,
      geoplugin_currencySymbol,
      geoplugin_timezone,
      geoplugin_currencyConverter,
      ip, } = this.props.location.geolocation
    return (
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary"><FormattedMessage id="dashboard.visit" /></h6>
        </div>
        <div className="card-body">

          <div className="row">
            <dl className="col">

              <dt className="col"><FormattedMessage id="label.continent" />: </dt>
              <dd className="col offset-sm-0">{geoplugin_continentName}</dd>

              <dt className="col"><FormattedMessage id="label.country" />: </dt>
              <dd className="col offset-sm-0">{geoplugin_countryName}</dd>

              <dt className="col"><FormattedMessage id="label.countryCode" />: </dt>
              <dd className="col offset-sm-0">{geoplugin_countryCode}</dd>


              <dt className="col"><FormattedMessage id="label.state" />: </dt>
              <dd className="col offset-sm-0">{geoplugin_regionName}</dd>

              <dt className="col"><FormattedMessage id="label.city" />: </dt>
              <dd className="col offset-sm-0">{geoplugin_city}</dd>

              <dt className="col">IP: </dt>
              <dd className="col offset-sm-0">{ip}</dd>

            </dl>

            <dl className="col">
              <dt className="col"><FormattedMessage id="label.currency" />: </dt>
              <dd className="col offset-sm-0">{geoplugin_currencyCode}</dd>

              <dt className="col"><FormattedMessage id="label.simbolCurrency" />: </dt>
              <dd className="col offset-sm-0">{geoplugin_currencySymbol}</dd>

              <dt className="col"><FormattedMessage id="label.changeDolar" />: </dt>
              <dd className="col offset-sm-0">{geoplugin_currencyConverter}</dd>

              <dt className="col"><FormattedMessage id="label.zone" />: </dt>
              <dd className="col offset-sm-0">{geoplugin_timezone}</dd>
            </dl>

          </div>

        </div>
      </div>
    )
  }
}

LocationInfo.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}
const mapStateToProps = ({ auth, location }) => ({ auth, location })
export default connect(mapStateToProps, {})(LocationInfo)