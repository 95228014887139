import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PaypalBtn from 'react-paypal-checkout'
import { isEmpty } from '../../../../util/isEmpty'
import styles from './paypalBtn.module.css'
import {
  Button,
  Modal as ModalB,
  ModalBody,
  Row,
  Col,
  Container,
  FormGroup,
  Input
} from 'reactstrap'
import { postDonation } from '../../../../redux/actions/donationsActions'
import { dispatchError } from '../../../../redux/actions/errorActions'
import { injectIntl, FormattedMessage } from 'react-intl'
import config from '../../../../config'
import { decryptionAES } from '../../../../util/encryption'
import { Link } from 'react-router-dom'
import PrivacyTermsContent from '../../PrivacyTerms/PrivacyTermsContent'

const initialState = {
  sandbox: '', // clientId
  production: '', // secret
  amount: 0,
  type: 'paypal',
  approved: true,
  checkLegal: false
}

export class PayPalBtn extends Component {
  state = {
    modal: false,
    currency: 'USD',
    ...initialState
  }

  componentDidMount() {
    const { sede } = this.props.location
    this.setState({ sede: sede._id, currency: sede.currency })
  }

  componentWillReceiveProps(np) {
    if (!isEmpty(np.location)) {
      const { sede } = np.location
      this.setState({ sede: sede._id, currency: sede.currency })
    }
  }

  toggle = () => {
    this.setState({ ...initialState, modal: !this.state.modal })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  // Congratulation, it came here means everything's fine!
  onSuccess = payment => {
    let paypal = { ...payment }
    const { sede, subsede, approved, amount, type, currency } = this.state
    const payload = { sede, subsede, approved, amount, type, paypal, currency }
    const { intl } = this.props
    this.props.postDonation(payload, intl, () => this.toggle())
    this.setState({ amount: 0 })
  }

  // User pressed "cancel" or close Paypal's popup!
  onCancel = data => {
    console.log({ data })
    this.toggle()
  }

  // The main Paypal's script cannot be loaded or somethings block the loading of that script!
  onError = error => {
    console.log(error)
    this.props.dispatchError(error)
  }

  render() {
    const { intl } = this.props
    const { sede } = this.props.location
    let canDonate = false
    let client = {}
    // validaciones para poder donar
    if (
      !isEmpty(sede) &&
      !isEmpty(sede._id) &&
      !isEmpty(sede.termsofPrivacy) &&
      !isEmpty(sede.paypal) &&
      !isEmpty(sede.paypal.account) &&
      !isEmpty(sede.paypal.clientId) &&
      !isEmpty(sede.paypal.secret)
    ) {
      canDonate = true
      const {
        paypal: { clientId }
      } = sede

      // Use the appropiated environment
      client =
        config.paypal === 'production'
          ? { production: decryptionAES(clientId, config.secret) }
          : { sandbox: decryptionAES(clientId, config.secret) }
    }

    return (
      <Fragment>
        {canDonate && (
          <Fragment>
            <Button
              size="lg"
              onClick={this.toggle}
              color=""
              className={`${styles['buttonDonation']}`}
            >
              <i className="fas fa-heart mr-3"></i>
              {/* <HeartSVG className={`img-fluid mb-3 ${styles['heart']} `} /> */}
              <FormattedMessage id="btn.donation" />
            </Button>

            <ModalB
              size="lg"
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={`${this.props.className}`}
            >
              <ModalBody>
                <Container fluid={true}>
                  <Row>
                    <Col md={{ size: 4, offset: 4 }}>
                      <h5 className="text-center">
                        <span className="text-muted">
                          <FormattedMessage id="table.amount" />{' '}
                          <small>${this.state.currency}</small>
                        </span>
                      </h5>
                      <FormGroup>
                        <Input
                          min={1}
                          type="number"
                          name="amount"
                          className="text-center"
                          value={this.state.amount}
                          onChange={this.handleInputChange}
                          disabled={!this.state.checkLegal}
                          placeholder={`$${this.state.currency}`}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 4, offset: 4 }}>
                      <ul className="list-unstyled">
                        <li className={!this.state.checkLegal && 'pointer-events-none'}>
                          <PaypalBtn
                            locale={'en_US'}
                            style={{
                              size: 'responsive',
                              shape: 'rect',
                              color: 'blue',
                              label: 'paypal'
                            }}
                            env={config.paypal}
                            client={client}
                            currency={this.state.currency}
                            total={this.state.amount}
                            onError={this.onError}
                            onSuccess={this.onSuccess}
                            onCancel={this.onCancel}
                          />
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                      <div className="text-center">
                        <label className="word-break-normal">
                          <input
                            type="checkbox"
                            name="checkLegal"
                            checked={this.state.checkLegal}
                            onChange={this.handleInputChange}
                          />{' '}
                          {intl.formatMessage({ id: 'label.confirmOfRead' })}
                          <Link to="/notice-privacy">
                            {intl.formatMessage({ id: 'label.noticeOfPrivacy' })}
                          </Link>
                        </label>
                      </div>
                      {this.state.checkLegal && (
                        <div className="terms-conditions-small-container">
                          <PrivacyTermsContent className="my-3" />
                        </div>
                      )}
                      <p className="word-break-normal text-center">
                        {' '}
                        {intl.formatMessage({ id: 'paypal.head' })}{' '}
                        <a href="mailto:fundacion.solidaria@softtek.com">
                          fundacion.solidaria@softtek.com
                        </a>
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                      <p className="text-center word-break-normal">
                        <span className="text-muted word-break-normal">
                          {intl.formatMessage({ id: 'label.consult' })}{' '}
                          <Link to="/terms-and-conditions">
                            {intl.formatMessage({
                              id: 'noticePrivacy.introduction.title'
                            })}
                          </Link>{' '}
                          {intl.formatMessage({ id: 'label.site' })}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </ModalB>
          </Fragment>
        )}
      </Fragment>
    )
  }
}
PayPalBtn.propTypes = {
  location: PropTypes.object.isRequired,
  postDonation: PropTypes.func.isRequired,
  dispatchError: PropTypes.func.isRequired
}
const mapStateToProps = ({ location }) => ({ location })
const mapDispatchToProps = { postDonation, dispatchError }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PayPalBtn))
