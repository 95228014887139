import React, { Fragment } from 'react'
import {
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon
} from 'react-share'

function ShareBar({ shareUrl, message }) {
	return (
		<Fragment>
			<div className="d-flex justify-content-center social-media-share-bar">
				<TwitterShareButton url={shareUrl} title={message}>
					<TwitterIcon size={30} round />
				</TwitterShareButton>
				<WhatsappShareButton url={shareUrl} title={message} separator=" ">
					<WhatsappIcon size={30} round />
				</WhatsappShareButton>
				<FacebookShareButton url={shareUrl} quote={message}>
					<FacebookIcon size={30} round />
				</FacebookShareButton>
				<LinkedinShareButton url={shareUrl} source={shareUrl} title={message} summary={message}>
					<LinkedinIcon size={30} round />
				</LinkedinShareButton>
			</div>
		</Fragment>
	)
}

export default ShareBar
