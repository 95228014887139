import React from 'react'
import Header from './../../../assets/svg/softtek-hollidays-2020.svg'

function ComponentMX() {
  return (
    <div className='mx-4 holiday-project-gallery-header'>
      <img src={Header} alt='Header' />
      <h2 className='my-4'>
        <strong>2020... En un año diferente, tu regalo es ayudar</strong>
      </h2>
      <p style={{ textAlign: 'justify' }}>
        En un año lleno de retos que nos han hecho reflexionar y cambiar, algo que no deseamos
        que cambie, es la oportunidad que estas épocas nos brindan para regalar y estamos
        convencidos de que el mejor obsequio ante una situación como la que hoy vivimos es
        apoyar a quienes más lo necesitan.
      </p>
      <p style={{ textAlign: 'justify' }}>
        En Softtek, cada año enviamos a nuestros clientes un presente alrededor de estas
        fechas. Ante un 2020 diferente, decidimos donar el monto de estos regalos a
        organizaciones que apoyan causas y poblaciones que hoy más que nunca necesitan el apoyo
        de todos nosotros.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          Realizaremos esta donación que equivalen a 5 despensas para 30 días a través de
          nuestra Fundación Solidaria y queremos pedirte que nos ayudes a decidir la causa a la
          que te gustaría que tu regalo sea entregado
        </strong>
        , por lo que solicitamos nos ayudes a completar los siguientes pasos para realizar tu
        aportación.
      </p>
      <ol>
        <li>
          Conoce en esta misma página las organizaciones que nuestro comité de Fundación
          Solidaria ha seleccionado dando click en el botón “Conoce más y apoya”.
        </li>
        <br></br>
        <li>
          Una vez que seleccionaste la organización de tu preferencia, da click en el botón
          "Apoyar esta causa" para que realicemos la donación equivalente a despensas para 5
          personas durante 30 días. Sólo puedes seleccionar una causa y donar una sola vez.
        </li>
        <br></br>
        <li>
          Listo, Fundación Solidaria realizará la aportación. Recibirás un correo confirmando
          la donación a la asociación que elegiste.
        </li>
        <br></br>
      </ol>
      <h5 style={{ textAlign: 'center' }}>
        ¡Gracias por ser parte de esta iniciativa y ayudar a quienes más lo necesitan!
      </h5>
      <p style={{ textAlign: 'center' }}>
        Nota: Realizaremos la donación anónimamente.{' '}
        <u>No compartiremos tus datos con nadie.</u>
      </p>
    </div>
  )
}

export default ComponentMX
