import React, { Fragment } from 'react'
import { Col, Row } from 'reactstrap'
import MultiSelectData from './../../common/Inputs/MultiSelectData'
import SelectData from './../../common/Inputs/SelectData'
import FormGroupCustom from '../lib/FormGroupCustom'
import { FormattedMessage } from 'react-intl';
const CategoriesForm = ({ categorie, cause, activities, skills, type, handleInputChange, handleChangeSelectData }) => {
  return (
    <Fragment>
      {/* Catalogs Selects */}
      <Row form>
        <Col>
          <FormGroupCustom forp="categorie" label={<FormattedMessage id="modal.categorie" />} {...categorie}>
            <SelectData
              name='categorie'
              route='api/categorie'
              valid={categorie.valid}
              onChange={handleChangeSelectData}
              selectedValue={categorie.value}
            />
          </FormGroupCustom>
        </Col>
        <Col>
          <FormGroupCustom forp="cause" label={<FormattedMessage id="modal.cause" />} {...cause}>
            <SelectData
              name='cause'
              route='api/cause'
              valid={cause.valid}
              onChange={handleChangeSelectData}
              selectedValue={cause.value}
            />
          </FormGroupCustom>
        </Col>
      </Row>

      {/* Activities and skills  */}
      <Row form>
        <Col>
          <FormGroupCustom forp="activities" label={<FormattedMessage id="modal.activitie" />} {...activities}>
            <MultiSelectData
              route='api/activitie'
              name='activities'
              valid={activities.valid}
              onChange={handleChangeSelectData}
              selectedValues={activities.value}
            />
          </FormGroupCustom>
        </Col>
      </Row>

      {
        type.value === 'probono' && (
          <Row form>
            <Col>
              <FormGroupCustom forp="skills" label={<FormattedMessage id="label.skill" />} {...skills}>
                <MultiSelectData
                  route='api/skill'
                  name='skills'
                  valid={skills.valid}
                  onChange={handleChangeSelectData}
                  selectedValues={skills.value}
                />
              </FormGroupCustom>
            </Col>
          </Row>
        )
      }
    </Fragment>
  )
}

export default CategoriesForm
