import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function TableContainer(props) {
  const { title, subtitle, children, view, toggle, showButtons = true, route, button } = props
  const { pathname } = useLocation()
  const action = pathname.split('/')[3]

  return (
    <div className='col-12 mb-4'>
      {title && <h1 className='h3 mb-2 text-gray-800'>{title}</h1>}
      <div className='card shadow my-4'>
        <div className='card-header py-3 px-0'>
          <div className='flex-row align-items-center d-flex justify-content-between px-4'>
            <span className='m-0 font-weight-bold text-primary'>{subtitle}</span>
            {showButtons && (
              <div>
                {action === 'view' && <ButtonsView view={view} toggle={toggle} />}
                {action === 'list' && <ButtonCreate route={route} />}
                {button}
              </div>
            )}
          </div>
        </div>
        <div className='card-body table-responsive pb-2'>{children}</div>
      </div>
    </div>
  )
}

const ButtonsView = ({ toggle, view }) => {
  return (
    <>
      <Link className='no-style mr-2' to={`../edit/${view._id}`}>
        <button className='btn btn-info'>
          <i className='icon-edit mr-2' style={{ verticalAlign: 'text-bottom' }} />
          Editar
        </button>
      </Link>
      <button
        className={`btn btn-${view.active ? 'danger' : 'success'} mr-2`}
        onClick={() => toggle(view)}>
        <i className='icon-power-off mr-2' style={{ verticalAlign: 'text-bottom' }} />
        {view.active ? 'Desactivar' : 'Activar'}
      </button>
    </>
  )
}

export function ButtonCreate({ style, route, icon, label }) {
  return (
    <Link className='float-right' style={style} to={route}>
      <button className='btn btn-primary d-flex align-items-center'>
        <i className={`${icon} mr-2`} />
        <span>{label}</span>
      </button>
    </Link>
  )
}

ButtonCreate.defaultProps = {
  route: './create',
  icon: 'fas fa-plus',
  label: 'Agregar'
}

export default TableContainer
