import React from 'react'

export default function UserSVG(props) {
  return (
  <svg xmlns='http://www.w3.org/2000/svg' width='22.338' height='22.338' viewBox='0 0 22.338 22.338' {...props}>
      <path id='Path_59' data-name='Path 59' d='M15.652,11.854a9.134,9.134,0,0,0-3.484-2.185,5.3,5.3,0,1,0-6,0A9.183,9.183,0,0,0,0,18.338H1.433a7.736,7.736,0,1,1,15.472,0h1.433A9.109,9.109,0,0,0,15.652,11.854ZM9.169,9.169A3.868,3.868,0,1,1,13.037,5.3,3.872,3.872,0,0,1,9.169,9.169Z'
      transform='translate(2 2)' stroke='#506ff4' strokeWidth='2'
      />
  </svg>
  )
}
