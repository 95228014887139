import React, { Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import { isEmpty } from './../../../../util/isEmpty'
import { FormattedMessage } from 'react-intl'
import { formatCurrency } from '../../../../util/formatCurrency'

const ClosingInformation = props => {
  const {
    fundsRaised,
    fundsRaisedOriginal,
    closure_actions,
    closure_comments,
    closure_inversion,
    closure_inversion_original,
    closure_benefited,
    closure_volunteers,
    closure_collaborators,
    closure_volunteeringHours,
    closure_inversion_currency,
    currency
  } = props

  return (
    <Row>
      <Col md={4}>
        <h5 className='h5 mb-2 text-gray-800'>
          <FormattedMessage id='report.closureDonations' />:
        </h5>
        <ul>
          <li>
            <strong>
              <FormattedMessage id='report.closureDonations' />:
            </strong>{' '}
            {`${formatCurrency(fundsRaised)} USD`}
          </li>
          <li>
            <strong>
              <FormattedMessage id='report.closureInversion' />:
            </strong>{' '}
            {`${formatCurrency(closure_inversion)} USD`}
          </li>
          {currency !== 'USD' && (
            <Fragment>
              <hr />
              <li>
                <strong>
                  <FormattedMessage id='report.closureDonations' />:
                </strong>{' '}
                {`${formatCurrency(fundsRaisedOriginal)} ${currency}`}
              </li>
              {closure_inversion_original > 0 && closure_inversion_currency !== null && (
                <li>
                  <strong>
                    <FormattedMessage id='report.closureInversion' />:
                  </strong>{' '}
                  {`${formatCurrency(
                    closure_inversion_original
                  )} ${closure_inversion_currency}`}
                </li>
              )}
            </Fragment>
          )}
        </ul>
      </Col>
      <Col md={4}>
        <h5 className='h5 mb-2 text-gray-800'>
          <FormattedMessage id='report.closureVolunteering' />:
        </h5>
        <ul>
          <li>
            <strong>
              <FormattedMessage id='report.closureVolunteers' />:
            </strong>{' '}
            {closure_volunteers}
          </li>
          <li>
            <strong>
              <FormattedMessage id='report.closureCollaborators' />:
            </strong>{' '}
            {closure_collaborators}
          </li>
          <li>
            <strong>
              <FormattedMessage id='report.closureHoursVolunteering' />:
            </strong>{' '}
            {closure_volunteeringHours}
          </li>
        </ul>
      </Col>
      <Col md={4}>
        <h5 className='h5 mb-2 text-gray-800'>
          <FormattedMessage id='report.closureBeneficiaries' />:
        </h5>
        <ul>
          <li>
            <strong>
              <FormattedMessage id='report.closureBeneficiaries' />:
            </strong>{' '}
            {closure_benefited}
          </li>
          {!isEmpty(closure_comments) && (
            <li>
              <strong>
                <FormattedMessage id='report.closureComments' />:
              </strong>{' '}
              {closure_comments}
            </li>
          )}
          <li>
            <strong>
              <FormattedMessage id='general.actions' />:
            </strong>{' '}
            {closure_actions}
          </li>
        </ul>
      </Col>
    </Row>
  )
}

ClosingInformation.defaultProps = {
  closure_inversion_original: 0
}

export default ClosingInformation
