// //  ____                          _       _              _
// // |  _ \    ___    __ _    ___  | |_    | |__     ___  | |  _ __     ___   _ __   ___ 
// // | |_) |  / _ \  / _` |  / __| | __|   | '_ \   / _ \ | | | '_ \   / _ \ | '__| / __|
// // |  _ <  |  __/ | (_| | | (__  | |_    | | | | |  __/ | | | |_) | |  __/ | |    \__ \
// // |_| \_\  \___|  \__,_|  \___|  \__|   |_| |_|  \___| |_| | .__/   \___| |_|    |___/
// //                                                          |_|                        

export const validationsOnType = (state, valitions, prop) => {

  const fieldsenter = state.fields
  const props = prop.split('.')
  const propName = props[props.length - 1]
  const arrayState = Object.keys(fieldsenter).map(key => ({ field: key, value: fieldsenter[key].value }))
  const field = arrayState.find(f => f.field === propName)
  if (field) {
    const validation = valitions.find(v => v.field === field.field)
    if (!isEmpty(validation)) {
      const r = validation.function(field.value, state)
      let fields = { ...fieldsenter, [field.field]: { ...fieldsenter[field.field], ...r } }
      return fields
    } else {
      let fields = fieldsenter
      return fields
    }
  }
}

export const validationsOnSubmit = (state, valitions, prop = null) => {

  let stateValidated = {}
  const { fields } = state
  const arrayState = Object.keys(fields).map(key => ({ field: key, value: fields[key].value }))
  for (let ob of arrayState) {
    const validation = valitions.find(v => v.field === ob.field)
    if (validation) {
      const r = validation.function(ob.value, state)
      if (!isEmpty(r)) {
        stateValidated = { ...stateValidated, [ob.field]: { ...fields[ob.field], ...r } }
      }
    }
  }
  const isValid = validateState(stateValidated)
  return { isValid, stateValidated }
}

export const specialValidationsOnChange = (state, valitions, prop) => {
  let stateValidated = {}
  const { fields } = state
  const props = prop.split('.')
  const propName = props[props.length - 1]
  const arrayState = Object.keys(fields).map(key => ({ field: key, value: fields[key].value }))
  const field = arrayState.find(f => f.field === propName)
  if (field) {
    const validation = valitions.find(v => v.field === field.field)
    if (validation) {
      const r = validation.function(field.value, fields)
      if (!isEmpty(r)) {
        stateValidated = { ...fields, [field.field]: { ...fields[field.field], ...r } }
      }
    }
    const isValid = validateState(stateValidated)
    return { isValid, stateValidated }
  }
  return fields
}


// cuando camabia el componente select data custom.
export const handleChangeSelectData = ({ name, value }) => {
  const hasLevel = name.includes('.')
  if (hasLevel) {
    const props = name.split('.')
    const childs = name.split('.').length
    switch (childs) {
      case 2:
        return (state, prop) => ({ [props[0]]: { ...state[props[0]], [props[1]]: { ...state[props[0]][props[1]], value } } })
      case 3:
        return (state, prop) => ({ [props[0]]: { ...state[props[0]], [props[1]]: { ...state[props[0]][props[1]], [props[2]]: { ...state[props[0]][props[1]][props[2]], value } } } })
      default:
        return
    }
  }
  // no childs
  return (state, prop) => ({ [name]: { ...state[name], value } })
}




export const onChangeState = event => {

  const target = event.target
  const name = target.name
  const value = target.type === 'checkbox' ? target.checked : target.value
  const hasLevel = name.includes('.')
  if (hasLevel) {
    const props = name.split('.')
    const childs = name.split('.').length
    switch (childs) {
      case 2:
        return (state, prop) => ({ [props[0]]: { ...state[props[0]], [props[1]]: { ...state[props[0]][props[1]], value } } })
      case 3:
        return (state, prop) => ({ [props[0]]: { ...state[props[0]], [props[1]]: { ...state[props[0]][props[1]], [props[2]]: { ...state[props[0]][props[1]][props[2]], value } } } })
      default:
        return
    }
  }
  return (state, prop) => ({ [name]: { ...state[name], value } })
}

// devuelve si un objeto , string, array 
// esta vacio devuelve TRUE.
export const isEmpty = value => (
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0) ||
  (Array.isArray(value) && value.length === 0)
)
// devuelve si un objeto , string, array 
// esta vacio devuelve TRUE si no devuelve 
// el parametro.
export const checkIsEmpty = value => isEmpty(value) ? isEmpty(value) : value

// checka si todos los elementos del state tienen la  
//  propiedad valid === true devuelve un Boolean.
export const validateState = s => (Object.keys(s).map(k => ({ ...s[k] }))
  .filter(o => {

    return o.valid !== null
  })
  .every(o => {
    return o.valid === true
  }))


// Función para determinar si se puede donar o no a un proyecto
export const canDonate = (sede, subsede, projectSede) => {
  if(isEmpty(sede) || isEmpty(subsede) || isEmpty(sede.bank) || isEmpty(sede.paypal) || isEmpty(subsede.email)){
    return 'No es posible donar a este proyecto'
  } else if(sede._id !== projectSede._id){
    return !isEmpty(projectSede.name) ?
      `Este proyecto pertenece a la sede Softtek ${projectSede.name}`:
      'Este proyecto pertenece a una sede Softtek diferente a la suya'
  } else {
    return true
  }
}

// Función que toma dos parametros, list y param, el primero debe ser un arreglo de objetos,
// el segundo la propiedad cuyo valor será el que regirá el agrupamiento de los elementos de la lista.
export const groupBy = (list, param) => list.reduce((a, b) => {
  let property = b[param]
  if(a.hasOwnProperty(property))
    a[property].push(b)
  else
    a[property] = [b]
  return a
},{})
