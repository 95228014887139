import React, { Component } from 'react'
import styles from './carouselSection.module.css'
import { connect } from 'react-redux'
import { isEmpty } from './../../../util/isEmpty'
import sampleData from './Helpers/sampleData'
import CarrouselSlide from './components/CarouselSlide'

class CarouselSection extends Component {
  // Method that separate an array into subarrays of 'chunk' (1 or 2) size
  // Example:
  // const array = [1, 2, 3, 4, 5]
  // this._divideSlides(array, 2)
  // -- Return value: [[1,2], [3,4], [5]] --
  _divideSlides = (slides, chunk) => {
    const result = []
    for (let i = 0; i < slides.length; i += chunk) {
      result.push(slides.slice(i, i + chunk))
    }
    return result
  }

  // Method that returns the current width of the device
  _getWidth = () => {
    return (
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    )
  }

  // Method to set into the state the current width
  updateDimensions = () => {
    this.setState({ width: this._getWidth() })
  }

  // When the component will mount, the devide width is set into the state
  componentWillMount = () => {
    this.updateDimensions()
  }

  // Add event listener for resizing the device width
  componentDidMount = () => {
    window.addEventListener('resize', this.updateDimensions)
  }

  // Remove event listener 'resize' when the component will unmount
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions)
  }

  render() {
    const { carrousel } = this.props
    // Name of the id for bootstrap jquery function to target the carrousel functionality
    const targetIndicators = 'carouselIndicators'
    // If there is no sede carrousel items, display sample Carrousel items
    const data = !isEmpty(carrousel.list) ? carrousel.list : sampleData
    // IF the width is greater than 1024 px, the carrousel slide will have 2 items, otherwise, just 1
    // const chunk = this.state.width >= 1024 ? 2 : 1
    // Divide the slides into the corresponding items p/slide
    const slides = this._divideSlides(data, 1)

    return (
      <header key='carrousel_section' className={styles['carousel']}>
        <div id={targetIndicators} className='carousel slide' data-ride='carousel'>
          <ol className='carousel-indicators'>
            {data.map((d, i) => (
              <li
                data-slide-to={i}
                key={`indicator_carrousel_${i}`}
                data-target={`#${targetIndicators}`}
                className={i === 0 && 'active'}
              />
            ))}
          </ol>

          <div
            key={`carrousel_${1}`}
            className={`carousel-inner ${styles['carrousel__desktop']}`}
            role='listbox'>
            {slides.map((d, i) => {
              return <CarrouselSlide items={slides[i]} className={i === 0 && 'active'} />
            })}
          </div>
        </div>
      </header>
    )
  }
}

const mapStateToProps = ({ carrousel }) => ({ carrousel })
export default connect(mapStateToProps)(CarouselSection)
