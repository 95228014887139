import React, { Component, Fragment } from 'react'
import io from '../../../../socket'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'


export default class Admincard extends Component {

  state = {
    usersonline: 0,
    projects: 0
  }

  // componentDidMount() {
  //   const usersonline = data => {
  //     this.setState(
  //       { usersonline: data.count },
  //       () => console.log(this.state)
  //     )
  //   }
  //   io.emit('usersonline', usersonline)
  //   io.on('usersonline', usersonline)

  //   axios
  //     .get('/api/project/count')
  //     .then(data => {
  //       this.setState({ projects: data.data.count })
  //     })
  //     .catch(e => alert(e.response.data))

  // }

  componentDidMount() {
    io.emit('usersonline', {})
    io.on('usersonline', data => {
      this.setState(
        { usersonline: data.count }
      )
    })
    axios
      .get('/api/project/count')
      .then(data => {
        this.setState({ projects: data.data.count })
      })
      .catch(e => alert(e.response.data))
  }

  componentWillUnmount() {
    //this.socket.close();
  }

  render() {
    return (
      <Fragment>
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1"><FormattedMessage id="dashboard.users" /></div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.usersonline}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-user fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 mb-4" >
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1"><FormattedMessage id="dashboard.projects" /></div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.projects}</div>
                </div>
                <div className="col-auto">
                  <i className="far fa-id-card fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}



