//import axios from 'axios'
import {
  SOCKET_AUTH,
  SOCKET_CONNECTION,
  SOCKET_DISCONNECT,
  SOCKET_DASHBOARD,
} from './types'

// @params:  
// @description:
// @return:

// @params:  
// @description:
// @return:
export const setConnections = payload => dispatch => (dispatch({ type: SOCKET_CONNECTION, payload }))

export const socketAuth = payload => dispatch => (dispatch({ type: SOCKET_AUTH, payload }))

export const socketDisconnect = payload => dispatch => (dispatch({ type: SOCKET_DISCONNECT, payload }))

export const socketDashboard = payload => dispatch => (dispatch({ type: SOCKET_DASHBOARD, payload }))