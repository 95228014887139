import React from 'react'

export default function TwitterSVG(props) {
  return (
    <svg id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 612 612' {...props}>
      <path className='st0' d='M503.2,214.6c0-4.7-0.2-9.2-0.3-13.8c19.8-14.7,37.1-33.4,50.6-54.5c-18.2,8.3-37.7,13.7-58.3,15.9 c21-12.9,37.1-33.5,44.6-58.3c-19.7,11.9-41.3,20.4-64.5,24.9c-18.5-21-44.9-34.4-74.2-35c-56-0.9-101.6,46.4-101.6,105.9 c0,8.4,0.9,16.7,2.7,24.5c-84.4-5.4-159.3-49.4-209.5-115.7C84.1,124.5,79,142.9,79,162.9c0,37.6,17.9,71.1,45.2,90.8 c-16.7-0.8-32.3-5.9-46-14.1c0,0.5,0,0.9,0,1.4c0,52.6,35,96.6,81.4,107c-8.6,2.4-17.6,3.6-26.7,3.6c-6.6,0-12.9-0.8-19.1-2.1 c12.9,43.1,50.5,74.5,94.8,75.6c-34.7,28.8-78.6,46.1-126.2,46c-8.3,0-16.2-0.6-24.2-1.5c44.9,30.8,98.4,48.8,155.7,48.8 C401,518.3,503.2,355.6,503.2,214.6z'
      />
    </svg>
  )
}
