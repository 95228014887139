import React, { Component, Fragment } from 'react'
import Footer from '../../Common/Footer/Footer'
import Navbar from '../../Common/Navbar/Navbar'
import { connect } from 'react-redux'
import axios from 'axios'
import swal from '@sweetalert/with-react'
import {
  onChangeState,
  handleChangeSelectData,
  specialValidationsOnChange,
  checkOnchange,
  specialValidations
} from '../../../../util/inputsHelpers'
import styles from './contact.module.css'
import { setNotify } from '../../../../redux/actions/notifyAction'
import { spinnerToggle } from '../../../../redux/actions/spinnerActions'
import ContactValidations from '../../../Dashboard/Catalogs/Contact/ContactValidations'
import ContactForm from '../../../Dashboard/Catalogs/Contact/ContactForm'
//import msg from '../../lang/message'
import { injectIntl } from 'react-intl'

// Solution to scroll restoration
import ScrollToTopOnMount from '../../Common/ScrollToTopOnMount'

export class Contact extends Component {
  state = {
    catalog: {},

    fields: {
      subsede: {
        value: '',
        valid: null,
        invalid: null,
        //text: msg.requerido,
        feed: []
      },
      name: {
        value: '',
        valid: null,
        invalid: null,
        //text: msg.requerido,
        feed: []
      },
      phone: {
        value: '',
        valid: null,
        invalid: null,
        //text: msg.requerido,
        feed: []
      },
      email: {
        value: '',
        valid: null,
        invalid: null,
        //text: msg.requerido,
        feed: []
      },
      message: {
        value: '',
        valid: null,
        invalid: null,
        //text: msg.requerido,
        feed: []
      }
    },
    create: false,
    list: [],
    item: {},
    errors: {}
  }

  componentWillMount() {
    this.props.spinnerToggle()
  }

  componentDidMount() {
    axios
      .get('/api/contact')
      .then(res => {
        this.props.spinnerToggle()
        this.setState(() => ({ list: [...res.data] }))
      })
      .catch(err => {
        this.props.spinnerToggle()
      })
  }

  handleChangeSelectData = props => {
    const field = props.name
    this.setState(handleChangeSelectData(props), () => {
      const { state } = this
      const validator = checkOnchange(state, ContactValidations, field)
      this.setState({ ...validator.state })
    })
  }

  handleChange = event => {
    const field = event.target.name
    this.setState(onChangeState(event), () => {
      const { state } = this
      const validator = specialValidationsOnChange(state.fields, ContactValidations, field)
      if (validator !== null) this.setState({ fields: { ...validator.stateValidated } })
    })
  }

  // @return       Void.
  // @description  handle the onChange event
  // for nested props
  onChangeObject = event => {
    const target = event.target
    const value = target.value
    const [prop, subprop] = target.name.split('.')
    this.setState(state => ({
      [prop]: { ...state[prop], [subprop]: value }
    }))
  }

  // @return        Void
  // Description    Create a element.
  onSubmit = async event => {
    let { intl } = this.props
    event.preventDefault()
    const r = specialValidations(this.state.fields, ContactValidations)
    if (!r.isValid) {
      this.setState({ fields: { ...r.stateValidated } })
    } else {
      this.props.spinnerToggle()
      this.setState({ create: true}) 
      const { subsede, name, phone, email, message } = this.state.fields
      const payload = {
        subsede: subsede.value,
        name: name.value,
        phone: phone.value,
        email: email.value,
        message: message.value
      }
      const route = '/api/contact'
      axios
        .post(route, payload)
        .then(res => {
          this.props.spinnerToggle()
          swal(
            intl.formatMessage({ id: 'alert.contactTh' }),
            intl.formatMessage({ id: 'alert.contactText' }),
            'success'
          )
          this.props.history.push('/')
        })
        .catch(err => {
          this.props.spinnerToggle()
          swal(
            intl.formatMessage({ id: 'alert.errorGeneralTitle' }),
            intl.formatMessage({ id: 'alert.errorGeneralText' }),
            'error'
          )
        })
    }
  }

  render() {
    return (
      <Fragment>
        <ScrollToTopOnMount />
        <div className={styles['contact-container']}>
          <Navbar />
          <ContactForm {...this} {...this.state} />
          <Footer />
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth, errors }) => ({ auth, errors })
const mapDispatchToProps = { setNotify, spinnerToggle }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Contact))
