import React from 'react'
import style from './campaingTagDate.module.css'

function TagDate(props) {
  const { icon, className, children, ...others } = props
  return (
    <li
      className={`list-inline-item ${style['tag-container']} ${className || ''}`}
      {...others}>
      <i className={`${icon} ${style['tag-icon']}`} />
      <strong className={style['tag']}>{children}</strong>
    </li>
  )
}

TagDate.propTypes = {}

export default TagDate
