import axios from 'axios'
import swal from '@sweetalert/with-react'
import {
  GET_ERRORS,
  POST_CONTACT,
  UPDATE_CONTACT,
  GET_All_CONTACT,
  GET_All_CLEAN_CONTACT
} from './types'
import { loadingPage } from './loadingpageAction'

// GET ALL CONVOCATORY
export const getAllContact = () => dispatch => {
  dispatch(loadingPage())
  axios
    .get('/api/contact')
    .then(r => dispatch({ type: GET_All_CONTACT, payload: r.data }))
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// POST Contact
export const postContact = (d, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .post(`/api/contact`, d)
    .then(r => dispatch({ type: POST_CONTACT, payload: r.data }))
    .then(() => swal(intl.formatMessage({ id: 'alert.contactCreate' }), '', 'success'))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// UPDATE Contact
export const updateContact = (id, d, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .put(`/api/contactcontacted/${id}`, d)
    .then(r => dispatch({ type: UPDATE_CONTACT, payload: r.data }))
    .then(() => swal(intl.formatMessage({ id: 'table.update' }), '', 'success'))
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// Clean the contact list
export const getAllCleanContact = () => ({ type: GET_All_CLEAN_CONTACT, payload: [] })