import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LoadingPageOverlay from './LoadingPageOverlay'

export function LoadingPage(props) {
  const { loading } = props.loadingpage
  return loading && <LoadingPageOverlay />
}

LoadingPage.propTypes = { loadingpage: PropTypes.object.isRequired }
const mapStateToProps = ({ loadingpage }) => ({ loadingpage })
export default connect(mapStateToProps)(LoadingPage)
