import React, { Fragment } from 'react'
import styles from './voluntarios-section.module.css'

const VoluntariosSection = ({ icon, title, title2, text, isActive }) => {
  return (
    <Fragment>
      {isActive && (
        <div className={`col-lg-6`} >
          <div className={`card ${styles['cardVolunteer']} mb-4`}>
            <img src={icon} alt="" className={styles['logoVolunteer']} />
            <h2 className={styles['titleText']}>
              {title}
            </h2>
            <div className={`mx-auto ${styles['volunteerTxt']}`}>
              <p className={`lead mb-0 ${styles['txtAuxiliarVolunteer']}`}>
                {title2} {text}
              </p>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default VoluntariosSection