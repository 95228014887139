import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import swal from '@sweetalert/with-react'
// import { setNotify } from './../../../../redux/actions/notifyAction'
// import { spinnerToggle } from './../../../../redux/actions/spinnerActions'
import { injectIntl, FormattedMessage } from 'react-intl'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { isEmpty } from './../../Helpers'

export class ModalSubscribe extends Component {

  state = {
    name: this.props.user.name,
    phone: '',
    email: this.props.user.email,
    // sede: '',
    // subsede: '',
    user: this.props.user._id
  }

  // Handle para agrega los valores del state
  // para inputs y checkbox
  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  componentWillReceiveProps(np) {
    // if (!isEmpty(np.location.sede)) {
    //   this.setState({ sede: np.location.sede._id, subsede: np.location.subsede._id })
    // }
    if (!isEmpty(np.auth.user)) {
      this.setState({ user: np.auth.user._id })
    }
  }

  componentDidMount() {
    // if (!isEmpty(this.props.location.sede)) {
    //   this.setState({ sede: this.props.location.sede._id, subsede: this.props.location.subsede._id })
    // }
    if (!isEmpty(this.props.auth.user)) {
      this.setState({ user: this.props.auth.user._id })
    }
  }

  handleValidSubmit = (event, values) => {
    const { intl } = this.props
    const { id } = this.props.match.params
    axios
      .put(`/api/project/subscribe/${id}`, values)
      .then(res => {
        this.props.toggle()
        swal(intl.formatMessage({ id: 'alert.convocatoryTh' }), intl.formatMessage({ id: 'alert.contactText' }), 'success')
      })
      .catch(err => {
        this.props.toggle()
        swal(intl.formatMessage({ id: 'alert.errorGeneralTitle' }), intl.formatMessage({ id: 'alert.errorGeneralText' }), 'error')
        console.log(err)
      })
  }


  render() {
    const { name, email, phone } = this.state
    return (
      <Modal
        size="md"
        isOpen={this.props.state.modal}
        toggle={this.props.toggle}
        style={{ padding: 25 }}
      >
        <ModalHeader toggle={this.props.toggle}>
          <FormattedMessage id='btn.join' /> {this.props.item.title}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={event => this.handleValidSubmit(event, this.state)}>
            <AvField
              onChange={this.handleInputChange}
              name="name"
              value={name}
              label={<FormattedMessage id='label.name' />}
              type="text"
              errorMessage="Campo no válido"
              validate={{
                required: { value: true },
                minLength: { value: 6 }
              }}
            />
            <AvField
              onChange={this.handleInputChange}
              name="phone" label={<FormattedMessage id='label.phone' />}
              type="number"
              value={phone}
              errorMessage="Número no válido"
              validate={{ required: { value: true } }}
            />
            <AvField
              onChange={this.handleInputChange}
              name="email"
              label={<FormattedMessage id='label.email' />}
              type="text"
              value={email}
              errorMessage="Email no válido"
              validate={{ required: { value: true }, email: true }}
            />
            <hr />
            <Button
              color="success"
              type="submit"
              className="btn-block"
            >
              <FormattedMessage id='btn.join' />
            </Button>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth, location }) => ({ auth, location })
// const mapDispatchToProps = { setNotify, spinnerToggle }
export default connect(mapStateToProps/*, mapDispatchToProps*/)(injectIntl(ModalSubscribe))