import { addItem, updatedItem } from '../../util/helpers'
import {
  POST_SF,
  UPDATE_SF,
  GET_All_SF,
  GET_All_CLEAN_SF
} from '../actions/types'

const initialState = {
  list: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_SF:
      return {
        ...state,
        list: addItem(state.list, action.payload)
      }
    case UPDATE_SF:
      return {
        ...state,
        list: updatedItem(state.list, action.payload)
      }
    case GET_All_SF:
      return {
        ...state,
        list: [...action.payload]
      }
    case GET_All_CLEAN_SF:
      return {
        ...state,
        list: [...action.payload]
      }
    default:
      return state
  }
}