import React, { Component } from 'react'
// Other dependencies
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
// Helpers' functions
import { tableOptions } from './helpers/tableHelpers'
import CSVButton from '../common/CSVButton'
import { isEmpty } from '../../util/helpers'
import { today } from '../../util/dateHelpers'
import { expandRow } from '../../util/tableHelpers'
import initialState from './helpers/initialState'
// Validations
import RolValidations from './validations'
import { onChangeState, validationsOnSubmit, specialValidations, validationsOnType } from '../../util/inputsHelpers'
// Redux actions
import {
	addRol,
	editRol,
	updateRol,
	getAllRol,
	toggleRol,
	getAllRolSubsedes,
	getCleanEditRol,
	getAllCleanRol
} from '../../redux/actions/rolActions'
// Components
import Admin from '../Layout/Admin/Admin'
import RolsForm from './RolsForm'
import ModalRols from './ModalRols'

import ExportExcel from '../common/ExportExcel/ExportExcel'
import { columnsToExport } from './helpers/tableHelpers'

// Global variables
const { SearchBar } = Search

class RolListPagination extends Component {
	// Filter select options
	rolOptions = {
		corporative: 'Corporative',
		maintainer: 'Maintainer',
		reporter: 'Reporter'
	}

	// Filter select options
	activeOptions = {
		true: this.props.intl.formatMessage({ id: 'general.confirmation' }),
		false: this.props.intl.formatMessage({ id: 'general.negation' })
	}

	state = {
		...initialState
	}

	// Set the state to the initial
	setStateToInitial = () => {
		this.setState({ ...initialState })
	}

	// Get all the users and the subsedes
	componentDidMount() {
		this.props.getAllRol()
		this.props.getAllRolSubsedes()
	}

	// Remove the list of rols from the global state
	componentWillUnmount() {
		this.props.getAllCleanRol()
	}

	// Handle for change at text's input fields
	// With validations on real time
	handleChange = (event) => {
		const field = event.target.name
		this.setState(onChangeState(event), () => {
			const { state } = this
			const validator = validationsOnType(state, RolValidations, field)
			if (validator !== null) this.setState({ fields: validator })
		})
	}

	// Click on the ADD button
	// - Open the form
	onClickAdd = () => {
		this.setState((state) => ({
			btnSave: !state.btnSave,
			btnAdd: !state.btnAdd,
			btnCancel: !state.btnCancel,
			form: !state.form
		}))
	}

	// The user wants to submit a new donation
	onSubmit = (event) => {
		event.preventDefault()
		const { intl } = this.props

		// Check for the validations
		const r = specialValidations(this.state.fields, RolValidations)
		if (!r.isValid) {
			// Validations didn't pass
			this.setState({ fields: { ...r.stateValidated } })
		} else {
			// Validation passed
			let { fields } = this.state
			let payload = {
				email: fields.email.value,
				rol: fields.rol.value,
				subsede: fields.subsede.value,
				is: fields.is.value,
				phone: fields.phone.value
			}

			// Make a HTTP POST request to /api/rol
			this.props.addRol(payload, intl)

			this.setStateToInitial()
		}
	}

	// // Click on the DELETE button
	// // Only is visible when the donation: Is not approved
	// onClickDelete = id => {
	//   const { intl } = this.props

	//   // Make a HTTP DELETE request to /api/donation/approve
	//   this.props.deleteDonation(id, intl)

	//   this.setStateToInitial()
	// }

	// Click on the CANCEL button
	// It cancel the current operation
	onClickCancel = () => {
		this.setStateToInitial()
	}

	// Click on the EDIT button
	onClickEdit = (row) => {
		window.scrollTo(0, 0)

		const { _id, email, rol, subsede, is, phone } = row

		// Set the values of the rol that
		// needs to be edited at the global state
		this.props.editRol({ _id })

		const filledFields = {
			email: { ...initialState.fields.email, value: email },
			rol: { ...initialState.fields.rol, value: rol },
			subsede: { ...initialState.fields.subsede, value: subsede._id },
			is: { ...initialState.fields.is, value: is },
			phone: { ...initialState.fields.phone, value: phone }
		}

		this.onClickCancel()

		this.setState((state) => ({
			fields: { ...filledFields },
			btnUpdate: !state.btnUpdate,
			btnAdd: !state.btnAdd,
			btnCancel: !state.btnCancel,
			form: !state.form
		}))
	}

	// Click on the UPDATE button
	// When the user wants to submit the chanes made to a donation's record
	onClickUpdate = (event) => {
		event.preventDefault()
		const { intl } = this.props

		const { _id } = this.props.rols.edit
		const { fields } = this.state

		const r = validationsOnSubmit(this.state, RolValidations)
		if (!r.isValid) {
			this.setState({ fields: { ...r.stateValidated } })
		} else {
			let payload = {
				email: fields.email.value,
				rol: fields.rol.value,
				subsede: fields.subsede.value,
				is: fields.is.value,
				phone: fields.phone.value
			}

			// Make a HTTP PUT request to /api/rol/:id
			this.props.updateRol(_id, payload, intl)

			this.setStateToInitial()
			this.props.getCleanEditRol()
		}
	}

	// Toggle Modal
	toggle = () => this.setState({ modal: !this.state.modal })

	// Open a modal
	onClickDetail = (item) => {
		this.setState({ item }, () => this.toggle())
	}

	onClickActive = (row) => {
		const { _id } = row
		const { intl } = this.props
		this.props.toggleRol(_id, row, intl)
	}

	render() {
		// debugger
		let { rolOptions, activeOptions, props: { intl } } = this
		const columns = tableOptions(intl.formatMessage, rolOptions, activeOptions, { ...this })

		const data = this.props.rols.list ? this.props.rols.list : []
		const { btnSave, btnUpdate, btnAdd, btnCancel, form } = this.state
		// console.log(data)

		return (
			<Admin>
				<div className="row mx-3">
					<div className="col-lg-12">
						<h1 className="h3 mb-2 text-gray-800">
							<FormattedMessage id="dashboard.users" />
						</h1>
					</div>
				</div>

				<div className="row mx-3">
					<div className="col-lg-12">
						<div className="card shadow mb-4">
							<div className="card-header py-3">
								<h6 className="m-0 font-weight-bold text-primary">
									<FormattedMessage id="general.actions" />
								</h6>
							</div>
							<div className="card-body">
								<ul className="list-inline">
									{btnAdd && (
										<li className="list-inline-item">
											<button className="btn btn-primary" onClick={this.onClickAdd}>
												<FormattedMessage id="btn.add" />
											</button>
										</li>
									)}
								</ul>

								{form && (
									<RolsForm
										{...this}
										{...this.state}
										subsedes={this.props.rols.subsedes}
										user={this.props.auth.user}
									/>
								)}

								<ul className="list-inline">
									{btnSave && (
										<li className="list-inline-item">
											<button className="btn btn-primary" onClick={this.onSubmit}>
												<FormattedMessage id="btn.save" />
											</button>
										</li>
									)}

									{btnCancel && (
										<li className="list-inline-item">
											<button className="btn btn-danger" onClick={this.onClickCancel}>
												<FormattedMessage id="btn.cancel" />
											</button>
										</li>
									)}

									{btnUpdate && (
										<li className="list-inline-item">
											<button className="btn btn-info" onClick={this.onClickUpdate}>
												<FormattedMessage id="btn.update" />
											</button>
										</li>
									)}
								</ul>
							</div>
						</div>

						<div className="card shadow mb-4">
							<div className="card-header py-3">
								<h6 className="m-0 font-weight-bold text-primary">
									<FormattedMessage id="table.registry" />
								</h6>
							</div>
							<div className="card-body table-responsive">
								<ToolkitProvider
									keyField="_id"
									data={data}
									columns={columns}
									bootstrap4={true}
									search={{
										searchFormatted: true
									}}
									exportCSV={{
										fileName: `RolsFS-${today()}.csv`,
										separator: ',',
										noAutoBOM: false,
										onlyExportFiltered: true,
										exportAll: false
									}}
								>
									{(props) => (
										<div>
											{/* <TableWrapper
                      title={this.props.intl.formatMessage({id: 'label.report'})}
                      label={this.props.intl.formatMessage({id: 'label.reportDonations'})}
                    > */}
											<SearchBar
												{...props.searchProps}
												placeholder={this.props.intl.formatMessage({ id: 'label.searchBar' })}
											/>
											<BootstrapTable
												{...props.baseProps}
												filter={filterFactory()}
												pagination={paginationFactory()}
												expandRow={expandRow}
												bordered
												hover
											/>
											{/* <CSVButton {...props.csvProps} /> */}
											{!isEmpty(data) && (
												<ExportExcel
													data={data}
													columnsToExport={columnsToExport}
													tableName="Usuarios"
												/>
											)}
											{/* </TableWrapper> */}
										</div>
									)}
								</ToolkitProvider>
							</div>
						</div>
					</div>
				</div>

				<ModalRols {...this} {...this.state} />
			</Admin>
		)
	}
}

RolListPagination.propTypes = {
	auth: PropTypes.object.isRequired,
	rols: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth, rols }) => ({ auth, rols })
const mapDispatchToProps = {
	addRol,
	editRol,
	updateRol,
	toggleRol,
	getAllRol,
	getAllRolSubsedes,
	getCleanEditRol,
	getAllCleanRol
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RolListPagination))
