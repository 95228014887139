import React, { Component, Fragment } from 'react'
import { dmy } from './../../../util/dateHelpers'
import socket from '../../../socket'
import VoluntaringBtn from './../component/VoluntaringBtn/VoluntaringBtn'
import { injectIntl } from 'react-intl'
import styles from './Panels.module.css'
import Counter from '../../Main/Common/Counter/Counter'

class VolunteeringPanel extends Component {
  state = {
    remaining: this.props.volunteering_volunteersRequired - this.props.volunteers.length
  }

  componentDidMount() {
    socket.on('subscribe', res => {
      if (this.props._id === res.project) {
        this.setState(s => ({ remaining: res.volunteers }))
      }
    })
  }

  render() {
    const { formatMessage } = this.props.intl
    const { remaining } = this.state
    const {
      volunteering_startDate,
      volunteering_endDate,
      volunteering_showCounter,
      project
    } = this.props
    return (
      <Fragment>
        <div className='card mb-4 box-shadowCard miniCard'>
          <div className='card-header text-center miniCardHeader'>
            <h4 className='my-0'>
              {volunteering_showCounter
                ? formatMessage({ id: 'label.volunteeringRemaining' }).toUpperCase()
                : formatMessage({ id: 'dare.voluntTit' }).toUpperCase()}
            </h4>
          </div>
          <div className='card-body miniCardBody'>
            {volunteering_showCounter && (
              <Counter
                delay={0}
                number={remaining}
                classNameText={`card-title textoLeyenda pricing-card-title text-center a-show ${styles['numbers']}`}
              />
            )}
            <VoluntaringBtn {...this.props} project={project} />

            <ul className='list-inline mb-0 textoLeyenda'>
              <li className='list-inline-item'>
                <strong>{formatMessage({ id: 'label.volunteering_startDate' }) + ':'}</strong>
              </li>
              <li className='list-inline-item'>
                {volunteering_startDate && dmy(volunteering_startDate)}
              </li>
              <li className='list-inline-item'>
                <strong>{formatMessage({ id: 'label.volunteering_endDate' }) + ':'}</strong>
              </li>
              <li className='list-inline-item'>
                {volunteering_endDate && dmy(volunteering_endDate)}
              </li>
            </ul>
          </div>
        </div>
        {/* {!isEmpty(lastSubscribed) &&
          <UncontrolledAlert color="info">
            {lastSubscribed} se acaba de unir al proyecto
          </UncontrolledAlert>
        } */}
      </Fragment>
    )
  }
}

export default injectIntl(VolunteeringPanel)
