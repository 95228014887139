import React from 'react'
import PropTypes from 'prop-types'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'

export function LightTable({ data, columns, label, ...props }) {
  return (
    <BootstrapTable
      keyField='_id'
      hover
      bootstrap4
      data={data}
      classes='mb-2'
      columns={columns}
      wrapperClasses='table-responsive'
      pagination={paginationFactory({ sizePerPage: 5 })}
      noDataIndication={<NoData />}
      {...props}
    />
  )
}

LightTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  label: PropTypes.string
}

LightTable.defaultProps = {
  data: [],
  columns: []
}

const NoData = () => <span className='text-center'>Sin registros disponibles</span>
