import React, { Component } from 'react'
import { Subtitle, Paragraph } from '../../Common/Tipography/Tipography'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'


export default class NoticePrivacyDefinitions extends Component {
  render() {
    return (
      <div>
        {/* Título: Definiciones */}
        <Subtitle>{<FormattedMessage id="noticePrivacy.definitions.title" />}</Subtitle>
        <Paragraph>
          {/* Lista ordenada de las definiciones */}
          <ol>
            <li>
              <strong>{<FormattedMessage id="noticePrivacy.definitions.content.label1" />}: </strong>
              <span>{<FormattedMessage id="noticePrivacy.definitions.content.text1" />}</span>
            </li>
            <li>
              <strong>{<FormattedMessage id="noticePrivacy.definitions.content.label2" />}: </strong>
              <span>{<FormattedMessage id="noticePrivacy.definitions.content.text2" />}</span>
            </li>
            <li>
              <strong>{<FormattedMessage id="noticePrivacy.definitions.content.label3" />}: </strong>
              <span>{<FormattedHTMLMessage id="noticePrivacy.definitions.content.text3" />}</span>
            </li>
            <li>
              <strong>{<FormattedMessage id="noticePrivacy.definitions.content.label4" />}: </strong>
              <span>{<FormattedMessage id="noticePrivacy.definitions.content.text4" />}</span>
            </li>
            <li>
              <strong>{<FormattedMessage id="noticePrivacy.definitions.content.label5" />}: </strong>
              <span>{<FormattedMessage id="noticePrivacy.definitions.content.text5" />}</span>
            </li>
          </ol>
        </Paragraph>
      </div>
    )
  }
}