// devuelve si un objeto , string, array 
// esta vacio devuelve TRUE.
export const isEmpty = value => (
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0) ||
  (Array.isArray(value) && value.length === 0)
)

export function toValidate(state) {
  return function (key) {
    const ob = state[key]
    return (ob.hasOwnProperty('value')
      && ob.hasOwnProperty('valid')
      && ob.hasOwnProperty('text')
      && ob.hasOwnProperty('feed')) ? { field: key, value: ob.value } : false
  }
}

export const getID = arr => arr.map(a => a.value)

export function mapState(state) {
  return function (key) {
    const o = state[key]
    if ((o.hasOwnProperty('value')
      && o.hasOwnProperty('valid')
      && o.hasOwnProperty('text')
      && o.hasOwnProperty('feed'))) {
      let result = { [key]: o.value }
      if (key === 'activities' || key === 'skills') {
        result = { [key]: getID(o.value) }
      } else if (key === 'institution' || key === 'cause' || key === 'categorie') {
        result = { [key]: o.value.value }
      }
      return result
    } else {
      const result = { [key]: o }
      return result
    }
  }
}

export function plainState(state) {
  // trae la propiedades que sean objetos para validar
  const arraySatete = Object.keys(state)
  return arraySatete.map(mapState(state)).reduce((p, a) => ({ ...p, ...a }), {})
}


export const validateOnSubmit = (state, validations) => {
  try {
    // trae la propiedades que sean objetos para validar
    const stateProps = Object.keys(state).filter(toValidate(state))
    // const numOfValidations = validations.length
    // const numOfProps = stateProps.length
    let stateValidated = {}
    let pass = true
    for (let validation of validations) {
      const prop = stateProps.find(s => validation.field === s)
      if (prop) {
        const value = state[prop].value
        const r = validation.function(value, state)
        if (r === false) continue

        r[prop].value = value
        stateValidated = { ...stateValidated, ...r }
        if (r[prop].valid === false) pass = false

      } else {
        continue
      }
    }
    return { pass, state: { ...stateValidated } }
  } catch (error) {
    console.log(error)
  }
}

// Helper ayuda a que la Mostar todos los textos en Upercase La primera letra
// y poner punto al final.
//export const ucfirst = string => string.charAt(0).toUpperCase() + string.slice(1) + '.'


export const editState = (intialState, response) => {
  try {
    // trae la propiedades que sean objetos para validar
    const rsa = Object.keys(response)
    let state = {}
    for (let key of rsa) {
      if (intialState.hasOwnProperty(key)) {
        // debugger
        const o = intialState[key]
        if ((o.hasOwnProperty('value')
          && o.hasOwnProperty('valid')
          && o.hasOwnProperty('text')
          && o.hasOwnProperty('feed'))) {
          o.value = response[key]
          const result = o
          console.log(result)
          // debugger
          state = { ...state, result }
        } else {
          const result = { [key]: response[key] }
          state = { ...state, result }
          // debugger
        }
      }
    }
    return state
  } catch (error) {
    console.log(error)
  }
}

// Cambiar { name: '', _id: '' } a { label: '', value: '' }
export const multiSelectFormater = selected => selected.map(s => ({ label: s.name, value: s._id }))


export const mergeState = (state, data) => {
  // debugger
  let result = {}
  const keys = Object.keys(state)
  keys.forEach(k => {
    let p = state[k]
    // debugger
    if ((p !== null)
      && (p.hasOwnProperty('value')
        && p.hasOwnProperty('valid')
        && p.hasOwnProperty('text')
        && p.hasOwnProperty('feed'))) {
      // MULTISELECTS
      if (k === 'activities' || k === 'skills') {
        result[k] = { ...p, value: multiSelectFormater(data[k]) }
      }
      // SELECTS
      else if (k === 'cause' || k === 'categorie' || k === 'institution') {
        result[k] = { ...p, value: { label: data[k].name, value: data[k]._id } }
      }
      // GENERAL VALUES
      else {
        result[k] = { ...p, value: data[k] }
      }
    } else {
      result[k] = data[k]
    }
  })
  
  // Plain values of donation
  result.donation = data.donation || false
  result.donations_showCounter = data.donations_showCounter || false
  result.donations_showCountDown = data.donations_showCountDown || false
  // Plain values of volunteering
  result.volunteering = data.volunteering || false
  result.volunteering_showCounter = data.volunteering_showCounter || false
   // Plain values of inkind donations
   result.inkindDonations = data.inkindDonations || false
   result.inkindDonations_showCountDown = data.inkindDonations_showCountDown || false
  return result
}
