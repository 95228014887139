import React, { Component } from 'react'
import { connect } from 'react-redux'
import Admin from './../Layout/Admin/Admin'
import PropTypes from 'prop-types'
import swal from '@sweetalert/with-react'
import { postAdminSF, getAllSF, getAllCleanSF, deactivateSF } from './../../redux/actions/solidaryfriendActions'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
// Hepers
import TableWrapper from './../common/TableWrapper'
import { Form, Input, InputGroup, InputGroupAddon, Button } from 'reactstrap'
import { dmy } from './../../util/dateHelpers'
import { isEmpty } from './../../util/helpers'
import { injectIntl, FormattedMessage } from 'react-intl'
import { formatToTable } from './helpers'
import { detailRow } from './../../util/report'
import CSVButton from './../common/CSVButton'
import axios from 'axios'
import ExportExcel from '../common/ExportExcel/ExportExcel'
import { columnsToExport } from './helpers/index'

class FriendList extends Component {
	handleChange = (event) => {
		const { name, value } = event.target
		this.setState({ [name]: value })
	}

	handleToggleActivate = (friend) => {
		const { intl } = this.props
		swal({
			title: `${friend.active
				? intl.formatMessage({ id: 'alert.deactivateTo' })
				: intl.formatMessage({ id: 'alert.activateTo' })} ${friend.name || friend.user.email}`,
			content: 'input',
			text: intl.formatMessage({ id: 'alert.confirmation' }),
			icon: 'info',
			buttons: true
		})
			.then((description) => {
				return (
					!isEmpty(description) &&
					this.props.deactivateSF(
						friend._id,
						{
							active: !friend.active,
							detail: {
								cause: `${!friend.active
									? intl.formatMessage({ id: 'alert.onCap' })
									: intl.formatMessage({ id: 'alert.offCap' })}`,
								description
							}
						},
						this.props.intl
					)
				)
			})
			.catch((e) => {
				alert(intl.formatMessage({ id: 'alert.errorGeneralTitle' }))
			})
	}

	// True or false traductions
	contactedOptions = {
		false: this.props.intl.formatMessage({ id: 'general.negation' }),
		true: this.props.intl.formatMessage({ id: 'general.confirmation' })
	}

	// Columns configurations
	columns = [
		{
			dataField: 'name',
			text: this.props.intl.formatMessage({ id: 'label.name' }),
			sort: true,
			formatter: (cell) => (isEmpty(cell) ? this.props.intl.formatMessage({ id: 'general.pendingField' }) : cell)
		},
		{
			dataField: 'phone',
			text: this.props.intl.formatMessage({ id: 'label.phone' }),
			sort: true,
			formatter: (cell) => (isEmpty(cell) ? this.props.intl.formatMessage({ id: 'general.pendingField' }) : cell)
		},
		{
			dataField: 'email',
			text: this.props.intl.formatMessage({ id: 'label.email' }),
			sort: true
		},
		{
			dataField: 'amount',
			text: this.props.intl.formatMessage({ id: 'table.amount' }),

			formatter: (cell) => (!isEmpty(cell) ? `$ ${cell.toFixed(2)}` : '$0.00'),
			sort: true
		},
		{
			dataField: 'sede',
			text: this.props.intl.formatMessage({ id: 'label.sede' }),
			sort: true
		},
		{
			dataField: 'subsede',
			text: this.props.intl.formatMessage({ id: 'label.subsede' }),
			sort: true
		},
		{
			dataField: 'active',
			text: this.props.intl.formatMessage({ id: 'table.active' }),

			formatter: (cell) => this.contactedOptions[cell]
		},
		{
			dataField: 'createdAt',
			text: this.props.intl.formatMessage({ id: 'label.dateRegistry' }),
			sort: true,
			formatter: dmy,
			csvFormatter: dmy
		},
		{
			dataField: 'active',
			text: this.props.intl.formatMessage({ id: 'label.continue' }),
			sort: true,
			hidden: this.props.auth.user.rol === 'reporter',
			csvExport: false,
			formatter: (cellContent, row) => (
				<div>
					<Button
						color={cellContent ? 'danger' : 'success'}
						className="mr-3"
						onClick={() => this.handleToggleActivate(row)}
					>
						<i className="fas fa-power-off" />
					</Button>
				</div>
			)
		}
	]

	addSolidaryFriend = (event) => {
		event.preventDefault()
		if (!isEmpty(this.state.selectedAdmin)) {
			this.props.postAdminSF({ user: this.state.selectedAdmin }, this.props.intl)
		}
	}

	state = {
		admins: [],
		selectedAdmin: ''
	}

	// LIFECYCLES METHODS

	componentDidMount() {
		if (isEmpty(this.props.solidaryFriend.list)) {
			this.props.getAllSF()
		}
		axios.get('/api/inputs/friend').then((res) => this.setState({ admins: res.data })).catch(() => alert('Error'))
	}

	componentWillUnmount() {
		this.props.getAllCleanSF()
	}

	// RENDER METHOD

	render() {
		const { intl, auth: { user } } = this.props
		let { list } = this.props.solidaryFriend
		return (
			<Admin>
				<ToolkitProvider
					keyField="_id"
					data={formatToTable(list)}
					columns={this.columns}
					bootstrap4={true}
					search={{
						searchFormatted: true
					}}
					exportCSV={{
						fileName: `FriendsFS.csv`,
						separator: ',',
						noAutoBOM: false,
						onlyExportFiltered: true,
						exportAll: false
					}}
				>
					{(props) => (
						<TableWrapper
							title={intl.formatMessage({ id: 'label.friend' })}
							label={intl.formatMessage({ id: 'label.list' })}
						>
							{(user.rol === 'corporative' || user.rol === 'maintainer') && (
								<Form onSubmit={this.addSolidaryFriend} className="mb-3">
									<h6 className="m-0 mb-4 font-weight-bold text-primary">
										<FormattedMessage id="btn.add" />
									</h6>
									<InputGroup>
										<Input name="selectedAdmin" type="select" onChange={this.handleChange}>
											{this.state.selectedAdmin === '' && <option value="" />}
											{this.state.admins.map((admin) => (
												<option key={`option_sf_${admin._id}`} value={admin._id}>
													{admin.email}
												</option>
											))}
										</Input>
										<InputGroupAddon addonType="append">
											<Button type="submit">
												<FormattedMessage id="btn.save" />
											</Button>
										</InputGroupAddon>
									</InputGroup>
								</Form>
							)}

							<BootstrapTable
								{...props.baseProps}
								pagination={paginationFactory()}
								filter={filterFactory()}
								expandRow={detailRow}
								className="table-responsive"
								bordered
								hover
							/>
							{/* <CSVButton {...props.csvProps} /> */}
							{!isEmpty(list) && (
								<ExportExcel
									data={list}
									columnsToExport={columnsToExport}
									tableName="Amigos Solidarios"
								/>
							)}
						</TableWrapper>
					)}
				</ToolkitProvider>
			</Admin>
		)
	}
}

FriendList.propTypes = {
	auth: PropTypes.object.isRequired,
	solidaryFriend: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth, solidaryFriend }) => ({ auth, solidaryFriend })
const mapDispatchToProps = { postAdminSF, getAllSF, getAllCleanSF, deactivateSF }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FriendList))
