import React, { Component } from 'react'
import { Title, Paragraph } from '../../Common/Tipography/Tipography'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

export default class NoticePrivacyIntroduction extends Component {
  render() {
    return (
      <div>
        <Title>{<FormattedMessage id='noticePrivacy.introduction.title' />}</Title>
        <Paragraph>
          {<FormattedHTMLMessage id='noticePrivacy.introduction.content' />}
        </Paragraph>
      </div>
    )
  }
}
