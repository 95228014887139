import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

function NavPerfil() {
  return (
    <Fragment>
      <ul className="nav nav-tabs mb-2">
        <li className="nav-item">
          <a href="profile" data-target="#profile" data-toggle="tab" className="nav-link active labelSof">
            <FormattedMessage id='general.profile' />
          </a>
        </li>
        <li className="nav-item">
          <a href="edit" data-target="#edit" data-toggle="tab" className="nav-link labelSof">
            <FormattedMessage id='btn.update' />
          </a>
        </li>
      </ul>
    </Fragment>
  )
}

export default NavPerfil