import React from 'react'
import { addItem, updatedItem } from '../../util/helpers'
import { dmy } from '../../util/dateHelpers'
import { generateActiveIcon } from '../common/Table/helpers'

export const columns = [
  {
    dataField: 'thumbnail.path',
    formatter: cell => <img src={cell} alt='' width='100' />
  },
  {
    dataField: 'title',
    text: 'Título',
    sort: true
  },
  {
    dataField: 'duration',
    text: 'Duración',
    formatter: cell => `${cell} días`,
    sort: true
  },
  {
    dataField: 'donations.length',
    text: 'Donadores',
    sort: true,
    formatter: cell => <center>{cell}</center>
  },
  {
    dataField: 'created_by.name',
    text: 'Autor',
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Creado',
    sort: true,
    formatter: dmy
  },
  {
    dataField: 'active',
    text: 'Activo',
    formatter: cell => generateActiveIcon(cell),
    sort: true
  }
]

// Add the returned document to the list
export function createSuccess(state, action) {
  return { ...state, list: addItem(state.list, action.payload) }
}

// Load the list of all records, set the loading to false
export function loadAllSuccess(state, action) {
  return { ...state, list: [...action.payload] }
}

// Set the returned single document into the view property
export function loadSuccess(state, action) {
  return { ...state, view: { ...action.payload } }
}

// Load the returned document into the edit property in order to edit it
export function editSuccess(state, action) {
  return { ...state, edit: { ...action.payload } }
}

// Successfully update the list
export function updateSuccess(state, action) {
  return { ...state, list: updatedItem(state.list, action.payload) }
}

// Successfully toggle a document an incorpore its updated version to the list
export function toggleSuccess(state, action) {
  const item = action.payload
  const list = state.list
  const index = list.findIndex(i => i.id === item.id)

  return {
    ...state,
    list: [
      ...list.slice(0, index),
      { ...list[index], active: item.active },
      ...list.slice(index + 1)
    ]
  }
}
