import React from "react"
import ScrollableAnchor from "react-scrollable-anchor"
import styles from './aboutSection.module.css'
import '../../../assets/css/fundacionSolidaria.css'
import { FormattedMessage } from 'react-intl'

import ContentWrapper from './../../common/ContentWrapper/ContentWrapper'

const AboutSection = () => (
  <ScrollableAnchor id="about">
    <section className={`my-4 mt-5 ${styles['fondoAbout']} ${styles['fondoAbout2']}`}>
      <div className={`container text-center `} >
        <div className="row">
        <div className="col-lg-10 mx-auto">
          {/* Mission */}
          <ContentWrapper
            pretitle={<FormattedMessage id="about.title" />}
            title={<FormattedMessage id="about.missTit" />}
            content={<FormattedMessage id="about.missTxt" />}
          />
          {/* Vision */}
          <ContentWrapper
            title={<FormattedMessage id="about.visTit" />}
            content={<FormattedMessage id="about.visTxt" />}
          />
          </div>
        </div>
      </div>
    </section>
  </ScrollableAnchor>
)

export default AboutSection