import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import PayPalbtn from './../component/Paypalbtn/Paypalbtn'
import Counter from '../../Main/Common/Counter/Counter'
import { formatCurrency } from '../../../util/formatCurrency'
import CreditCardButton from './../component/ConektaButtons/CreditCardButton'
import OxxoPay from './../../../components/OxxoPay/OxxoPay'
import socket from '../../../socket'
import styles from './Panels.module.css'

class DontionsPanel extends Component {
  componentWillMount() {
    this.setState(this.props.project)
  }

  componentDidMount() {
    socket.on('projecyDontarion', res => {
      const { fundsRaised: funds, project } = this.state
      const fundsRaised = funds + res.fundsRaised
      if (project === res.project) {
        this.setState({ fundsRaised })
      }
      socket.emit('dashboard', {})
    })
  }

  render() {
    const { project } = this.props
    const today = moment()
    const can = moment(today).isBetween(
      project.donations_startDate,
      project.donations_endDate,
      'days',
      '[]'
    )
    const canDonate = can && project.closed === false

    return (
      <div className='card mb-4 box-shadowCard miniCard'>
        <div className='card-header text-center miniCardHeader'>
          <h4 className='my-0'>
            {this.props.intl.formatMessage({ id: 'label.donation' }).toUpperCase()}{' '}
          </h4>
        </div>
        <div className='card-body miniCardBody'>
          {project.donations_showCounter && (
            <Counter
              delay={0}
              decimals={2}
              symbol='USD'
              classNameSymbol='counterSymbol'
              number={this.state.fundsRaised}
              formattingFn={formatCurrency}
              classNameText={`card-title textoLeyenda pricing-card-title text-center a-show ${styles['numbers']}`}
            />
          )}
          {canDonate && <PayPalbtn />}
          {/* {canDonate && project.sede.name === 'Mexico' && project.sede.conekta.clientId && (
            <Fragment>
              <CreditCardButton />
              <OxxoPay />
            </Fragment>
          )} */}
          {canDonate && (
            <p className='textoLeyenda text-center'>{this.state.donations_text}</p>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ socket, auth }) => ({ socket, auth })
export default connect(mapStateToProps)(injectIntl(DontionsPanel))
