import React, { Fragment } from 'react'
import { isEmpty, } from './helpers'
import { FormGroup, FormFeedback, FormText, Label } from 'reactstrap'


const FeedBack = ({ forp, feed, valid, text }) => {
  return (
    <Fragment>
      {
        !isEmpty(feed) &&
        feed.length !== 0 &&
        valid === true &&
        feed.map(f => <FormFeedback
          id={`${forp}-${f}`}
          key={`${forp}-${f}`}
          valid={valid}>
          {(f)}
        </FormFeedback>)
      }
      {
        !isEmpty(feed) &&
        feed.length !== 0 &&
        valid === false &&
        feed.map(f => <FormFeedback
          id={`${forp}-${f}`}
          key={`${forp}-${f}`}>
          {(f)}
        </FormFeedback>)
      }
      {
        !isEmpty(text) &&
        valid === null &&
        <FormText>
          {(text)}
        </FormText>
      }
    </Fragment>
  )
}

const FormGroupValid = ({
  valid,
  forp,
  text,
  feed,
  children,
  label
}) => {
  return (
    <FormGroup>
      <Label for={forp} className={
        !isEmpty(valid) ?
          valid === true ? 'text-success' : 'text-danger'
          : ''
      }>{(label)} </Label>
      {children}
      <FeedBack forp={forp} feed={feed} valid={valid} text={text} />
    </FormGroup>
  )
}
export default FormGroupValid




