import React, { Fragment } from 'react'
import styles from './project-disaster.module.css'

const ProjectDisasters = ({ img, title, text, isActive }) => {
  return (
    <Fragment>
      {isActive && (
        <div className={`col-md-4`} >
          <div className={`card ${styles['card']}`}>
            <img className={styles['image']} src={img} alt="help1" />
            <h3 className={styles['titleCard']}>{title}</h3>
            <p className={styles['txtCard']}>{text}</p>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default ProjectDisasters