import React from 'react'

export default function FacebookSVG(props) {
  return (
    <svg id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 612 612' {...props}>
      <path className='st0' d='M303.1,548.1V305.6h80l12.6-80.3h-92.7V185c0-21,6.9-40.9,36.9-40.9h60.1V63.9h-85.4 c-71.8,0-91.4,47.2-91.4,112.9v48.6h-49.3v80.2h49.3v242.5H303.1L303.1,548.1z'
      />
    </svg>
  )
}

