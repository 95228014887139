import React from 'react'
import PropTypes from 'prop-types'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from './../../authConfig'
import { connect } from 'react-redux'
import { loginUser } from '../../redux/actions/authActions'
import { spinnerToggle } from '../../redux/actions/spinnerActions'
import styles from '../Main/Pages/Contact/contact.module.css'

import { FormattedMessage, injectIntl, classes } from 'react-intl'
import axios from 'axios'

function AzureSignInButton({loginUser, spinnerToggle, button}) {
  const { instance } = useMsal()
  function handleLogin(instance) {
    instance
      .loginPopup(loginRequest)
      .then(async result => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${result.accessToken}`
        const data = {
          email: `${result.account.username}`,
          username: result.account.name
        }
        spinnerToggle()
        loginUser(data)
      })
      .catch(e => {
        console.error(e)
      })
  }
  return (
    <>
      <button type='submit' className={`btn btn-solidaria-g btn-block mt-4 ${styles['buttonLogIn']}`} onClick={() => handleLogin(instance)}>
        <FormattedMessage id='general.login' />
      </button>
    </>
  )
}
AzureSignInButton.prototypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = ({ auth, location, errors }) => ({ auth, location, errors })
export default connect(mapStateToProps, { loginUser, spinnerToggle })(
  injectIntl(AzureSignInButton)
)
