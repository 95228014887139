import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { spinnerToggle } from '../../redux/actions/spinnerActions'
import { editCleanNews, editNews, updateNews } from './../../redux/actions/newsActions'
// Layout
import Admin from '../Layout/Admin/Admin'
// Forms
import { Form } from 'reactstrap'
import ThumbnailForm from './forms/ThumbnailForm'
// Helpers
import { isEmpty } from './../../util/isEmpty'
import initialState from './Helpers/initialState';
import validations from './validations/edit'
import { mergeState } from './Helpers'
import { validateOnSubmit, plainState } from './../Campaign/lib/helpers'
import { FormattedMessage, injectIntl } from 'react-intl'

export class NewsEdit extends Component {

  state = { ...initialState }

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.editNews(id)
  }

  componentWillReceiveProps(np) {
    const { edit } = np.news

    if (!isEmpty(edit)) {
      const state = mergeState(initialState, edit)
      this.setState({ ...state })
    }
    if (isEmpty(edit)) {
      const { id } = this.props.match.params
      this.props.editNews(id)
    }
    // Set the user's subsede
    if (!isEmpty(np.auth.user)) {
      this.setState({ subsede: np.auth.user.subsede })
    }
  }

  componentWillUnmount() {
    this.props.editCleanNews()
  }

  // Handle para agregar los valores a el
  // state de la propiedad de un objeto
  // recibe el nombre y un objeto de propiedades
  handleChangeSelectData = props => {
    this.setState(s => ({ ...s, [props.name]: { ...s[props.name], value: props.value } }))
  }

  // Handle agrega los valores a el
  // state de la propiedad del editor 
  onChangeEditor = ({ editor }) => {
    const value = editor.getData()
    this.setState((state, prop) => ({ content: { ...state.content, value } }))
  }
  // Handle para agrega los valores del state
  // para inputs y checkbox
  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState(s => ({ ...s, [name]: { ...s[name], value } }))
  }

  // Hadle si las validaciones pasaron
  onSubmit = event => {
    event.preventDefault()
    const validated = validateOnSubmit(this.state, validations(this.props.news.edit.publishDate))
    console.log(validated)
    if (validated.pass) {
      const s = { ...validated.state }
      const payload = plainState(s)
      const { id } = this.props.match.params
      const { intl } = this.props
      this.props.updateNews(id, payload, this.props.history, intl)
    } else {
      window.scrollTo(0, 0)
      const faildState = validated.state
      this.setState({ ...faildState })
    }
  }

  render() {
    // const model = this.state
    return (
      <Admin>
        <div className="row">
          <div className="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10">
            <h1 className="h3 mb-2 text-gray-800">
              <FormattedMessage id="news.edit" />
            </h1>
          </div>
        </div>
        <Form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-xl-10 col-lg-10 offset-lg-1">
              <ThumbnailForm {...this} formatMessage={this.props.intl.formatMessage} />
            </div>
          </div>
        </Form>
      </Admin>
    )
  }
}

NewsEdit.propTypes = {
  auth: PropTypes.object.isRequired,
  news: PropTypes.object.isRequired,
  spinnerToggle: PropTypes.func.isRequired,
  updateNews: PropTypes.func.isRequired,
}
const mapStateToProps = ({ auth, news }) => ({ auth, news })
const mapDispatchToProps = { spinnerToggle, editCleanNews, editNews, updateNews }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewsEdit))