import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { isEmpty } from './../../util/isEmpty'
import { FormattedMessage } from 'react-intl'
import styles from '../Main/Common/Cards/card.module.css'

class GeneralPagination extends React.Component {
  state = {
    data: [],
    currentPage: 1,
    sizePerPage: this.props.sizePerPage
  }

  componentWillReceiveProps(np) {
    this.setState({ data: np.data })
  }

  componentDidMount() {
    this.setState({ data: this.props.data, sizePerPage: this.props.sizePerPage })
  }

  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id)
    })
  }

  render() {
    const { data, currentPage, sizePerPage } = this.state

    // Logic for displaying current data
    const indexOfLast = currentPage * sizePerPage
    const indexOfFirst = indexOfLast - sizePerPage
    const renderData = data.slice(indexOfFirst, indexOfLast)

    // Logic for displaying page numbers
    const pageNumbers = []
    for (let i = 1; i <= Math.ceil(data.length / sizePerPage); i++) {
      pageNumbers.push(i)
    }

    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <PaginationItem
          active={number === this.state.currentPage}
        >
          <PaginationLink
            key={number}
            id={number}
            // href="#" 
            onClick={this.handleClick}
          >
            {number}
          </PaginationLink>
        </PaginationItem>
      )
    })

    return (
      <div className="contenedorGalle">
        <div className={`my-3 ${this.props.className}`}>
          {
            !isEmpty(renderData) ? renderData :
              <h4 className={styles['sinContenido_perfil']}><FormattedMessage id="general.found" /></h4>
          }
        </div>
        <Pagination className="d-flex justify-content-center" aria-label="Page navigation example">
          {renderPageNumbers}
        </Pagination>
      </div>
    )
  }
}

export default GeneralPagination