import React from 'react'
import { isEmpty } from '../../../../util/isEmpty'
import { FormattedMessage } from 'react-intl';
const ActivitiesValidations = [
  {
    field: 'name',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else if (value.length < 3) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.min3" />] }
      }
      else if (value.length > 25) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.max25" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  },
  {
    field: 'description',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else if (value.length < 5) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.min5" />] }
      }
      else if (value.length > 140) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.max140" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  },
]
export default ActivitiesValidations