import React from "react";
import { isEmpty } from '../../../../util/inputsHelpers'
import { dmy, sorting } from '../../../../util/dateHelpers'
import { FormattedMessage } from 'react-intl'

const DetailTable = props => {
  return (
    <tr>
      {
        (props.r.detail.length > 0) && (
          <td colspan="12" className="hiddenRow" style={{ background: '#f0f0f0' }}>

            <table id={`demo${props.i}`} className="accordian-body collapse" style={{ width: '100%', background: '#f0f0f0' }}>
              <tr>
                <th>#</th>
                <th><FormattedMessage id="modal.cause" /></th>
                <th><FormattedMessage id="label.description" /></th>
                <th><FormattedMessage id="general.user" /></th>
                <th><FormattedMessage id="general.date" /></th>
              </tr>

              {(sorting(props.r.detail).map((record, subindex) => {
                return (
                  <tr>
                    <td>{subindex + 1}</td>
                    <td>{!isEmpty(record.cause) && record.cause}</td>
                    <td>{!isEmpty(record.description) ? record.description.trim() : "No description"}</td>
                    <td>{!isEmpty(record.created_by) ? record.created_by.name : 'Unknown'}</td>
                    <td>{dmy(record.createdAt)}</td>
                  </tr>
                )
              }))}
            </table>
          </td>)
      }
    </tr>
  )
}

export default DetailTable