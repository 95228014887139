import React from 'react'
import { InputGroupAddon, Input, Form, Row, Col } from 'reactstrap'

import styles from './../../Campaign/component/Filters/Filters.module.css'

const Filters = ({ handleSubmit, onChange, filters, formatMessage, subsedes }) => (
  <Form
    onSubmit={handleSubmit}
    className={`container mt-3 mb-4 ${styles['filters']}`}
  >
    <Row style={{ marginBottom: '1.5%', width: '100%'}}>
      <Col md={5} className='mx-0 my-2 px-1'>
        <Input
          name='title'
          type='text'
          placeholder={formatMessage({ id: 'label.title' })}
          onChange={onChange}
          value={filters.title.value}
        />
      </Col>
      <Col md={2} className='mx-0 my-2 px-1'>

        <Input
          name='subsede'
          type='select'
          onChange={onChange}
          value={filters.subsede.value}
        >
          <option value='' selected>{formatMessage({ id: 'label.allSub' })}</option>
          {subsedes.map(s => <option key={`subsede_${s._id}`} value={s._id}>{s.name}</option>)}
        </Input>
      </Col>
      <Col md={2} className='mx-0 my-2 px-1'>
        <Input
          type='date'
          name='publishDate'
          onChange={onChange}
          value={filters.publishDate.value}
        />
      </Col>
      <Col md={2} className='mx-0 my-2 px-1'>
        <Input
          type='date'
          name='endDate'
          onChange={onChange}
          value={filters.endDate.value}
        />
      </Col>
      <Col md={1} className='mx-0 my-2 px-1'>
        <InputGroupAddon addonType='prepend' style={{wdith: '100%'}}>
          <button type='submit' className={`btn btn-block ${styles['button']}`}>
            <i class='fas fa-search'></i>
          </button>
        </InputGroupAddon>
      </Col>
    </Row>
  </Form>
)

export default Filters