import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import logo from './img/softtek_logo.png'
import styles from './footer.module.css'
import { FormattedMessage } from 'react-intl'
import FacebookSVG from '../../../common/SVG/socialNetworks/FacebookSVG'
import InstagramSVG from '../../../common/SVG/socialNetworks/InstagramSVG'
import TwitterSVG from '../../../common/SVG/socialNetworks/TwitterSVG'

const Footer = () => (
  <Fragment>
    <footer className={`${styles['footer']}`}>
      <div className='col-lg-12 row'>
        <div className={`col-lg-4 ${styles['place']}`}>
          <div className={`${styles['copy']}`}>
            Copyright &copy; Softtek {new Date().getFullYear()}
          </div>
          <div className={`${styles['mobile']}`}>
            <Link to='/terms-and-conditions' className={`${styles['terms']} `}>
              <FormattedMessage id='noticePrivacy.introduction.title' />
            </Link>
            <span className={`${styles['terms']} `}> | </span>
            <Link to='/notice-privacy' className={` ${styles['terms']}`}>
              <FormattedMessage id='privacyTerms.title' />
            </Link>
          </div>
        </div>
        <div className={`text-center col-lg-6 ${styles['divSocial']}`}>
          <ul className={`list-inline ${styles['iconsSocial']}`} style={{ marginTop: '2.4%' }}>
            <li className='list-inline-item'>
              <a
                className={`rounded-circle text-white mr-3 ${styles['social-link']}`}
                href={'https://www.facebook.com/fundacionsolidariaAC'}
                target='_blank'
                rel='noopener noreferrer'>
                <FacebookSVG className={styles['social-media']} />
              </a>
            </li>
            <li className='list-inline-item'>
              <a
                className={`rounded-circle text-white mr-3 ${styles['social-link']}`}
                href={'https://twitter.com/SolidariaAC'}
                target='_blank'
                rel='noopener noreferrer'>
                <InstagramSVG className={styles['social-media']} />
              </a>
            </li>
            <li className='list-inline-item'>
              <a
                className={`rounded-circle text-white mr-3 ${styles['social-link']}`}
                href={'https://twitter.com/SolidariaAC'}
                target='_blank'
                rel='noopener noreferrer'>
                <TwitterSVG className={styles['social-media']} />
              </a>
            </li>
          </ul>
          <div className={`${styles['divFont']}`}>
            <h3 className={`${styles['font']}`}>
              <FormattedMessage id='callout.share' />
            </h3>
          </div>
        </div>
        <div className='text-center col-lg-2'>
          <a href={'https://www.softtek.com'} target='_blank' rel='noopener noreferrer'>
            <img
              src={logo}
              className={`img-fluid ${styles['logoSofttek']}`}
              alt='fundacion solidaria logo'
            />
          </a>
        </div>
      </div>
    </footer>
  </Fragment>
)
export default Footer
