import axios from 'axios'
import swal from '@sweetalert/with-react'
import {
  GET_All_ROL,
  GET_ERRORS,
  EDIT_ROL,
  ADD_ROL,
  UPDATE_ROL,
  GET_All_ROL_SUBSEDES
} from './types'
import { isEmpty } from '../../util/isEmpty'
import socket from '../../socket'

// Add a manual user/rol
export const addRol = (p, intl) => dispatch => {
  axios
    .post('/api/rol', p)
    .then(r => dispatch({ type: ADD_ROL, payload: r.data }))
    .then(() => swal(intl.formatMessage({ id: 'alert.adminAdd' }), '', 'success'))
    .catch(error => {
      // ERRORES HTTP
      if (!isEmpty(error.response.data.message)) {
        const status = error.response.status
        const message = error.response.data.message
        // Ya existe un usuario con ese email
        if (status === 400 && message === 'alert.alreadyExist') {
          swal({ title: intl.formatMessage({ id: message }), text: '', icon: 'warning' })
        }
        // El usuario que se intenta agregar como administrador es softtekian
        else if (status === 400 && message === 'alert.existSofttekian') {
          swal({
            title: intl.formatMessage({ id: message }),
            text: intl.formatMessage({ id: 'alert.instructionsSofttekian' }),
            icon: 'warning'
          })
        }
        // NO autorizado
        else if (error.response.status === 401) {
          swal(
            intl.formatMessage({ id: 'alert.error401Title' }),
            intl.formatMessage({ id: 'alert.error401Text' }),
            'error'
          )
        }
        // Error general
        else {
          swal(
            intl.formatMessage({ id: 'alert.errorGeneralTitle' }),
            intl.formatMessage({ id: 'alert.errorGeneralText' }),
            'error'
          )
        }
      } else {
        swal(
          intl.formatMessage({ id: 'alert.errorGeneralTitle' }),
          intl.formatMessage({ id: 'alert.errorGeneralText' }),
          'error'
        )
      }
    })
}

// Get all the user's records
export const getAllRol = () => dispatch => {
  // dispatch(loadingPage())
  axios
    .get('/api/rol')
    .then(r => dispatch({ type: GET_All_ROL, payload: r.data }))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// Get the user/rol's data to edit
export const editRol = payload => dispatch => {
  dispatch({ type: EDIT_ROL, payload })
}

// Update a user/rol record
export const updateRol = (id, d, intl) => async dispatch => {
  const cause = await swal({
    title: intl.formatMessage({ id: 'alert.desTitle' }),
    icon: 'warning',
    buttons: {
      error: {
        text: intl.formatMessage({ id: 'alert.causeErrorText' }),
        value: intl.formatMessage({ id: 'alert.causeErrorText' })
      },
      actualizacion: {
        text: intl.formatMessage({ id: 'alert.upText' }),
        value: intl.formatMessage({ id: 'alert.upText' })
      }
    }
  })
  if (!isEmpty(cause)) {
    // ! Alerta que guarda la justificación
    const desc = await swal({
      title: intl.formatMessage({ id: 'alert.desTitle' }),
      content: 'input',
      buttons: true
    })
    if (!isEmpty(desc)) {
      const payload = {
        payload: { ...d },
        detail: {
          cause,
          description: desc
        }
      }
      const route = `/${'api/rol'}/${id}`

      // PUT api/rol/:id
      // Se actualiza el registro
      axios
        .put(route, payload)
        .then(res => {
          dispatch({ type: UPDATE_ROL, payload: res.data })
          socket.emit('dumppuser', res.data)
          swal(intl.formatMessage({ id: 'alert.changesRegistry' }), { icon: 'success' })
        })
        .catch(err => {
          // ERRORES HTTP
          if (!isEmpty(err.response.data.message)) {
            const status = err.response.status
            const message = err.response.data.message

            // Ya hay un registro con un email de ese usuario
            if (status === 400 && message === 'alert.alreadyExist') {
              swal({ title: intl.formatMessage({ id: message }), text: '', icon: 'warning' })
            }
            // NO autorizado
            else if (err.response.status === 401) {
              swal(
                intl.formatMessage({ id: 'alert.error401Title' }),
                intl.formatMessage({ id: 'alert.error401Text' }),
                'error'
              )
            }
            // Error general
            else {
              swal(
                intl.formatMessage({ id: 'alert.errorGeneralTitle' }),
                intl.formatMessage({ id: 'alert.errorGeneralText' }),
                'error'
              )
            }
          } else {
            swal(
              intl.formatMessage({ id: 'alert.errorGeneralTitle' }),
              intl.formatMessage({ id: 'alert.errorGeneralText' }),
              'error'
            )
          }
        })
    } else {
      swal(
        intl.formatMessage({ id: 'alert.changesOff' }),
        intl.formatMessage({ id: 'alert.writeCause' }),
        'info'
      )
    }
  }
}

export const toggleRol = (id, d, intl) => async dispatch => {
  const route = `/${'api/rol'}/${id}`
  const confirmation = await swal({
    title: intl.formatMessage({ id: 'alert.confiTitle' }),
    text: `${intl.formatMessage({ id: 'alert.changeRegistry' })} ${
      d.active
        ? intl.formatMessage({ id: 'alert.off' })
        : intl.formatMessage({ id: 'alert.on' })
    }`,
    icon: 'warning',
    buttons: true
  })
  if (confirmation === true) {
    const desc = await swal({
      title: intl.formatMessage({ id: 'alert.desTitle' }),
      content: 'input'
    })
    if (!isEmpty(desc)) {
      const payload = {
        payload: { active: !d.active, login: false },
        detail: {
          cause: `${
            !d.active
              ? intl.formatMessage({ id: 'alert.onCap' })
              : intl.formatMessage({ id: 'alert.offCap' })
          }`,
          description: desc
        }
      }

      // PUT api/rol/:id
      // Se actualiza el registro
      axios
        .put(route, payload)
        .then(res => {
          // debugger
          // desconecto al usuario
          dispatch({ type: UPDATE_ROL, payload: res.data })
          if (res.data.active === false) {
            socket.emit('dumppuser', res.data)
          }

          swal({
            title: '',
            text: payload.payload.active
              ? intl.formatMessage({ id: 'alert.registerActivate' })
              : intl.formatMessage({ id: 'alert.registerDeactivate' }),
            icon: 'success'
          })
        })

        .catch(err => {
          // ERRORES HTTP
          if (err.response.status === 401)
            swal(
              intl.formatMessage({ id: 'alert.error401Title' }),
              intl.formatMessage({ id: 'alert.error401Text' }),
              'error'
            )
          else
            swal(
              intl.formatMessage({ id: 'alert.errorGeneralTitle' }),
              intl.formatMessage({ id: 'alert.errorGeneralText' }),
              'error'
            )
        })
    } else {
      swal(
        intl.formatMessage({ id: 'alert.changesOff' }),
        intl.formatMessage({ id: 'alert.writeCause' }),
        'info'
      )
    }
  }
}

// Get the subsedes than can be assigned to a user
export const getAllRolSubsedes = () => dispatch => {
  axios
    .get('/api/subsede')
    .then(r => {
      const payload = r.data
      dispatch({ type: GET_All_ROL_SUBSEDES, payload })
    })
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// Clean nodes of the user/rol property
export const getAllCleanRol = () => dispatch => {
  dispatch({ type: GET_All_ROL, payload: [] })
}

export const getCleanEditRol = () => dispatch => {
  dispatch({ type: EDIT_ROL, payload: {} })
}
