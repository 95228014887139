import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getNews, getCleanNews } from './../../redux/actions/newsActions'
import Navbar from '../Main/Common/Navbar/Navbar'
import Footer from '../Main/Common/Footer/Footer'
import Img from './../../components/common/ImgPreview'
import { isEmpty } from './../../util/isEmpty'
import { dmy } from './../../util/dateHelpers'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import ProjectStatus from './../Campaign/component/ProjectStatus/ProjectStatus'
import Breadcrumb from '../Main/Common/Breadcrumb/Breadcrumb'
import ShareBar from '../SocialMedia/ShareBar'

export class News extends Component {
	componentDidMount() {
		const { id } = this.props.match.params
		this.props.getNews(id, this.props.intl)
	}

	componentWillReceiveProps(np) {
		if (isEmpty(np.news.view)) {
			const { id } = this.props.match.params
			this.props.getNews(id, this.props.intl)
		}
	}

	componentWillUnmount() {
		this.props.getCleanNews()
	}

	render() {
		const { view } = this.props.news
		const message = `Amigos, revisen la noticia "${view.title}" desde esta página.`
		const shareUrl = window.location.href

		return (
			<Fragment>
				<Navbar />
				{isEmpty(view) ? (
					<h1>
						<FormattedMessage id="general.loading" />
					</h1>
				) : (
					<div className="contenedorProject">
						<Breadcrumb {...view} link="/news/gallerie" id="news.gall" />
						<h1 className="mt-4 mb-3 subtitleProject">{view.title}</h1>
						<br />
						<div className="row">
							<div class="col-lg-6" style={{ marginBottom: '5%' }}>
								<Img {...view.thumbnail} />
								<ul className="list-inline mb-0 listaEtiquetas">
									<li className="list-inline-item">
										<i className="fas fa-globe" />
									</li>
									<li className="list-inline-item txtProject">
										<a href="/#">{view.subsede.name}</a>
									</li>
									<li className="list-inline-item">
										<i className="far fa-calendar-alt" />
									</li>
									<li className="list-inline-item txtProject">{dmy(view.publishDate)}</li>
									<li className="list-inline-item txtProject">
										<ProjectStatus status={view.status} />
									</li>
									<li className="list-inline-item">
										<ShareBar shareUrl={shareUrl} message={message} />
									</li>
								</ul>
							</div>
							<div className="col-lg-6">
								<p className="lead titleProject">{view.description}</p>
								<hr />
								<div
									className=" txtProject "
									style={{ textAlign: 'justify' }}
									dangerouslySetInnerHTML={{ __html: view.content }}
								/>
								<hr />
								<h3 className="autor">
									<FormattedMessage id="label.author" />:{' '}
									{!isEmpty(view.created_by) && view.created_by.name}
								</h3>
							</div>
						</div>
					</div>
				)}

				<Footer />
			</Fragment>
		)
	}
}

News.propTypes = {
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	getNews: PropTypes.func.isRequired
}

const mapitemToProps = ({ auth, news }) => ({ auth, news })
const mapDispatchToProps = { getNews, getCleanNews }
export default connect(mapitemToProps, mapDispatchToProps)(injectIntl(News))
