export default {
	'en-US': {
		nav: {
			home: 'HOME',
			about: 'ABOUT US',
			value: 'OUR VALUES',
			programs: 'OUR PROGRAMS',
			projects: 'OUR PROJECTS',
			gallerie: 'PROJECT GALLERY',
			help: 'HOW TO HELP?',
			dare: 'DARE TO SHARE',
			share: 'SHARE YOUR INITIATIVE',
			contact: 'CONTACT',
			publications: 'PROGRAMS',
			news: 'NEWS',
			convocatory: 'SOCIAL PROGRAMS',
			historical: 'PROJECT HISTORY'
		},
		about: {
			title: 'ABOUT US',
			missTit: 'Mission',
			missTxt: 'Contribute to the sustainable development of our communities.',
			visTit: 'Vision',
			visTxt:
				'Become a global organization, with a volunteer committee in each of our locations, using technology as a means of generating social value.'
		},
		callout: {
			touch: 'GET IN TOUCH, WE ARE HERE TO HELP',
			share: 'BECAUSE SHARING IS NOT GIVING WHAT WE HAVE LEFT',
			share2: 'Because sharing is not giving what we have left'
		},
		counter: {
			labelVis: 'VISITS',
			labelCount: 'USERS',
			labelProy: 'PROJECTS',
			labelDona: 'DOLLARS',
			result: 'RESULTS IN FIGURES',
			impact: 'Our Impact'
		},
		header: {
			firstTit: 'First',
			firstTxt: 'Description of the first image',
			seconTit: 'Second',
			seconTxt: 'Description of the second image',
			thirTit: 'Third',
			thirTxt: 'Description of the third image'
		},
		video: {
			title: 'Videos',
			titleSecond: '¡Proyect!',
			cerrar: 'Close',
			txt: 'Most important project of the month',
			thanks: 'THANKS FOR PARTICIPATING',
			public: 'Publish video',
			header: 'Video'
		},
		volunteer: {
			title: 'Ways of volunteering with which we work',
			microTit: 'Micro-volunteering',
			microTit2: 'Help an organization performing tasks remotely.',
			microTxt:
				'A volunteer or a team of volunteers carry out online activities from anywhere to benefit a non-profit organization. It is also called "virtual volunteering" and usually takes a few minutes or a couple of hours to complete',
			tradTit: 'Traditional',
			tradTit2: 'Organize volunteering team action to support the community.',
			tradTxt:
				'From planting a tree to building a shelter, the goal of this volunteering is to connect people with organizations that need manpower to carry out activities where hands and physical effort are needed.'
		},
		make: {
			txt:
				'It’s a program that during the year launches campaigns to help different causes, projects and institutions. It aims to offer easy and practical alternatives to our associates so they can contribute with their:',
			time: 'Time',
			money: 'Money',
			items: 'Items'
		},
		dare: {
			title: 'Alternatives to contribute',
			donaETit: 'Financial Support',
			donaETxt:
				"It refers to all the money received in the Foundation's bank account to support a project or institution. There are several ways to donate: ",
			donaPE1: 'Making a donation through this platform (either for a project or to the Foundation) ',
			donaPE2:
				'Through the "Softtekian Solidario" program where an associate makes a monthly contribution to the Foundation\'s account.',
			donaPE3: 'Making an in-hand donation destined to a specific activity.',
			donaSTit: 'In kind Donations',
			donaSTxt:
				'Material contributions that our associates provide to the Foundation, they can be articles or services, and they are destined to the different projects or institutions for which they were collected. For example: items for a charity bazaar.',
			donaTTit: 'Volunteering Time',
			donaTTxt:
				'You participate through some type of volunteering and you give part of your time to support a project or activity that is organized by one of the Foundation’s committees.',
			voluntTit: 'Volunteering',
			voluntTxt:
				'Volunteering is a fundamental part for Solidaria Foundation. All the activities that are carried out take place thanks to the support of our collaborators, their relatives and friends; who voluntarily and freely devote their time, talent and skills to different causes and projects.',
			typesVolunt: 'There are different types of volunteering.',
			actTit: 'Active volunteer',
			actTxt:
				'In this modality you share some of your time carrying out the activities planned by the Solidaria Foundation committees. It allows you to get involved with the people who are benefited and experience the joy of helping them.',
			remoteTit: 'Remote volunteer',
			remoteTxt:
				'If you can’t participate actively due to schedule conflicts, you can support us helping with the organization and logistics required to carry out the activities.',
			comiteTit: 'Committee member',
			comiteTxt:
				'In each location it is necessary to have a team in charge of defining the activities to be carried out. This group is involved in the planning, organization and development of all the activities. It should be noted that you do not need to attend all of them.',
			friendTit: 'Softtekian Solidario',
			friendTxt:
				'This is a program that operates in all the committees of the Softtek offices in Mexico and Brazil. It consists of those employees who voluntarily agree to donate a specific amount that is deducted from their monthly salary to support the activities of their committee and the programs of Solidaria Foundation. Solidaria Foundation provides tax-deductible receipts every year for these donations.<br/> <a href="https://forms.office.com/r/J2z5EwzvP0">Sign up here</a>',
			button: "I'M INTERESTED",
			know: 'KNOW MORE OF DTS'
		},
		// friend: {
		//   title: 'Softtekian Solidario',
		//   txt: 'This is a program that operates in all the committees of the Softtek offices in Mexico and Brazil. It consists of those employees who voluntarily agree to donate a specific amount that is deducted from their monthly salary to support the activities of their committee and the programs of Solidaria Foundation. Solidaria Foundation provides tax-deductible receipts every year for these donations.'
		// },
		initiative: {
			title: 'Share your initiative',
			button: 'I HAVE AN IDEA',
			txt:
				'All the activities carried out on behalf of Solidaria Foundation are defined by the committees of the different regions. If you know an organization that the Foundation can support or want to propose an activity that benefits your community, we want to hear about it. Share us the details of your proposal so that the committee of your region can analyze it, and if it is feasible, integrate it into their activity plan. <br/>We are all Solidaria Foundation! '
		},
		programs: {
			know: 'KNOW MORE',
			title: 'Our Programs',
			txt: 'Programs designed to contribute to the sustainable development of the communities where we operate.',
			health: 'HEALTH',
			healthTxt:
				'For Solidaria Foundation it is essential to work in preventing diseases as well as supporting institutions and organizations that help those who suffer from any kind of disease. These actions are aimed at providing some services and supply materials through volunteering and benefactors.',
			dress: 'HOUSING, FOOD AND CLOTHING',
			dressTxt:
				'We provide, according to our current capabilities, in-kind donations to support institutions that require construction or renovation of its facilities as well as the donation of new or used clothing in good condition, and food supplies.',
			dressT0:
				'We organize campaigns to collect used or new clothing and donate them to our partner institutions.',
			dressT1: 'We deliver basic food supplies in underserved communities.',
			dressT2:
				'We provide institutions with construction and painting materials, as well as electronic equipment.',
			dressT3: ' ',
			nature: 'ENVIRONMENT',
			natureTxt:
				'Nowadays, it is important and necessary to work towards ecological balance and environmental protection. Solidaria Foundation encourages and promotes activities that maintain this balance in a responsible and organized way. Our actions are aimed towards the following issues:',
			nT1: 'Animal protection: support to animal shelters.',
			nT2:
				'Natural Disasters: coordination with organizations for the collection and delivery of commodities and materials after a natural disaster.',
			nT3: 'Reforestation and cleaning up green spaces.',
			nt4: 'Training and awareness about the importance and benefits of recycling.',
			natureTxt2:
				'The Foundation supports the promotion and dissemination of the campaigns of other NGOs or groups that seek to promote and preserve the ecological balance.  For example:',
			nT4: 'Adoption and support campaigns for homeless animals.',
			nT5: 'Awareness campaigns to avoid cruelty to animals.',
			nT6: 'Food donation campaigns for the benefit of people affected.',
			nT7: 'Train institutions on the importance and benefits of recycling.',
			solidarity: 'COEXISTENCE AND SOLIDARITY',
			solTxt:
				'Solidarity Foundation seeks to promote and enrich social relationships through healthy social coexistence in favor of the most vulnerable or vulnerable groups, and solidarity with them to support them in improving their quality of life.  <br /> Our actions are aimed at providing events, services and provision of materials, either directly with unprotected groups or through related institutions: NGOs, homes, orphanages, prisons, rehabilitation centers, shelters, schools,  rural communities.',
			sT1:
				'Alliances: the Foundation supports the promotion and dissemination of the campaigns of other NGOs or groups that seek integration and social coexistence.',
			tit2: 'Material supply',
			tit3:
				'We provide, according to our current capabilities, in-kind donations to support institutions that require construction or renovation of its facilities as well as the donation of new or used clothing in good condition, and foodstuff.',
			sT2: 'Construction material and infrastructure.',
			sT3: 'Food and clothing.',
			edu: 'EDUCATION',
			eduTxt:
				'Education is a key component in the development of the communities we serve, therefore, we generate high-impact projects that benefit not only those who are actively involved, but those that are the beginning of a value chain multiplying the impact and scope of the projects. '
		},
		values: {
			pillar: 'OUR PILLAR',
			title: 'Core Values',
			transparency: 'TRANSPARENCY',
			transTxt:
				'Clarity and accountability on how we operate and manage our resources, which come from our people, donations and the organization.',
			commitment: 'COMMITMENT',
			commTxt: 'Perform the necessary tasks so the activities promoted in each of our programs become a reality.',
			synergy: 'SINERGY',
			synTxt:
				'Join efforts at local, regional and global levels in order to multiply our strengths and create a greater impact on our environment.',
			trust: 'TRUST',
			trustTxt: 'We are confident that each of our committees operates entirely according to our vision.',
			focus: 'Our focus',
			focusTxt:
				'Solidaria Foundation focuses its efforts on actions that contribute to the sustainable development of the communities where Softtek operates. For this reason, its honorary president, Blanca Treviño, defined the educational field as its priority for the generation of initiatives that promote and encourage the progress of the locations where we have presence.',
			belief: 'Our beliefs',
			beliefTxt:
				'Sharing is the beginning of the cycle of abundance and growth.  People are capable of sustaining themselves when they are respected as human beings and have opportunities to grow.  The sum of efforts generates great changes. ',
			reason: 'What drives us?'
		},
		weshare: {
			title: 'Our projects',
			txt:
				'Solidaria Foundation is made up of committees in different locations that evaluate local project proposals and support requests related to education, specifically those that involve the use of technology. In addition, they also consider projects and related requirements with some of the following causes:',
			causes1: 'Natural disasters, such as earthquakes, floods and fires.',
			causes2: 'Health organizations and institutions that carry out annual activities to raise funds.',
			causes3: 'Projects related to the preservation and improvement of the environment.',
			causes4: 'Support for the elderly, children and patients of health institutions.',
			exc:
				'* Any other case that does not qualify in the topics described in this section must be evaluated by the Executive Committee of Solidaria Foundation.',
			tit1: 'Natural Disasters',
			titlePoint: 'During the selection of the projects, the following points will be taken into consideration:',
			titP1: 'Sustainability',
			point1: 'Projects that transcend in time, for example, that are replicable or permanent.',
			titP2: 'Impact',
			point2: 'Projects with a measurable positive effect in our communities.',
			titP3: 'Feasibility',
			point3:
				'Projects that adhere to the mission, vision and programs of our Foundation;  and that can be done with our economic resources through our volunteers. ',
			committee:
				'The Foundation committee will respond via email to all requests notifying whether the grant of support is possible or not.',
			some: 'Considerations'
		},
		btn: {
			add: 'Add',
			save: 'Save',
			cancel: 'Cancel',
			update: 'Update',
			send: 'Send',
			login: 'LOGIN',
			logout: 'Exit',
			dashboard: 'Dashboard',
			sendUs: 'Send a message',
			subscribe: 'join the program',
			callme: 'contact me',
			share: 'Share!',
			join: 'Join',
			edit: 'Edit',
			close: 'Close',
			infoClose: 'Closing information',
			see: 'See',
			register: 'Sign in',
			donation: 'DONATE',
			approve: 'Approve',
			delete: 'Delete',
			detail: 'Detail',
			unsubscribe: 'Unsubscribe',
			contact: 'Contact'
		},
		help: {
			required: 'This field is required',
			obliged: 'Required Field',
			right: 'Right Field',
			number: 'Field is not a number',
			email: 'Field is not email',
			photo: 'Select a photo',
			date: 'The date must be between',
			day: 'day',
			year: 'year',
			min3: 'The minimum of characters allowed is 3',
			min4: 'The minimum of characters allowed is 4',
			min5: 'The minimum of characters allowed is 5',
			min6: 'The minimum of characters allowed is 6',
			min7: 'The minimum of characters allowed is 7',
			min15: 'The minimum of characters allowed is 15',
			min580: 'The minimum of characters allowed is 580',
			max7: 'The minimum of characters allowed is 7',
			max20: 'The maximum characters allowed is 2000.',
			max25: 'The maximum characters allowed is 25',
			max35: 'The maximum characters allowed is 35',
			max50: 'The maximum characters allowed is 50',
			max100: 'The maximum characters allowed is 100',
			max140: 'The maximum characters allowed is 140',
			max280: 'The maximum characters allowed is 280',
			max500: 'The maximum allowed characters is 500',
			beforeToday: 'You can not select an earlier date today.',
			beforeBegin: 'Unable to select a date before the selected start date.',
			rank: 'It is not in the project life time range',
			reqStartDate: 'The start date is required',
			reqEndDate: 'The end date is required',
			beforeStart: 'You can not select a date before the initial.',
			equalStart: 'You can not select a date equal to or before the initial.',
			equalToday: 'You can not select a date equal or earlier than today.',
			cannotEditStart: 'You can not edit the start date, the project is already published',
			withoutProject: 'Without project'
		},
		label: {
			activitie: 'Activities',
			categorie: 'Categories',
			cause: 'Causes',
			contact: 'Contacts',
			donation: 'Donations',
			friend: 'Solidary Friends',
			initiative: 'Initiatives',
			institution: 'Institutions',
			role: 'Roles',
			sede: 'Sede',
			skill: 'Skills',
			subsede: 'Subsede',
			name: 'Name',
			fullName: 'Full Name',
			lastName: 'last name',
			description: 'Description',
			phone: 'Phone',
			email: 'Email',
			msg: 'Message',
			amount: 'Amount',
			kind: 'Type of donation',
			manual: 'manual',
			transfer: 'Transfer',
			project: 'Select the project',
			country: 'Country',
			state: 'State',
			city: 'City',
			zip: 'Zip Code',
			address: 'Address',
			is: 'IS',
			id: 'ID',
			web: 'Web page',
			languageCode: 'Language Code',
			countryCode: 'Country Code',
			paypal_account: 'PayPal Account',
			paypal_clientId: 'PayPal Client ID',
      paypal_secret: 'Paypal Secret Key',
      conekta_account: 'Conekta Account',
			conekta_clientId: 'Conekta Client ID',
			conekta_secret: 'Conekta Secret Key',
			bank_name: 'Bank',
			bank_account: 'Bank Account',
			termsofPrivacy: 'Terms of privacy',
			publishDate: 'Date of publication',
			startDate: 'Start date',
			endDate: 'End date',
			author: 'Author',
			content: 'Content',
			title: 'Title',
			continent: 'Continent',
			currency: 'Currency',
			simbolCurrency: 'currency Symbol',
			changeDolar: 'Currency change dolar',
			zone: 'Timezone',
			enable: 'Enable monetary donations',
			startDonation: 'Startup donations',
			endDonation: 'Purpose of donation',
			receipt: 'Receipt',
			legend: 'Legend',
			showCounter: 'Show counter',
			showCountDown: 'Show countdown',
			itemsDonated: 'Donations in kind',
			itemsEnable: 'Enable donations in kind',
			shareBar: 'Share',
      shareTree: 'Locate your tree in the Amazon here',
      shareTreeLink: 'Your tree - Saving the Amazon',
			info: 'Information',
			volunteer: 'Volunteer',
			subscription: 'Enable logging of volunteers',
			subscription_required: 'Volunteers required',
			showCounterVolunteer: 'Show volunteers',
			goal: 'Goal',
			type: 'Type',
			cover: 'Cover',
			moneyDonated: 'Monetary donations',
			start: 'Start',
			event: 'Event',
			list: 'List',
			listProject: 'List of projects',
			specialEvent: 'Special event',
			probono: 'Pro-bono',
			campaign: 'Campaign',
			report: 'Report',
			reportProject: 'Project report',
			reportDonations: 'Donations report',
			export: 'Export records in CSV',
			inkindDonations_startDate: 'Start reception',
			inkindDonations_endDate: 'End of reception',
			inkindDonations_text: 'What does your project need?  (Separate with commas) ',
			volunteering_startDate: 'Start of registration',
			volunteering_endDate: 'End of registration',
			volunteering_volunteersRequired: 'Number of volunteers required',
			ubicationActivitie: 'Location of the activity',
			contact_email: 'Contact email',
			contact_address: 'Address of the activity',
			thumbnail: 'Thumbnail',
			dateRegistry: 'Registration date',
			continue: 'Follow-up',
			listSuscribe: 'List of subscribers',
			convocatory: 'Social Program',
			status: 'Status',
			new: 'New',
			finalized: 'Finished',
			completed: 'Completed',
			allTypes: 'All project types',
			published: 'Published',
			publicProject: 'Publish project',
			monthlyAmount: 'Amount to donate monthly',
			volunteeringRemaining: 'Volunteers needed',
			completeDate: 'Date completed',
			allSub: 'All Subsites',
			finalizeProject: 'Finish project',
			finalize: 'Finish',
			search: 'Search',
			actionsCarriedOut: 'Actions carried out',
			volunteerColla: 'Volunteer collaborators',
			externalVolunters: 'External volunteers',
			volunterHours: 'Hours of volunteering',
			investment: 'Investment',
			benefit: 'Beneficiaries',
			comments: 'Comments (optional)',
			numVolunteers: 'Number of volunteers',
			numColla: 'Number of voluntary collaborators',
			totalHoursVolunteer: 'Total hours of volunteering invested',
			numBenefit: 'Number of beneficiaries',
			summaryProject: 'Project summary',
			searchBar: 'Search bar',
			upload: 'Upload',
			gallerieImages: 'Image gallery',
			uploadImage: 'Upload image',
			viewGallerie: 'Manage gallery',
			donationMin0: 'Manual donations must be greater than 0',
			donationMax120: 'Manual donations must be less than 120 USD',
			assignTo: 'Assigned project',
			userSF: 'You are a Solidary friend',
			leftSF: 'Stop being a Solidary friend',
			joinSF: 'Back to be a Solidary friend',
			noticeOfPrivacy: 'Notice of Privacy',
			hello: 'Hello',
			language: 'Language',
			confirmOfRead: 'I confirm having read the ',
			consult: 'See the',
			site: 'of the site',
			loginEmail: 'Your email without the termination @softtek.com',
			loginPassword: 'Your Softtek email password',
      evidence: 'Evidence',
      currencyChange: 'The currency and its exchange rate has been updated'
		},
		modal: {
			activitie: 'Activity',
			categorie: 'Category',
			cause: 'Cause',
			contact: 'Contact',
			donation: 'Donation',
			friend: 'Friend solidarity',
			initiative: 'Initiative',
			institution: 'Institution',
			role: 'Role',
			sede: 'Sede',
			skill: 'Skill',
			subsede: 'Subsede',
			solidaryFriend: 'Friend Solidarity',
			toBe: 'I Want to be a friend in solidarity!',
			question: 'Do you Want to be a friend in solidarity?',
			project: 'Project',
			donor: 'Donor',
			usd: 'USD',
			typeDonation: 'Type of donation',
			detailDonation: 'Details of donation',
			originalAmount: 'Original amount',
			donationNet: 'Net donation',
			donationGross: 'Gross donation',
			fee: 'Fee',
			donorCreator: 'Registered by'
		},
		table: {
			registry: 'Records',
			active: 'Active',
			inactive: 'Inactive',
			create: 'Created',
			update: 'Updated',
			cause: 'Reason',
			amount: 'Amount',
			approved: 'Approved'
		},
		alert: {
			causeTitle: 'Detailed description of the reason',
			causeChange: 'Reason for change',
			causeErrorText: 'Erroneous information',
			upText: 'Update',
			desTitle: 'Justification',
			confiTitle: 'Are you sure?',
			confiText: 'This record will be ',
			error401Title: 'Unauthorized',
			error401Text: 'Does not have the required permissions',
			errorGeneralTitle: 'Error on the server',
			errorGeneralText: 'Please try again later',
			successfully: 'successfully',
			registry: 'Registration',
			success: 'Success',
			activitieAdd: 'Registered activity',
			categorieAdd: 'Registered category',
			causeAdd: 'Cause added',
			adminAdd: 'Administrator added',
			institutionAdd: 'Registered institution',
			donationAdd: 'Registered donation',
			donationReverse: 'Once approved it can not be reversed',
			donationApprove: 'How did you check the donation?',
			contactTh: 'Thanks for the comment!',
			initiTh: '¡Thanks for your initiative!',
			contactText: 'Very soon we will contact each other',
			contactDelete: 'Has this person already been contacted?  Provide a description of this',
			changes: 'Saved changes',
			changeRegistry: 'This record will be ',
			friendToBe: 'This solidary friend will be',
			initiativeToBe: 'The initiative will be',
			initiative: 'Initiative',
			friend: 'Softtekian solidario',
			changesRegistry: 'Changes registered in the system',
			contactCuase: 'The person was contacted successfully',
			projectCreate: 'Project created',
			newCreate: 'News created',
			convocaCreate: 'Social Program created',
			sedeAdd: 'Registered Headquarter',
			subsedeAdd: 'Added sub-office',
			off: 'off',
			on: 'activated',
			offCap: 'Off',
			onCap: 'Activated',
			causeOff: 'Reason for inactivation',
			causeOn: 'Reason for activation',
			approveRegistry: 'Approved registration',
			friendTh: 'Thanks for your cooperation!',
			convocatoryTh: 'Thanks for your registration!',
			donationTh: 'Thank you for your donation!',
			alertClose: 'Once closed it can not be edited again',
			projectComplete: 'Project completed',
			profileUpdate: 'Your profile has been updated',
			profileValidation: 'It is necessary to fill in the fields of your profile',
			alreadyExist: 'Already exists',
			conectionOff: 'Offline',
			error: 'Error',
			changesOff: 'Changes were not saved',
			contactCreate: 'Registered contact',
			delete: 'Deleted',
			suscribeDelete: 'Subscriber successfully deleted',
			contactConvocatory: 'Contacted',
			registryDelete: 'This record will be deleted',
			suscriber: 'Already contacted',
			request: 'Request sent',
			skillAdd: 'Added skill',
			confirmation: 'Add supplementary information of the request',
			new: 'New',
			published: 'Published',
			finalized: 'Finalized',
			completed: 'Completed',
			password: 'Incorrect password',
			passwordInvalid: 'Password is invalid',
			user: 'Incorrect user',
			userInvalid: 'Invalid user',
			activateTo: 'Activate',
			deactivateTo: ' Deactivate',
			registerActivate: 'Registration activated',
			registerDeactivate: 'Registration disabled',
			writeCause: 'Write a justification for the change',
			alreadyFriend: 'You are already a Solidary friend',
			wasFriend: 'To ask to be reactivated as a supportive friend, go to your profile',
			videoUploaded: 'The video was successfully uploaded',
			videoConfirmation: 'Are you sure you want to publish the video?',
			friendThanks: 'Soon we will get in touch',
			credentials: 'Invalid username or password',
			token: 'session expired',
			existSofttekian: 'Softtekian user',
			instructionsSofttekian: 'To change Role contact Technical Support',
			file: 'File type not allowed',
			unsubscribed: 'Unsubscribed from the project',
			imageDeleted: 'Image deleted',
			imageAdded: 'Image added',
			serviceUnavailable: 'Server in maintenance',
			noAdblocker: "Make sure you don't have an Ad-blocker extension activated",
			// Empty profile
			profileEmptyTitle: 'We invite you to register your profile',
			profileEmptyText: 'It is important to have the option to contribute to the Fundación Solidaria projects'
		},
		general: {
			contacted: 'Contacted',
			user: 'User',
			date: 'Date',
			actions: 'Actions',
			role: 'Role Type',
			password: 'Password',
			login: 'Login',
			logout: 'Logout',
			profile: 'Profile',
			dashboard: 'Dashboard',
			joinus: 'To join you must login',
			volunteer: 'Volunteers',
			confirmation: 'Yes',
			negation: 'No',
			pendingField: 'Please fill',
			pending: 'Pending',
			loading: 'Loading...',
			found: 'NO RESULTS FOUND',
			actually: 'Currently, there are no projects available'
		},
		dashboard: {
			administration: 'ADMINISTRATION',
			projects: 'Projects',
			news: 'News',
			conv: 'Social Programs',
			admin: 'Administrators',
			create: 'Create',
			list: 'List',
			gallerie: 'Gallery',
			catalog: 'Catalogs',
			admon: 'Manage',
      paypal: 'PayPal',
			welcome: 'Welcome',
			inf: 'Your information',
			visit: 'Information from which you visit us',
			activities: 'Latest activity',
			interaction: 'Interaction',
			configuration: 'Configuration',
			users: 'Users',
			gallProjects: 'Projects gallery',
			reports: 'Reports',
			multimedia: 'Multimedia'
		},
		news: {
			list: 'News list',
			gall: 'News Gallery',
			public: 'Publish news',
			edit: 'Edit news',
			new: 'New news'
		},
		convocatory: {
			list: 'List of Social Programs',
			gall: 'Gallery of Social Programs',
			public: 'Publish call',
			edit: 'Edit call',
			subscribed: 'Subscribed'
		},
		report: {
			cardStatus: 'Status',
			cardPaypal: 'Paypal donations',
			cardOthers: 'Other donations',
			cardVolunteers: 'Volunteers',
			chartDonations: 'Donations chart',
			chartTypes: 'Types of donations',
			tableDonations: 'Table of donations',
			summaryLabel: 'Project summary',
			summaryTitle: 'Title of the project',
			summaryObjective: 'Objective',
			summaryDescription: 'Description',
			closureLabel: 'Closing Information',
			closureDonations: 'Donations',
			closureMonetary: 'Monetary donations',
			closureInversion: 'Investment',
			closureVolunteering: 'Volunteering',
			closureVolunteers: 'Number of volunteers',
			closureCollaborators: 'Number of voluntary collaborators',
			closureHoursVolunteering: 'Total hours of volunteering invested',
			closureBeneficiaries: 'Beneficiaries',
			closureComments: 'Comments'
		},
		dash: {
			lineChart: 'Donations timeline (USD)',
			pieCHart: 'Donations total by type',
			cardUsers: 'Online Users',
			cardAdmins: 'Online Admins',
			cardSofttekians: 'Online Softtekians',
			cardVisits: 'Online Visits'
		},
		paypal: {
			head: 'If you need a donation receipt for tax deduction, please reach us at',
			bank: 'Bank',
			account: 'Account',
			fullAccount: 'Clabe',
			sub: 'If you want to make a donation by bank transfer, the account details are the following :',
			footer:
				'once you have made the transfer, please send your proof to the Foundation of Solidarity to the email'
		},
		donationLabel: {
			projectD: 'To deallocate project, select the empty field'
		},
		carrousel: {
			new: 'Project added to the carousel',
			active: 'Project visible on the carousel',
			unactive: 'Project removed from the carousel',
			duplicated: 'There can not be two projects published from the same sub-site on the carousel',
			title: 'Carousel',
			instruction: 'Select the project',
			publish: 'Publish project on carousel',
			list: 'Carousel records list'
		},
		softtekian: {
			info: 'Without information',
			addInfo: 'Add',
			addInfo2: 'to your profile, please',
			causes: 'Causes that you support',
			typeAct: 'Type of activities you would like to participate in',
			skills: 'Skills you have',
			photo: 'Profile picture',
			updateProfile: 'Update my profile'
		},
		noticePrivacy: {
			introduction: {
				title: 'Terms and Conditions',
				content:
					'Fundación Solidaria, A.C. (hereinafter “Solidaria”), Solidaria offers the User all the information, tools and services within the Site <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>, subject to the acceptance by the User of the following Terms of Use, as well as the Policies and Privacy Notices that apply. Please read them carefully. The use of the Site by the User constitutes acceptance of it to these Terms of Use and other Privacy documents.'
			},
			definitions: {
				title: 'Definitions.',
				content: {
					label1: 'Content',
					text1:
						'refers to all content on the Site, including, without limitation, text, design, graphics, logos, icons, images, audio clips, video clips, downloaded materials, interfaces, tools, applications, code, software and brands.',
					label2: 'Personal Data',
					text2: 'any information concerning an identified or identifiable natural person.',
					label3: 'Website and Website',
					text3:
						'refers to <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>, owned and operated by Solidaria.',
					label4: 'Solidaria',
					text4:
						'refers to Valores Corporativos Softtek, SA de CV with address at Boulevard Constitución 3098 6th Floor, Colonia Santa María, Monterrey, Nuevo León CP 64650.  User: refers to the person who is browsing the Site or using it in any way.',
					label5: 'Terms of Use',
					text5: 'refers to the following terms and conditions related to the use of the Site.'
				}
			},
			termsOfUse: {
				title: 'Terms of use.',
				content: {
					l1: 'Information on the Site',
					c1:
						'The information contained in the Site should be used for general informational purposes only and should not be used or referred to as the sole basis for decision making or as a legal representation or guarantee by Solidaria . Solidaria is not responsible if the information available on this Site is not accurate, complete or updated. The information contained in the Site is intended to provide general information on a particular topic or topics and not exhaustively on said topic (s).',
					l2: 'Intellectual property',
					c2:
						'All Content is the exclusive property of Solidaria, its affiliates, its licensors or its Content providers and is protected by intellectual property laws and other applicable laws. The User is authorized to access, copy, download and print the materials contained in the Site for personal and non-commercial use only, provided that such materials are not modified and that any copyright or trademark notice is not modified or deleted. . Any other use of the Contents, including without limitation, the reproduction, modification, use, publication, transmission in media, loading, transmission, fixation, distribution, licensing or the creation of derivative works of the Contents, is strictly prohibited. Solidaria reserves all rights not expressly granted in these Terms of Use, as well as for the Site and its Content. <br/> <br/>Solidaria ® and its logo are registered trademarks of Fundación Solidaria, A.C.  This brand and any other brand and service marks that are part of the Contents must not be used in any way without the prior written consent of Solidaria.  Nothing on this Site should be construed as the granting of any license or right to use any part of the Content.',
					l3: 'Use of contact information',
					c3:
						'The User will not use contact information provided on the Site for any unauthorized purpose, including marketing, without the prior written consent of Solidaria.',
					l4: 'Links to other sites',
					c4:
						'The Site offers links to Web pages and access to Contents , products and services of third parties, including users, advertisers, affiliates and sponsors of the Site. The User accepts that Solidaria is not responsible for the availability and Content included in third-party Web pages. The User is prevented from reviewing the policies announced by other Web pages in relation to their own terms of use before use. Solidaria is not responsible for the third-party content accessible through the Site, including opinions, advice, statements and advertisements, and the User will assume all risks associated with the use of such Content. No links should be made to this Site without the prior written consent of Solidaria.',
					l5: 'Personal and non-personal information',
					c5: `Some functions on the Site require registration, which implies that you provide us with some of your Personal Data. Your Personal Data are obtained, used, stored and, in general, are treated in accordance with the Privacy Notices that are made available to you. By registering on the Site, the User agrees to provide information and other personal data, true, precise, updated nd complete about the user itself. At the time of registration, the User may choose the option of being contacted by a Solidarity representative by any of the means of contact that he has captured in the registration form, so when choosing said option the User is agreement to be contacted by Solidaria , however, the User accepts that choosing such option does not create an obligation for Solidaria to contact the User.<br/>
            <br/>
            Additionally, the user may choose in such registration the record the option to receive press releases and newsletters by Solidaria in the user’s email, so if you choose this option, you agree that Solidaria will send such materials solely for strictly informational purposes. All the materials sent by Solidaria in said communications and newsletters will be considered as part of the Contents of the Site and will be subject to the Terms of Use provided in this document. In the event that the User does not wish to receive said communications and newsletters at any later time, they must request their removal from the distribution list by opening the Internet link identified for this purpose at the bottom of the email message.<br/>
            <br/>
            Some functions on the Site in addition to the registration, require the use of a password that will be exclusively under the responsibility of the User. The User will be solely responsible for any material or communication sent through the Site using his registration key, and where appropriate, his password, and will be solely responsible for any obligation or damage arising or arising in connection with the sending of said materials by the User. <br/>
            <br/>
            The User accepts that any shipment of materials or communications through The open spaces of the Site by the User, including any comments, questions, suggestions or the like, are sent without restrictions, in a non-exclusive, royalty-free, perpetual and irrevocable manner and will be treated as non-confidential and non- exclusive property . The User must not send any material or communication that is obscene, defamatory or that its delivery constitutes an infringement or violation of any third party right.<br/>
            <br/>
            Solidaria is the sole owner of the information contained in this Site. Solidaria only has access to collect the information voluntarily provided by the User through email or by any other means of direct contact. We will not sell or lease this information to any natural or legal person. Solidaria uses the User's information to respond to the same regarding the reason for contacting us. Solidaria will not share the User's information with any third party that is not within our organization; Solidaria will only share the information necessary to comply with the User's requirement. Unless the User requests otherwise, Solidaria may contact the User in the future to send him information about our products or services, or about any changes to our privacy policies. The User may choose to request Solidaria not to re-establish contact with him, at any time, the above may be done at any time by contacting Solidaria through email or through the telephone number provided in the place. Solidaria takes the necessary preventive measures to protect User information. Solidaria does not safeguard sensitive information (such as information regarding credit cards), only Solidaria personnel who need the information to perform a specific job (for example, solution or marketing designs), have access to information on Personal identification of each User. The computers and servers where Solidaria stores Personal Data are protected in a secure environment.<br/>
            <br/>
            Solidaria has adopted and maintains the necessary security measures for the protection of the Personal Data that are provided by the User in order to prevent their loss, misuse, alteration, unauthorized access and theft. Notwithstanding the foregoing, the user must be aware that security measures in Internet are not impregnable and therefore must take into consideration that Solidarity cannot guarantee that your Personal Data will be free from loss, misuse, alteration, unauthorized access and / or theft, as well as damage caused by misuse of the website or virus on the network.`,
					l6: 'Use of Cookies',
					c6:
						'Cookies are pieces of information that a Web page transfers to an individual hard disk drive for registration purposes, cookies are not saved passwords. Cookies help Solidaria know which pages are most frequently visited and help Solidaria to ensure that your Site responds to the needs of users. If the User does not want to receive a cookie, or if he wants his browser to notify him when he receives a cookie, the User can use the option in his browser to disable cookies. Click on the "Help" section of your browser to learn how to change cookie preferences. However, the User must take into account that if you disable all cookies, you may not be able to take advantage of all the functions available on this Website. Solidaria does not use the personal identification data that is transferred through cookies either for promotion or for marketing purposes, nor is said information shared with third parties.',
					l7: 'Site Content',
					c7:
						'All Site Content is provided on an "as is" and "based on availability" basis. Solidaria expressly disclaims all warranties of any kind with respect to the Content of the Site, whether express or implied, including but not limited to, the implied warranties of merchantability and fitness for a particular purpose and any guarantees that the materials on the Site do not infringe rights, as well as the implied warranties of an execution or management course. Solidaria reserves the right to modify the Contents at any time, but does not assume the obligation to update any information on the Site. Solidaria makes no guarantee or representations regarding the use of the materials on this Site in terms of their integrity, accuracy, precision, suitability, usefulness, timelines, reliability or otherwise.',
					l8: 'Guarantee',
					c8: `Solidaria does not offer any guarantee that: (a) the Site meets the requirements of the User; (b) the Site will be available in an uninterrupted, free, timely, secure or error-free manner or that the server that makes the Site available will be virus free; (c) the results that may be obtained from the use of the Site or any of the services offered through the Site will be accurate or reliable; or (d) the information contained in the Site is updated and reflects the current situation of the company. If the User is not satisfied with any part of the Site, or with any of these Terms of Use, it is the sole and exclusive decision of the User to discontinue the use of this Site. The User acknowledges that the download of any material from this Site, reliability in the information on this Site and any use of this Site is solely at the risk of the User, and that the Site is available to the User at no cost. Therefore, the User acknowledges and accepts that, to the maximum extent applicable permitted by law, neither Solidaria nor its affiliates or third party content providers will be liable for direct, indirect, punitive, exemplary, incidental, special, consequential or other nature derived from or in any way related to this Site. Solidaria will not be responsible for any damage to the system of computation or loss of User information resulting from the download of any content, materials, or information from the Site. Under no circumstances will Solidarity be liable for damages of any kind, including without limitation damages, direct, incidental or consequential (including, but not limited to, damages for loss of profits, business interruption and loss of programs or information) that may be derive from the use or inability to use the Site, or any information provided therein.`,
					l9: 'Bans',
					c9: `The User must not use any hardware or software that he intends to damage, interfere with the proper operation of the Site or intercept any data or personal information of the Site. The User must not interrupt, attempt to interrupt, invade or attempt to invade in any way the operation of the Site. The User is not allowed to frame this Site or use the Solidaria marks as meta-tags, without the prior written consent of Solidaria. The User may not use frames or use framing techniques to attach any Content. Additionally, the User may not use any Content in any meta tags or any other similar technique, without the prior written consent of Solidaria. The User may not hide any Content, including these Terms of Use or any notice of copyright or property on the Site.`,
					l10: 'Compensation',
					c10: `The User agrees to indemnify, defend and remove Solidaria from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, derived from or related to improper use by the User of the Contents and services provided on the Site or any other breach by the User of the Terms of Use contained herein.`,
					l11: 'Termination of access',
					c11: `Solidaria may terminate access to the User at any time and for any reason. The provisions relating to disclaimers of guarantee, the accuracy of the information and the indemnity obligations shall survive such termination.`,
					l12: 'Modifications to the Terms of Use',
					c12: `Solidaria may revise and change any or all of the Terms of Use at any time without prior notice to the User. These changes will take effect immediately upon posting on the Site. By continuing to use the Site after the changes are published, the User acknowledges the acceptance of such changes. Any conflict between the Terms of Use and the terms of use published in an area of the Site, the latter will have preference with respect to the use of that area of the Site.`,
					l13: 'Legal use of the Site',
					c13: `The User guarantees that access to this Site and the information contained herein is not illegal under the applicable laws of the jurisdiction where the User is a resident or where the User has access to the Site. If any Content or use of this Site is contrary to the laws of the place where the User is accessing the Site, this Site is not intended for said User and the User must refrain from accessing or using this Site.`,
					l14: 'Invalid Clauses',
					c14: `If any of the terms, limitations or disclaimers contained in the above Terms of Use are considered as illegal, unenforceable or invalid in whole or in part, for any reason, under the laws applicable in the jurisdiction where the User resides or where you have had access to the Site, such illegal, unenforceable or invalid provisions or part thereof shall be removed from these Terms of Use, and such deleted provision will not affect the legality, enforceability, or validity of the rest of these Terms of Use. If any provision or part of these Terms of Use are removed in accordance with the provisions of this section, then those disposed provisions will be replaced, to the extent possible, with a legal, applicable and valid provision that is as much similar possible to the disposition removed.`,
					l15: 'Waiver of these Terms	of Use',
					c15: `No modification, authorization or waiver under these Terms of Use will be effective unless in writing and signed by a duly authorized representative of Solidaria.`,
					l16: 'Applicable laws ',
					c16:
						'These Terms of Use will be subject to the applicable laws of Mexico.   <br/>    <br/>Thank you for visiting <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>'
				}
			}
		},

		privacyTerms: {
			title: 'Notice of Privacy',
			text:
				'Fundación Solidaria, A.C. established at Boulevard Constitución 3098, 6th Floor, Colony Santa María , Monterrey, Nuevo León, Postal Code 64650, (hereinafter “Solidaria”) informs you that the personal data that are voluntarily provided by you, including sensitive personal data, shall be used only to contact you and follow up with your communication; in the case that your profile is of Solidaria interest, your personal data will be used for to make selecting decisions, evaluations, records, among others.',
			text1:
				'Solidaria shall not sell, assign or transfer your personal data to any third party foreign to Softtek without your prior consent. Notwithstanding the aforementioned, Solidaria may transfer your personal data when said transference is provided by the Law. Solidaria has adopted the security, administrative, technical and physical measures necessary to protect your personal data against harm, loss, alteration, destruction or unauthorized use, access or processing. Notwithstanding the aforementioned, it is clear that no data transmission through the Internet is completely secure and therefore Solidaria cannot guarantee that the processing of your personal data will be free from any harm, loss, alteration, destruction or unauthorized use, access or processing.',
			text2:
				'You, as the owner of personal data, may exercise before the  Responsible Person of Personal Data of Solidaria the access, ratification, cancelation and opposition rights(ARCO rights), established in the Federal Law of Protection of Personal Data in possession of Private Parties  (The Law). In addition, you may revoke, at any moment, the consent that you have granted and that was necessary to process your personal data, as well as request to limit the use or disclosure of the same. The aforementioned, sending your request according to the terms of the law to the E-mail: <a href="mailto:privacy@softtek.com">privacy@softtek.com</a> or to Boulevard Constitución 3098, 6th Floor, Colony Santa María, Monterrey, Nuevo León. Postal Code 64650.',
			text3:
				'Solidaria reserves the right to make any change to this Privacy Notice, which will be communicated through the website <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>.',
			strong:
				'If you do not wish that Solidaria process your personal data to fulfill the purposes described above, we ask you to abstain from providing them, since providing them implies you agree with all the terms of this agreement.',
			text4:
				'We inform you that, in the event of a refusal to respond the request of ARCO rights or disagreement with the request , you may submit before the Federal Institute of Access to the Information and Data Protection, the corresponding Request for Protection of Rights in the terms and conditions established in the Law and its Regulations. '
		}
	},
	'es-MX': {
		nav: {
			home: 'INICIO',
			about: 'SOBRE NOSOTROS',
			value: 'NUESTROS VALORES',
			programs: 'NUESTROS PROGRAMAS',
			projects: 'NUESTROS PROYECTOS',
			gallerie: 'GALERIA DE PROYECTOS',
			help: '¿CÓMO AYUDAR?',
			dare: 'DARE TO SHARE',
			share: 'COMPARTE TU INICIATIVA',
			contact: 'CONTACTO',
			publications: 'PUBLICACIONES',
			news: 'NOTICIAS',
			convocatory: 'CONVOCATORIAS',
			historical: 'HISTÓRICO DE PROYECTOS'
		},
		about: {
			title: 'ACERCA DE NOSOTROS',
			missTit: 'Misión',
			missTxt: 'Contribuir al desarrollo sostenible de las comunidades donde tenemos presencia.',
			visTit: 'Visión',
			visTxt:
				'Consolidarnos como una fundación global, con un comité de voluntarios en cada una de las sedes donde tenemos presencia, utilizando la tecnología para generar valor social.'
		},
		callout: {
			touch: 'EN CONTACTO, ESTAMOS AQUÍ PARA AYUDAR',
			share: 'PORQUE COMPARTIR NO ES DAR LO QUE NOS SOBRA',
			share2: 'Porque compartir no es dar lo que nos sobra'
		},
		counter: {
			labelVis: 'VISITAS',
			labelCount: 'USUARIOS',
			labelProy: 'PROYECTOS',
			labelDona: 'DOLARES',
			result: 'RESULTADOS EN CIFRAS',
			impact: 'Nuestro Impacto'
		},
		header: {
			firstTit: 'Primero',
			firstTxt: 'Descripción de la primera imagen',
			seconTit: 'Segundo',
			seconTxt: 'Descripción de la segunda imagen',
			thirTit: 'Tercero',
			thirTxt: 'Descripción de la tercera imagen'
		},
		video: {
			title: 'Videos',
			titleSecond: '¡Proyecto!',
			cerrar: 'Cerrar',
			txt: 'Proyecto más importante del mes',
			thanks: 'Gracias por participar',
			public: 'Publicar vídeo',
			header: 'Video'
		},
		volunteer: {
			title: 'Formas de voluntariado con las que trabajamos',
			microTit: 'Micro-voluntariado',
			microTit2: 'Ayuda a una organización realizando tareas de manera remota.',
			microTxt:
				'Un voluntario o un equipo de voluntarios realizan actividades en línea desde cualquier lugar para beneficiar una organización sin fines de lucro. También es llamado "voluntariado virtual" y usualmente toma pocos minutos o un par de horas para completarlo.',
			tradTit: 'Tradicional',
			tradTit2: 'Organiza acción voluntaria en equipo para apoyar a la comunidad.',
			tradTxt:
				'Desde plantar un árbol hasta construir un albergue, el objetivo de este voluntariado es conectar personas con organizaciones que necesiten mano de obra para realizar actividades donde se necesiten las manos o bien, esfuerzo físico.'
		},
		make: {
			txt:
				'Es un programa que en el transcurso del año lanza convocatorias para apoyar diferentes causas, proyectos e instituciones. Su objetivo es ofrecer alternativas fáciles y prácticas a los colaboradores para que contribuyan en alguna de las 3 modalidades:',
			time: 'Tiempo',
			money: 'Dinero',
			items: 'Artículos'
		},
		dare: {
			title: 'Alternativas para contribuir',
			donaETit: 'Donativos económicos',
			donaETxt:
				'Se refiere a los donativos económicos que se reciben en la cuenta de la Fundación y se canalizan hacia el proyecto o institución que se decide apoyar. Existen varios mecanismos para realizar los donativos:',
			donaPE1: 'Realizando una donación desde esta plataforma (ya sea para un proyecto o para la Fundación)',
			donaPE2:
				'Mediante el programa "Softtekian Solidario" donde un colaborador realiza una aportación mensual a la cuenta de la Fundación.',
			donaPE3: 'Realizando un donativo en persona destinado a una actividad específica.',
			donaSTit: 'Donativos en especie ',
			donaSTxt:
				'Son aquellas contribuciones en especie que nuestros colaboradores brindan a la Fundación, pueden ser artículos o servicios, y que son destinados a los diversos proyectos o instituciones para los que fueron recolectados. Por ejemplo: artículos para un bazar en beneficio.',
			donaTTit: 'Donativos de tiempo',
			donaTTxt:
				'Cuando participas en alguna forma de voluntariado y das parte de tu tiempo para apoyar un proyecto o actividad que organiza alguno de los comités de la Fundación.',
			voluntTit: 'Voluntariado',
			voluntTxt:
				'El voluntariado es una parte fundamental para Fundación Solidaria. Todas las actividades que se realizan son gracias al apoyo de nuestros colaboradores, sus familiares y amigos; que voluntaria y libremente dedican su tiempo, talento y habilidades a las diferentes causas y proyectos.',
			typesVolunt: 'Existen diferentes tipos de voluntariado.',
			actTit: 'Voluntario activo',
			actTxt:
				'En esta modalidad apoyas con tu tiempo asistiendo y apoyando las actividades que realizan los comités de Fundación Solidaria. Te permite involucrarte con las personas que son beneficiadas y experimentar la alegría de ayudar de primera mano.',
			remoteTit: 'Voluntario remoto',
			remoteTxt:
				'Si no puedes participar activamente, puedes apoyarnos con la organización y la logística requeridas para realizar las actividades.',
			comiteTit: 'Miembro de comité',
			comiteTxt:
				'En cada sede es necesario tener un equipo encargado de definir las actividades a realizar; que se involucre en la planeación, organización y desarrollo de las actividades del comité. Cabe aclarar que no requieres asistir a todas ellas.',
			friendTit: 'Softtekian Solidario',
			friendTxt:
          `Este es un programa que opera en todos los comités de las oficinas Softtek en México y Brasil. Lo constituyen aquellos colaboradores que voluntariamente acceden a donar una cantidad que se descuenta de su salario mensual para apoyar las actividades de su comité y los programas de Fundación Solidaria. Fundación Solidaria proporciona recibos deducibles de impuestos cada año por estas donaciones. <a href="https://forms.office.com/r/J2z5EwzvP0">Registrate aquí</a>`,
			button: 'ESTOY INTERESADO',
			know: 'CONOCE MÁS DE DTS',

		},
		// friend: {
		//   title: 'Softtekian Solidario',
		//   txt: 'Este es un programa que opera en todos los comités de las oficinas Softtek en México y Brasil. Lo constituyen aquellos colaboradores que voluntariamente acceden a donar una cantidad que se descuenta de su salario mensual para apoyar las actividades de su comité y los programas de Fundación Solidaria. Fundación Solidaria proporciona recibos deducibles de impuestos cada año por estas donaciones.'
		// },
		initiative: {
			title: 'Comparte tu iniciativa',
			button: 'TENGO UNA IDEA',
			txt:
				'Todas las actividades realizadas a nombre de Fundación Solidaria son definidas por los comités de las diferentes sedes. Si tú tienes conocimiento de una organización a la que podamos apoyar, o quieres proponer una actividad que beneficie a tu comunidad, queremos escucharte. <br/> Compártenos los detalles de tu propuesta para que el comité de tu localidad pueda analizarla, y si es factible, integrarla en su plan de actividades. <br/>¡Fundación Solidaria somos todos!'
		},
		programs: {
			know: 'CONOCE MÁS',
			title: 'Nuestros Programas',
			txt: 'Programas diseñados para contribuir al desarrollo sostenible de las comunidades donde operamos.',
			health: 'SALUD',
			healthTxt:
				'Para la Fundación Solidaria es fundamental trabajar en la prevención de enfermedades, así como apoyar a instituciones o asociaciones que ayudan a personas con algún padecimiento o enfermedad. Estas acciones están dirigidas a brindar servicios y provisión de materiales a través del voluntariado y los prestadores de servicio social.',
			dress: 'VIVIENDA, ALIMENTACIÓN, Y VESTIDO',
			dressTxt:
				'La Fundación, de acuerdo a sus capacidades, brinda donativos en especie para apoyar a instituciones que requieren de la ampliación, construcción o remodelación de sus instalaciones, así como con la donación de ropa nueva o usada, en buen estado, y despensas con productos de la canasta básica. Apoyamos con lo siguiente:',
			dressT0:
				'Recaudación de ropa nueva o usada en buen estado y calzado para canalizarlos a instituciones de asistencia social que la requieran.',
			dressT1: 'Entrega de despensas con productos de la canasta básica, en zonas marginadas.',
			dressT2: 'Entrega de materiales de construcción y remodelación.',
			dressT3: 'Entrega de aparatos electrónicos.',
			nature: 'NATURALEZA',
			natureTxt:
				'Fundación Solidaria impulsa y promueve actividades que permiten mantener este equilibrio de manera responsable y organizada. En este ámbito también se incluye la asistencia a animales y el apoyo en los desastres causados por fenómenos naturales. Nuestras acciones están dirigidas a brindar solamente servicios en las siguientes áreas:',
			nT1:
				'Protección a animales: apoyar a instituciones de asistencia social que brinden albergue, comida o tratamiento médico a animales que lo requieran.',
			nT2:
				'Desastres Naturales: coordinación con instituciones para la recolección y entrega de despensas y materiales requeridos en apoyo a la población que sea afectada por los fenómenos naturales.',
			nT3: 'Labores de reforestación y limpieza en áreas verdes.',
			nt4: 'Crear conciencia sobre la importancia y los beneficios del reciclaje.',
			natureTxt2:
				'La Fundación apoya a promover y difundir las campañas de otras ONG o grupos que buscan impulsar y preservar el equilibrio ecológico. Por ejemplo:',
			nT4: 'Campañas de adopción y apoyo a animales sin hogar.',
			nT5: 'Campañas de sensibilización para evitar la crueldad hacia los animales.',
			nT6: 'Campañas de donación de víveres en beneficio de personas damnificadas.',
			nT7: 'Capacitar a instituciones sobre la importancia y beneficios del reciclaje.',
			solidarity: 'CONVIVENCIA Y SOLIDARIDAD',
			solTxt:
				'Fundación Solidaria busca fomentar y enriquecer las relaciones sociales a través de la sana convivencia social a favor de los grupos más desprotegidos o vulnerables, y se solidariza con ellos para apoyarlos en mejorar su calidad de vida. <br /> Nuestras acciones están dirigidas a brindar eventos, servicios y provisión de materiales, ya sea directamente con los grupos desprotegidos o a través de instituciones afines: ONG, casas hogar, orfanatos, reclusorios, centros de rehabilitación, refugios, albergues, escuelas, comunidades rurales.',
			sT1:
				'Alianzas: la Fundación apoya a promover y difundir las campañas de otras ONGs o grupos que busquen la integración y convivencia social.',
			tit2: 'Provisión de materiales',
			tit3:
				'La Fundación, de acuerdo a sus capacidades, brinda donativos en especie para apoyar a instituciones que requieren de la ampliación, construcción o remodelación de sus instalaciones, así como con la donación de ropa nueva o usada, en buen estado, y despensas con productos de la canasta básica:',
			sT2: 'Material de construcción e infraestructura.',
			sT3: 'Alimentación y vestimenta.',
			edu: 'EDUCACIÓN',
			eduTxt:
				'Para la fundación el ámbito educativo es un componente central en el desarrollo de las comunidades donde operamos, por esta razón, generamos proyectos de alto impacto que benefician no solo a quienes participan activamente en ellos, sino que también son el inicio de una cadena de valor que multiplica el impacto y alcance de los mismos.'
		},
		values: {
			pillar: 'NUESTRO PILAR',
			title: 'Nuestros Valores',
			transparency: 'TRANSPARENCIA',
			transTxt:
				'Claridad y rendición de cuentas no sujetas a ninguna condición, pues los recursos económicos que hacen posible nuestra labor provienen de nuestra gente, donaciones y de la organización.',
			commitment: 'COMPROMISO',
			commTxt:
				'Realizar las tareas necesarias para convertir en realidad las acciones y beneficios que promovemos en cada uno de nuestros programas.',
			synergy: 'SINERGIA',
			synTxt:
				'Unir nuestros esfuerzos a nivel local, regional y global con el fin de multiplicar nuestras fortalezas y generar un mayor impacto en nuestro entorno.',
			trust: 'CONFIANZA',
			trustTxt:
				'Tenemos la seguridad de que cada uno de nuestros comités opera íntegramente de acuerdo a nuestra visión.',
			focus: 'Nuestro foco',
			focusTxt:
				'Fundación Solidaria enfoca sus esfuerzos en acciones que contribuyan al desarrollo sostenible de las comunidades donde opera Softtek. Por esta razón, su Presidenta honoraria, Blanca Treviño, definió el ámbito educativo como su prioridad para la generación de iniciativas que impulsen y fomenten el progreso de las localidades donde tenemos presencia.',
			belief: 'Nuestras creencias',
			beliefTxt:
				'Compartir es el inicio del ciclo de la abundancia y crecimiento. Las personas son capaces de auto sustentarse cuando son respetadas como seres humanos y cuentan con oportunidades para crecer. La suma de esfuerzos genera grandes cambios.',
			reason: '¿Qué nos motiva?'
		},
		weshare: {
			title: 'Nuestros proyectos',
			txt:
				'Fundación Solidaria está formada por comités en diferentes geografías que evalúan propuestas de proyectos locales y solicitudes de apoyo relacionadas con la educación, especificamente aquellas que involucran el uso de tecnología. \n Adicionalmente también consideran proyectos y requerimientos relacionados con algunas de las siguientes causas:',
			causes1: 'Desastres naturales, como terremotos, inundaciones e incendios.',
			causes2: 'Organizaciones e instituciones de salud que realizan actividades anuales para recaudar fondos.',
			causes3: 'Proyectos relacionados con la preservación y mejora del medio ambiente.',
			causes4: 'Apoyo a gente de la tercera edad, niños y pacientes de instituciones de salud.',
			exc:
				'*Cualquier otro caso que no califique en los tópicos descritos en esta sección deberá ser evaluada por el Comité Ejecutivo de Fundación Solidaria.',
			tit1: 'Desastres Naturales',
			titlePoint: 'Durante la selección de los proyectos, se tomarán a consideración los siguientes puntos:',
			titP1: 'Sostenibilidad',
			point1: 'Proyectos que trasciendan en el tiempo, que son replicables o permanentes.',
			titP2: 'Impacto',
			point2: 'Proyectos cuyo efecto positivo puede ser cuantificable y verificable.',
			titP3: 'Factibilidad',
			point3:
				'Proyectos que se adhieren a la misión, visión y programas de nuestra Fundación; y que pueden ser realizados con nuestros recursos económicos a través de nuestros voluntarios.',
			committee:
				'El comité de la fundación responderá vía correo electrónico a todas las solicitudes notificando si procede o no la adjudicación de apoyo. ',
			some: 'Algunas consideraciones'
		},
		btn: {
			add: 'Agregar',
			save: 'Guardar',
			cancel: 'Cancelar',
			update: 'Actualizar',
			send: 'Enviar',
			login: 'INGRESAR',
			logout: 'Salir',
			dashboard: 'Administración',
			sendUs: 'Envíanos un mensaje',
			subscribe: 'Inscribirme al programa',
			callme: 'Contáctame',
			share: '¡Comparte!',
			join: 'Unirse',
			edit: 'Editar',
			close: 'Cierre',
			infoClose: 'Información de cierre',
			see: 'Ver',
			register: 'Registrarse',
			donation: 'DONAR',
			approve: 'Aprobar',
			delete: 'Eliminar',
			detail: 'Detalle',
			unsubscribe: 'Darse de baja',
			contact: 'Contactar'
		},
		help: {
			required: 'Este campo es requerido',
			obliged: 'Campo obligatorio',
			right: 'Campo correcto',
			number: 'El campo no es un número',
			email: 'El campo no es email',
			photo: 'Seleccione una fotografía',
			date: 'La fecha debe ser entre',
			day: 'dias',
			year: 'años',
			min3: 'El mínimo de caracteres permitido es 3',
			min4: 'El mínimo de caracteres permitido es 4',
			min5: 'El mínimo de caracteres permitido es 5',
			min6: 'El mínimo de caracteres permitido es 6',
			min7: 'El mínimo de caracteres permitido es 7',
			min15: 'El mínimo de caracteres permitido es 15',
			min580: 'El mínimo de caracteres permitido es 580',
			max7: 'El mínimo de caracteres permitido es 7',
			max20: 'El el maximo de caracteres permitido es 2000.',
			max25: 'El el máximo de caracteres permitido es 25',
			max35: 'El el máximo de caracteres permitido es 35',
			max50: 'El el máximo de caracteres permitido es 50',
			max100: 'El el máximo de caracteres permitido es 100',
			max140: 'El el máximo de caracteres permitido es 140',
			max280: 'El el máximo de caracteres permitido es 280',
			max500: 'El el máximo de caracteres permitido es 500',
			beforeToday: 'No se puede seleccionar una fecha anterior del día de hoy.',
			beforeBegin: 'No se puede seleccionar una fecha antes de la fecha de inicio seleccionada.',
			rank: 'No esta en el rango de tiempo de vida del proyecto',
			reqStartDate: 'Es requerida la fecha inicial',
			reqEndDate: 'Es requerida la fecha final',
			beforeStart: 'No se puede seleccionar una fecha anterior a la inicial.',
			equalStart: 'No se puede seleccionar una fecha igual o anterior a la inicial.',
			equalToday: 'No se puede seleccionar una fecha igual o anterior al día de hoy.',
			cannotEditStart: 'No puede editar la fecha de inicio, el proyecto ya está publicado',
			withoutProject: 'Sin proyecto'
		},
		label: {
			activitie: 'Actividades',
			categorie: 'Categorías',
			cause: 'Causas',
			contact: 'Contactos',
			donation: 'Donaciones',
      paypal: 'Donaciones Paypal',
			friend: 'Amigos Solidarios',
			initiative: 'Iniciativas',
			institution: 'Instituciones',
			role: 'Roles',
			sede: 'Sedes',
			skill: 'Habilidades',
			subsede: 'Subsedes',
			name: 'Nombre',
			fullName: 'Nombre completo',
			lastName: 'Apellidos',
			description: 'Descripción',
			phone: 'Teléfono',
			email: 'Correo electrónico',
			msg: 'Mensaje',
			amount: 'Cantidad',
			kind: 'Tipo',
			manual: 'manual',
			transfer: 'Transferencia',
			project: 'Seleccione el proyecto',
			country: 'País',
			state: 'Estado',
			city: 'Ciudad',
			zip: 'Código Postal',
			address: 'Domicilio',
			is: 'IS',
			id: 'ID',
			web: 'Página web',
			languageCode: 'Código de idioma',
			countryCode: 'Código de país',
			paypal_account: 'Cuenta de PayPal',
			paypal_clientId: 'ID de PayPal',
      paypal_secret: 'Llave secreta de Paypal',
      conekta_account: 'Cuenta de Conekta',
			conekta_clientId: 'ID de Conekta',
			conekta_secret: 'Llave secreta de Conekta',
			bank_name: 'Banco',
			bank_account: 'Cuenta bancaria',
			termsofPrivacy: 'Terminos de privacidad',
			publishDate: 'Publicación',
			startDate: 'Inicio',
			endDate: 'Terminación',
			author: 'Autor',
			content: 'Contenido',
			title: 'Título',
			continent: 'Continente',
			currency: 'Divisa',
			simbolCurrency: 'Símbolo de divisa',
			changeDolar: 'Divisa cambio a dolar',
			zone: 'Zona horaria',
			enable: 'Habilitar donaciones monetarias',
			startDonation: 'Inicio de donaciones',
			endDonation: 'Fin de donaciones',
			receipt: 'Recibo',
			legend: 'Leyenda',
			showCounter: 'Mostrar contador',
			showCountDown: 'Mostrar cuenta regresiva',
			itemsDonated: 'Donaciones en especie',
			itemsEnable: 'Habilitar donaciones en especie',
			shareBar: 'Compartir',
      shareTree: 'Ubica tu árbol en el Amazonas aquí',
      shareTreeLink: 'Tu árbol - Saving the Amazon',
			info: 'Información',
			volunteer: 'Voluntariado',
			subscription: 'Habilitar registro de voluntarios',
			subscription_required: 'Voluntarios requeridos',
			showCounterVolunteer: 'Mostrar voluntarios',
			goal: 'Objetivo',
			type: 'Tipo',
			cover: 'Portada',
			moneyDonated: 'Donaciones monetarias',
			start: 'Inicio',
			event: 'Evento',
			list: 'Lista',
			listProject: 'Lista de proyectos',
			specialEvent: 'Evento Especial',
			probono: 'Pro-bono',
			campaign: 'Campaña',
			report: 'Reporte',
			reportProject: 'Reporte de proyectos',
			reportDonations: 'Reporte de donaciones',
			export: 'Exportar registros en CSV',
			inkindDonations_startDate: 'Inicio de recepción',
			inkindDonations_endDate: 'Fin de recepción',
			inkindDonations_text: '¿Qué necesita tu proyecto? (Separar con comas)',
			volunteering_startDate: 'Inicio de registro',
			volunteering_endDate: 'Fin de registro',
			volunteering_volunteersRequired: 'Número de voluntarios requeridos',
			ubicationActivitie: 'Ubicación de la actividad',
			contact_email: 'Correo de contacto',
			contact_address: 'Dirección de la actividad',
			thumbnail: 'Miniatura',
			dateRegistry: 'Registro',
			continue: 'Seguimiento',
			listSuscribe: 'Lista de suscritos',
			convocatory: 'Convocatoria',
			status: 'Estado',
			new: 'Nuevo',
			published: 'Publicado',
			finalized: 'Finalizado',
			completed: 'Completado',
			allTypes: 'Todos los tipos de proyectos',
			publicProject: 'Publicar proyecto',
			monthlyAmount: 'Cantidad a donar mensualmente',
			volunteeringRemaining: 'Voluntarios faltantes',
			completeDate: 'Cierre',
			allSub: 'Todas las subsedes',
			finalizeProject: 'Finalizar proyecto',
			finalize: 'Finalizar',
			search: 'Búsqueda',
			actionsCarriedOut: 'Acciones llevadas a cabo',
			volunteerColla: 'Colaboradores voluntarios',
			externalVolunters: 'Voluntarios externos',
			volunterHours: 'Horas de voluntariado',
			investment: 'Inversión',
			benefit: 'Beneficiados',
			comments: 'Comentarios (opcional)',
			numVolunteers: 'Número de voluntarios',
			numColla: 'Número de colaboradores voluntarios',
			totalHoursVolunteer: 'Total de horas de voluntariado invertidas',
			numBenefit: 'Número de beneficiados',
			summaryProject: 'Resumen del proyecto',
			searchBar: 'Barra de búsqueda',
			upload: 'Subir',
			gallerieImages: 'Galería de Imagenes',
			uploadImage: 'Subir imagen',
			viewGallerie: 'Administrar galeria',
			donationMin0: 'Las donaciones manuales deben ser a mayores a 0',
			donationMax120: 'Las donaciones manuales deben ser menores a 120 USD',
			assignTo: 'Proyecto asignado',
			userSF: 'Eres softtekian solidario',
			leftSF: 'Dejar de ser softtekian solidario',
			joinSF: 'Volver a ser softtekian solidario',
			noticeOfPrivacy: 'Aviso de Privacidad',
			hello: 'Hola',
			language: 'Idioma',
			confirmOfRead: 'Confirmo haber leído el ',
			consult: 'Consulte los',
			site: 'del sitio',
			loginEmail: 'Su correo sin la terminación @softtek.com',
			loginPassword: 'Contraseña de tu correo Softtek',
      evidence: 'Evidencia',
      currencyChange: 'La divisa y su tasa de cambio serán actualizadas'
		},
		modal: {
			activitie: 'Actividad',
			categorie: 'Categoría',
			cause: 'Causa',
			contact: 'Contacto',
			donation: 'Donación',
			friend: 'Softtekian solidario',
			initiative: 'Iniciativa',
			institution: 'Institución',
			role: 'Rol',
			sede: 'Sede',
			skill: 'Habilidad',
			subsede: 'Subsede',
			solidaryFriend: 'Softtekian Solidario',
			toBe: '¡Quiero ser softtekian solidario!',
			question: '¿Quieres ser softtekian solidario?',
			project: 'Proyecto',
			donor: 'Donador',
			usd: 'USD',
			typeDonation: 'Tipo de donación',
			detailDonation: 'Detalle de donación',
			originalAmount: 'Monto original',
			donationNet: 'Donación neta',
			donationGross: 'Donación bruta',
			fee: 'Comisión',
			donorCreator: 'Registrada por'
		},
		table: {
			registry: 'Registros',
			active: 'Activo',
			inactive: 'No activo',
			create: 'Creado',
			update: 'Actualizado',
			cause: 'Motivo',
			amount: 'Monto',
			approved: 'Aprobado'
		},
		alert: {
			causeTitle: 'Justificación del cambio',
			causeChange: 'Motivo del cambio',
			causeErrorText: 'Información erronea',
			upText: 'Actualización',
			desTitle: 'Justificación',
			confiTitle: '¿Estás seguro?',
			confiText: 'Este registro será ',
			error401Title: 'No autorizado',
			error401Text: 'No cuenta con los permisos requeridos',
			errorGeneralTitle: 'Error en el servidor',
			errorGeneralText: 'Por favor, intenta más tarde',
			successfully: 'exitosamente',
			registry: 'Registro',
			success: 'Éxito',
			activitieAdd: 'Actividad agregada',
			categorieAdd: 'Categoría agregada',
			causeAdd: 'Causa agregada',
			adminAdd: 'Usuario agregado',
			institutionAdd: 'Institución agregada',
			donationAdd: 'Donación registrada',
			donationReverse: 'Una vez aprobada no podrá revertirse',
			donationApprove: '¿Cómo comprobó la donación?',
			contactTh: '¡Gracias por el comentario!',
			initiTh: '¡Gracias por tu iniciativa!',
			contactText: 'Muy pronto nos pondremos en contacto',
			contactDelete: '¿Ya se contactó a esta persona? Brinda una descripción al respecto',
			changes: 'Cambios guardados',
			changeRegistry: 'Este registro será ',
			friendToBe: 'Este softtekian solidario será',
			initiativeToBe: 'La iniciativa será',
			initiative: 'Iniciativa',
			friend: 'Softtekian solidario',
			changesRegistry: 'Cambios registrados en el sistema',
			contactCuase: 'La persona fue contactada con exito',
			projectCreate: 'Proyecto creado',
			newCreate: 'Noticia creada',
			convocaCreate: 'Convocatoria creada',
			sedeAdd: 'Sede agregada',
			subsedeAdd: 'Subsede agregada',
			off: 'desactivado',
			on: 'activado',
			offCap: 'Desactivado',
			onCap: 'Activado',
			causeOff: 'Motivo de inactivación',
			causeOn: 'Motivo de activación',
			approveRegistry: 'Registro aprobado',
			friendTh: '¡Gracias por tu cooperación!',
			convocatoryTh: '¡Gracias por tu registro!',
			donationTh: '¡Gracias por tu Donación!',
			alertClose: 'Una vez cerrado no podrá editarse de nuevo',
			projectComplete: 'Proyecto completado',
			profileUpdate: 'Tu perfil ha sido actualizado',
			profileValidation: 'Es necesario llenar los campos de tu perfil',
			alreadyExist: 'Ya está registrado',
			conectionOff: 'Sin conexión',
			error: 'Error',
			changesOff: 'No se guardaron los cambios',
			contactCreate: 'Contacto registrado',
			delete: 'Eliminado',
			suscribeDelete: 'Suscriptor eliminado exitosamente',
			contactConvocatory: 'Contactado',
			registryDelete: 'Este registro será eliminado',
			suscriber: '¿Ya se contacto a ',
			request: 'Solicitud enviada',
			skillAdd: 'Habilidad agregada',
			confirmation: 'Agrega información complementaria de la solicitud',
			new: 'nuevo',
			published: 'Publicado',
			finalized: 'Finalizado',
			completed: 'Completado',
			password: 'Contraseña incorrecta',
			passwordInvalid: 'Contraseña invalida',
			user: 'Usuario incorrecto',
			userInvalid: 'Usuario invalido',
			activateTo: 'Activar a',
			deactivateTo: 'Desactivar a',
			registerActivate: 'Registro activado',
			registerDeactivate: 'Registro desactivado',
			writeCause: 'Debe escribir una justificación del cambio',
			alreadyFriend: 'Ya eres softtekian solidario',
			videoUploaded: 'El vídeo se subio correctamente',
			videoConfirmation: '¿Estás seguro que deseas publicar el vídeo?',
			wasFriend: 'Para solicitar ser reactivado como softtekian solidario ingresa desde tu perfil',
			friendThanks: 'Muy pronto nos pondremos en contacto',
			credentials: 'Usuario o contraseña inválidos',
			token: 'Sesión expirada',
			existSofttekian: 'Usuario Softtekian',
			instructionsSofttekian: 'Para cambio de Rol contacta a Soporte Técnico',
			file: 'Tipo de archivo no permitido',
			unsubscribed: 'Baja registrada',
			imageDeleted: 'Imagen eliminada',
			imageAdded: 'Imagenes agregadas',
			serviceUnavailable: 'Servidor en mantenimiento',
			noAdblocker: 'Asegúrese de no tener activada una extensión Ad-blocker',
			// Empty profile
			profileEmptyTitle: 'Te invitamos a registrar tu perfil',
			profileEmptyText:
				'Es importante para que puedas tener la opción de contribuir a los proyectos de Fundación Solidaria'
		},
		general: {
			contacted: 'Contactado',
			user: 'Usuario',
			date: 'Fecha',
			actions: 'Acciones',
			rol: 'Tipo de Rol',
			password: 'Contraseña',
			login: 'Entrar',
			logout: 'Salir',
			profile: 'Perfil',
			dashboard: 'Administrar',
			joinus: 'Para unirte debes iniciar sesión',
			volunteer: 'Voluntarios',
			confirmation: 'Sí',
			negation: 'No',
			pendingField: 'Favor de asignar',
			pending: 'Pendiente',
			loading: 'Cargando...',
			found: 'NO SE ENCONTRARON RESULTADOS',
			actually: 'Actualmente, no hay proyectos disponibles'
		},
		dashboard: {
			administration: 'ADMINISTRACIÓN',
			projects: 'Proyectos',
			news: 'Noticias',
			conv: 'Convocatorias',
			admin: 'Administradores',
			create: 'Crear',
			list: 'Lista',
			gallerie: 'Galería',
			catalog: 'Catálogos',
			admon: 'Administrar',
      paypal: 'PayPal',
			welcome: 'Bienvenido',
			inf: 'Tu información',
			visit: 'Información desde donde nos visitas',
			activities: 'Últimas actividades',
			interaction: 'Interacción',
			configuration: 'Configuración',
			users: 'Usuarios',
			gallProjects: 'Galería de proyectos',
			reports: 'Reportes',
			multimedia: 'Multimedia'
		},
		news: {
			list: 'Lista de noticias',
			gall: 'Galería de noticias',
			public: 'Publicar noticia',
			edit: 'Editar noticia',
			new: 'Nueva noticia'
		},
		convocatory: {
			list: 'Lista de convocatorias',
			gall: 'Galería de convocatorias',
			public: 'Publicar convocatoria',
			edit: 'Editar convocatoria',
			subscribed: 'Suscritos'
		},
		report: {
			cardStatus: 'Estado',
			cardPaypal: 'Donaciones Paypal',
			cardOthers: 'Otras donaciones',
			cardVolunteers: 'Voluntarios',
			chartDonations: 'Gráfica de donaciones',
			chartTypes: 'Tipos de donaciones',
			tableDonations: 'Tabla de donaciones',
			summaryLabel: 'Resumen del proyecto',
			summaryTitle: 'Título del proyecto',
			summaryObjective: 'Objetivo',
			summaryDescription: 'Descripción',
			closureLabel: 'Información de cierre',
			closureDonations: 'Donaciones',
			closureMonetary: 'Donaciones monetarias',
			closureInversion: 'Inversión',
			closureVolunteering: 'Voluntariado',
			closureVolunteers: 'Número de voluntarios',
			closureCollaborators: 'Número de colaboradores voluntarios',
			closureHoursVolunteering: 'Total de horas de voluntariado invertidas',
			closureBeneficiaries: 'Beneficiados',
			closureComments: 'Comentarios'
		},
		dash: {
			lineChart: 'Línea del tiempo de donaciones (USD)',
			pieCHart: 'Total de donaciones por tipo (USD)',
			cardUsers: 'Usuarios en linea',
			cardAdmins: 'Administradores en linea',
			cardSofttekians: 'Softtekians en linea',
			cardVisits: 'Visitantes en linea'
		},
		paypal: {
			head:
				'Para la solicitud del recibo fiscal de tu donación favor de ponerte en contacto con Fundación Solidaria a través del correo',
			bank: 'Banco',
			account: 'Cuenta',
			fullAccount: 'Clabe',
			sub: 'Si quieres realizar una donación mediante transferencia, los datos de la cuenta son los siguientes:',
			footer: 'Una vez realizada la transferencia, favor de enviar tu comprobante a Fundación Solidaria al correo'
		},
		donationLabel: {
			projectD: 'Para desasignar proyecto seleccione el campo vacío'
		},
		carrousel: {
			new: 'Proyecto agregado al carrusel',
			active: 'Proyecto visible en el carrusel',
			unactive: 'Proyecto eliminado del carrusel',
			duplicated: 'No pueden haber dos proyectos publicados de la misma subsede en el carrusel',
			title: 'Carrusel',
			instruction: 'Selecciona el proyecto',
			publish: 'Publicar proyecto en carrusel',
			list: 'Lista de registros de carrusel'
		},
		softtekian: {
			info: 'Sin información',
			addInfo: 'Agregue',
			addInfo2: 'a su perfil, por favor',
			causes: 'Causas que apoyas',
			typeAct: 'Tipo de actividades en las que te gustaría participar',
			skills: 'Habilidades que posees',
			photo: 'Fotografía de perfil',
			updateProfile: 'Actualizar mi perfil'
		},
		noticePrivacy: {
			introduction: {
				title: 'Términos y Condiciones',
				content:
					'Fundación Solidaria, A.C.  (en lo sucesivo “Solidaria”) Solidaria ofrece al Usuario toda la información, herramientas y servicios dentro del Sitio <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>, sujeto a la aceptación por parte del Usuario de los siguientes Términos de Uso, así como las Políticas y Avisos de Privacidad que correspondan. Por favor, léalos atentamente. El uso del Sitio por el Usuario constituye la aceptación del mismo a estos Términos de Uso y demás documentos de Privacidad.'
			},
			definitions: {
				title: 'Definiciones.',
				content: {
					label1: 'Contenido',
					text1:
						'se refiere a todo el contenido en el Sitio, incluyendo, sin limitación, texto, diseño, gráficos, logotipos, íconos, imágenes, clips de audio, clips de vídeo, materiales descargados, interfaces, herramientas, aplicaciones, código, software y marcas.',
					label2: 'Datos Personales',
					text2: 'cualquier información concerniente a una persona física identificada o identificable.',
					label3: 'Sitio y Página Web',
					text3:
						'se refiere a <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>, propiedad de y operado por Solidaria. ',
					label4: 'Solidaria',
					text4:
						'se refiere a Fundación Solidaria, A.C. con domicilio en Boulevard Constitución 3098 Piso 6, Colonia Santa María, Monterrey, Nuevo León C.P. 64650. Usuario: se refiere a la persona que está navegando en el Sitio o utilizándolo de cualquier manera.',
					label5: 'Términos de Uso',
					text5: 'se refiere a los siguientes términos y condiciones relativas al uso del Sitio.'
				}
			},
			termsOfUse: {
				title: 'Términos de Uso.',
				content: {
					l1: 'Información en el Sitio',
					c1:
						'La información contenida en el Sitio deberá ser utilizada únicamente para propósitos informativos generales y no deberá ser utilizada o referida como la única base para la toma de decisiones o como una representación legal o garantía por parte de Solidaria. Solidaria no es responsable si la información disponible en este Sitio no se encuentra exacta, completa o actualizada. La información contenida en el Sitio está destinada a proporcionar información general sobre un tema o temas en particular y no en forma exhaustiva sobre dicho(s) tema(s).',
					l2: 'Propiedad Intelectual',
					c2:
						'Todo el Contenido es propiedad exclusiva de Solidaria, sus afiliadas, sus licenciantes o de sus proveedores de Contenidos y está protegido por las leyes de propiedad intelectual y otras leyes aplicables. El Usuario está autorizado a acceder, copiar, descargar e imprimir los materiales contenidos en el Sitio sólo para uso personal y no comercial, siempre que tales materiales no sean modificados y que cualquier aviso de derechos de autor o de marcas registradas no sean modificados o eliminados. Cualquier otro uso de los Contenidos, incluyendo sin limitación, la reproducción, modificación, uso, publicación, transmisión en medios, carga, transmisión, fijación, distribución, licenciamiento o la creación de obras derivadas de los Contenidos, está estrictamente prohibido. Solidaria se reserva todos los derechos expresamente no otorgados en estos Términos de Uso, así como para el Sitio y su Contenido. <br/> <br/>Solidaria ® y su logotipo son marcas registradas de Fundación Solidaria, A.C. Esta marca y cualquier otra marca y marcas de servicio que son parte de los Contenidos no deben ser utilizadas de ninguna manera sin el previo consentimiento por escrito de Solidaria. Nada en este Sitio deberá interpretarse como el otorgamiento de cualquier licencia o derecho de uso de cualquier marca parte del Contenido.',
					l3: 'Uso de información de contactos',
					c3:
						'El Usuario no usará información de contactos proporcionada en el Sitio para cualquier propósito no autorizado, incluyendo mercadotecnia, sin el previo consentimiento por escrito de Solidaria.',
					l4: 'Enlaces a otros sitios',
					c4:
						'El Sitio ofrece enlaces a páginas Web y acceso a Contenidos, productos y servicios de terceros, incluidos usuarios, anunciantes, afiliadas y patrocinadores del Sitio. El Usuario acepta que Solidaria no es responsable por la disponibilidad y el Contenido incluido en páginas Web de terceros. Se previene al Usuario a revisar las políticas anunciadas por otras páginas Web en relación con sus propios términos de uso antes de su uso. Solidaria no es responsable por el contenido de terceros accesible a través del Sitio, incluyendo opiniones, consejos, declaraciones y anuncios, y el Usuario asumirá todos los riesgos asociados con el uso de dichos Contenidos. No deberán realizarse enlaces a este Sitio sin el previo consentimiento por escrito de Solidaria.',
					l5: 'Información personal y no personal',
					c5: `Algunas funciones en el Sitio requieren de registro, lo que implica que nos proporcione algunos de sus Datos Personales. Sus Datos Personales se obtienen, usan, almacenan y, en general, son tratados de conformidad con los Avisos de Privacidad que se pongan a su disposición. Al registrarse en el Sitio, el Usuario se compromete a proporcionar información y otros Datos Personales verdaderos, precisos, actualizados y completos sobre sí mismo. Al momento de llevar a cabo su registro, el Usuario podrá elegir la opción de ser contactado por un representante de Solidaria por cualquiera de los medios de contacto que haya capturado en el formulario de registro, por lo que al elegir dicha opción el Usuario está de acuerdo en ser contactado por Solidaria, sin embargo, el Usuario acepta que el elegir dicha opción no crea una obligación para Solidaria de contactar al Usuario.<br/>
            <br/>
            Adicionalmente, el Usuario podrá elegir en dicho registro la opción de recibir comunicados y boletines informativos por parte de Solidaria en su correo electrónico, por lo que en caso de elegir dicha opción, el Usuario acepta que Solidaria enviará dichos materiales únicamente para propósitos estrictamente informativos. Todos los materiales enviados por Solidaria en dichos comunicados y boletines informativos se considerarán como parte de los Contenidos del Sitio y estarán sujetos a los Términos de Uso previstos en este documento. En caso de que el Usuario no desee recibir dichos comunicados y boletines informativos en cualquier momento posterior, deberá solicitar su remoción de la lista de distribución abriendo la liga de Internet identificada para tal propósito al pie del mensaje de correo electrónico.<br/>
            <br/>
            Algunas funciones en el Sitio adicionalmente al registro, requieren el uso de una contraseña que estará exclusivamente bajo la responsabilidad del Usuario. El Usuario será el único responsable de cualquier material o comunicación enviada a través del Sitio utilizando su clave de registro, y en su caso, su contraseña, y será el único responsable de cualquier obligación o daño derivado o que surja en relación con el envío de dichos materiales por el Usuario. <br/>
            <br/>
            El Usuario acepta que cualquier envío de materiales o comunicaciones a través de los espacios abiertos del Sitio por el Usuario, incluido cualquier comentario, preguntas, sugerencias o similares, se envía sin restricciones, en forma no exclusiva, libre de regalías, perpetua e irrevocable y será tratado como no confidencial y propiedad no exclusiva. El Usuario no deberá enviar cualquier material o comunicación que sea obsceno, difamatorio o que su entrega constituya una infracción o violación de cualquier derecho de tercero. <br/>
            <br/>
            Solidaria es el único propietario de la información contenida en este Sitio. Solidaria únicamente tiene acceso a recolectar la información que voluntariamente nos proporciona el Usuario a través del correo electrónico o por cualquier otro medio de contacto directo. No vamos a vender o a arrendar esta información a ninguna persona física o moral. Solidaria utiliza la información del Usuario para responderle a éste mismo respecto al motivo por el cual nos contactó. Solidaria no compartirá la información del Usuario con ningún tercero que no se encuentre dentro de nuestra organización, Solidaria únicamente compartirá la información necesaria para cumplir con el requerimiento del Usuario. Salvo que el Usuario solicite lo contrario, Solidaria podría contactar al Usuario en un futuro para hacerle llegar información sobre nuestros productos o servicios, o bien sobre cualquier cambio a nuestras políticas de privacidad. El Usuario puede optar por solicitarle a Solidaria que no vuelva a establecer contacto con él, en ningún momento, lo anterior lo puede hacer en cualquier momento poniéndose en contacto con Solidaria por medio del correo electrónico o bien por medio del número telefónico que se proporciona en el Sitio. Solidaria toma las medidas preventivas necesarias para proteger la información del Usuario. Solidaria no resguarda información delicada (tal como información respecto a tarjetas de crédito), solamente el personal de Solidaria que necesita la información para la realización de un trabajo en específico (por ejemplo, diseños de soluciones o mercadotecnia), tiene acceso a la información de identificación personal de cada Usuario. Las computadoras y los servidores en donde Solidaria almacena Datos Personales están resguardadas en un entorno seguro.<br/>
            <br/>
            Solidaria ha adoptado y mantiene las medidas de seguridad necesarias para la protección de los Datos Personales que se proporcionan por el Usuario a fin de evitar su pérdida, mal uso, alteración, acceso no autorizado y robo. No obstante lo anterior, el Usuario debe ser consciente que las medidas de seguridad en Internet no son inexpugnables y por ende debe tomar en consideración que Solidaria no puede garantizar que sus Datos Personales estarán libres de toda pérdida, mal uso, alteración, acceso no autorizado y/o robo, así como de los daños causados por mal uso de la página Web o virus en la red.`,
					l6: 'Uso de Cookies',
					c6:
						'Las cookies son piezas de información que una página Web transfiere a una unidad de disco duro individual para propósitos de registro, en las cookies no se guardan contraseñas. Las cookies ayudan a Solidaria a saber qué páginas se visitan con más frecuencia y ayudan a Solidaria a asegurarse que su Sitio responda a las necesidades de los usuarios. Si el Usuario no desea recibir una cookie, o si desea que su navegador le notifique cuando reciba una cookie, el Usuario puede utilizar la opción en su navegador para deshabilitar las cookies. Haga clic en la sección de "Ayuda" de su navegador para aprender cómo cambiar las preferencias de las cookies. Sin embargo, el Usuario deberá tener en cuenta que si deshabilita todas las cookies, puede no ser capaz de aprovechar todas las funciones disponibles en esta Página Web. Solidaria no utiliza los datos de identificación personal que se transfieren a través de las cookies ya sea para promoción o con fines de mercadotecnia, ni dicha información se comparte con terceros.',
					l7: 'Contenido del Sitio',
					c7:
						'Todo el Contenido del Sitio se proporciona en forma "tal cual" y "según disponibilidad". Solidaria rechaza expresamente todas las garantías de cualquier tipo con respecto al Contenido del Sitio, ya sean expresas o implícitas, incluyendo pero no limitado a, las garantías implícitas de comerciabilidad y adecuación para un fin determinado y cualesquiera garantías de que los materiales en el Sitio no infringen derechos, así como las garantías implícitas de un curso de ejecución o de gestión. Solidaria se reserva el derecho de modificar los Contenidos en cualquier momento, pero no asume la obligación de actualizar ninguna información en el Sitio. Solidaria no hace ninguna garantía o representaciones respecto al uso de los materiales en este Sitio en términos de su integridad, exactitud, precisión, idoneidad, utilidad, líneas de tiempo, su fiabilidad u otra manera.',
					l8: 'Garantías',
					c8: `Solidaria no ofrece ninguna garantía de que: (a) el Sitio satisfaga los requerimientos del Usuario; (b) el Sitio estará disponible en forma no interrumpida, libre, oportuna, segura o sin errores o que el servidor que hace que el Sitio esté disponible, estará libre de virus; (c) los resultados que puedan obtenerse de la utilización del Sitio o de cualquiera de los servicios ofrecidos a través del Sitio serán exactos o confiables; o (d) la información contenida en el Sitio está actualizada y refleja la situación actual de la compañía. Si el Usuario no está satisfecho con cualquier parte del Sitio, o con cualquiera de éstos Términos de Uso, queda a decisión única y exclusiva del Usuario el descontinuar con el uso de este Sitio. El Usuario reconoce que la descarga de cualquier material de este Sitio, confiabilidad en la información en este Sitio y cualquier uso de este Sitio es únicamente a riesgo del Usuario, y que el Sitio está disponible para el Usuario sin costo alguno. Por lo tanto, el Usuario reconoce y acepta que, en la máxima medida aplicable permitida por la ley, ni Solidaria ni sus afiliadas o terceros proveedores de contenido serán responsables por daños directos, indirectos, punitivos, ejemplares, incidentales, especiales, consecuenciales o de otra índole derivados de o de alguna manera relacionados con este Sitio. Solidaria no tendrá responsabilidad por cualquier daño al sistema de cómputo o pérdida de información del Usuario resultado de la descarga de cualquier Contenido, materiales, o información del Sitio. En ninguna circunstancia Solidaria será responsable por daños de cualquier clase, incluyendo sin limitación daños, directos, incidentales o consecuenciales (incluyendo, pero no limitado a, daños por la pérdida de ganancias, interrupción de negocios y la pérdida de programas o información) que se deriven del uso o la imposibilidad de uso del Sitio, o cualquier información proporcionada en el mismo.`,
					l9: 'Prohibiciones',
					c9: `El Usuario no deberá utilizar cualquier hardware o software que tenga como intención dañar, interferir con la operación propia del Sitio o de interceptar cualquier dato o información personal del Sitio. El Usuario no deberá interrumpir, intentar interrumpir, invadir o intentar invadir en cualquier forma la operación del Sitio. Al Usuario no se le permite enmarcar este Sitio o utilizar las marcas de Solidaria como meta tags, sin el previo consentimiento por escrito de Solidaria. El Usuario no podrá utilizar marcos o utilizar técnicas de enmarcado para adjuntar cualquier Contenido. Adicionalmente, el Usuario no podrá utilizar cualquier Contenido en cualesquier meta tags o cualquier otra técnica similar, sin el previo consentimiento por escrito de Solidaria. El Usuario no podrá ocultar cualquier Contenido, incluyendo estos Términos de Uso o cualquier aviso de derechos de autor o de propiedad en el Sitio.`,
					l10: 'Indemnización',
					c10: `El Usuario acepta indemnizar, defender y sacar en paz y a salvo a Solidaria de y en contra de todas las pérdidas, gastos, daños y costos, incluyendo honorarios razonables de abogados, derivados de o relacionados con el uso indebido por parte del Usuario de los Contenidos y servicios proporcionados en el Sitio o cualquier otro incumplimiento por parte del Usuario de los Términos de Uso Contenidos en el presente.`,
					l11: 'Terminación del acceso',
					c11: `Solidaria puede dar por terminado el acceso al Usuario en cualquier momento y por cualquier motivo. Las disposiciones relativas a las renuncias de garantía, la exactitud de la información y las obligaciones indemnización sobrevivirán a dicha terminación. `,
					l12: 'Modificaciones a los Términos de Uso',
					c12: `Solidaria podrá revisar y cambiar alguno o todos los Términos de Uso en cualquier momento sin previo aviso al Usuario. Estos cambios surtirán efectos inmediatamente tras su publicación en el Sitio. Al continuar utilizando el Sitio después de que los cambios sean publicados, el Usuario reconoce la aceptación de dichos cambios. Cualquier conflicto entre los Términos de Uso y los términos de uso publicados en un área del Sitio, éstos últimos tendrán preferencia con respecto al uso de esa área del Sitio.`,
					l13: 'Uso legal del Sitio',
					c13: `El Usuario garantiza que el acceso a este Sitio y la información aquí contenida no es ilegal bajo las leyes aplicables de la jurisdicción donde el Usuario es residente o donde el Usuario tiene acceso al Sitio. Si cualquier Contenido o el uso de este Sitio es contrario a las leyes del lugar donde el Usuario está accediendo al Sitio, este Sitio no está destinado a dicho Usuario y el Usuario deberá abstenerse de acceder o usar este Sitio. `,
					l14: 'Cláusulas inválidas',
					c14: `Si cualquiera de los términos, limitaciones o exenciones de responsabilidad Contenidos en los Términos de Uso anteriores son considerados como ilegales, inaplicables o inválidos en su totalidad o en parte, por cualquier razón, en virtud de las leyes aplicables en la jurisdicción donde reside el Usuario o en donde ha tenido acceso al Sitio, dichas disposiciones ilegales, inaplicables o inválidas o parte de las mismas se deberán eliminar de los presentes Términos de Uso, y dicha disposición eliminada no afectará la legalidad, exigibilidad, o la validez del resto de estos Términos de Uso. Si cualquier disposición o parte de estos Términos de Uso son eliminados de conformidad con las disposiciones de esta sección, entonces dichas disposiciones eliminadas serán sustituidas, en la medida de lo posible, con una disposición legal, aplicable y válida que sea lo más similar posible a la disposición eliminada.  `,
					l15: 'Renuncia a estos Términos de Uso',
					c15: `Ninguna modificación, autorización o renuncia bajo éstos Términos de Uso será efectiva a menos que sea por escrito y esté firmada por un
            representante debidamente autorizado de Solidaria. `,
					l16: 'Leyes aplicables',
					c16:
						'Estos Términos de Uso se sujetarán a las leyes aplicables de México.  <br/>    <br/>Gracias por visitar <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>'
				}
			}
		},
		privacyTerms: {
			title: 'Aviso de Privacidad',
			text:
				'Fundación Solidaria, A.C.  con domicilio en Boulevard Constitución 3098 Piso 6, Colonia Santa María, Monterrey, Nuevo León C.P. 64650, (en adelante “Solidaria”) le informa que los datos personales que nos proporcione voluntariamente, incluidos datos personales sensibles, serán utilizados únicamente para contactarlo y dar seguimiento a su comunicación; en caso de que su perfil sea de interés para Solidaria sus datos personales serán utilizados para tomar decisiones de selección, hacer evaluaciones, conformar expedientes, entre otros.',
			text1:
				'Solidaria no venderá, cederá o transferirá sus datos personales a terceros ajenos a Softtek sin su consentimiento previo. Sin embargo, Solidaria podrá transferir sus datos personales cuando dicha transferencia esté prevista en la Ley. Solidaria ha adoptado las medidas de seguridad, administrativas, técnicas y físicas, necesarias para proteger sus datos personales contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado. Sin embargo, es claro que ninguna transmisión de datos por Internet es completamente segura y por ende Solidaria no puede garantizar que el tratamiento de sus datos personales estará libre de todo daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.',
			text2:
				'Usted, como titular de datos personales, podrá ejercitar ante la Persona Responsable de Datos Personales de Solidaria los derechos de acceso, rectificación, cancelación y oposición (derechos ARCO), establecidos en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la Ley). Asimismo, podrá revocar, en todo momento, el consentimiento que haya otorgado y que fuese necesario para llevar a cabo el tratamiento de sus datos personales, así como solicitar que se limite el uso o divulgación de los mismos.  Lo anterior, enviando su petición en los términos establecidos por la Ley al correo electrónico: <a href="mailto:privacy@softtek.com">privacy@softtek.com</a> o a Boulevard Constitución 3098 Piso 6, Colonia Santa María, Monterrey, Nuevo León C.P. 64650.',
			text3:
				'Solidaria se reserva el derecho de hacer cambios en el presente Aviso de Privacidad, mismos que le serán comunicados a través de la página web <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>.',
			strong:
				'Si no desea que Solidaria trate sus datos personales para cumplir con las finalidades antes descritas, le pedimos que se abstenga de proporcionarlos, ya que el proporcionarlos implica que está de acuerdo con los términos del presente.',
			text4:
				'Le informamos que, ante la negativa de respuesta a las solicitudes de derechos ARCO o inconformidad con la misma, usted puede presentar ante el Instituto Federal de Acceso a la Información y Protección de Datos, la correspondiente Solicitud de Protección de Derechos en los plazos y términos fijados por la Ley y su Reglamento.'
		}
	},
	'pt-BR': {
		nav: {
			home: 'INICIO',
			about: 'SOBRE NOSOTROS',
			value: 'NUESTROS VALORES',
			programs: 'NUESTROS PROGRAMAS',
			projects: 'NUESTROS PROYECTOS',
			gallerie: 'GALERIA DE PROYECTOS',
			help: '¿CÓMO AYUDAR?',
			dare: 'DARE TO SHARE',
			share: 'COMPARTE TU INICIATIVA',
			contact: 'CONTACTO',
			publications: 'PUBLICACIONES',
			news: 'NOTICIAS',
			convocatory: 'CONVOCATORIAS',
			historical: 'HISTÓRICO DE PROYECTOS'
		},
		about: {
			title: 'ACERCA DE NOSOTROS',
			missTit: 'Misión',
			missTxt: 'Contribuir al desarrollo sostenible de las comunidades donde tenemos presencia.',
			visTit: 'Visión',
			visTxt:
				'Consolidarnos como una fundación global, con un comité de voluntarios en cada una de las sedes donde tenemos presencia, utilizando la tecnología para generar valor social.'
		},
		callout: {
			touch: 'EN CONTACTO, ESTAMOS AQUÍ PARA AYUDAR',
			share: 'PORQUE COMPARTIR NO ES DAR LO QUE NOS SOBRA',
			share2: 'Porque compartir no es dar lo que nos sobra'
		},
		counter: {
			labelVis: 'VISITAS',
			labelCount: 'USUARIOS',
			labelProy: 'PROYECTOS',
			labelDona: 'DOLARES',
			result: 'RESULTADOS EN CIFRAS',
			impact: 'Nuestro Impacto'
		},
		header: {
			firstTit: 'Primero',
			firstTxt: 'Descripción de la primera imagen',
			seconTit: 'Segundo',
			seconTxt: 'Descripción de la segunda imagen',
			thirTit: 'Tercero',
			thirTxt: 'Descripción de la tercera imagen'
		},
		video: {
			title: 'Videos',
			titleSecond: '¡Proyecto!',
			cerrar: 'Cerrar',
			txt: 'Proyecto más importante del mes',
			thanks: 'Gracias por participar',
			public: 'Publicar vídeo',
			header: 'Video'
		},
		volunteer: {
			title: 'Formas de voluntariado con las que trabajamos',
			microTit: 'Micro-voluntariado',
			microTit2: 'Ayuda a una organización realizando tareas de manera remota.',
			microTxt:
				'Un voluntario o un equipo de voluntarios realizan actividades en línea desde cualquier lugar para beneficiar una organización sin fines de lucro. También es llamado "voluntariado virtual" y usualmente toma pocos minutos o un par de horas para completarlo.',
			tradTit: 'Tradicional',
			tradTit2: 'Organiza acción voluntaria en equipo para apoyar a la comunidad.',
			tradTxt:
				'Desde plantar un árbol hasta construir un albergue, el objetivo de este voluntariado es conectar personas con organizaciones que necesiten mano de obra para realizar actividades donde se necesiten las manos o bien, esfuerzo físico.'
		},
		make: {
			txt:
				'Es un programa que en el transcurso del año lanza convocatorias para apoyar diferentes causas, proyectos e instituciones. Su objetivo es ofrecer alternativas fáciles y prácticas a los colaboradores para que contribuyan en alguna de las 3 modalidades:',
			time: 'Tiempo',
			money: 'Dinero',
			items: 'Artículos'
		},
		dare: {
			title: 'Alternativas para contribuír',
			donaETit: 'Donativos económicos',
			donaETxt:
				'Se refiere a los donativos económicos que se reciben en la cuenta de la Fundación y se canalizan hacia el proyecto o institución que se decide apoyar. Existen varios mecanismos para realizar los donativos:',
			donaPE1:
				'Realizando una donación desde esta plataforma (ya sea para un proyecto o para la fundación en General)',
			donaPE2:
				'Mediante el programa "softtekian solidario" donde un colaborador realiza una aportación mensual a la cuenta de la fundación.',
			donaPE3: 'Realizando un donativo en persona destinado a una actividad específica.',
			donaSTit: 'Donativos en especie ',
			donaSTxt:
				'Son aquellas contribuciones en especie que nuestros colaboradores proporcionan a la Fundación, pueden ser artículos o servicios, y que son destinados a los diversos proyectos o instituciones para los que fueron recolectados. Por ejemplo: artículos para un bazar en beneficio.',
			donaTTit: 'Donativos de tiempo',
			donaTTxt:
				'Cuando participas en alguna forma de voluntariado (se explican más adelante) y das parte de tu tiempo para apoyar un proyecto o actividad que organiza alguno de los comités de la Fundación.',
			voluntTit: 'Voluntariado',
			voluntTxt:
				'El voluntariado es una parte fundamental para Fundación Solidaria. Todas las actividades que se realizan son gracias al apoyo de nuestros colaboradores, sus familiares y amigos; que voluntaria y libremente dedican su tiempo, talento y habilidades a las diferentes causas y proyectos. ',
			typesVolunt: 'Existen diferentes tipos de voluntariado',
			actTit: 'Voluntario activo',
			actTxt:
				'En esta modalidad apoyas con tu tiempo asistiendo y apoyando las actividades que realizan los comités de Fundación Solidaria. Te permite involucrarte con las personas que son beneficiadas y experimentar la alegría de ayudar de primera mano.',
			remoteTit: 'Voluntario remoto',
			remoteTxt:
				'Si no puedes participar activamente, puedes apoyarnos con la organización y la logística requeridas para realizar las actividades.',
			comiteTit: 'Miembro de comité',
			comiteTxt:
				'En cada sede es necesario tener un equipo encargado de definir las actividades a realizar; que se involucre en la planeación, organización y desarrollo de las actividades del comité. Cabe aclarar que no requieres asistir a todas ellas.',
			friendTit: 'Softtekian Solidario',
			friendTxt:
				'Este es un programa que opera en todos los comités de las oficinas Softtek de México y Brasil. Lo constituyen aquellos colaboradores que voluntariamente acceden a donar una cantidad que se descuenta de su salario mensual para apoyar las actividades de su comité y los programas de Fundación Solidaria. Fundación Solidaria proporciona recibos deducibles de impuestos cada año por estas donaciones. <a href="https://forms.office.com/r/J2z5EwzvP0"> Registrate aquí </a>',
			button: 'ESTOY INTERESADO',
			know: 'CONOCE MÁS DE DTS'
		},
		friend: {
			title: 'Softtekian Solidario',
			txt:
				'Este es un programa que opera en todos los comités de las oficinas Softtek de México y Brasil. Lo constituyen aquellos colaboradores que voluntariamente acceden a donar una cantidad que se descuenta de su salario mensual para apoyar las actividades de su comité y los programas de Fundación Solidaria. Fundación Solidaria proporciona recibos deducibles de impuestos cada año por estas donaciones.'
		},
		initiative: {
			title: 'Comparte tu iniciativa',
			button: 'TENGO UNA IDEA',
			txt:
				'Todas las actividades realizadas a nombre de Fundación Solidaria son definidas por los comités de las diferentes sedes, pero si tú tienes conocimiento de una organización a la que podamos apoyar, o quieres proponer una actividad que beneficie a tu comunidad, queremos escucharte.<br/ >Compártenos los detalles de tu propuesta para que el comité de tu localidad pueda analizarla, y si es factible, integrarla en su plan de actividades.<br /><br />¡Porque Fundación Solidaria somos todos!'
		},
		programs: {
			know: 'CONOCE MÁS',
			title: 'Nuestros Programas',
			txt: 'Programas diseñados para contribuir al desarrollo sostenible de las comunidades donde operamos.',
			health: 'SALUD',
			healthTxt:
				'Para la Fundación Solidaria es fundamental trabajar en la prevención de enfermedades, así como apoyar a instituciones o asociaciones que ayudan a personas enfermas. Estas acciones están dirigidas a brindar algunos servicios y provisión de materiales a través del voluntariado y los prestadores de servicio social.',
			dress: 'VIVIENDA, ALIMENTACIÓN, Y VESTIDO',
			dressTxt:
				'La Fundación, de acuerdo a sus capacidades, brinda donativos en especie para apoyar a instituciones que requieren de la ampliación, construcción o remodelación de sus instalaciones, así como con la donación de ropa nueva o usada, en buen estado, y despensas con productos de la canasta básica:',
			dressT0:
				'Recaudación de ropa nueva o usada en buen estado y calzado para canalizarlos a instituciones de asistencia social que la requieran.',
			dressT1: 'Entrega de despensas con productos de la canasta básica, en zonas marginadas.',
			dressT2: 'Entrega de materiales de construcción y remodelación.',
			dressT3: 'Entrega de aparatos electrónicos.',
			nature: 'NATURALEZA',
			natureTxt:
				'Fundación Solidaria impulsa y promueve actividades que permiten mantener este equilibrio de manera responsable y organizada. En este ámbito también se incluye la asistencia a animales y el apoyo en los desastres causados por fenómenos naturales. Nuestras acciones están dirigidas a brindar solamente servicios en las siguientes áreas:',
			nT1:
				'Protección a animales: apoyar a instituciones de asistencia social que brinden albergue, comida o tratamiento médico a animales que lo requieran.',
			nT2:
				'Desastres Naturales: coordinación con instituciones para la recolección y entrega de despensas y materiales requeridos en apoyo a la población que sea afectada por los fenómenos naturales.',
			nT3: 'Labores de reforestación y limpieza en áreas verdes.',
			natureTxt2:
				'La Fundación apoya a promover y difundir las campañas de otras ONGs o grupos que buscan impulsar y preservar el equilibrio ecológico. Por ejemplo:',
			nT4: 'Campañas de adopción y apoyo a animales sin hogar.',
			nT5: 'Campañas de sensibilización para evitar la crueldad hacia los animales.',
			nT6: 'Campañas de donación de víveres en beneficio de personas damnificadas.',
			nT7: 'Capacitar a instituciones sobre la importancia y beneficios del reciclaje.',
			solidarity: 'CONVIVENCIA Y SOLIDARIDAD',
			solTxt:
				'Fundación Solidaria busca fomentar y enriquecer las relaciones sociales a través de la sana convivencia social a favor de los grupos más desprotegidos o vulnerables, y se solidariza con ellos para apoyarlos en mejorar su calidad de vida. <br /> Nuestras acciones están dirigidas a brindar eventos, servicios y provisión de materiales, ya sea directamente con los grupos desprotegidos o a través de instituciones afines: ONG’s, casas hogar, orfanatos, reclusorios, centros de rehabilitación, refugios, albergues, escuelas, comunidades rurales.',
			sT1:
				'Alianzas: la Fundación apoya a promover y difundir las campañas de otras ONGs o grupos que busquen la integración y convivencia social.',
			tit2: 'Provisión de materiales',
			sT2: 'Material de construcción e infraestructura.',
			sT3: 'Alimentación y vestimenta.',
			edu: 'EDUCACIÓN',
			eduTxt:
				'Para la fundación el ámbito educativo es un componente central en el desarrollo de las comunidades donde operamos, por esta razón, generamos proyectos de alto impacto que benefician no solo a quienes participan activamente en ellos, sino que también son el inicio de una cadena de valor que multiplica el impacto y alcance de los mismos.',
			class: 'Aula Tecnológica de Acción Social',
			classTxt:
				'Desde entonces a la fecha, este programa ha beneficiado a 250 estudiantes en niveles de educación básica, media y media superior, hemos brindado un total de 1,310 horas clase, las cuales han sido impartidas por 25 colaboradores de Softtek y 4 estudiantes de universidad que realizaron su servicio social dentro de este programa. El objetivo del Aula Tecnológica es fortalecer los conocimientos de los estudiantes mediantes cursos de computación y programación básica, idiomas extranjeros dándoles herramientas que contribuyen a su desarrollo personal y profesional. En “AULATEC” se viven los cánones de acción que Softtek promueve, los alumnos se comprometen, se auto determinan, aprenden que con entusiasmo, perseverancia y esfuerzo se generan grandes cambios.',
			alliance: 'Alianza educativa ciudadana por Nuevo León',
			allianceTxt:
				'Alianza Educativa es un programa que apoya a las nuevas generaciones de las escuelas cercanas a Softtek, de preescolar, primaria y secundaria, a detectar necesidades e implementar mejoras con los padres de familia, alumnos, maestros y los voluntarios de Softtek a través de Fundación Solidaria. De esta manera podemos atender las diferentes necesidades según las prioridades de la escuela, mejorando así la calidad educativa y de vida para los estudiantes, dándole poder a la misma comunidad.<br /> 2013 fue el primer año en que participamos en el programa de "Circulo Virtuoso" de Alianza Educativa, en el cual se han invertido más de $500,000.00 pesos en infraestructura y en diferentes actividades sociales que han impactado positivamente a las escuelas y su comunidad. Trabajamos también con los consejos escolares de participación social, conformados por padres de familia, a los que involucramos en el desarrollo y mejora de la escuela y sus programas, ayudándoles a tomar decisiones sobre el futuro de sus hijos.'
		},
		values: {
			pillar: 'NUESTRO PILAR',
			title: 'Valores',
			transparency: 'TRANSPARENCIA',
			transTxt:
				'Claridad y rendición de cuentas no sujetas a ninguna condición, pues los recursos económicos que hacen posible nuestra labor provienen de nuestra gente, donaciones y de la organización.',
			commitment: 'COMPROMISO',
			commTxt:
				'Realizar las tareas necesarias para convertir en realidad las acciones y beneficios que promovemos en cada uno de nuestros programas.',
			synergy: 'SINERGIA',
			synTxt:
				'Unir nuestros esfuerzos a nivel local, regional y global con el fin de multiplicar nuestras fortalezas y generar un mayor impacto en nuestro entorno.',
			trust: 'CONFIANZA',
			trustTxt:
				'Tenemos la seguridad de que cada uno de nuestros comités opera íntegramente de acuerdo a nuestra visión.',
			focus: 'Nuestro foco',
			focusTxt:
				'Fundación Solidaria enfoca sus esfuerzos en acciones que contribuyan al desarrollo sostenible de las comunidades donde opera Softtek. Por esta razón, recientemente su Presidenta honoraria, Blanca Treviño, definió el ámbito educativo como su prioridad para la generación de iniciativas que impulsen y fomenten el progreso de las localidades donde tenemos presen',
			belief: 'Nuestras creencias',
			beliefTxt:
				'Compartir es el inicio del ciclo de la abundancia y crecimiento. Las personas son capaces de auto sustentarse cuando son respetadas como seres humanos y cuentan con oportunidades para crecer. La suma de esfuerzos genera grandes cambios.',
			reason: '¿Qué nos motiva?'
		},
		weshare: {
			title: 'Nuestros proyectos',
			txt:
				'Fundación Solidaria está formada por comités en diferentes geografías que evalúan propuestas de proyectos locales y<br /> solicitudes de apoyo relacionadas con la educación, especificamente aquellas que involucran el uso de tecnología<br />  Adicionalmente también consideran proyectos y requerimientos relacionados con algunas de las siguientes causas:',
			causes1: 'Desastres naturales, como terremotos, inundaciones e incendios.',
			causes2: 'Organizaciones e instituciones de salud que realizan actividades anuales para recaudar fondos.',
			causes3: 'Proyectos relacionados con la preservación y mejora del medio ambiente.',
			causes4: 'Apoyo a gente de la tercera edad, niños y pacientes de instituciones de salud.',
			exc:
				'*Cualquier otro caso que no califique en los tópicos descritos en esta sección deberá ser evaluada por el Comité Ejecutivo de Fundación Solidaria.',
			tit1: 'Desastres Naturales',
			titlePoint: 'Durante la selección de los proyectos, se tomarán a consideración los siguientes puntos:',
			titP1: 'Sostenibilidad',
			point1: 'Proyectos que trasciendan en el tiempo, por ejemplo, que son replicables o permanentes.',
			titP2: 'Impacto',
			point2: 'Proyectos cuyo efecto positivo puede ser cuantificable y verificable.',
			titP3: 'Factibilidad',
			point3:
				'Proyectos que se adhieren a la misión, visión y programas de nuestra Fundación; y que pueden ser realizados con nuetros recursos económicos a través de nuestros voluntarios.',
			committee:
				'El comité de la fundación responderá vía correo electrónico a todas las solicitudes notificando si procede o no la adjudicación de apoyo. ',
			some: 'Algunas consideraciones'
		},
		btn: {
			add: 'Agregar',
			save: 'Guardar',
			cancel: 'Cancelar',
			update: 'Actualizar',
			send: 'Enviar',
			login: 'INGRESAR',
			logout: 'Salir',
			dashboard: 'Administración',
			sendUs: 'Envíanos un mensaje',
			subscribe: 'Inscribirme al programa',
			callme: 'Contáctame',
			share: '¡Comparte!',
			join: 'Unirse',
			edit: 'Editar',
			close: 'Cierre',
			infoClose: 'Información de cierre',
			see: 'Ver',
			register: 'Registrarse',
			donation: 'DONAR',
			approve: 'Aprobar',
			delete: 'Eliminar',
			detail: 'Detalle',
			unsubscribe: 'Darse de baja',
			contact: 'Contactar'
		},
		help: {
			required: 'Este campo es requerido',
			obliged: 'Campo obligatorio',
			right: 'Campo correcto',
			number: 'El campo no es un número',
			email: 'El campo no es email',
			photo: 'Seleccione una fotografía',
			date: 'La fecha debe ser entre',
			day: 'dias',
			year: 'años',
			min3: 'El mínimo de caracteres permitido es 3',
			min4: 'El mínimo de caracteres permitido es 4',
			min5: 'El mínimo de caracteres permitido es 5',
			min6: 'El mínimo de caracteres permitido es 6',
			min7: 'El mínimo de caracteres permitido es 7',
			min15: 'El mínimo de caracteres permitido es 15',
			min580: 'El mínimo de caracteres permitido es 580',
			max7: 'El mínimo de caracteres permitido es 7',
			max20: 'El el maximo de caracteres permitido es 2000.',
			max25: 'El el máximo de caracteres permitido es 25',
			max35: 'El el máximo de caracteres permitido es 35',
			max50: 'El el máximo de caracteres permitido es 50',
			max100: 'El el máximo de caracteres permitido es 100',
			max140: 'El el máximo de caracteres permitido es 140',
			max280: 'El el máximo de caracteres permitido es 280',
			max500: 'El el máximo de caracteres permitido es 500',
			beforeToday: 'No se puede seleccionar una fecha anterior del día de hoy.',
			beforeBegin: 'No se puede seleccionar una fecha antes de la fecha de inicio seleccionada.',
			rank: 'No esta en el rango de tiempo de vida del proyecto',
			reqStartDate: 'Es requerida la fecha inicial',
			reqEndDate: 'Es requerida la fecha final',
			beforeStart: 'No se puede seleccionar una fecha anterior a la inicial.',
			equalStart: 'No se puede seleccionar una fecha igual o anterior a la inicial.',
			equalToday: 'No se puede seleccionar una fecha igual o anterior al día de hoy.',
			cannotEditStart: 'You can not edit the start date, the project is already published',
			withoutProject: 'Sin proyecto'
		},
		label: {
			activitie: 'Actividades',
			categorie: 'Categorías',
			cause: 'Causas',
			contact: 'Contactos',
			donation: 'Donaciones',
			friend: 'Amigos Solidarios',
			initiative: 'Iniciativas',
			institution: 'Instituciones',
			role: 'Roles',
			sede: 'Sedes',
			skill: 'Habilidades',
			subsede: 'Subsedes',
			name: 'Nombre',
			fullName: 'Nombre completo',
			lastName: 'Apellidos',
			description: 'Descripción',
			phone: 'Teléfono',
			email: 'Correo electrónico',
			msg: 'Mensaje',
			amount: 'Cantidad',
			kind: 'Tipo',
			manual: 'manual',
			transfer: 'Transferencia',
			project: 'Seleccione el proyecto',
			country: 'País',
			state: 'Estado',
			city: 'Ciudad',
			zip: 'Código Postal',
			address: 'Domicilio',
			is: 'IS',
			id: 'ID',
			web: 'Página web',
			languageCode: 'Código de idioma',
			countryCode: 'Código de país',
			paypal_account: 'Cuenta de PayPal',
			paypal_clientId: 'ID de PayPal',
      paypal_secret: 'Llave secreta de Paypal',
      conekta_account: 'Cuenta de Conekta',
			conekta_clientId: 'ID de Conekta',
			conekta_secret: 'Llave secreta de Conekta',
			bank_name: 'Banco',
			bank_account: 'Cuenta bancaria',
			termsofPrivacy: 'Terminos de privacidad',
			publishDate: 'Publicación',
			startDate: 'Inicio',
			endDate: 'Terminación',
			author: 'Autor',
			content: 'Contenido',
			title: 'Título',
			continent: 'Continente',
			currency: 'Divisa',
			simbolCurrency: 'Símbolo de divisa',
			changeDolar: 'Divisa cambio a dolar',
			zone: 'Zona horaria',
			enable: 'Habilitar donaciones monetarias',
			startDonation: 'Inicio de donaciones',
			endDonation: 'Fin de donaciones',
			receipt: 'Recibo',
			legend: 'Leyenda',
			showCounter: 'Mostrar contador',
			showCountDown: 'Mostrar cuenta regresiva',
			itemsDonated: 'Donaciones en especie',
			itemsEnable: 'Habilitar donaciones en especie',
			shareBar: 'Compartir',
      shareTree: 'Ubica tu árbol en el Amazonas aquí',
      shareTreeLink: 'Tu árbol - Saving the Amazon',
			info: 'Información',
			volunteer: 'Voluntariado',
			subscription: 'Habilitar registro de voluntarios',
			subscription_required: 'Voluntarios requeridos',
			showCounterVolunteer: 'Mostrar voluntarios',
			goal: 'Objetivo',
			type: 'Tipo',
			cover: 'Portada',
			moneyDonated: 'Donaciones monetarias',
			start: 'Inicio',
			event: 'Evento',
			list: 'Lista',
			listProject: 'Lista de proyectos',
			specialEvent: 'Evento Especial',
			probono: 'Pro-bono',
			campaign: 'Campaña',
			report: 'Reporte',
			reportProject: 'Reporte de proyectos',
			reportDonations: 'Reporte de donaciones',
			export: 'Exportar registros en CSV',
			inkindDonations_startDate: 'Inicio de recepción',
			inkindDonations_endDate: 'Fin de recepción',
			inkindDonations_text: '¿Qué necesita tu proyecto? (Separar con comas)',
			volunteering_startDate: 'Inicio de registro',
			volunteering_endDate: 'Fin de registro',
			volunteering_volunteersRequired: 'Número de voluntarios requeridos',
			ubicationActivitie: 'Ubicación de la actividad',
			contact_email: 'Correo de contacto',
			contact_address: 'Dirección de la actividad',
			thumbnail: 'Miniatura',
			dateRegistry: 'Registro',
			continue: 'Seguimiento',
			listSuscribe: 'Lista de suscritos',
			convocatory: 'Convocatoria',
			status: 'Estado',
			new: 'Nuevo',
			published: 'Publicado',
			finalized: 'Finalizado',
			completed: 'Completado',
			allTypes: 'Todos los tipos de proyectos',
			publicProject: 'Publicar proyecto',
			monthlyAmount: 'Cantidad a donar mensualmente',
			volunteeringRemaining: 'Voluntarios faltantes',
			completeDate: 'Cierre',
			allSub: 'Todas las subsedes',
			finalizeProject: 'Finalizar proyecto',
			finalize: 'Finalizar',
			search: 'Búsqueda',
			actionsCarriedOut: 'Acciones llevadas a cabo',
			volunteerColla: 'Colaboradores voluntarios',
			externalVolunters: 'Voluntarios externos',
			volunterHours: 'Horas de voluntariado',
			investment: 'Inversión',
			benefit: 'Beneficiados',
			comments: 'Comentarios (opcional)',
			numVolunteers: 'Número de voluntarios',
			numColla: 'Número de colaboradores voluntarios',
			totalHoursVolunteer: 'Total de horas de voluntariado invertidas',
			numBenefit: 'Número de beneficiados',
			summaryProject: 'Resumen del proyecto',
			searchBar: 'Barra de búsqueda',
			upload: 'Subir',
			gallerieImages: 'Galería de Imagenes',
			uploadImage: 'Subir imagen',
			viewGallerie: 'Administrar galeria',
			donationMin0: 'Las donaciones manuales deben ser mayores a 0',
			donationMax120: 'Las donaciones manuales deben ser menores a 120 USD',
			assignTo: 'Proyecto asignado',
			userSF: 'Eres softtekian solidario',
			leftSF: 'Dejar de ser softtekian solidario',
			joinSF: 'Volver a ser softtekian solidario',
			noticeOfPrivacy: 'Aviso de Privacidad',
			hello: 'Hola',
			language: 'Idioma',
			confirmOfRead: 'Confirmo haber leído el ',
			consult: 'Consulte los',
			site: 'del sitio',
			loginEmail: 'Su correo sin la terminación @softtek.com',
			loginPassword: 'Contraseña de tu correo Softtek',
      evidence: 'Evidencia',
      currencyChange: 'La divisa y su tasa de cambio serán actualizadas'
		},
		modal: {
			activitie: 'Actividad',
			categorie: 'Categoría',
			cause: 'Causa',
			contact: 'Contacto',
			donation: 'Donación',
			friend: 'Softtekian solidario',
			initiative: 'Iniciativa',
			institution: 'Institución',
			role: 'Rol',
			sede: 'Sede',
			skill: 'Habilidad',
			subsede: 'Subsede',
			solidaryFriend: 'Softtekian Solidario',
			toBe: '¡Quiero ser softtekian solidario!',
			question: '¿Quieres ser softtekian solidario?',
			project: 'Proyecto',
			donor: 'Donador',
			usd: 'USD',
			typeDonation: 'Tipo de donación',
			detailDonation: 'Detalle de donación',
			originalAmount: 'Monto original',
			donationNet: 'Donación neta',
			donationGross: 'Donación bruta',
			fee: 'Comisión',
			donorCreator: 'Registrada por'
		},
		table: {
			registry: 'Registros',
			active: 'Activo',
			inactive: 'No activo',
			create: 'Creado',
			update: 'Actualizado',
			cause: 'Motivo',
			amount: 'Monto',
			approved: 'Aprobado'
		},
		alert: {
			causeTitle: 'Justificación del cambio',
			causeChange: 'Motivo del cambio',
			causeErrorText: 'Información erronea',
			upText: 'Actualización',
			desTitle: 'Justificación',
			confiTitle: '¿Estás seguro?',
			confiText: 'Este registro será ',
			error401Title: 'No autorizado',
			error401Text: 'No cuenta con los permisos requeridos',
			errorGeneralTitle: 'Error en el servidor',
			errorGeneralText: 'Por favor, intenta más tarde',
			successfully: 'exitosamente',
			registry: 'Registro',
			success: 'Éxito',
			activitieAdd: 'Actividad agregada',
			categorieAdd: 'Categoría agregada',
			causeAdd: 'Causa agregada',
			adminAdd: 'Usuario agregado',
			institutionAdd: 'Institución agregada',
			donationAdd: 'Donación registrada',
			donationReverse: 'Una vez aprobada no podrá revertirse',
			donationApprove: '¿Cómo comprobó la donación?',
			contactTh: '¡Gracias por el comentario!',
			initiTh: '¡Gracias por tu iniciativa!',
			contactText: 'Muy pronto nos pondremos en contacto',
			contactDelete: '¿Ya se contactó a esta persona? Brinda una descripción al respecto',
			changes: 'Cambios guardados',
			changeRegistry: 'Este registro será ',
			friendToBe: 'Este softtekian solidario será',
			initiativeToBe: 'La iniciativa será',
			initiative: 'Iniciativa',
			friend: 'Softtekian solidario',
			changesRegistry: 'Cambios registrados en el sistema',
			contactCuase: 'La persona fue contactada con exito',
			projectCreate: 'Proyecto creado',
			newCreate: 'Noticia creada',
			convocaCreate: 'Convocatoria creada',
			sedeAdd: 'Sede agregada',
			subsedeAdd: 'Subsede agregada',
			off: 'desactivado',
			on: 'activado',
			offCap: 'Desactivado',
			onCap: 'Activado',
			causeOff: 'Motivo de inactivación',
			causeOn: 'Motivo de activación',
			approveRegistry: 'Registro aprobado',
			friendTh: '¡Gracias por tu cooperación!',
			convocatoryTh: '¡Gracias por tu registro!',
			donationTh: '¡Gracias por tu Donación!',
			alertClose: 'Una vez cerrado no podrá editarse de nuevo',
			projectComplete: 'Proyecto completado',
			profileUpdate: 'Tu perfil ha sido actualizado',
			profileValidation: 'Es necesario llenar los campos de tu perfil',
			alreadyExist: 'Ya está registrado',
			conectionOff: 'Sin conexión',
			error: 'Error',
			changesOff: 'No se guardaron los cambios',
			contactCreate: 'Contacto registrado',
			delete: 'Eliminado',
			suscribeDelete: 'Suscriptor eliminado exitosamente',
			contactConvocatory: 'Contactado',
			registryDelete: 'Este registro será eliminado',
			suscriber: '¿Ya se contacto a ',
			request: 'Solicitud enviada',
			skillAdd: 'Habilidad agregada',
			confirmation: 'Agrega información complementaria de la solicitud',
			new: 'nuevo',
			published: 'Publicado',
			finalized: 'Finalizado',
			completed: 'Completado',
			password: 'Contraseña incorrecta',
			passwordInvalid: 'Contraseña invalida',
			user: 'Usuario incorrecto',
			userInvalid: 'Usuario invalido',
			activateTo: 'Activar a',
			deactivateTo: 'Desactivar a',
			registerActivate: 'Registro activado',
			registerDeactivate: 'Registro desactivado',
			writeCause: 'Debe escribir una justificación del cambio',
			alreadyFriend: 'Ya eres softtekian solidario',
			videoUploaded: 'El vídeo se subio correctamente',
			videoConfirmation: '¿Estás seguro que deseas publicar el vídeo?',
			wasFriend: 'Para solicitar ser reactivado como softtekian solidario ingresa desde tu perfil',
			friendThanks: 'Muy pronto nos pondremos en contacto',
			credentials: 'Usuario o contraseña inválidos',
			token: 'Sesión expirada',
			existSofttekian: 'Usuario Softtekian',
			instructionsSofttekian: 'Para cambio de Rol contacta a Soporte Técnico',
			file: 'Tipo de archivo no permitido',
			unsubscribed: 'Baja registrada',
			imageDeleted: 'Imagen eliminada',
			imageAdded: 'Imagenes agregadas',
			serviceUnavailable: 'Servidor en mantenimiento',
			noAdblocker: 'Asegúrese de no tener activada una extensión Ad-blocker',
			// Empty profile
			profileEmptyTitle: 'Te invitamos a registrar tu perfil',
			profileEmptyText:
				'Es importante para que puedas tener la opción de contribuir a los proyectos de Fundación Solidaria'
		},
		general: {
			contacted: 'Contactado',
			user: 'Usuario',
			date: 'Fecha',
			actions: 'Acciones',
			rol: 'Tipo de Rol',
			password: 'Contraseña',
			login: 'Entrar',
			logout: 'Salir',
			profile: 'Perfil',
			dashboard: 'Administrar',
			joinus: 'Para unirte debes iniciar sesión',
			volunteer: 'Voluntarios',
			confirmation: 'Sí',
			negation: 'No',
			pendingField: 'Favor de asignar',
			pending: 'Pendiente',
			loading: 'Cargando...',
			found: 'NO SE ENCONTRARON RESULTADOS',
			actually: 'Actualmente, no hay proyectos disponibles'
		},
		dashboard: {
			administration: 'ADMINISTRACIÓN',
			projects: 'Proyectos',
			news: 'Noticias',
			conv: 'Convocatorias',
			admin: 'Administradores',
			create: 'Crear',
			list: 'Lista',
			gallerie: 'Galería',
			catalog: 'Catálogos',
			admon: 'Administrar',
      paypal: 'PayPal',
			welcome: 'Bienvenido',
			inf: 'Tu información',
			visit: 'Información desde donde nos visitas',
			activities: 'Últimas actividades',
			interaction: 'Interacción',
			configuration: 'Configuración',
			users: 'Usuarios',
			gallProjects: 'Galería de proyectos',
			reports: 'Reportes',
			multimedia: 'Multimedia'
		},
		news: {
			list: 'Lista de noticias',
			gall: 'Galería de noticias',
			public: 'Publicar noticia',
			edit: 'Editar noticia',
			new: 'Nueva noticia'
		},
		convocatory: {
			list: 'Lista de convocatorias',
			gall: 'Galería de convocatorias',
			public: 'Publicar convocatoria',
			edit: 'Editar convocatoria',
			subscribed: 'Suscritos'
		},
		report: {
			cardStatus: 'Estado',
			cardPaypal: 'Donaciones Paypal',
			cardOthers: 'Otras donaciones',
			cardVolunteers: 'Voluntarios',
			chartDonations: 'Gráfica de donaciones',
			chartTypes: 'Tipos de donaciones',
			tableDonations: 'Tabla de donaciones',
			summaryLabel: 'Resumen del proyecto',
			summaryTitle: 'Título del proyecto',
			summaryObjective: 'Objetivo',
			summaryDescription: 'Descripción',
			closureLabel: 'Información de cierre',
			closureDonations: 'Donaciones',
			closureMonetary: 'Donaciones monetarias',
			closureInversion: 'Inversión',
			closureVolunteering: 'Voluntariado',
			closureVolunteers: 'Número de voluntarios',
			closureCollaborators: 'Número de colaboradores voluntarios',
			closureHoursVolunteering: 'Total de horas de voluntariado invertidas',
			closureBeneficiaries: 'Beneficiados',
			closureComments: 'Comentarios'
		},
		dash: {
			lineChart: 'Línea del tiempo de donaciones (USD)',
			pieCHart: 'Total de donaciones por tipo (USD)',
			cardUsers: 'Usuarios en linea',
			cardAdmins: 'Administradores en linea',
			cardSofttekians: 'Softtekians en linea',
			cardVisits: 'Visitantes en linea'
		},
		paypal: {
			head:
				'Para la solicitud del recibo fiscal de tu donación favor de ponerte en contacto con Fundación Solidaria a través del correo.',
			bank: 'Banco',
			account: 'Cuenta',
			fullAccount: 'Clabe',
			sub: 'Si quieres realizar una donación mediante transferencia, los datos de la cuenta son los siguientes:',
			footer: 'Una vez realizada la transferencia, favor de enviar tu comprobante a Fundación Solidaria al correo'
		},
		donationLabel: {
			projectD: 'Para desasignar proyecto seleccione el campo vacío'
		},
		carrousel: {
			new: 'Proyecto agregado al carrusel',
			active: 'Proyecto visible en el carrusel',
			unactive: 'Proyecto eliminado del carrusel',
			duplicated: 'No pueden haber dos proyectos publicados de la misma subsede en el carrusel',
			title: 'Carrusel',
			instruction: 'Selecciona el proyecto',
			publish: 'Publicar proyecto en carrusel',
			list: 'Lista de registros de carrusel'
		},
		softtekian: {
			info: 'Sin información',
			addInfo: 'Agregué',
			addInfo2: 'a su perfil, por favor',
			causes: 'Causas que apoyas',
			typeAct: 'Tipo de actividades en las que te gustaría participar',
			skills: 'Habilidades que posees',
			photo: 'Fotografía de perfil',
			updateProfile: 'Actualizar mi perfil'
		},
		noticePrivacy: {
			introduction: {
				title: 'Termos e Condições ',
				content:
					'Fundación Solidaria, A.C. (de agora em diante “Solidária”) A Solidária oferece ao Usuário todas as informações, ferramentas e serviços dentro do Site  <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>, sujeito à aceitação pelo Usuário dos seguintes Termos de Uso, bem como as Políticas e Avisos de Privacidade que se aplica. Por favor, leia-os com atenção. O uso do Site pelo Usuário constitui aceitação deste a estes Termos de Uso e outros documentos de Privacidade.'
			},
			definitions: {
				title: 'Definições.',
				content: {
					label1: 'Conteúdo',
					text1:
						'refere-se a todo o conteúdo do Site, incluindo, sem limitação, texto, design, gráficos, logotipos, ícones, imagens, clipes de áudio, videoclipes, materiais baixados, interfaces, ferramentas, aplicativos, código, Software e marcas.',
					label2: 'Dados Pessoais',
					text2: 'qualquer informação relativa a uma pessoa física identificada ou identificável.',
					label3: 'Website e página Web',
					text3:
						'refere-se a <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>, de propriedade e operados pela Solidária.',
					label4: 'Solidária',
					text4:
						'refere-se a Valores Corporativos Softtek, S.A. de C.V. com endereço em Boulevard Constitución 3098 Piso 6, Colonia Santa María, Monterrey, Nuevo León C.P. 64650. Usuário: refere-se à pessoa que está navegando no site ou usando-o de alguma forma.',
					label5: 'Termos de Uso',
					text5: 'refere-se aos seguintes termos e condições relacionados ao uso do Site.'
				}
			},
			termsOfUse: {
				title: 'Termos de Uso.',
				content: {
					l1: 'Informação no Site',
					c1:
						'As informações contidas no Site devem ser usadas apenas para fins informativos gerais e não devem ser usadas ou mencionadas como a única base para a tomada de decisões ou como uma representação legal ou garantia da Solidária. A Solidária não é responsável se as informações disponíveis neste Site não forem precisas, completas ou atualizadas. As informações contidas no Site destinam-se a fornecer informações gerais sobre um determinado tópico ou tópicos e não exaustivamente sobre o (s) referido (s) tema (s).',
					l2: 'Propriedade Intelectual',
					c2:
						'Todo o Conteúdo é de propriedade exclusiva da Solidária, suas afiliadas, seus licenciadores ou seus provedores de Conteúdo e está protegido por leis de propriedade intelectual e outras leis aplicáveis. O Usuário está autorizado a acessar, copiar, fazer o download e imprimir os materiais contidos no Site apenas para uso pessoal e não comercial, desde que tais materiais não sejam modificados e que nenhum aviso de direitos autorais ou marca registrada seja modificado ou excluído. Qualquer outro uso do Conteúdo, incluindo, sem limitação, a reprodução, modificação, uso, publicação, transmissão em mídia, carregamento, transmissão, fixação, distribuição, licenciamento ou a criação de trabalhos derivados dos Conteúdos, é estritamente proibido. A Solidária reserva todos os direitos expressamente não concedidos nestes Termos de Uso, bem como para o Site e seu Conteúdo. <br/> <br/>Solidaria ® e seu logotipo são marcas registradas da Fundación Solidaria, A.C. Esta marca e quaisquer outras marcas e marcas de serviço que fazem parte dos Conteúdos não devem ser usadas de forma alguma sem o consentimento prévio por escrito da Solidária. Nada neste Site deve ser interpretado como a concessão de qualquer licença ou direito de usar qualquer parte do Conteúdo.',
					l3: 'Uso de informação de contatos',
					c3:
						'O Usuário não usará as informações de contato fornecidas no Site para qualquer finalidade não autorizada, incluindo marketing, sem o consentimento prévio por escrito da Solidária.',
					l4: 'Links para outros sites',
					c4:
						'O Site oferece links para páginas da Web e acesso a conteúdos, produtos e serviços de terceiros, incluindo usuários, anunciantes, afiliados e patrocinadores do Site. O Usuário aceita que a Solidária não é responsável pela disponibilidade e Conteúdo incluídos nas páginas da Web de terceiros. O Usuário é impedido de revisar as políticas anunciadas por outras páginas da Web em relação aos seus próprios termos de uso antes de usar. A Solidária não é responsável pelo conteúdo de terceiros acessível através do Site, incluindo opiniões, conselhos, declarações e anúncios, e o Usuário assumirá todos os riscos associados ao uso do referido Conteúdo. Nenhum link deve ser feito para este Site sem o consentimento prévio por escrito da Solidária.',
					l5: 'Informação pessoal e não pessoal',
					c5: `Algumas funções no Site exigem registro, o que significa que você nos fornece alguns de seus Dados Pessoais. Seus Dados Pessoais são obtidos, usados, armazenados e, em geral, são tratados de acordo com os Avisos de Privacidade que são disponibilizados para você. Ao se registrar no Site, o Usuário compromete-se a fornecer informações e outros Dados Pessoais verdadeiros, precisos, atualizados e completos sobre si mesmo. No momento da inscrição, o usuário pode escolher a opção de ser contatado por um representante da Solidária por qualquer um dos meios de contato que ele tenha capturado no formulário de registro, por isso, ao escolher essa opção, o Usuário está de acordo a ser contatado pela Solidária, no entanto, o Usuário aceita que a escolha dessa opção não crie uma obrigação para a Solidária entrar em contato com o Usuário.<br/>
            <br/>
            Além disso, o Usuário poderá escolher no cadastro a opção de receber comunicações e boletins informativos da Solidária em seu e-mail, portanto, se ele escolher essa opção, o Usuário aceita que a Solidária envie o referido material apenas para fins meramente informativos. Todos os materiais enviados pela Solidária nos referidos comunicados e newsletters serão considerados como parte do Conteúdo do Site e estarão sujeitos aos Termos de Uso fornecidos neste documento. Caso o Usuário não deseje receber essas comunicações e boletins informativos posteriormente, devem solicitar sua remoção da lista de distribuição abrindo o link da Internet identificado para essa finalidade na parte inferior da mensagem de e-mail.<br/>
            <br/>
            Algumas funções no Site, além do registro, exigem o uso de uma senha que ficará exclusivamente sob a responsabilidade do Usuário. O Utilizador será o único responsável por qualquer material ou comunicação enviada através do Site, utilizando a sua chave de acesso e, no seu caso, a sua senha, e será o único responsável por qualquer obrigação ou dano resultante ou emergente relacionado com o envio de ditos materiais pelo Usuário.<br/>
            <br/>
            O Usuário aceita que qualquer emissão de materiais ou comunicações através dos espaços abertos do Site pelo Usuário, incluindo quaisquer comentários, perguntas, sugestões ou semelhantes, sejam enviados sem restrições, de forma não exclusiva, isenta de royalties, perpétua e irrevogável e será tratado como não confidencial e propriedade não exclusiva. O Usuário não deve enviar nenhum material ou comunicação que seja obscena, difamatória ou que sua entrega constitua uma infração ou violação de qualquer direito de terceiros.<br/>
            <br/>
            A Solidária é a única proprietária das informações contidas neste Site. A Solidária só tem acesso para coletar as informações fornecidas voluntariamente pelo Usuário por e-mail ou por qualquer outro meio de contato direto. Nós não iremos vender ou arrendar essas informações para qualquer pessoa física ou jurídica. A Solidária usa as informações do usuário para responder ao mesmo em relação ao motivo de entrar em contato conosco. A Solidária não compartilhará as informações do Usuário com terceiros que não estejam em nossa organização. A Solidária compartilhará apenas as informações necessárias para cumprir as exigências do Usuário. A menos que o Usuário solicite o contrário, a Solidária poderá entrar em contato com o Usuário no futuro para enviar informações sobre nossos produtos ou serviços, ou sobre quaisquer alterações em nossas políticas de privacidade. O Usuário pode optar por solicitar à Solidária que não restabeleça contato com ele, a qualquer momento, o que estiver acima pode ser feito a qualquer momento, entrando em contato com a Solidária por e-mail ou através do telefone fornecido no Site. A Solidária toma as medidas preventivas necessárias para proteger as informações do Usuário. A Solidária não resguarda informações confidenciais (como informações sobre cartões de crédito), apenas o pessoal da Solidária que precisa das informações para realizar um trabalho específico (por exemplo, projetos de solução ou marketing), tem acesso a informações sobre identificação pessoal de cada Usuário. Os computadores e servidores em que a Solidária armazena Dados Pessoais são protegidos em um ambiente seguro.<br/>
            <br/>
            A Solidária adotou e mantém as medidas de segurança necessárias para a proteção dos Dados Pessoais que são fornecidos pelo Usuário, a fim de evitar sua perda, uso indevido, alteração ou acesso não autorizado e roubo. Não obstante o acima mencionado, o Usuário deve estar ciente de que as medidas de segurança na Internet não são inexpugnáveis e, portanto, deve levar em consideração que a Solidária não pode garantir que seus Dados Pessoais estarão livres de qualquer perda, uso indevido, alteração, acesso não autorizado e/ou roubo, bem como danos causados pelo uso indevido da página Web ou vírus na rede.`,
					l6: 'Uso de Cookies',
					c6:
						'Cookies são pedaços de informação que uma página da Web transfere para uma unidade de disco duro individual para fins de registro; nos cookies as senhas não são salvas. Os cookies ajudam a Solidária saber quais páginas são mais visitadas e ajudam a Solidária a garantir que o seu Site atenda às necessidades dos usuários. Se o usuário não quiser receber um cookie, ou se ele quiser que seu navegador o notifique quando ele receber um cookie, o usuário poderá usar a opção em seu navegador para desativar os cookies. Clique na seção "Ajuda" do seu navegador para saber como alterar as preferências de cookies. No entanto, o usuário deve levar em conta que, se desativar todos os cookies, talvez não possa aproveitar todas as funções disponíveis nesta Página Web. A Solidária não utiliza os dados de identificação pessoal que são transferidos por meio de cookies para fins promocionais ou de marketing, nem as referidas informações são compartilhadas com terceiros.',
					l7: 'Conteúdo do Site',
					c7:
						'Todo o conteúdo do site é fornecido "como está" e "com base na disponibilidade". A Solidária rejeita expressamente todas as garantias de qualquer tipo com relação ao Conteúdo do Site, sejam expressas ou implícitas, incluindo, mas não limitadas a, garantias implícitas de comercialização e adequação a uma finalidade específica e quaisquer garantias de que os materiais no Site não infringem direitos, bem como as garantias implícitas de um curso de execução ou gerenciamento. A Solidária se reserva o direito de modificar o Conteúdo a qualquer momento, mas não assume a obrigação de atualizar qualquer informação no Site. A Solidária não garante ou representa o uso dos materiais neste Site em termos de integridade, exatidão, precisão, adequação, utilidade, prazos, confiabilidade ou outros.',
					l8: 'Garantias',
					c8: `A Solidária não oferece qualquer garantia de que: (a) o Site atenda aos requisitos do Usuário; (b) o Site estará disponível de maneira ininterrupta, livre, oportuna, segura ou livre de erros, ou que o servidor que disponibiliza o Site estará livre de vírus; (c) os resultados que podem ser obtidos com o uso do Site ou qualquer um dos serviços oferecidos através do Site serão precisos ou confiáveis; ou (d) as informações contidas no Site são atualizadas e refletem a situação atual da companhia. Se o Usuário não estiver satisfeito com qualquer parte do Site, ou com qualquer um destes Termos de Uso, será unicamente e exclusiva decisão do Usuário de descontinuar o uso deste Site. O Usuário reconhece que o download de qualquer material deste Site, confiabilidade nas informações deste Site e qualquer uso deste Site são de responsabilidade exclusiva do Usuário, e que o Site está disponível para o Usuário sem nenhum custo. Portanto, o Usuário reconhece e aceita que, na máxima extensão permitida por lei, nem a Solidária nem suas afiliadas ou provedores de conteúdos de terceiros serão responsáveis por danos diretos, indiretos, punitivos, exemplares, incidentais, especiais, consequenciais ou indiretos ou de outra natureza derivada ou de alguma forma relacionada a este Site. A Solidária não será responsável por qualquer dano ao sistema de computação ou perda de informações do Usuário resultante do download de qualquer Conteúdo, materiais ou informações do Site. Sob nenhuma circunstância a Solidária será responsável por danos de qualquer espécie, incluindo sem limitação danos diretos, incidentais ou consequenciais (incluindo, mas não limitado a, danos por lucros cessantes, interrupção de negócios e perda de programas ou informações) que possam ser derivados do uso ou incapacidade de usar o Site, ou qualquer informação nele fornecida.`,
					l9: 'Proibições',
					c9: `O Usuário não deve usar nenhum hardware ou software que pretenda danificar, interferir com o funcionamento adequado do Site ou interceptar quaisquer dados ou informações pessoais do Site. O Usuário não deve interromper, tentar interromper, invadir ou tentar invadir de qualquer forma a operação do Site. O Usuário não tem permissão para enquadrar este Site ou usar as marcas da Solidária como meta tags, sem o consentimento prévio por escrito da Solidária. O Usuário não pode usar quadros ou usar técnicas de enquadramento para anexar qualquer conteúdo. Além disso, o Usuário não pode usar qualquer conteúdo em nenhuma meta tags ou qualquer outra técnica similar, sem o consentimento prévio por escrito da Solidária. O Usuário não pode ocultar qualquer Conteúdo, incluindo estes Termos de Uso ou qualquer aviso de direitos autorais ou de propriedade no Site.`,
					l10: 'Indenização',
					c10: `O Usuário concorda em indenizar, defender e remover a Solidária de e contra todas as perdas, despesas, danos e custos, incluindo honorários razoáveis de advogados, derivados ou relacionados ao uso indevido por parte do Usuário dos Conteúdos e serviços fornecidos no Site ou qualquer outra violação por parte do Usuário dos Termos de Uso aqui contidos.`,
					l11: 'Fim do acesso',
					c11: `A Solidária pode encerrar o acesso ao usuário a qualquer momento e por qualquer motivo. As disposições relativas a renúncias de garantia, a precisão das informações e as obrigações de indenização devem sobreviver a tal rompimento.`,
					l12: 'Modificações aos Termos de Uso',
					c12: `A Solidária pode revisar e alterar qualquer um ou todos os Termos de Uso a qualquer momento, sem aviso prévio ao Usuário. Essas alterações entrarão em vigor imediatamente após a publicação no site. Ao continuar a usar o site após a publicação das alterações, o Usuário reconhece a aceitação de tais alterações. Qualquer conflito entre os Termos de Uso e os termos de uso publicados em uma área do Site, este último terá preferência com relação ao uso daquela área do Site.`,
					l13: 'Uso legal do Site',
					c13: `O Usuário garante que o acesso a este Site e a informação contida neste documento não é ilegal sob as leis aplicáveis da jurisdição onde o Usuário é residente ou onde o Usuário tem acesso ao Site. Se qualquer Conteúdo ou uso deste Site for contrário às leis do lugar onde o Usuário está acessando o Site, este Site não é destinado ao referido Usuário e o Usuário deve abster-se de acessar ou usar este Site.`,
					l14: 'Cláusulas inválidas',
					c14: `Se qualquer um dos termos, limitações ou isenções de responsabilidade Contidos nos Termos de Uso acima forem considerados ilegais, inexecutáveis ou inválidos, no todo ou em parte, por qualquer motivo, sob as leis aplicáveis na jurisdição onde o Usuário reside ou onde  teve acesso ao Site, tais disposições ilegais, inexecutáveis ou inválidas, ou parte delas, deverão ser removidas destes Termos de Uso, e tal disposição excluída não afetará a legalidade, aplicabilidade ou validade do restante destes Termos de Uso. Se qualquer disposição ou parte destes Termos de Uso forem removidos de acordo com as disposições desta seção, as disposições descartadas serão substituídas, na medida do possível, por uma disposição legal, aplicável e válida que seja a mais semelhante possível à disposição removida.`,
					l15: 'Renuncia a estes Termos de Uso',
					c15: `Nenhuma modificação, autorização ou renúncia sob estes Termos de Uso terá efeito, a menos que seja por escrito e assinada por um representante devidamente autorizado da Solidária.`,
					l16: 'Leis aplicáveis',
					c16:
						'Estes Termos de Uso estarão sujeitos às leis aplicáveis do México.<br/>    <br/>Obrigado por visitar  <a href="http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>'
				}
			}
		},

		privacyTerms: {
			title: 'Aviso de Privacidade ',
			text:
				'Fundación Solidaria, A.C. domiciliada na Av. Boulevard Constitución 3098 6° Andar, Colonia Santa María, Monterrey, Nuevo León C.P. 64650, (mais adiante “Solidaria”) informa que os dados pessoais que você nos proporcionar voluntariamente, incluindo dados pessoais sensíveis, serão usados somente para contatá-lo e acompanhar sua comunicação; caso seu perfil seja de interesse para Solidaria seus dados pessoais serão utilizados para tomar decisões de seleção, fazer avaliações, compilar registros, entre outros.',
			text1:
				'Solidaria não irá vender, ceder ou transferir seus dados pessoais para terceiros alheios a Softtek sem o seu consentimento prévio. No entanto, o Solidaria pode transferir seus dados pessoais quando a transferência estiver prevista na lei. Solidaria adotou as medidas de segurança, administrativas, técnicas e físicas, necessárias para proteger seus dados pessoais contra danos, perda, alteração, destruição ou uso, acesso ou processamento não autorizado. Porém, é claro que nenhuma transmissão de dados pela Internet é completamente segura e, portanto, a Solidaria não pode garantir que o processamento de seus dados pessoais esteja livre de qualquer dano, perda, alteração, destruição ou uso, acesso ou processamento não autorizado.',
			text2:
				'Você como titular de dados pessoais, pode exercer perante a Pessoa Responsável pelos Dados Pessoais de Solidaria os direitos de acesso, retificação, cancelamento e oposição (direitos ARCO), estabelecidos na Lei Federal de Proteção de Dados Pessoais em Posse dos Particulares (a lei). Além disso, você pode revogar, a qualquer momento, o consentimento que tenha dado e que foi necessário para realizar o processamento de seus dados pessoais, bem como solicitar que o uso ou divulgação dos mesmos seja limitado. O anterior citado, enviando seu pedido nos termos estabelecidos por lei para o e-mail: <a href="mailto:privacy@softtek.com">privacy@softtek.com</a> ou a Boulevard Constitución 3098 6º andar, Colonia Santa María, Monterrey, Nuevo León C.P. 64650.',
			text3:
				'Solidaria se reserva o direito de fazer alterações neste Aviso de Privacidade, que será comunicado através do site <a href=""http://www.fundacionsolidaria.org">www.fundacionsolidaria.org</a>.',
			strong:
				'Se não desejar que a Solidaria processe seus dados pessoais para cumprir com as finalidades  descritas acima, solicitamos que você se abstenha de fornecê-los, já que fornecê-los implica que você concorda com os termos deste.',
			text4:
				'Informamos que, em face da recusa em responder as solicitações de direito ARCO ou desacordo com o mesmo, você pode enviar ao Instituto Federal de Acesso à Informação e Proteção de Dados, o correspondente Pedido de Proteção de Direitos nos termos e condições fixados pela Lei e seu Regulamento.'
		}
	}
}

/*
El lenguaje contiene 3 niveles :
1.- El códgigo de lenguaje manejado con ISO :  en-US
 2.- El nombre de las páginas que componen solidaria o alguna sección particular :   nav
  3.- El nombre del texto que se encuentra en cada pág. de navegación  :   home

Se comienza con las pág. respecto a la navegación natural que se daría en la misma,
es decir comienza con el navbar .. home .. about us .. etc. hasta terminar en las opciones de dashboard como administrador.

Se manejan los datos de arriba hacia abajo, es decir comienza con os titulos.

Respecto a los catalogos :
Los nombres de los labels de cada form  se encuentra en un segundo nivel llamado "label".
  A manera de lo posible se mantienen los nombres como se manejan en su modela de la API
  {si necesita alguno en plural se encuentra en label, si lo necesita singular, se encuentra en modal}

Los botones se encuentran en un segundo nivel llamado "btn".

La información de cada modal se encuentra en un segundo nivel llamado "modal".

También se encuentra en un segundo nivel "help" que abarca todos los mensajes helptext tales como : campo requerido

Para tener un mejor control ningún dato se repite, si se necesita algún texto en otra sección se rehúsa.

*/
