import axios from 'axios'
import Swal from 'sweetalert2'
import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import { donationActions, donationTypes } from './donation.redux'
import swal from 'sweetalert'

export function* createConektaDonation({ payload, cb }) {
  try {
    const url = '/api/projectdonation/credit-card'

    const campaing = yield select(state => state.campaing.view)

    const promptConfig = {
      title: 'Confirmación',
      text: `Donar $${Number(payload.amount).toFixed(2)} a ${campaing.title}`,
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false
    }

    const prompt = yield Swal.fire(promptConfig)
    if (prompt.value === false) return

    yield call(cb)

    const { data } = yield call(axios.post, url, payload)
    yield put(donationActions.createConektaDonationSuccess(data))
    yield swal('¡Gracias por tu Donación!', '', 'success')
  } catch (error) {
    if (error){
      const { text, title, icon} = handleConektaErrors(error.response.data)
      yield Swal.fire({title, text, icon})
      yield put(donationActions.createConektaDonationFailure(error))
    }
  }
}

export function handleConektaErrors(error) {
  if (!error.detail) return {
    title: 'Error al consultar el servicio',
    text: 'Favor de notificar este error a la siguiente direccion de correo probono@softtek.com',
    icon: 'error'
  }
  const {http_code, details} = error.detail
  switch (http_code) {
    case 422:
      return {
        title: 'Favor de considerar los siguientes requisitos para realizar su donacion',
        text: Array.isArray(details) ? details.map(l => l.message.split('\n')) : 'Error al tratar de procesar el pago' ,
        icon: 'warning'
      }
    case 402:
      return {
        title: 'Fondos insuficientes y/o problema con la tarjeta',
        text: Array.isArray(details) ? details.map(l => l.message.split('\n')) : 'Error al tratar de procesar el pago' ,
        icon: 'warning'
      }
    default:
      return {
        title: 'Error al consultar el servicio',
        text: 'Favor de notificar este error a la siguiente direccion de correo probono@softtek.com',
        icon: 'error'
      }
  }
}


export function* requestOxxoPayDonation({ payload, cb }) {
  try {
    const url = '/api/projectdonation/oxxo-pay/request'
    const { data } = yield call(axios.post, url, payload)
    yield put(donationActions.requestOxxoPayDonationSuccess(data))

    yield call(cb, data)
  } catch (error) {
    yield Swal.fire({
      title: 'Información inválida',
      text: 'Verifique los datos proporcionados',
      icon: 'error'
    })
    yield put(donationActions.requestOxxoPayDonationFailure(error))
    console.log(error)
  }
}

export function* donationSagas() {
  yield all([
    takeLatest(donationTypes.CREATE_CONEKTA_DONATION_REQUEST, createConektaDonation),
    takeLatest(donationTypes.REQUEST_OXXO_PAY_DONATION_REQUEST, requestOxxoPayDonation)
  ])
}
