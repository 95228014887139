import React, { Fragment } from 'react'
import Footer from '../Common/Footer/Footer'
import Navbar from '../Common/Navbar/Navbar'
import DTSInfoSection from './../DareToSharePageSection/DTSInfoSection/DTSInfoSection'
import DTSImage from '../DareToSharePageSection/DTSImage/DTSImage'
import DTSVoluntarioSection from '../DareToSharePageSection/DTSInfoVoluntarioSection/DTSVoluntarioSection'

// Solution to scroll restoration
import ScrollToTopOnMount from './../Common/ScrollToTopOnMount'

function DaretoShare() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <Navbar />
      <DTSInfoSection />
      <DTSImage />
      <DTSVoluntarioSection />
      <Footer />
    </Fragment>
  )
}

export default DaretoShare
