import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty } from './../util/inputsHelpers';

export class PrivateRoute extends Component {
  render() {
    let { roles, component: Component, auth, ...rest } = this.props
    // viene roles de router state push
    if (isEmpty(roles)) {
      roles = this.props.location.state.roles
    }
    let can = true
    let render = null
    if (auth.isAuthenticated) {
      if (!isEmpty(roles) && auth.isAuthenticated) {
        const rol = auth.user.rol
        can = roles.some(r => r === rol)
        if (can) {
          render = <Route {...rest} render={props => <Component {...props} />} />
        } else {
          render = <Redirect to="/" />
        }
      } else {
        render = <Redirect to="/" />
      }
    } else {
      render = <Redirect to="/login" />
    }
    return render
  }
}


PrivateRoute.propTypes = { auth: PropTypes.object.isRequired }
const mapDispatchToProps = {}
const mapStateToProps = ({ auth }) => ({ auth })
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)