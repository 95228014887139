/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { actions } from './mailing.redux'
import DataTable from '../common/Table/Table'
import TableContainer from '../common/Container/TableContainer'
import Admin from '../Layout/Admin/Admin'
import { mdyhm } from '../../util/dateHelpers'

function MailingList(props) {
  useEffect(() => {
    props.loadAllMailing()
  }, [])

  const redirectToView = id => {
    props.history.push(`/dashboard/mailing/view/${id}`)
  }

  const rowEvents = {
    onClick: (_, { _id }) => redirectToView(_id)
  }

  const templates = { 'end-of-year-not-participated-us': 'Agradecimiento US' }
  const columns = [
    {
      dataField: 'template',
      text: 'Plantilla del correo',
      formatter: cell => templates[cell]
    },
    { dataField: 'emails.length', text: 'Correos', sort: true },
    { dataField: 'created_by.name', text: 'Autor', sort: true },
    { dataField: 'createdAt', text: 'Creado', sort: true, formatter: mdyhm }
  ]

  return (
    <Admin>
      <TableContainer title='Correos enviados' subtitle='Lista'>
        <DataTable {...props} rowEvents={rowEvents} data={props.mailing} structure={columns} />
      </TableContainer>
    </Admin>
  )
}

const mapStateToProps = ({ mailing }) => ({ mailing: mailing.list })
const mapDispatchToProps = { loadAllMailing: actions.loadAllMailingRequest }
export default connect(mapStateToProps, mapDispatchToProps)(MailingList)
