import React from 'react'

import styles from './contentWrapper.module.css'

export default function ContentWrapper(props) {

  // Properties destructuring
  const {
    pretitle, title, content,                 // Texts
    pretitleClass, titleClass, contentClass,  // Classnames
    pretitleStyle, titleStyle, contentStyle,  // Styles
    ...wrapperProps
  } = props

  return (
    <div className='my-5' {...wrapperProps}>
      {pretitle && (<h3
        style={pretitleStyle}
        className={`${styles['pretitle']} ${pretitleClass || ''} mt-3 mb-4`}
      >
        {pretitle}
      </h3>)}
      {title && (<h1
        style={titleStyle}
        className={`${styles['title']} ${titleClass || ''} my-4`}
      >
        {title}
      </h1>)}
      {content && (<p
        style={contentStyle}
        className={`${styles['content']} lead txt ${contentClass || ''} mt-4 mb-5`}
      >
        {content}
      </p>)}
    </div>
  )
}
