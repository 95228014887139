import React, {  } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap'
import { isEmpty } from '../../util/inputsHelpers'
import { dmy } from '../../util/dateHelpers'
import { FormattedMessage } from 'react-intl'

// Función que recibe como parametro un objeto con diferentes campos que componen una dirección local
// (Calle, Colonia, Ciudad, Estado, etc) y retorna un 'string' con toda la dirección separada por comas.
const addressFormatter = address => {
  return Object.keys(address).filter(k => k !== 'recipient_name').map(key => address[key]).join(', ')
}

const ModalDonation = props => {
  const { item, state } = props
  const { paypal } = item
  console.log('paypal', paypal)
  return (
    <Modal size="lg" isOpen={state.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        <FormattedMessage id="modal.detailDonation" /> {state.modalPaypal && 'Paypal'}
      </ModalHeader>
      <ModalBody>
        {(!isEmpty(item.paypal) || !isEmpty(item.conekta)) &&
          <>
            <Row>
              <Col>
                <strong><FormattedMessage id="modal.donationGross" />:</strong> ${item.amount.toFixed(2)} <FormattedMessage id="modal.usd" /><br />
                <strong><FormattedMessage id="modal.fee" />:</strong> ${item.fee.toFixed(2)} <FormattedMessage id="modal.usd" /><br />
                <strong><FormattedMessage id="modal.donationNet" />:</strong> ${(item.amount - item.fee).toFixed(2)} <FormattedMessage id="modal.usd" /><br />
              </Col>
              {item.currency !== 'USD' &&
                <Col>
                  <strong><FormattedMessage id="modal.donationGross" />:</strong> ${(item.originalAmount || item.amount).toFixed(2)} {item.currency}<br />
                  <strong><FormattedMessage id="modal.fee" />:</strong> ${item.originalFee.toFixed(2)} {item.currency}<br />
                  <span><strong><FormattedMessage id="modal.donationNet" />:</strong> ${(item.originalAmount - item.originalFee).toFixed(2)} {item.currency}<br /></span>
                </Col>}
            </Row>
            <hr />
          </>
        }
        {/* MODAL GENERAL */}
        {!isEmpty(item) && (<div>
          {isEmpty(item.paypal) &&
            <>
              <strong><FormattedMessage id="modal.donationNet" /> (<FormattedMessage id="modal.usd" />):</strong> ${(item.amount - item.fee).toFixed(2)} <br />
              {item.currency !== 'USD' && <span><strong><FormattedMessage id="modal.donationNet" /> ({item.currency}):</strong> ${(item.originalAmount - item.originalFee).toFixed(2)}<br /></span> }
            </>}
          <strong><FormattedMessage id="modal.typeDonation" />:</strong> {item.type} <br />
          <strong><FormattedMessage id="modal.project" />:</strong> {!isEmpty(item.project) ? item.project.title : 'No asignado'} <br />
          <strong><FormattedMessage id="label.description" />:</strong> {!isEmpty(item.description) ? item.description : 'Sin descripción'} <br />
          <strong><FormattedMessage id="general.date" />:</strong> {dmy(item.createdAt)} <br />
          <strong><FormattedMessage id="modal.donorCreator" />:</strong> {!isEmpty(item.created_by) ? item.created_by.name : isEmpty(item.paypal) ? 'Conekta': 'PayPal'} <br />
        </div>)}
        {/* MODAL PAYPAL */}
        {(!isEmpty(item) && !isEmpty(item.paypal)) && (
          <div>
            <hr />
            <div>
              <strong><FormattedMessage id="modal.donor" />:</strong> {paypal.payer.name.given_name} {paypal.payer.name.surname} <br />
              {/* <strong><FormattedMessage id="modal.donationGross" /> (<FormattedMessage id="modal.usd" />):</strong> ${item.amount.toFixed(2)} <br />
              <strong><FormattedMessage id="modal.feePayPal" /> (<FormattedMessage id="modal.usd" />):</strong> ${item.fee.toFixed(2)} <br /> */}
              {/* {item.currency!== 'USD' &&
                <>
                  <strong><FormattedMessage id="modal.donationGross" /> ({item.currency}):</strong> ${(item.originalAmount || item.amount).toFixed(2)} <br />
                  <strong><FormattedMessage id="modal.feePayPal" /> ({item.currency}):</strong> ${item.originalFee.toFixed(2)} <br />
                </>} */}
              <strong><FormattedMessage id="label.email" />:</strong> {paypal.payer.email_address} <br />
              {/* <strong><FormattedMessage id="label.address" />:</strong> {addressFormatter(paypal.address)} */}
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={props.toggle}><FormattedMessage id="btn.cancel" /></Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalDonation
