import React, { useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { isEmpty } from '../../util/isEmpty'
import { actions } from './customer-project.redux'
import ImgPreview from './../common/ImgPreview'
import Navbar from '../Main/Common/Navbar/Navbar'
import Footer from '../Main/Common/Footer/Footer'
import Breadcrumb from '../Main/Common/Breadcrumb/Breadcrumb'
import CustomerProjectDonate from './components/CustomerProjectDonate'
import LoadingPageOverlay from '../common/LoadingPage/LoadingPageOverlay'

export function CustomerProject({ item, match, intl, publicLoadCustomerProject }) {
  useEffect(() => {
    const { slug } = match.params
    publicLoadCustomerProject({ slug, intl: intl })
  }, [match.params.slug])

  if (isEmpty(item)) return <LoadingPageOverlay />

  return (
    <Fragment>
      <Navbar />
      <div className='contenedorProject'>
        <Breadcrumb {...item} link='.' id='nav.gallerie' />
        <div className='col-lg-12 row'>
          <h1 className='mt-4 mb-3 subtitleProject'>{item.title}</h1>
        </div>
        <br />
        <div className='row'>
          <div className='col-lg-8'>
            <ImgPreview {...item.thumbnail} />
            <span className='lead titleProject'>{item.description}</span>
            <hr />
            <div
              className='txt-customer-project'
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </div>
          <div className='col-lg-4'>
            <CustomerProjectDonate project={item} match={match} />
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  )
}

CustomerProject.propTypes = {
  item: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  publicLoadCustomerProject: PropTypes.func.isRequired
}

const mapStateToProps = ({ customerProject }) => ({ item: customerProject.individual })
const mapDispatchToProps = {
  publicLoadCustomerProject: actions.publicLoadCustomerProjectRequest
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default withConnect(injectIntl(CustomerProject))
