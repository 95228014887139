
import { SET_INIT_LOADING } from './../actions/types'
const initialState = { loadin: true }
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INIT_LOADING:
      return { loading: !state.loading }
    default:
      return state
  }
}