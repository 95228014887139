import React, { Fragment } from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { dmy } from '../../../util/dateHelpers'
import { isEmpty } from './../../../util/isEmpty'
import { FormattedMessage } from 'react-intl'
import { ymd } from '../../../util/dateHelpers'
import { formatCurrency } from './../../../util/formatCurrency'

const needReceiptFormatter = (cell, row, options) => {
	return !isEmpty(row.paypal) ? options.true : options[cell]
}

const canEdit = (row) => (row.approved ? isEmpty(row.project) : true)

export const approvedIcon = (value) => {
	return value ? (
		<i className="fas fa-check " style={{ color: '#41b38f' }} />
	) : (
		<i style={{ color: '#dc3545' }} className="fas fa-times" />
	)
}

export const tableOptions = (formatMessage, typeOptions, approvedOptions, props) => [
	{
		dataField: 'project.title',
		text: formatMessage({ id: 'modal.project' }),
		sort: true,
		formatter: (cell) => (cell ? cell : 'General'),
		csvFormatter: (cell) => (cell ? cell : formatMessage({ id: 'help.withoutProject' }))
	},
	{
		dataField: 'type',
		text: formatMessage({ id: 'label.kind' }),
		formatter: (cell) => typeOptions[cell],
		sort: true
	},
	{
		dataField: 'amount',
		text: formatMessage({ id: 'modal.donationNet' }) + ' (USD)',
		formatter: (cell, row) => formatCurrency(row.paypal ? cell - row.fee : cell) + ' USD',
		headerStyle: () => ({ minWidth: '150px' }),
		sort: true
	},
	{
		dataField: 'originalAmount',
		text: `${formatMessage({ id: 'modal.donationNet' })} (${formatMessage({
			id: 'label.currency'
		})})`,
		formatter: (cell, row) => {
			const amount = cell === 0 ? row.amount : cell
			return formatCurrency(amount - row.originalFee) + ' ' + row.currency
		},
		headerStyle: () => ({ minWidth: '150px' }),
		sort: true
	},
	{
		dataField: 'sede.name',
		text: formatMessage({ id: 'label.sede' }),
		sort: true,
		formatter: (cell) => (cell ? cell : approvedIcon(false))
	},
	{
		dataField: 'subsede.name',
		text: formatMessage({ id: 'label.subsede' }),
		sort: true,
		formatter: (cell) => (cell ? cell : approvedIcon(false))
	},
	{
		dataField: 'needReceipt',
		text: formatMessage({ id: 'label.receipt' }),
		sort: true,
		formatter: (cell, row) => {
			return !isEmpty(row.paypal) ? approvedIcon(true) : approvedIcon(cell)
		},

		csvFormatter: (cell, row) => needReceiptFormatter(cell, row, approvedOptions)
	},
	{
		dataField: 'approved',
		text: formatMessage({ id: 'table.approved' }),
		sort: true,
		formatter: (cell) => approvedIcon(cell),
		csvFormatter: (cell) => approvedOptions[cell]
	},
	{
		dataField: 'createdAt',
		text: formatMessage({ id: 'general.date' }),
		sort: true,
		formatter: dmy,
		csvFormatter: dmy
	},
	{
		dataField: '_id',
		text: formatMessage({ id: 'general.actions' }),
		hidden: props.props.auth.user.rol === 'reporter',
		csvExport: false,
		formatter: (cell, row) => {
			return props.props.auth.user.rol !== 'reporter' ? (
				<UncontrolledDropdown>
					<DropdownToggle caret>
						<i class="fas fa-list-ul" />
					</DropdownToggle>
					<DropdownMenu>
						{canEdit(row) && (
							<DropdownItem>
								<button className="btn btn-block btn-info" onClick={() => props.onClickEdit(row)}>
									<i class="fas fa-edit" /> <FormattedMessage id="btn.edit" />
								</button>
							</DropdownItem>
						)}
						{!row.approved && (
							<DropdownItem>
								<button
									className="btn btn-block btn-success"
									onClick={() => props.onClickApprove(row._id)}
								>
									<i class="fas fa-power-off" /> <FormattedMessage id="btn.approve" />
								</button>
							</DropdownItem>
						)}
						{!row.approved &&
						isEmpty(row.paypal) && (
							<DropdownItem>
								<button
									className="btn btn-block btn-danger"
									onClick={() => props.onClickDelete(row._id)}
								>
									<i class="fa fa-trash" /> <FormattedMessage id="btn.delete" />
								</button>
							</DropdownItem>
						)}
						<DropdownItem>
							<button
								name="general"
								className="btn btn-block btn-primary"
								onClick={(e) => props.onClickDetail(e.target.name, row._id)}
							>
								<i class="fas fa-eye" /> <FormattedMessage id="btn.detail" />
							</button>
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			) : (
				<Fragment>
					<button
						name="general"
						className="btn btn-block btn-primary"
						onClick={(e) => props.onClickDetail(e.target.name, row._id)}
					>
						<i class="fas fa-eye" />
					</button>
				</Fragment>
			)
		}
	}
]

const typeOptions = {
	transfer: 'Transfer',
	manual: 'Manual',
	paypal: 'Paypal',
	softtek: 'Softtek',
	others: 'Others'
}

export const columnsToExport = [
	{ title: 'Cantidad', value: (row) => row.amount.toFixed(2) },
	{ title: 'Tipo', value: (row) => typeOptions[row.type] },
	{
		title: 'Proyecto',
		value: (row) => (!isEmpty(row.project) ? row.project.title : 'No asignado')
	},
	{ title: 'Sede', value: (row) => (!isEmpty(row.sede) ? row.sede.name : 'N/A') },
	{ title: 'Subsede', value: (row) => (!isEmpty(row.subsede) ? row.subsede.name : 'N/A') },
	{ title: 'Recibo', value: (row) => (row.needReceipt ? 'Si' : 'No') },
	{ title: 'Aprobado', value: (row) => (row.approved ? 'Si' : 'No') },
	{ title: 'Creado', value: (row) => ymd(row.createdAt) }
]
