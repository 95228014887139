import React, { Fragment } from 'react'
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { dmy } from './../../../util/dateHelpers'

const approvedIcon = (value) => {
	return value ? (
		<i className="fas fa-check" style={{ color: '#41b38f' }} />
	) : (
		<i style={{ color: '#dc3545' }} className="fas fa-times" />
	)
}

export const tableOptions = (formatMessage, rolOptions, activeOptions, props) => [
	{
		dataField: 'email',
		text: formatMessage({ id: 'label.email' }),
		sort: true,
		filter: textFilter()
	},
	{
		dataField: 'rol',
		text: formatMessage({ id: 'label.role' }),
		sort: true,
		formatter: (cell) => rolOptions[cell],
		filter: selectFilter({
			options: rolOptions
		})
	},
	{
		dataField: 'is',
		text: formatMessage({ id: 'label.is' }),
		filter: textFilter(),
		formatter: (cell) => (cell ? cell : formatMessage({ id: 'general.pendingField' })),
		sort: true
	},
	{
		dataField: 'phone',
		text: formatMessage({ id: 'label.phone' }),
		filter: textFilter(),
		sort: true,
		formatter: (cell) => (cell ? cell : formatMessage({ id: 'general.pendingField' }))
	},
	{
		dataField: 'sede.name',
		text: formatMessage({ id: 'modal.sede' }),
		sort: true,
		filter: textFilter()
	},
	{
		dataField: 'subsede.name',
		text: formatMessage({ id: 'modal.subsede' }),
		sort: true,
		filter: textFilter()
	},
	{
		dataField: 'active',
		text: formatMessage({ id: 'table.active' }),
		sort: true,
		formatter: (cell) => approvedIcon(cell),
		csvFormatter: (cell) => activeOptions[cell]
	},
	{
		dataField: '_id',
		text: formatMessage({ id: 'general.actions' }),
		hidden: props.props.auth.user.rol === 'reporter',
		csvExport: false,
		formatter: (cell, row) => {
			return props.props.auth.user.rol !== 'reporter' ? (
				<UncontrolledDropdown>
					<DropdownToggle caret>
						<FormattedMessage id="general.actions" />
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem>
							<button className="btn btn-block btn-info" onClick={() => props.onClickEdit(row)}>
								<i class="fas fa-edit" /> <FormattedMessage id="btn.edit" />
							</button>
						</DropdownItem>
						{!row.approved && (
							<DropdownItem>
								<button className="btn btn-block btn-success" onClick={() => props.onClickActive(row)}>
									<i class="fas fa-power-off" /> <FormattedMessage id="table.active" />
								</button>
							</DropdownItem>
						)}
						<DropdownItem>
							<button
								name="general"
								className="btn btn-block btn-primary"
								onClick={(e) => props.onClickDetail(row)}
							>
								<i class="fas fa-eye" /> <FormattedMessage id="btn.detail" />
							</button>
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			) : (
				<Fragment>
					<button
						name="general"
						className="btn btn-block btn-primary"
						onClick={(e) => props.onClickDetail(row._id)}
					>
						<i class="fas fa-eye" />
					</button>
				</Fragment>
			)
		}
	}
]

export const columnsToExport = [
	{ title: 'Email', value: (row) => row.email },
	{ title: 'Rol', value: (row) => row.rol },
	{ title: 'IS', value: (row) => row.is },
	{ title: 'Telefono', value: 'phone' },
	{ title: 'Sede', value: (row) => row.sede.name },
	{ title: 'Subsede', value: (row) => row.subsede.name },
	{ title: 'Activo', value: (row) => (row.active ? 'Sí' : 'No') }
]
