import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Pie, Line } from 'react-chartjs-2'
//import socket from '../../../../../socket'
//import { FormattedMessage, injectIntl } from 'react-intl'
import { injectIntl } from 'react-intl'
export class ChartsRow extends Component {
  render() {
    const {
      intl: { formatMessage }
    } = this.props
    const { line, pie } = this.props.socket.charts
    return (
      <div className='row'>
        <div className='col-xl-8 col-lg-7'>
          {/* <!-- Area Chart --> */}
          <div className='card shadow mb-4' style={{ height: '95%' }}>
            <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
              <h6 className='m-0 font-weight-bold text-primary'>
                {formatMessage({ id: 'dash.lineChart' })}
              </h6>
            </div>
            <div className='card-body'>
              <div className='chart-area'>
                <Line
                  data={line}
                  width={800}
                  height={300}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true
                  }}
                />
              </div>
              <hr />
            </div>
          </div>
        </div>

        {/* <!-- lDonut Chart --> */}
        <div className='col-xl-4 col-lg-5'>
          <div className='card shadow mb-4' style={{ height: '95%' }}>
            {/* <!-- Card Header - Dropdown --> */}
            <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
              <h6 className='m-0 font-weight-bold text-primary'>
                {formatMessage({ id: 'dash.pieCHart' })}
              </h6>
            </div>
            {/* <!-- Card Body --> */}
            <div className='card-body'>
              <div className='chart-pie pt-4 pb-2'>
                <Pie
                  data={pie}
                  width={350}
                  height={350}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true
                  }}
                />
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ChartsRow.propTypes = {
  socket: PropTypes.object.isRequired
}

const mapStateToProps = ({ socket, auth }) => ({ socket, auth })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChartsRow))
