import React from 'react'
import { isEmpty } from './../../../util/isEmpty'
import { FormattedMessage } from 'react-intl';

export default [
  {
    field: 'project',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  }
]