import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, Col, Row } from 'reactstrap'
import { updateCloseCampaing } from '../../../../redux/actions/campaingActions'
import { injectIntl } from 'react-intl'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import { FormattedMessage } from 'react-intl'
import UploadFiles from '../../../common/Upload/UploadFiles'

export class ModalUpdateClosure extends Component {
  state = {
    closure_evidence: null
  }

  handleChange = ({ name, value }) => {
    this.setState({ [name]: value })
  }

  handleValidSubmit = (event, values) => {
    if (this.state.closure_evidence) {
      const { _id } = this.props.project
      const payload = { ...values, ...this.state, completeDate: Date.now() }
      this.props.updateCloseCampaing(_id, payload, this.props.intl)
      this.props.toggle('modalUpdate')
    }
  }

  render() {
    const { formatMessage } = this.props.intl
    const currency = this.props.project.sede ? this.props.project.sede.currency : ''
    return (
      <Modal
        size='lg'
        isOpen={this.props.state.modalUpdate}
        toggle={() => this.props.toggle('modalUpdate')}
        style={{ padding: 25 }}>
        <ModalHeader toggle={() => this.props.toggle('modalUpdate')}>
          <FormattedMessage id='label.finalizeProject' /> {this.props.project.title}
        </ModalHeader>

        <ModalBody>
          <AvForm onValidSubmit={this.handleValidSubmit}>
            <AvField
              name='closure_actions'
              label={<FormattedMessage id='label.actionsCarriedOut' />}
              type='textarea'
              errorMessage={formatMessage({ id: 'help.required' })}
              validate={{
                required: { value: true },
                minLength: { value: 1 },
                maxLength: { value: 300 }
              }}
            />
            <AvField
              name='closure_collaborators'
              label={<FormattedMessage id='label.volunteerColla' />}
              type='number'
              errorMessage={formatMessage({ id: 'help.required' })}
              validate={{ required: { value: true } }}
            />
            <Row>
              <Col md={6}>
                <AvField
                  name='closure_volunteers'
                  label={<FormattedMessage id='label.externalVolunters' />}
                  type='number'
                  errorMessage={formatMessage({ id: 'help.required' })}
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col md={6}>
                <AvField
                  name='closure_volunteeringHours'
                  label={<FormattedMessage id='label.volunterHours' />}
                  type='number'
                  errorMessage={formatMessage({ id: 'help.required' })}
                  validate={{ required: { value: true } }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <AvField
                  name='closure_inversion'
                  label={`${formatMessage({ id: 'label.investment' })} - ${currency}`}
                  type='number'
                  errorMessage={formatMessage({ id: 'help.required' })}
                  validate={{ required: { value: true } }}
                />
              </Col>
              <Col md={6}>
                <AvField
                  name='closure_benefited'
                  label={<FormattedMessage id='label.benefit' />}
                  type='number'
                  errorMessage={formatMessage({ id: 'help.required' })}
                  validate={{ required: { value: true } }}
                />
              </Col>
            </Row>
            <AvField
              name='closure_comments'
              label={<FormattedMessage id='label.comments' />}
              type='textarea'
            />

            <FormattedMessage id='label.evidence' />
            <UploadFiles
              url={'/api/file'}
              file={file => this.handleChange({ name: 'closure_evidence', value: file._id })}
              onupdatefiles={files => {
                if (files.length === 0)
                  this.handleChange({ name: 'closure_evidence', value: null })
              }}
            />
            <span className={this.state.closure_evidence ? 'd-none' : 'text-danger'}>
              {formatMessage({ id: 'help.required' })}
            </span>

            <hr />
            <Button color='success' type='submit' className='btn-block'>
              <FormattedMessage id='label.finalize' />
            </Button>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth, location, campaign }) => ({ auth, location, campaign })
const mapDispatchToProps = { updateCloseCampaing }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModalUpdateClosure))
