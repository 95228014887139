import axios from 'axios'
import Select from 'react-select'
import React, { Component, Fragment } from 'react'
import { isEmpty } from '../../../util/isEmpty'
export default class SelectData extends Component {

  state = {
    options: [],
    data: null,
    defaultValue: null,
    defaultInputValue: ''
  }

  map = list => list.filter(r => r.active).map(r => ({ label: r.name, value: r._id }))

  componentWillMount() {
    console.log('componentWillMount')
    const route = `/${this.props.route}`
    axios
      .get(route)
      .then(res => {
        const options = this.map(res.data)
        if (!isEmpty(this.props.defaultValue)) {
          const df = this.props.defaultValue
          const current = options.find(o => df === o.value)
          const defaultValue = current
          const defaultInputValue = current.label
          this.setState({ options, defaultValue, defaultInputValue })

        }
        this.setState({ options })
        this.props.theOptions = options
      })
      .catch(err => console.log(err))
  }

  // @return       Object.
  // @description  handel the <Select /> Compoenet.
  // and pass up name and value .
  onChange = item => this.props.onChange({ name: this.props.name, value: item })

  customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: "red",
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "red"
      }
    })
  }

  render() {
    const { options, name } = this.state
    const { valid } = this.props
    const customStyles = {
      control: (base, state) => ({
        ...base,
        // state.isFocused can display different borderColor if you need it
        borderColor: state.isFocused ?
          '#ddd' : valid ?
            '#ddd' : 'red',
        // overwrittes hover style
        '&:hover': {
          borderColor: state.isFocused ?
            '#ddd' : valid ?
              '#ddd' : 'red'
        }
      })
    }

    return (
      <Fragment>
        <Select
          styles={
            !isEmpty(valid) &&
            valid === false &&
            customStyles}
          options={options}
          name={name}
          value={this.props.selectedValue}
          onChange={this.onChange} />
      </Fragment >
    )
  }
}