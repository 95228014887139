import React from 'react'
import transparency from './img/valores-01.svg'
import commitment from './img/valores-02.svg'
import synergy from './img/valores-03.svg'
import trust from './img/valores-04.svg'
import styles from './values-info-section.module.css'
import { FormattedMessage } from 'react-intl'
import ValuesIcons from './ValuesIcons/ValuesIcons'
import ContentWrapper from '../../../common/ContentWrapper/ContentWrapper';

const info = [
  { icon: transparency, title: <FormattedMessage id="values.transparency" />, text: <FormattedMessage id="values.transTxt" />, isActive: true },
  { icon: commitment, title: <FormattedMessage id="values.commitment" />, text: <FormattedMessage id="values.commTxt" />, isActive: true },
  { icon: synergy, title: <FormattedMessage id="values.synergy" />, text: <FormattedMessage id="values.synTxt" />, isActive: true },
  { icon: trust, title: <FormattedMessage id="values.trust" />, text: <FormattedMessage id="values.trustTxt" />, isActive: true }
]


const ValuesInfoSection = () => (
  <section>
    <div className={`testimonials text-center bg-white ${styles['fondoPlusValues']}`} >
      <ContentWrapper
        className='my-0'
        pretitle={<FormattedMessage id="values.pillar" />}
        title={<FormattedMessage id="values.title" />}
        pretitleClass={`${styles['titles']}`}
        titleClass={`${styles['pretitles']}`}
      />
      <div className={`container`} >
        <div className='row'>
          {
            info.map((c, i) => (
              <div className={`col-md-6`}>
                <ValuesIcons key={c.label + '-' + i} {...c} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  </section>
);

export default ValuesInfoSection