import React from 'react'

import styles from './tipography.module.css'

export const Pretitle = ({className = '', children, ...props}) => {
  return (
    <h3 className={`${styles['pretitle']} ${className} mt-3 mb-5`} {...props} >
      {children}
    </h3>
  )
}

export const Title = ({className = '', children, ...props}) => {
  return (
    <h1 className={`${styles['title']} ${className} my-4`} {...props} >
      {children}
    </h1>
  )
}

export const Subtitle = ({className = '', children, ...props}) => {
  return (
    <h2 className={`${styles['subtitle']} ${className} txtPrograms my-4`} {...props} >
      {children}
    </h2>
  )
}


export const Paragraph = ({className = '', children, ...props}) => {
  return (
    <p className={`${styles['content']} lead txt ${className} mt-4 mb-5`} {...props} >
      {children}
    </p>
  )
}