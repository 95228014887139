import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import { createStore, applyMiddleware, compose } from 'redux'
import * as sagas from './sagas'

function initSagas(middleware) {
  Object.values(sagas).forEach(middleware.run.bind(middleware))
}

export const history = createBrowserHistory()
export const saga = createSagaMiddleware()

function getStore() {
  const initialState = {}
  const middleware = [routerMiddleware(history), thunk, saga]
  const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    process.env.REACT_APP_ENV !== 'production'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose

  const enhancer = composeEnhancers(applyMiddleware(...middleware))
  const rootReducer = createRootReducer(history)
  const store = createStore(rootReducer, initialState, enhancer)

  initSagas(saga)

  return store
}

export default getStore
