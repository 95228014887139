import React from 'react'
import UploadFiles from './../../common/Upload/UploadFiles'
import { FormGroup, Button } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import FormGroupCustom from './../../Campaign/lib/FormGroupCustom'

// const AvUploadFiles = props => (<AvField {...props} tag={UploadFiles} />)

const VideoForm = ({ handleChangeSelectData, state }) => {
  const { video } = state
  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            <FormattedMessage id='video.public' />
          </h6>
        </div>
        <div className="card-body table-responsive py-0 my-0">

          <FormGroupCustom forp='video' label={''} {...video}>
            <UploadFiles
              url={'/api/video'}
              file={file => handleChangeSelectData({ name: 'video', value: file._id })} />
          </FormGroupCustom>
  
          <FormGroup>
            <Button type="submit" className='btn btn-block'>
              <FormattedMessage id='label.upload' />
            </Button>
          </FormGroup>

        </div>
      </div>
    </div>
  )
}

export default VideoForm
