import React, { Component } from 'react'
import { connect } from 'react-redux'
import Admin from './../Layout/Admin/Admin'
import PropTypes from 'prop-types'
import {
  getAllCampaingAdmin,
  getCampaing,
  postCampaing,
  updateCloseCampaing,
  updateCampaing,
  editCampaing,
  getCleanCampaing,
  editCleanCampaing,
  getAllCleanCampaing,
  setVolunteersCampaing,
  setGallerieCampaing,
  activateCampaing
} from '../../redux/actions/campaingActions'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
// Hepers
import { formatToTable } from './Helpers/report'
import TableWrapper from './../common/TableWrapper'
import { expandRow } from './../../util/tableHelpers'
import { isEmpty } from '../../util/helpers'
import { tableOptions, typeOptions } from './Helpers/tableHelpers'
import { ModalUpdateClosure } from './component/Modals/ModalUpdateClosure'
import { FormattedMessage, injectIntl } from 'react-intl'

// Global variables
const { SearchBar } = Search

class CampaingList extends Component {
  handleView = id => this.props.getCampaing(id, this.props.intl)
  handleEdit = id => this.props.editCampaing(id)
  handleDelete = n =>
    this.props.activateCampaing(
      n._id,
      { active: !n.active },
      this.props.history,
      this.props.intl
    )

  // Methods for the modal to load with data and toggle
  toggle = prop => this.setState({ [prop]: !this.state[prop] })

  handleSubscribers = project => this.props.setVolunteersCampaing(project)

  handleCloseProject = n => {
    this.setState({ project: n })
    this.toggle('modalUpdate')
  }

  handleViewGallerie = project => {
    this.props.setGallerieCampaing(project)
  }

  // Application's state
  state = {
    project: {},
    projects: [],
    modalUpdate: false,
    modalViewSubscribers: false
  }

  componentDidMount() {
    this.props.getAllCampaingAdmin(this.props.intl)
  }

  componentWillReceiveProps(np) {
    if (!isEmpty(np.campaing.list)) {
      this.setState({ projects: formatToTable(np.campaing.list) })
    }
    if (!isEmpty(np.campaing.view)) {
      const id = np.campaing.view._id
      this.props.history.push(`/campaign/gallerie/${id}`)
    }
    if (!isEmpty(np.campaing.edit)) {
      const id = np.campaing.edit._id
      this.props.history.push(`/dashboard/campaign/edit/${id}`)
    }
    if (!isEmpty(np.campaing.volunteers)) {
      const id = np.campaing.volunteers._id
      np.history.push(`/dashboard/campaign/volunteers/${id}`)
    }
    if (!isEmpty(np.campaing.gallerie)) {
      const id = np.campaing.gallerie._id
      np.history.push(`/dashboard/campaign/gallerie/${id}`)
    }
  }

  componentWillUnmount() {
    this.props.getAllCleanCampaing()
  }

  render() {
    const {
      state: { projects }
    } = this
    const columns = tableOptions(this.props.intl.formatMessage, typeOptions, { ...this })
    return (
      <Admin>
        <ToolkitProvider
          keyField='_id'
          data={projects}
          columns={columns}
          bootstrap4={true}
          search={{
            searchFormatted: true
          }}>
          {props => (
            <TableWrapper
              title={<FormattedMessage id='label.list' />}
              label={<FormattedMessage id='label.listProject' />}>
              <SearchBar {...props.searchProps} />
              <BootstrapTable
                {...props.baseProps}
                filter={filterFactory()}
                pagination={paginationFactory()}
                expandRow={expandRow}
                className='table-responsive'
                bordered
                hover
              />
            </TableWrapper>
          )}
        </ToolkitProvider>
        <ModalUpdateClosure
          {...this}
          {...this.props}
          {...this.state}
          project={this.state.project}
        />
      </Admin>
    )
  }
}

CampaingList.propTypes = {
  auth: PropTypes.object.isRequired,
  campaing: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth, campaing }) => ({ auth, campaing })
const mapDispatchToProps = {
  getAllCampaingAdmin,
  getCampaing,
  postCampaing,
  setVolunteersCampaing,
  updateCampaing,
  editCampaing,
  updateCloseCampaing,
  getCleanCampaing,
  editCleanCampaing,
  getAllCleanCampaing,
  activateCampaing,
  setGallerieCampaing
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaingList))
