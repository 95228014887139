
import { ISOtoStandard } from './../../../util/dateHelpers'

export const mergeState = (state, data) => {
  const result = {}
  const keys = Object.keys(state)
  keys.forEach(k => {
    let p = state[k]
    if ((p !== null)
        && (p.hasOwnProperty('value')
        && p.hasOwnProperty('valid')
        && p.hasOwnProperty('text')
        && p.hasOwnProperty('feed'))){
        // Dates format
        if(k === 'publishDate' || k === 'endDate'){
          result[k] = { ...p, value: ISOtoStandard(data[k]) }
        } else if(k === 'sede' || k === 'subsede' || k === 'thumbnail') {
          result[k] = { ...p, value: data[k]._id }
        } else {
          result[k] = { ...p, value: data[k] }
        }
    }
  })
  return result
}