import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getConvocatory, getCleanConvocatory } from './../../redux/actions/convocatoryActions'
import Navbar from '../Main/Common/Navbar/Navbar'
import Footer from '../Main/Common/Footer/Footer'
import Img from './../../components/common/ImgPreview'
import { isEmpty } from './../../util/isEmpty'
import { dmy } from './../../util/dateHelpers'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import SubscribeModal from './forms/SubscribeModal'
import { Button } from 'reactstrap'
import { injectIntl } from 'react-intl'
import ProjectStatus from './../Campaign/component/ProjectStatus/ProjectStatus'
import Breadcrumb from '../Main/Common/Breadcrumb/Breadcrumb'
import ShareBar from '../SocialMedia/ShareBar'

export class Convocatory extends Component {
	state = {
		modal: false
	}

	componentDidMount() {
		const { id } = this.props.match.params
		this.props.getConvocatory(id, this.props.intl)
	}

	componentWillReceiveProps(np) {
		if (isEmpty(np.convocatory.view)) {
			const { id } = this.props.match.params
			this.props.getConvocatory(id, this.props.intl)
		}
	}

	componentWillUnmount() {
		this.props.getCleanConvocatory()
	}

	toggle = () => this.setState({ modal: !this.state.modal })

	render() {
		const { view } = this.props.convocatory
		const message = `Amigos, apoyemos la convocatoria "${view.title}" desde esta página.`
		const shareUrl = window.location.href

		return (
			<Fragment>
				<Navbar />
				{isEmpty(view) ? (
					<h1>
						<FormattedMessage id="general.loading" />
					</h1>
				) : (
					<div className="contenedorProject">
						<Breadcrumb {...view} link="/convocatory/gallerie" id="convocatory.gall" />
						<div className="row">
							<div className="col-md-9">
								<h1 className="mt-4 mb-3 subtitleProject" style={{ wordBreak: 'break-word' }}>
									{view.title}
								</h1>
							</div>
							<div className="col-md-2 offset-md-1 d-flex align-items-center">
								<Button className="buttonConvocatory" color="" size="sm" block onClick={this.toggle}>
									<FormattedMessage id="btn.register" />
								</Button>
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-lg-8 mb-4 imgConvocatory">
								<Img {...view.thumbnail} />
								<ul className="list-inline mb-0 listaEtiquetas">
									<li className="list-inline-item">
										<i className="fas fa-globe" />
									</li>
									<li className="list-inline-item txtProject">
										<Link to="/">{view.sede.name}</Link>,
										<Link to="/"> {view.subsede.name}</Link>
									</li>
									<li className="list-inline-item">
										<i className="far fa-calendar-alt" />
									</li>
									<li className="list-inline-item txtProject">{dmy(view.startDate)} -</li>
									<li className="list-inline-item txtProject">{dmy(view.endDate)}</li>
									<li className="list-inline-item">
										<ProjectStatus status={view.status} />
									</li>
									<li className="list-inline-item">
										<ShareBar shareUrl={shareUrl} message={message} />
									</li>
								</ul>
							</div>
							<div className="col-lg-12">
								<p className="lead titleProject" style={{ wordBreak: 'break-word' }}>
									{view.description}
								</p>
								<hr />
								<div
									className=" txtProject  "
									dangerouslySetInnerHTML={{ __html: view.content }}
									style={{ wordBreak: 'break-word' }}
								/>
							</div>
						</div>
						<h3 className="autor">
							<FormattedMessage id="label.author" />: {!isEmpty(view.created_by) && view.created_by.name}
						</h3>
					</div>
				)}
				<SubscribeModal {...this} {...this.props} item={view} />
				<Footer />
			</Fragment>
		)
	}
}

Convocatory.propTypes = {
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	getConvocatory: PropTypes.func.isRequired
}

const mapitemToProps = ({ auth, convocatory }) => ({ auth, convocatory })
const mapDispatchToProps = { getConvocatory, getCleanConvocatory }
export default connect(mapitemToProps, mapDispatchToProps)(injectIntl(Convocatory))
