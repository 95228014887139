const initialState = {
  type: null,
  message: null,
  timeout: 2000,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_NOTIFY':
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}