export function initConekta(client) {
  window.Conekta.setPublicKey(client)
}

export function getCardBrand(cardNumber) {
  return window.Conekta.card.getBrand(cardNumber)
}

export function validateCardNumber(cardNumber) {
  return window.Conekta.card.validateNumber(cardNumber)
}

export function validateCvc(cvc) {
  return window.Conekta.card.validateCVC(cvc)
}

export function validateExpirationDate(expiryMonth, expiryYear) {
  return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`)
}

export function tokenize(
  { number, name, exp_month, exp_year, cvc },
  successCallback,
  errorCallback
) {
  const tokenParams = {
    card: { number, name, exp_year, exp_month, cvc }
  }
  window.Conekta.Token.create(tokenParams, successCallback, errorCallback)
}
