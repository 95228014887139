import React from 'react'

const CollapsibleCard = ({ label, id, className, children, expanded = true }) => {
  return (
    <div className={className}>
      <div className='card shadow mb-4'>
        <a
          href={`#${id}`}
          className='d-block card-header py-3'
          data-toggle='collapse'
          role='button'
          aria-expanded='true'
          aria-controls={id}>
          <h6 className='m-0 font-weight-bold text-primary'>{label}</h6>
        </a>
        <div className={`collapse ${expanded ? 'show' : ''}`} id={id}>
          <div className='card-body'>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default CollapsibleCard
