import swal from '@sweetalert/with-react'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isEmpty } from '../../../../../../util/isEmpty'
import { postSF } from '../../../../../../redux/actions/solidaryfriendActions'
import styles from './button-amigo-solidario.module.css'
import { injectIntl, FormattedMessage } from 'react-intl'

export class ButtonAmigoSolidario extends Component {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    postSF: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  }

  state = { user: null }

  componentDidMount() {
    if (!isEmpty(this.props.auth)) {
      const { user } = this.props.auth
      this.setState({ user: user._id })
    }
  }

  componentWillReceiveProps(np) {
    if (!isEmpty(np.auth)) {
      const { user } = np.auth
      this.setState({ user: user._id })
    }
  }

  needLogin = history => history.push('/login')

  needInfo = history => {
    const { intl } = this.props
    swal(intl.formatMessage({ id: 'alert.profileValidation' }), ``, 'warning')
    history.push('/user')
  }

  register = () => {
    const { user } = this.state
    this.props.postSF({ user }, this.props.intl)
  }

  handleClick = () => {
    let click = null
    const { history } = this.props
    const { user, isAuthenticated } = this.props.auth
    if (!isAuthenticated) {
      click = this.needLogin(history)
    }
    else if ((isAuthenticated === true) && (
      isEmpty(user) ||
      isEmpty(user.is) ||
      isEmpty(user.sede) ||
      isEmpty(user.name) ||
      isEmpty(user.email) ||
      isEmpty(user.phone))) {
      click = this.needInfo(history)
    } else {
      click = this.register()
    }
    return click
  }

  // Si el rol es administrador, el botón de softtekian solidario no se muestra.
  // Esto se realizo ya que si se deshabilita la apariencia del botón es la misma
  // unicamente no sucede nada al dar click, lo que puede originar confusión en el usuario
  render() {
    return (
      <div className={`container`}>
        {
          (this.props.auth.user.rol === 'softtekian' || isEmpty(this.props.auth.user)) &&
          <button
            className={styles['buttonAS']}
            onClick={this.handleClick}
          >
            <FormattedMessage id="dare.button" />
          </button>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ auth, location }) => ({ auth, location })
const mapDispatchToProps = { postSF }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ButtonAmigoSolidario)))
