import React, { Component } from 'react'
import { connect } from 'react-redux'
import Admin from './../Layout/Admin/Admin'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import swal from '@sweetalert/with-react'
import {
  getCleanVolunteersCampaing,
  getVolunteersCampaing,
  unsubscribeCampaingAdmin
} from '../../redux/actions/campaingActions'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter, dateFilter } from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
// Hepers
import TableWrapper from './../common/TableWrapper'
import { dmy } from './../../util/dateHelpers'
import { isEmpty } from '../../util/helpers'
import { FormattedMessage, injectIntl } from 'react-intl'
import { expandRow } from './../../util/tableHelpers'

class CampaingVolunteers extends Component {
  handleUnsubscribe = volunteer => {
    const { id } = this.props.match.params
    const { intl } = this.props
    swal({
      title: intl.formatMessage({ id: 'alert.confiTitle' }),
      text: intl.formatMessage({ id: 'alert.registryDelete' }),
      icon: 'warning',
      buttons: true
    }).then(confirm => {
      confirm && this.props.unsubscribeCampaingAdmin(id, { user: volunteer.user._id }, intl)
    })
  }

  // handleContact = subscriber => {
  //   const { id } = this.props.match.params
  //   const { intl } = this.props
  //   swal({ title: `${intl.formatMessage({id: 'alert.suscriber'})} ${subscriber.name}?`, content: 'input', text: intl.formatMessage({id: 'alert.confirmation'}), icon: 'info', buttons: true })
  //     .then(confirm => confirm && this.props.contactCampaing(id, { subscriber: subscriber._id }, intl))
  // }

  // contactedOptions = {
  //   true: this.props.intl.formatMessage({id:"general.confirmation"}),
  //   false: this.props.intl.formatMessage({id:"general.negation"})
  // }

  // Columns configurations
  columns = [
    {
      dataField: 'user.name',
      text: this.props.intl.formatMessage({ id: 'label.name' }),
      sort: true,
      filter: textFilter()
    },
    {
      dataField: 'user.phone',
      text: this.props.intl.formatMessage({ id: 'label.phone' }),
      sort: true
      // filter: textFilter()
    },
    {
      dataField: 'user.email',
      text: this.props.intl.formatMessage({ id: 'label.email' }),
      sort: true
      // filter: textFilter()
    },
    // {
    //   dataField: 'contacted',
    //   text: this.props.intl.formatMessage({id:"alert.contactConvocatory"}),
    //   filter: selectFilter({
    //     options: this.contactedOptions
    //   }),
    //   formatter: (cell => this.contactedOptions[cell])
    // },
    {
      dataField: 'createdAt',
      text: this.props.intl.formatMessage({ id: 'label.dateRegistry' }),
      sort: true,
      formatter: dmy,
      filter: dateFilter(),
      csvFormatter: dmy
    },
    {
      dataField: '_id',
      text: this.props.intl.formatMessage({ id: 'label.continue' }),
      sort: true,
      hidden: this.props.auth.user.rol === 'reporter',
      formatter: (cellContent, row) => (
        <div>
          {/* <Button
            color="success"
            disabled={cellContent}
            className="mr-3"
            onClick={() => this.handleContact(row)}>
            <i className="fa fa-phone"></i>
          </Button> */}
          {/* {!cellContent && */}
          <Button color='danger' onClick={() => this.handleUnsubscribe(row)}>
            <i class='fa fa-trash'></i>
          </Button>
        </div>
      )
    }
  ]

  // LIFECYCLES METHODS

  componentDidMount() {
    if (isEmpty(this.props.campaing.volunteers)) {
      const { id } = this.props.match.params
      this.props.getVolunteersCampaing(id)
    }
  }

  componentWillUnmount() {
    this.props.getCleanVolunteersCampaing()
  }

  // RENDER METHOD

  render() {
    const { volunteers } = this.props.campaing
    const data = volunteers.volunteers ? volunteers.volunteers : []
    return (
      <Admin>
        <ToolkitProvider
          keyField='_id'
          data={data}
          columns={this.columns}
          bootstrap4={true}
          // search={{
          //   searchFormatted: true
          // }}
          // exportCSV={{
          //   fileName: `ProyectosFS-${today()}.csv`,
          //   separator: ',',
          //   noAutoBOM: false
          // }}
        >
          {props => (
            <TableWrapper
              title={`${volunteers.title}`}
              label={<FormattedMessage id='label.listSuscribe' />}>
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                filter={filterFactory()}
                expandRow={expandRow}
                className='table-responsive'
                bordered
                hover
              />
              {/* <MyExportCSV { ...props.csvProps }/> */}
            </TableWrapper>
          )}
        </ToolkitProvider>
      </Admin>
    )
  }
}

CampaingVolunteers.propTypes = {
  auth: PropTypes.object.isRequired,
  campaing: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth, campaing }) => ({ auth, campaing })
const mapDispatchToProps = {
  getCleanVolunteersCampaing,
  getVolunteersCampaing,
  unsubscribeCampaingAdmin
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaingVolunteers))
