import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Alert, Button } from 'reactstrap'
import { petitionDeactivateSF, petitionActivateSF } from './../../redux/actions/solidaryfriendActions'
import { BreadCrumb, InformationColumn, BadgeList } from './common/Profile'
import { injectIntl, FormattedMessage } from 'react-intl'
import { isEmpty } from './../../util/isEmpty'
import Pagination from './../common/GeneralPagination'
import { Badge } from 'reactstrap'
import { Link } from 'react-router-dom'

const ActivateSF = ({ user, handleRedirect, handleActivate }) => (
  <Button onClick={() => handleActivate(user._id)} className='mb-3 btn btn-block'>
    <i class="fas fa-heart"></i> <FormattedMessage id='label.joinSF' />
  </Button>
)

const DeactivateSF = ({ user, handleDeactivate }) => (
  <Button onClick={() => handleDeactivate(user._id)} className='mb-3 btn btn-block'>
    <i class="fas fa-sign-out-alt"></i> <FormattedMessage id='label.leftSF' />
  </Button>
)

class UserPerfil extends Component {

  // Handle cuando fue softtekian solidario y quiere volver a serlo
  handleActivate = id => this.props.petitionActivateSF(id, this.props.intl)

  // Handle cuando es softtekian solidario pero quiere darse de baja
  handleDeactivate = id => this.props.petitionDeactivateSF(id, this.props.intl)

  render() {
    const { user } = this.props.auth
    const { formatMessage } = this.props.intl
    const data = !isEmpty(user.subscriptions) ? user.subscriptions.map(s => s.project) : []

    const listSubscriptions = data.map(d => {
      return (
        <div className='col-lg-4 col-md-6 my-2'>
          <Link to={`/campaign/gallerie/${d._id}`}>
            <Badge className='p-3' color='primary' style={{width: '100%'}}>
              {d.title}
            </Badge>
          </Link>
        </div>
      )
    })

    return (
      <Fragment>
        <Row style={{ width: '100%' }}>
          <Col md={12}>
            <h4 className="my-3 text-left">{user.name}</h4>
          </Col>
          <Col className="mt-2" md={6}>
            <Row>
              {/* Información de locaclización del usuario */}
              <InformationColumn className="titlePages" label={formatMessage({id: 'label.sede'})} value={user.sede_name} size={4} />
              <InformationColumn label={formatMessage({id: 'label.subsede'})} value={user.subsede_name} size={4} />
              <InformationColumn label={formatMessage({id: 'label.is'})} value={user.is} size={4} />
            </Row>
            <hr />
            <Row>
              {/* Información de contacto del usuario */}
              <InformationColumn label={formatMessage({id: 'label.email'})} value={user.email} size={8} />
              <InformationColumn label={formatMessage({id: 'label.phone'})} value={user.phone} size={4} />
            </Row>
            <hr />
            {/* Breadcrumbs: # de causas, actividades y habilidades */}
            <div className="d-flex justify-content-between my-4">
              <BreadCrumb className="fa fa-user" label={formatMessage({id: 'label.cause'})} value={user.causes} />
              <BreadCrumb className="fa fa-cog" label={formatMessage({id: 'label.activitie'})} value={user.activities} />
              <BreadCrumb className="fa fa-eye" label={formatMessage({id: 'label.skill'})} value={user.skills} />
            </div>
          </Col>
          <Col className="mt-2" md={6}>
            {/* Lista de habilidades, actividades y causas que apoya el usuario */}
            <BadgeList label={formatMessage({id: 'label.skill'})} list={user.skills} />
            <BadgeList label={formatMessage({id: 'label.activitie'})} list={user.activities} />
            <BadgeList label={formatMessage({id: 'label.cause'})} list={user.causes} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={6}>
            {
              user.isFriend &&
              <Alert color='danger'>
                <i class="fas fa-heart"></i> <FormattedMessage id='label.userSF' />
              </Alert>
            }
          </Col>
          <Col md={6}>
            {user.isFriend === false && <ActivateSF user={user} {...this} />}
            {user.isFriend === true && <DeactivateSF user={user} {...this} />}
          </Col>
        </Row>
        <Row>
          <h5>
            <FormattedMessage id='dashboard.projects' />
          </h5>
        </Row>
        <Pagination
          className='row'
          data={listSubscriptions}
          sizePerPage={6}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = { petitionDeactivateSF, petitionActivateSF }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserPerfil))
