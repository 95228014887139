import { createReducer } from 'reduxsauce'
import { createActions } from 'reduxsauce'

const { Creators, Types } = createActions(
  {
    alertMessageSuccess: ['payload'],
    alertMessageWarning: ['payload'],
    alertMessageError: ['payload'],
    alertPromptShow: ['payload'],
    alertPromptHide: ['payload'],
    alertClear: ['payload']
  },
  {}
)

const initialState = {
  justification: '',
  success: '',
  warning: '',
  error: '',
  prompt: false
}

export const HANDLERS = {
  [Types.ALERT_MESSAGE_SUCCESS]: (_, action) => ({ ...initialState, success: action.payload }),
  [Types.ALERT_MESSAGE_WARNING]: (_, action) => ({ ...initialState, warning: action.payload }),
  [Types.ALERT_MESSAGE_ERROR]: (state, action) => ({ ...initialState, error: action.payload }),
  [Types.ALERT_PROMPT_SHOW]: state => ({ ...initialState, prompt: true }),
  [Types.ALERT_PROMPT_HIDE]: (_, action) => ({
    ...initialState,
    prompt: false,
    justification: action.payload
  }),
  [Types.ALERT_CLEAR]: () => ({ ...initialState })
}

export const alertTypes = Types
export const alertActions = Creators
export const alert = createReducer(initialState, HANDLERS)
