import axios from 'axios'
import swal from '@sweetalert/with-react'
import {
  // GET_CONTACT,
  GET_ERRORS,
  UPDATE_INITIATIVE,
  GET_All_INITIATIVE
} from './types'
import { loadingPage } from './loadingpageAction'

// GET ALL INIATIATIVE
export const getAllInitiative = () => dispatch => {
  dispatch(loadingPage())
  axios
    .get('/api/initiative')
    .then(r => dispatch({ type: GET_All_INITIATIVE, payload: r.data }))
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// UPDATE INITIATIVE
export const updateInitiative = (id, d, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .put(`/api/initiative/${id}`, d)
    .then(r => dispatch({ type: UPDATE_INITIATIVE, payload: r.data }))
    .then(() => swal(intl.formatMessage({ id: 'table.update' }), '', 'success'))
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// CLEAN THE INITIATIVE
export const getAllCleanInitiative = () => ({
  type: GET_All_INITIATIVE,
  payload: []
})


