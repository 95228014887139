import axios from "axios"
import swal from "@sweetalert/with-react"
import socket from "./../../socket"
import {
  currentStatus,
  isClosed,
  removeCharFromDate,
  getStatus,
} from "./../../components/Campaign/Helpers/projectStatus"
import { isEmpty } from "../../util/isEmpty"
import { getSubscriptionsUser } from "./authActions"
import { loadingPage } from "./loadingpageAction"
import {
  GET_ERRORS,
  GET_CAMPAING,
  POST_CAMPAING,
  EDIT_CAMPAING,
  UPDATE_CAMPAING,
  GET_All_CAMPAING,
  GET_CLOSE_CAMPAING,
  GET_CLEAN_CAMPAING,
  GET_REPORT_CAMPAING,
  EDIT_CLEAN_CAMPAING,
  UPDATE_CLOSE_CAMPAING,
  ADD_GALLERIE_CAMPAING,
  GET_GALLERIE_CAMPAING,
  GET_All_CLEAN_CAMPAING,
  REMOVE_GALLERIE_CAMPAING,
  GET_VOLUNTEERS_CAMPAING,
} from "./types"

// GET ALL CAMPAINGS (ADMIN)
export const getAllCampaingAdmin = (intl) => (dispatch) => {
  dispatch(loadingPage())
  axios
    .get("/api/projectadmin")
    .then((res) => {
      const payload = res.data.map((r) => ({
        ...r,
        status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(
          r.startDate,
          r.endDate,
          r.closed,
          r.active
        ),
      }))
      dispatch({ type: GET_All_CAMPAING, payload })
    })
    .then(dispatch(loadingPage()))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET ALL CAMPAINGS (USER)
export const getAllCampaingUser = (intl) => (dispatch) => {
  dispatch(loadingPage())
  axios
    .get("/api/project")
    .then((res) => {
      const payload = res.data.map((r) => ({
        ...r,
        status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(
          r.startDate,
          r.endDate,
          r.closed,
          r.active
        ),
      }))
      dispatch({ type: GET_All_CAMPAING, payload })
    })
    .then(dispatch(loadingPage()))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET ALL CAMPAINGS (PUBLISHED, COMPLETED AND FINISHED) (USER)
export const getAllCampaingHistorical = (intl) => (dispatch) => {
  dispatch(loadingPage())
  axios
    .get("/api/projecthistorical")
    .then((res) => {
      const payload = res.data.map((r) => ({
        ...r,
        status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(
          r.startDate,
          r.endDate,
          r.closed,
          r.active
        ),
      }))
      dispatch({ type: GET_All_CAMPAING, payload })
    })
    .then(dispatch(loadingPage()))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

export const getCampaingsByCategorie = () => (dispatch) => {
  // dispatch(loadingPage())
  axios
    .get("/api/project/category/COVID-19")
    .then((res) => {
      dispatch({ type: GET_All_CAMPAING, payload: res.data })
    })
    // .then(dispatch(loadingPage()))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET A CAMPAING
// error con el estatus
export const getCampaing = (id, intl) => (dispatch) => {
  dispatch(loadingPage())
  axios
    .get(`/api/project/${id}`)
    .then((r) => {
      const payload = {
        ...r.data,
        status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(
          r.data.startDate,
          r.data.endDate,
          r.data.closed,
          r.data.active
        ),
      }
      dispatch({ type: GET_CAMPAING, payload })
    })
    .then(dispatch(loadingPage()))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET A CAMPAING TO EDIT
export const editCampaing = (id) => (dispatch) => {
  dispatch(loadingPage())
  axios
    .get(`/api/project/${id}`)
    .then((r) => dispatch({ type: EDIT_CAMPAING, payload: r.data }))
    .then(dispatch(loadingPage()))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// POST CAMPAING
export const postCampaing = (d, history, intl) => (dispatch) => {
  dispatch(loadingPage())
  axios
    .post(`/api/project`, d)
    .then((r) => dispatch({ type: POST_CAMPAING, payload: r.data }))
    .then(dispatch(loadingPage()))
    .then(() =>
      swal({
        title: intl.formatMessage({ id: "alert.projectCreate" }),
        text: "",
        icon: "success",
        closeOnClickOutside: false,
        closeOnEsc: false,
      })
    )
    .then((go) => (go ? history.push("/campaign/gallerie") : ""))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// UPDATE CAMPAING
export const updateCampaing = (id, d, history, intl) => (dispatch) => {
  swal({
    title: intl.formatMessage({ id: "alert.desTitle" }),
    icon: "warning",
    buttons: {
      error: {
        text: intl.formatMessage({ id: "alert.causeErrorText" }),
        value: intl.formatMessage({ id: "alert.causeErrorText" }),
      },
      actualizacion: {
        text: intl.formatMessage({ id: "alert.upText" }),
        value: intl.formatMessage({ id: "alert.upText" }),
      },
    },
  }).then((cause) => {
    if (!isEmpty(cause)) {
      swal({
        title: intl.formatMessage({ id: "alert.desTitle" }),
        content: "input",
        buttons: true,
      })
        .then((justification) => {
          if (!isEmpty(justification)) {
            const detail = { cause, description: justification }
            axios.put(`/api/project/detail/${id}`, detail)
            dispatch(loadingPage())
            axios
              .put(`/api/project/${id}`, d)
              .then((r) => {
                const payload = {
                  ...r.data,
                  status: currentStatus(
                    isClosed,
                    removeCharFromDate,
                    getStatus,
                    intl
                  )(r.data.startDate, r.data.endDate, r.data.closed, r.data.active),
                }
                dispatch({ type: UPDATE_CAMPAING, payload })
              })
              .then(dispatch(loadingPage()))
              .then(() =>
                swal({
                  title: intl.formatMessage({ id: "alert.changes" }),
                  text: "",
                  icon: "success",
                  closeOnClickOutside: false,
                  closeOnEsc: false,
                })
              )
              .then((go) => (go ? history.push("/dashboard/campaign/list") : ""))
              .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
          } else {
            swal(
              intl.formatMessage({ id: "alert.changesOff" }),
              intl.formatMessage({ id: "alert.writeCause" }),
              "info"
            )
          }
        })
        .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
    }
  })
}

// ACTIVATE/DEACTIVATE CAMPAING
export const activateCampaing = (id, d, history, intl) => (dispatch) => {
  swal({
    title: intl.formatMessage({ id: "alert.confiTitle" }),
    text: `${intl.formatMessage({ id: "alert.confiText" })} ${
      d.active
        ? intl.formatMessage({ id: "alert.on" })
        : intl.formatMessage({ id: "alert.off" })
    }`,
    icon: "warning",
    buttons: true,
  })
    .then((confirmation) => {
      if (confirmation === true) {
        swal({
          title: intl.formatMessage({ id: "alert.desTitle" }),
          content: "input",
        }).then((justification) => {
          if (!isEmpty(justification)) {
            const detail = {
              cause: intl.formatMessage({ id: "alert.upText" }),
              description: justification,
            }
            axios.put(`/api/project/detail/${id}`, detail)
            dispatch(loadingPage())
            axios
              .put(`/api/project/${id}`, d)
              .then((r) => {
                const payload = {
                  ...r.data,
                  status: currentStatus(
                    isClosed,
                    removeCharFromDate,
                    getStatus,
                    intl
                  )(r.data.startDate, r.data.endDate, r.data.closed, r.data.active),
                }
                dispatch({ type: UPDATE_CAMPAING, payload })
              })
              .then(dispatch(loadingPage()))
              .then(() =>
                swal({
                  title: "",
                  text: d.active
                    ? intl.formatMessage({ id: "alert.registerActivate" })
                    : intl.formatMessage({ id: "alert.registerDeactivate" }),
                  icon: "success",
                })
              )
              .then((go) => (go ? history.push("/dashboard/campaign/list") : ""))
              .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
          } else {
            swal(
              intl.formatMessage({ id: "alert.changesOff" }),
              intl.formatMessage({ id: "alert.writeCause" }),
              "info"
            )
          }
        })
      }
    })
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// Agregar la información de cierre de campaña
export const updateCloseCampaing = (id, d, intl) => (dispatch) => {
  dispatch(loadingPage())
  swal({
    title: intl.formatMessage({ id: "alert.confiTitle" }),
    text: intl.formatMessage({ id: "alert.alertClose" }),
    icon: "warning",
    buttons: true,
  })
    .then((confirmation) => {
      if (confirmation) {
        axios
          .put(`/api/projectclosure/${id}`, d)
          .then((r) => dispatch({ type: UPDATE_CLOSE_CAMPAING, payload: r.data }))
          .then(() =>
            swal(intl.formatMessage({ id: "alert.projectComplete" }), "", "success")
          )
          .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
      }
      dispatch(loadingPage())
    })
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

export const getReportCampaing = (id, intl) => (dispatch) => {
  dispatch(loadingPage())
  axios
    .get(`/api/projectadmin/report/${id}`)
    .then((res) => {
      const payload = {
        ...res.data,
        cards: {
          ...res.data.cards,
          status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(
            res.data.startDate,
            res.data.endDate,
            res.data.closed,
            res.data.active
          ),
        },
      }
      dispatch({ type: GET_REPORT_CAMPAING, payload })
    })
    .then(dispatch(loadingPage()))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// Obtener información de cierre de campaña
export const getCloseCampaing = (id) => (dispatch) => {
  dispatch(loadingPage())
  axios
    .get(`/api/projectclosure/${id}`)
    .then((r) => dispatch({ type: GET_CLOSE_CAMPAING, payload: r.data }))
    .then(dispatch(loadingPage()))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// Suscribir un softtekian a un proyecto
export const subscribeCampaing = (id, d, intl) => (dispatch) => {
  axios
    .put(`/api/project/subscribe/${id}`, d)
    .then((res) => {
      swal({
        title: intl.formatMessage({ id: "alert.convocatoryTh" }),
        text: intl.formatMessage({ id: "alert.contactText" }),
        closeOnClickOutside: false,
        closeOnEsc: false,
        icon: "success",
      })
    })
    .then(() => dispatch(getSubscriptionsUser(d.user)))
    .then(() => socket.emit("subscribe", { project: id }))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// Desuscribir un softtekian a un proyecto como softtekian
export const unsubscribeCampaing = (id, d, intl) => (dispatch) => {
  axios
    .put(`/api/project/unsubscribe/${id}`, d)
    .then((res) => {
      swal({
        title: intl.formatMessage({ id: "alert.unsubscribed" }),
        closeOnClickOutside: false,
        closeOnEsc: false,
        icon: "success",
        text: "",
      })
    })
    .then(() => dispatch(getSubscriptionsUser(d.user)))
    .then(() => socket.emit("subscribe", { project: id }))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// Desuscribir un softtekian a un proyecto como administrador
export const unsubscribeCampaingAdmin = (id, d, intl) => (dispatch) => {
  axios
    .put(`/api/projectadmin/unsubscribe/${id}`, d)
    .then((res) => {
      swal({
        title: intl.formatMessage({ id: "alert.unsubscribed" }),
        closeOnClickOutside: false,
        closeOnEsc: false,
        icon: "success",
        text: "",
      })
      return res
    })
    .then((r) => dispatch({ type: GET_VOLUNTEERS_CAMPAING, payload: r.data }))
    .then(() => socket.emit("subscribe", { project: id }))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET VOLUNTEERS
export const getVolunteersCampaing = (id) => (dispatch) => {
  dispatch(loadingPage())
  axios
    .get(`/api/projectadmin/${id}`)
    .then((r) =>
      dispatch({
        type: GET_VOLUNTEERS_CAMPAING,
        payload: {
          _id: r.data._id,
          title: r.data.title,
          volunteers: r.data.volunteers,
        },
      })
    )
    .then(() => dispatch(loadingPage()))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET GALLERIE
export const getGallerieCampaing = (id) => (dispatch) => {
  dispatch(loadingPage())
  axios
    .get(`/api/project/${id}`)
    .then((r) =>
      dispatch({
        type: GET_GALLERIE_CAMPAING,
        payload: { _id: r.data._id, title: r.data.title, gallerie: r.data.gallerie },
      })
    )
    .then(() => dispatch(loadingPage()))
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// Eliminar una imagen de la galeria
export const removeGallerieCampaing = (id, d, intl) => (dispatch) => {
  swal({
    title: intl.formatMessage({ id: "alert.confiTitle" }),
    text: intl.formatMessage({ id: "alert.registryDelete" }),
    icon: "warning",
    buttons: true,
  })
    .then((confirmation) => {
      if (confirmation === true) {
        dispatch(loadingPage())
        axios
          .put(`/api/gallerie/delete/${id}`, d)
          .then((r) => dispatch({ type: REMOVE_GALLERIE_CAMPAING, payload: r.data }))
          .then(() => dispatch(loadingPage()))
          .then(() =>
            swal(intl.formatMessage({ id: "alert.imageDeleted" }), "", "success")
          )
          .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
      }
    })
    .catch((error) => dispatch({ type: GET_ERRORS, payload: error }))
}

// Obtener informacion de voluntarios
export const setVolunteersCampaing = (project) => (dispatch) =>
  dispatch({
    type: GET_VOLUNTEERS_CAMPAING,
    payload: {
      _id: project._id,
      title: project.title,
      volunteers: project.volunteers,
    },
  })

// Obtener galeria de imagenes de proyecto
export const setGallerieCampaing = (project) => (dispatch) =>
  dispatch({
    type: GET_GALLERIE_CAMPAING,
    payload: { _id: project._id, title: project.title, gallerie: project.gallerie },
  })

// Agregar imagen en la galería de un proyecto
export const addGallerieCampaing = (image) => (dispatch) =>
  dispatch({ type: ADD_GALLERIE_CAMPAING, payload: image })

// CLEAN EDIT PROP.
export const getCleanCampaing = () => (dispatch) =>
  dispatch({ type: GET_CLEAN_CAMPAING, payload: {} })

// CLEAN THE REPORT
export const getCleanReportCampaing = () => (dispatch) =>
  dispatch({ type: GET_REPORT_CAMPAING, payload: {} })

// CLEAN EDIT PROP.
export const editCleanCampaing = () => (dispatch) =>
  dispatch({ type: EDIT_CLEAN_CAMPAING, payload: {} })

// CLEAN ALL THE LIST
export const getAllCleanCampaing = () => (dispatch) =>
  dispatch({ type: GET_All_CLEAN_CAMPAING, payload: [] })

// CLEAN THE VOLUNTEERS NODE
export const getCleanVolunteersCampaing = () => (dispatch) =>
  dispatch({ type: GET_VOLUNTEERS_CAMPAING, payload: {} })

// CLEAN THE GALLERIE NODE
export const getCleanGallerieCampaing = () => (dispatch) =>
  dispatch({ type: GET_GALLERIE_CAMPAING, payload: {} })
