import React from 'react'
import { reduxForm } from 'redux-form'
import { Form, Button } from 'reactstrap'
import { withForm } from '../../../util/withForm'
import { required } from '../../../util/form'
import { RFHTMLEditor } from '../../Inputs/RFFields'
import FormContainer from '../../common/Container/FormContainer'

function ContentForm({ button, previousPage, ...props }) {
  return (
    <FormContainer {...props}>
      <Form>
        <RFHTMLEditor name='content' validate={[required]} />
      </Form>
      <Button onClick={previousPage}>Anterior</Button>
      <Button color='primary' className='float-right' onClick={props.handleSubmit}>
        Siguiente
      </Button>
    </FormContainer>
  )
}

const HolidayProjectContentForm = withForm(
  reduxForm({
    form: 'holiday-project',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(ContentForm)
)

export default HolidayProjectContentForm
