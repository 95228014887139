import React, { useState } from 'react'
import { Row, Button } from 'reactstrap'
import { Pie, Line } from 'react-chartjs-2'
import { injectIntl } from 'react-intl'

function RowChart({ intl, currency: cur, charts }) {
  const isUsd = cur === 'USD'

  const [currency, setCurrency] = useState(isUsd ? 'usd' : 'local')
  const [data, setData] = useState(charts[currency])

  const toggleCurrency = () => {
    if (!isUsd) {
      setCurrency(state => (state === 'usd' ? 'local' : 'usd'))
      setData(charts[currency])
    }
  }

  if (data === undefined) return null

  return (
    <Row className='my-1'>
      <div className='col-xl-8 col-lg-7'>
        {/* <!-- Area Chart --> */}
        <div className='card shadow mb-4'>
          <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
            <h6 className='m-0 font-weight-bold text-primary'>
              {intl.formatMessage({ id: 'report.chartDonations' })}
            </h6>
          </div>
          <div className='card-body'>
            <div className='chart-area'>
              <Line
                data={data.line}
                width={800}
                height={300}
                options={{
                  maintainAspectRatio: false,
                  responsive: true
                }}
              />
            </div>
            <hr />
          </div>
        </div>
      </div>

      {/* <!-- lDonut Chart --> */}
      <div className='col-xl-4 col-lg-5'>
        <div className='card shadow mb-4' style={{ height: '95%' }}>
          {/* <!-- Card Header - Dropdown --> */}
          <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
            <h6 className='m-0 font-weight-bold text-primary'>
              {intl.formatMessage({ id: 'report.chartTypes' })}
            </h6>
          </div>
          {/* <!-- Card Body --> */}
          <div className='card-body'>
            <div className='chart-pie pt-4 pb-2'>
              <Pie
                data={data.pie}
                width={350}
                height={350}
                options={{
                  maintainAspectRatio: false,
                  responsive: true
                }}
              />
            </div>
            <hr />
            {!isUsd && (
              <Button className='float-right' onClick={toggleCurrency}>
                USD/{cur}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Row>
  )
}

export default injectIntl(RowChart)
