import socket from '../../socket'
import ipify from 'ipify2'
import { SET_LOCATION, SET_LANGUAGE_MANUAL, SET_INIT_LOADING, GET_ERRORS } from './types'
import { softekkianLoginProps, setCurrentUser, logoutUser } from './authActions'
import { setCounterMoney, setCounterVisists, setCounterProjects } from './counterActions'
import { getVideo } from './videoActions'
import jwt_decode from 'jwt-decode'
import setAuthToken from '../.././util/setAuthToken'
import { getAllCarrouselUser } from './carrouselActions'
import { getLocation } from './locationActions'

export const initPage = () => dispatch => {
  ipify
    .ipv4()
    // Set the user current location into the three state
    .then(ip => dispatch(getLocation(ip)))
    .then(() => {
      let decoded = null
      // Check for token
      if (localStorage.token) {
        // Set token to Auth header
        setAuthToken(localStorage.token)
        // Decode token to get user data
        decoded = jwt_decode(localStorage.token)
        // Check for expired token
        const currentTime = Date.now() / 1000
        if (decoded.exp < currentTime) {
          // logoutUser user
          dispatch(logoutUser())
        } else {
          // token aun no a expirado
          if (decoded.rol !== 'softtekian') {
            // Set current Admin
            dispatch(setCurrentUser(decoded))
          } else {
            // Set extra props softtekian
            dispatch(softekkianLoginProps(decoded))
          }
          socket.emit('auth', decoded)
        }
      }
    })
    .then(() => dispatch(getAllCarrouselUser()))
    .then(() => dispatch({ type: SET_INIT_LOADING }))
    .then(() => dispatch(getVideo()))
    .then(() => dispatch(setCounterMoney()))
    .then(() => dispatch(setCounterVisists()))
    .then(() => dispatch(setCounterProjects()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
    .catch(() => dispatch({ type: SET_INIT_LOADING }))
}
export const setLocation = payload => ({ type: SET_LOCATION, payload })
export const setLanguageManual = payload => dispatch =>
  dispatch({ type: SET_LANGUAGE_MANUAL, payload })
