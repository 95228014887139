import { createActions, createReducer } from 'reduxsauce'
import { addItem } from '../../util/helpers'

const { Types, Creators } = createActions(
  {
    loadAllMailingRequest: ['payload'],
    loadAllMailingSuccess: ['payload'],
    loadAllMailingFailure: ['payload'],

    loadMailingRequest: ['payload'],
    loadMailingSuccess: ['payload'],
    loadMailingFailure: ['payload'],

    createMailingRequest: ['payload'],
    createMailingSuccess: ['payload'],
    createMailingFailure: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  view: {},
  edit: {},
  gallery: [],
  individual: {}
}

export const loadAllMailing = (s, action) => ({ ...s, list: [...action.payload] })
export const loadSuccess = (s, action) => ({ ...s, view: { ...action.payload } })
export const createSuccess = (s, action) => ({ ...s, list: addItem(s.list, action.payload) })

export const HANDLERS = {
  [Types.LOAD_ALL_MAILING_SUCCESS]: loadAllMailing,
  [Types.LOAD_MAILING_SUCCESS]: loadSuccess,
  [Types.CREATE_MAILING_SUCCESS]: createSuccess
}

export const types = Types
export const actions = Creators
export const mailing = createReducer(initialState, HANDLERS)
