import React, { Component, Fragment } from 'react'
import Footer from '../Common/Footer/Footer'
import Navbar from '../Common/Navbar/Navbar'
import NoticePrivacyContent from './../NoticePrivacyContent/NoticePrivacyContent'

// Solution to scroll restoration
import ScrollToTopOnMount from '../Common/ScrollToTopOnMount'

export default class NoticePrivacy extends Component {
  render() {
    return (
      <Fragment>
        <ScrollToTopOnMount />
        <Navbar />
        <NoticePrivacyContent />
        <Footer />
      </Fragment>
    )
  }
}
