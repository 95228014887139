import React, { Fragment, useState } from 'react'
import HolidayProjectInformationForm from './Forms/HolidayProjectInformationForm'
import HolidayProjectContentForm from './Forms/HolidayProjectContentForm'
import Stepper from 'react-stepper-horizontal'
import HolidayProjectPreview from './Forms/HolidayProjectPreview'

function WizardForm({ onSubmit, ...props }) {
  const [page, setPage] = useState(0)

  const nextPage = () => {
    setPage(page + 1)
  }

  const previousPage = () => {
    setPage(page - 1)
  }

  const setFormPage = (page, title) => ({
    title,
    onClick: () => {
      setPage(page)
    }
  })

  return (
    <Fragment>
      <div className='col-12 form-stepper'>
        <Stepper
          steps={[
            setFormPage(0, 'Información general'),
            setFormPage(1, 'Contenido'),
            setFormPage(2, 'Vista previa')
          ]}
          activeStep={page}
        />
      </div>
      {page === 0 && (
        <HolidayProjectInformationForm
          {...props}
          onSubmit={nextPage}
          label='Información general'
        />
      )}
      {page === 1 && (
        <HolidayProjectContentForm
          {...props}
          previousPage={previousPage}
          onSubmit={nextPage}
          label='Contenido de campaña'
        />
      )}
      {page === 2 && (
        <HolidayProjectPreview
          {...props}
          previousPage={previousPage}
          onSubmit={onSubmit}
          label='Vista previa'
        />
      )}
    </Fragment>
  )
}

export default WizardForm
