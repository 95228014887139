/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
function chunkArray(myArray, chunk_size) {
  let results = []
  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size))
  }
  return results
}
// HELPERS FUNCTIONS
const formater = list => JSON.stringify(list, null, 2)
// ( C ) Add a new item to the Array
const addItem = (list, item) => [...list, item]
// ( R ) Find item by id from a Array
const findItem = (list, _id) => list.find(i => i._id === _id)
// ( U ) Update item from a Array
const updatedItem = (list, item) => {
  const index = list.findIndex(i => i._id === item._id)
  return [...list.slice(0, index), item, ...list.slice(index + 1)]
}
// ( D ) Deletes a item from a Array
const deleteItem = (list, _id) => list.filter(i => i._id !== _id)
// Generates a random number
const generateId = () => Math.floor(Math.random() * 1000)
// Will return a number inside the given range, inclusive of both minimum and maximum
// i.e. if min=0, max=20, returns a number from 0-20
const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

// @type        Boolean
// @describe    return true or false
// if a value is empty.
const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0)

// @type        Boolean
// @describe    check if the
// value is empty return false or the value
const checkIsEmpty = value => (isEmpty(value) ? '' : value)

/*
 ***********************************************
 ********* React Funtional Programing  *********
 ***********************************************
 */

// @params    'propName' (string) , new props {}
// desc       set the state to this new props
// example    state {names: {prop1: 'porp1'}, prop2: 'porp2'}
const addObjectToStateProp = (propName, obj) => state => ({
  [propName]: { ...state[propName], ...obj }
})

// @desc    regresa el un objetor
// con el nombre y valor.
// @example {[name]: value}
// @return  [], array.
const getPropValue = event => {
  const target = event.target
  const name = target.name
  const val = target.value
  return { [name]: val }
}

// 1
// @desc   usar cuando en la propiedad
// name se un prop de un objeto dentro del
// statete.
// @example name="settings.type"
// @retrun : ['settings','type', value] , array.
const getPropChildValue = event => {
  const target = event.target
  const name = target.name
  const value = target.value
  const hasLevel = name.includes('.')
  if (hasLevel) {
    const props = name.split('.')
    const childs = name.split('.').length
    switch (childs) {
      case 2:
        return (state, prop) => ({ [props[0]]: { ...state[props[0]], [props[1]]: value } })
      case 3:
        return (state, prop) => ({
          [props[0]]: { ...state[props[0]], ...state[props[0]][props[1]], [props[2]]: value }
        })
      default:
        return
    }
  }
  // no childs
  return (state, prop) => ({ [name]: value })
}

// 1.1
const setStateFirstLevelProp = params => state => ({ [params[0]]: params[1] })

// 1.2
const setStateScondLevelProp = params => state => ({
  [params[0]]: { ...state[params[0]], [params[1]]: params[2] }
})

// 1.3
const setStateThirdLevelProp = params => state => ({
  [params[0]]: { [params[1]]: { ...state[params[1]], [params[2]]: params[3] } }
})

// funtional setState
// @params: event: {},  getLevel: [] , setLevel: f()
// @retrun f() , function.
function functionalSetState(event, getLevel, setLevel) {
  const params = getLevel(event)
  const state = setLevel(params)
  return state
}

/**
 * Vanilla JS implementation of pick function of lodash library.
 * Creates an object composed of the object properties predicate returns truthy for.
 * @author  https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_pick
 * @param   {object}   object   original object to be filtered by its keys
 * @param   {array}    keys     keys to be copied to the result object
 * @returns {object} filtered object with only the keys specified
 */

export const pick = (object, keys) => {
  return keys.reduce((obj, key) => {
    // Nested object properties
    if (key.includes('.')) key = key.split('.')[0]
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key]
    }
    return obj
  }, {})
}

export {
  functionalSetState,
  setStateThirdLevelProp,
  setStateScondLevelProp,
  setStateFirstLevelProp,
  addObjectToStateProp,
  getPropChildValue,
  getRandomInt,
  getPropValue,
  checkIsEmpty,
  updatedItem,
  deleteItem,
  chunkArray,
  generateId,
  findItem,
  formater,
  addItem,
  isEmpty
}
