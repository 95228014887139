import React, { Component } from 'react'

// Componentes de Términos y condiciones divididos en las tres secciones
// principales del documento
import NoticePrivacyIntroduction from './components/NoticePrivacyIntroduction'
import NoticePrivacyDefinitions from './components/NoticePrivacyDefinitions'
import NoticePrivacyTermsUse from './components/NoticePrivacyTermsUse'
export default class NoticePrivacyContent extends Component {
  render() {
    return (
      <section>
        <div className="container my-5" >
          <NoticePrivacyIntroduction />
          <NoticePrivacyDefinitions />
          <NoticePrivacyTermsUse />
        </div>
      </section>
    )
  }
}
