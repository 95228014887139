import React from 'react'
import validator from 'validator'
import { isEmpty } from '../../../../util/isEmpty'
import { FormattedMessage } from 'react-intl'

const ContactValidations = [
  {
    field: 'subsede',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, ''] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  },
  {
    field: 'name',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, ''] }
      } else if (value.length < 3) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.min3' />] }
      } else if (value.length > 25) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.max25' />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  },
  {
    field: 'phone',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, ''] }
      } else if (value.length < 4) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.min4' />] }
      } else if (!validator.isNumeric(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.number' />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  },
  {
    field: 'email',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, ''] }
      } else if (!validator.isEmail(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.email' />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  },
  {
    field: 'message',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, ''] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  }
]
export default ContactValidations
