import React, { Component } from 'react'
import { connect } from 'react-redux'
import Admin from './../Layout/Admin/Admin'
import PropTypes from 'prop-types'
import { getAllCampaingAdmin, getAllCleanCampaing, getReportCampaing } from '../../redux/actions/campaingActions'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
// Hepers
import { formatToTable, amountFormat } from './Helpers/report'
import TableWrapper from './../common/TableWrapper'
import { dmy } from './../../util/dateHelpers'
import { isEmpty } from '../../util/helpers'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button } from 'reactstrap'
import ExportExcel from '../common/ExportExcel/ExportExcel'
import { columnsToExport } from './Helpers/tableHelpers'

// Global variables
const { SearchBar } = Search

class CampaingReport extends Component {
	handleClick = (id) => this.props.getReportCampaing(id, this.props.intl)

	// Filter select options
	typeOptions = {
		actividad: this.props.intl.formatMessage({ id: 'modal.activitie' }),
		eventoespecial: this.props.intl.formatMessage({ id: 'label.specialEvent' }),
		probono: this.props.intl.formatMessage({ id: 'label.probono' }),
		campaña: this.props.intl.formatMessage({ id: 'label.campaign' })
	}

	// Columns configurations
	columns = [
		{
			dataField: 'title',
			text: this.props.intl.formatMessage({ id: 'label.title' }),
			sort: true
			// filter: textFilter({
			//   delay: 600
			// })
		},
		{
			dataField: 'type',
			text: this.props.intl.formatMessage({ id: 'modal.project' }),
			formatter: (cell) => this.typeOptions[cell],
			// filter: selectFilter({
			//   delay: 600,
			//   options: this.typeOptions
			// }),
			sort: true
		},
		{
			dataField: 'status',
			text: this.props.intl.formatMessage({ id: 'label.status' }),
			// filter: textFilter({
			//   delay: 600
			// }),
			sort: true
		},
		{
			dataField: 'fundsRaised',
			text: this.props.intl.formatMessage({ id: 'modal.donation' }),
			sort: true,
			formatter: (cell) => amountFormat(cell) + ' USD'
			// filter: numberFilter({
			//   delay: 600
			// })
		},
		{
			dataField: 'institution',
			text: this.props.intl.formatMessage({ id: 'modal.institution' }),
			sort: true
			// filter: textFilter({
			//   delay: 600
			// })
		},
		{
			dataField: 'subsede',
			text: this.props.intl.formatMessage({ id: 'modal.subsede' }),
			sort: true
			// filter: textFilter({
			//   delay: 600
			// })
		},
		{
			dataField: 'startDate',
			text: this.props.intl.formatMessage({ id: 'label.startDate' }),
			sort: true,
			// filter: dateFilter({
			//   delay: 600
			// }),
			formatter: dmy,
			csvFormatter: dmy
		},
		{
			dataField: 'eventDate',
			text: this.props.intl.formatMessage({ id: 'label.event' }),
			sort: true,
			// filter: dateFilter({
			//   delay: 600
			// }),
			formatter: dmy,
			csvFormatter: dmy
		},
		{
			dataField: 'endDate',
			text: this.props.intl.formatMessage({ id: 'label.endDate' }),
			sort: true,
			// filter: dateFilter({
			//   delay: 600
			// }),
			formatter: dmy,
			csvFormatter: dmy
		},
		{
			dataField: '_id',
			csvExport: false,
			text: this.props.intl.formatMessage({ id: 'label.report' }),
			formatter: (cell) => {
				return (
					<Button color="secondary" onClick={() => this.handleClick(cell)}>
						<i className="fas fa-eye" />
					</Button>
				)
			}
		}
	]

	// Application's state
	state = {
		projects: []
	}

	// LIFECYCLES METHODS

	componentDidMount() {
		this.props.getAllCampaingAdmin(this.props.intl)
	}

	componentWillReceiveProps(np) {
		if (isEmpty(np.campaing.list)) {
			np.getAllCampaingAdmin(np.intl)
		} else {
			this.setState({
				projects: formatToTable(np.campaing.list)
			})
		}
		if (!isEmpty(np.campaing.report)) {
			// debugger
			const { _id } = np.campaing.report
			np.history.push(`/dashboard/campaign-report/${_id}`)
		}
	}

	componentWillUnmount() {
		this.props.getAllCleanCampaing()
	}

	// RENDER METHOD

	render() {
		const { columns, state: { projects } } = this
		return (
			<Admin>
				<ToolkitProvider
					keyField="_id"
					data={projects}
					columns={columns}
					bootstrap4={true}
					search={{
						searchFormatted: true
					}}
				>
					{(props) => (
						<TableWrapper
							title={<FormattedMessage id="label.report" />}
							label={<FormattedMessage id="label.reportProject" />}
						>
							<SearchBar
								{...props.searchProps}
								placeholder={this.props.intl.formatMessage({ id: 'label.searchBar' })}
							/>
							<BootstrapTable
								{...props.baseProps}
								filter={filterFactory()}
								className="table-react-boostrap"
								pagination={paginationFactory()}
								bordered
								hover
							/>
							{!isEmpty(projects) && (
								<ExportExcel
									data={projects}
									columnsToExport={columnsToExport}
									tableName="Reporte de proyectos"
								/>
							)}
						</TableWrapper>
					)}
				</ToolkitProvider>
			</Admin>
		)
	}
}

CampaingReport.propTypes = {
	auth: PropTypes.object.isRequired,
	getAllCampaingAdmin: PropTypes.func.isRequired,
	getAllCleanCampaing: PropTypes.func.isRequired,
	getReportCampaing: PropTypes.func.isRequired
}
const mapStateToProps = ({ auth, campaing }) => ({ auth, campaing })
const mapDispatchToProps = { getAllCampaingAdmin, getAllCleanCampaing, getReportCampaing }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaingReport))
