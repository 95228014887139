import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import styles from './initiative-info-section.module.css'
import ContentWrapper from '../../../common/ContentWrapper/ContentWrapper'
import ModalInitiative from '../../../Dashboard/Catalogs/Initiative/ModalInitiative'

const InitiativeInfoSection = () => (
  <section>
    <div className={`text-center showcase ${styles['fondoInitiative']}`}>
      <div className={`container`}>
        <ContentWrapper
          className='my-0'
          pretitle={<FormattedMessage id="programs.know" />}
          title={<FormattedMessage id="initiative.title" />}
          content={<FormattedHTMLMessage id="initiative.txt" />}
        />
      </div>
      <ModalInitiative />
    </div>
  </section>
)

export default InitiativeInfoSection