import React from 'react'
import styles from './dts-info-card.module.css'

export default function DTSInfoCard({ icon, children, className, ...props }) {
  return (
    <div className={`row my-4 ${className}`} {...props}>
      <div className='col-md-2 text-center'>
        <img className={`img-fluid rounded-circle mb-3 iconsPrograms ${styles['logo']}`} src={icon} alt="" />
      </div>
      <div className='col-md-10'>
        {children}
      </div>
    </div>
  )
}
