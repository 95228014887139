import { SET_SUBSEDES } from '../actions/types'
const initialState = {
  list: [],
  options: []
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SUBSEDES:
      return {
        list: [...action.payload.list],
        options: [...action.payload.options]
      }
    default:
      return state
  }
}