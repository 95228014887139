export const initialState = {
  catalog: {},

  fields: {
    name: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    },
    description: {
      value: '',
      valid: null,
      invalid: null,
      feed: []
    }
  },

  list: [],
  item: {},
  errors: {},
  modal: false,
  historical: [],

  btnSave: false,
  btnUpdate: false,
  btnAdd: true,
  btnCancel: false,
  form: false
}
