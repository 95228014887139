/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { columns, donationLineAndPieChart } from './paypal.helpers'
import { clearStatePaypal, loadAllDonationPaypalRequest } from './paypal.redux'
import DataTable from '../common/Table/TablePagination'
import TableContainer from '../common/Container/TableContainer'
import Admin from './../Layout/Admin/Admin'
import PaypalCharts from './PaypalCharts'
import SearchBar from './SearchBar'
import './paypal.css'

function PaypalList(props) {
  const { graph, paypals, info } = props

  const data = paypals.docs || []
  const totalDocs = paypals.totalDocs
  const pageAct = paypals.page
  const limit = paypals.limit

  useEffect(() => {
    if (props.filters.search) {
      props.filters.search = undefined
      props.clearStatePaypal()
    }
    props.clearStatePaypal()
    props.loadAllDonationPaypalRequest(props.filters)
  }, [])

  const rowEvents = {
    onClick: (e, row) => {
      const redirectTo = `/dashboard/paypal/${row._id}`
      props.history.push(redirectTo)
    }
  }

  const handleTableChange = (type, { page, sizePerPage, filter }) => {
    props.filters.page = page
    if (filter) {
      props.filters.search = filter
    }

    props.loadAllDonationPaypalRequest(props.filters)
  }

  const chart = donationLineAndPieChart(graph, graph)

  return (
    <Admin>
      <div className="col-12 mb-4">
        <h1 className="h3 mb-2 text-gray-800">Donaciones PayPal</h1>
      </div>
      <div className='mx-3 mt-5 pt-4 row'></div>
      <div className='box-container'>
        <div className='information-box'>
          <label className='information-label'>Total</label>
          <label>${info.total} USD</label>
        </div>
        <div className='information-box'>
          <label className='information-label'>Número de donaciones</label>
          <label>{info.donors}</label>
        </div>
      </div>
      <PaypalCharts {...chart} />
      <SearchBar filter={props.filters} onTableChange={handleTableChange} />
      <TableContainer subtitle='Lista'>
          <DataTable
            {...props}
            data={data}
            totalDocs={totalDocs}
            pageAct={pageAct}
            limit={limit}
            structure={columns}
            rowEvents={rowEvents}
            filter={props.filters}
            onTableChange={handleTableChange}
          />
      </TableContainer>
    </Admin>
  )
}

const mapStateToProps = ({ paypal }) => ({
  paypals: paypal.list,
  info: paypal.info,
  filters: paypal.filters,
  graph: paypal.graph
})

const mapDispatchToProps = {
  clearStatePaypal,
  loadAllDonationPaypalRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PaypalList)
