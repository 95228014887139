import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Redux
import { connect } from 'react-redux'
import SideNavbar from './adminComponents/SideNavbar'
import Topbar from './adminComponents/Topbar'
import { isEmpty } from '../../../util/isEmpty'
import Welcome from './Welcome'
import '../../../assets/css/admin.css'
import Push from './../../common/Push/Push'
export class Admin extends Component {

  render() {
    return (
      <div id="wrapper">
        <SideNavbar />
        <div id="content-wrapper">
          <div id="content">
            <Topbar />
            <Push />
            <div className="container-fluid">
              <br />
              {isEmpty(this.props.children) ? <Welcome /> : (this.props.children)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Admin.propTypes = {
  auth: PropTypes.object.isRequired,
}
const mapStateToProps = ({ auth, }) => ({ auth, })
export default connect(mapStateToProps, {})(Admin)
