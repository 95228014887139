import React, { Fragment } from 'react'
import { Input, Col, Row } from 'reactstrap'
import FormGroupValid from '../lib/FormGroupValid'
import FormGroupCustom from '../lib/FormGroupCustom'
import UploadFiles from './../../common/Upload/UploadFiles'
import { FormattedMessage } from 'react-intl';
// const validStyle = valid => valid ? { fontSize: "80%", color: "#28a745" } : { fontSize: "80%", color: "#dc3545" }

const ThumbnailForm = ({ title, description, thumbnail, handleInputChange, handleChangeSelectData }) => {
  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroupValid forp="title" label={<FormattedMessage id="label.title" />} {...title}>
            <Input
              id="title"
              type="text"
              name="title"
              value={title.value}
              valid={title.valid}
              invalid={title.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupValid forp='description' label={<FormattedMessage id="label.description" />} {...description}>
            <Input
              type="textarea"
              id="description"
              name="description"
              value={description.value}
              valid={description.valid}
              invalid={description.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
      </Row>


      <Row>
        <Col sm='12'>
          <FormGroupCustom forp='thumbnail' label={<FormattedMessage id="label.cover" />} {...thumbnail}>
            <UploadFiles
              url={'/api/file'}
              file={file => handleChangeSelectData({ name: 'thumbnail', value: file._id })} />
          </FormGroupCustom>
        </Col>
      </Row>

    </Fragment>
  )
}

export default ThumbnailForm
