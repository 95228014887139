import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

export const ConektaDonationModal = ({ toggle, modal, children, amount, ...props }) => {
  return (
    <div>
      <button
        onClick={toggle}
        className='buttonProjectPage credit-card-btn mt-2 p-2'
        disabled
        {...props}>
        Tarjeta de Crédito
      </button>
      <Modal size='md' isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Donación con Tarjeta de crédito o débito</ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </div>
  )
}
