/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { NavLink } from 'react-router-dom'

import arrowGreen from './../img/arrow-verde-01.svg'
import arrowWhite from './../img/arrow-blanco-02.svg'

const handleClick = event => {
  event.currentTarget.classList.toggle('dropdown-open')
}

export default function NavbarDropdown({ title, links, className = '', children, ...props }) {
  return (
    <li className={`dropdown-sub-menu ${className}`} {...props} onClick={handleClick}>
      <a href='#'>
        <div className='dropdown-title'>
          {title}
          <img src={arrowWhite} alt='' className='arrow-toggle-dropdown' />
        </div>
      </a>
      <ul className='sub-menu'>
        {links.map(({ label, link }, i) => {
          return (
            <li key={`dropdow-item-${label}-${i}`}>
              <NavLink to={link}>
                <img className='arrow-link' src={arrowGreen} alt='' />
                {label}
              </NavLink>
            </li>
          )
        })}
        {children}
      </ul>
    </li>
  )
}
