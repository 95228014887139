import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
export class AdminInfo extends Component {
  componentWillReceiveProps(np) { }
  render() {
    const {
      email,
      name,
      rol,
      is,
      sede_name,
      subsede_name, } = this.props.auth.user
    return (
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary"><FormattedMessage id="dashboard.inf" /></h6>
        </div>
        <div className="card-body">
          <div className="row">
            <dl className="col">
              <dt><FormattedMessage id="label.fullName" />:</dt>
              <dd>{name}</dd>
              <dt><FormattedMessage id="label.is" />:</dt>
              <dd>{is} </dd>
              <dt><FormattedMessage id="label.email" />:</dt>
              <dd>{email} </dd>
              <dt><FormattedMessage id="modal.role" />: </dt>
              <dd>{rol}</dd>
            </dl>
            <dl className="col">
              <dt><FormattedMessage id="modal.sede" />:</dt>
              <dd>{sede_name}</dd>
              <dt><FormattedMessage id="modal.subsede" />:</dt>
              <dd>{subsede_name} </dd>
            </dl>
          </div>
        </div>
      </div>
    )
  }
}
//MRCM                                                                                                                                                                                                
AdminInfo.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}
const mapStateToProps = ({ auth, location }) => ({ auth, location })
export default connect(mapStateToProps, {})(AdminInfo)