import React, { Fragment } from 'react'
import Footer from '../Common/Footer/Footer'
import Navbar from '../Common/Navbar/Navbar'
import InitiativeInfoSection from '../InitiativeSection/InitiativeInfoSection/InitiativeInfoSection'
import InitiativeImage from '../InitiativeSection/InitiativeImage/InitiativeImage'

// Solution to scroll restoration
import ScrollToTopOnMount from './../Common/ScrollToTopOnMount'

function Initiative() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <Navbar />
      <InitiativeInfoSection />
      <InitiativeImage />
      <Footer />
    </Fragment>
  )
}

export default Initiative