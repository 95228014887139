// cuando cambia un input onChange.
export const handleChange = event => {
  const { target } = event
  const { name } = target
  const value = target.type === 'checkbox' ? target.checked : target.value
  return state => ({ [name]: { ...state[name], value } })
}


// valida el campo cuando se esta escribiendo en el.
export const checkOnchange = (state, valitions, field) => {
  let result = {}
  valitions.forEach(c => {
    Object.keys(state).forEach(prop => {
      if ((prop === field) && (c.field === field)) {
        let value = state[prop]['value']
        let name = prop
        const val = c.function(value)
        result = { isValid: false, state: { ...state, ...result, [name]: { ...state[name], ...val } } }
      }
    })
  })
  return result
}

export const checkOnSubmit = (s, valitions) => {
  let result = {}
  valitions.forEach(c => {
    Object.keys(s).forEach(prop => {
      if (prop === c.field) {
        let value = s[prop]['value']
        let name = prop
        const val = c.function(value)
        result = { ...s, ...result, [name]: { ...s[name], ...val } }
      }
    })
  })
  return result
}


//  ____                          _       _              _                             
// |  _ \    ___    __ _    ___  | |_    | |__     ___  | |  _ __     ___   _ __   ___ 
// | |_) |  / _ \  / _` |  / __| | __|   | '_ \   / _ \ | | | '_ \   / _ \ | '__| / __|
// |  _ <  |  __/ | (_| | | (__  | |_    | | | | |  __/ | | | |_) | |  __/ | |    \__ \
// |_| \_\  \___|  \__,_|  \___|  \__|   |_| |_|  \___| |_| | .__/   \___| |_|    |___/
//                                                          |_|                        



export const specialValidations = (state, valitions, prop = null) => {
  let stateValidated = {}
  const arrayState = Object.keys(state).map(key => ({ field: key, value: state[key].value }))
  for (let ob of arrayState) {
    const validation = valitions.find(v => v.field === ob.field)
    if (validation) {
      const r = validation.function(ob.value, state)
      stateValidated = { ...stateValidated, [ob.field]: { ...state[ob.field], ...r } }
    }
  }
  const isValid = validateState(stateValidated)
  return { isValid, stateValidated }
}

export const specialValidationsOnChange = (state, valitions, prop) => {
  let stateValidated = {}
  const props = prop.split('.')
  const propName = props[props.length - 1]
  const arrayState = Object.keys(state).map(key => ({ field: key, value: state[key].value }))
  const field = arrayState.find(f => f.field === propName)
  if (field) {
    const validation = valitions.find(v => v.field === field.field)

    if (validation) {

      const r = validation.function(field.value, state)

      stateValidated = { ...state, [field.field]: { ...state[field.field], ...r } }
    }
    const isValid = validateState(stateValidated)
    return { isValid, stateValidated }
  }
  return null
}


// cuando camabia el componente select data custom.
export const handleChangeSelectData = ({ name, value }) => {
  const hasLevel = name.includes('.')
  if (hasLevel) {
    const props = name.split('.')
    const childs = name.split('.').length
    switch (childs) {
      case 2:
        return (state, prop) => ({ [props[0]]: { ...state[props[0]], [props[1]]: { ...state[props[0]][props[1]], value } } })
      case 3:
        return (state, prop) => ({ [props[0]]: { ...state[props[0]], [props[1]]: { ...state[props[0]][props[1]], [props[2]]: { ...state[props[0]][props[1]][props[2]], value } } } })
      default:
        return
    }
  }
  // no childs
  return (state, prop) => ({ [name]: { ...state[name], value } })
}

export const onChangeState = event => {
  const target = event.target
  const name = target.name
  const value = target.type === 'checkbox' ? target.checked : target.value
  const hasLevel = name.includes('.')
  if (hasLevel) {
    const props = name.split('.')
    const childs = name.split('.').length
    switch (childs) {
      case 2:
        return (state, prop) => ({ [props[0]]: { ...state[props[0]], [props[1]]: { ...state[props[0]][props[1]], value } } })
      case 3:
        return (state, prop) => ({ [props[0]]: { ...state[props[0]], [props[1]]: { ...state[props[0]][props[1]], [props[2]]: { ...state[props[0]][props[1]][props[2]], value } } } })
      default:
        return
    }
  }
  // no childs
  return (state, prop) => ({ [name]: { ...state[name], value } })
}

// check if empty or not 
export const isEmpty = value => (
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0)
)

export const checkIsEmpty = value => isEmpty(value) ? isEmpty(value) : value



// checka si todos los elementos del state tienen la  
//  propiedad valid === true devuelve un Boolean.
export const validateState = s => Object.keys(s).map(k => ({ ...s[k] })).filter(o => o.valid !== null).every(o => o.valid === true)


export const validationsOnType = (state, valitions, prop) => {
  const fieldsenter = state.fields
  const props = prop.split('.')
  const propName = props[props.length - 1]
  const arrayState = Object.keys(fieldsenter).map(key => ({ field: key, value: fieldsenter[key].value }))
  const field = arrayState.find(f => f.field === propName)
  if (field) {
    const validation = valitions.find(v => v.field === field.field)
    if (!isEmpty(validation)) {
      const r = validation.function(field.value, state)
      let fields = { ...fieldsenter, [field.field]: { ...fieldsenter[field.field], ...r } }
      return fields
    } else {
      let fields = fieldsenter
      return fields
    }
  }
}

export const validationsOnSubmit = (state, valitions, prop = null) => {
  // debugger
  let stateValidated = {}
  const { fields } = state
  const arrayState = Object.keys(fields).map(key => ({ field: key, value: fields[key].value }))
  for (let ob of arrayState) {
    const validation = valitions.find(v => v.field === ob.field)
    if (validation) {
      const r = validation.function(ob.value, state)
      if (!isEmpty(r)) {
        stateValidated = { ...stateValidated, [ob.field]: { ...fields[ob.field], ...r } }
      }
    }
  }
  const isValid = validateState(stateValidated)
  return { isValid, stateValidated }
}

//  OTHER HELPERS

// String.prototype.capitalize = function () {
//   return this.charAt(0).toUpperCase() + this.slice(1);
// }
