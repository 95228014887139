import axios from 'axios'
import Select from 'react-select'
import React, { Component, Fragment } from 'react'
// import PropTypes from 'prop-types'

export class SelectSubsede extends Component {
  state = {
    options: [],
    data: null,
  }

  map = list => list.filter(r => r.active).map(r => ({ label: r.name, value: r }))

  componentDidMount() {
    const route = `${this.props.route}`
    axios
      .get(route)
      .then(res => {
        this.setState({ options: this.map(res.data) })
      })
      .catch(err => console.log(err))
  }

  // @return       Object.
  // @description  handel the <Select /> Compoenet.
  // and pass up name and value .
  onChange = ({ value }) => this.props.onChange({ name: this.props.name, value })

  customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: "red",
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "red"
      }
    })
  }

  render() {
    return (
      <Fragment>
        <Select
          options={this.state.options}
          name={this.props.name}
          onChange={this.onChange}
          className={this.props.className}
        //styles={this.props.invalid ? this.customStyles : null}
        />
      </Fragment >
    )
  }
}

export default SelectSubsede