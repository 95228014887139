import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon
} from 'react-share'

function ShareCard({ shareUrl, message }) {
  return (
    <div className='card mb-4 box-shadowCard miniCard'>
      <div className='card-header miniCardHeader'>
        <h5 className='my-0 text-center' style={{ textTransform: 'uppercase' }}>
          <FormattedMessage id='label.shareBar' />
        </h5>
      </div>
      <div className='card-body miniCardBody d-flex justify-content-center social-media-share-bar'>
        <TwitterShareButton url={shareUrl} title={message}>
          <TwitterIcon size={40} round />
        </TwitterShareButton>
        <WhatsappShareButton url={shareUrl} title={message} separator=' '>
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>
        <FacebookShareButton url={shareUrl} quote={message}>
          <FacebookIcon size={40} round />
        </FacebookShareButton>
        <LinkedinShareButton
          url={shareUrl}
          source={shareUrl}
          title={message}
          summary={message}>
          <LinkedinIcon size={40} round />
        </LinkedinShareButton>
      </div>
    </div>
  )
}

export default ShareCard
