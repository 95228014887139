import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Label } from 'reactstrap'
import React, { Component } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import { loginUser } from '../../redux/actions/authActions'
import Clean from '../Main/Layout/Clean'
import { spinnerToggle } from '../../redux/actions/spinnerActions'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from '../Main/Pages/Contact/contact.module.css'
import Im1 from '../../assets/img/Icons/verified-01.svg'

import ScrollToTopOnMount from '../Main/Common/ScrollToTopOnMount'
import AzureSignInButton from './AzureSignInButton'

class Login extends Component {
  state = {
    email: '',
    password: '',
    loading: false,
    errors: {}
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.rol === 'mantainer' || 'reporter' || 'corporative')
        this.props.history.push('/dashboard')
      if (this.props.auth.user.rol === 'softtekian') this.props.history.push('/user')
    }
  }

  componentWillReceiveProps(np) {
    if (
      np.auth.isAuthenticated &&
      (np.auth.user.rol === 'mantainer' || 'reporter' || 'corporative')
    )
      this.props.history.push('/dashboard')
    if (np.auth.isAuthenticated && np.auth.user.rol === 'softtekian')
      this.props.history.push('/user')
    if (np.errors) this.setState({ errors: np.errors, loading: false })
    this.props.spinnerToggle()
  }

  onChange = event => this.setState({ [event.target.name]: event.target.value })

  onSubmit = event => {
    event.preventDefault()
    this.setState({ loading: true })
    const { email, password } = this.state
    const user = { email: `${email}@softtek.com`, password }
    this.props.spinnerToggle()
    this.props.loginUser(user)
  }
  render() {
    const {
      email,
      password,
      errors: { email: emailError, password: passwordError }
    } = this.state
    return (
      <Clean>
        <ScrollToTopOnMount />

        <div className='row' style={{ marginTop: '50px' }}>
          <div className='col-md-8 m-auto'>
            <h1 className={`display-4 text-center ${styles['subtitleLogin']}`}>
              <FormattedMessage id='general.login' />
            </h1>
              <AzureSignInButton/>
          </div>
        </div>
      </Clean>
    )
  }
}

Login.prototypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default injectIntl(connect(mapStateToProps, { loginUser, spinnerToggle })(Login))
