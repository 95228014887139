import React from 'react'
import { FormGroup, Label, Input, FormFeedback, FormText, Form, Row, Col } from 'reactstrap'
import SelectSubsede from './../../../common/Inputs/SelectSubsede'
import { FormattedMessage } from 'react-intl'
import styles from '../../../Main/Pages/Contact/contact.module.css'

const ContactForm = ({ fields, handleChange, handleChangeSelectData, onSubmit, create }) => {
  return (
    <div className={`${styles['fondoContacto']}`}>
      <div className='container'>
        <div className={`row`} style={{ marginTop: '5%' }}>
          <div className='col-lg-8 mb-4'>
            <div>
              <h1 className={styles['title']}>
                <FormattedMessage id='callout.touch' />
              </h1>
              <h1 className={styles['subtitle']}>
                <FormattedMessage id='btn.sendUs' />
                ...
              </h1>
            </div>
            <Form>
              <FormGroup>
                {/* <img className={`img-fluid rounded-circle mb-3 ${styles['logo']}`} src={Im1} alt="" /> */}
                <Label for='subsede' className={styles['label']}>
                  <strong>
                    *<FormattedMessage id='modal.subsede' />:{' '}
                  </strong>
                </Label>
                <SelectSubsede
                  className='contact__input-select-subsede'
                  route='/api/subsede'
                  name='fields.subsede'
                  onChange={handleChangeSelectData}
                  valid={fields.subsede.valid}
                  invalid={fields.subsede.invalid}
                />
                <FormFeedback valid={fields.subsede.valid}>
                  <ul className='list-unstyled'>
                    {fields.subsede.feed.map(f => (
                      <li className=''>{f}</li>
                    ))}
                  </ul>
                </FormFeedback>
                <FormText>{fields.subsede.text}</FormText>
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    {/* <img className={`img-fluid rounded-circle mb-3 ${styles['logo1']}`} src={Im1} alt="" /> */}
                    <Label for='name' className={styles['label']}>
                      <strong>
                        *<FormattedMessage id='label.name' />
                      </strong>
                      :
                    </Label>
                    <Input
                      className={styles['input']}
                      type='text'
                      name='fields.name'
                      valid={fields.name.valid}
                      invalid={fields.name.invalid}
                      value={fields.name.value}
                      onChange={handleChange}
                    />
                    <FormFeedback valid={fields.name.valid}>
                      <ul className='list-unstyled'>
                        {fields.name.feed.map(f => (
                          <li className=''>{f}</li>
                        ))}
                      </ul>
                    </FormFeedback>
                    <FormText>{fields.name.text}</FormText>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    {/* <img className={`img-fluid rounded-circle mb-3 ${styles['logo1']}`} src={Im1} alt="" /> */}
                    <Label for='phone' className={styles['label']}>
                      <strong>
                        *<FormattedMessage id='label.phone' />:
                      </strong>
                    </Label>
                    <Input
                      className={styles['input']}
                      type='text'
                      name='fields.phone'
                      valid={fields.phone.valid}
                      invalid={fields.phone.invalid}
                      value={fields.phone.value}
                      onChange={handleChange}
                    />
                    <FormFeedback valid={fields.phone.valid}>
                      <ul className='list-unstyled'>
                        {fields.phone.feed.map(f => (
                          <li className=''>{f}</li>
                        ))}
                      </ul>
                    </FormFeedback>
                    <FormText>{fields.phone.text}</FormText>
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                {/* <img className={`img-fluid rounded-circle mb-3 ${styles['logo']}`} src={Im1} alt="" /> */}
                <Label for='email' className={styles['label']}>
                  <strong>
                    *<FormattedMessage id='label.email' />:
                  </strong>
                </Label>
                <Input
                  className={styles['input']}
                  type='email'
                  name='fields.email'
                  valid={fields.email.valid}
                  invalid={fields.email.invalid}
                  value={fields.email.value}
                  onChange={handleChange}
                />
                <FormFeedback valid={fields.email.valid}>
                  <ul className='list-unstyled'>
                    {fields.email.feed.map(f => (
                      <li className=''>{f}</li>
                    ))}
                  </ul>
                </FormFeedback>
                <FormText>{fields.email.text}</FormText>
              </FormGroup>

              <FormGroup>
                {/* <img className={`img-fluid rounded-circle mb-3 ${styles['logo']}`} src={Im1} alt="" /> */}
                <Label for='message' className={styles['label']}>
                  <strong>
                    *<FormattedMessage id='label.msg' />:
                  </strong>
                </Label>
                <Input
                  className={styles['input']}
                  type='textarea'
                  rows='3'
                  name='fields.message'
                  valid={fields.message.valid}
                  invalid={fields.message.invalid}
                  value={fields.message.value}
                  onChange={handleChange}
                />
                <FormFeedback valid={fields.message.valid}>
                  <ul className='list-unstyled'>
                    {fields.message.feed.map(f => (
                      <li className=''>{f}</li>
                    ))}
                  </ul>
                </FormFeedback>
                <FormText>{fields.message.text}</FormText>
              </FormGroup>
            </Form>
            <button
              type='submit'
              disabled={create}
              onClick={onSubmit}
              className={styles['buttonContact']}
              id='sendMessageButton'>
              <FormattedMessage id='btn.send' />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
