import React, { Fragment } from 'react'
import Footer from './../Common/Footer/Footer'
import Navbar from './../Common/Navbar/Navbar'
import DonationSection from '../DonationSection/DonationSection'
import ProjectsInfoSection from './../OurProjectsSection/ProjectsInfoSection/ProjectsInfoSection'
import ProjectsConsiderationsSection from './../OurProjectsSection/ProjectsConsiderationsSection/ProjectsConsiderationsSection'

// Solution to scroll restoration
import ScrollToTopOnMount from './../Common/ScrollToTopOnMount'

function OurProjects() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <Navbar />
      <ProjectsInfoSection />
      <DonationSection />
      <ProjectsConsiderationsSection />
      <Footer />
    </Fragment >
  )
}

export default OurProjects