import React, { Fragment } from 'react'
import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap'
import SelectSubsede from './../../../common/Inputs/SelectSubsede'
import { FormattedMessage } from 'react-intl'

const SubsedeForm = ({ defaultValue, fields, handleChange, handleChangeSelectData }) => {
  return (
    <Fragment>
      <form>
        <FormGroup>
          <Label for="name"><FormattedMessage id="label.name" /> :</Label>
          <Input
            type="text"
            name="fields.name"
            valid={fields.name.valid}
            invalid={fields.name.invalid}
            value={fields.name.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.name.valid}>
            <ul className="list-unstyled">
              {fields.name.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.name.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="description"><FormattedMessage id="label.description" /> :</Label>
          <Input
            type="textarea"
            name="fields.description"
            valid={fields.description.valid}
            invalid={fields.description.invalid}
            value={fields.description.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.description.valid}>
            <ul className="list-unstyled">
              {fields.description.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.description.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="sede"><FormattedMessage id="label.sede" /> :</Label>
          <SelectSubsede
            route='/api/sede'
            name='fields.sede'
            onChange={handleChangeSelectData}
          />
        </FormGroup>

        <FormGroup>
          <Label for="location_address"><FormattedMessage id="label.address" /> :</Label>
          <Input
            type="text"
            name="fields.location_address"
            valid={fields.location_address.valid}
            invalid={fields.location_address.invalid}
            value={fields.location_address.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.location_address.valid}>
            <ul className="list-unstyled">
              {fields.location_address.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.location_address.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="email"><FormattedMessage id="label.email" /> :</Label>
          <Input
            type="email"
            name="fields.email"
            valid={fields.email.valid}
            invalid={fields.email.invalid}
            value={fields.email.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.email.valid}>
            <ul className="list-unstyled">
              {fields.email.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.email.text}</FormText>
        </FormGroup>
      </form>
    </Fragment >
  )
}

export default SubsedeForm