import { COUNTER_MONEY, COUNTER_VISITS, COUNTER_PROYECTS } from '../actions/types'

const initialState = {
  money: 0,
  visits: 0,
  proyects: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case COUNTER_MONEY:
      return {
        ...state,
        money: action.payload
      }
    case COUNTER_VISITS:
      return {
        ...state,
        visits: action.payload
      }
    case COUNTER_PROYECTS:
      return {
        ...state,
        proyects: action.payload
      }
    default:
      return state
  }
}