import moment from 'moment'

// Recibir una fecha en string y remover los caracteres
// que no se necesiten.
export const removeCharFromDate = date => date.substring(0, 10)

// Retorna el status del proyecto
// comparando la fecha de inicio y fin vs
// el dia de hoy. Basado en el analisis :
// @DOC :  FS005-RF01
// @PUNTO : 3.1 Proyectos
// @return (Nuevo || Publicado || Finalizado) ,
// STRING MULTI LENGUAJE.
export const getStatus = (sd, ed, today) => {
  if (moment(today).isBetween(sd, ed, 'days', '[]')) return 'label.published'
  if (moment(today).isBefore(sd)) return 'label.new'
  if (moment(today).isAfter(ed)) return 'label.finalized'
}

// Retorna el status del proyecto Completado
// cuando se pasa el parametro  @closed
// el dia de hoy. Basado en el analisis :
// @DOC :  FS005-RF01
// @PUNTO : 3.1 Proyectos
// @return (True  || False ) ,
// STRING MULTI LENGUAJE.
export const isClosed = closed => closed ? { closed: closed, status: 'label.completed' } : false


// 1.- check si el proyecto esta cerrado si si retorna 
// 2.- remover los caracteres extra y obtener el dia de hoy.
// 3.- verificar el estatus.
// 4.- regresar el estatus en multi-lenguaje
export const currentStatus = (isClosed, removeCharFromDate, getStatus, intl) => {
  return function (sd, ed, closed, active) {
    let result = null
    const close = isClosed(closed)
    if (close === false) {
      const end = removeCharFromDate(ed)
      const start = removeCharFromDate(sd)
      const today = moment().format('YYYY-MM-DD')
      const status = getStatus(start, end, today)
      result = intl.formatMessage({ id: status })
    } else {
      result = intl.formatMessage({ id: close.status })
    }
    if (active === false) {
      result = intl.formatMessage({ id: 'table.inactive' })
    }
    return result
  }
}
// Da forato a la fecha .
export const dateFormatted = (date, format) => moment(date).format(format)