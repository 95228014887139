import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import { actions } from './holiday-project.redux'
import { Row, Col } from 'reactstrap'
import { dmy } from '../../util/dateHelpers'
import { isEmpty } from '../../util/helpers'
import TableContainer from '../common/Container/TableContainer'
import { ListItem } from '../common/ListItem'
import Admin from '../Layout/Admin/Admin'
import { LightTable } from '../common/Table/LightTable'
import { downloadFile } from '../../util/download'
import HolidayProjectLogo from './components/HolidayProjectLogo'

class HolidayProjectView extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.loadHolidayProjectRequest({ id })
  }

  componentWillUnmount() {
    this.props.loadHolidayProjectSuccess()
  }

  getReportName = title => `${title}_${moment().format('DD-MM-YY')}.xlsx`

  render() {
    const { view: item } = this.props.holidayProject

    const handleExport = () => {
      const { id } = this.props.match.params
      axios({
        url: '/api/holiday-project/export/' + id,
        method: 'POST',
        responseType: 'blob'
      })
        .then(downloadFile(this.getReportName(item.title)))
        .catch(console.error)
    }

    return (
      <Admin>
        <TableContainer
          title='Campaña México 2020'
          subtitle='Información del registro'
          toggle={this.props.toggleHolidayProjectRequest}
          view={item}>
          {!isEmpty(item) && (
            <Row>
              <Col md='7'>
                <ul>
                  <ListItem label='Título' value={item.title} />
                  <ListItem label='Descripción' value={item.description} />
                  <ListItem label='Duración' value={`${item.duration} días`} />
                </ul>
              </Col>
              <Col md='5'>
                <ul>
                  <ListItem label='Activo' value={item.active} />
                  <ListItem label='Creado' value={dmy(item.createdAt)} />
                  <ListItem label='Última modificación' value={dmy(item.updatedAt)} />
                </ul>
                <HolidayProjectLogo
                  alt={item.title}
                  src={item.logo.path}
                  className='mb-3'
                  style={{ width: 150, display: 'block', margin: 'auto' }}
                />
              </Col>
            </Row>
          )}
        </TableContainer>
        <div className='px-1'>
          <BasicCard header='Donadores' handle={handleExport}>
            <LightTable
              data={item.donations}
              columns={[
                { dataField: 'email', text: 'Correo electrónico', sort: true },
                { dataField: 'createdAt', text: 'Fecha de selección', formatter: dmy }
              ]}
            />
          </BasicCard>
        </div>
      </Admin>
    )
  }
}

function BasicCard({ header, children, handle }) {
  return (
    <div className='card shadow mb-5 mx-2'>
      {!isEmpty(header) && (
        <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
          <h6 className='m-0 font-weight-bold text-primary'>{header}</h6>
          <button className='btn btn-success' onClick={handle}>
            <i className='fas fa-save' /> Descargar
          </button>
        </div>
      )}
      <div className='card-body'>{children}</div>
    </div>
  )
}

const mapStateToProps = ({ holidayProject }) => ({ holidayProject })
export default connect(mapStateToProps, actions)(HolidayProjectView)
