import { addItem, updatedItem } from '../../util/helpers'
import {
  GET_CONTACT,
  POST_CONTACT,
  UPDATE_CONTACT,
  GET_All_CONTACT,
  GET_All_CLEAN_CONTACT
} from '../actions/types'

const initialState = {
  list: [],
  view: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONTACT:
      return {
        ...state,
        view: { ...action.payload }
      }
    case POST_CONTACT:
      return {
        ...state,
        list: addItem(state.list, action.payload)
      }
    case UPDATE_CONTACT:
      return {
        ...state,
        list: updatedItem(state.list, action.payload)
      }
    case GET_All_CONTACT:
      return {
        ...state,
        list: [...action.payload]
      }
    case GET_All_CLEAN_CONTACT:
      return {
        ...state,
        list: [...action.payload]
      }
    default:
      return state
  }
}