import React, { Fragment } from 'react'
import { Input, Col, Row } from 'reactstrap'
import FormGroupValid from '../lib/FormGroupValid'
import { FormattedMessage } from 'react-intl';

const DonationsForm = ({ donations_startDate, donations_endDate, donations_text, donations_showCountDown, donations_showCounter, handleInputChange, onChange }) => {
  return (
    <Fragment>

      <Row>
        <Col md={6}>
          <FormGroupValid forp="donations_startDate" label={<FormattedMessage id="label.startDonation" />} {...donations_startDate}>
            <Input
              type="date"
              id="donations_startDate"
              name="donations_startDate"
              value={donations_startDate.value}
              valid={donations_startDate.valid}
              invalid={donations_startDate.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>

        <Col md={6}>
          <FormGroupValid forp="donations_endDate" label={<FormattedMessage id="label.endDonation" />} {...donations_endDate}>
            <Input
              type="date"
              id="donations_endDate"
              name="donations_endDate"
              value={donations_endDate.value}
              valid={donations_endDate.valid}
              invalid={donations_endDate.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupValid forp="donations_text" label={<FormattedMessage id="label.legend" />} {...donations_text}>
            <Input
              type="textarea"
              id="donations_text"
              name="donations_text"
              value={donations_text.value}
              valid={donations_text.valid}
              invalid={donations_text.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className="form-group col">
            <div className="form-check">
              <input
                type="checkbox"
                id="donations_showCountDown"
                name="donations_showCountDown"
                checked={donations_showCountDown}
                onChange={onChange}
                className="form-check-input" />
              <label className="form-check-label">
                <FormattedMessage id="label.showCountDown" />
              </label>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group col">
            <div className="form-check">
              <input
                type="checkbox"
                id="donations_showCounter"
                name="donations_showCounter"
                checked={donations_showCounter}
                onChange={onChange}
                className="form-check-input" />
              <label className="form-check-label">
                <FormattedMessage id="label.showCounter" />
              </label>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment >
  )
}

export default DonationsForm
