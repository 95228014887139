import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TextFieldGroup = ({
  name,
  type,
  info,
  value,
  error,
  disabled,
  className,
  onChange,
  placeholder,
  fcs,
  fgs,
  children
}) => {
  return (
    <div className={`form-group ${fgs && fgs}`}>
      {children}
      <input
        type={type}
        name={name}
        value={value ? value : ''}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        className={classnames(`${fcs ? fcs : 'form-control ' + className}`,
          { 'is-invalid': error })} />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && (<div className="invalid-feedback"> {error}</div>)}
    </div>
  )
}

TextFieldGroup.propTyoes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  info: PropTypes.string,
  //value: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default TextFieldGroup