import React, { Component, Fragment } from 'react'
import Navbar from '../Common/Navbar/Navbar'
import CarouselSection from '../CarouselSection/CarouselSection'
import AboutSection from '../AboutSection/AboutSection'
import CountersSection from '../CountersSection/CountersSection'
import DonationSection from '../DonationSection/DonationSection'
import VolunteerSection from '../VolunteerSection/VolunteerSection'
import Footer from '../Common/Footer/Footer'
import VideoSection from '../VideoSection/VideoSection'
import DareToShareSection from '../DareToShareSection/DareToShareSection'

import ScrollToTopOnMount from './../Common/ScrollToTopOnMount'
import CampaingSlider from '../../Campaign/CampaingSlider'

export default class Lading extends Component {
  render() {
    return (
      <Fragment>
        <ScrollToTopOnMount />
        <Navbar />
        <CarouselSection />
        <CampaingSlider />
        <AboutSection />
        <CountersSection />
        <DonationSection />
        <VolunteerSection />
        <DareToShareSection />
        <VideoSection />
        <Footer />
      </Fragment>
    )
  }
}
