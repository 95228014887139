import React from 'react'
import styles from './dts-voluntariado.module.css'
import ContentWrapper from './../../../../common/ContentWrapper/ContentWrapper'
import { FormattedMessage } from 'react-intl'

const DTSVoluntariado = () => (
  <div className={`text-center`} >
    <div className="container">
      <div className="row" >
        <ContentWrapper
          className={`margin-bottom: 5%`}
          title={<FormattedMessage id="dare.voluntTit" />}
          content={<FormattedMessage id="dare.voluntTxt" />}
          titleClass={styles['subtitleDts']}
          contentClass="contentPrograms text-center"
        />
      </div>
    </div>
  </div>
);

export default DTSVoluntariado