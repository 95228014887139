import { createActions, createReducer } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    createConektaDonationRequest: ['payload', 'cb'],
    createConektaDonationSuccess: ['payload'],
    createConektaDonationFailure: ['payload'],

    requestOxxoPayDonationRequest: ['payload', 'cb'],
    requestOxxoPayDonationSuccess: ['payload'],
    requestOxxoPayDonationFailure: ['payload']
  },
  {}
)

const initialState = {
  oxxopay: {}
}

export const HANDLERS = {
  [Types.REQUEST_OXXO_PAY_DONATION_SUCCESS]: function(state, action) {
    return { ...state, oxxopay: { ...action.payload } }
  }
}

export const donationTypes = Types
export const donationActions = Creators
export const donation = createReducer(initialState, HANDLERS)
