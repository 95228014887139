import axios from 'axios'
import swal from '@sweetalert/with-react'
import { GET_VIDEO, POST_VIDEO, GET_ERRORS } from './types'
import { loadingPage } from './loadingpageAction'


// Action creator:
// GET the active video (Public)
export const getVideo = () => dispatch => {
  axios
    .get('/api/video/active')
    .then(r => r.data)
    .then(payload => dispatch({ type: GET_VIDEO, payload }))
    .catch(error =>  dispatch({ type: GET_ERRORS, payload: error }))
}

// POST a new video (Private - Corporative)
export const postVideo = (payload, history, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .post('/api/video/activate', payload)
    .then(r => r.data)
    .then(payload => dispatch({ type: POST_VIDEO, payload }))
    .then(() => dispatch(loadingPage()))
    .then(() => swal({title: intl.formatMessage({id: 'alert.videoUploaded'}), text:'', icon:'success'}))
    .then(go => go ? history.push('/') : '')
    .then(() => window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}
