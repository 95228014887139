import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { dmy } from './../../util/dateHelpers'
import { isEmpty } from './../../util/helpers'
import TableContainer from './../common/Container/TableContainer'
import { ListItem } from './../common/ListItem'
import Admin from './../Layout/Admin/Admin'
import { donationPaypalGetOneRequest } from './paypal.redux'
import { useParams } from 'react-router-dom'

function PaypalDonationView(props) {
  const { view } = props
  const location = useParams()

  console.log(view)

  useEffect(() => {
    props.donationPaypalGetOneRequest(location.id)
  }, [])

  return (
    <Admin>
      <TableContainer
        showButtons={false}
        title='Donación Paypal'
        subtitle='Información del registro'
        view={view}>
        {!isEmpty(view) && (
          <Fragment>
          <Row id='view-donation'>
              <Col>
                <ul>
                  <ListItem label='Estatus' value={view.paypal.status} />
                  <ListItem
                    label='Proyecto'
                    value={view.project.title}
                  />
                  <ListItem
                    label='Descripcion'
                    value={view.project.description}
                  />
                </ul>
              </Col>
              <Col>
                <ul>
                <ListItem
                    label='Nombre'
                    value={`${view.paypal.purchase_units[0].shipping.name.full_name}`}
                  />
                  <ListItem
                    label='Correo'
                    value={`${view.paypal.purchase_units[0].payee.email_address}`}
                  />
                </ul>
              </Col>
            </Row>
            <Row>
            {view.currency !== 'USD' &&
                <Col>
                  <ul>
                    <ListItem label='Fecha' value={dmy(view.paypal.update_time)} />
                    <ListItem label='Donación bruta' value={`$${(view.originalAmount || view.amount).toFixed(2)} ${view.currency}`} />
                    <ListItem label='Comisión' value={`$${view.originalFee.toFixed(2)} ${view.currency}`} />
                    <ListItem label='Donación neta' value={`$${(view.originalAmount - view.originalFee).toFixed(2)} ${view.currency}`} />
                  </ul>
                </Col>
              }
            </Row>

          </Fragment>
        )}
      </TableContainer>
    </Admin>
  )
}

const mapStateToProps = ({ paypal }) => ({ view: paypal.view })

const mapDispatchToProps = {
  donationPaypalGetOneRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PaypalDonationView)
