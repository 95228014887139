import React, { Fragment } from 'react'
import { Form, FormGroup, Label, Input, FormFeedback, FormText, Row, Col } from 'reactstrap'
import { isEmpty } from '../../util/inputsHelpers'
import { FormattedMessage } from 'react-intl'

const InputField = props => {
  return (
    <FormGroup>
      <Label for={props.name}>{props.label}</Label>
      <Input
        type={props.type}
        name={`fields.${props.name}`}
        valid={props.valid}
        invalid={props.invalid}
        value={props.value}
        placholder={props.placeholder}
        onChange={props.handleChange}
        disabled={props.disabled}>
        {props.children}
      </Input>
      <FormFeedback valid={props.valid}>
        <ul className='list-unstyled'>
          {!isEmpty(props.feed) && props.feed.map(f => <li className=''>{f}</li>)}
        </ul>
      </FormFeedback>
      <FormText>{!isEmpty(props.text) && props.text}</FormText>
    </FormGroup>
  )
}

const DonationsForm = ({ fields, handleChange, handleCheckbox, projects }) => {
  const currencies = ['BRL', 'USD', 'MXN', 'EUR']
  return (
    <Fragment>
      <Form>
        <Row>
          <Col md='3'>
            {!fields.approved.value && (
              <InputField
                name='currency'
                type='select'
                label={<FormattedMessage id='label.currency' />}
                valid={fields.currency.valid}
                invalid={fields.currency.invalid}
                handleChange={handleChange}
                value={fields.currency.value}
                feed={fields.currency.feed}
                text={fields.currency.text}
                disabled={!isEmpty(fields.project.value)}>
                <option></option>
                {currencies.map((currency, i) => (
                  <option key={`currency-${i}`} value={currency}>
                    {currency}
                  </option>
                ))}
              </InputField>
            )}
          </Col>
          <Col md='4'>
            {!fields.approved.value && (
              <InputField
                name='amount'
                type='number'
                label={<FormattedMessage id='label.amount' />}
                valid={fields.amount.valid}
                invalid={fields.amount.invalid}
                handleChange={handleChange}
                value={fields.amount.value}
                placeholder='(USD)'
                feed={fields.amount.feed}
                text={fields.amount.text}
              />
            )}
          </Col>
          <Col md='5'>
            {!fields.approved.value && (
              <InputField
                name='type'
                type='select'
                label={<FormattedMessage id='label.kind' />}
                valid={fields.type.valid}
                invalid={fields.type.invalid}
                handleChange={handleChange}
                value={fields.type.value}
                feed={fields.type.feed}
                text={fields.type.text}>
                <option></option>
                <option value='manual'>Manual</option>
                <option value='transfer'>Transfer</option>
                <option value='softtek'>Softtek</option>
                <option value='others'>Others</option>
              </InputField>
            )}
          </Col>
        </Row>

        <InputField
          name='project'
          type='select'
          label={<FormattedMessage id='label.project' />}
          handleChange={handleChange}
          value={!isEmpty(fields.project) && fields.project.value}
          text={
            <FormattedMessage
              id={isEmpty(projects) ? 'general.actually' : 'donationLabel.projectD'}
            />
          }
          disabled={isEmpty(projects)}>
          <option></option>
          {projects
            .filter(r => r.active)
            .map((option, i) => (
              <option key={i} value={option._id}>
                {option.title}
              </option>
            ))}
        </InputField>

        {!fields.approved.value && (
          <InputField
            name='description'
            type='textarea'
            label={<FormattedMessage id='label.description' />}
            valid={fields.description.valid}
            invalid={fields.description.invalid}
            handleChange={handleChange}
            value={fields.description.value}
            feed={fields.description.feed}
            text={fields.description.text}
          />
        )}

        {!fields.approved.value && (
          <FormGroup check>
            <Input
              type='checkbox'
              name='fields.needReceipt'
              id='needReceipt'
              checked={fields.needReceipt.value}
              onChange={handleCheckbox}
            />
            <Label check>
              <FormattedMessage id='label.receipt' />
            </Label>
          </FormGroup>
        )}
      </Form>
    </Fragment>
  )
}

export default DonationsForm
