import axios from 'axios'
import swal from '@sweetalert/with-react'
import { isEmpty } from '../../util/isEmpty'
import {
  EDIT_NEWS,
  GET_ERRORS,
  GET_All_NEWS,
  GET_CLEAN_NEWS,
  EDIT_CLEAN_NEWS,
  GET_All_CLEAN_NEWS,
  GET_NEWS, POST_NEWS, UPDATE_NEWS,
} from './types'
import { loadingPage } from './loadingpageAction'
import { currentStatus, isClosed, removeCharFromDate, getStatus } from './../../components/Campaign/Helpers/projectStatus'

// GET ALL NEWS FOR ADMINS
export const getAllNewsAdmin = intl => dispatch => {
  dispatch(loadingPage())
  axios
    .get('/api/newsadmin')
    .then(r => {
      const payload = r.data.map(r => ({
        ...r,
        status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(r.publishDate, r.endDate, false, r.active)
      }))
      dispatch({ type: GET_All_NEWS, payload })
    })
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET ALL NEWS (USER)
export const getAllNewsUser = intl => dispatch => {
  dispatch(loadingPage())
  axios
    .get('/api/news')
    .then(r => {
      const payload = r.data.map(r => ({
        ...r,
        status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(r.publishDate, r.endDate, false, r.active)
      }))
      dispatch({ type: GET_All_NEWS, payload })
    })
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET A NEWS
export const getNews = (id, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .get(`/api/news/${id}`)
    .then(res => {
      const r = res.data
      const payload = {
        ...r,
        status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(r.publishDate, r.endDate, false, r.active)
      }
      dispatch({ type: GET_NEWS, payload })
    })
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// EDIT A NEWS
export const editNews = id => dispatch => {
  dispatch(loadingPage())
  axios
    .get(`/api/news/${id}`)
    .then(r => {
      dispatch({ type: EDIT_NEWS, payload: r.data })
      dispatch(loadingPage())
    })
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// POST NEWS
export const postNews = (d, history, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .post(`/api/news`, d)
    .then(r => dispatch({ type: POST_NEWS, payload: r.data }))
    .then(() => swal({ title: intl.formatMessage({ id: 'alert.newCreate' }), text: '', icon: 'success', closeOnClickOutside: false, closeOnEsc: false }))
    .then(go => go ? history.push('/news/gallerie') : '')
    .then(() => dispatch(loadingPage()))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// UPDATE NEWS
export const updateNews = (id, payload, history, intl) => dispatch => {
  swal({
    title: intl.formatMessage({ id: 'alert.desTitle' }),
    icon: 'warning',
    buttons: {
      error: {
        text: intl.formatMessage({ id: 'alert.causeErrorText' }),
        value: intl.formatMessage({ id: 'alert.causeErrorText' })
      },
      actualizacion: {
        text: intl.formatMessage({ id: 'alert.upText' }),
        value: intl.formatMessage({ id: 'alert.upText' })
      }
    }
  }).then(cause => {
    if(!isEmpty(cause)) {
      swal({ title: intl.formatMessage({ id: 'alert.desTitle' }), content: "input", buttons: true })
        .then(justification => {
          if (!isEmpty(justification)) {
            dispatch(loadingPage())
            const detail = { cause, description: justification }
            const d = { detail, payload }
            axios
              .put(`/api/news/${id}`, d)
              .then(r => dispatch({ type: UPDATE_NEWS, payload: r.data }))
              .then(dispatch(loadingPage()))
              .then(() => swal({ title: intl.formatMessage({ id: 'alert.changes' }), text:'', icon: 'success', closeOnClickOutside: false, closeOnEsc: false }))
              .then(go => go ? history.push('/dashboard/news/list') : '')
              .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
          } else {
            swal(intl.formatMessage({ id: 'alert.changesOff' }), intl.formatMessage({ id: 'alert.writeCause' }), 'info')
          }
        })
        .catch((e) => console.log(e))
      }
    })
}

// ACTIVATE/DEACTIVATE NEWS
export const activateNews = (id, payload, history, intl) => dispatch => {
  swal({
    title: intl.formatMessage({ id: 'alert.confiTitle' }),
    text: `${intl.formatMessage({ id: 'alert.confiText' })} ${payload.active ? intl.formatMessage({ id: 'alert.on' }) : intl.formatMessage({ id: 'alert.off' })}`,
    icon: 'warning',
    buttons: true
  })
    .then(confirmation => {
      if (confirmation === true) {
        swal({ title: intl.formatMessage({ id: 'alert.desTitle' }), content: "input" })
          .then(justification => {
            if (!isEmpty(justification)) {
              dispatch(loadingPage())
              const detail = {
                cause: `${payload.active ? intl.formatMessage({ id: 'alert.onCap' }) : intl.formatMessage({ id: 'alert.offCap' })}`,
                description: justification
              }
              const d = { payload, detail }
              axios
                .put(`/api/news/${id}`, d)
                .then(r => {
                  const payload = {
                    ...r.data,
                    status: currentStatus(isClosed, removeCharFromDate, getStatus, intl)(r.data.publishDate, r.data.endDate, false, r.data.active)
                  }
                  dispatch({ type: UPDATE_NEWS, payload })
                })
                .then(dispatch(loadingPage()))
                .then(() => swal({ title: '', text: d.payload.active ? intl.formatMessage({ id: 'alert.registerActivate' }) : intl.formatMessage({ id: 'alert.registerDeactivate' }), icon: 'success' }))
                .then(go => go ? history.push('/dashboard/news/list') : '')
                .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
            } else {
              swal(intl.formatMessage({ id: 'alert.changesOff' }), intl.formatMessage({ id: 'alert.writeCause' }), 'info')
            }
          })
      }
    })
    .catch((e) => console.log(e))
}

// CLEAN A NEWS VIEW FROM THE STATE
export const getCleanNews = () => ({
  type: GET_CLEAN_NEWS,
  payload: {}
})

// CLEAN A NEWS VIEW FROM THE STATE
export const editCleanNews = () => ({
  type: EDIT_CLEAN_NEWS,
  payload: {}
})

// CLEAN THE NEWS LIST FROM THE STATE
export const getAllCleanNews = () => ({
  type: GET_All_CLEAN_NEWS,
  payload: []
})