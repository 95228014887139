import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
// import jwt_decode from 'jwt-decode'
import { isEmpty } from '../../util/isEmpty'
import { loadingPageOff } from '../../redux/actions/loadingpageAction'
import { logoutUser } from '../../redux/actions/authActions'
import { removeErrors } from '../../redux/actions/errorActions'
import { injectIntl } from 'react-intl'
export class Error extends Component {
  componentWillReceiveProps(np) {
    if (!isEmpty(np.errors)) {
      this.props.loadingPageOff()
      const { intl } = this.props
      // ajax error response
      if (!isEmpty(np.errors.response)) {
        // need it porps
        // const data = np.errors.response.data
        const status = np.errors.response.status
        const message = np.errors.response.data.message

        // token expires
        if (status === 401 && isEmpty(message)) {
          swal({
            title: intl.formatMessage({ id: 'alert.token' }),
            text: '',
            icon: 'warning'
          })

          this.props.logoutUser()
          this.props.removeErrors()
        }

        // Unauthorized !
        if (status === 401 && !isEmpty(message)) {
          swal({
            title: intl.formatMessage({ id: message }),
            text: '',
            icon: 'warning'
          })

          this.props.removeErrors()
        }

        // credenciales invalidas
        if (status === 400 && !isEmpty(message) && message === 'alert.credentials') {
          swal({
            title: intl.formatMessage({ id: message }),
            text: '',
            icon: 'warning'
          })

          this.props.removeErrors()
        }

        // Este mensaje siempre saldra cuando un registro ya esta registrado
        if (status === 400 && !isEmpty(message) && message === 'alert.alreadyExist') {
          swal({
            title: intl.formatMessage({ id: message }),
            text: '',
            icon: 'warning'
          })

          this.props.removeErrors()
        }

        // message from the server with out multi-language
        if (
          !isEmpty(message) &&
          !message.includes('.') &&
          status !== 401 &&
          status !== 400
        ) {
          swal('Error', message, 'error')
          this.props.removeErrors()
        }
        // message from the server with multi-language
        if (
          !isEmpty(message) &&
          message.includes('.') &&
          status !== 401 &&
          status !== 400
        ) {
          swal('Error', intl.formatMessage({ id: message }), 'error')
          this.props.removeErrors()
        } else {
          this.props.removeErrors()
        }
      } else {
        console.log(np.errors)
        swal({
          title: intl.formatMessage({ id: 'alert.serviceUnavailable' }),
          text: intl.formatMessage({ id: 'alert.noAdblocker' }),
          icon: 'warning'
        })
        this.props.removeErrors()
      }
    }
  }

  render() {
    return <Fragment>{''}</Fragment>
  }
}

Error.propTypes = {
  errors: PropTypes.object.isRequired,
  loadingpage: PropTypes.object.isRequired,
  loadingPageOff: PropTypes.func.isRequired,
  removeErrors: PropTypes.func.isRequired
}
const mapStateToProps = ({ errors, loadingpage }) => ({ errors, loadingpage })
const mapDispatchToProps = { loadingPageOff, logoutUser, removeErrors }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Error))
