import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from './mailing.redux'
import { Row, Col } from 'reactstrap'
import { mdyhm } from '../../util/dateHelpers'
import { isEmpty } from '../../util/helpers'
import TableContainer from '../common/Container/TableContainer'
import { ListItem } from '../common/ListItem'
import Admin from '../Layout/Admin/Admin'
import { LightTable } from '../common/Table/LightTable'
import axios from 'axios'
import moment from 'moment'
import { downloadFile } from '../../util/download'

class MailingView extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.loadMailingRequest({ id })
  }

  componentWillUnmount() {
    this.props.loadMailingSuccess()
  }

  getReportName = () => `Emails_${moment().format('DD-MM-YY')}.xlsx`
  getStatusLabel = (_, item) => (!item.date ? 'Pendiente' : mdyhm(item.date))
  getDateLabel = (_, item) => (!item.date ? 'Pendiente' : item.succeed ? 'Sí' : 'No')

  render() {
    const { view: item } = this.props.mailing
    const templates = { 'end-of-year-not-participated-us': 'Agradecimiento US' }

    const handleExport = () => {
      const { id } = this.props.match.params
      axios({
        url: '/api/mailing/export/' + id,
        method: 'POST',
        responseType: 'blob'
      })
        .then(downloadFile(this.getReportName()))
        .catch(console.error)
    }

    return (
      <Admin>
        <TableContainer
          title='Envío de correos'
          subtitle='Información del envío'
          showButtons={false}>
          {!isEmpty(item) && (
            <Row>
              <Col md='7'>
                <ul>
                  <ListItem label='Plantilla de correo' value={templates[item.template]} />
                  <ListItem label='Creador' value={item.created_by.name} />
                  <ListItem label='Autor' value={mdyhm(item.createdAt)} />
                </ul>
              </Col>
            </Row>
          )}
        </TableContainer>
        <div className='px-1'>
          <BasicCard header='Donadores' onClick={handleExport}>
            <LightTable
              data={item.emails}
              columns={[
                { dataField: 'email', text: 'Correo electrónico', sort: true },
                {
                  dataField: 'succeed',
                  text: 'Envío exitoso',
                  formatter: this.getDateLabel
                },
                {
                  dataField: 'createdAt',
                  text: 'Fecha',
                  formatter: this.getStatusLabel
                }
              ]}
            />
          </BasicCard>
        </div>
      </Admin>
    )
  }
}
function BasicCard({ header, children, onClick }) {
  return (
    <div className='card shadow mb-5 mx-2'>
      {!isEmpty(header) && (
        <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
          <h6 className='m-0 font-weight-bold text-primary'>{header}</h6>
          <button className='btn btn-success' onClick={onClick}>
            <i className='fas fa-save' /> Descargar
          </button>
        </div>
      )}
      <div className='card-body'>{children}</div>
    </div>
  )
}

const mapStateToProps = ({ mailing }) => ({ mailing })
export default connect(mapStateToProps, actions)(MailingView)
