import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from './holiday-project.redux'
import HolidayProjectForm from './HolidayProjectForm'
import { reset, initialize } from 'redux-form'
import Admin from '../Layout/Admin/Admin'

class HolidayProjectCreate extends Component {
  componentDidMount() {
    this.props.initialize('holiday-project', {})
  }

  componentWillUnmount() {
    this.props.resetForm('holiday-project')
  }

  submit = values => {
    this.props.createHolidayProject(values)
  }

  render() {
    return (
      <Admin>
        <div className='header-h2 col'>
          <h1 className='h3 mb-2 text-gray-800'>Agregar campaña</h1>
        </div>
        <HolidayProjectForm {...this.props} label='Agregar' onSubmit={this.submit} />
      </Admin>
    )
  }
}

const mapStateToProps = ({ holidayProject }) => ({ holidayProject })
const mapDispatchToProps = {
  createHolidayProject: actions.createHolidayProjectRequest,
  resetForm: reset,
  initialize
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HolidayProjectCreate)
