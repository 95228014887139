import React from "react"
import Micro from './img/developer-01.svg'
import Tra from './img/respect-01.svg'
import styles from './volunteerSection.module.css'
import '../../../assets/css/fundacionSolidaria.css'
import { FormattedMessage } from 'react-intl'
import VoluntariosSection from './VoluntariosSection/VoluntariosSection'
import ContentWrapper from './../../common/ContentWrapper/ContentWrapper'

const voluntario = [
  { icon: Micro, title: <FormattedMessage id="volunteer.microTit" />, title2: <FormattedMessage id="volunteer.microTit2" />, text: <FormattedMessage id="volunteer.microTxt" />, isActive: true },
  { icon: Tra, title: <FormattedMessage id="volunteer.tradTit" />, title2: <FormattedMessage id="volunteer.tradTit2" />, text: <FormattedMessage id="volunteer.tradTxt" />, isActive: true }
]

const VolunteerSection = () => (
  <section>
    <div className={`text-center ${styles['backgroundVolunteerYellow']}`}>
      <div className={`container`} >
        <ContentWrapper
          className='my-0'
          pretitle={<FormattedMessage id="dare.title" />}
          title={<FormattedMessage id="volunteer.title" />}
          titleClass={`${styles['subtitle']} my-5`}
        />
          <div className='row mb-5 card-deck'>
          {
            voluntario.map((c, i) => (
              <VoluntariosSection key={c.label + '-' + i} {...c} />
            ))
          }
          </div>
        </div>
      </div>
  </section>
)
export default VolunteerSection