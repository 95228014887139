import React, { Component } from 'react'
import { FilePond, File, registerPlugin } from 'react-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import PropTypes from 'prop-types'
import swal from '@sweetalert/with-react'
import { injectIntl } from 'react-intl'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop
)
class UploadFiles extends Component {
  state = { files: [] }
  render() {
    return (
      <FilePond
        ref={ref => (this.pond = ref)}
        allowMultiple={this.props.allowMultiple ? true : false}
        maxFiles={this.props.maxFiles ? this.props.maxFiles : 1}
        server={{
          process: {
            url: this.props.url,
            method: 'POST',
            headers: { Authorization: `Bearer ${localStorage.token}` },
            onload: response => this.props.file(JSON.parse(response)),
            onerror: error => {
              const { intl } = this.props
              swal({
                title: intl.formatMessage({ id: 'alert.file' }),
                text: '',
                icon: 'warning'
              })
            }
          }
        }}
        onupdatefiles={fileItems => {
          this.setState({ files: fileItems.map(fileItem => fileItem.file) }, () => {
            if (this.props.onupdatefiles) this.props.onupdatefiles(fileItems)
          })
        }}>
        {/* Update current files  */}
        {this.state.files.map(file => (
          <File key={file} src={file} origin='local' />
        ))}
      </FilePond>
    )
  }
}
UploadFiles.prototypes = {
  allowMultiple: PropTypes.object.isRequired,
  maxFiles: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  url: PropTypes.object.isRequired
}
export default injectIntl(UploadFiles)
