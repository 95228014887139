import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { isEmpty } from '../../../../util/isEmpty'
import {
  Button,
  Modal as ModalB,
  ModalBody,
  Row,
  Col,
  Container,
  FormGroup,
  Input
} from 'reactstrap'
import config from '../../../../config'
import { projectDonationPayPal } from '../../../../redux/actions/donationsActions'
import { dispatchError } from '../../../../redux/actions/errorActions'
import { injectIntl, FormattedMessage } from 'react-intl'
import { decryptionAES } from '../../../../util/encryption'
import { Link } from 'react-router-dom'
import PrivacyTermsContent from '../../../Main/PrivacyTerms/PrivacyTermsContent'
import { PayPalButton as PayPal } from 'react-paypal-button-v2'

const initialState = {
  sandbox: '', // clientId
  production: '', // secret
  amount: 0,
  type: 'paypal',
  approved: true,
  checkLegal: false
}

export class Paypalbtn extends Component {
  state = {
    modal: false,
    ...initialState,
    currency: 'USD'
  }

  componentDidMount() {
    const { _id, sede, subsede } = this.props.campaing.view
    const userSede = this.props.location.sede
    const currency = !isEmpty(userSede) ? userSede.currency : 'USD'
    this.setState({
      sede: sede._id,
      currency,
      subsede: subsede._id,
      project: _id
    })
  }

  componentWillReceiveProps(np) {
    const { _id, sede, subsede } = np.campaing.view
    const userSede = np.location.sede
    const currency = !isEmpty(userSede) ? userSede.currency : 'USD'
    this.setState({
      sede: sede._id,
      currency,
      subsede: subsede._id,
      project: _id
    })
  }

  toggle = () => {
    this.setState({ ...initialState, modal: !this.state.modal })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  // Congratulation, it came here means everything's fine!
  onSuccess = payment => {
    console.log(payment)
    let paypal = { ...payment }
    const { sede, subsede, approved, amount, type, project, currency } = this.state
    const payload = { sede, subsede, approved, amount, type, paypal, project, currency }
    const { intl } = this.props
    this.props.projectDonationPayPal(payload, intl, () => this.toggle())
  }

  // User pressed "cancel" or close Paypal's popup!
  onCancel = data => {
    this.toggle()
  }

  // The main Paypal's script cannot be loaded or somethings block the loading of that script!
  onError = error => {
    console.log(error)
    this.props.dispatchError(error)
  }

  render() {
    const { intl } = this.props
    const { view: project } = this.props.campaing
    let onTime = false
    let canDonate = false
    let client = {}
    let currency = {}

    // check the life of donations
    if (!isEmpty(project)) {
      const today = moment()
      onTime = moment(today).isBetween(project.startDate, project.endDate, 'days', '[]')
    }
    // Validation for donations
    if (
      !isEmpty(project.sede) &&
      !isEmpty(project.sede._id) &&
      !isEmpty(this.props.location.sede) &&
      // A user can only donate to project's that are in his country
      project.sede._id === this.props.location.sede._id &&
      !isEmpty(project.sede.termsofPrivacy) &&
      !isEmpty(project.sede.paypal) &&
      !isEmpty(project.sede.paypal.account) &&
      !isEmpty(project.sede.paypal.clientId) &&
      !isEmpty(project.sede.paypal.secret) &&
      !isEmpty(project) &&
      !isEmpty(project.subsede.email) &&
      project.closed === false &&
      onTime
    ) {
      canDonate = true
      currency = this.state.currency
      const {
        sede: {
          paypal: { clientId }
        }
      } = project
      // Use the appropiated environment
      client =
        config.paypal === 'production'
          ? decryptionAES(clientId, config.secret)
          : decryptionAES(clientId, config.secret)
    }

    return (
      <Fragment>
        {canDonate && (
          <Fragment>
            <div className='buttonProjectPage__wrapper'>
              <Button
                color=''
                size='md'
                block={true}
                onClick={this.toggle}
                className='buttonProjectPage paypal-pay-button'>
                PayPal
                {/* <FormattedMessage id='btn.donation' /> */}
              </Button>
            </div>

            <ModalB
              size='lg'
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={`${this.props.className}`}>
              <ModalBody>
                <Container fluid={true}>
                  <Row>
                    <Col md={{ size: 4, offset: 4 }}>
                      <h5 className='text-center'>
                        <span className='text-muted'>
                          <FormattedMessage id='table.amount' />{' '}
                          <small>${this.state.currency}</small>
                        </span>
                      </h5>
                      <FormGroup>
                        <Input
                          min={1}
                          type='number'
                          name='amount'
                          className='text-center'
                          value={this.state.amount}
                          onChange={this.handleInputChange}
                          disabled={!this.state.checkLegal}
                          placeholder={`$${this.state.currency}`}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 4, offset: 4 }}>
                      <ul className='list-unstyled'>
                        <li className={!this.state.checkLegal && 'pointer-events-none'}>
                          <PayPal
                            amount={this.state.amount}
                            style={{layout: 'horizontal', tagline: 'false'}}
                            options={{
                              currency: currency,
                              clientId: client,
                            }}
                            onSuccess={this.onSuccess}
                          />
                        </li>
                      </ul>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={{ size: 10, offset: 1 }}>
                      <div className='text-center'>
                        <label className='word-break-normal'>
                          <input
                            type='checkbox'
                            name='checkLegal'
                            checked={this.state.checkLegal}
                            onChange={this.handleInputChange}
                          />{' '}
                          {intl.formatMessage({ id: 'label.confirmOfRead' })}
                          <Link to='/notice-privacy'>
                            {intl.formatMessage({ id: 'label.noticeOfPrivacy' })}
                          </Link>
                        </label>
                      </div>
                      {this.state.checkLegal && (
                        <div className='terms-conditions-small-container'>
                          <PrivacyTermsContent className='my-3' />
                        </div>
                      )}
                      <p className='word-break-normal text-center'>
                        {' '}
                        {intl.formatMessage({ id: 'paypal.head' })}{' '}
                        <a href='mailto:fundacion.solidaria@softtek.com'>
                          fundacion.solidaria@softtek.com
                        </a>
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={{ size: 10, offset: 1 }} className='text-center'>
                      <p className='text-center word-break-normal'>
                        <span className='text-muted'>
                          {intl.formatMessage({ id: 'label.consult' })}{' '}
                          <Link to='/terms-and-conditions'>
                            {intl.formatMessage({ id: 'noticePrivacy.introduction.title' })}
                          </Link>{' '}
                          {intl.formatMessage({ id: 'label.site' })}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
            </ModalB>
          </Fragment>
        )}
      </Fragment>
    )
  }
}
Paypalbtn.propTypes = {
  auth: PropTypes.object.isRequired,
  campaing: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  projectDonationPayPal: PropTypes.func.isRequired,
  dispatchError: PropTypes.func.isRequired
}
const mapStateToProps = ({ auth, location, campaing }) => ({ auth, location, campaing })
const mapDispatchToProps = { projectDonationPayPal, dispatchError }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Paypalbtn))
