import React from 'react'
import CKEditor from "react-ckeditor-component"
import FormGroupCustom from '../lib/FormGroupCustom'
import { FormattedMessage } from 'react-intl';
// const validStyle = valid => valid ? { fontSize: "80%", color: "#28a745" } : { fontSize: "80%", color: "#dc3545" }
const config = {
  width: 'auto',
  height: '75vh',
  padding: '0px',
  margin: '0px',
}
const ContentForm = ({ onChangeEditor, content }) => {
  return (
    <div className="card shadow mb-4" style={{ marginBottom: '0px' }}>
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">
          <FormattedMessage id="label.content" />
        </h6>
      </div>

      <FormGroupCustom {...content}>
        <CKEditor
          id='content'
          config={config}
          activeClass="p10"
          content={content.value}
          events={{ "change": onChangeEditor }} />
      </FormGroupCustom>
    </div>
  )
}

export default ContentForm
