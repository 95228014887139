import { SET_LOADING_PAGE, SET_LOADING_PAGE_OFF } from '../actions/types'
const initialState = { loading: false }
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_PAGE:
      return { loading: !state.loading }
    case SET_LOADING_PAGE_OFF:
      return { loading: false }
    default:
      return state
  }
}