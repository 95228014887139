import React from 'react'
import style from './campaingTagText.module.css'

function TagText(props) {
  return (
    <li
      className={`list-inline-item ${style['tag-container']} ${props.className || ''}`}
      style={props.style}>
      <i className={`${props.icon} ${style['tag-icon']}`} />{' '}
      <strong className={style['tag']}>{props.children}</strong>
    </li>
  )
}

TagText.propTypes = {}

export default TagText
