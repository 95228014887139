import React, { Component } from 'react'
import Navbar from '../Common/Navbar/Navbar'
import Notify from '../../common/Notify'
import Footer from '../Common/Footer/Footer'
import Push from '../../common/Push/Push'

class Clean extends Component {
  render() {
    return (
      <div className='clean__main_layout'>
        <Notify />
        <Push />
        <Navbar />
        <div className='container clean__main_layout-content'>{this.props.children}</div>
        <Footer />
      </div>
    )
  }
}

export default Clean
