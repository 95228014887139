import React from 'react'

export default function HolidayProjectLogo({ alt, ...props }) {
  return (
    <img
      alt={alt}
      style={{
        width: '60%',
        display: 'block',
        margin: '30px auto'
      }}
      {...props}
    />
  )
}

HolidayProjectLogo.defaultProps = {
  alt: 'Logo'
}
