import React, { useState } from 'react'
import { connect } from 'react-redux'
import Card from 'react-credit-cards'
import { useParams } from 'react-router-dom'
import swal from 'sweetalert'
import { ConektaDonationModal } from './ConektaDonationModal'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from './../../../../util/payment'
import { donationActions } from './../../../Donations/donation.redux'
import * as conekta from './../../../../util/conekta'
import 'react-credit-cards/es/styles-compiled.css'
import './credit-card.css'

function ConektaCreditCardPayment({ createConektaDonationRequest }) {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const { id: project } = useParams()

  const INITIAL = {
    amount: null,
    number: '',
    name: '',
    expiry: '',
    email: '',
    phone: '',
    cvc: '',
    issuer: '',
    focused: ''
  }

  const [state, setState] = useState(INITIAL)
  let form = null

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setState({ ...state, issuer })
    }
  }

  const handleInputFocus = ({ target }) => {
    setState({ ...state, focused: target.name })
  }

  const handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value)
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value)
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value)
    }

    setState({ ...state, [target.name]: target.value })
  }

  const handleDonate = token => {
    const { email, phone, amount } = state
    createConektaDonationRequest(
      { token: token.id, name, amount, email, phone, project },
      () => {
        form.reset()
        setState(INITIAL)
        setModal(false)
      }
    )
  }

  const handleError = error => {
    swal('Error, intente nuevamente', 'La información no es correcta', 'error')
    console.error(error)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const { number, name, expiry, cvc } = state
    const [exp_month, exp_year] = expiry.split('/')

    conekta.tokenize(
      { number, name, exp_year: `20${exp_year}`, exp_month, cvc },
      handleDonate,
      handleError
    )
  }

  const { name, number, expiry, cvc, focused, issuer, amount } = state

  return (
    <ConektaDonationModal modal={modal} toggle={toggle} amount={amount}>
      <div key='Payment'>
        <div className='card-payment'>
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={handleCallback}
          />
          <form ref={c => (form = c)} onSubmit={handleSubmit}>
            <div className='form-group'>
              <input
                type='number'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                name='amount'
                placeholder='Cantidad a donar MXN'
              />
            </div>
            <div className='form-group'>
              <input
                type='tel'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                name='number'
                placeholder='Número de tarjeta'
                pattern='[\d| ]{16,22}'
              />
            </div>
            <div className='form-group'>
              <input
                type='tel'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                name='name'
                placeholder='Nombre completo'
              />
            </div>
            <div className='form-group'>
              {/* <div className='col-6'> */}
              <input
                type='tel'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                name='expiry'
                placeholder='Vencimiento'
                pattern='\d\d/\d\d'
              />
              {/* </div> */}
              {/* <div className='col-6'> */}
            </div>
            <div className='form-group'>
              <input
                type='tel'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                name='cvc'
                placeholder='CVC'
                pattern='\d{3,4}'
              />
            </div>

            <div className='form-group'>
              <input
                type='email'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                name='email'
                placeholder='Correo electrónico'
              />
            </div>
            <div className='form-group'>
              <input
                type='tel'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                name='phone'
                placeholder='Teléfono'
              />
            </div>
            <input type='hidden' name='issuer' value={issuer} />
            <div className='form-actions'>
              <button className='btn btn-primary btn-block mt-2'>Donar</button>
            </div>
          </form>
        </div>
      </div>
    </ConektaDonationModal>
  )
}

const withConnect = connect(null, donationActions)
export default withConnect(ConektaCreditCardPayment)
