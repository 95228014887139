import React, { Fragment } from 'react'
import { FormGroup, Label, Input, FormFeedback, FormText, Form } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

const InstitutionForm = ({ fields, handleChange }) => {
  return (
    <Fragment>
      <Form>
        <FormGroup>
          <Label for="name"><FormattedMessage id="label.name" /> :</Label>
          <Input
            type="text"
            name="fields.name"
            valid={fields.name.valid}
            invalid={fields.name.invalid}
            value={fields.name.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.name.valid}>
            <ul className="list-unstyled">
              {fields.name.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.name.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="address"><FormattedMessage id="label.address" /> :</Label>
          <Input
            type="text"
            name="fields.address"
            valid={fields.address.valid}
            invalid={fields.address.invalid}
            value={fields.address.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.address.valid}>
            <ul className="list-unstyled">
              {fields.address.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.address.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="country"><FormattedMessage id="label.country" /> :</Label>
          <Input
            type="text"
            name="fields.country"
            valid={fields.country.valid}
            invalid={fields.country.invalid}
            value={fields.country.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.country.valid}>
            <ul className="list-unstyled">
              {fields.country.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.country.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="state"><FormattedMessage id="label.state" /> :</Label>
          <Input
            type="text"
            name="fields.state"
            valid={fields.state.valid}
            invalid={fields.state.invalid}
            value={fields.state.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.state.valid}>
            <ul className="list-unstyled">
              {fields.state.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.state.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="city"><FormattedMessage id="label.city" /> :</Label>
          <Input
            type="text"
            name="fields.city"
            valid={fields.city.valid}
            invalid={fields.city.invalid}
            value={fields.city.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.city.valid}>
            <ul className="list-unstyled">
              {fields.city.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.city.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="zip"><FormattedMessage id="label.zip" /> :</Label>
          <Input
            type="text"
            name="fields.zip"
            valid={fields.zip.valid}
            invalid={fields.zip.invalid}
            value={fields.zip.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.zip.valid}>
            <ul className="list-unstyled">
              {fields.zip.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.zip.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="phone"><FormattedMessage id="label.phone" /> :</Label>
          <Input
            type="text"
            name="fields.phone"
            valid={fields.phone.valid}
            invalid={fields.phone.invalid}
            value={fields.phone.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.phone.valid}>
            <ul className="list-unstyled">
              {fields.phone.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.phone.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="email"><FormattedMessage id="label.email" /> :</Label>
          <Input
            type="text"
            name="fields.email"
            valid={fields.email.valid}
            invalid={fields.email.invalid}
            value={fields.email.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.email.valid}>
            <ul className="list-unstyled">
              {fields.email.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.email.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="web"><FormattedMessage id="label.web" /> :</Label>
          <Input
            type="text"
            name="fields.web"
            valid={fields.web.valid}
            invalid={fields.web.invalid}
            value={fields.web.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.web.valid}>
            <ul className="list-unstyled">
              {fields.web.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.web.text}</FormText>
        </FormGroup>

      </Form>
    </Fragment >
  )
}

export default InstitutionForm