import routes from './routes'
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import NotFoundPage from '../components/404/NotFoundPage'
import PrivateRoute from './PrivateRoute'
import { ConnectedRouter } from 'connected-react-router'
import { history } from '../redux/store'

export default class Router extends Component {
  render() {
    const routesList = routes.map((r, i) => {
      if (r.private)
        return (
          <PrivateRoute
            exact
            path={r.path}
            roles={r.roles}
            component={r.component}
            key={`private-route-${i}`}
          />
        )

      return <Route key={`public-route-${i}`} exact path={r.path} component={r.component} />
    })

    return (
      <ConnectedRouter history={history}>
        <Switch>
          {routesList}
          <Route path='*' component={NotFoundPage} />
        </Switch>
      </ConnectedRouter>
    )
  }
}
