import React, { Component } from 'react'
import ImgPreview from '../ImgPreview'

class Card2 extends Component {
  render() {
    const { path, url, onClick, projectId, _id, intl } = this.props

    return (
      <div className='col-lg-3 col-md-6 my-2'>
        <div className='card2__container'>
          <ImgPreview
            className='card2__image'
            style={{ height: 'auto' }}
            title={url}
            path={path}
            url={url}
          />

          <div className='card2__middle'>
            <button
              className='btn btn-danger'
              onClick={() => onClick(projectId, { _id }, intl)}>
              <i className='fa fa-trash'></i>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Card2
