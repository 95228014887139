import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { logoutUser } from '../../../../redux/actions/authActions'
import { isEmpty } from '../../../../util/isEmpty'
import logo from './img/logo-nav.png'
import { FormattedMessage, injectIntl } from 'react-intl'

import UserSVG from './../../../common/SVG/UserSVG'
import arrow from './img/arrow-verde-01.svg'
import world from './img/idioma_grey-01.svg'
import SelectLanguage from '../../../common/SelectLanguage/SelectLanguage'
import NavbarDropdown from './components/NavbarDropdown'

import dropdowns from './links'
import './navbar.css'

const FlagIcon = props => {
  const { className = '' } = props
  return <span className={`${className} flag-icon flag-icon-${props.code}`}></span>
}

class Navbar extends Component {
  state = {
    isOpen: false
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  logOut = event => {
    event.preventDefault()
    this.props.logoutUser()
  }

  toggleNavbar = () => {
    const navbar = document.querySelector('.nav-fs-collapse')
    const sidebarIcon = document.querySelector('.menu-toggle > i')
    if (sidebarIcon.classList.contains('fa-bars')) {
      sidebarIcon.className = 'fa fa-times'
    } else {
      sidebarIcon.className = 'fa fa-bars'
    }
    navbar.classList.toggle('active')
  }

  render() {
    const { isAuthenticated, user } = this.props.auth
    const { formatMessage } = this.props.intl
    const { sede } = this.props.location
    // const countryCode = sede.countryCode

    const firstName = !isEmpty(user.name) && user.name.split(' ')[0]
    let dashboard = {}

    if (user.rol !== 'softtekian') {
      dashboard = {
        title: `${formatMessage({ id: 'label.hello' })}, ${firstName}`,
        links: [{ label: formatMessage({ id: 'general.dashboard' }), link: '/dashboard' }]
      }
    } else {
      dashboard = {
        title: `${formatMessage({ id: 'label.hello' })}, ${firstName}`,
        links: [{ label: formatMessage({ id: 'general.profile' }), link: '/user' }]
      }
    }

    const authLinksDesktop = (
      <NavbarDropdown {...dashboard} className='authenticated'>
        <li>
          <NavLink to='#' onClick={this.logOut}>
            <img className='arrow-link' src={arrow} alt='' />
            <FormattedMessage id='general.logout' />
          </NavLink>
        </li>
      </NavbarDropdown>
    )

    const guestLinksDesktop = (
      <li>
        <NavLink to='/login'>
          <span className='btn-login'>
            <UserSVG className='btn-user' />
            <FormattedMessage id='general.login' />
          </span>
        </NavLink>
      </li>
    )

    const authLinksMobile = (
      <NavbarDropdown {...dashboard} className='link-hidden'>
        <li>
          <NavLink to='#' onClick={this.logOut}>
            <img className='arrow-link' src={arrow} alt='' />
            <FormattedMessage id='general.logout' />
          </NavLink>
        </li>
      </NavbarDropdown>
    )

    const guestLinksMobile = (
      <li className='link-hidden'>
        <NavLink to='/login'>
          <FormattedMessage id='general.login' />
        </NavLink>
      </li>
    )

    return (
      <Fragment>
        <header className='nav-fs'>
          <div className='menu-toggle' onClick={this.toggleNavbar}>
            <i className='fa fa-bars'></i>
          </div>
          <div className='logo'>
            <NavLink to='/'>
              <img src={logo} title='' alt='' />
            </NavLink>
            {sede.countryCode && (
              <FlagIcon code={sede.countryCode.toLowerCase()} className='flag-icon-mobile' />
            )}
          </div>
          <nav className='nav-fs-collapse'>
            <ul>
              <li>
                <NavLink to='/'>
                  <FormattedMessage id='nav.home' />
                </NavLink>
              </li>
              {dropdowns.map(({ title, links }, i) => {
                return <NavbarDropdown key={`dropdown-${title}`} title={title} links={links} />
              })}
              <li>
                <NavLink to='/contact'>
                  <FormattedMessage id='nav.contact' />
                </NavLink>
              </li>
              {isAuthenticated ? authLinksMobile : guestLinksMobile}
              <SelectLanguage
                className='link-hidden submenu-idioma'
                title={formatMessage({ id: 'label.language' })}
              />
            </ul>
          </nav>
          <nav>
            <ul>
              {isAuthenticated ? authLinksDesktop : guestLinksDesktop}
              <SelectLanguage
                title={<img width='30' src={world} alt='' />}
                className='language'
              />
              {sede.countryCode && <FlagIcon code={sede.countryCode.toLowerCase()} />}
            </ul>
          </nav>
        </header>
        {/* <section className="container-fluid navbar-buttons">
          <div className="d-flex justify-content-center">
            <PayPalBtn responsive />
            <ModalInitiative />
          </div>
        </section> */}
      </Fragment>
    )
  }
}

Navbar.prototypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth, location }) => ({ auth, location })
export default connect(mapStateToProps, { logoutUser })(injectIntl(Navbar))
