import React from 'react'
import Im1 from './img/1.jpg'
import Im2 from './img/2.jpg'
import Im3 from './img/8.jpg'
import styles from './projects-considerations-section.module.css'
import { FormattedMessage } from 'react-intl'
import ProjectDisasters from './ProjectDisasters/ProjectDisasters'
import ContentWrapper from '../../../common/ContentWrapper/ContentWrapper';

const desastres = [
  { img: Im1, title: <FormattedMessage id="weshare.titP1" />, text: <FormattedMessage id="weshare.point1" />, isActive: true },
  { img: Im2, title: <FormattedMessage id="weshare.titP2" />, text: <FormattedMessage id="weshare.point2" />, isActive: true },
  { img: Im3, title: <FormattedMessage id="weshare.titP3" />, text: <FormattedMessage id="weshare.point3" />, isActive: true }
]

const ProjectsConsiderationsSection = () => (
  <section>
    <div className={`text-center ${styles['backgroundYellow']}`}>
      <div className="container-fluid">

        {/* Título y pretítulo de la sección */}
        <div className="row">
          <div className="col-12">
            <div className="mx-auto mb-5 mb-lg-0 mb-lg-3">
              <ContentWrapper
                pretitle={<FormattedMessage id="weshare.some" />}
                title={<FormattedMessage id="weshare.tit1" />}
                content={<FormattedMessage id="weshare.titlePoint" />}
                contentClass={styles['txtAsterisco']}
              />
            </div>
          </div>
        </div>

        {/* Cards */}
        <div className="row card-deck">
          {
            desastres.map((c, i) => (
              <ProjectDisasters key={c.label + '-' + i} {...c} />
            ))
          }
        </div>

        {/* Pie de página */}
        <div className="row">
          <div className="col-12">
            <ContentWrapper
              content={<FormattedMessage id="weshare.committee" />}
              contentClass={`${styles['txtAsterisco']}`}
            />
          </div>
        </div>

      </div>
    </div>
  </section>
)

export default ProjectsConsiderationsSection;