import React from 'react'
import styles from './dareToShareIcon.module.css'

export default function DareToShareIcon({ icon, text }) {
  return (
    <div>
      <img className={styles['logoItems']} src={icon} alt="" />
      <h2 className={styles['txt']}>
        {text}
      </h2>
    </div>
  )
}
