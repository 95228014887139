import React from 'react'
import styles from './programs-info-section.module.css'
import { FormattedMessage } from 'react-intl'
import ContentWrapper from '../../../common/ContentWrapper/ContentWrapper'


const ProgramsInfoSection = () => (
  <section>
    <div className={`text-center ${styles['fondoPlusPrograms']}`}>
      <div className={`container-fluid`} >
        <div className={`container text-center`}>
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <ContentWrapper
                pretitle={<FormattedMessage id="programs.know" />}
                title={<FormattedMessage id="programs.title" />}
                content={<FormattedMessage id="programs.txt" />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ProgramsInfoSection