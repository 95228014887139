import React from 'react'
import 'moment-countdown'
import { injectIntl } from 'react-intl'

const inkindDonationsPanel = (props) => {
	const { inkindDonations_text } = props

	return (
		<div className="card mb-4 box-shadowCard miniCard">
			<div className="card-header miniCardHeader">
				<h5 className="my-0 text-center">
					{props.intl.formatMessage({ id: 'label.itemsDonated' }).toUpperCase()}
				</h5>
			</div>
			<div className="card-body miniCardBody">
				<ul className="textoLeyenda">
					{inkindDonations_text.split(',').map((text) => <li>{text.trim()}</li>)}
				</ul>
			</div>
		</div>
	)
}

export default injectIntl(inkindDonationsPanel)
