import React, { Fragment } from 'react'
import Footer from '../Common/Footer/Footer'
import Navbar from '../Common/Navbar/Navbar'

// Solution to scroll restoration
import ScrollToTopOnMount from './../Common/ScrollToTopOnMount'

function Gallerie({ children }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <Navbar />
      {children}
      <Footer />
    </Fragment>
  )
}

export default Gallerie
