import React from 'react'
import { FormattedMessage } from 'react-intl'


function ShareTree() {
  return (
    <div className='card mb-4 box-shadowCard miniCard'>
      <div className='card-header miniCardHeader'>
        <h5 className='my-0 text-center' style={{ textTransform: 'uppercase' }}>
          <FormattedMessage id='label.shareTree' />
        </h5>
      </div>
      <div className='card-body miniCardBody d-flex justify-content-center social-media-share-bar'>
        <a href='https://savingtheamazon.org/pages/tu-arbol' target='_blank'><FormattedMessage id='label.shareTreeLink' /></a>
      </div>
    </div>
  )
}

export default ShareTree
