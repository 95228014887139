import React, { Component } from 'react'
import { FormGroup, Button, Input } from 'reactstrap'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import FormGroupValid from './../../Campaign/lib/FormGroupCustom'
import { isEmpty } from './../../../util/isEmpty'
import { injectIntl } from 'react-intl'
import { getOptionsCarrousel } from './../../../redux/actions/carrouselActions'

class VideoForm extends Component {

  componentDidMount(){
    this.props.getOptionsCarrousel()
  }

  render(){
    
    const { state: { project }, carrousel: { select }, handleInputChange } = this.props
  
    return (
      <div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              <FormattedMessage id='carrousel.publish' />
            </h6>
          </div>
          <div className="card-body table-responsive">

            <FormGroupValid forp='project' label={<FormattedMessage id='carrousel.instruction' />} {...project}>
              <Input
                type="select"
                name="project"
                valid={project.valid}
                invalid={project.valid === false}
                onChange={handleInputChange}
                value={project.value}
              >
                {isEmpty(project.value) && <option></option>}
                {select.map((s, i) => (<option key={`project-${i}`} value={s._id}>{s.title}</option>))}
              </Input>
            </FormGroupValid>
            <FormGroup>
              <Button type="submit" className='btn btn-block'>
                <FormattedMessage id='btn.save' />
              </Button>
            </FormGroup>

          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = ({ carrousel }) => ({ carrousel })
const mapDispatchToProps = { getOptionsCarrousel }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VideoForm))
