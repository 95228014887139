import React, { Fragment } from 'react'
import { Input, Col, Row } from 'reactstrap'
import SelectData from './../../common/Inputs/SelectData'
import FormGroupValid from '../lib/FormGroupValid'
import FormGroupCustom from '../lib/FormGroupCustom'
import { isEmpty } from './../Helpers'
import { FormattedMessage } from 'react-intl';
const InformationForm = ({ type, startDate, endDate, eventDate, objective, institution, handleInputChange, handleChangeSelectData, props }) => {

  const { formatMessage } =  props.intl
  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroupValid forp="type" label={<FormattedMessage id="label.type" />} {...type}>
            <Input
              id='type'
              type='select'
              name='type'
              value={type.value}
              valid={type.valid}
              invalid={type.valid === false}
              onChange={handleInputChange}>
              {isEmpty(type.value) && <option disabled></option>}
              <option value='actividad'>
                {formatMessage({ id: 'modal.activitie' })}
              </option>
              <option value='campaña'>
                {formatMessage({ id: 'label.campaign' })}
              </option>
              <option value='eventoespecial'>
                {formatMessage({ id: 'label.specialEvent' })}
              </option>
              <option value='probono'>
                {formatMessage({ id: 'label.probono' })}
              </option>
            </Input>
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupValid forp="startDate" label={<FormattedMessage id="label.startDate" />} {...startDate}>
            <Input
              id="startDate"
              type="date"
              name="startDate"
              value={startDate.value}
              valid={startDate.valid}
              invalid={startDate.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
        <Col>
          <FormGroupValid for="endDate" label={<FormattedMessage id="label.endDate" />} {...endDate}>
            <Input
              type="date"
              id="endDate"
              name="endDate"
              value={endDate.value}
              valid={endDate.valid}
              invalid={endDate.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>

        <Col>
          <FormGroupValid forp="eventDate" label={<FormattedMessage id="label.event" />} {...eventDate}>
            <Input
              type="date"
              id="eventDate"
              name="eventDate"
              value={eventDate.value}
              valid={eventDate.valid}
              invalid={eventDate.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupValid forp="objective" label={<FormattedMessage id="label.goal" />} {...objective}>
            <Input
              type="textarea"
              id="objective"
              name="objective"
              value={objective.value}
              valid={objective.valid}
              invalid={objective.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupCustom forp="institution" label={<FormattedMessage id="modal.institution" />} {...institution}>
            <SelectData
              name='institution'
              route='api/institution'
              valid={objective.valid}
              onChange={handleChangeSelectData}
              selectedValue={institution.value}
            />
          </FormGroupCustom>
        </Col>
      </Row>
    </Fragment>
  )
}

export default InformationForm
