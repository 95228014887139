import React from 'react'
import PropTypes from 'prop-types'
import { Input as ReactstrapInput } from 'reactstrap'

export const Input = ({ input, ...props }) => {
  return (
    <div>
      <ReactstrapInput {...props} {...input} />
    </div>
  )
}

Input.propTypes = {
  type: PropTypes.string.isRequired
}

Input.defaultProps = {
  type: 'text',
  rows: 5
}
