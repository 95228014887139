import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import { actions } from './customer-project.redux'
import { Row, Col } from 'reactstrap'
import { dmy } from './../../util/dateHelpers'
import { isEmpty } from './../../util/helpers'
import TableContainer from './../common/Container/TableContainer'
import { ListItem } from './../common/ListItem'
import Admin from './../Layout/Admin/Admin'
import { LightTable } from '../common/Table/LightTable'
import { downloadFile } from './../../util/download'
import CustomerProjectLogo from './components/CustomerProjectLogo'

class CustomerProjectView extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.loadCustomerProjectRequest({ id })
  }

  componentWillUnmount() {
    this.props.loadCustomerProjectSuccess()
  }

  getReportName = title => `${title}_${moment().format('DD-MM-YY')}.xlsx`

  render() {
    const { view: item } = this.props.customerProject
    const countries = { MX: 'México', US: 'Estados Unidos' }

    const handleExport = () => {
      const { id } = this.props.match.params
      axios({
        url: '/api/customer-project/export/' + id,
        method: 'POST',
        responseType: 'blob'
      })
        .then(downloadFile(this.getReportName(item.title)))
        .catch(console.error)
    }

    return (
      <Admin>
        <TableContainer
          title='Campaña'
          subtitle='Información del registro'
          toggle={this.props.toggleCustomerProjectRequest}
          view={item}>
          {!isEmpty(item) && (
            <Row>
              <Col md='7'>
                <ul>
                  <ListItem label='Título' value={item.title} />
                  <ListItem label='Descripción' value={item.description} />
                  <ListItem label='Duración' value={`${item.duration} días`} />
                  <li className='list-unstyled' style={{ padding: '.2rem' }}>
                    <i className={`mr-2 flag-icon flag-icon-${item.country.toLowerCase()}`} />
                    <strong>País: </strong>
                    <span>{countries[item.country]}</span>
                  </li>
                </ul>
              </Col>
              <Col md='5'>
                <ul>
                  <ListItem label='Activo' value={item.active} />
                  <ListItem label='Creado' value={dmy(item.createdAt)} />
                  <ListItem label='Última modificación' value={dmy(item.updatedAt)} />
                </ul>
                <CustomerProjectLogo alt={item.title} src={item.logo.path} className='mt-5' />
              </Col>
            </Row>
          )}
        </TableContainer>
        <div className='px-1'>
          <BasicCard header='Donadores' handle={handleExport}>
            <LightTable
              data={item.donations}
              columns={[
                { dataField: 'email', text: 'Correo electrónico', sort: true },
                { dataField: 'createdAt', text: 'Fecha de selección', formatter: dmy }
              ]}
            />
          </BasicCard>
        </div>
      </Admin>
    )
  }
}

function BasicCard({ header, children, handle }) {
  return (
    <div className='card shadow mb-5 mx-2'>
      {!isEmpty(header) && (
        <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
          <h6 className='m-0 font-weight-bold text-primary'>{header}</h6>
          <button className='btn btn-success' onClick={handle}>
            <i className='fas fa-save' /> Descargar
          </button>
        </div>
      )}
      <div className='card-body'>{children}</div>
    </div>
  )
}

const mapStateToProps = ({ customerProject }) => ({ customerProject })
export default connect(mapStateToProps, actions)(CustomerProjectView)
