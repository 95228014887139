import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { injectIntl } from 'react-intl'
import Gallerie from '../Main/Layout/Gallerie'
import { actions } from './customer-project.redux'
import styles from '../Main/Common/Cards/cardNew.module.css'
import Pagination from './../common/Pagination/Pagination'
import Card from './components/CustomerProjectCard'
import ComponentMX from './textComponents/ComponentMX'
import ComponentEU from './textComponents/ComponentEU'
import lenguaje from './text'

export function CustomerProjectGallery({ intl, match, gallery, loadAllCustomerProject }) {
  useEffect(() => {
    const { country } = match.params
    loadAllCustomerProject({ intl, country })
  }, [])

  const isSpanish = match.params.country === 'MX'

  return (
    <Gallerie>
      <Container>
        {isSpanish ? <ComponentMX /> : <ComponentEU />}
        <h1 className={`${styles['card__gallerie-title']} mx-3`}>
          {isSpanish ? lenguaje.MX.titleGallery : lenguaje.EU.titleGallery}
        </h1>

        <Pagination
          data={gallery}
          sizePerPage={6}
          component={Card}
          route={`customer-project/gallery/${match.params.country}`}
        />
      </Container>
    </Gallerie>
  )
}

CustomerProjectGallery.propTypes = {
  customerProject: PropTypes.object.isRequired,
  loadAllCustomerProject: PropTypes.func.isRequired
}

const mapStateToProps = ({ customerProject }) => ({ gallery: customerProject.gallery })
const mapDispatchToProps = {
  loadAllCustomerProject: actions.publicLoadAllCustomerProjectRequest
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)
export default withConnect(injectIntl(CustomerProjectGallery))
