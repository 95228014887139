import React, { Fragment } from 'react'
import styles from './dts-info-section.module.css'
import Time from './img/d2s-01.svg'
import Money from './img/d2s-03.svg'
import Items from './img/d2s-02.svg'
import Color from './img/logo-color.png'
import ContentWrapper from './../../../common/ContentWrapper/ContentWrapper'
import DTSInfoCard from '../DTSInfoCard/DTSInfoCard'
import { FormattedMessage } from 'react-intl'
import ListElement from './../../Common/ListElement/ListElement.js'
import Bullet from './../../Common/Bullet/Bullet.js'

const DTSInfoSectionRow = ({ title, content }) => (
  <Fragment>
    <h4 className={`txtPrograms ${styles['titleContent']}`} >
      <FormattedMessage id={title} />
    </h4>
    <p className={`contentPrograms`}>
      <FormattedMessage id={content} />
    </p>
  </Fragment>
)

const DTSInfoSection = () => (
  <section className={`${styles['fondoInformationDTS']}`}>
    <div className="container text-center">
      <div className="row">
        <div className="col-lg-12">
          <ContentWrapper
            className='my-0'
            pretitle={<FormattedMessage id="dare.title" />}
            pretitleClass={styles['title']}
          />
          <img className={`${styles['logoDTS']} mb-md-5 mb-sm-4`} src={Color} alt="time" />
        </div>
      </div>
    </div>
    <div className={`container`}>
      <DTSInfoCard icon={Money}>
        <h4 className={`txtPrograms ${styles['titleContent']}`}><FormattedMessage id="dare.donaETit" /></h4>
        <p className={`contentPrograms`}><FormattedMessage id="dare.donaETxt" /></p>
        <ul className="font-weight-light lead mb-0 list-unstyled" >
          <ListElement message={"dare.donaPE1"}>
            <Bullet />
          </ListElement>
          <ListElement message={"dare.donaPE2"}>
            <Bullet />
          </ListElement>
          <ListElement message={"dare.donaPE3"}>
            <Bullet />
          </ListElement>
        </ul>
      </DTSInfoCard>
      <DTSInfoCard icon={Items}>
        <DTSInfoSectionRow title="dare.donaSTit" content="dare.donaSTxt" />
      </DTSInfoCard>
      <DTSInfoCard icon={Time}>
        <DTSInfoSectionRow title="dare.donaTTit" content="dare.donaTTxt" />
      </DTSInfoCard>
    </div>
  </section>
)

export default DTSInfoSection