import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from './mailing.redux'
import MailingForm from './MailingForm'
import { reset, initialize } from 'redux-form'
import Admin from '../Layout/Admin/Admin'

class MailingCreate extends Component {
  componentDidMount() {
    this.props.initialize('mailing', {})
  }

  componentWillUnmount() {
    this.props.resetForm('mailing')
  }

  submit = values => {
    const emails = values.emails.split(',').map(email => email.trim())
    this.props.createMailing({ ...values, emails })
  }

  render() {
    return (
      <Admin>
        <div className='header-h2 col'>
          <h1 className='h3 mb-2 text-gray-800'>Enviar correos</h1>
        </div>
        <MailingForm {...this.props} button='add' label='Enviar' onSubmit={this.submit} />
      </Admin>
    )
  }
}

const mapStateToProps = ({ mailing }) => ({ mailing })
const mapDispatchToProps = {
  createMailing: actions.createMailingRequest,
  resetForm: reset,
  initialize
}

export default connect(mapStateToProps, mapDispatchToProps)(MailingCreate)
