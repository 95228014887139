import React from 'react'
import CarrouselItem from './CarouselItem'
import { isEmpty } from './../../../../util/isEmpty'
import styles from './../carouselSection.module.css'

export default props => {
  const { className, items } = props
  return (
    <div className={`carousel-item ${className} ${styles['carousel-item']}`}>
      <div className={`row ${styles['h100']}`}>
        {!isEmpty(items) &&
          items.map(item => {
            return (
              <div className={`col-lg-${12/items.length} p-0 ${styles['h100']}`}>
                <CarrouselItem key={item._id} {...item} />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}