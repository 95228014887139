import { addItem, updatedItem } from '../../util/helpers'
import {
  GET_NEWS, POST_NEWS, UPDATE_NEWS,
  GET_All_NEWS,
  EDIT_NEWS,
  GET_CLEAN_NEWS,
  EDIT_CLEAN_NEWS,
  GET_All_CLEAN_NEWS
} from '../actions/types'

const initialState = {
  list: [],
  view: {},
  edit: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        view: { ...action.payload }
      }
    case POST_NEWS:
      return {
        ...state,
        list: addItem(state.list, action.payload)
      }
    case EDIT_NEWS:
      return {
        ...state,
        edit: {...action.payload}
      }
    case UPDATE_NEWS:
      return {
        ...state,
        list: updatedItem(state.list, action.payload)
      }
    case GET_All_NEWS:
      return {
        ...state,
        list: [...action.payload]
      }
    case GET_CLEAN_NEWS:
      return {
        ...state,
        view: {...action.payload}
      }
    case EDIT_CLEAN_NEWS:
      return {
        ...state,
        edit: {...action.payload}
      }
    case GET_All_CLEAN_NEWS:
      return {
        ...state,
        list: [...action.payload]
      }
    default:
      return state
  }
}