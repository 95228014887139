import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from './customer-project.redux'
import CustomerProjectForm from './CustomerProjectForm'
import { reset, initialize } from 'redux-form'
import Admin from './../Layout/Admin/Admin'

class CustomerProjectCreate extends Component {
  componentDidMount() {
    this.props.initialize('customer-project', {})
  }

  componentWillUnmount() {
    this.props.resetForm('customer-project')
  }

  submit = values => {
    this.props.createCustomerProject(values)
  }

  render() {
    return (
      <Admin>
        <div className='header-h2 col'>
          <h1 className='h3 mb-2 text-gray-800'>Agregar campaña</h1>
        </div>
        <CustomerProjectForm {...this.props} label='Agregar' onSubmit={this.submit} />
      </Admin>
    )
  }
}

const mapStateToProps = ({ customerProject }) => ({ customerProject })
const mapDispatchToProps = {
  createCustomerProject: actions.createCustomerProjectRequest,
  resetForm: reset,
  initialize
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerProjectCreate)
