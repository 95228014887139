import MailingList from './MailingList'
import MailingCreate from './MailingCreate'
import MailingView from './MailingView'

export default [
  {
    path: '/dashboard/mailing/list',
    component: MailingList,
    roles: ['corporative'],
    private: true
  },
  {
    path: '/dashboard/mailing/create',
    component: MailingCreate,
    roles: ['corporative'],
    private: true
  },
  {
    path: '/dashboard/mailing/view/:id',
    component: MailingView,
    roles: ['corporative'],
    private: true
  }
]
