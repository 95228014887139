import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put, take } from 'redux-saga/effects'
import { alertActions, alertTypes } from '../Alert/alert.redux'
import { actions, types } from './holiday-project.redux'
import { isEmpty } from '../../util/helpers'
import heart from './../../assets/img/heart.png'
import Swal from 'sweetalert2'

/**
 * Display the customer project list
 * @returns {iterator}
 */
export function* publicLoadAllHolidayProject({ payload }) {
  try {
    const url = `/api/public/holiday-project`
    const { data } = yield call(axios.get, url)
    yield put(actions.publicLoadAllHolidayProjectSuccess(data))
  } catch (error) {
    yield put(actions.publicLoadAllHolidayProjectFailure(error))
    console.log(error)
  }
}

/**
 * Display a single campaign record
 * @param   {object}   action.payload
 * @returns {iterator}
 */
export function* publicLoadHolidayProject({ payload } = {}) {
  try {
    const { slug } = payload
    const url = `/api/public/holiday-project/${slug}`
    const { data } = yield call(axios.get, url)
    yield put(actions.publicLoadHolidayProjectSuccess(data))
  } catch (error) {
    yield put(actions.publicLoadHolidayProjectFailure(error))
    console.log(error)
  }
}

/**
 * Donate to a single campaign
 * @param   {object}   action.payload
 * @returns {iterator}
 */
export function* publicDonateHolidayProject({ payload } = {}) {
  try {
    const { slug, email } = payload
    const url = `/api/public/holiday-project/${slug}`
    const { data } = yield call(axios.post, url, { email })
    yield put(actions.publicLoadHolidayProjectSuccess(data.result))
    yield put(push('/'))
    if (data.duplicated)
      yield Swal.fire({
        text: 'Ya has seleccionado a la asociación a la que deseas apoyar. ¡Muchas gracias!',
        icon: 'info'
      })
    else
      yield Swal.fire({
        title: '¡Muchas gracias!',
        text: 'Has realizado la selección de la asociación que recibirá la donación.',
        imageUrl: heart,
        imageHeight: 90
      })
  } catch (error) {
    yield put(actions.publicLoadHolidayProjectFailure(error))
    console.log(error)
  }
}

/**
 * Display the customer project list
 * @returns {iterator}
 */
export function* loadAllHolidayProject() {
  try {
    const url = '/api/holiday-project/'
    const { data } = yield call(axios.get, url)
    yield put(actions.loadAllHolidayProjectSuccess(data))
  } catch (error) {
    yield put(actions.loadAllHolidayProjectFailure(error))
    console.log(error)
  }
}

/**
 * Display a single campaign record
 * @param   {object}   action.payload
 * @returns {iterator}
 */
export function* loadHolidayProject({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/holiday-project/${id}`
    const { data } = yield call(axios.get, url)
    yield put(actions.loadHolidayProjectSuccess(data))
  } catch (error) {
    yield put(actions.loadHolidayProjectFailure(error))
    console.log(error)
  }
}

/**
 * Create an campaign record
 * @param   {object}   action.payload Data to create an campaign record
 * @returns {iterator}
 */
export function* createHolidayProject({ payload }) {
  try {
    const url = '/api/holiday-project/'
    const { data } = yield call(axios.post, url, payload)
    // Add new document to the list
    yield put(actions.createHolidayProjectSuccess(data))
    // Show notification
    yield put(alertActions.alertMessageSuccess('Registro guardado'))
    // Return the user to the list
    yield put(push('/dashboard/holiday-project/list'))
  } catch (error) {
    yield put(actions.createHolidayProjectFailure(error))
    console.log(error)
  }
}

/**
 * Load the information of a single campaign record to edit it
 * @param   {object}   action.payload
 * @returns {iterator}
 */
export function* editHolidayProject({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/holiday-project/${id}`
    const { data } = yield call(axios.get, url)
    yield put(actions.editHolidayProjectSuccess(data))
  } catch (error) {
    yield put(actions.editHolidayProjectFailure(error))
    console.log(error)
  }
}

/**
 * Update an campaign record
 * @param   {object}   action.payload Data to update an campaign record
 * @returns {iterator}
 */
export function* updateHolidayProject({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)

    if (isEmpty(prompt.payload)) return

    const { id, values } = payload
    const url = `/api/holiday-project/${id}`

    const detail = { cause: 'Actualización', description: prompt.payload }
    const data = { payload: values, detail }
    const { data: updatedHolidayProject } = yield call(axios.put, url, data)
    yield put(actions.updateHolidayProjectSuccess(updatedHolidayProject))
    // Success notification and return the user to the list
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))
    yield put(push('/dashboard/holiday-project/list'))
  } catch (error) {
    yield put(actions.updateHolidayProjectFailure(error))
    console.log(error)
  }
}

/**
 * Toggle the active property of an campaign record
 * @param   {object}   action.payload Data to update an campaign record
 * @returns {iterator}
 */
export function* toggleHolidayProject({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)
    const description = prompt.payload

    // The prompt was closed, stop the flow
    if (isEmpty(description)) return

    const { _id, active } = payload
    const url = `/api/holiday-project/${_id}`

    const detail = { cause: active ? 'Desactivación' : 'Activación', description }
    const { data } = yield call(axios.put, url, { payload: { active: !active }, detail })
    yield put(actions.toggleHolidayProjectSuccess(data))

    // Show success notification
    const result = data.active ? 'activado' : 'desactivado'
    yield put(alertActions.alertMessageSuccess(`Registro ${result}`))
    yield put(push('/dashboard/holiday-project/list'))
  } catch (error) {
    yield put(actions.toggleHolidayProjectFailure(error))
    console.log(error)
  }
}

export function* holidayProjectSagas() {
  yield all([
    takeLatest(types.PUBLIC_LOAD_ALL_HOLIDAY_PROJECT_REQUEST, publicLoadAllHolidayProject),
    takeLatest(types.PUBLIC_LOAD_HOLIDAY_PROJECT_REQUEST, publicLoadHolidayProject),
    takeLatest(types.PUBLIC_DONATE_HOLIDAY_PROJECT_REQUEST, publicDonateHolidayProject),
    takeLatest(types.LOAD_ALL_HOLIDAY_PROJECT_REQUEST, loadAllHolidayProject),
    takeLatest(types.LOAD_HOLIDAY_PROJECT_REQUEST, loadHolidayProject),
    takeLatest(types.CREATE_HOLIDAY_PROJECT_REQUEST, createHolidayProject),
    takeLatest(types.EDIT_HOLIDAY_PROJECT_REQUEST, editHolidayProject),
    takeLatest(types.UPDATE_HOLIDAY_PROJECT_REQUEST, updateHolidayProject),
    takeLatest(types.TOGGLE_HOLIDAY_PROJECT_REQUEST, toggleHolidayProject)
  ])
}
