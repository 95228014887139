import React, { Fragment } from 'react'
import DTSVoluntariado from './DTSVoluntariado/DTSVoluntariado'
import DTSVoluntario from './DTSVoluntario/DTSVoluntario'
import DTSAmigoSolidario from './DTSAmigoSolidario/DTSAmigoSolidario'
import styles from './dts-voluntario-section.module.css'

const DTSVoluntarioSection = () => (
  <Fragment>
    <DTSVoluntariado />
    <div className={`${styles['fondoVoluntariadoDTS']}`} >
      <div className={`container`}>
        <DTSVoluntario />
        <DTSAmigoSolidario />
      </div>
    </div>
  </Fragment>
)

export default DTSVoluntarioSection