import axios from 'axios'
import swal from '@sweetalert/with-react'
import { loadingPage } from './loadingpageAction'
import { isEmpty } from './../../util/isEmpty'
import {
  GET_ALL_CARROUSEL_USER,
  GET_ALL_CARROUSEL_ADMIN,
  POST_CARROUSEL,
  GET_ERRORS,
  GET_OPTIONS_CARROUSEL,
  ACTIVATE_CARROUSEL,
  DEACTIVATE_CARROUSEL
} from './types'

// GET the list of carrousel items (Private - Corporative/Maintainer)
export const getAllCarrouselAdmin = () => dispatch => {
  const route = `/api/carrousel/admin`
  axios
    .get(route)
    .then(r => r.data)
    .then(payload => dispatch({ type: GET_ALL_CARROUSEL_ADMIN, payload }))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET the list of carrousel items (Public)
export const getAllCarrouselUser = () => dispatch => {
  // const sede = store.getState().location.sede._id
  // const route = `/api/carrousel/${sede}`
  const route = '/api/carrousel'
  axios
    .get(route)
    .then(r => r.data)
    .then(payload => dispatch({ type: GET_ALL_CARROUSEL_USER, payload }))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// GET the project available to first post to the carrousel (Private)
export const getOptionsCarrousel = () => dispatch => {
  const route = '/api/inputs/select-carrousel'
  axios
    .get(route)
    .then(r => r.data)
    .then(payload => dispatch({ type: GET_OPTIONS_CARROUSEL, payload }))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// POST a new carrousel item (Private - Corporative/Maintainer)
export const postCarrousel = (payload, history, intl) => dispatch => {
  dispatch(loadingPage())
  axios
    .post('/api/carrousel', payload)
    .then(r => r.data)
    .then(payload => dispatch({ type: POST_CARROUSEL, payload }))
    .then(() => dispatch(loadingPage()))
    .then(() =>
      swal({ title: intl.formatMessage({ id: 'carrousel.new' }), text: '', icon: 'success' })
    )
    // Redirects to homepage if clicks on the OK button
    .then(go => (go ? history.push('/') : ''))
    .catch(error => {
      const message = error.response.data.message
      swal('', intl.formatMessage({ id: message }), 'warning')
      dispatch({ type: GET_ERRORS, payload: error })
    })
}

// PUT activate a carrousel item (Private - Corporative/Maintainer)
export const activateCarrousel = (id, history, intl) => dispatch => {
  // Confirmation alert
  swal({
    title: intl.formatMessage({ id: 'alert.confiTitle' }),
    text: `${intl.formatMessage({ id: 'alert.confiText' })} ${intl.formatMessage({
      id: 'alert.on'
    })}`,
    icon: 'warning',
    buttons: true
  })
    .then(confirmation => {
      if (confirmation === true) {
        // Justification input alert
        swal({ title: intl.formatMessage({ id: 'alert.desTitle' }), content: 'input' }).then(
          justification => {
            if (!isEmpty(justification)) {
              // PUT route
              const route = `/api/carrousel/admin/activate/${id}`
              // Detail object to send into the PUT request
              const detail = {
                cause: intl.formatMessage({ id: 'alert.onCap' }),
                description: justification
              }

              dispatch(loadingPage())
              axios
                .put(route, { detail: { ...detail } })
                .then(r => r.data)
                .then(payload => dispatch({ type: ACTIVATE_CARROUSEL, payload }))
                .then(() => dispatch(loadingPage()))
                .then(() =>
                  swal({
                    title: intl.formatMessage({ id: 'carrousel.active' }),
                    text: '',
                    icon: 'success'
                  })
                )
                // Redirects to homepage if clicks on the OK button
                .then(go => (go ? history.push('/') : ''))
                .catch(error => {
                  const message = error.response.data.message
                  swal('', intl.formatMessage({ id: message }), 'warning')
                  dispatch({ type: GET_ERRORS, payload: error })
                })
            } else {
              // Changes weren't saved
              swal(
                intl.formatMessage({ id: 'alert.changesOff' }),
                intl.formatMessage({ id: 'alert.writeCause' }),
                'info'
              )
            }
          }
        )
      }
    })
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

// PUT deactivate a carrousel item (Private - Corporative/Maintainer)
export const deactivateCarrousel = (id, history, intl) => dispatch => {
  // Confirmation alert
  swal({
    title: intl.formatMessage({ id: 'alert.confiTitle' }),
    text: `${intl.formatMessage({ id: 'alert.confiText' })} ${intl.formatMessage({
      id: 'alert.off'
    })}`,
    icon: 'warning',
    buttons: true
  })
    .then(confirmation => {
      if (confirmation === true) {
        // Justification input alert
        swal({ title: intl.formatMessage({ id: 'alert.desTitle' }), content: 'input' }).then(
          justification => {
            if (!isEmpty(justification)) {
              // PUT route
              const route = `/api/carrousel/admin/deactivate/${id}`
              // Detail object to send into the PUT request
              const detail = {
                cause: intl.formatMessage({ id: 'alert.offCap' }),
                description: justification
              }

              dispatch(loadingPage())
              axios
                .put(route, { detail: { ...detail } })
                .then(r => r.data)
                .then(payload => dispatch({ type: DEACTIVATE_CARROUSEL, payload }))
                .then(() => dispatch(loadingPage()))
                .then(() =>
                  swal({
                    title: intl.formatMessage({ id: 'carrousel.unactive' }),
                    text: '',
                    icon: 'success'
                  })
                )
                // .then(go => go ? history.push('/') : '')
                .catch(error => {
                  const message = error.response.data.message
                  swal('', intl.formatMessage({ id: message }), 'warning')
                  dispatch({ type: GET_ERRORS, payload: error })
                })
            } else {
              // Changes weren't saved
              swal(
                intl.formatMessage({ id: 'alert.changesOff' }),
                intl.formatMessage({ id: 'alert.writeCause' }),
                'info'
              )
            }
          }
        )
      }
    })
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}
