import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { spinnerToggle } from '../../redux/actions/spinnerActions'
import { getConvocatory, editCleanConvocatory, editConvocatory, postConvocatory, updateConvocatory } from './../../redux/actions/convocatoryActions'
// Layout 
import Admin from '../Layout/Admin/Admin'
// Forms
import ThumbnailForm from './forms/ThumbnailForm'
import ContentForm from './forms/ContentForm'
// Helpers
import { Form, FormGroup, Button } from 'reactstrap'
import { isEmpty } from './../../util/isEmpty'
import { FormattedMessage, injectIntl } from 'react-intl'
import { validateOnSubmit, plainState } from './../Campaign/lib/helpers'
import initialState from './Helpers/initialState';
import validations from './validations/edit'
import { mergeState } from './Helpers'
export class ConvocatoryEdit extends Component {

  state = { ...initialState, published: false }

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.editConvocatory(id)
  }

  componentWillReceiveProps(np) {
    const { edit } = np.convocatory

    if (!isEmpty(edit)) {
      const state = mergeState(initialState, edit)
      this.setState({ ...state })
    }
    if (isEmpty(edit)) {
      const { id } = this.props.match.params
      this.props.editConvocatory(id)
    }
  }

  componentWillUnmount() {
    this.props.editCleanConvocatory()
  }

  // Handle para agregar los valores a el
  // state de la propiedad de un objeto
  // recibe el nombre y un objeto de propiedades
  handleChangeSelectData = props => {
    this.setState(s => ({ ...s, [props.name]: { ...s[props.name], value: props.value } }))
  }

  // Handle agrega los valores a el
  // state de la propiedad del editor 
  onChangeEditor = ({ editor }) => {
    const value = editor.getData()
    this.setState((state, prop) => ({ content: { ...state.content, value } }))
  }
  // Handle para agrega los valores del state
  // para inputs y checkbox
  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState(s => ({ ...s, [name]: { ...s[name], value } }))
  }

  // Hadle si las validaciones pasaron
  onSubmit = event => {
    event.preventDefault()
    const validated = validateOnSubmit(this.state, validations(this.props.convocatory.edit.startDate))
    console.log(validated)
    if (validated.pass) {
      const s = { ...validated.state }
      const payload = plainState(s)
      const { id } = this.props.match.params
      const { intl } = this.props
      this.props.updateConvocatory(id, payload, this.props.history, intl)
      this.setState({ published: true })
    } else {
      window.scrollTo(0, 0)
      const faildState = validated.state
      this.setState({ ...faildState })
    }
  }

  render() {
    const { published } = this.state
    return (
      <Admin>
        <div className="row">
          <div className="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10">
            <h1 className="h3 mb-2 text-gray-800"><FormattedMessage id="convocatory.edit" /></h1>
          </div>
        </div>
        <Form
          onSubmit={this.onSubmit}
        >
          <div className="row">
            <div className="col-xl-8 col-lg-8 offset-lg-2">
              <ThumbnailForm {...this} />
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <ContentForm {...this} {...this.state} />
            </div>
          </div>

          <FormGroup>
            <Button type="submit" disabled={published}><FormattedMessage id="btn.save" /></Button>
          </FormGroup>
        </Form>
      </Admin>
    )
  }
}

ConvocatoryEdit.propTypes = {
  auth: PropTypes.object.isRequired,
  convocatory: PropTypes.object.isRequired,
  spinnerToggle: PropTypes.func.isRequired,
  getConvocatory: PropTypes.func.isRequired,
  editConvocatory: PropTypes.func.isRequired,
  postConvocatory: PropTypes.func.isRequired,
  updateConvocatory: PropTypes.func.isRequired,
}
const mapStateToProps = ({ auth, convocatory }) => ({ auth, convocatory })
const mapDispatchToProps = { spinnerToggle, editCleanConvocatory, getConvocatory, editConvocatory, postConvocatory, updateConvocatory }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConvocatoryEdit))