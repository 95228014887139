const msg =
{
  requerido: 'Este campo es requerido',
  obligado: 'Campo obligatorio',
  correcto: 'Campo correcto',
  min5: 'El mínimo de caracteres permitido es 5',
  max7: 'El mínimo de caracteres permitido es 7',
  max280: 'El  máximo de caracteres permitido es 280',
  numero: 'El campo no es un número',
  email: 'El campo no es email',
  min580: 'El mínimo de caracteres permitido es 580',
  max25: 'El máximo de caracteres permitido es 25',
  max50: 'El máximo de caracteres permitido es 50',
  min4: 'El mínimo de caracteres permitido es 4',
  anteriorHoy: 'No se puede seleccionar una fecha anterior al día de hoy',
  anteriorInicial: 'No se puede seleccionar una fecha anterior a la inicial',
  rango: 'Seleccionar fecha entre fecha inicial y fecha final',
  min15: 'El mínimo de caracteres permitido es 15',
  max20: 'El maximo de caracteres permitido es 2000.',
  min7: 'El mínimo de caracteres permitido es 7',
  cantDol: 'Cantidad en dolares',
  reqFInicial: 'Es requerida la Fecha Inicial',
  reqFFinal: 'Es requerida la Fecha Final',
  name: 'Softtekian Solidario',
  num: '/'
}

export default msg
