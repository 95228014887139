import React, { Fragment } from 'react'
import styles from './values-icons.module.css'

const ValuesIcons = ({ icon, title, text, isActive }) => {
  return (
    <Fragment>
      {isActive && (
        <div className={`testimonial-item mx-auto mb-md-2 mb-lg-0 ${styles['marginValues']}`} >
          <img className={`img-fluid mb-3 ${styles['iconsSize']}`} src={icon} alt="" />
          <h4 className={styles['titleValues']} >{title}</h4>
          <p className={`font-weight-light lead mb-0 ${styles['txtValues']}`} >{text}</p>
        </div>
      )}
    </Fragment>
  )
}

export default ValuesIcons