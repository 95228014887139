import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Notify from '../../common/Notify'
import { Form } from 'reactstrap'
import Footer from '../../Main/Common/Footer/Footer'
import Navbar from '../../Main/Common/Navbar/Navbar'
import { updateSofttekian } from './../../../redux/actions/authActions'
import UserForm from './components/UserForm'
import Push from '../../common/Push/Push'
import ImgPerfil from '../../Perfil/ImgPerfil/ImgPerfil'
import UserPerfil from '../../Perfil/UserPerfil'
import NavPerfil from '../../Perfil/NavPerfil'
import { validateOnSubmit } from './../../Campaign/lib/helpers'
import validations from './validations'
import { isEmpty } from '../../../util/isEmpty'
import { injectIntl } from 'react-intl'
import axios from 'axios'
import swal from 'sweetalert'

// Cambiar { name: '', _id: '' } a { label: '', value: '' }
const multiSelectFormater = (selected) =>
  selected.map((s) => ({ label: s.name, value: s._id }))

export class User extends Component {
  state = {
    is: {
      value: this.props.auth.user.is,
      valid: null,
      text: '',
      feed: [],
    },
    subsede: {
      value: this.props.auth.user.subsede,
      valid: null,
      text: '',
      feed: [],
    },
    avatar: {
      value: null,
      valid: null,
      text: '',
      feed: [],
    },
    phone: {
      value: this.props.auth.user.phone,
      valid: null,
      text: '',
      feed: [],
    },
    causes: {
      value: [],
      valid: null,
      text: '',
      feed: [],
    },
    activities: {
      value: [],
      valid: null,
      text: '',
      feed: [],
    },
    skills: {
      value: [],
      valid: null,
      text: '',
      feed: [],
    },

    options: {
      causes: [],
      skills: [],
      activities: [],
    },

    subsedes: [],
  }

  componentDidMount() {
    const { user } = this.props.auth

    if (!isEmpty(user.causes)) {
      this.setState((s) => ({
        ...s,
        subsede: {
          ...s.subsede,
          value: user.subsede,
        },
        is: {
          ...s.is,
          value: user.is,
        },
        phone: {
          ...s.phone,
          value: user.phone,
        },
        options: {
          causes: multiSelectFormater(user.causes),
          skills: multiSelectFormater(user.skills),
          activities: multiSelectFormater(user.activities),
        },
      }))
    }

    axios
      .get('/api/subsede')
      .then((res) => this.setState((s) => ({ ...s, subsedes: res.data })))
      .catch((e) => alert('Error'))
  }

  componentWillReceiveProps(np) {
    const { user, isAuthenticated } = np.auth
    if (
      isAuthenticated &&
      (isEmpty(user.is) ||
        isEmpty(user.sede) ||
        isEmpty(user.name) ||
        isEmpty(user.email) ||
        isEmpty(user.phone))
    ) {
      swal(
        this.props.intl.formatMessage({ id: 'alert.profileEmptyTitle' }),
        this.props.intl.formatMessage({ id: 'alert.profileEmptyText' }),
        'warning'
      )
    }

    if (!isEmpty(user.causes)) {
      this.setState((s) => ({
        ...s,
        subsede: {
          ...s.subsede,
          value: user.subsede,
        },
        is: {
          ...s.is,
          value: user.is,
        },
        phone: {
          ...s.phone,
          value: user.phone,
        },
        options: {
          causes: multiSelectFormater(user.causes),
          skills: multiSelectFormater(user.skills),
          activities: multiSelectFormater(user.activities),
        },
      }))
    }
  }

  // Handle para agrega los valores del state
  // para inputs y checkbox
  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState((s) => ({ ...s, [name]: { ...s[name], value } }))
  }

  // Handle agrega los valores a el
  // state de la propiedad de un objeto
  // recibe el nombre y un objecto de propiedades
  handleChangeSelectData = (props) => {
    this.setState((s) => ({
      ...s,
      [props.name]: { ...s[props.name], value: props.value },
    }))
  }

  // Handle agrega los valores a el
  // state de la propiedad de un objeto
  // recibe el nombre y un objecto de propiedades
  handleChangeMultiSelect = (props) => {
    this.setState((s) => ({
      ...s,
      options: { ...s.options, [props.name]: props.value },
    }))
  }

  // Handle si las validaciones pasaron
  onSubmit = (event) => {
    event.preventDefault()
    const { _id } = this.props.auth.user
    const { state } = this
    const { is, subsede, phone, options, avatar } = state
    const payload = {
      is: is.value,
      subsede: subsede.value,
      phone: phone.value,
      causes: options.causes.map((e) => e.value),
      activities: options.activities.map((e) => e.value),
      skills: options.skills.map((e) => e.value),
      avatar: avatar.value,
    }
    const validated = validateOnSubmit(state, validations)
    const payloadKeys = Object.keys(payload).filter((k) => payload[k] !== null)
    const data = payloadKeys.reduce((pre, cur, i) => {
      pre[cur] = payload[cur]
      return pre
    }, {})

    if (validated.pass) {
      const { intl } = this.props
      this.props.updateSofttekian(_id, data, intl)
      this.setState({ ...validated.state })
    } else {
      const faildState = validated.state
      this.setState({ ...faildState })
    }
  }

  render() {
    return (
      <Fragment>
        <Navbar />
        <Notify />
        <Push />
        <div className="container mt-4">
          <br />
          <div className="row my-2">
            <ImgPerfil avatar={this.props.auth.user.avatar} />
            <div className="col-lg-9 push-lg-4">
              <NavPerfil />
              <div className="tab-content p-b-3">
                <div className="tab-pane active" id="profile">
                  <UserPerfil {...this.props} />
                </div>
                <div className="tab-pane" id="edit">
                  <Form onSubmit={this.onSubmit} ref={(f) => (this.form = f)}>
                    <UserForm {...this} {...this.state} {...this.props} />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    )
  }
}

User.propTypes = {
  auth: PropTypes.object.isRequired,
  updateSofttekian: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = { updateSofttekian }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(User))
