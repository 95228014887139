import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { injectIntl } from 'react-intl'
import Gallerie from '../Main/Layout/Gallerie'
import { actions } from './holiday-project.redux'
import styles from '../Main/Common/Cards/cardNew.module.css'
import Pagination from '../common/Pagination/Pagination'
import Card from './components/HolidayProjectCard'
import ComponentMX from './textComponents/ComponentMX'
import lenguaje from './../CustomerProject/text'

export function HolidayProjectGallery({ intl, match, gallery, loadAllHolidayProject }) {
  useEffect(() => {
    loadAllHolidayProject({ intl })
  }, [])

  return (
    <Gallerie>
      <Container>
        <ComponentMX />
        <h1 className={`${styles['card__gallerie-title']} mx-3`}>
          {lenguaje.MX.titleGallery2020}
        </h1>

        <Pagination data={gallery} sizePerPage={6} component={Card} route={`mexico2020`} />
      </Container>
    </Gallerie>
  )
}

HolidayProjectGallery.propTypes = {
  holidayProject: PropTypes.object.isRequired,
  loadAllHolidayProject: PropTypes.func.isRequired
}

const mapStateToProps = ({ holidayProject }) => ({ gallery: holidayProject.gallery })
const mapDispatchToProps = {
  loadAllHolidayProject: actions.publicLoadAllHolidayProjectRequest
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)
export default withConnect(injectIntl(HolidayProjectGallery))
