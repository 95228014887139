import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import getStore from './redux/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import {
  setConnections,
  socketAuth,
  socketDisconnect,
  socketDashboard
} from './redux/actions/socketActions'
import { logoutUser } from './redux/actions/authActions'
import { initPage } from './redux/actions'
import socket from './socket'
import { isEmpty } from './util/helpers'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'

export const store = getStore()

// init request
store.dispatch(initPage())

// escucha por las conecionex.
socket.on('connection', res => {
  const id = socket.id
  const { connections, count } = res.data
  const payload = { id, sockets: { connections, count } }
  store.dispatch(setConnections(payload))
})

// escucha por los usarios authenticados.
socket.on('auth', res => {
  const { connections, count } = res.data
  const payload = { sockets: { connections, count } }
  store.dispatch(socketAuth(payload))
  socket.emit('dashboard', {})
})

// saca a un administrador desactivado por un (coorporativo).
socket.on('dumppuser', res => {
  return store.dispatch(logoutUser())
})

socket.on('dashboard', res => {
  return store.dispatch(socketDashboard(res))
})

// escucha por los usarios authenticados.
socket.on('disconnect', res => {
  if (!isEmpty(res.data)) {
    const { connections, count } = res.data
    const payload = { sockets: { connections, count } }
    store.dispatch(socketDisconnect(payload))
  }
})

// escucha por notificaciones.
socket.on('alert', res => {
  alert(res.message)
})
const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </Provider>,
  document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
