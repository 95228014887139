import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import { alertActions } from '../Alert/alert.redux'
import { actions, types } from './mailing.redux'

/**
 * Display the mailing list
 * @returns {iterator}
 */
export function* loadAllMailing() {
  try {
    const url = '/api/mailing/'
    const { data } = yield call(axios.get, url)
    yield put(actions.loadAllMailingSuccess(data))
  } catch (error) {
    yield put(actions.loadAllMailingFailure(error))
    console.log(error)
  }
}

/**
 * Display a single mailing record
 * @param   {object}   action.payload
 * @returns {iterator}
 */
export function* loadMailing({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/mailing/${id}`
    const { data } = yield call(axios.get, url)
    yield put(actions.loadMailingSuccess(data))
  } catch (error) {
    yield put(actions.loadMailingFailure(error))
    console.log(error)
  }
}

/**
 * Create an mailing record
 * @param   {object}   action.payload Data to create an mailing record
 * @returns {iterator}
 */
export function* createMailing({ payload }) {
  try {
    const url = '/api/mailing/'
    const { data } = yield call(axios.post, url, payload)
    // Add new document to the list
    yield put(actions.createMailingSuccess(data))
    // Show notification
    yield put(alertActions.alertMessageSuccess('Registro guardado'))
    // Return the user to the list
    yield put(push('/dashboard/mailing/list'))
  } catch (error) {
    yield put(actions.createMailingFailure(error))
    console.log(error)
  }
}

export function* mailingSagas() {
  yield all([
    takeLatest(types.LOAD_ALL_MAILING_REQUEST, loadAllMailing),
    takeLatest(types.LOAD_MAILING_REQUEST, loadMailing),
    takeLatest(types.CREATE_MAILING_REQUEST, createMailing)
  ])
}
