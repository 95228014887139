import React from 'react'
import styles from './values-motivation-section.module.css'
import { FormattedMessage } from 'react-intl'
import ValuesButton from './ValuesButton/ValuesButton'
import ContentWrapper from './../../../common/ContentWrapper/ContentWrapper'



const ValuesMotivationSection = () => (
  <section className="testimonials text-center bg-white" >
    <div className={`text-center text-white ${styles['fondoRojo']}`} >
      <div className="container" >
        <ContentWrapper
          className='my-0'
          pretitle={<FormattedMessage id="values.reason" />}
          title={<FormattedMessage id="values.focus" />}
          content={<FormattedMessage id="values.focusTxt" />}
          pretitleClass={`${styles['titleMotivation']} mt-4 mb-4`}
          titleClass={`${styles['subtitleMotivation']}`}
          contentClass={`font-weight-light lead mb-0 ${styles['txtMotivation']}`}
        />
        <ContentWrapper
          title={<FormattedMessage id="values.belief" />}
          content={<FormattedMessage id="values.beliefTxt" />}
          titleClass={styles['subtitleMotivation']}
          contentClass={styles['txtMotivation']}
        />
        <ValuesButton />
      </div>
    </div>
  </section>
)

export default ValuesMotivationSection