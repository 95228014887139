import { GET_VIDEO, POST_VIDEO } from './../actions/types'

const initialState = {
  view: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VIDEO:
      return {
        ...state,
        view: { ...action.payload }
      }
    case POST_VIDEO:
      return {
        ...state,
        view: { ...action.payload }
      }
    default:
      return state
  }
}