import CustomerProjectList from './CustomerProjectList'
import CustomerProjectCreate from './CustomerProjectCreate'
import CustomerProjectView from './CustomerProjectView'
import CustomerProjectEdit from './CustomerProjectEdit'
import CustomerProjectGallery from './CustomerProjectGallery'
import CustomerProject from './CustomerProject'

export default [
  {
    path: '/dashboard/customer-project/list',
    component: CustomerProjectList,
    roles: ['corporative'],
    private: true
  },
  {
    path: '/dashboard/customer-project/create',
    component: CustomerProjectCreate,
    roles: ['corporative'],
    private: true
  },
  {
    path: '/dashboard/customer-project/view/:id',
    component: CustomerProjectView,
    roles: ['corporative'],
    private: true
  },
  {
    path: '/dashboard/customer-project/edit/:id',
    component: CustomerProjectEdit,
    roles: ['corporative'],
    private: true
  },
  {
    path: '/customer-project/gallery/:country',
    component: CustomerProjectGallery
  },
  {
    path: '/customer-project/gallery/:country/:slug',
    component: CustomerProject
  }
]
