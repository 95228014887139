const lenguaje = {
  MX: {
    titleGallery: 'Galería de campañas',
    titleGallery2020: 'México 2020',
    panelTitle: '¡Donar!',
    donateText:
      '* El registro de tu correo es solo para control interno. La donación se realizará de manera anónima, por lo que no se compartirán tus datos.',
    donateButton: 'Apoyar esta causa',
    donationAlertTitle: '¡Muchas gracias!',
    donationAlertText: 'Has realizado la selección de la asociación que recibirá la donación.',
    duplicatedText:
      'Ya has seleccionado a la asociación a la que deseas apoyar. ¡Muchas gracias!',
    btnCard: 'Conoce más y Apoya',
    modalConfirmlabel: 'Apoyar campaña',
    buttonConfirm: 'Confirmar',
    buttonCancel: 'Cancelar',
    validEmail: 'Correo electrónico inválido'
  },
  EU: {
    titleGallery: 'Organizations we are supporting',
    panelTitle: 'Support this cause',
    donateText: `* The registration of your email address is for Internal control only.  The donation will be made anonymously; your data will not be shared with anyone. Take a look at our privacy policy notice`,
    donateButton: 'Assign 30 meals',
    donationAlertTitle: 'Thank you!',
    donationAlertText:
      'You have successfully chosen the organization that will receive the 30 meals donation. Thank you! ',
    duplicatedText:
      'You have successfully chosen the association that you wish to support.  Thank you!',
    btnCard: 'Learn more',
    modalConfirmlabel: 'Support this cause',
    modalConfirmtext: 'Would you like to confirm your support for this organization?',
    buttonConfirm: 'Confirm',
    buttonCancel: 'Cancel',
    validEmail: 'Invalid email'
  }
}

export default lenguaje
