import React from 'react'
import UploadFiles from './../../common/Upload/UploadFiles'
import { Col, Row, Input, FormGroup, Button } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import FormGroupValid from './../../Campaign/lib/FormGroupValid'
import FormGroupCustom from './../../Campaign/lib/FormGroupCustom'
import CKEditor from "react-ckeditor-component"

const config = {
  width: 'auto',
  height: '75vh',
  padding: '0px',
  margin: '0px',
}

// const AvUploadFiles = props => (<AvField {...props} tag={UploadFiles} />)

const ThumbnailForm = ({ handleChangeSelectData, handleInputChange, state, onChangeEditor }) => {
  const { title, publishDate, endDate, description, thumbnail, content } = state
  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
          <FormattedMessage id='label.thumbnail' />
          </h6>
        </div>
        <div className="card-body table-responsive">

          <FormGroupValid forp="title" label={<FormattedMessage id="label.title" />} {...title}>
            <Input
              id="title"
              type="text"
              name="title"
              value={title.value}
              valid={title.valid}
              invalid={title.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>

          <Row>
            <Col md={6}>
              <FormGroupValid forp="publishDate" label={<FormattedMessage id="label.publishDate" />} {...publishDate}>
                <Input
                  id="publishDate"
                  type="date"
                  name="publishDate"
                  value={publishDate.value}
                  valid={publishDate.valid}
                  invalid={publishDate.valid === false}
                  onChange={handleInputChange} />
              </FormGroupValid>
            </Col>

            <Col md={6}>
              <FormGroupValid for="endDate" label={<FormattedMessage id="label.endDate" />} {...endDate}>
                <Input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={endDate.value}
                  valid={endDate.valid}
                  invalid={endDate.valid === false}
                  onChange={handleInputChange} />
              </FormGroupValid>
            </Col>
          </Row>

          <FormGroupValid forp="description" label={<FormattedMessage id="label.description" />} {...description}>
            <Input
              type="textarea"
              id="description"
              name="description"
              value={description.value}
              valid={description.valid}
              invalid={description.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>

          <FormGroupCustom forp='thumbnail' label={<FormattedMessage id="label.cover" />} {...thumbnail}>
            <UploadFiles
              url={'/api/file'}
              file={file => handleChangeSelectData({ name: 'thumbnail', value: file._id })} />
          </FormGroupCustom>
        </div>
        
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            <FormattedMessage id='label.content' />
          </h6>
        </div>

        <FormGroupCustom {...content}>
          <CKEditor
            config={config}
            activeClass="p10"
            content={content.value}
            events={{ "change": onChangeEditor }} />
        </FormGroupCustom>

      <FormGroup>
        <Button type="submit">
          <FormattedMessage id='btn.save' />
        </Button>
      </FormGroup>

      </div>
    </div>
  )
}

export default ThumbnailForm
