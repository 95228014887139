import React from 'react'
import moment from 'moment'
import { isEmpty } from './../../../util/isEmpty'
import { FormattedMessage } from 'react-intl';

export default startDateEstablished => [
  {
    field: 'title',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [<FormattedMessage id="help.obliged" />] }
      } else if (value.length < 3) {
        check = { ...check, feed: [<FormattedMessage id="help.min3" />] }
      } else if (value.length > 25) {
        check = { ...check, feed: [<FormattedMessage id="help.max25" />] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'subsede',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [<FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'description',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [<FormattedMessage id="help.obliged" />] }
      } else if (value.length < 5) {
        check = { ...check, feed: [<FormattedMessage id="help.min5" />] }
      } else if (value.length > 140) {
        check = { ...check, feed: [<FormattedMessage id="help.max140" />] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'startDate',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [<FormattedMessage id="help.obliged" />] }
      } else if (!moment(startDateEstablished).isBefore(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.equalStart" />] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'endDate',
    function(value, state = null) {
      const { startDate } = state
      const sd = startDate.value
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(sd)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      }
      else if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.right" />] }
      }
      else if (!moment(sd).isBefore(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.equalStart" />] }
      }
      else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'content',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      }      else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'thumbnail',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, text: '', feed: [''] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },

]