import React, { Component } from 'react'
import { connect } from 'react-redux'
import Admin from '../Layout/Admin/Admin'
import PropTypes from 'prop-types'
import {
	getAllNewsAdmin,
	editNews,
	getNews,
	getAllCleanNews,
	updateNews,
	activateNews
} from '../../redux/actions/newsActions'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
// Hepers
import TableWrapper from '../common/TableWrapper'
import { expandRow } from '../../util/tableHelpers'
import { isEmpty } from '../../util/helpers'
import { tableOptions } from './Helpers/tableHelpers'
import { FormattedMessage, injectIntl } from 'react-intl'
import CSVButton from './../common/CSVButton'
import { today } from './../../util/dateHelpers'
import ExportExcel from '../common/ExportExcel/ExportExcel'
import { columnsToExport } from './Helpers/tableHelpers'

// Global variables
const { SearchBar } = Search

class NewsListPagination extends Component {
	handleView = (id) => this.props.getNews(id, this.props.intl)
	handleEdit = (id) => this.props.editNews(id)
	handleDelete = (n) => this.props.activateNews(n._id, { active: !n.active }, this.props.history, this.props.intl)

	// LIFECYCLES METHODS

	componentDidMount() {
		this.props.getAllNewsAdmin(this.props.intl)
	}

	componentWillReceiveProps(np) {
		if (!isEmpty(np.news.view)) {
			const id = np.news.view._id
			this.props.history.push(`/news/gallerie/${id}`)
		}
		if (!isEmpty(np.news.edit)) {
			const id = np.news.edit._id
			this.props.history.push(`/dashboard/news/edit/${id}`)
		}
	}

	componentWillUnmount() {
		this.props.getAllCleanNews()
	}

	// Objeto que muestra las opciones de activo en multilenguaje
	activeOptions = {
		false: this.props.intl.formatMessage({ id: 'general.negation' }),
		true: this.props.intl.formatMessage({ id: 'general.confirmation' })
	}

	// RENDER METHOD

	render() {
		const { list } = this.props.news
		const columns = tableOptions(this.props.intl.formatMessage, this.activeOptions, {
			...this
		})
		return (
			<Admin>
				<ToolkitProvider
					keyField="_id"
					data={list}
					columns={columns}
					bootstrap4={true}
					search={{
						searchFormatted: true
					}}
					exportCSV={{
						fileName: `NewsFS-${today()}.csv`,
						separator: ',',
						noAutoBOM: false,
						onlyExportFiltered: true,
						exportAll: false
					}}
				>
					{(props) => (
						<TableWrapper
							title={<FormattedMessage id="label.list" />}
							label={<FormattedMessage id="news.list" />}
						>
							<SearchBar
								{...props.searchProps}
								placeholder={this.props.intl.formatMessage({ id: 'label.searchBar' })}
							/>
							<BootstrapTable
								{...props.baseProps}
								filter={filterFactory()}
								pagination={paginationFactory()}
								expandRow={expandRow}
								className="table-responsive"
								bordered
								hover
							/>
							{/* <CSVButton {...props.csvProps} /> */}
							{!isEmpty(list) && (
								<ExportExcel data={list} columnsToExport={columnsToExport} tableName="Noticias" />
							)}
						</TableWrapper>
					)}
				</ToolkitProvider>
			</Admin>
		)
	}
}

NewsListPagination.propTypes = {
	auth: PropTypes.object.isRequired,
	news: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth, news }) => ({ auth, news })
const mapDispatchToProps = {
	getAllNewsAdmin,
	editNews,
	getNews,
	getAllCleanNews,
	updateNews,
	activateNews
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewsListPagination))
