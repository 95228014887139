import React from 'react'
import styles from './dts-amigo-solidario.module.css'
import Friend from './img/daretoshare-01.svg'
import ButtonAmigoSolidario from './ButtonAmigoSolidario/ButtonAmigoSolidario'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import DTSInfoCard from '../../DTSInfoCard/DTSInfoCard'

const DTSAmigoSolidario = () => (
  <div>
    <DTSInfoCard icon={Friend}>
      <h4 className={styles['txtVoluntario']}>
        <strong>
          <FormattedMessage id='dare.friendTit' />
        </strong>
      </h4>
      <p className={`contentPrograms`}>
        <FormattedHTMLMessage id='dare.friendTxt' />
      </p>
    </DTSInfoCard>

    <div className='row'>
      <div className={styles['buttonContainer']}>
        <ButtonAmigoSolidario />
      </div>
    </div>
  </div>
)

export default DTSAmigoSolidario
