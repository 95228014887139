import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from 'react-bootstrap-table2-paginator'
// import overlayFactory from 'react-bootstrap-table2-overlay'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'

const Table = props => {
  const {
    data,
    pageAct,
    limit,
    expand = true,
    expandableRow,
    onTableChange,
    totalDocs,
    structure,
    rowEvents
  } = props

  const RemotePagination = props => (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          page: pageAct,
          sizePerPage: limit,
          totalSize: totalDocs
        })}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField='_id'
            search={{ searchFormatted: true }}
            data={data}
            columns={structure}
            bootstrap4>
            {props => (
              <div>
                <BootstrapTable
                  remote
                  keyField='id'
                  data={data}
                  rowEvents={rowEvents}
                  columns={structure}
                  onTableChange={onTableChange}
                  {...paginationTableProps}
                  expandRow={expand && expandableRow}
                  // overlay={overlaySpinner}
                  filter={filterFactory()}
                  bordered
                  hover
                  {...paginationTableProps}
                />
                <PaginationListStandalone {...paginationProps} />
                <br />
                <p
                  style={{
                    float: 'left',
                    margin: '6px 0 0 0',
                    fontSize: '15px',
                    color: '#747474'
                  }}>
                  Total registros:
                  <span className='span-total'>{paginationProps.totalSize}</span>
                </p>
                <br />
              </div>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </div>
  )

  return (
    <RemotePagination
      data={data}
      page={pageAct}
      sizePerPage={totalDocs}
      totalSize={data.length}
      onTableChange={onTableChange}
    />
  )
}

export default Table
