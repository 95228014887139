import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put, take } from 'redux-saga/effects'
import { alertActions, alertTypes } from './../Alert/alert.redux'
import { actions, types } from './customer-project.redux'
import { isEmpty } from './../../util/helpers'
import heart from './../../assets/img/heart.png'
import Swal from 'sweetalert2'
import lenguaje from './text'

/**
 * Display the customer project list
 * @returns {iterator}
 */
export function* publicLoadAllCustomerProject({ payload }) {
  try {
    const { country } = payload
    const url = `/api/public/customer-project?country=${country}`
    const { data } = yield call(axios.get, url)
    yield put(actions.publicLoadAllCustomerProjectSuccess(data))
  } catch (error) {
    yield put(actions.publicLoadAllCustomerProjectFailure(error))
    console.log(error)
  }
}

/**
 * Display a single campaign record
 * @param   {object}   action.payload
 * @returns {iterator}
 */
export function* publicLoadCustomerProject({ payload } = {}) {
  try {
    const { slug } = payload
    const url = `/api/public/customer-project/${slug}`
    const { data } = yield call(axios.get, url)
    yield put(actions.publicLoadCustomerProjectSuccess(data))
  } catch (error) {
    yield put(actions.publicLoadCustomerProjectFailure(error))
    console.log(error)
  }
}

/**
 * Donate to a single campaign
 * @param   {object}   action.payload
 * @returns {iterator}
 */
export function* publicDonateCustomerProject({ payload } = {}) {
  try {
    const { slug, email, lang } = payload
    const url = `/api/public/customer-project/${slug}`
    const { data } = yield call(axios.post, url, { email })
    yield put(actions.publicLoadCustomerProjectSuccess(data.result))
    yield put(push('/'))
    if (data.duplicated)
      yield Swal.fire({
        text: lenguaje[lang].duplicatedText,
        icon: 'info'
      })
    else
      yield Swal.fire({
        title: lenguaje[lang].donationAlertTitle,
        text: lenguaje[lang].donationAlertText,
        imageUrl: heart,
        imageHeight: 90
      })
  } catch (error) {
    yield put(actions.publicLoadCustomerProjectFailure(error))
    console.log(error)
  }
}

/**
 * Display the customer project list
 * @returns {iterator}
 */
export function* loadAllCustomerProject() {
  try {
    const url = '/api/customer-project/'
    const { data } = yield call(axios.get, url)
    yield put(actions.loadAllCustomerProjectSuccess(data))
  } catch (error) {
    yield put(actions.loadAllCustomerProjectFailure(error))
    console.log(error)
  }
}

/**
 * Display a single campaign record
 * @param   {object}   action.payload
 * @returns {iterator}
 */
export function* loadCustomerProject({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/customer-project/${id}`
    const { data } = yield call(axios.get, url)
    yield put(actions.loadCustomerProjectSuccess(data))
  } catch (error) {
    yield put(actions.loadCustomerProjectFailure(error))
    console.log(error)
  }
}

/**
 * Create an campaign record
 * @param   {object}   action.payload Data to create an campaign record
 * @returns {iterator}
 */
export function* createCustomerProject({ payload }) {
  try {
    const url = '/api/customer-project/'
    const { data } = yield call(axios.post, url, payload)
    // Add new document to the list
    yield put(actions.createCustomerProjectSuccess(data))
    // Show notification
    yield put(alertActions.alertMessageSuccess('Registro guardado'))
    // Return the user to the list
    yield put(push('/dashboard/customer-project/list'))
  } catch (error) {
    yield put(actions.createCustomerProjectFailure(error))
    console.log(error)
  }
}

/**
 * Load the information of a single campaign record to edit it
 * @param   {object}   action.payload
 * @returns {iterator}
 */
export function* editCustomerProject({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/customer-project/${id}`
    const { data } = yield call(axios.get, url)
    yield put(actions.editCustomerProjectSuccess(data))
  } catch (error) {
    yield put(actions.editCustomerProjectFailure(error))
    console.log(error)
  }
}

/**
 * Update an campaign record
 * @param   {object}   action.payload Data to update an campaign record
 * @returns {iterator}
 */
export function* updateCustomerProject({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)

    if (isEmpty(prompt.payload)) return

    const { id, values } = payload
    const url = `/api/customer-project/${id}`

    const detail = { cause: 'Actualización', description: prompt.payload }
    const data = { payload: values, detail }
    const { data: updatedCustomerProject } = yield call(axios.put, url, data)
    yield put(actions.updateCustomerProjectSuccess(updatedCustomerProject))
    // Success notification and return the user to the list
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))
    yield put(push('/dashboard/customer-project/list'))
  } catch (error) {
    yield put(actions.updateCustomerProjectFailure(error))
    console.log(error)
  }
}

/**
 * Toggle the active property of an campaign record
 * @param   {object}   action.payload Data to update an campaign record
 * @returns {iterator}
 */
export function* toggleCustomerProject({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)
    const description = prompt.payload

    // The prompt was closed, stop the flow
    if (isEmpty(description)) return

    const { _id, active } = payload
    const url = `/api/customer-project/${_id}`

    const detail = { cause: active ? 'Desactivación' : 'Activación', description }
    const { data } = yield call(axios.put, url, { payload: { active: !active }, detail })
    yield put(actions.toggleCustomerProjectSuccess(data))

    // Show success notification
    const result = data.active ? 'activado' : 'desactivado'
    yield put(alertActions.alertMessageSuccess(`Registro ${result}`))
    yield put(push('/dashboard/customer-project/list'))
  } catch (error) {
    yield put(actions.toggleCustomerProjectFailure(error))
    console.log(error)
  }
}

export function* customerProjectSagas() {
  yield all([
    takeLatest(types.PUBLIC_LOAD_ALL_CUSTOMER_PROJECT_REQUEST, publicLoadAllCustomerProject),
    takeLatest(types.PUBLIC_LOAD_CUSTOMER_PROJECT_REQUEST, publicLoadCustomerProject),
    takeLatest(types.PUBLIC_DONATE_CUSTOMER_PROJECT_REQUEST, publicDonateCustomerProject),
    takeLatest(types.LOAD_ALL_CUSTOMER_PROJECT_REQUEST, loadAllCustomerProject),
    takeLatest(types.LOAD_CUSTOMER_PROJECT_REQUEST, loadCustomerProject),
    takeLatest(types.CREATE_CUSTOMER_PROJECT_REQUEST, createCustomerProject),
    takeLatest(types.EDIT_CUSTOMER_PROJECT_REQUEST, editCustomerProject),
    takeLatest(types.UPDATE_CUSTOMER_PROJECT_REQUEST, updateCustomerProject),
    takeLatest(types.TOGGLE_CUSTOMER_PROJECT_REQUEST, toggleCustomerProject)
  ])
}
