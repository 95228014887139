import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { isEmpty } from '../../util/inputsHelpers'
import { dmy } from '../../util/dateHelpers'
import { FormattedMessage } from 'react-intl'

const ModalDonation = props => {
  const { item, state, toggle } = props
  return (
    <Modal size="lg" isOpen={state.modal} toggle={toggle}>
    <ModalHeader toggle={toggle}><FormattedMessage id="modal.role" /></ModalHeader>
    <ModalBody>
      {!isEmpty(item) && (<p>
        <strong><FormattedMessage id="label.email" />: </strong> {item.email} <br />
        <strong><FormattedMessage id="label.role" />: </strong> {item.rol} <br />
        <strong><FormattedMessage id="label.is" />: </strong>{item.is} <br />
        <strong><FormattedMessage id="label.phone" />: </strong>{item.phone} <br />
        <strong><FormattedMessage id="table.active" />: </strong> {item.active ? <FormattedMessage id='general.confirmation' /> : <FormattedMessage id='general.negation' />}<br />
        <strong><FormattedMessage id="table.create" />: </strong>{dmy(item.createdAt)} <br />
        <strong><FormattedMessage id="table.update" />: </strong>{dmy(item.updatedAt)} <br />
      </p>)}
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}><FormattedMessage id="btn.cancel" /></Button>
    </ModalFooter>
  </Modal>
)
}

export default ModalDonation