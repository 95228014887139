import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Card, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap'

import styles from './cardNew.module.css'
import ArrowSVG from './../../../common/SVG/ArrowSVG'

export default function CardNew(props) {
  const { _id, title, route, sede, subsede, description, thumbnail, lg, md, sm, slug } = props
  const className = `col-md-${md || 6} col-lg-${lg || 4} col-sm-${sm || 12} mb-5`
  const { path } = thumbnail || {}

  return (
    <div className={props.className || className}>
      <Card className={styles['card']}>
        <Link to={`/${route}/${slug}`}>
          <div
            className={styles['center-cropped']}
            style={{
              backgroundImage: path
                ? `url(${path})`
                : 'url(https://dummyimage.com/286x180/d6cfd6/e2e3f0)'
            }}></div>
        </Link>
        <CardBody>
          {/* Sede and subsede of the publication */}
          <CardSubtitle className={`${styles['card__subtitle']} my-2 mb-3`}>
            {sede && <strong>{sede.name}</strong>}
            {subsede && <strong> | {subsede.name}</strong>}
          </CardSubtitle>

          {/* Publication's title */}
          <CardTitle className={`${styles['card__title']} mb-3`}>{title}</CardTitle>

          {/* Publication's description */}
          <CardText className={`${styles['card__text']}`}>{description}</CardText>

          {/* The button to open the publication */}
          <Link to={`/${route}/${slug}`} className={`${styles['card__button']}`}>
            <FormattedMessage id='btn.see' />
            <ArrowSVG className={`${styles['card__button-image']}`} />
          </Link>
        </CardBody>
      </Card>
    </div>
  )
}
