import React from 'react'
import vivienda from './img/programas-02.svg'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import ListElement from '../../../Common/ListElement/ListElement';
import Bullet from '../../../Common/Bullet/Bullet'

const ProgramsHusing = () => (
  <div className="row">
    <div className=" mx-auto col-md-2 mb-lg-0">
      <img className="img-fluid rounded-circle mb-3 iconsPrograms" src={vivienda} alt="" />
    </div>
    <div className=" mx-auto col-md-10 mb-lg-0">
      <h4 className="txtPrograms">
        <FormattedMessage id="programs.dress" />
      </h4>
      <p className="contentPrograms" >
        <FormattedHTMLMessage id="programs.dressTxt" />
      </p>
      <ul className="font-weight-light lead mb-0 list-unstyled" >
        <ListElement message={"programs.dressT0"}>
          <Bullet />
        </ListElement>
        <ListElement message={"programs.dressT1"}>
          <Bullet />
        </ListElement>
        <ListElement message={"programs.dressT2"}>
          <Bullet />
        </ListElement>
        <ListElement message={"programs.dressT3"}>
          <Bullet />
        </ListElement>
      </ul><br /><br />
    </div>
  </div>
)

export default ProgramsHusing 