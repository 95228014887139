import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import { expandRow } from './helpers'
import OverlayTable from './OverlayTable'
import { Row, Col } from 'reactstrap'

const { SearchBar } = Search

class Table extends Component {
  render() {
    return (
      <ToolkitProvider
        keyField='_id'
        search={{ searchFormatted: true }}
        data={this.props.data}
        columns={this.props.structure}
        bootstrap4>
        {props => (
          <div className='w-100'>
            <TableSearchExport
              {...this.props}
              searchProps={props.searchProps}
              disableDownload={!(this.props.data.length > 0)}
            />
            <BootstrapTable
              {...props.baseProps}
              rowEvents={this.props.rowEvents}
              noDataIndication={() => <OverlayTable loading={this.props.loading} />}
              pagination={paginationFactory()}
              expandRow={this.props.expand && this.props.expandableRow}
              wrapperClasses='table-responsive'
              filter={filterFactory()}
              loading={this.props.loading}
              selectRow={this.props.selectRow}
              bordered
              hover
            />
          </div>
        )}
      </ToolkitProvider>
    )
  }
}

function TableSearchExport({ canDownload, disableDownload, searchProps, onExport, selected }) {
  return (
    <Row className='mb-4' id='table-toolbar'>
      <Col lg='12'>
        <SearchBar
          {...searchProps}
          placeholder='Barra de búsqueda'
          className='search-bar w-100'
        />
      </Col>
      {/* <Col lg='3'>
        {canDownload && (
          <Button
            color='success'
            block
            className='download-excel-button'
            onClick={onExport}
            disabled={disableDownload}>
            <i className='fas fa-cloud-download-alt mr-2' />
            <span>Descargar {selected.length > 0 ? 'selección' : 'todo'}</span>
          </Button>
        )}
      </Col> */}
    </Row>
  )
}

Table.defaultProps = {
  data: [],
  structure: [],
  expand: true,
  loading: false,
  searchBar: true,
  enableExport: true,
  expandableRow: expandRow,
  canDownload: true,
  selected: []
}

export default Table
