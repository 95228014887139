import React from 'react'
import Header from './../../../assets/svg/softtek-hollidays-2020.svg'

function ComponentEU() {
  return (
    <div className='mx-4 customer-project-gallery-header'>
      <img src={Header} alt='Header' />

      <h2 className='my-4'>End of year – Giving is more important than ever!</h2>
      <p>Have you heard? 2020 has been an atypical year.</p>
      <p>
        This year Softtek will support three organizations in the U.S. that help vulnerable
        communities with a commodity that we often take for granted: food. We would like to
        make a donation on behalf of our clients and partners.
      </p>
      <p>
        We ask you to pick one of these organizations to allocate your portion of the donation.
        To do this, we ask you to please complete the following steps:
      </p>
      <ol>
        <li>
          <strong>Check out the causes.</strong> Learn about the organizations we have
          pre-selected.{' '}
          <span className='underlined-text'>(Click on each cause for more details.)</span>
        </li>
        <li>
          <strong>Make a selection.</strong> Click on the “Support this cause” to assign 30
          meals/relief assistance. Softtek will donate on your *behalf, anonymously. We will
          not share your personal data with anyone.
        </li>
        <li>
          <strong>Thank you!</strong> You will receive an email from Softtek’s Solidaria
          Foundation confirming your selection.
        </li>
      </ol>
      <p style={{ fontSize: '0.9rem' }}>
        <em>
          *Please note, that since Softtek will fund the donation, although you are selecting
          the organizations, you will NOT be entitled to a charitable deduction on your tax
          return for Softtek’s donation.
        </em>
      </p>
      <p>Thank you!</p>
      <p>
        <strong>The Softtek Family</strong>
      </p>
    </div>
  )
}

export default ComponentEU
