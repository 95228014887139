import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './loading.module.css'
import logo from '../../../assets/img/logos/3iconos-solidaria-verde.svg'
import { connect } from 'react-redux'
export class LoadingPage extends Component {
  render() {
    const { loading } = this.props.loading
    return (
      loading &&
      (<div className={styles['overlay-init']}>
        <div className={styles['wrap-overlay']}>
          <img src={logo} className={styles['blink']} style={{ height: 'auto', width: '50%' }} alt='' />
        </div>
        <ul className={styles['straps-pos']}>
          <li className={styles['li']}><div className={styles['animate-me']}></div></li>
          <li className={styles['li']}><div className={styles['animate-me']}></div></li>
          <li className={styles['li']}><div className={styles['animate-me']}></div></li>
        </ul>
      </div>)
    )
  }
}



LoadingPage.propTypes = { loading: PropTypes.object.isRequired }
const mapStateToProps = ({ loading }) => ({ loading })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(LoadingPage)