import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Form } from 'reactstrap'
import swal from '@sweetalert/with-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { postCarrousel } from './../../redux/actions/carrouselActions'

// Layout
import Admin from './../Layout/Admin/Admin'
// Forms
import CarrouselForm from './forms/CarrouselForm'
// Helpers
import validations from './validations'
import { validateOnSubmit } from './../Campaign/lib/helpers'

export class CarrouselCreate extends Component {
  state = {
    project: {
      value: '',
      valid: null,
      text: '',
      feed: []
    }
  }

  // Handle para agregar los valores a el
  // state de la propiedad de un objeto
  // recibe el nombre y un objeto de propiedades
  handleInputChange = event => {
    const { name, value } = event.target
    this.setState(s => ({ ...s, [name]: { ...s[name], value } }))
  }

  // Handle para validar el formulario
  onSubmit = event => {
    event.preventDefault()
    const { intl, history } = this.props
    let {
      state: { project }
    } = this
    const stateToValidate = { project }

    const validated = validateOnSubmit(stateToValidate, validations)
    if (validated.pass) {
      const s = { ...validated.state }
      const payload = { project: s.project.value }
      swal({
        text: '¿Estás seguro que deseas publicar este proyecto en el carrusel?',
        buttons: true,
        icon: 'warning'
      })
        .then(confirm => (confirm ? this.props.postCarrousel(payload, history, intl) : ''))
        .catch(e => console.log('Error'))
    } else {
      // Las validaciones NO pasaron
      const faildState = validated.state
      this.setState({ ...faildState })
    }
  }

  render() {
    return (
      <Admin>
        <div className='row'>
          <div className='offset-xl-1 col-xl-10 offset-lg-1 col-lg-10'>
            <h1 className='h3 mb-2 text-gray-800'>
              <FormattedMessage id='carrousel.title' />
            </h1>
          </div>
        </div>

        <Form onSubmit={this.onSubmit}>
          <div className='row'>
            <div className='col-xl-10 col-lg-10 offset-lg-1'>
              <CarrouselForm {...this} />
            </div>
          </div>
        </Form>
      </Admin>
    )
  }
}

CarrouselCreate.propTypes = {
  carrousel: PropTypes.object.isRequired,
  postCarrousel: PropTypes.func.isRequired
}
const mapStateToProps = ({ carrousel }) => ({ carrousel })
const mapDispatchToProps = { postCarrousel }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CarrouselCreate))
