//import validator from 'validator'
import React from 'react'
import { isEmpty } from '../../../../util/isEmpty'
import msg from './../../../../lang/message'
import { FormattedMessage } from 'react-intl'

const SedesValidations = [
  {
    field: 'name',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.obliged' />] }
      } else if (value.length < 3) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.min3' />] }
      } else if (value.length > 25) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.max25' />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  },
  {
    field: 'description',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.obliged' />] }
      } else if (value.length < 5) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.min5' />] }
      } else if (value.length > 140) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.max140' />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  },
  {
    field: 'paypal_account',
    function(value) {
      let check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      return check
    }
  },
  {
    field: 'paypal_clientId',
    function(value) {
      let check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      return check
    }
  },
  {
    field: 'paypal_secret',
    function(value) {
      let check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      return check
    }
  },
  {
    field: 'conekta_account',
    function(value) {
      let check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      return check
    }
  },
  {
    field: 'conekta_clientId',
    function(value) {
      let check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      return check
    }
  },
  {
    field: 'conekta_secret',
    function(value) {
      let check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      return check
    }
  },
  {
    field: 'languageCode',
    function(value) {
      let check = { valid: false, invalid: true, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.obliged' />] }
      } else {
        check = { ...check, valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  },
  {
    field: 'countryCode',
    function(value) {
      let check = { valid: false, invalid: true, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.obliged' />] }
      } else {
        check = { ...check, valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  },
  {
    field: 'termsofPrivacy',
    function(value) {
      let check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      return check
    }
  },
  {
    field: 'currency',
    function(value) {
      let check = { valid: false, invalid: true, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id='help.obliged' />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  }
]
export default SedesValidations
