import React from 'react'
import styles from '../Main/Common/Cards/card.module.css'

const ImgPreview = ({ path, url, title, className, style, ...props }) => {
  return (
    <img
      className={`${styles['card-thumbnail']} ${className ? className : ''}`}
      style={style}
      src={path ? `${path}` : url ? url : 'https://dummyimage.com/286x180/d6cfd6/e2e3f0'}
      alt={title}
      title={title}
    />
  )
}

export default ImgPreview
