import React from 'react'
import validator from 'validator'
import { isEmpty } from '../../../../util/isEmpty'
import msg from './../../../../lang/message'
import { FormattedMessage } from 'react-intl';

const Subsedevalidations = [
  {
    field: 'name',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else if (value.length < 3) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.min3" />] }
      }
      else if (value.length > 25) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.max25" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  },
  {
    field: 'description',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else if (value.length < 5) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.min5" />] }
      }
      else if (value.length > 140) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.max140" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [''] }
      }
      return check
    }
  },
  {
    field: 'location_address',
    function(value) {

      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, msg.requerido] }
      } else if (value.length < 7) {
        check = { ...check, feed: [...check.feed, msg.min7] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  },
  {
    field: 'email',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, msg.requerido] }
      } else if (!validator.isEmail(value)) {
        check = { ...check, feed: [...check.feed, msg.email] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  },
  {
    field: 'sede',
    function(value) {

      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, msg.requerido] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [msg.correcto] }
      }
      return check
    }
  },
]
export default Subsedevalidations