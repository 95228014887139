import React, { Fragment } from 'react'
import { isEmpty } from './helpers'
import { FormGroup, FormText, Label } from 'reactstrap'
const styles = {
  // .invalid
  invalidClass: {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#dc3545"
  },
  // .valid
  validClass: {
    width: "100%",
    marginTop: ".25rem",
    fontSize: "80%",
    color: "#28a745"
  }
}



const FeedBackCustom = ({ forp, feed, valid, text }) => {
  return (
    <Fragment>
      {
        !isEmpty(feed) &&
        !isEmpty(valid) &&
        feed.length !== 0 &&
        feed.map(f => <p
          style={
            !isEmpty(valid) ?
              valid === true ? styles.validClass : styles.invalidClass
              : ''}
          id={`${forp}-${f}`}
          key={`${forp}-${f}`}
        >
          {(f)}
        </p>)
      }
      {
        !isEmpty(text) &&
        valid === null &&
        <FormText>
          {(text)}
        </FormText>
      }
    </Fragment>
  )
}



function FormGroupCustom({ valid,
  forp,
  text,
  feed,
  children,
  label }) {
  return (
    <FormGroup id={forp}>
      {!isEmpty(forp) &&
        <Label for={forp} className={
          !isEmpty(valid) ?
            valid === true ? 'text-success' : 'text-danger'
            : ''
        }>{!isEmpty(label) && (label)} </Label>
      }
      {children}
      <FeedBackCustom forp={forp} feed={feed} valid={valid} text={text} />
    </FormGroup>
  )
}

export default FormGroupCustom
