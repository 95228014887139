import React from 'react'
import moment from 'moment'
// import validator from 'validator'
import { isEmpty } from '../Helpers'
import msg from './../../../lang/message'
import { FormattedMessage } from 'react-intl';
export default [
  {
    field: 'type',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [<FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'startDate',
    function(value, state = null) {
      const today = moment().format('YYYY-MM-DD')
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [<FormattedMessage id="help.obliged" />] }
      } else if (!moment(today).isSameOrBefore(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.beforeToday" />] }
      }
      else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'endDate',
    function(value, state = null) {
      const { startDate } = state
      const today = moment().format('YYYY-MM-DD')
      const sd = startDate.value
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(sd)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.reqStartDate" />] }
      }
      else if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      }
      else if (!moment(today).isBefore(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.equalToday" />] }
      }
      else if (!moment(sd).isBefore(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.equalStart" />] }
      }
      else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },

  {
    field: 'eventDate',
    function(value, state = null) {
      const { startDate, endDate } = state

      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      }
      // Es requerida la fecha inicial.
      else if (isEmpty(startDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.reqStartDate" />] }
      }
      // Es requerida la fecha final. 
      else if (isEmpty(endDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.reqEndDate" />] }
      }
      // Esta en el  rango de fecha de inicio y final
      else if (!moment(value).isBetween(startDate.value, endDate.value, 'days', '[]')) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.rank" />] }
      }
      else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'objective',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else if (value.length < 5) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.min5" />] }
      } else if (value.length > 140) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.max140" />] }
      }
      else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'institution',
    function(value, state = null) {

      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'title',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else if (value.length < 3) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.min3" />] }
      }
      else if (value.length > 25) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.max25" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'description',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else if (value.length < 5) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.min5" />] }
      }
      else if (value.length > 140) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.max140" />] }
      }
      else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'thumbnail',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },

  {
    field: 'donations_startDate',
    function(value, state = null) {
      const { startDate, endDate, donation } = state
      let check = { valid: false, text: '', feed: [] }
      if (donation === false) return false

      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      }
      else if (isEmpty(startDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.required" />] }
      }
      else if (isEmpty(endDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.required" />] }
      }
      else if (!moment(value).isBetween(startDate.value, endDate.value, 'days', '[]')) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.rank" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'donations_endDate',
    function(value, state = null) {
      const { startDate, endDate, donation } = state
      let check = { valid: false, text: '', feed: [] }
      if (donation === false) return false

      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      }
      else if (isEmpty(startDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.required" />] }
      }
      else if (isEmpty(endDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.required" />] }
      }
      else if (!moment(value).isBetween(startDate.value, endDate.value, 'days', '[]')) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.rank" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'donations_text',
    function(value, state = null) {
      if (state.donation === false) return false
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'categorie',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'cause',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'activities',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'skills',
    function(value, state = null) {
      if (state.type.value !== 'probono') return false
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'content',
    function(value, state = null) {
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else if (value.length < 5) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.min5" />] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },


  {
    field: 'volunteering_startDate',
    function(value, state = null) {
      const { startDate, endDate, volunteering } = state
      let check = { valid: false, text: '', feed: [] }
      if (volunteering === false) return false

      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      }
      else if (isEmpty(startDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.required" />] }
      }
      else if (isEmpty(endDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.required" />] }
      }
      else if (!moment(value).isBetween(startDate.value, endDate.value, 'days', '[]')) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.rank" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'volunteering_endDate',
    function(value, state = null) {
      const { startDate, endDate, volunteering } = state
      let check = { valid: false, text: '', feed: [] }
      if (volunteering === false) return false

      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      }
      else if (isEmpty(startDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.required" />] }
      }
      else if (isEmpty(endDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.required" />] }
      }
      else if (!moment(value).isBetween(startDate.value, endDate.value, 'days', '[]')) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.rank" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'volunteering_volunteersRequired',
    function(value, state = null) {
      if (state.volunteering === false) return false
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value) || value <= 0) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },

  {
    field: 'inkindDonations_startDate',
    function(value, state = null) {
      const { startDate, endDate, inkindDonations } = state
      let check = { valid: false, text: '', feed: [] }
      if (inkindDonations === false) return false

      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      }
      else if (isEmpty(startDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.reqStartDate" />] }
      }
      else if (isEmpty(endDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.reqEndDate" />] }
      }
      else if (!moment(value).isBetween(startDate.value, endDate.value, 'days', '[]')) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.rank" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'inkindDonations_endDate',
    function(value, state = null) {
      const { startDate, endDate, inkindDonations } = state
      let check = { valid: false, text: '', feed: [] }
      if (inkindDonations === false) return false

      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, msg.obligado] }
      }
      else if (isEmpty(startDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.reqStartDate" />] }
      }
      else if (isEmpty(endDate.value)) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.obliged" />] }
      }
      else if (!moment(value).isBetween(startDate.value, endDate.value, 'days', '[]')) {
        check = { ...check, feed: [...check.feed, <FormattedMessage id="help.rank" />] }
      }
      else {
        check = { valid: true, invalid: false, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
  {
    field: 'inkindDonations_text',
    function(value, state = null) {
      if (state.inkindDonations === false) return false
      let check = { valid: false, text: '', feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, msg.obligado] }
      } else {
        check = { valid: true, text: '', feed: [<FormattedMessage id="help.right" />] }
      }
      const result = { [this.field]: { ...check } }
      return result
    }
  },
]