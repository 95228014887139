import axios from 'axios'
import { all, takeLatest, call, put } from 'redux-saga/effects'
import {
  LOAD_COUNTERS_REQUEST,
  LOAD_ALL_DONATION_PAYPAL_REQUEST,
  LOAD_ONE_DONATION_PAYPAL_REQUEST,
  loadCountersPaypalSuccess,
  loadCountersPaypalFailure,
  loadAllDonationPaypalSuccess,
  loadAllDonationPaypalFailure,
  donationPaypalGetOneSuccess,
  donationPaypalGetOneFailure,
  graphDonationPaypalSuccess
} from './paypal.redux'

/**
 * Show donation information, all and how many
 */
export function* paypalCounters() {
  try {
    const url = '/api/donations/payPal/counters/info'
    const { data } = yield call(axios.get, url)
    yield put(loadCountersPaypalSuccess(data))
  } catch (error) {
    yield put(loadCountersPaypalFailure(error))
  }
}

/**
 * Help function to filter data
 * @param   {object}   data returns page number for pagination
 */
function encodeFiltersData(params) {
  return Object.keys(params)
    .map(key => key + '=' + params[key])
    .join('&')
}

/**
 * Help function to filter data, remove null or undefined fields
 * @param   {object}   data returns page number for pagination
 */
function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

/**
 * Show paged PayPal donations
 * @returns {iterator}
 */
export function* paypalDonationPagination({ filters }) {
  try {
    let url = ''
    let urlCounters = ''
    let urlGraph = ''

    if (filters.search) {
      const oCleansed = removeEmpty(filters.search)
      const filterString = encodeFiltersData(oCleansed)
      url = `/api/donations/payPal?${filterString}&page=${filters.page}`
      urlCounters = `/api/donations/payPal/counters/info?${filterString}`
      urlGraph = `/api/donations/payPal/graph/info?${filterString}`
    } else {
      const filterString = encodeFiltersData(filters)
      url = `/api/donations/payPal?${filterString}`
      urlCounters = '/api/donations/payPal/counters/info'
      urlGraph = `/api/donations/payPal/graph/info`
    }

    const { data } = yield call(axios.get, url)
    const { data: counters } = yield call(axios.get, urlCounters)
    const { data: graph } = yield call(axios.get, urlGraph)

    yield all([
      put(loadAllDonationPaypalSuccess(data)),
      put(loadCountersPaypalSuccess(counters)),
      put(graphDonationPaypalSuccess(graph))
    ])
  } catch (error) {
    yield put(loadAllDonationPaypalFailure(error.message))
  }
}

/**
 * Display a single donation record
 * @param   {object}   action.payload Data to take the id of the requested institution
 * @returns {iterator}
 */
export function* paypalDonationOne({ id }) {
  try {
    const url = `/api/donations/payPal/${id}`
    const { data } = yield call(axios.get, url)
    yield put(donationPaypalGetOneSuccess(data.data))
  } catch (error) {
    yield put(donationPaypalGetOneFailure(error.message))
  }
}

export function* paypalSagas() {
  yield all([
    takeLatest(LOAD_COUNTERS_REQUEST, paypalCounters),
    takeLatest(LOAD_ALL_DONATION_PAYPAL_REQUEST, paypalDonationPagination),
    takeLatest(LOAD_ONE_DONATION_PAYPAL_REQUEST, paypalDonationOne)
  ])
}
