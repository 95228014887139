import React from 'react'
// import Card from '../../Main/Common/Cards/CardNew'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { isEmpty } from '../../../util/isEmpty'
import { FormattedMessage } from 'react-intl'
import cardStyles from '../../Main/Common/Cards/card.module.css'
import paginationStyles from './pagination.module.css'

class Paginate extends React.Component {
  state = {
    data: [],
    currentPage: 1,
    sizePerPage: this.props.sizePerPage
  }

  componentWillReceiveProps(np) {
    this.setState({ data: np.data })
  }

  componentDidMount() {
    this.setState({ data: this.props.data, sizePerPage: this.props.sizePerPage })
  }

  handleClick = event => {
    this.setState({
      currentPage: Number(event.target.id)
    })
  }

  render() {
    const { data, currentPage, sizePerPage } = this.state

    // Logic for displaying current data
    const indexOfLast = currentPage * sizePerPage
    const indexOfFirst = indexOfLast - sizePerPage
    const currentData = data.slice(indexOfFirst, indexOfLast)
    const Card = this.props.component

    const renderData = currentData.map(p => (
      <Card key={p._id} {...p} route={this.props.route} />
    ))

    // Logic for displaying page numbers
    const pageNumbers = []
    for (let i = 1; i <= Math.ceil(data.length / sizePerPage); i++) {
      pageNumbers.push(i)
    }

    const renderPageNumbers = pageNumbers.map(number => {
      let isActive = number === this.state.currentPage
      return (
        <PaginationItem active={isActive}>
          <PaginationLink
            key={number}
            id={number}
            className={isActive ? paginationStyles['active_link'] : paginationStyles['link']}
            // href="#"
            onClick={this.handleClick}>
            {number}
          </PaginationLink>
        </PaginationItem>
      )
    })

    return (
      <div>
        <div className='row card-deck mx-auto mb-4'>
          {!isEmpty(renderData) ? (
            renderData
          ) : (
            <h4 className={`my-4 ${cardStyles['sinContenido']}`}>
              <FormattedMessage id='general.found' />
            </h4>
          )}
        </div>
        <Pagination
          className='d-flex justify-content-center mb-4'
          aria-label='Page navigation example'>
          {renderPageNumbers}
        </Pagination>
      </div>
    )
  }
}

export default Paginate
