import push from 'push.js'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import softtekLogo32 from '../../../assets/img/flatoicon/logo32.png'
import io from '../../../socket'
export class Push extends Component {

  state = {
    timeline: [],
    notifications: []
  }
  componentDidMount() {
    io.on('timeline', t => {
      push.create(t.moddule_name, {
        body: t.description,
        icon: softtekLogo32,
        timeout: 15000,
        onClick: function () {
          window.focus();
          this.close();
        }
      })
    })
  }

  componentWillUnmount() {
    //this.socket.close();
  }

  render() { return (<Fragment>{''}</Fragment>) }
}

const mapStateToProps = ({ auth, location }) => ({ auth, location })
export default connect(mapStateToProps)(Push)
