import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import styles from './breadcrumb.module.css'

const Breadcrumb = ({ title, link, id }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className={`${styles['breadcrumb']} breadcrumb`}>
        <li className="breadcrumb-item">
          <Link to="/">
            <FormattedMessage id="nav.home" />
          </Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={link}>
            <FormattedMessage id={id} />
          </Link>
        </li>
        <li className="breadcrumb-item active">{title}</li>
      </ol>
    </nav>
  )
}

export default Breadcrumb