import React, { Component } from 'react'
import { Row } from 'reactstrap'
import CollapsibleCard from './../CollapsibleCard'
import { injectIntl } from 'react-intl'

// BootstrapTable extensions
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { isEmpty } from '../../../../../util/helpers'
import ExportExcel from '../../../../common/ExportExcel/ExportExcel'
import { capitalize } from '../../../Helpers/report'
import { dmy } from '../../../../../util/dateHelpers'

const { SearchBar } = Search

const amountFormat = cell => (cell ? `${cell.toFixed(2)}` : '0.00')

export const generateColumnsToExport = formatMessage => [
  {
    title: formatMessage({ id: 'modal.donationNet' }) + ' (USD)',
    value: row => amountFormat(row.amount - row.fee)
  },
  {
    title: formatMessage({ id: 'modal.feePayPal' }) + ' (USD)',
    value: row => amountFormat(row.fee)
  },
  {
    title: formatMessage({ id: 'label.currency' }),
    value: () => 'USD'
  },
  {
    title: `${formatMessage({ id: 'modal.donationNet' })}`,
    value: row => {
      const amount = row.originalAmount === 0 ? row.amount : row.originalAmount
      return amountFormat(amount - row.originalFee)
    }
  },
  {
    title: `${formatMessage({ id: 'modal.feePayPal' })}`,
    value: row => amountFormat(row.originalFee)
  },
  {
    title: `${formatMessage({ id: 'label.currency' })}`,
    value: row => row.currency
  },
  {
    title: formatMessage({ id: 'label.type' }),
    value: row => capitalize(row.type)
  },
  {
    title: formatMessage({ id: 'table.approved' }),
    value: row =>
      row.approved
        ? formatMessage({ id: 'general.confirmation' })
        : formatMessage({ id: 'general.negation' })
  },
  {
    title: formatMessage({ id: 'general.date' }),
    value: row => dmy(row.createdAt)
  }
]

class RowDonations extends Component {
  render() {
    const {
      intl: { formatMessage },
      data,
      columns,
      label
    } = this.props
    const columnsToExport = generateColumnsToExport(formatMessage)
    return (
      <Row>
        <CollapsibleCard
          expanded={data.length > 0}
          id='donations_table'
          label={formatMessage({ id: 'report.tableDonations' })}
          className='col-xl-12 col-lg-12'>
          <ToolkitProvider
            keyField='_id'
            data={data}
            columns={columns}
            bootstrap4={true}
            search={{
              searchFormatted: true
            }}
            exportCSV={{
              fileName: `DonacionesFS.csv`,
              separator: ',',
              noAutoBOM: false
            }}>
            {props => (
              <div className='react_boostrap_table__small_table'>
                <SearchBar
                  {...props.searchProps}
                  placeholder={formatMessage({ id: 'label.search' })}
                />
                <div className='table-responsive'>
                  <BootstrapTable
                    {...props.baseProps}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                    bordered
                    hover
                  />
                </div>
                {!isEmpty(data) && (
                  <div className='pt-3 pb-5'>
                    <ExportExcel
                      data={data}
                      columnsToExport={columnsToExport}
                      tableName={label}
                    />
                  </div>
                )}
              </div>
            )}
          </ToolkitProvider>
        </CollapsibleCard>
      </Row>
    )
  }
}

export default injectIntl(RowDonations)
