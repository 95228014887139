import React from 'react'
import styles from './ImgPerfil.module.css'

function ImgPerfil(props) {
  return (
      <div className="col-lg-3 text-xs-center">
        <center className={styles['container__img-profile']}>
          <img
            src={props.avatar || "https://via.placeholder.com/300"}
            className={styles['img-profile']}
            alt="avatar"
          />
        </center>
    </div>
  )
}

export default ImgPerfil
