import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback
} from 'reactstrap'
import { connect } from 'react-redux'
import { actions } from '../holiday-project.redux'
import lenguaje from '../../CustomerProject/text'
import HolidayProjectLogo from './HolidayProjectLogo'
import { isEmail } from '../../../util/form'

const ModalConfirmation = ({ label, children, modal, toggle, onSubmit, ...match }) => {
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{label}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button color='info' onClick={onSubmit}>
            {lenguaje.MX.buttonConfirm}
          </Button>{' '}
          <Button color='danger' onClick={toggle}>
            {lenguaje.MX.buttonCancel}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

function HolidayProjectDonate({ project, donate, match }) {
  const [email, setEmail] = useState('')
  const [modal, setModal] = useState(false)
  const [error, setError] = useState('')
  const [touched, setTouched] = useState(false)

  const message = lenguaje.MX.validEmail

  const toggle = () => {
    if (!error) setModal(modal => !modal)
  }

  function handleChangeEmail(event) {
    const { value } = event.target
    const validation = isEmail(value, message)
    setError(validation)
    setEmail(value)
    if (touched === false) setTouched(true)
  }

  function handleDonate(event) {
    event.preventDefault()
    donate({ slug: project.slug, email })
    toggle()
  }

  return (
    <>
      <CardPanel title={lenguaje.MX.panelTitle}>
        <HolidayProjectLogo alt={project.title} src={project.logo.path} />
        <form onSubmit={toggle} className='card-body miniCardBody'>
          <Input
            type='email'
            className='w-100 mb-3'
            placeholder='email'
            onChange={handleChangeEmail}
            value={email}
            valid={touched && !error}
            invalid={touched && !!error}
          />
          <FormFeedback>{error}</FormFeedback>
          <p style={{ fontSize: '10px' }}>{lenguaje.MX.donateText}</p>
          <p style={{ fontSize: '10px' }}>
            Importante:
            <ol style={{ listStyleType: 'none', marginLeft: '-25px' }}>
              <li>
                - Realizaremos la donación de 5 despensas para 30 días, a la organización que
                elijas, anónimamente. <u>No compartiremos tus datos con nadie</u>.
              </li>
              <li>
                - La donación se realizará solo a la organización que selecciones, a través de
                Fundación Solidaria.
              </li>
            </ol>
          </p>
          <Button
            block
            color='info'
            type='button'
            style={{ fontSize: 17 }}
            disabled={!email}
            onClick={toggle}>
            {lenguaje.MX.donateButton}
          </Button>
        </form>
      </CardPanel>

      <ModalConfirmation
        {...match}
        modal={modal}
        toggle={toggle}
        label={lenguaje.MX.modalConfirmlabel}
        onSubmit={handleDonate}>
        {`Tu donación será destinada a ${project.title} si tu correo es ${email} ¿Deseas confirmar tu elección?`}
      </ModalConfirmation>
    </>
  )
}

function CardPanel({ title, children }) {
  return (
    <div className='card mb-4 box-shadowCard miniCard holiday-project-card'>
      <div className='card-header miniCardHeader holiday-project-card-header'>
        <h4 className='my-0'>{title}</h4>
      </div>
      <>{children}</>
    </div>
  )
}

const mapDispatchToProps = { donate: actions.publicDonateHolidayProjectRequest }
const withConnect = connect(
  null,
  mapDispatchToProps
)

export default withConnect(injectIntl(HolidayProjectDonate))
