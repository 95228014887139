import React from 'react'
import { Button } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { isEmpty } from './../../../util/isEmpty'
import { ymd } from './../../../util/dateHelpers'
import { approvedIcon } from '../../Donations/helpers/tableHelpers'

// El componente BootstrapTable solo acepta un objeto plano como data
export const formatToTable = data =>
  data.map(d => {
    if (!isEmpty(d.subsede)) d = { ...d, subsede: d.subsede.name }
    if (!isEmpty(d.institution)) d = { ...d, institution: d.institution.name }
    return d
  })
// Cell formatters
export const amountFormat = cell => (cell ? `$ ${cell.toFixed(2)}` : '$ 0.00')
export const emptyFormat = cell => (!isEmpty(cell) ? capitalize(cell) : 'Sin información')

// onFilter function to identify changes in filtering data
export const onFilteringPlain = (column, value) => console.log(`Mostrar ${column}: ${value}`)
export const onFilteringDate = (column, { date, comparator }) =>
  console.log(`Mostrar ${column} ${comparator} a: ${date}`)
export const onFilteringNumber = (column, { number, comparator }) =>
  console.log(`Mostrar ${column} ${comparator} a: ${number}`)

// Helper ayuda a que la Mostar todos los textos en Upercase La primera letra
// y poner punto al final.
export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

export const donationsColumns = (formatMessage, formater) => [
  {
    dataField: 'amount',
    text: formatMessage({ id: 'modal.donationNet' }),
    formatter: (cell, row) => amountFormat(cell - row.fee) + ' USD',
    sort: true
  },
  {
    dataField: 'fee',
    text: formatMessage({ id: 'modal.feePayPal' }),
    formatter: (cell, row) => {
      if (row.type !== 'paypal') return 'N/A'
      return amountFormat(cell) + ' USD'
    },
    sort: true
  },
  {
    dataField: 'originalAmount',
    text: `${formatMessage({ id: 'modal.donationNet' })} (${formatMessage({
      id: 'label.currency'
    })})`,
    formatter: (cell, row) => {
      const amount = cell === 0 ? row.amount : cell
      return amountFormat(amount - row.originalFee) + ' ' + row.currency
    },
    sort: true
  },
  {
    dataField: 'originalFee',
    text: `${formatMessage({ id: 'modal.feePayPal' })} (${formatMessage({
      id: 'label.currency'
    })})`,
    formatter: (cell, row) => {
      if (row.type !== 'paypal') return 'N/A'
      return amountFormat(cell) + ' ' + row.currency
    },
    sort: true
  },
  {
    dataField: 'type',
    text: formatMessage({ id: 'label.type' }),
    formatter: capitalize,
    sort: true
  },
  {
    dataField: 'approved',
    text: formatMessage({ id: 'table.approved' }),
    formatter: cell => <center>{approvedIcon(cell)}</center>,
    sort: true
  },
  {
    dataField: 'createdAt',
    text: formatMessage({ id: 'general.date' }),
    formatter: formater,
    sort: true
  }
]

export const ButtonView = ({ campaing, children, handleView }) => (
  <Button
    className='btn btn-block'
    id='viewCampaign'
    color='primary'
    onClick={() => handleView(campaing._id)}>
    <i className='fas fa-eye'></i> {children}
  </Button>
)

export const ButtonEdit = ({ campaing, children, handleEdit }) => (
  <Button
    className='btn btn-block'
    id='editCampaign'
    onClick={() => handleEdit(campaing._id)}
    color='info'
    title=''>
    <i className='fas fa-edit'></i> {children}
  </Button>
)

export const ButtonDelete = ({ campaing, children, handleDelete }) => (
  <Button
    className='btn btn-block'
    color={campaing.active ? 'danger' : 'success'}
    onClick={() => handleDelete(campaing)}>
    <i className='fas fa-power-off'></i> {children}
  </Button>
)

export const ButtonCloseProject = ({ campaing, children, handleCloseProject }) => (
  <Button
    className='btn btn-block'
    id='closeCampaign'
    color='secondary'
    onClick={() => handleCloseProject(campaing)}>
    <i className='fas fa-flag-checkered'></i> {children}
  </Button>
)

export const ButtonDetailProject = ({ campaing, children, handleDetailProject }) => (
  <Button
    className='btn btn-block'
    id='detailCampaign'
    color='secondary'
    onClick={() => handleDetailProject(campaing)}>
    <i className='fas fa-flag-checkered'></i> {children}
  </Button>
)

export const ButtonViewSubscribers = ({ campaing, children, handleViewSubscribers }) => (
  <Button
    className='btn btn-block'
    id='subscribersCampaign'
    color='success'
    onClick={() => handleViewSubscribers(campaing)}>
    <i className='fas fa-people-carry'></i> {children}
  </Button>
)

export const ButtonGallerie = ({ campaing, children, handleViewGallerie }) => (
  <Button
    className='btn btn-block'
    id='gallerieCampaign'
    color='success'
    onClick={() => handleViewGallerie(campaing)}>
    <i className='fas fa-image'></i> {children}
  </Button>
)

export const detailRow = {
  // Table que muestra el registro historico de modificaciones
  renderer: row =>
    !isEmpty(row.detail) && (
      <table style={{ width: '100%', backgroundColor: 'whitesmoke' }}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id='modal.cause' />
            </th>
            <th>
              <FormattedMessage id='alert.desTitle' />
            </th>
            <th>
              <FormattedMessage id='general.date' />
            </th>
            <th>Usuario</th>
          </tr>
        </thead>
        <tbody>
          {row.detail.map(d => (
            <tr>
              <td>{d.cause}</td>
              <td>{d.description}</td>
              <td>{ymd(d.createdAt)}</td>
              <td>{!isEmpty(d.created_by) ? d.created_by.name : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
}
