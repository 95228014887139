import { updatedItem, deleteItem } from '../../util/helpers'
import {
  GET_All_DONATION,
  POST_DONATION,
  UPDATE_DONATION,
  GET_All_DONATION_PROJECTS,
  DELETE_DONATION,
  EDIT_DONATION
} from '../actions/types'

const initialState = {
  list: [],
  edit: {},
  projects: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_All_DONATION:
      return {
        ...state,
        list: [...action.payload]
      }

    case POST_DONATION:
      return {
        ...state,
        list: [action.payload, ...state.list]
      }

    case UPDATE_DONATION:
      return {
        ...state,
        list: updatedItem(state.list, action.payload)
      }

    case EDIT_DONATION:
      return {
        ...state,
        edit: { ...action.payload }
      }

    case DELETE_DONATION:
      return {
        ...state,
        list: deleteItem(state.list, action.payload._id)
      }

    case GET_All_DONATION_PROJECTS:
      return {
        ...state,
        projects: [...action.payload]
      }

    default:
      return state
  }
}
