import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {
  onChangeState,
  handleChangeSelectData,
  specialValidationsOnChange,
  checkOnchange, specialValidations
} from '../../../../util/inputsHelpers'
import swal from '@sweetalert/with-react'
import { setNotify } from './../../../../redux/actions/notifyAction'
import { spinnerToggle } from './../../../../redux/actions/spinnerActions'
import InitiativeValidations from './InitiativeValidations'
import InitiativeForm from './InitiativeForm'
//import msg from './../../../../lang/message'
import { injectIntl, FormattedMessage } from 'react-intl'
import styles from './initiative.module.css'
import { isEmpty } from './../../../../util/isEmpty'
import { withRouter } from 'react-router-dom'

const initialState = {

  fields: {
    description: {
      value: '',
      valid: null,
      invalid: null,
      //text: msg.requerido,
      feed: []
    }
  },

  errors: {},
  modal: false,
}

export class ModalInitiative extends Component {

  state = { ...initialState }

  componentWillMount() {
    this.props.spinnerToggle()
  }

  handleChangeSelectData = props => {
    const field = props.name
    this.setState(handleChangeSelectData(props), () => {
      const { state } = this
      const validator = checkOnchange(state, InitiativeValidations, field)
      this.setState({ ...validator.state })
    })
  }

  handleChange = event => {
    const field = event.target.name
    this.setState(onChangeState(event), () => {
      const { state } = this
      const validator = specialValidationsOnChange(state.fields, InitiativeValidations, field)
      if (validator !== null) this.setState({ fields: { ...validator.stateValidated } })
    })
  }

  // @return       Void.
  // @description  handle the onChange event
  // for nested props
  onChangeObject = event => {
    const target = event.target
    const value = target.value
    const [prop, subprop] = target.name.split('.')
    this.setState(state => ({
      [prop]: { ...state[prop], [subprop]: value }
    }))
  }

  needLogin = history => history.push('/login')

  needInfo = history => {
    const { intl } = this.props
    swal(intl.formatMessage({ id: 'alert.profileValidation' }), ``, 'warning')
    history.push('/user')
  }

  // @return        Void
  // Description    Get the details
  // data from the _id selected and
  // open a modal.
  onClickInitiative = () => {
    let click = null
    const { history } = this.props
    const { user, isAuthenticated } = this.props.auth
    if (!isAuthenticated) {
      click = this.needLogin(history)
    }
    else if ((isAuthenticated === true) && (
      isEmpty(user) ||
      isEmpty(user.subsede) ||
      isEmpty(user.name) ||
      isEmpty(user.email))) {
        click = this.needInfo(history)
      } else {
        this.setState(this.state, () => this.toggle())
      }
    return click
  }

  // @return        Void
  // Description    Create a element.
  onSubmit = event => {
    let { intl } = this.props
    event.preventDefault()
    const r = specialValidations(this.state.fields, InitiativeValidations)
    if (!r.isValid) {
      this.setState({ fields: { ...r.stateValidated } })
    } else {
      this.props.spinnerToggle()
      const { description } = this.state.fields
      const payload = {
        description: description.value,
        user: this.props.auth.user
      }
      const route = '/api/initiative'
      axios
        .post(route, payload)
        .then(res => {
          this.props.spinnerToggle()
          swal(intl.formatMessage({ id: 'alert.initiTh' }), intl.formatMessage({ id: 'alert.contactText' }), "success");
          this.setState(initialState)
        })
        .catch(err => {
          this.props.spinnerToggle()
          swal(intl.formatMessage({ id: 'alert.errorGeneralTitle' }), intl.formatMessage({ id: 'alert.errorGeneralText' }), "error");
        })

    }
  }

  // @return      Void
  // Description  Toggle Modal.
  toggle = () => this.setState({ modal: !this.state.modal })
  onClickCancel = () => this.setState(state => ({ ...initialState }))


  render() {
    return (
      <Fragment>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            <FormattedMessage id="btn.share" />
          </ModalHeader>
          <ModalBody>
            <InitiativeForm {...this} {...this.state} />
          </ModalBody>
          <ModalFooter>
            <center>
              <Button
                color=""
                className={styles['buttonSend']}
                onClick={this.onSubmit}
              >
                <FormattedMessage id="btn.send" />
              </Button>
            </center>
          </ModalFooter>
        </Modal>
        <div>
          <center>
            <button
              className={styles['buttonInitiative']}
              onClick={(this.onClickInitiative)}
            >
              <i className="fas fa-lightbulb mr-3" />
              <FormattedMessage id="initiative.button" />
            </button>
          </center>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth, errors }) => ({ auth, errors })
const mapDispatchToProps = { setNotify, spinnerToggle }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ModalInitiative)))