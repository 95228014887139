import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import swal from '@sweetalert/with-react'
// import { setNotify } from './../../../../redux/actions/notifyAction'
// import { spinnerToggle } from './../../../../redux/actions/spinnerActions'
import { injectIntl, FormattedMessage } from 'react-intl'
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { isEmpty } from './../../../util/isEmpty'

export class SubscribeModal extends Component {

  state = {
    name: '',
    phone: '',
    email: '',
    content: '',
    sede: '',
    subsede: ''
  }

  // Handle para agrega los valores del state
  // para inputs y checkbox
  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  componentWillReceiveProps(np) {
    if (!isEmpty(np.location.sede)) {
      this.setState({ sede: np.location.sede._id, subsede: np.location.subsede._id })
    }
  }

  handleValidSubmit = (event, values) => {
    const { id } = this.props.match.params
    const { intl } = this.props
    axios
      .put(`/api/convocatory/subscribe/${id}`, values)
      .then(res => {
        this.props.toggle()
        swal(intl.formatMessage({ id: 'alert.convocatoryTh' }), intl.formatMessage({ id: 'alert.contactText' }), 'success')
      })
      .catch(err => {
        this.props.toggle()
        swal(intl.formatMessage({ id: 'alert.errorGeneralTitle' }), intl.formatMessage({ id: 'alert.errorGeneralText' }), 'error')
        console.log(err)
      })
  }


  render() {
    return (
      <Modal size="md" isOpen={this.props.state.modal} toggle={this.props.toggle} style={{ padding: 25 }}>
        <ModalHeader toggle={this.props.toggle}><FormattedMessage id='btn.register' /> - {this.props.item.title}</ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={event => this.handleValidSubmit(event, this.state)}>
            <AvField onChange={this.handleInputChange} name="name" label="Nombre" type="text" errorMessage="Campo no válido" validate={{
              required: { value: true },
              minLength: { value: 6 }
            }} />
            <AvField onChange={this.handleInputChange} name="phone" label="Telefono:" type="number" errorMessage="Número no válido" validate={{ required: { value: true } }} />
            <AvField onChange={this.handleInputChange} name="email" label="Email:" type="text" errorMessage="Email no válido" validate={{ required: { value: true }, email: true }} />
            <AvField onChange={this.handleInputChange} name="content" label="Motivo:" type="textarea" placeholder="¿Por qué deseas unirse a esta convocatoria?" />
            <hr />
            <Button color="success " type="submit" className="btn-block">
              <FormattedMessage id='btn.register' />
            </Button>
          </AvForm>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({ auth, location }) => ({ auth, location })
// const mapDispatchToProps = { setNotify, spinnerToggle }
export default connect(mapStateToProps/*, mapDispatchToProps*/)(injectIntl(SubscribeModal))