import React from 'react'
import { Button } from 'reactstrap'

export const ButtonView = ({item, children, handleView}) => (
  <Button
    className='btn btn-block'
    id="viewCampaign"
    color='primary'
    onClick={() => handleView(item._id)}
  >
    <i className="fas fa-eye"></i> {children}
  </Button>
)

export const ButtonEdit = ({item, children, handleEdit}) => (
  <Button
    className='btn btn-block'
    id="editCampaign"
    onClick={() => handleEdit(item._id)}
    color='info'
  >
    <i className="fas fa-edit"></i> {children}
  </Button>
)

export const ButtonDelete = ({item, children, handleDelete}) => (
  <Button
    className='btn btn-block'
    color={item.active ? 'danger' : 'success'}
    onClick={() => handleDelete(item)}
  >
    <i className="fas fa-power-off"></i> {children}
  </Button>
)

export const ButtonSubscribers = ({item, handleSubscribers}) => {
  const subscribers = item.subscribers.filter(s => s.active).length
  return (
    <Button
      className='btn btn-block'
      color='success'
      onClick={subscribers > 0 && (() => handleSubscribers(item._id))}
    >
      <i className="fas fa-user"></i> {subscribers}
    </Button>
  )

}