import { isEmpty } from './../../../util/isEmpty'
import { ymd, dmy } from './../../../util/dateHelpers'

// El componente BootstrapTable solo acepta un objeto plano como data
export const formatToTable = (data) =>
	data.map((d) => {
		if (!isEmpty(d.user.subsede)) {
			const { name, phone, email, sede, subsede } = d.user
			d = { ...d, name, phone, email, sede: sede.name, subsede: subsede.name, createdAt: ymd(d.createdAt) }
		}
		return d
	})

export const columnsToExport = [
	{ title: 'Nombre', value: (row) => row.user.name },
	{ title: 'Telefono', value: (row) => row.user.phone },
	{ title: 'Email', value: (row) => row.user.email },
	{ title: 'Monto', value: (row) => row.amount },
	{ title: 'Sede', value: (row) => row.user.sede.name },
	{ title: 'Subsede', value: (row) => row.user.subsede.name },
	{ title: 'Activo', value: (row) => (row.active ? 'Sí' : 'No') },
	{ title: 'Fecha de Registro', value: (row) => dmy(row.createdAt) }
]
