import React from 'react'
import { reduxForm } from 'redux-form'
import { Form, Button } from 'reactstrap'
import { withForm } from '../../../util/withForm'
import { required, substring, minimumValueIs, isLessThan } from '../../../util/form'
import { RFInput, RFUploader } from '../../Inputs/RFFields'
import FormContainer from '../../common/Container/FormContainer'

const limit50 = substring(0, 50)
const limit150 = substring(0, 150)
const minDays = minimumValueIs(1, 'El mínimo de días es 1')
const maxDays = isLessThan(365, 'El máximo de días es 365')

function HolidayProjectInformationForm({ button, handleSubmit, options, ...props }) {
  return (
    <FormContainer {...props}>
      <Form>
        <RFInput name='title' label='Título' validate={[required]} normalize={limit50} />
        <RFInput
          name='description'
          type='textarea'
          label='Descripción'
          rows='2'
          validate={[required]}
          normalize={limit150}
        />
        <RFUploader name='thumbnail' label='Imagen' validate={[required]} />
        <RFUploader name='logo' label='Logo' validate={[required]} />
        <RFInput
          name='duration'
          type='number'
          label='Duración en días'
          min='1'
          validate={[required, minDays, maxDays]}
        />
      </Form>
      <Button color='primary' className='float-right' onClick={handleSubmit}>
        Siguiente
      </Button>
    </FormContainer>
  )
}

export default withForm(
  reduxForm({
    form: 'holiday-project',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(HolidayProjectInformationForm)
)
