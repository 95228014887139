import React, { Fragment } from 'react'
// import { Input } from 'availity-reactstrap-validation'
import { Input, Label, Button, Col, Row } from 'reactstrap'
import MultiSelectData from './Inputs/MultiSelectData'
import FormGroupValid from './../../../Campaign/lib/FormGroupValid'
import FormGroupCustom from './../../../Campaign/lib/FormGroupCustom'
import { isEmpty } from './../../../../util/isEmpty'
import UploadFiles from './../../../common/Upload/UploadFiles'
import { FormattedMessage } from 'react-intl'
import Im1 from '../../../../assets/img/Icons/verified-01.svg'
// import MultiSelectData from './Inputs/MultiSelectData';
// import { FormattedMessage } from 'react-intl'

//   // Cambiar { name: '', _id: '' } a { label: '', value: '' }
// const multiSelectFormater = selected => selected.map(s => ({label: s.name, value: s._id}))


const UserForm = ({ handleInputChange, handleChangeSelectData, handleChangeMultiSelect, subsedes, phone, options, is, subsede, causes, activities, skills, avatar }) => {
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <img className="img-fluid rounded-circle mb-3 logoSof" src={Im1} alt="" />
          <Label className="labelSof"> <FormattedMessage id="label.is" /> :</Label>
          <FormGroupValid forp="is" {...is}>
            <Input
              type="text"
              id="is"
              name="is"
              valid={is.valid}
              value={is.value}
              invalid={is.valid === false}
              onChange={handleInputChange}
            />
          </FormGroupValid>
        </Col>
        <Col md={6}>
          <img className="img-fluid rounded-circle mb-3 logoSof" src={Im1} alt="" />
          <Label className="labelSof"><FormattedMessage id="modal.subsede" /> :</Label>
          <FormGroupValid forp="subsede" {...subsede}>
            <Input
              type="select"
              name="subsede"
              valid={subsede.valid}
              invalid={subsede.valid === false}
              onChange={handleInputChange}
              value={subsede.value}>
              {isEmpty(subsede.value) && <option></option>}
              {subsedes.map((s, i) => (<option key={`subsede-${i}`} value={s._id}>{s.name}</option>))}
            </Input>
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col>
          <img className="img-fluid rounded-circle mb-3 logoSofG" src={Im1} alt="" />
          <Label className="labelSof"><FormattedMessage id="label.phone" /> :</Label>
          <FormGroupValid forp="phone" {...phone}>
            <Input
              type="number"
              name="phone"
              valid={phone.valid}
              invalid={phone.valid === false}
              onChange={handleInputChange}
              value={phone.value}
            />
          </FormGroupValid>
        </Col>
      </Row>

      {/* Catalogs Selects */}
      <Row form>
        <Col>
          <img className="img-fluid rounded-circle mb-3 logoSofG" src={Im1} alt="" />
          <Label className="labelSof"><FormattedMessage id="softtekian.causes" /> :</Label>
          <FormGroupCustom forp="causes"  {...causes}>
            <MultiSelectData
              name='causes'
              route='api/cause'
              valid={causes.valid}
              onChange={handleChangeMultiSelect}
              selectedOptions={options.causes}
            />
          </FormGroupCustom>
        </Col>
      </Row>

      {/* Activities and skills  */}
      <Row form>
        <Col>
          <img className="img-fluid rounded-circle mb-3 logoSofG" src={Im1} alt="" />
          <Label className="labelSof"><FormattedMessage id="softtekian.typeAct" />:</Label>
          <FormGroupCustom forp="activities"  {...activities}>
            <MultiSelectData
              route='api/activitie'
              name='activities'
              valid={activities.valid}
              onChange={handleChangeMultiSelect}
              selectedOptions={options.activities}
            />
          </FormGroupCustom>
        </Col>
      </Row>

      <Row form>
        <Col>
          <img className="img-fluid rounded-circle mb-3 logoSofG" src={Im1} alt="" />
          <Label className="labelSof"><FormattedMessage id="softtekian.skills" /> :</Label>
          <FormGroupCustom forp="skills" {...skills}>
            <MultiSelectData
              route='api/skill'
              name='skills'
              valid={skills.valid}
              onChange={handleChangeMultiSelect}
              selectedOptions={options.skills}
            />
          </FormGroupCustom>
        </Col>
      </Row>

      <Row>
        <Col sm='12'>
          <img className="img-fluid rounded-circle mb-3 logoSofG" src={Im1} alt="" />
          <Label className="labelSof"><FormattedMessage id="softtekian.photo" /> :</Label>
          <FormGroupCustom forp='avatar' {...avatar}>
            <UploadFiles
              url={'/api/file/user'}
              file={file => handleChangeSelectData({ name: 'avatar', value: file._id })} />
          </FormGroupCustom>
        </Col>
      </Row>

      <div className='buttonProfile__wrapper'>
        <Button className="buttonProfile" color="" type="submit"><FormattedMessage id="softtekian.updateProfile" /></Button>
      </div>
    </Fragment>
  )
}

export default UserForm