import React from 'react'
import { Row } from 'reactstrap'
import MiniCard from './../MiniCard'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { formatCurrency } from '../../../../../util/formatCurrency'

function RowCards({ intl, currency, data, status }) {
  return (
    <Row className='my-4'>
      <MiniCard
        className='col-xl-3 col-md-6 mb-4'
        color='info'
        label={intl.formatMessage({ id: 'label.status' })}
        value={status}
        icon='fa fa-calendar'
      />
      <MiniCard
        className='col-xl-3 col-md-6 mb-4'
        color='success'
        label={'Total'}
        value={`${formatCurrency(data.total.local)} ${currency}`}
        sublabel={`${formatCurrency(data.total.usd)} USD`}
        icon='fas fa-dollar-sign'
      />
      <MiniCard
        className='col-xl-3 col-md-6 mb-4'
        color='primary'
        label={intl.formatMessage({ id: 'report.cardPaypal' })}
        value={`${formatCurrency(data.paypal.local)} ${currency}`}
        sublabel={`${formatCurrency(data.paypal.usd)} USD`}
        icon='fas fa-dollar-sign'
      />
      <MiniCard
        className='col-xl-3 col-md-6 mb-4'
        color='warning'
        label={intl.formatMessage({ id: 'report.cardOthers' })}
        value={`${formatCurrency(data.others.local)} ${currency}`}
        sublabel={`${formatCurrency(data.others.usd)} USD`}
        icon='fas fa-dollar-sign'
      />
    </Row>
  )
}

RowCards.defaultProps = {
  currency: 'USD'
}

RowCards.propTypes = {
  currency: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default injectIntl(RowCards)
