import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import Admin from './../Layout/Admin/Admin'
import PropTypes from 'prop-types'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
// Hepers
import { formatToTable } from './helpers'
import TableWrapper from './../common/TableWrapper'
import { today } from './../../util/dateHelpers'
import { injectIntl } from 'react-intl'
import { detailRow } from '../../util/report'
import CSVButton from './../common/CSVButton'

// Global variables
const { SearchBar } = Search

class RolReport extends Component {
  // Filter select options
  rolOptions = {
    corporative: 'Corporativo',
    maintainer: 'Maintainer',
    reporter: 'Reporter'
  }

  // Filter select options
  activeOptions = {
    true: 'Sí',
    false: 'No'
  }

  // Columns configurations
  columns = [
    {
      dataField: 'email',
      text: 'Correo',
      sort: true,
      filter: textFilter({ delay: 600 })
    },
    {
      dataField: 'rol',
      text: 'Rol',
      sort: true,
      formatter: cell => this.rolOptions[cell],
      filter: selectFilter({
        delay: 600,
        options: this.rolOptions
      })
    },
    {
      dataField: 'is',
      text: 'IS',
      filter: textFilter({ delay: 600 }),
      formatter: cell =>
        cell ? cell : this.props.intl.formatMessage({ id: 'general.pendingField' }),
      sort: true
    },
    {
      dataField: 'phone',
      text: 'Teléfono',
      filter: textFilter({ delay: 600 }),
      sort: true,
      formatter: cell =>
        cell ? cell : this.props.intl.formatMessage({ id: 'general.pendingField' })
    },
    {
      dataField: 'sede',
      text: 'Sede',
      sort: true,
      filter: textFilter({ delay: 600 })
    },
    {
      dataField: 'subsede',
      text: 'Subsede',
      sort: true,
      filter: textFilter({ delay: 600 })
    },
    {
      dataField: 'active',
      text: 'Activo',
      sort: true,
      formatter: cell => this.activeOptions[cell],
      filter: selectFilter({
        delay: 600,
        options: this.activeOptions
      }),
      csvFormatter: cell => (cell ? 'Sí' : 'No')
    }
  ]

  // Application state
  state = {
    administrators: []
  }

  // LIFECYCLES METHODS

  componentDidMount() {
    // HTTP GET api/donation
    axios
      .get('/api/rol')
      // Si la petición fue exitosa se cargan los registros de administradores al estado
      .then(res => this.setState({ administrators: formatToTable(res.data) }))
      // Mensaje de eror cuando no se puedieron obtener los registros de administradores
      .catch(error => swal('Error', '', 'warning'))
  }

  // RENDER METHOD
  render() {
    const {
      state: { administrators },
      columns
    } = this
    return (
      <Admin>
        <ToolkitProvider
          keyField='_id'
          data={administrators}
          columns={columns}
          bootstrap4={true}
          search={{
            searchFormatted: true
          }}
          exportCSV={{
            fileName: `administratorsFS-${today()}.csv`,
            separator: ',',
            noAutoBOM: false,
            onlyExportFiltered: true,
            exportAll: false
          }}>
          {props => (
            <TableWrapper title='Reporte de administradores' label='Administradores'>
              <SearchBar
                {...props.searchProps}
                placeholder={this.props.intl.formatMessage({ id: 'label.searchBar' })}
              />
              <BootstrapTable
                {...props.baseProps}
                filter={filterFactory()}
                pagination={paginationFactory()}
                expandRow={detailRow}
                bordered
                hover
              />
              <CSVButton {...props.csvProps} />
            </TableWrapper>
          )}
        </ToolkitProvider>
      </Admin>
    )
  }
}

RolReport.propTypes = {
  auth: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth }) => ({ auth })
export default connect(mapStateToProps)(injectIntl(RolReport))
