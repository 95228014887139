import PaypalList from './PaypalList'
import PaypalView from './PaypalView'

export default [
  {
    path: '/dashboard/paypal',
    component: PaypalList,
    roles: ['corporative', 'maintainer'],
    private: true
  },
  {
    path: '/dashboard/paypal/:id',
    component: PaypalView,
    roles: ['corporative', 'maintainer'],
    private: true
  }
]
