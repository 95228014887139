import axios from 'axios'
import Select from 'react-select'
import React, { Component, Fragment } from 'react'
import { isEmpty } from '../../../util/isEmpty'
export class MultiSelectData extends Component {
  state = { options: [] }
  map = list => list.filter(r => r.active).map(r => ({ label: r.name, value: r._id }))
  componentDidMount() {
    const route = `/${this.props.route}`
    axios
      .get(route)
      .then(res => this.setState({ options: this.map(res.data) }))
      .then(() => console.log(this.state))
      .catch(err => console.log(err))
  }
  // @return       Object.
  // @description  handel the <MultiSelect /> Compoenet.
  // and pass up name and the values .
  onChange = list => this.props.onChange({ name: this.props.name, value: list.map(s => s) })
  render() {
    const { options, name } = this.state
    const { valid } = this.props
    const customStyles = {
      control: (base, state) => ({
        ...base,
        // state.isFocused can display different borderColor if you need it
        borderColor: state.isFocused ?
          '#ddd' : valid ?
            '#ddd' : 'red',
        // overwrittes hover style
        '&:hover': {
          borderColor: state.isFocused ?
            '#ddd' : valid ?
              '#ddd' : 'red'
        }
      })
    }
    return (
      <Fragment>
        <Select
          isMulti
          styles={
            !isEmpty(valid) &&
            valid === false &&
            customStyles}
          options={options}
          name={name}
          value={this.props.selectedValues}
          onChange={this.onChange} />
      </Fragment>
    )
  }
}
export default MultiSelectData