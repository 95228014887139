import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { postConvocatory } from './../../redux/actions/convocatoryActions'
// Layout 
import Admin from '../Layout/Admin/Admin'
// Forms
import ThumbnailForm from './forms/ThumbnailForm'
import ContentForm from './forms/ContentForm'
// Helpers
import { Form, FormGroup, Button } from 'reactstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { isEmpty } from '../../util/isEmpty';
import { validateOnSubmit, plainState } from './../Campaign/lib/helpers'
import validations from './validations'
import initialState from './Helpers/initialState'

export class ConvocatoryCreate extends Component {

  state = { ...initialState, published: false }

  componentDidMount() {
    // Set the user's subsede in the convocatory
    if (!isEmpty(this.props.auth.user)) {
      this.setState(s => ({ ...s, subsede: { ...s.subsede, value: this.props.auth.user.subsede } }))
    }
  }

  // Handle para agregar los valores a el
  // state de la propiedad de un objeto
  // recibe el nombre y un objeto de propiedades
  handleChangeSelectData = props => {
    this.setState(s => ({ ...s, [props.name]: { ...s[props.name], value: props.value } }))
  }

  componentWillReceiveProps(np) {
    // Set the user's subsede in the convocatory
    if (!isEmpty(np.auth.user)) {
      this.setState(s => ({ ...s, subsede: { ...s.subsede, value: np.auth.user.subsede } }))
    }
  }

  // Handle agrega los valores a el
  // state de la propiedad del editor 
  onChangeEditor = ({ editor }) => {
    const value = editor.getData()
    this.setState((state, prop) => ({ content: { ...state.content, value } }))
  }
  // Handle para agrega los valores del state
  // para inputs y checkbox
  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState(s => ({ ...s, [name]: { ...s[name], value } }))
  }

  // Hadle si las validaciones pasaron
  onSubmit = event => {
    event.preventDefault()
    let { state: { title, subsede, startDate, endDate, description, thumbnail, content } } = this
    const stateToValidate = { title, subsede, startDate, endDate, description, thumbnail, content }
    const validated = validateOnSubmit(stateToValidate, validations)
    if (validated.pass) {
      const s = { ...validated.state }
      const payload = plainState(s)
      const { intl } = this.props
      this.props.postConvocatory(payload, this.props.history, intl)
      this.setState({ published: true })
    } else {
      window.scrollTo(0, 0)
      const faildState = validated.state
      this.setState({ ...faildState })
    }
  }

  // Handle agrega los valores a el
  // state de la propiedad del editor 
  onChangeEditor = ({ editor }) => {
    const value = editor.getData()
    this.setState((state, prop) => ({ content: { ...state.content, value } }))
  }

  // @return      Void
  // Description  Toggle Modal.
  toggle = () => this.setState({ modal: !this.state.modal })

  render() {
    const { published } = this.state
    return (
      <Admin>
        <div className="row">
          <div className="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10">
            <h1 className="h3 mb-2 text-gray-800">
              <FormattedMessage id="convocatory.public" />
            </h1>
          </div>
        </div>
        <Form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-xl-10 col-lg-10 offset-lg-1">
              <ThumbnailForm {...this} {...this.state} />
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <ContentForm {...this} {...this.state} />
            </div>
          </div>

          <FormGroup>
            <Button type="submit" disabled={published}>
              <FormattedMessage id="btn.save" />
            </Button>
          </FormGroup>
        </Form>
      </Admin>
    )
  }
}

ConvocatoryCreate.propTypes = {
  auth: PropTypes.object.isRequired,
  convocatory: PropTypes.object.isRequired,
  subsedes: PropTypes.object.isRequired,
  postConvocatory: PropTypes.func.isRequired,
}
const mapStateToProps = ({ auth, convocatory, location }) => ({ auth, convocatory, location })
const mapDispatchToProps = { postConvocatory }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConvocatoryCreate))