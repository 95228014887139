import React, { Fragment } from 'react'
import { Input, Col, Row } from 'reactstrap'
import FormGroupValid from '../lib/FormGroupValid'
import { FormattedMessage } from 'react-intl';

const ItemsForm = ({ inkindDonations_startDate, inkindDonations_endDate, inkindDonations_showCountDown, inkindDonations_text, handleInputChange, onChange }) => {
  return (
    <Fragment>

      <Row>
        <Col md={6}>
          <FormGroupValid forp="inkindDonations_startDate" label={<FormattedMessage id="label.inkindDonations_startDate" />} {...inkindDonations_startDate}>
            <Input
              type="date"
              id="inkindDonations_startDate"
              name="inkindDonations_startDate"
              value={inkindDonations_startDate.value}
              valid={inkindDonations_startDate.valid}
              invalid={inkindDonations_startDate.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>

        <Col md={6}>
          <FormGroupValid forp="inkindDonations_endDate" label={<FormattedMessage id="label.inkindDonations_endDate" />} {...inkindDonations_endDate}>
            <Input
              type="date"
              id="inkindDonations_endDate"
              name="inkindDonations_endDate"
              value={inkindDonations_endDate.value}
              valid={inkindDonations_endDate.valid}
              invalid={inkindDonations_endDate.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupValid forp="inkindDonations_text" label={<FormattedMessage id="label.inkindDonations_text" />} {...inkindDonations_text}>
            <Input
              type="textarea"
              id="inkindDonations_text"
              name="inkindDonations_text"
              value={inkindDonations_text.value}
              valid={inkindDonations_text.valid}
              invalid={inkindDonations_text.valid === false}
              onChange={handleInputChange} />
          </FormGroupValid>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="form-group col">
            <div className="form-check">
              <input
                type="checkbox"
                id="inkindDonations_showCountDown"
                name="inkindDonations_showCountDown"
                checked={inkindDonations_showCountDown}
                onChange={onChange}
                className="form-check-input" />
              <label className="form-check-label">
                <FormattedMessage id="label.showCountDown" />
              </label>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  )

}

export default ItemsForm
