export const GET_ERRORS = 'GET_ERRORS'
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
export const SET_LANGUAGES = 'SET_LANGUAGES'

export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILES = 'GET_PROFILES'
export const PROFILE_LOADING = 'PROFILE_LOADING'
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND'
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE'
export const GET_LENGUAGES = 'GET_LENGUAGES'

// location
export const SET_LOCATION = 'SET_LOCATION'
export const SET_LANGUAGE_MANUAL = 'SET_LANGUAGE_MANUAL'

// Campaing
export const GET_CAMPAING = 'GET_CAMPAING'
export const GET_CLEAN_CAMPAING = 'GET_CLEAN_CAMPAING'
export const POST_CAMPAING = 'POST_CAMPAING'
export const UPDATE_CAMPAING = 'UPDATE_CAMPAING'
export const DELETE_CAMPAING = 'DELETE_CAMPAING'
export const EDIT_CAMPAING = 'EDIT_CAMPAING'
export const EDIT_CLEAN_CAMPAING = 'EDIT_CLEAN_CAMPAING'
export const GET_All_CAMPAING = 'GET_All_CAMPAING'
export const GET_CLOSE_CAMPAING = 'GET_CLOSE_CAMPAING'
export const UPDATE_CLOSE_CAMPAING = 'UPDATE_CLOSE_CAMPAING'
export const GET_All_CLEAN_CAMPAING = 'GET_All_CLEAN_CAMPAING'
export const GET_VOLUNTEERS_CAMPAING = 'GET_VOLUNTEERS_CAMPAING'
export const GET_REPORT_CAMPAING = 'GET_REPORT_CAMPAING'
export const GET_GALLERIE_CAMPAING = 'GET_GALLERIE_CAMPAING'
export const ADD_GALLERIE_CAMPAING = 'ADD_GALLERIE_CAMPAING'
export const REMOVE_GALLERIE_CAMPAING = 'REMOVE_GALLERIE_CAMPAING'

// News
export const GET_NEWS = 'GET_NEWS'
export const POST_NEWS = 'POST_NEWS'
export const UPDATE_NEWS = 'UPDATE_NEWS'
export const DELETE_NEWS = 'DELETE_NEWS'
export const GET_All_NEWS = 'GET_All_NEWS'
export const EDIT_NEWS = 'EDIT_NEWS'
export const GET_CLEAN_NEWS = 'GET_CLEAN_NEWS'
export const EDIT_CLEAN_NEWS = 'EDIT_CLEAN_NEWS'
export const GET_All_CLEAN_NEWS = 'GET_ALL_CLEAN_NEWS'

// Convocatory
export const GET_CONVOCATORY = 'GET_CONVOCATORY'
export const POST_CONVOCATORY = 'POST_CONVOCATORY'
export const UPDATE_CONVOCATORY = 'UPDATE_CONVOCATORY'
export const DELETE_CONVOCATORY = 'DELETE_CONVOCATORY'
export const EDIT_CONVOCATORY = 'EDIT_CONVOCATORY'
export const GET_All_CONVOCATORY = 'GET_All_CONVOCATORY'
export const GET_CLEAN_CONVOCATORY = 'GET_CLEAN_CONVOCATORY'
export const EDIT_CLEAN_CONVOCATORY = 'EDIT_CLEAN_CONVOCATORY'
export const GET_All_CLEAN_CONVOCATORY = 'GET_ALL_CLEAN_CONVOCATORY'
export const GET_SUBSCRIBERS_CONVOCATORY = 'GET_SUBCRIBERS_CONVOCATORY'
export const GET_CLEAN_SUBSCRIBERS_CONVOCATORY = 'GET_CLEAN_SUBSCRIBERS_CONVOCATORY'

// Iniative
export const UPDATE_INITIATIVE = 'UPDATE_INITIATIVE'
export const GET_All_INITIATIVE = 'GET_All_INITIATIVE'

// Spinner
export const SET_LOADING = 'SET_LOADING'

// USER
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'

// SubSedes
export const SET_SUBSEDES = 'SET_SUBSEDES'

// Loading
export const SET_INIT_LOADING = 'SET_INIT_LOADING'
export const SET_LOADING_PAGE = 'SET_LOADING_PAGE'
export const SET_LOADING_PAGE_OFF = 'SET_LOADING_PAGE_OFF'

// Donations
export const GET_DONATION = 'GET_DONATION'
export const POST_DONATION = 'POST_DONATION'
export const POST_ADMIN_DONATION = 'POST_ADMIN_DONATION'
export const UPDATE_DONATION = 'UPDATE_DONATION'
export const APPROVE_DONATION = 'APPROVE_DONATION'
export const DELETE_DONATION = 'DELETE_DONATION'
export const EDIT_DONATION = 'EDIT_DONATION'
export const GET_All_DONATION = 'GET_All_DONATION'
export const GET_All_PAYPAL = 'GET_All_PAYPAL'
export const GET_CLEAN_DONATION = 'GET_CLEAN_DONATION'
export const EDIT_CLEAN_DONATION = 'EDIT_CLEAN_DONATION'
export const GET_All_CLEAN_DONATION = 'GET_ALL_CLEAN_DONATION'
export const GET_All_DONATION_PROJECTS = 'GET_All_DONATION_PROJECTS'

// Contact
export const GET_CONTACT = 'GET_CONTACT'
export const POST_CONTACT = 'POST_CONTACT'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const GET_All_CONTACT = 'GET_ALL_CONTACT'
export const GET_All_CLEAN_CONTACT = 'GET_All_CLEAN_CONTACT'

// Solidary friend
export const POST_SF = 'POST_SF'
export const UPDATE_SF = 'UPDATE_SF'
export const GET_All_SF = 'GET_All_SF'
export const GET_All_CLEAN_SF = 'GET_All_CLEAN_SF'

// Solidary friend
export const GET_VIDEO = 'GET_VIDEO'
export const POST_VIDEO = 'POST_VIDEO'

// Socket's
export const SOCKET_AUTH = 'SOCKET_AUTH'
export const SOCKET_CONNECTION = 'SOCKET_CONNECTION'
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT'
export const SOCKET_DASHBOARD = 'SOCKET_DASHBOARD'

// Main page counter's
export const COUNTER_MONEY = 'COUNTER_MONEY'
export const COUNTER_VISITS = 'COUNTER_VISITS'
export const COUNTER_PROYECTS = 'COUNTER_PROYECTS'

// Carrousel
export const GET_ALL_CARROUSEL_USER = 'GET_ALL_CARROUSEL_USER'
export const GET_ALL_CARROUSEL_ADMIN = 'GET_ALL_CARROUSEL_ADMIN'
export const POST_CARROUSEL = 'POST_CARROUSEL'
export const GET_OPTIONS_CARROUSEL = 'GET_OPTIONS_CARROUSEL'
export const ACTIVATE_CARROUSEL = 'ACTIVATE_CARROUSEL'
export const DEACTIVATE_CARROUSEL = 'DEACTIVATE_CARROUSEL'

// Users/Rols
export const GET_ROL = 'GET_ROL'
export const ADD_ROL = 'ADD_ROL'
export const UPDATE_ROL = 'UPDATE_ROL'
export const ACTIVATE_ROL = 'ACTIVATE_ROL'
export const DEACTIVATE_ROL = 'DEACTIVATE_ROL'
export const EDIT_ROL = 'EDIT_ROL'
export const GET_All_ROL = 'GET_All_ROL'
export const GET_CLEAN_ROL = 'GET_CLEAN_ROL'
export const EDIT_CLEAN_ROL = 'EDIT_CLEAN_ROL'
export const GET_All_CLEAN_ROL = 'GET_ALL_CLEAN_ROL'
export const GET_All_ROL_SUBSEDES = 'GET_All_ROL_SUBSEDES'
