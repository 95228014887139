import React from 'react'
import { isEmpty } from '../../../util/isEmpty'
import { FormattedMessage } from 'react-intl'

const DonationsValidations = [
  {
    field: 'amount',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, ''] }
      } else if (value <= 0) {
        check = {
          ...check,
          feed: [...check.feed, <FormattedMessage id='label.donationMin0' />]
        }
      }
      // else if (value > 120) {
      //   check = { ...check, feed: [...check.feed, <FormattedMessage id='label.donationMax120' />] }
      // }
      else {
        check = {
          valid: true,
          invalid: false,
          text: '',
          feed: [<FormattedMessage id='help.right' />]
        }
      }
      return check
    }
  },
  {
    field: 'currency',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, ''] }
      } else {
        check = {
          valid: true,
          invalid: false,
          text: '',
          feed: [<FormattedMessage id='help.right' />]
        }
      }
      return check
    }
  },
  {
    field: 'type',
    function(value) {
      let check = { valid: false, invalid: true, feed: [] }
      if (isEmpty(value)) {
        check = { ...check, feed: [...check.feed, ''] }
      } else {
        check = {
          valid: true,
          invalid: false,
          text: '',
          feed: [<FormattedMessage id='help.right' />]
        }
      }
      return check
    }
  },
  {
    field: 'project',
    function(value) {
      let check = { valid: true, invalid: false, feed: [] }
      return check
    }
  },
  {
    field: 'description',
    function(value) {
      let check = { valid: true, invalid: false, feed: [] }
      return check
    }
  },
  {
    field: 'paypal',
    function(value) {
      let check = { valid: true, invalid: false, feed: [] }
      return check
    }
  },
  {
    field: 'approved',
    function(value) {
      let check = { valid: true, invalid: false, feed: [] }
      return check
    }
  },
  {
    field: 'needReceipt',
    function(value) {
      let check = { valid: true, invalid: false, feed: [] }
      return check
    }
  }
]
export default DonationsValidations
