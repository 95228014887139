import Login from '../components/Auth/Login'
import Home from '../components/Main/Pages/Home'
import Contact from '../components/Main/Pages/Contact/Contact'
import OurProjects from '../components/Main/Pages/OurProjects'
import DaretoShare from '../components/Main/Pages/DareToShare'
import Initiative from '../components/Main/Pages/Initiative'
import OurValues from '../components/Main/Pages/OurValues'
import OurPrograms from '../components/Main/Pages/OurPrograms'
import CatalogContact from './../components/Dashboard/Catalogs/Contact/ContactListPagination'
import InitiativeList from './../components/Initiative/InitiativeList'

import Skills from '../components/Dashboard/Catalogs/Skills/Skills'
import Sedes from './../components/Dashboard/Catalogs/Sedes/Sedes'
import Subsede from './../components/Dashboard/Catalogs/Subsede/Subsede'
import Activities from './../components/Dashboard/Catalogs/Activities/Activities'
import Institution from './../components/Dashboard/Catalogs/Institution/Institution'
import Causes from './../components/Dashboard/Catalogs/Causes/Causes'
import Video from './../components/Video/Video'

import User from './../components/Layout/User/User'
import Categories from './../components/Dashboard/Catalogs/Categories/Categories'
import FriendList from './../components/Friend/FriendList'

import CampaignItem from './../components/Campaign/Campaing'
import CampaingListPagination from './../components/Campaign/CampaingListPagination'
import CampaingCreate from './../components/Campaign/CampaingCreate'
import CampaingGallerie from './../components/Campaign/CampaingGallerie'
import CampaingEdit from './../components/Campaign/CampaingEdit'

import NewsItem from './../components/News/News'
import NewsListPagination from './../components/News/NewsListPagination'
import NewsCreate from './../components/News/NewsCreate'
import NewsGallerie from './../components/News/NewsGallerie'
import NewsEdit from './../components/News/NewsEdit'

import ConvocatoryItem from './../components/Convocatory/Convocatory'
import ConvocatoryListPagination from './../components/Convocatory/ConvocatoryListPagination'
import ConvocatoryCreate from './../components/Convocatory/ConvocatoryCreate'
import ConvocatoryGallerie from './../components/Convocatory/ConvocatoryGallerie'
import ConvocatoryEdit from './../components/Convocatory/ConvocatoryEdit'
import ConvocatorySubscribers from '../components/Convocatory/ConvocatorySubscribers'

// import DonationsReport from './../components/Donations/DonationsReport'
import DonationsListPagination from './../components/Donations/DonationsListPagination'
import PaypalRoutes from './../components/PayPal/paypal.routes'
import CampaingReport from './../components/Campaign/CampaingReport'
import CampaingIndividualReport from './../components/Campaign/CampaingIndividualReport'
import CampaingVolunteers from '../components/Campaign/CampaingVolunteers'
import CampaingImagesGallerie from '../components/Campaign/CampaingImagesGallerie'

import RolReport from './../components/Rol/RolReport'
import RolsListPagination from './../components/Rols/RolsListPagination'

import Dashboard from './../components/Dashboard/Dashboard/Dashboard'

import CarrouselCreate from './../components/Carrousel/CarrouselCreate'
import CarrouselList from './../components/Carrousel/CarrouselList'

import NoticePrivacy from '../components/Main/Pages/NoticePrivacy'
import PrivacyTerms from '../components/Main/Pages/PrivacyTerms'

import customerProjectRoutes from './../components/CustomerProject/customer-project.routes'
import holidayProjectRoutes from './../components/HolidayProject/holiday-project.routes'

import mailingRoutes from './../components/Mailing/mailing.routes'

export default [
  // public
  { path: '/', component: Home },
  { path: '/login', component: Login },
  { path: '/contact', component: Contact },
  { path: '/ourprojects', component: OurProjects },
  { path: '/daretoshare', component: DaretoShare },
  { path: '/initiative', component: Initiative },
  { path: '/ourvalues', component: OurValues },
  { path: '/ourPrograms', component: OurPrograms },
  { path: '/user', component: User, private: true, roles: ['softtekian'] },
  // dashboard
  {
    path: '/dashboard',
    component: Dashboard,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  // catalogs
  {
    path: '/dashboard/catalog-causes',
    component: Causes,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-contacts',
    component: CatalogContact,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-friends',
    component: FriendList,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-initiative',
    component: InitiativeList,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-categories',
    component: Categories,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-skills',
    component: Skills,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-sedes',
    component: Sedes,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-subsedes',
    component: Subsede,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-activities',
    component: Activities,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-institution',
    component: Institution,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-donation',
    component: DonationsListPagination,
    private: true,
    roles: ['corporative', 'maintainer']
  },
  {
    path: '/dashboard/catalog-rol',
    component: RolsListPagination,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/catalog-video',
    component: Video,
    private: true,
    roles: ['corporative']
  },
  // Campañas
  { path: '/campaign/gallerie', component: CampaingGallerie },
  { path: '/campaign/historical', component: CampaingGallerie },
  { path: '/campaign/gallerie/:id', component: CampaignItem },
  {
    path: '/dashboard/campaign/edit/:id',
    component: CampaingEdit,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/campaign/add',
    component: CampaingCreate,
    private: true,
    roles: ['corporative', 'maintainer']
  },
  {
    path: '/dashboard/campaign/list',
    component: CampaingListPagination,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/campaign/volunteers/:id',
    component: CampaingVolunteers,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/campaign/gallerie/:id',
    component: CampaingImagesGallerie,
    private: true,
    roles: ['corporative', 'maintainer']
  },
  // Noticias
  { path: '/news/gallerie', component: NewsGallerie },
  { path: '/news/gallerie/:id', component: NewsItem },
  {
    path: '/dashboard/news/edit/:id',
    component: NewsEdit,
    private: true,
    roles: ['corporative', 'maintainer']
  },
  {
    path: '/dashboard/news/add',
    component: NewsCreate,
    private: true,
    roles: ['corporative', 'maintainer']
  },
  {
    path: '/dashboard/news/list',
    component: NewsListPagination,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },

  // Convocatorias
  { path: '/convocatory/gallerie', component: ConvocatoryGallerie },
  { path: '/convocatory/gallerie/:id', component: ConvocatoryItem },
  {
    path: '/dashboard/convocatory/edit/:id',
    component: ConvocatoryEdit,
    private: true,
    roles: ['corporative', 'maintainer']
  },
  {
    path: '/dashboard/convocatory/add',
    component: ConvocatoryCreate,
    private: true,
    roles: ['corporative', 'maintainer']
  },
  {
    path: '/dashboard/convocatory/list',
    component: ConvocatoryListPagination,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/convocatory/subscribers/:id',
    component: ConvocatorySubscribers,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  // Reportes
  {
    path: '/dashboard/campaign-report',
    component: CampaingReport,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/campaign-report/:id',
    component: CampaingIndividualReport,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },
  {
    path: '/dashboard/rol-report',
    component: RolReport,
    private: true,
    roles: ['corporative', 'maintainer', 'reporter']
  },

  // Carrousel
  {
    path: '/dashboard/carrousel/add',
    component: CarrouselCreate,
    private: true,
    roles: ['corporative', 'maintainer']
  },
  {
    path: '/dashboard/carrousel/list',
    component: CarrouselList,
    private: true,
    roles: ['corporative', 'maintainer']
  },

  // Terms and conditions
  { path: '/terms-and-conditions', component: NoticePrivacy },
  { path: '/notice-privacy', component: PrivacyTerms },

  ...customerProjectRoutes,
  ...holidayProjectRoutes,
  ...mailingRoutes,
  ...PaypalRoutes
]
