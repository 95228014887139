import React from 'react'
import PropTypes from 'prop-types'
import styles from './counter.module.css'
import CountUp from 'react-countup'
import { injectIntl } from 'react-intl'

function Counter({ className, ...props }) {
  let label = props.label
  if (props.intlId) {
    label = props.intl.formatMessage({ id: props.intlId })
  }
  return (
    props.active && (
      <div className={className}>
        <h1 className={props.classNameText}>
          <CountUp delay={props.delay} end={props.number} {...props} />
          <strong className={props.classNameSymbol}>{props.symbol}</strong>
        </h1>
        <h4 className={styles['subtitle-counter']}> {label} </h4>
      </div>
    )
  )
}

Counter.propTypes = {
  // Customize counter
  label: PropTypes.string,
  number: PropTypes.isRequired,
  active: PropTypes.bool,
  symbol: PropTypes.string,
  delay: PropTypes.number,
  // Formatting counter number or label (intl)
  formattingFn: PropTypes.func,
  intlId: PropTypes.string,
  // Add classes to container and counter
  className: PropTypes.string,
  classNameText: PropTypes.string,
  classNameSymbol: PropTypes.string
}

Counter.defaultProps = {
  active: true,
  number: 0,
  delay: 5000,
  className: styles['counter-container'],
  classNameText: styles['numbers'],
}

export default injectIntl(Counter)
