import React, { Component } from 'react'
import { connect } from 'react-redux'
import { actions } from './customer-project.redux'
import { isEmpty } from './../../util/helpers'
import CustomerProjectForm from './CustomerProjectForm'
import Admin from './../Layout/Admin/Admin'
import { initialize } from 'redux-form'

class CustomerProjectEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params
    this.props.editCustomerProjectRequest({ id })
  }

  componentWillUnmount() {
    this.props.editCustomerProjectSuccess({})
  }

  componentDidUpdate() {
    this.props.initialize('customer-project', this.props.customerProject.edit)
  }

  onCancel = () => {
    this.props.history.push('/dashboard/customer-project/list')
  }

  submit = values => {
    const { id } = this.props.match.params
    this.props.updateCustomerProject({ id, values })
  }

  render() {
    const values = this.props.customerProject.edit

    return (
      <Admin>
        <div className='header-h2 col'>
          <h1 className='h3 mb-2 text-gray-800'>Editar campaña</h1>
        </div>
        {!isEmpty(values) && (
          <CustomerProjectForm
            {...this.props}
            label='Actualizar'
            onCancel={this.onCancel}
            onSubmit={this.submit}
            initialValues={values}
          />
        )}
      </Admin>
    )
  }
}

const mapStateToProps = ({ customerProject }) => ({ customerProject })
const mapDispatchToProps = {
  updateCustomerProject: actions.updateCustomerProjectRequest,
  editCustomerProjectRequest: actions.editCustomerProjectRequest,
  editCustomerProjectSuccess: actions.editCustomerProjectSuccess,
  initialize
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerProjectEdit)
