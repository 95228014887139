import React, { Fragment } from 'react'
import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap'
import { FormattedMessage } from 'react-intl';

const SkillsForm = ({ fields, handleChange }) => {
  return (
    <Fragment>
      <form>
        <FormGroup>
          <Label for="name"><FormattedMessage id="label.name" /> :</Label>
          <Input
            type="text"
            name="fields.name"
            valid={fields.name.valid}
            invalid={fields.name.invalid}
            value={fields.name.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.name.valid}>
            <ul className="list-unstyled">
              {fields.name.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.name.text}</FormText>
        </FormGroup>

        <FormGroup>
          <Label for="description"><FormattedMessage id="label.description" /> :</Label>
          <Input
            type="textarea"
            name="fields.description"
            valid={fields.description.valid}
            invalid={fields.description.invalid}
            value={fields.description.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.description.valid}>
            <ul className="list-unstyled">
              {fields.description.feed.map(f => (<li className="">
                {f}
              </li>))}
            </ul>
          </FormFeedback>
          <FormText>{fields.description.text}</FormText>
        </FormGroup>
      </form>
    </Fragment >
  )
}

export default SkillsForm