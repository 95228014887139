import React, { Fragment } from 'react'
import { Col, Alert } from 'reactstrap'
import { isEmpty } from './../../../util/isEmpty'
import { FormattedMessage } from 'react-intl'

// Column of md-4 to display sede and subsede user's values
export const InformationColumn = ({ size, label, value, ...props }) => (
  <Col xs={size} style={{ wordBreak: 'break-word' }}>
    <strong className='labelSof'>{label}</strong>
    <p className='txtPages'>
      {isEmpty(value) ? (
        <em className=' txtPages'>
          <FormattedMessage id='softtekian.info' />
        </em>
      ) : (
        value
      )}
    </p>
  </Col>
)

export const BadgeList = ({ label, list }) => (
  <Fragment>
    <strong className='skillsSof'>{label}: </strong>
    {!isEmpty(list) ? (
      <Alert color='success'>{list.map(b => b.name).join(', ')}</Alert>
    ) : (
      <Alert className='txtBadge badgeSof' color=''>
        <FormattedMessage id='softtekian.addInfo' /> {label}{' '}
        <FormattedMessage id='softtekian.addInfo2' />
      </Alert>
    )}
  </Fragment>
)

// Breadcrumbs to display the number of skills, activities and causes of the user
export const BreadCrumb = props => (
  <span className='badge breadSof p-2' style={{ width: 120 }}>
    <i className={props.className}>
      {' '}
      {!isEmpty(props.value) ? props.value.length : 0} {props.label}
    </i>
  </span>
)
