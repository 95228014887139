import React, { Fragment } from 'react'
import { ButtonEdit, ButtonView, ButtonDelete, ButtonCloseProject, ButtonGallerie } from './report'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'
import { isEmpty } from './../../../util/isEmpty'
import { dmy } from './../../../util/dateHelpers'
import { FormattedMessage } from 'react-intl'
import { formatCurrency } from '../../../util/formatCurrency'

export const typeOptions = (formatMessage) => ({
	actividad: formatMessage({ id: 'modal.activitie' }),
	eventoespecial: formatMessage({ id: 'label.specialEvent' }),
	probono: formatMessage({ id: 'label.probono' }),
	campaña: formatMessage({ id: 'label.campaign' })
})

export const ButtonSubscribers = ({ item, handleSubscribers }) => {
	const volunteers = item.volunteers.filter((s) => s.active).length
	return (
		<Button
			color="success"
			onClick={volunteers > 0 && (() => handleSubscribers(item))}
		>
			<i className="fas fa-user" /> {volunteers}
		</Button>
	)
}
export const tableOptions = (formatMessage, typeOptions, props) => [
	{
		dataField: 'title',
		text: formatMessage({ id: 'label.title' }),
		sort: true
	},
	{
		dataField: 'type',
		text: formatMessage({ id: 'modal.project' }),
		formatter: (cell) => typeOptions(formatMessage)[cell],
		sort: true
	},
	{
		dataField: 'status',
		text: formatMessage({ id: 'label.status' }),
		sort: true
	},
	{
		dataField: 'fundsRaised',
		text: formatMessage({ id: 'modal.donation' }),
		sort: true,
		formatter: (cell, row) => formatCurrency(cell) + ' USD',
		headerStyle: () => ({ minWidth: '150px' })
	},
	{
		dataField: 'institution',
		text: formatMessage({ id: 'modal.institution' }),
		sort: true
	},
	{
		dataField: 'subsede',
		text: formatMessage({ id: 'modal.subsede' }),
		sort: true
	},
	{
		dataField: 'startDate',
		text: formatMessage({ id: 'label.startDate' }),
		sort: true,
		formatter: dmy,
		csvFormatter: dmy
	},
	{
		dataField: 'eventDate',
		text: formatMessage({ id: 'label.event' }),
		sort: true,
		formatter: dmy,
		csvFormatter: dmy
	},
	{
		dataField: 'endDate',
		text: formatMessage({ id: 'label.endDate' }),
		sort: true,
		formatter: dmy,
		csvFormatter: dmy
	},
	{
		dataField: 'completeDate',
		text: formatMessage({ id: 'label.completeDate' }),
		sort: true,
		formatter: (cell) => (!isEmpty(cell) ? dmy(cell) : formatMessage({ id: 'general.pending' })),
		csvFormatter: dmy
	},
	{
		dataField: 'subscribers',
		text: formatMessage({ id: 'general.volunteer' }),
		csvExport: false,
		formatter: (cellContent, row) => (
			<div>
				<ButtonSubscribers {...props} item={row} />
			</div>
		)
	},
	{
		dataField: '_id',
		text: <FormattedMessage id="general.actions" />,
		// hidden: props.props.auth.user.rol === 'reporter',
		formatter: (cellContent, row) => {
			return props.props.auth.user.rol !== 'reporter' ? (
				<UncontrolledDropdown>
					<DropdownToggle caret>
						<i class="fas fa-list-ul" />
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem>
							<ButtonView {...props} campaing={row}>
								<FormattedMessage id="btn.see" />
							</ButtonView>
						</DropdownItem>

						{!row.closed && (
							<Fragment>
								<DropdownItem>
									<ButtonEdit {...props} campaing={row}>
										<FormattedMessage id="btn.edit" />
									</ButtonEdit>
								</DropdownItem>
								<DropdownItem>
									<ButtonDelete {...props} campaing={row}>
										<FormattedMessage id="table.active" />
									</ButtonDelete>
								</DropdownItem>
								<DropdownItem>
									<ButtonCloseProject {...props} campaing={row}>
										<FormattedMessage id="btn.close" />
									</ButtonCloseProject>
								</DropdownItem>
							</Fragment>
						)}

						<DropdownItem>
							<ButtonGallerie {...props} campaing={row}>
								<FormattedMessage id="dashboard.gallerie" />
							</ButtonGallerie>
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			) : (
				<Fragment>
					<ButtonView {...props} campaing={row}>
						<FormattedMessage id="btn.see" />
					</ButtonView>
				</Fragment>
			)
		}
	}
]

const campaignTypes = {
	actividad: 'Actividad',
	eventoespecial: 'Evento especial',
	probono: 'Probono',
	campaña: 'Campaña'
}

export const columnsToExport = [
	{ title: 'Título', value: (row) => row.title },
	{ title: 'Tipo', value: (row) => campaignTypes[row.type] },
	{ title: 'Estatus', value: 'status' },
	{ title: 'Donación', value: 'fundsRaised' },
	{ title: 'Institutición', value: 'institution' },
	{ title: 'Subsede', value: 'subsede' },
	{ title: 'Fecha de inicio', value: (row) => dmy(row.startDate) },
	{ title: 'Evento', value: (row) => dmy(row.eventDate) },
	{ title: 'Fecha de terminación', value: (row) => dmy(row.endDate) }
]
