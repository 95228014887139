import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { donationActions } from './../Donations/donation.redux'
import OxxoPayStub from './OxxoPayStub'
import oxxopay from './../../assets/svg/oxxopay.svg'

function OxxoPay({ requestOxxoPay }) {
  const [modal, setModal] = useState(false)
  const [reference, setReference] = useState('')
  const [barcode, setBarcode] = useState('')
  const { id: project } = useParams()

  const INITIAL = {
    amount: null,
    number: '',
    name: '',
    expiry: '',
    email: '',
    phone: '',
    cvc: '',
    issuer: '',
    focused: ''
  }

  const [values, setValues] = useState(INITIAL)
  let form = null

  useEffect(() => {
    setReference('')
  }, [values.amount])

  const toggle = () => {
    setModal(!modal)
  }

  const handleInputChange = ({ target }) => {
    setValues({ ...values, [target.name]: target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    requestOxxoPay({ ...values, project }, order => {
      const newReference = order.charges.data[0].payment_method.reference
      const barcode = order.charges.data[0].payment_method.barcode_url
      setReference(newReference)
      setBarcode(barcode)
    })
  }

  return (
    <div>
      <button
        disabled
        block
        onClick={toggle}
        className='buttonProjectPage credit-card-btn mt-2 p-2'>
        <img width='50' className='mr-2' src={oxxopay} alt='conekta' />
        <span>OXXO Pay</span>
      </button>
      <Modal size='md' isOpen={modal} toggle={toggle}>
        {/* <ModalHeader toggle={toggle}>
          Donación de ${values.amount} MXN con OXXO Pay
        </ModalHeader> */}
        <ModalBody>
          {reference === '' ? (
            <form ref={c => (form = c)} onSubmit={handleSubmit}>
              <div className='form-group'>
                <input
                  required
                  name='amount'
                  type='number'
                  placeholder='Monto a donar (MXN)'
                  className='form-control'
                  onChange={handleInputChange}
                />
              </div>
              <div className='form-group'>
                <input
                  required
                  name='name'
                  type='text'
                  placeholder='Nombre(s)'
                  className='form-control'
                  onChange={handleInputChange}
                />
              </div>{' '}
              <div className='form-group'>
                <input
                  required
                  name='lastName'
                  type='text'
                  placeholder='Apellido(s)'
                  className='form-control'
                  onChange={handleInputChange}
                />
              </div>{' '}
              <div className='form-group'>
                <input
                  required
                  name='email'
                  type='email'
                  placeholder='Correo electrónico'
                  className='form-control'
                  onChange={handleInputChange}
                />
              </div>{' '}
              <div className='form-group'>
                <input
                  required
                  type='tel'
                  name='phone'
                  minlength='10'
                  maxlength='10'
                  placeholder='Teléfono'
                  className='form-control'
                  onChange={handleInputChange}
                />
              </div>
              <div className='form-group'>
                <input
                  type='submit'
                  value='Continuar'
                  className='btn btn-primary py-3 px-5 block'
                />
              </div>
            </form>
          ) : (
            <OxxoPayStub amount={values.amount} reference={reference} barcode={barcode} />
          )}
        </ModalBody>
      </Modal>
    </div>
  )
}

const withConnect = connect(null, {
  requestOxxoPay: donationActions.requestOxxoPayDonationRequest
})

export default withConnect(OxxoPay)
