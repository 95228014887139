import React, { useState, useEffect } from 'react'
import { reduxForm, formValueSelector } from 'redux-form'
import { Button } from 'reactstrap'
import FormContainer from '../../common/Container/FormContainer'
import { withForm } from '../../../util/withForm'
import ImgPreview from '../../common/ImgPreview'
import { connect } from 'react-redux'
import axios from 'axios'

function HolidayProjectPreview({ button, handleSubmit, previousPage, values, ...props }) {
  const [thumbnail, setThumbnail] = useState('')

  const hasProperty = (value, prop) => typeof value === 'object' && value.hasOwnProperty(prop)

  useEffect(() => {
    if (hasProperty(values.thumbnail, 'path')) {
      setThumbnail(values.thumbnail.path)
    } else {
      axios
        .get(`/api/file/${values.thumbnail}`)
        .then(({ data }) => {
          setThumbnail(data.path)
        })
        .catch(console.error)
    }
  }, [])

  return (
    <FormContainer {...props}>
      <div className='contenedorProject'>
        <div className='col-lg-12 row'>
          <h1 className='mt-4 mb-3 subtitleProject'>{values.title}</h1>
        </div>
        <br />
        {/* <div className='row'> */}
        <div>
          <ImgPreview path={thumbnail} />
          <span className='lead titleProject'>{values.description}</span>
          <hr />
          <div
            className='txt-holiday-project'
            dangerouslySetInnerHTML={{ __html: values.content }}
          />
        </div>
        {/* </div> */}
      </div>
      <Button onClick={previousPage}>Anterior</Button>
      <Button color='primary' className='float-right' onClick={handleSubmit}>
        Guardar
      </Button>
    </FormContainer>
  )
}

const selector = formValueSelector('holiday-project')

const withConnect = connect(state => {
  return {
    values: {
      title: selector(state, 'title'),
      description: selector(state, 'description'),
      thumbnail: selector(state, 'thumbnail'),
      content: selector(state, 'content')
    }
  }
})

export default withForm(
  reduxForm({
    form: 'holiday-project',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(withConnect(HolidayProjectPreview))
)
