import React, { useState, useEffect } from 'react'
// import ReactSelect from 'react-select'
import { Input } from 'reactstrap'
import PropTypes from 'prop-types'
import axios from 'axios'

// Select, requires options and formatter function
export const Select = ({ input, defaultLabel, ...props }) => {
  const options =
    typeof props.formatter === 'function' ? props.options.map(props.formatter) : props.options
  return (
    <Input type='select' {...props} {...input}>
      <option value=''></option>
      {options.map(item => (
        <option key={item.value} value={item.value} selected={item.value === input.value}>
          {item.label}
        </option>
      ))}
    </Input>
  )
}

// Asynchronous select, caution with re-render
export const SelectAsync = props => {
  const [options, setOptions] = useState([])
  useEffect(() => {
    axios
      .get(props.url)
      .then(result => result.data.map(props.formattter))
      .then(setOptions)
      .catch(console.log)
  }, [props.formattter, props.url])

  return <Select {...props} options={options} />
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
}

Select.defaultProps = {
  options: [],
  defaultLabel: ''
}
