import axios from 'axios'
import socket from '../../socket'
import { COUNTER_MONEY, COUNTER_VISITS, COUNTER_PROYECTS, GET_ERRORS } from './types'
import { store } from '../../index'

// @params:  $usd money (Number)
// @description: action to set in the store the money in the main page counter
// @return: (Number)
export const setMoney = payload => dispatch => dispatch({ type: COUNTER_MONEY, payload })

// @params: (Number)
// @description: action to set in the store the visits count in the main page counter
// @return: (Number)
export const setVisits = payload => dispatch => dispatch({ type: COUNTER_VISITS, payload })

// @params:  (Number)
// @description: action to set in the store the proyects count in the main page counter
// @return: (Number)
export const setProyects = payload => dispatch => dispatch({ type: COUNTER_PROYECTS, payload })

export const setCounterMoney = () => dispatch => {
  axios
    .get('/api/donation/count')
    .then(res => dispatch(setMoney(res.data.total)))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

export const setCounterVisists = () => dispatch => {
  axios
    .get('/api/visit/visits')
    .then(res => dispatch(setVisits(res.data.views)))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

export const setCounterProjects = () => dispatch => {
  axios
    .get('/api/project/count')
    .then(res => dispatch(setProyects(res.data.count)))
    .catch(error => dispatch({ type: GET_ERRORS, payload: error }))
}

socket.on('donation', res => {
  store.dispatch(setCounterMoney())
  socket.emit('dashboard', {})
})

socket.on('visit', res => {
  store.dispatch(setCounterVisists())
})

socket.on('project', res => {
  store.dispatch(setCounterProjects())
})
