import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const SummaryInformation = props => {
  const {
    title,
    objective,
    description,
    startDate,
    eventDate,
    endDate,
    formatter,
    institution,
    type,
    categorie
  } = props
  return (
    <Fragment>
      <dl className='col-md-6'>
        <dt>
          <FormattedMessage id='report.summaryTitle' />
        </dt>
        <dd>{title}</dd>
        <dt>
          <FormattedMessage id='report.summaryObjective' />
        </dt>
        <dd>{objective}</dd>
        <dt>
          <FormattedMessage id='report.summaryDescription' />
        </dt>
        <dd>{description}</dd>
      </dl>
      <dl className='col-md-3'>
        <dt>
          <FormattedMessage id='modal.institution' />
        </dt>
        <dd>{institution.name}</dd>
        <dt>
          <FormattedMessage id='label.type' />
        </dt>
        <dd>{capitalize(type)}</dd>
        <dt>
          <FormattedMessage id='modal.categorie' />
        </dt>
        <dd>{capitalize(categorie.name)}</dd>
      </dl>
      <dl className='col-md-3'>
        <dt>
          <FormattedMessage id='label.startDate' />
        </dt>
        <dd>{formatter(startDate)}</dd>
        <dt>
          <FormattedMessage id='label.event' />
        </dt>
        <dd>{formatter(eventDate)}</dd>
        <dt>
          <FormattedMessage id='label.endDate' />
        </dt>
        <dd>{formatter(endDate)}</dd>
      </dl>
    </Fragment>
  )
}

export default SummaryInformation
