import React from 'react'
import naturaleza from './img/programas-05.svg'
import {
  FormattedMessage,
  FormattedHTMLMessage
} from 'react-intl'
import ListElement from '../../../Common/ListElement/ListElement'
import Bullet from '../../../Common/Bullet/Bullet'


const ProgramsNature = () => (
  <div className="row">
    <div className="mx-auto col-md-2 mb-md-0" >
      <img className="img-fluid rounded-circle mb-3 iconsPrograms" src={naturaleza} alt="" />
    </div>
    <div className="mx-auto col-md-10 mb-md-0" >
      <h4 className="txtPrograms">
        <FormattedMessage id="programs.nature" />
      </h4>
      <p className="contentPrograms" >
        <FormattedHTMLMessage id="programs.natureTxt" />
        <br />
      </p>
      <ul className="font-weight-light lead mb-0 list-unstyled" >
        <ListElement message={"programs.nT1"}>
          <Bullet />
        </ListElement>
        <ListElement message={"programs.nT2"}>
          <Bullet />
        </ListElement>
        <ListElement message={"programs.nT3"}>
          <Bullet />
        </ListElement>
        <ListElement message={"programs.nt4"}>
          <Bullet />
        </ListElement>
      </ul>
      <br />
      <br />
      <p className="contentPrograms" >
        <FormattedMessage id="programs.natureTxt2" />
        <br />
      </p>
      <ul className="font-weight-light lead mb-0 list-unstyled" >
        <ListElement message={"programs.nT4"}>
          <Bullet />
        </ListElement>
        <ListElement message={"programs.nT5"}>
          <Bullet />
        </ListElement>
        <ListElement message={"programs.nT6"}>
          <Bullet />
        </ListElement>
        <ListElement message={"programs.nT7"}>
          <Bullet />
        </ListElement>
      </ul>
      <br />
      <br />
    </div>
  </div>
)

export default ProgramsNature