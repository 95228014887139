import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Admin from './../../Layout/Admin/Admin'
import ConnectionsRow from './components/Connections/ConnectionsRow'

import ChartsRow from './components/Connections/ChartsRow'
import { FormattedMessage, injectIntl } from 'react-intl'

export class Dashboard extends Component {

  render() {
    const { name } = this.props.auth.user
    return (
      <Admin>
        <div className="row">
          <div className="col-xl-12 col-md-12 mb-4 fixed">
            <h1 className="h3 mb-2 text-gray-800"><FormattedMessage id="dashboard.welcome" />, {name}</h1>
          </div>
        </div>
        <br />
        <ConnectionsRow />
        <br />
        <ChartsRow />
      </Admin>
    )
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  socket: PropTypes.object.isRequired,
}

const mapStateToProps = ({ auth, location, socket }) => ({ auth, location, socket })
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dashboard))