import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

export default props => {
  const handleClick = () => {
    props.onExport()
  }
  return (
    <Fragment>
      <button
        className="btn btn-success"
        style={{ float: 'right' }}
        onClick={handleClick}
      >
        <i className="fas fa-save"></i> <FormattedMessage id="label.export" />
      </button>
    </Fragment>
  )
}
