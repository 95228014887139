import React, { Component, Fragment } from 'react'
import Footer from '../Common/Footer/Footer'
import Navbar from '../Common/Navbar/Navbar'
import PrivacyTermsContent from './../PrivacyTerms/PrivacyTermsContent'

// Solution to scroll restoration
import ScrollToTopOnMount from '../Common/ScrollToTopOnMount'

export default class PrivacyTerms extends Component {
  render() {
    return (
      <Fragment>
        <ScrollToTopOnMount />
        <Navbar />
        <PrivacyTermsContent />
        <Footer />
      </Fragment>
    )
  }
}