import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './counterSection.module.css'
import Counter from './../Common/Counter/Counter'
import ContentWrapper from './../../common/ContentWrapper/ContentWrapper'
import { FormattedMessage, injectIntl } from 'react-intl'
import { formatCurrency } from '../../../util/formatCurrency'

export class CountersSection extends Component {
  state = {
    money: 0,
    visits: 0,
    proyects: 0,
  }

  componentDidMount() {
    const { money, visits, proyects } = this.props.counter
    this.setState({ money, visits, proyects })
  }

  componentWillReceiveProps(np) {
    const { money, visits, proyects } = this.state
    if (money !== np.counter.money) {
      this.setState({ money: np.counter.money })
    }
    if (visits !== np.counter.visits) {
      this.setState({ visits: np.counter.visits })
    }
    if (proyects !== np.counter.proyects) {
      this.setState({ proyects: np.counter.proyects })
    }
  }

  render() {
    const { money, visits, proyects } = this.state
    return (
      <section className={`${styles['fondoCounter']} my-4 mb-5`}>
        <div className="text-center">
          <ContentWrapper
            pretitle={<FormattedMessage id="counter.result" />}
            title={<FormattedMessage id="counter.impact" />}
          />
          <div className="container-fluid" style={{ marginBottom: '3%' }}>
            <div className="row">
              <div className="col-sm-4">
                <Counter number={visits} symbol="+" intlId="counter.labelVis" />
              </div>
              <div className="col-sm-4">
                <Counter
                  decimals={2}
                  number={money}
                  intlId="counter.labelDona"
                  formattingFn={formatCurrency}
                />
              </div>
              <div className="col-sm-4">
                <Counter number={proyects} symbol="+" intlId="counter.labelProy" />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

CountersSection.propTypes = {
  counter: PropTypes.object.isRequired,
}
const mapStateToProps = ({ counter }) => ({ counter })
const mapDispatchToProps = {}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CountersSection))
