import React, { Component } from 'react'
import { connect } from 'react-redux'
import Admin from '../Layout/Admin/Admin'
import PropTypes from 'prop-types'
import {
  getAllCarrouselAdmin,
  activateCarrousel,
  deactivateCarrousel
} from '../../redux/actions/carrouselActions'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
// Hepers
import TableWrapper from '../common/TableWrapper'
import { expandRow } from './Helpers/tableHelpers'
import { tableOptions } from './Helpers/tableHelpers'
import { injectIntl } from 'react-intl'

// Global variables
const { SearchBar } = Search

class CarrouselList extends Component {
  handleActivate = id => {
    const { intl, history } = this.props
    this.props.activateCarrousel(id, history, intl)
  }

  handleDeactivate = id => {
    const { intl, history } = this.props
    this.props.deactivateCarrousel(id, history, intl)
  }

  // LIFECYCLES METHODS

  componentDidMount() {
    this.props.getAllCarrouselAdmin()
  }

  // RENDER METHOD

  render() {
    const { listAdmin } = this.props.carrousel
    const {
      intl: { formatMessage }
    } = this.props
    const columns = tableOptions(formatMessage, this)
    return (
      <Admin>
        <ToolkitProvider
          keyField='_id'
          data={listAdmin}
          columns={columns}
          bootstrap4={true}
          search={{
            searchFormatted: true
          }}>
          {props => (
            <TableWrapper
              title={formatMessage({ id: 'label.list' })}
              label={formatMessage({ id: 'carrousel.list' })}>
              <SearchBar
                {...props.searchProps}
                placeholder={formatMessage({ id: 'label.searchBar' })}
              />
              <BootstrapTable
                {...props.baseProps}
                filter={filterFactory()}
                pagination={paginationFactory()}
                expandRow={expandRow}
                className='table-responsive'
                bordered
                hover
              />
            </TableWrapper>
          )}
        </ToolkitProvider>
      </Admin>
    )
  }
}

CarrouselList.propTypes = {
  auth: PropTypes.object.isRequired,
  carrousel: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth, carrousel }) => ({ auth, carrousel })
const mapDispatchToProps = { getAllCarrouselAdmin, activateCarrousel, deactivateCarrousel }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CarrouselList))
