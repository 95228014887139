import React, { Component } from 'react'
// Other dependencies
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import swal from '@sweetalert/with-react'
import { FormattedMessage, injectIntl } from 'react-intl'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
// Helpers' functions
import { tableOptions, columnsToExport } from './helpers/tableHelpers'
// import TableWrapper from './../common/TableWrapper'
import { expandRow } from './../../util/tableHelpers'
import initialState from './helpers/initialState'
import { isEmpty } from './../../util/isEmpty'
import { findItem } from './../../util/helpers'
// Validations
import DonationsValidations from './validations'
import {
  onChangeState,
  validationsOnSubmit,
  specialValidationsOnChange,
  specialValidations
} from './../../util/inputsHelpers'
// Redux actions
import {
  addDonation,
  editDonation,
  updateDonation,
  getAllDonation,
  approveDonation,
  deleteDonation,
  getAllDonationProjects,
  getCleanEditDonation,
  getAllCleanDonation
} from '../../redux/actions/donationsActions'
// Components
import Admin from './../Layout/Admin/Admin'
import DonationsForm from './DonationsForm'
import ModalDonation from './ModalDonation'
import ExportExcel from '../common/ExportExcel/ExportExcel'

// Global variables
const { SearchBar } = Search

class DonationsReport extends Component {
  // Filter select options
  typeOptions = {
    transfer: 'Transfer',
    manual: 'Manual',
    paypal: 'Paypal',
    softtek: 'Softtek',
    others: 'Others',
    card: 'Credit card'
  }

  // Filter select options
  approvedOptions = {
    true: this.props.intl.formatMessage({ id: 'general.confirmation' }),
    false: this.props.intl.formatMessage({ id: 'general.negation' })
  }

  state = {
    ...initialState
  }

  // Set the state to the initial
  setStateToInitial = () => {
    this.setState({ ...initialState })
  }

  // Get all the donation and the projects that
  // accept donations
  componentDidMount() {
    this.props.getAllDonation()
    this.props.getAllDonationProjects()
  }

  // Remove the list of donations from the global state
  componentWillUnmount() {
    this.props.getAllCleanDonation()
  }

  // Handle for checkbox change at the field 'needReceipt'
  handleCheckbox = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        needReceipt: { ...prevState.fields.needReceipt, value: value }
      }
    }))
  }

  // Handle for change at text's input fields
  // With validations on real time
  handleChange = event => {
    const field = event.target.name
    const value = event.target.value
    const { projects } = this.props.donations
    this.setState(onChangeState(event), () => {
      const validator = specialValidationsOnChange(
        this.state.fields,
        DonationsValidations,
        field
      )
      if (validator !== null) this.setState({ fields: { ...validator.stateValidated } })
      if (field === 'fields.project' && value) {
        const { sede } = projects.find(item => item._id === value)
        this.setState(state => ({
          fields: {
            ...state.fields,
            currency: {
              ...state.fields.currency,
              value: sede.currency
            }
          }
        }))
      }
    })
  }

  // Click on the ADD button
  // - Open the form
  onClickAdd = () => {
    this.setState(state => ({
      btnSave: !state.btnSave,
      btnAdd: !state.btnAdd,
      btnCancel: !state.btnCancel,
      form: !state.form
    }))
  }

  // The user wants to submit a new donation
  onSubmit = event => {
    event.preventDefault()
    const { intl } = this.props

    // Check for the validations
    const r = specialValidations(this.state.fields, DonationsValidations)
    if (!r.isValid) {
      // Validations didn't pass
      this.setState({ fields: { ...r.stateValidated } })
    } else {
      // Validation passed
      const { fields } = this.state
      const payload = {
        amount: fields.amount.value,
        type: fields.type.value,
        description: fields.description.value,
        needReceipt: fields.needReceipt.value,
        project: fields.project.value,
        currency: fields.currency.value
      }

      // Make a HTTP POST request to /api/donation
      this.props.addDonation(payload, intl, () => {
        this.setStateToInitial()
      })
    }
  }

  // Click on the APPROVE button
  // Only is visible when the donation: Is not approved
  onClickApprove = id => {
    const { intl } = this.props
    // Make a HTTP PUT request to /api/donation/:id
    this.props.approveDonation(id, intl)
    this.setStateToInitial()
  }

  // Click on the DELETE button
  // Only is visible when the donation: Is not approved
  onClickDelete = id => {
    const { intl } = this.props
    // Make a HTTP DELETE request to /api/donation/approve
    this.props.deleteDonation(id, intl)
    this.setStateToInitial()
  }

  // Click on the CANCEL button
  // It cancel the current operation
  onClickCancel = () => {
    this.setStateToInitial()
  }

  // Click on the EDIT button
  onClickEdit = row => {
    window.scrollTo(0, 0)

    const {
      _id,
      amount,
      originalAmount,
      type,
      project,
      description,
      currency,
      needReceipt,
      approved
    } = row

    // If the donation has not project assigned it is set to null
    const projectId = isEmpty(project) ? null : project._id

    // Set the values of the donation that
    // needs to be edited at the global state
    this.props.editDonation({ _id, currency })

    const filledAmount = currency === 'USD' ? amount : originalAmount

    const filledFields = {
      amount: { ...initialState.fields.amount, value: filledAmount },
      type: { ...initialState.fields.type, value: type },
      project: { ...initialState.fields.project, value: projectId },
      description: { ...initialState.fields.description, value: description },
      currency: { ...initialState.fields.currency, value: currency },
      needReceipt: { ...initialState.fields.needReceipt, value: needReceipt },
      approved: { ...initialState.fields.approved, value: approved }
    }

    this.onClickCancel()

    this.setState(state => ({
      fields: { ...filledFields },
      btnUpdate: !state.btnUpdate,
      btnAdd: !state.btnAdd,
      btnCancel: !state.btnCancel,
      form: !state.form
    }))
  }

  // Click on the UPDATE button
  // When the user wants to submit the chanes made to a donation's record
  onClickUpdate = event => {
    event.preventDefault()
    const { intl } = this.props

    const { _id, currency } = this.props.donations.edit
    console.log(this.props.donations.edit)
    const { fields } = this.state

    const r = validationsOnSubmit(this.state, DonationsValidations)
    if (!r.isValid) {
      this.setState({ fields: { ...r.stateValidated } })
    } else {
      const payload = {
        amount: fields.amount.value,
        type: fields.type.value,
        description: fields.description.value,
        needReceipt: fields.needReceipt.value,
        project: fields.project.value,
        currency: fields.currency.value,
        currencyChanged: fields.currency.value !== currency
      }

      // Make a HTTP PUT request to /api/donation/:id
      this.props.updateDonation(_id, payload, intl, () => {
        this.setStateToInitial()
        this.props.getCleanEditDonation()
      })
    }
  }

  // Toggle Modal
  toggle = () => this.setState({ modal: !this.state.modal })

  // Open a modal
  onClickDetail = (name, _id) => {
    const modalPaypal = name === 'paypal'
    const item = findItem(this.props.donations.list, _id)
    this.setState({ modalPaypal, item }, () => this.toggle())
  }

  render() {
    const {
      typeOptions,
      approvedOptions,
      props: { donations, intl }
    } = this
    const columns = tableOptions(intl.formatMessage, typeOptions, approvedOptions, { ...this })

    const data = donations.list
    const { btnSave, btnUpdate, btnAdd, btnCancel, form } = this.state

    return (
      <Admin>
        <div className='row mx-3'>
          <div className='col-lg-12'>
            <h1 className='h3 mb-2 text-gray-800'>
              <FormattedMessage id='label.donation' />
            </h1>
          </div>
        </div>

        <div className='row mx-3'>
          <div className='col-lg-12'>
            <div className='card shadow mb-4'>
              <div className='card-header py-3'>
                <h6 className='m-0 font-weight-bold text-primary'>
                  <FormattedMessage id='general.actions' />
                </h6>
              </div>
              <div className='card-body'>
                <ul className='list-inline'>
                  {btnAdd && (
                    <li className='list-inline-item'>
                      <button className='btn btn-primary' onClick={this.onClickAdd}>
                        <FormattedMessage id='btn.add' />
                      </button>
                    </li>
                  )}
                </ul>

                {form && (
                  <DonationsForm {...this} {...this.state} projects={donations.projects} />
                )}

                <ul className='list-inline'>
                  {btnSave && (
                    <li className='list-inline-item'>
                      <button className='btn btn-primary' onClick={this.onSubmit}>
                        <FormattedMessage id='btn.save' />
                      </button>
                    </li>
                  )}

                  {btnCancel && (
                    <li className='list-inline-item'>
                      <button className='btn btn-danger' onClick={this.onClickCancel}>
                        <FormattedMessage id='btn.cancel' />
                      </button>
                    </li>
                  )}

                  {btnUpdate && (
                    <li className='list-inline-item'>
                      <button className='btn btn-info' onClick={this.onClickUpdate}>
                        <FormattedMessage id='btn.update' />
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className='card shadow mb-4'>
              <div className='card-header py-3'>
                <h6 className='m-0 font-weight-bold text-primary'>
                  <FormattedMessage id='table.registry' />
                </h6>
              </div>
              <div className='card-body table-responsive'>
                <ToolkitProvider
                  keyField='_id'
                  data={data}
                  columns={columns}
                  bootstrap4={true}
                  search={{
                    searchFormatted: true
                  }}>
                  {props => (
                    <div>
                      <SearchBar
                        {...props.searchProps}
                        placeholder={this.props.intl.formatMessage({ id: 'label.searchBar' })}
                      />

                      <BootstrapTable
                        {...props.baseProps}
                        filter={filterFactory()}
                        pagination={paginationFactory()}
                        expandRow={expandRow}
                        bordered
                        hover
                      />
                      {!isEmpty(data) && (
                        <ExportExcel
                          data={data}
                          columnsToExport={columnsToExport}
                          tableName='Reporte de donaciones'
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>

        <ModalDonation {...this} {...this.state} />
      </Admin>
    )
  }
}

DonationsReport.propTypes = {
  auth: PropTypes.object.isRequired,
  donations: PropTypes.object.isRequired
}

const mapStateToProps = ({ auth, donations }) => ({ auth, donations })
const mapDispatchToProps = {
  addDonation,
  editDonation,
  getCleanEditDonation,
  updateDonation,
  approveDonation,
  deleteDonation,
  getAllDonation,
  getAllCleanDonation,
  getAllDonationProjects
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DonationsReport))
