import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Card, CardText, CardBody, CardTitle } from 'reactstrap'
import styles from './../../Main/Common/Cards/cardNew.module.css'
import ArrowSVG from '../../common/SVG/ArrowSVG'
import lenguaje from '../text'

export default function CustomerProjectCard(props) {
  const { slug, title, route, description, thumbnail, lg, md, sm } = props
  const className = `col-md-${md || 6} col-lg-${lg || 4} col-sm-${sm || 12} mb-5`
  const { path } = thumbnail || {}

  debugger
  return (
    <div className={props.className || className}>
      <Card className={styles['card']} style={{ height: 450 }}>
        <Link to={`/${route}/${slug}`}>
          <div
            className={styles['center-cropped']}
            style={{
              backgroundImage: path
                ? `url(${path})`
                : 'url(https://dummyimage.com/286x180/d6cfd6/e2e3f0)'
            }}
          />
        </Link>
        <CardBody>
          <CardTitle className={`${styles['card__title']} mb-3`}>{title}</CardTitle>
          <CardText className={`${styles['card__text']}`}>{description}</CardText>
          <Link to={`/${route}/${slug}`} className={`${styles['card__button']}`}>
            <FormattedMessage
              id={props.country === 'MX' ? lenguaje.MX.btnCard : lenguaje.EU.btnCard}
            />
            <ArrowSVG className={`${styles['card__button-image']}`} />
          </Link>
        </CardBody>
      </Card>
    </div>
  )
}
