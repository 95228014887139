import config from '../../config'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Admin from '../Layout/Admin/Admin'
import { FilePond, File, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import Card2 from './../common/Card/Card2'
import swal from '@sweetalert/with-react'
import { injectIntl, FormattedMessage } from 'react-intl'
import {
  getGallerieCampaing,
  getCleanGallerieCampaing,
  addGallerieCampaing,
  removeGallerieCampaing
} from '../../redux/actions/campaingActions'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop
)

export class CampaingImagesGallerie extends Component {
  state = {
    files: [],
    gallerie: [],
    uploadInput: true,
    file: {
      _id: null,
      title: '',
      img: ''
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    this.props.getGallerieCampaing(id)
  }

  componentWillUnmount() {
    this.props.getCleanGallerieCampaing()
  }

  server = {
    url: config.URL_API,
    process: {
      url: `/api/gallerie/${this.props.match.params.id}`,
      method: 'POST',
      headers: { Authorization: `Bearer ${localStorage.token}` },
      onload: response => {
        const image = JSON.parse(response)
        this.props.addGallerieCampaing(image)
        this.setState(s => ({
          uploadInput: false,
          file: { ...s.file, ...image }
        }))
      },
      onerror: error => {
        const { intl } = this.props
        swal({
          title: intl.formatMessage({ id: 'alert.file' }),
          text: '',
          icon: 'warning'
        })
      }
    }
  }

  render() {
    const { gallerie: campaing } = this.props.campaing
    const data = campaing.gallerie ? campaing.gallerie : []

    return (
      <Admin>
        <div className='container-fluid'>
          <div className='row'>
            <h1 className='h3 mb-2 col-12 text-gray-800'>{campaing.title}</h1>
            <div className='card shadow mt-3 mb-4' style={{ width: '100%' }}>
              <div className='card-header py-3'>
                <h6 className='m-0 mb-4 font-weight-bold text-primary'>
                  <FormattedMessage id='label.uploadImage' />
                </h6>
              </div>
              <div className='card-body table-responsive'>
                <div className='col-md-6 offset-md-3'>
                  <FilePond
                    ref={ref => (this.pond = ref)}
                    allowMultiple={true}
                    maxFiles={3}
                    server={this.server}
                    allowImagePreview={false}
                    onupdatefiles={fileItems => {
                      // Set current file objects to this.state
                      this.setState({
                        files: fileItems.map(fileItem => fileItem.file)
                      })
                    }}>
                    {/* Update current files  */}
                    {this.state.files.map(file => (
                      <File key={file} src={file} origin='local' />
                    ))}
                  </FilePond>
                </div>
              </div>
            </div>
            {/* Images Gallerie */}
            <div className='col-12'>
              <h1 className='h3 mb-2 text-gray-800'>
                <FormattedMessage id='label.gallerieImages' />
              </h1>
            </div>
            <div className='row my-3' style={{ width: '100%' }}>
              {data.map(i => {
                return (
                  <Card2
                    _id={i._id}
                    key={i._id}
                    url={i.url}
                    path={i.path}
                    title={i.title}
                    projectId={campaing._id}
                    intl={this.props.intl}
                    onClick={this.props.removeGallerieCampaing}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </Admin>
    )
  }
}

const mapStateToProps = ({ auth, campaing }) => ({ auth, campaing })
const mapDispatchToProps = {
  getGallerieCampaing,
  getCleanGallerieCampaing,
  addGallerieCampaing,
  removeGallerieCampaing
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CampaingImagesGallerie))
