import React from 'react'
import Time from './img/d2s-01.svg'
import Money from './img/d2s-03.svg'
import Items from './img/d2s-02.svg'
import Color from './img/logo-color.png'
import styles from './dareToShare-section.module.css'
import { FormattedMessage } from 'react-intl'

import ContentWrapper from './../../common/ContentWrapper/ContentWrapper'
import DareToShareIcon from './DareToShareIcon/DareToShareIcon'

const icons = [
  { icon: Time, text: <FormattedMessage id="make.time" /> },
  { icon: Money, text: <FormattedMessage id="make.money" /> },
  { icon: Items, text: <FormattedMessage id="make.items" /> }
]

const DareToShare = () => (
  <section className={`${styles['fondoDare']}`}>
    <div className={`text-center`}>
      <div className={`container`}>
        <ContentWrapper
          className='my-0'
          pretitle={<FormattedMessage id="dare.know" />}
        />
        <img className={`${styles['logoDare']} my-2`} src={Color} alt="time" />
        <p className={`lead ${styles['txt']} mb-5`}>
          <FormattedMessage id="make.txt" />
        </p>
      </div>
      <div className="col-12 mb-4">
        <div className="container">
          <div className="row">
            {icons.map((c, i) => (
              <div className={`col-sm-4`}>
                <DareToShareIcon key={c.text + '-' + i} {...c} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>

)

export default DareToShare