import React, { Component } from 'react'
import { connect } from 'react-redux'
import Admin from '../Layout/Admin/Admin'
import PropTypes from 'prop-types'
import {
	getAllConvocatoryAdmin,
	editConvocatory,
	getConvocatory,
	updateConvocatory,
	getSubscribersConvocatory,
	getAllCleanConvocatory,
	activateConvocatory
} from './../../redux/actions/convocatoryActions'
// BootstrapTable
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
// Hepers
import TableWrapper from '../common/TableWrapper'
import { expandRow } from '../../util/tableHelpers'
import { isEmpty } from '../../util/helpers'
import { tableOptions } from './Helpers/tableHelpers'
import CSVButton from './../common/CSVButton'
import { FormattedMessage, injectIntl } from 'react-intl'
import { today } from './../../util/dateHelpers'
import ExportExcel from '../common/ExportExcel/ExportExcel'
import { columnsToExport } from './Helpers/tableHelpers'

// Global variables
const { SearchBar } = Search

class ConvocatoryListPagination extends Component {
	handleView = (id) => this.props.getConvocatory(id, this.props.intl)
	handleEdit = (id) => this.props.editConvocatory(id)
	handleSubscribers = (id) => this.props.getSubscribersConvocatory(id)
	handleDelete = (n) =>
		this.props.activateConvocatory(n._id, { active: !n.active }, this.props.history, this.props.intl)

	// LIFECYCLES METHODS

	componentDidMount() {
		this.props.getAllConvocatoryAdmin(this.props.intl)
	}

	componentWillReceiveProps(np) {
		if (!isEmpty(np.convocatory.view)) {
			const id = np.convocatory.view._id
			np.history.push(`/convocatory/gallerie/${id}`)
		}
		if (!isEmpty(np.convocatory.edit)) {
			const id = np.convocatory.edit._id
			np.history.push(`/dashboard/convocatory/edit/${id}`)
		}
		if (!isEmpty(np.convocatory.subscribers)) {
			const id = np.convocatory.subscribers._id
			np.history.push(`/dashboard/convocatory/subscribers/${id}`)
		}
	}

	componentWillUnmount() {
		this.props.getAllCleanConvocatory()
	}

	// Objeto que muestra las opciones de activo en multilenguaje
	activeOptions = {
		false: this.props.intl.formatMessage({ id: 'general.negation' }),
		true: this.props.intl.formatMessage({ id: 'general.confirmation' })
	}

	// RENDER METHOD

	render() {
		const { list } = this.props.convocatory
		const columns = tableOptions(this.props.intl.formatMessage, this.activeOptions, {
			...this
		})
		return (
			<Admin>
				<ToolkitProvider
					keyField="_id"
					data={list}
					columns={columns}
					bootstrap4={true}
					search={{
						searchFormatted: true
					}}
					exportCSV={{
						fileName: `ConvocatoriesFS-${today()}.csv`,
						separator: ',',
						noAutoBOM: false,
						onlyExportFiltered: true,
						exportAll: false
					}}
				>
					{(props) => (
						<TableWrapper
							title={<FormattedMessage id="label.list" />}
							label={<FormattedMessage id="convocatory.list" />}
						>
							<SearchBar
								{...props.searchProps}
								placeholder={this.props.intl.formatMessage({ id: 'label.searchBar' })}
							/>
							<BootstrapTable
								{...props.baseProps}
								filter={filterFactory()}
								pagination={paginationFactory()}
								expandRow={expandRow}
								className="table-responsive"
								bordered
								hover
							/>
							{/* <CSVButton {...props.csvProps} /> */}
							{!isEmpty(list) && (
								<ExportExcel data={list} columnsToExport={columnsToExport} tableName="Convocatorias" />
							)}
						</TableWrapper>
					)}
				</ToolkitProvider>
			</Admin>
		)
	}
}

ConvocatoryListPagination.propTypes = {
	auth: PropTypes.object.isRequired,
	convocatory: PropTypes.object.isRequired
}
const mapStateToProps = ({ auth, convocatory }) => ({ auth, convocatory })
const mapDispatchToProps = {
	getAllConvocatoryAdmin,
	editConvocatory,
	getConvocatory,
	updateConvocatory,
	getSubscribersConvocatory,
	getAllCleanConvocatory,
	activateConvocatory
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConvocatoryListPagination))
