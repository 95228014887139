import { createActions, createReducer } from 'reduxsauce'
import * as handlers from './customer-project.helpers'

const { Types, Creators } = createActions(
  {
    publicLoadAllCustomerProjectRequest: ['payload'],
    publicLoadAllCustomerProjectSuccess: ['payload'],
    publicLoadAllCustomerProjectFailure: ['payload'],

    publicLoadCustomerProjectRequest: ['payload'],
    publicLoadCustomerProjectSuccess: ['payload'],
    publicLoadCustomerProjectFailure: ['payload'],

    publicDonateCustomerProjectRequest: ['payload'],
    publicDonateCustomerProjectSuccess: ['payload'],
    publicDonateCustomerProjectFailure: ['payload'],

    loadAllCustomerProjectRequest: ['payload'],
    loadAllCustomerProjectSuccess: ['payload'],
    loadAllCustomerProjectFailure: ['payload'],

    loadCustomerProjectRequest: ['payload'],
    loadCustomerProjectSuccess: ['payload'],
    loadCustomerProjectFailure: ['payload'],

    createCustomerProjectRequest: ['payload'],
    createCustomerProjectSuccess: ['payload'],
    createCustomerProjectFailure: ['payload'],

    editCustomerProjectRequest: ['payload'],
    editCustomerProjectSuccess: ['payload'],
    editCustomerProjectFailure: ['payload'],

    updateCustomerProjectRequest: ['payload'],
    updateCustomerProjectSuccess: ['payload'],
    updateCustomerProjectFailure: ['payload'],

    toggleCustomerProjectRequest: ['payload'],
    toggleCustomerProjectSuccess: ['payload'],
    toggleCustomerProjectFailure: ['payload'],

    cleanCustomerProject: null
  },
  {}
)

const initialState = {
  list: [],
  view: {},
  edit: {},
  gallery: [],
  individual: {}
}

export function publicLoadAllCustomerProject(state, action) {
  return { ...state, gallery: [...action.payload] }
}

export function publicLoadCustomerProject(state, action) {
  return { ...state, individual: { ...action.payload } }
}

export function loadAllCustomerProject(state, action) {
  return { ...state, list: [...action.payload] }
}

export const HANDLERS = {
  [Types.PUBLIC_LOAD_ALL_CUSTOMER_PROJECT_SUCCESS]: publicLoadAllCustomerProject,
  [Types.PUBLIC_LOAD_CUSTOMER_PROJECT_SUCCESS]: publicLoadCustomerProject,
  [Types.LOAD_ALL_CUSTOMER_PROJECT_SUCCESS]: loadAllCustomerProject,
  [Types.LOAD_CUSTOMER_PROJECT_SUCCESS]: handlers.loadSuccess,
  [Types.CREATE_CUSTOMER_PROJECT_SUCCESS]: handlers.createSuccess,
  [Types.EDIT_CUSTOMER_PROJECT_SUCCESS]: handlers.editSuccess,
  [Types.UPDATE_CUSTOMER_PROJECT_SUCCESS]: handlers.updateSuccess,
  [Types.TOGGLE_CUSTOMER_PROJECT_SUCCESS]: handlers.toggleSuccess,
  [Types.CLEAN_CUSTOMER_PROJECT]: state => ({ ...state, individual: {} })
}

export const types = Types
export const actions = Creators
export const customerProject = createReducer(initialState, HANDLERS)
