import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import swal from '@sweetalert/with-react'
import { updatedItem, findItem } from '../../../../util/helpers'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {
  onChangeState, isEmpty,
  handleChangeSelectData,
  validationsOnSubmit,
  specialValidationsOnChange,
  checkOnchange, specialValidations
} from '../../../../util/inputsHelpers'
import { setNotify } from './../../../../redux/actions/notifyAction'
import { spinnerToggle } from './../../../../redux/actions/spinnerActions'
import SubsedeTable from './SubsedeTable'
import Subsedevalidations from './Subsedevalidations'
import SubsedeForm from './SubsedeForm'
import Admin from './../../../Layout/Admin/Admin'
//import msg from './../../../../lang/message'
import { initialState as initState } from '../utils/initialState'
import { injectIntl, FormattedMessage } from 'react-intl'

const initialState = {

  ...initState,
  fields: {
    name: {
      value: '',
      valid: null,
      invalid: null,
      text: '',
      feed: []
    },
    description: {
      value: '',
      valid: null,
      invalid: null,
      text: '',
      feed: []
    },
    email: {
      value: '',
      valid: null,
      invalid: null,
      text: '',
      feed: []
    },
    sede: {
      value: '',
      valid: null,
      invalid: null,
      text: '',
      feed: []
    },
    location_address: {
      value: '',
      valid: null,
      invalid: null,
      text: '',
      feed: []
    }
  },
  sede_options: []
}

export class Subsede extends Component {

  state = { ...initialState }

  columns = ['label.name', 'label.description', 'table.active', 'table.create', 'table.update']

  componentWillMount() {
    this.props.spinnerToggle()
    axios
      .get('/api/sede')
      .then(res => {
        if (!isEmpty(res.data)) {
          this.props.spinnerToggle()
          this.setState(() => ({ sede: [...res.data] }))
        }
      })
      .catch(err => {
        this.props.spinnerToggle()
      })
  }

  componentDidMount() {
    axios
      .get('/api/subsede')
      .then(res => {
        this.props.spinnerToggle()
        this.setState(() => ({ list: [...res.data] }))
      })
      .catch(err => {
        this.props.spinnerToggle()
      })
  }


  handleChangeSelectData = props => {
    const field = props.name
    this.setState(handleChangeSelectData(props), () => {
      const { state } = this
      const validator = checkOnchange(state, Subsedevalidations, field)
      this.setState({ ...validator.state })
    })
  }

  handleChange = event => {
    const field = event.target.name
    this.setState(onChangeState(event), () => {
      const { state } = this
      const validator = specialValidationsOnChange(state.fields, Subsedevalidations, field)
      if (validator !== null) this.setState({ fields: { ...validator.stateValidated } })
    })
  }

  // @return       Void.
  // @description  handle the onChange event
  // for nested props
  onChangeObject = event => {
    const target = event.target
    const value = target.value
    const [prop, subprop] = target.name.split('.')
    this.setState(state => ({
      [prop]: { ...state[prop], [subprop]: value }
    }))
  }


  // @return        Void
  // Description    Clean the state and fields
  // and set visible the btn for save.
  onClickAdd = () => this.setState(state => ({
    catalog: initialState.catalog,
    btnSave: !state.btnSave,
    btnAdd: !state.btnAdd,
    btnCancel: !state.btnCancel,
    form: !state.form
  }))


  // @return        Void
  // Description    Create a element.
  onSubmit = event => {
    let { intl } = this.props
    event.preventDefault()
    const r = specialValidations(this.state.fields, Subsedevalidations)
    if (!r.isValid) {
      this.setState({ fields: { ...r.stateValidated } })
    } else {
      this.props.spinnerToggle()
      const { name,
        description,
        email,
        location_address,
        sede,
      } = this.state.fields
      const payload = {
        name: name.value,
        description: description.value,
        email: email.value,
        location: {
          address: location_address.value
        },
        sede: sede.value,
      }
      const route = '/api/subsede'
      axios
        .post(route, payload)
        .then(res => {
          this.props.spinnerToggle()
          this.setState(state => ({
            list: [...state.list, res.data],
            catalog: initialState.catalog,
            fields: initialState.fields,
            btnSave: false,
            btnUpdate: false,
            btnAdd: true,
            btnCancel: false,
            form: false
          }))
          swal(intl.formatMessage({ id: 'alert.subsedeAdd' }), '', "success");
        })
        .catch(err => {
          // ERRORES HTTP
          if (err.response.status === 401) swal(intl.formatMessage({ id: 'alert.error401Title' }), intl.formatMessage({ id: 'alert.error401Text' }), "error");
          else swal(intl.formatMessage({ id: 'alert.errorGeneralTitle' }), intl.formatMessage({ id: 'alert.errorGeneralText' }), "error");
        })
    }
  }

  // @return        Void
  // Description    Get the details
  // data from the _id selected and
  // open a modal.
  onClickDetail = _id => {
    const { list } = this.state
    const item = findItem(list, _id)
    this.setState({ item }, () => this.toggle())
  }
  // @return        Void
  // Description    Updated the fields
  // with the data form the row to can
  // edit this row.
  onClickEdit = _id => {
    window.scrollTo(0, 0)
    const { list } = this.state
    const catalog = list.find(c => c._id === _id)
    const fields = {
      name: { ...initialState.fields.name, value: catalog.name },
      description: { ...initialState.fields.description, value: catalog.description },
      email: { ...initialState.fields.email, value: catalog.email },
      sede: { ...initialState.fields.sede, value: !isEmpty(catalog.sede) ? catalog.sede._id : '' },
      location_address: { ...initialState.fields.location_address, value: catalog.location.address },
    }
    this.setState(state => ({
      fields,
      catalog,
      btnUpdate: !state.btnUpdate,
      btnAdd: !state.btnAdd,
      btnCancel: !state.btnCancel,
      form: !state.form
    }))
  }



  // @return        Void
  // Description    Updated element.
  onClickUpdate = async event => {
    let { intl } = this.props
    event.preventDefault()
    const r = validationsOnSubmit(this.state, Subsedevalidations)
    // ! Alerta que guarda la causa de la edición al registro
    if (!r.isValid) {
      this.setState({ fields: { ...r.stateValidated } })
    } else {
      const cause = await swal({
        title: intl.formatMessage({ id: 'alert.desTitle' }),
        icon: 'warning',
        buttons: {
          error: {
            text: intl.formatMessage({ id: 'alert.causeErrorText' }),
            value: intl.formatMessage({ id: 'alert.causeErrorText' })
          },
          actualizacion: {
            text: intl.formatMessage({ id: 'alert.upText' }),
            value: intl.formatMessage({ id: 'alert.upText' })
          }
        }
      })
      if (!isEmpty(cause)) {
        // ! Alerta que guarda la justificación
        const desc = await swal({
          title: intl.formatMessage({ id: 'alert.desTitle' }),
          content: 'input',
          buttons: true
        })
        if (!isEmpty(desc)) {
          this.props.spinnerToggle()
          const { name, description, email, location_address, sede } = this.state.fields
          const payload = {
            payload: {
              name: name.value, description: description.value, email: email.value,
              location: {
                address: location_address.value
              },
              sede: sede.value
            },
            detail: {
              cause,
              description: desc
            }
          }
          const { catalog: { _id } } = this.state
          const route = `/${'api/subsede'}/${_id}`

          /// -------------------------- HTTP REQUESTS --------------------------

          // PUT api/subsede/:id
          // Se actualiza el registro
          axios
            .put(route, payload)
            .then(res => {
              this.props.spinnerToggle()
              this.setState(state => ({
                ...initialState,
                list: updatedItem(state.list, res.data),
                sede_options: state.sede_options
              }))
              swal(intl.formatMessage({ id: 'alert.changes' }), { icon: "success" })
            })
            .catch(err => {
              // ERRORES HTTP
              if (err.response.status === 401) swal(intl.formatMessage({ id: 'alert.error401Title' }), intl.formatMessage({ id: 'alert.error401Text' }), "error");
              else swal(intl.formatMessage({ id: 'alert.errorGeneralTitle' }), intl.formatMessage({ id: 'alert.errorGeneralText' }), "error");
            })
        } else {
          swal(intl.formatMessage({ id: 'alert.changesOff' }), intl.formatMessage({ id: 'alert.writeCause' }), 'info') 
        }
      }
    }
  }



  // @return        Void
  // Description    Toggle Active element.
  onClickDelete = _id => {
    let { intl } = this.props
    this.props.spinnerToggle()
    const { list: oldList } = this.state
    const item = oldList.find(c => c._id === _id)
    const route = `/${'api/subsede'}/${_id}`
    swal({
      title: intl.formatMessage({ id: 'alert.confiTitle' }),
      text: `${intl.formatMessage({ id: 'alert.changeRegistry' })} ${item.active ? intl.formatMessage({ id: 'alert.off' }) : intl.formatMessage({ id: 'alert.on' })}`,
      icon: "warning",
      buttons: true,
    })
    .then(willUpdate => {
      if(willUpdate){
        swal({
          title: intl.formatMessage({ id: 'alert.desTitle' }),
          content: 'input'
        }).then(desc => {
          if (!isEmpty(desc)) {
            const payload = {
              payload: {
                active: !item.active
              },
              detail: {
                cause: `${!item.active ? intl.formatMessage({ id: 'alert.onCap' }) : intl.formatMessage({ id: 'alert.offCap' })}`,
                description: desc
              }
            }
            axios
              .put(route, payload)
              .then(res => {
                this.props.spinnerToggle()
                this.setState(state => ({
                  ...initialState,
                  list: updatedItem(state.list, res.data),
                }))
                swal(`${intl.formatMessage({ id: 'alert.registry' })} ${item.active ? intl.formatMessage({ id: 'alert.off' }) : intl.formatMessage({ id: 'alert.on' })}`, {
                  icon: "success",
                })
              })
              .catch(err => {
                // ERRORES HTTP
                if (err.response.status === 401) swal(intl.formatMessage({ id: 'alert.error401Title' }), intl.formatMessage({ id: 'alert.error401Text' }), "error");
                else swal(intl.formatMessage({ id: 'alert.errorGeneralTitle' }), intl.formatMessage({ id: 'alert.errorGeneralText' }), "error");
              })
          } else {
            swal(intl.formatMessage({ id: 'alert.changesOff' }), intl.formatMessage({ id: 'alert.writeCause' }), 'info') 
          }
      })
    }
  })
}

  // @return      Void
  // Description  Toggle Modal.
  toggle = () => this.setState({ modal: !this.state.modal })

  // @return      Void
  // Description  Return to the initial state
  // but dont modify the list prop.
  onClickCancel = () => this.setState(state => ({ ...initialState, list: [...state.list] }))

  render() {
    const { btnSave, btnUpdate, btnAdd, btnCancel, form } = this.state
    return (
      <Admin>
        <div className="row">
          <div className="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10">
            <h1 className="h3 mb-2 text-gray-800"><FormattedMessage id="label.subsede" /></h1>
          </div>
        </div>

        <div className="row">
          <div className="offset-xl-1 col-xl-10 offset-lg-1 col-lg-10">

            <div class="card shadow mb-5">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary"><FormattedMessage id="general.actions" /></h6>
              </div>
              <div class="card-body">
                <ul className="list-inline">
                  {btnAdd && (
                    <li className="list-inline-item">
                      <button className="btn btn-primary" onClick={this.onClickAdd}><FormattedMessage id="btn.add" /></button>
                    </li>)}
                </ul>

                {form && (
                  <SubsedeForm {...this} {...this.state} />
                )}

                <ul className="list-inline">
                  {btnSave && (
                    <li className="list-inline-item">
                      <button className="btn btn-primary" onClick={this.onSubmit}><FormattedMessage id="btn.save" /></button>
                    </li>)}

                  {btnCancel && (
                    <li className="list-inline-item">
                      <button className="btn btn-danger" onClick={this.onClickCancel}><FormattedMessage id="btn.cancel" /></button>
                    </li>)}

                  {btnUpdate && (
                    <li className="list-inline-item">
                      <button className="btn btn-info" onClick={this.onClickUpdate}><FormattedMessage id="btn.update" /></button>
                    </li>)}
                </ul>

              </div>
            </div>

            <div class="card shadow mb-5 table-responsive">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary"><FormattedMessage id="table.registry" /></h6>
              </div>
              <div class="card-body">
                {this.state.list.length !== 0 && (<SubsedeTable {...this.state} {...this} {...this.props} columns={this.columns} />)}
              </div>
            </div>
          </div>
        </div>

        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}><FormattedMessage id="modal.subsede" /></ModalHeader>
          <ModalBody>
            {!isEmpty(this.state.item) && (<p>
              <strong><FormattedMessage id="label.name" />: </strong>{this.state.item.name} <br />
              <strong><FormattedMessage id="label.description" />: </strong>{this.state.item.description} <br />
              <strong><FormattedMessage id="label.email" />: </strong>{this.state.item.email} <br />
              <strong><FormattedMessage id="modal.sede" />: </strong>{this.state.item.sede.name} <br />
              <strong><FormattedMessage id="label.address" />: </strong>{this.state.item.location.address} <br />
            </p>)}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}><FormattedMessage id="btn.cancel" /></Button>
          </ModalFooter>
        </Modal>
      </Admin>
    )
  }
}

const mapStateToProps = ({ auth, errors }) => ({ auth, errors })
const mapDispatchToProps = { setNotify, spinnerToggle }
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Subsede))
