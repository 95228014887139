import HolidayProjectList from './HolidayProjectList'
import HolidayProjectCreate from './HolidayProjectCreate'
import HolidayProjectView from './HolidayProjectView'
import HolidayProjectEdit from './HolidayProjectEdit'
import HolidayProjectGallery from './HolidayProjectGallery'
import HolidayProject from './HolidayProject'

export default [
  {
    path: '/dashboard/holiday-project/list',
    component: HolidayProjectList,
    roles: ['corporative'],
    private: true
  },
  {
    path: '/dashboard/holiday-project/create',
    component: HolidayProjectCreate,
    roles: ['corporative'],
    private: true
  },
  {
    path: '/dashboard/holiday-project/view/:id',
    component: HolidayProjectView,
    roles: ['corporative'],
    private: true
  },
  {
    path: '/dashboard/holiday-project/edit/:id',
    component: HolidayProjectEdit,
    roles: ['corporative'],
    private: true
  },
  {
    path: '/mexico2020',
    component: HolidayProjectGallery
  },
  {
    path: '/mexico2020/:slug',
    component: HolidayProject
  }
]
