import React from 'react'
import styles from './values-button.module.css'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'


const ValuesButton = () => (
  <div className="text-center row">
    <div className={styles['centerButton']}>
      <Link to="/ourprojects">
        <button className={styles['buttonValues']}>
          <FormattedMessage id="weshare.title" />
        </button>
      </Link>
    </div>
  </div>
);

export default ValuesButton