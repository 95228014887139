import React, { Component, Fragment } from 'react'
import './404.css'

export default class NotFoundPage extends Component {
  styles = {
    body: {
      background: '#33cc99',
      color: '#fff',
      fontFamily: 'Open, Sans, sans-serif',
      maxHeight: '700px',
      overflow: 'hidden'
    }
  }

  componentWillMount() {
    for (let i in this.styles.body) {
      document.body.style[i] = this.styles.body[i]
    }
  }
  componentWillUnmount() {
    for (let i in this.styles.body) {
      document.body.style[i] = null
    }
  }

  render() {
    return (
      <Fragment>
        <div id='clouds'>
          <div className='cloud x1'></div>
          <div className='cloud x1_5'></div>
          <div className='cloud x2'></div>
          <div className='cloud x3'></div>
          <div className='cloud x4'></div>
          <div className='cloud x5'></div>
        </div>
        <div className='c'>
          <div className='_404'>404</div>
          <hr />
          <div className='_1'>THE PAGE</div>
          <div className='_2'>WAS NOT FOUND</div>
          <a className='btn-not-found' href='/'>
            GO BACK
          </a>
        </div>
      </Fragment>
    )
  }
}
