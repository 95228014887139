import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { getCampaingsByCategorie } from '../../redux/actions/campaingActions'
import { Container } from 'reactstrap'
import { isEmpty } from './lib/helpers'
import styles from '../Main/Common/Cards/cardNew.module.css'
import Card from '../Main/Common/Cards/CardNew'
import containerStyles from './styles/CampaingSlider.module.css'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import configuration from './Helpers/slider'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export class CampaingSlider extends Component {
  componentDidMount() {
    this.props.getCampaingsByCategorie()
  }

  componentWillReceiveProps(np) {
    if (!isEmpty(np.campaing.view)) {
      const id = np.campaing.view._id
      this.props.history.push(`/campaign/gallerie/${id}`)
    }
  }

  render() {
    return (
      <Container
        fluid
        className={`${containerStyles['background-projects']} mb-5`}
        style={{ display: isEmpty(this.props.campaing.list) ? 'none' : 'block' }}>
        <Container className='mb-5'>
          {/* <Link to='/campaign/gallerie' style={{ textDecorationLine: 'none' }}> */}
            <h1 className={`${styles['card__gallerie-title']} text-center mx-3`}>COVID-19</h1>
          {/* </Link> */}
        </Container>
        <div className='mx-1'>
          <Slider {...configuration}>
            {this.props.campaing.list.map(p => (
              <Card key={p._id} {...p} className='mx-3' route='campaign/gallerie' />
            ))}
          </Slider>
        </div>
        <Container className='mb-5'>
          <Link to='/campaign/gallerie' style={{ textDecorationLine: 'none' }}>
            <h1 className={`${styles['card__gallerie-subtitle']} text-center mx-3`}>Más proyectos</h1>
          </Link>
        </Container>
      </Container>
    )
  }
}

CampaingSlider.propTypes = {
  auth: PropTypes.object.isRequired,
  campaing: PropTypes.object.isRequired,
  subsedes: PropTypes.object.isRequired,
  getAllCampaingUser: PropTypes.func.isRequired,
  getCampaing: PropTypes.func.isRequired,
  getCleanCampaing: PropTypes.func.isRequired,
  getAllCleanCampaing: PropTypes.func.isRequired
}
const mapStateToProps = ({ auth, campaing, subsedes }) => ({
  auth,
  campaing,
  subsedes
})
const mapDispatchToProps = {
  getCampaingsByCategorie
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CampaingSlider))
