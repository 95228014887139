import React, { Fragment } from 'react'
import { FormGroup, Label, Input, FormFeedback, FormText, Row, Col } from 'reactstrap'
import { FormattedMessage } from 'react-intl'

const currencies = ['BRL', 'USD', 'MXN', 'EUR']

const SedesForm = ({ fields, handleChange }) => {
  return (
    <Fragment>
      <form>
        <FormGroup>
          <Label for='name'>
            <FormattedMessage id='label.name' /> :
          </Label>
          <Input
            type='text'
            name='fields.name'
            valid={fields.name.valid}
            invalid={fields.name.invalid}
            value={fields.name.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.name.valid}>
            <ul className='list-unstyled'>
              {fields.name.feed.map(f => (
                <li className=''>{f}</li>
              ))}
            </ul>
          </FormFeedback>
          <FormText>{fields.name.text}</FormText>
        </FormGroup>
        <Row form>
          <Col>
            <FormGroup>
              <Label for='languageCode'>
                <FormattedMessage id='label.languageCode' />:
              </Label>
              <Input
                type='select'
                name='fields.languageCode'
                valid={fields.languageCode.valid}
                invalid={fields.languageCode.invalid}
                value={fields.languageCode.value}
                onChange={handleChange}>
                <option value='es-MX'>es-MX</option>
                <option value='en-US'>en-US</option>
                <option value='pt-BR'>pt-BR</option>
              </Input>
              <FormFeedback valid={fields.languageCode.valid}>
                <ul className='list-unstyled'>
                  {fields.languageCode.feed.map(f => (
                    <li className=''>{f}</li>
                  ))}
                </ul>
              </FormFeedback>
              <FormText>{fields.languageCode.text}</FormText>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for='currency'>
                <FormattedMessage id='label.currency' />:
              </Label>
              <Input
                type='select'
                name='fields.currency'
                valid={fields.currency.valid}
                invalid={fields.currency.invalid}
                onChange={handleChange}
                value={fields.currency.value}>
                {currencies.map((currency, i) => (
                  <option key={`currency-${i}`} value={currency}>
                    {currency}
                  </option>
                ))}
              </Input>

              <FormFeedback valid={fields.currency.valid}>
                <ul className='list-unstyled'>
                  {fields.currency.feed.map(f => (
                    <li className=''>{f}</li>
                  ))}
                </ul>
              </FormFeedback>
              <FormText>{fields.currency.text}</FormText>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for='countryCode'>
                <FormattedMessage id='label.countryCode' />:
              </Label>
              <Input
                type='text'
                name='fields.countryCode'
                valid={fields.countryCode.valid}
                invalid={fields.countryCode.invalid}
                value={fields.countryCode.value}
                onChange={handleChange}
              />
              <FormFeedback valid={fields.countryCode.valid}>
                <ul className='list-unstyled'>
                  {fields.countryCode.feed.map(f => (
                    <li className=''>{f}</li>
                  ))}
                </ul>
              </FormFeedback>
              <FormText>{fields.countryCode.text}</FormText>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label for='description'>
            <FormattedMessage id='label.description' /> :
          </Label>
          <Input
            type='textarea'
            name='fields.description'
            valid={fields.description.valid}
            invalid={fields.description.invalid}
            value={fields.description.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.description.valid}>
            <ul className='list-unstyled'>
              {fields.description.feed.map(f => (
                <li className=''>{f}</li>
              ))}
            </ul>
          </FormFeedback>
          <FormText>{fields.description.text}</FormText>
        </FormGroup>
        <Row form>
          <Col>
            <FormGroup>
              <Label for='paypal_account'>
                <FormattedMessage id='label.paypal_account' />:
              </Label>
              <Input
                type='text'
                name='fields.paypal_account'
                valid={fields.paypal_account.valid}
                invalid={fields.paypal_account.invalid}
                value={fields.paypal_account.value}
                onChange={handleChange}
              />
              <FormFeedback valid={fields.paypal_account.valid}>
                <ul className='list-unstyled'>
                  {fields.paypal_account.feed.map(f => (
                    <li className=''>{f}</li>
                  ))}
                </ul>
              </FormFeedback>
              <FormText>{fields.paypal_account.text}</FormText>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for='paypal_clientId'>
                <FormattedMessage id='label.paypal_clientId' /> :
              </Label>
              <Input
                type='text'
                name='fields.paypal_clientId'
                valid={fields.paypal_clientId.valid}
                invalid={fields.paypal_clientId.invalid}
                value={fields.paypal_clientId.value}
                onChange={handleChange}
              />
              <FormFeedback valid={fields.paypal_clientId.valid}>
                <ul className='list-unstyled'>
                  {fields.paypal_clientId.feed.map(f => (
                    <li className=''>{f}</li>
                  ))}
                </ul>
              </FormFeedback>
              <FormText>{fields.paypal_clientId.text}</FormText>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label for='paypal_secret'>
            <FormattedMessage id='label.paypal_secret' /> :
          </Label>
          <Input
            type='text'
            name='fields.paypal_secret'
            valid={fields.paypal_secret.valid}
            invalid={fields.paypal_secret.invalid}
            value={fields.paypal_secret.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.paypal_secret.valid}>
            <ul className='list-unstyled'>
              {fields.paypal_secret.feed.map(f => (
                <li className=''>{f}</li>
              ))}
            </ul>
          </FormFeedback>
          <FormText>{fields.paypal_secret.text}</FormText>
        </FormGroup>

        {fields.name.value === 'Mexico' && (
          <Fragment>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for='conekta_account'>
                    <FormattedMessage id='label.conekta_account' />:
                  </Label>
                  <Input
                    type='text'
                    name='fields.conekta_account'
                    valid={fields.conekta_account.valid}
                    invalid={fields.conekta_account.invalid}
                    value={fields.conekta_account.value}
                    onChange={handleChange}
                  />
                  <FormFeedback valid={fields.conekta_account.valid}>
                    <ul className='list-unstyled'>
                      {fields.conekta_account.feed.map(f => (
                        <li className=''>{f}</li>
                      ))}
                    </ul>
                  </FormFeedback>
                  <FormText>{fields.conekta_account.text}</FormText>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for='conekta_clientId'>
                    <FormattedMessage id='label.conekta_clientId' /> :
                  </Label>
                  <Input
                    type='text'
                    name='fields.conekta_clientId'
                    valid={fields.conekta_clientId.valid}
                    invalid={fields.conekta_clientId.invalid}
                    value={fields.conekta_clientId.value}
                    onChange={handleChange}
                  />
                  <FormFeedback valid={fields.conekta_clientId.valid}>
                    <ul className='list-unstyled'>
                      {fields.conekta_clientId.feed.map(f => (
                        <li className=''>{f}</li>
                      ))}
                    </ul>
                  </FormFeedback>
                  <FormText>{fields.conekta_clientId.text}</FormText>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for='conekta_secret'>
                <FormattedMessage id='label.conekta_secret' /> :
              </Label>
              <Input
                type='text'
                name='fields.conekta_secret'
                valid={fields.conekta_secret.valid}
                invalid={fields.conekta_secret.invalid}
                value={fields.conekta_secret.value}
                onChange={handleChange}
              />
              <FormFeedback valid={fields.conekta_secret.valid}>
                <ul className='list-unstyled'>
                  {fields.conekta_secret.feed.map(f => (
                    <li className=''>{f}</li>
                  ))}
                </ul>
              </FormFeedback>
              <FormText>{fields.conekta_secret.text}</FormText>
            </FormGroup>
          </Fragment>
        )}
        <FormGroup>
          <Label for='termsofPrivacy'>
            <FormattedMessage id='label.termsofPrivacy' />:
          </Label>
          <Input
            type='textarea'
            name='fields.termsofPrivacy'
            valid={fields.termsofPrivacy.valid}
            invalid={fields.termsofPrivacy.invalid}
            value={fields.termsofPrivacy.value}
            onChange={handleChange}
          />
          <FormFeedback valid={fields.termsofPrivacy.valid}>
            <ul className='list-unstyled'>
              {fields.termsofPrivacy.feed.map(f => (
                <li className=''>{f}</li>
              ))}
            </ul>
          </FormFeedback>
          <FormText>{fields.termsofPrivacy.text}</FormText>
        </FormGroup>
      </form>
    </Fragment>
  )
}

export default SedesForm
