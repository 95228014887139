import swal from 'sweetalert2'

export const toastConfig = {
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  }
}

export const successConfig = title => ({ icon: 'success', title })
export const warningConfig = title => ({ icon: 'warning', title })
export const errorConfig = title => ({ icon: 'error', title })

export const promptConfing = {
  input: 'textarea',
  title: 'Justificación',
  inputPlaceholder: 'Escriba una justificación del cambio',
  showCancelButton: true,
  allowOutsideClick: false,
  allowEscapeKey: false,
  allowEnterKey: false
}

export const toast = swal.mixin(toastConfig)
export const prompt = () => swal.fire(promptConfing)
