import React, { Fragment } from 'react'
import { dmy } from './../../../util/dateHelpers'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { ButtonView, ButtonEdit, ButtonDelete } from './../../common/Buttons/ActionsList/actionsButtons'

export const tableOptions = (formatMessage, activeOptions, props) => [
	{
		dataField: 'title',
		text: formatMessage({ id: 'label.title' }),
		sort: true
	},
	{
		dataField: 'sede.name',
		text: formatMessage({ id: 'modal.sede' }),
		sort: true
	},
	{
		dataField: 'subsede.name',
		text: formatMessage({ id: 'modal.subsede' }),
		sort: true
	},
	{
		dataField: 'status',
		text: formatMessage({ id: 'label.status' }),
		sort: true
	},
	{
		dataField: 'created_by.name',
		text: formatMessage({ id: 'label.author' }),
		sort: true
	},
	{
		dataField: 'publishDate',
		text: formatMessage({ id: 'label.publishDate' }),
		sort: true,
		formatter: dmy,
		csvFormatter: dmy
	},
	{
		dataField: 'endDate',
		text: formatMessage({ id: 'label.endDate' }),
		sort: true,
		formatter: dmy,
		csvFormatter: dmy
	},
	{
		dataField: '_id',
		text: <FormattedMessage id="general.actions" />,
		csvExport: false,
		// hidden: props.props.auth.user.rol === 'reporter',
		formatter: (cellContent, row) => {
			return props.props.auth.user.rol !== 'reporter' ? (
				<UncontrolledDropdown>
					<DropdownToggle caret>
						<i class="fas fa-list-ul" />
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem>
							<ButtonView {...props} item={row}>
								<FormattedMessage id="btn.see" />
							</ButtonView>
						</DropdownItem>
						<DropdownItem>
							<ButtonEdit {...props} item={row}>
								<FormattedMessage id="btn.edit" />
							</ButtonEdit>
						</DropdownItem>
						<DropdownItem>
							<ButtonDelete {...props} item={row}>
								<FormattedMessage id="table.active" />
							</ButtonDelete>
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			) : (
				<Fragment>
					<ButtonView {...props} item={row}>
						<FormattedMessage id="btn.see" />
					</ButtonView>
				</Fragment>
			)
		}
	}
]

export const columnsToExport = [
	{ title: 'Título', value: (row) => row.title },
	{ title: 'Sede', value: (row) => row.sede.name },
	{ title: 'Subsede', value: (row) => row.subsede.name },
	{ title: 'Estatus', value: 'status' },
	{ title: 'Autor', value: (row) => row.created_by.name },
	{ title: 'Fecha de Publicación', value: (row) => dmy(row.publishDate) },
	{ title: 'Fecha de terminación', value: (row) => dmy(row.endDate) }
]
