import { SET_LOCATION, SET_LANGUAGE_MANUAL } from '../actions/types'
const initialState = {
  sede: {},
  subsede: {},
  geolocation: {},
  languageCode: 'en-US'
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOCATION:
      return { ...state, ...action.payload }
    case SET_LANGUAGE_MANUAL:
      return { ...state, languageCode: action.payload }
    default:
      return state
  }
}